const CardItem = (props) => {
  return (
    <>
      <div className='media-card'>
        <div className='select-media'>
          <div class='mediabox-cont text-center'>
            <h3>
              {props?.title || ''} <span>({props?.ratio})</span>
            </h3>
          </div>
          <div className='mediaimg-box'>
            <div className='media-img'>
              <img src={props?.src} alt={props?.alt} />
            </div>
            <div
              className='media-social'
              onClick={(e) =>
                props?.handleRemoveCardImage(
                  props?.index,
                  props?.type,
                  props?.id,
                )
              }
            >
              <i class='icon-delete'></i>
            </div>
          </div>
          <div className='mediabox-cont'>
            <h3>{props?.mediaName}</h3>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardItem;
