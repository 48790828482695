import axios from 'axios';

import { baseUrlStg, googleV1Url } from '../../utils/constants';
import { getCookies } from '../../utils/utils';

export const CommonExport = () => {
  const handleAPICall = async (tab, ids, platform, page) => {
    let payload = {
      tab: tab,
      id: ids,
      atdStatus: ['string'],
      googleStatus: ['string'],
      rows: 0,
    };
    let endPoint =
      platform == 'dv360'
        ? `${baseUrlStg}/api/DVCampaign/ExportToExcel`
        : `${googleV1Url}/ExportToExcel`;

    let getCookie = getCookies();
    const CustomHeader = {
      Authorization: getCookie?.substring(1, getCookie?.length - 1),
    };

    let res = await axios.post(endPoint, payload, {
      headers: {
        accept: 'application/json',
        ...CustomHeader,
      },
      responseType: 'blob',
    });

    try {
      const fileURL = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = fileURL;
      let fileName = `${page}.xlsx`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log('err', err);
    }
  };

  return { handleAPICall };
};
