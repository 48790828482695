export const DV360Status = [
  {
    label: 'NA',
    value: 'na',
  },
  {
    label: 'Published(Enabled)',
    value: '1',
  },
  {
    label: 'Published(Disabled)',
    value: '0',
  },
];
