import CardBody from '../common/CardBody';
import InfoText from '../common/InfoText';
import ReactSelect from '../common/ReactSelect';
import SingleInput from './SingleInput';

const MoreOptions = (props) => {
  return (
    <>
      <CardBody>
        <div className='action-btn-both action-btn-both-check row'>
          <div className='form-check-box'>
            <input
              className='form-check-input'
              type='checkbox'
              id='call-to-action-text'
              onChange={props?.handleIsEnabledCallToAction}
              checked={props?.callToActionValue ? true : false}
            />
            <label className='form-check-label' htmlFor='call-to-action-text'>
              Call to action text
            </label>
          </div>
          {/* call to action options start */}
          {props?.callToActionValue && (
            <div className='card-form form-panel'>
              <div className='row align-items-center'>
                <div className='form-item col-12'>
                  <ReactSelect
                    options={props?.getCallToActionLists}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    onChange={props?.handleCallToAction}
                    value={props?.callToActionSelectedVal}
                  />
                </div>
              </div>
            </div>
          )}
          {/* call to action options end */}
          <div className='form-check-box'>
            <input
              className='form-check-input'
              type='checkbox'
              id='custom-colors'
              onChange={props?.handleIsCustomColorEnabled}
              checked={props?.customColorValue ? true : false}
            />
            <label className='form-check-label' htmlFor='custom-colors'>
              Custom colors
            </label>
          </div>
          {/* custom colors options start */}
          {props?.customColorValue && (
            <>
              <div className='card-form form-panel'>
                <div className='row align-items-center'>
                  <div className='form-item col-6'>
                    <SingleInput
                      label={'Main color'}
                      bgLineDisplay={false}
                      handleSetValue={props?.setCustomMainColor}
                      value={props?.customMainColor}
                    />
                    <InfoText copy={'Example: #ffffff'} />
                  </div>
                  <div className='form-item col-6'>
                    <SingleInput
                      label={'Accent Color'}
                      bgLineDisplay={false}
                      handleSetValue={props?.setAccentColor}
                      value={props?.customAccentColor}
                    />
                    <InfoText copy={'Example: #4285f4'} />
                  </div>
                </div>
              </div>
              {/* custom colors options end */}
              {/* Show this ad start */}
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='show-ad-on-text'
                  onChange={props?.setIsCustomColorEnabled}
                  checked={props?.iscustomcolorEnabled}
                />
                <label className='form-check-label' htmlFor='show-ad-on-text'>
                  Show this ad on text and native ad placements, even when
                  publisher settings may override your custom color selections.
                </label>
              </div>
              {/* Show this ad end */}
            </>
          )}
        </div>
      </CardBody>
      <InfoText
        copy={
          'Your ads might not always include all your text and images. Some cropping or shortening may occur in some formats, and either of your custom colors may be used.'
        }
      />
    </>
  );
};
export default MoreOptions;
