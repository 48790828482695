import Modal from 'react-bootstrap/Modal';

const RejectModel = (props) => {
  return (
    <Modal
      show={props?.showRejectModel}
      className='atd-modal approved-model'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img-cont'></div>
        <div className='modal-text'>
          {props?.requestStatus ? (
            <>
              <h3>Are you sure you want to proceed with this action?</h3>
            </>
          ) : (
            <>
              <h3>Rejection of request</h3>
              <p>Please type reason for rejection of request</p>
              {/*  */}
              <div className='card-form form-panel'>
                <div className='row align-items-center'>
                  <div className='form-item col-12'>
                    <div className='label-icon'>
                      <label>Type Reason</label>
                    </div>
                    <div className='input-sizer stacked'>
                      <textarea
                        oninput='this.parentNode.dataset.value = this.value'
                        rows={1}
                        placeholder='Type Description'
                        defaultValue={''}
                        onChange={props?.handleRemark}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
            </>
          )}
        </div>
      </Modal.Body>
      <div className='modal-footer'>
        <button
          type='button'
          className='primary-btn footer-close-btn'
          onClick={props?.handleCloseModel}
        >
          cancel
        </button>
        <button
          type='button'
          className='primary-btn'
          onClick={props?.handleRejectCall}
        >
          {props?.requestStatus ? 'Ok' : 'Reject'}
        </button>
      </div>
    </Modal>
  );
};
export default RejectModel;
