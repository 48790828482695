import SingleInput from '../google/SingleInput';
import CardBody from './CardBody';

const CommonAmountComponent = (props) => {
  return (
    <CardBody>
      <SingleInput
        type={'number'}
        label={'Amount'}
        handleSetValue={props?.handleSetAmountVal}
        bgLineDisplay={false}
        value={props?.value}
        getCurrentPageStatus={props?.getCurrentPageStatus}
      />
    </CardBody>
  );
};
export default CommonAmountComponent;
