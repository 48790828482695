import React, { useState, useEffect } from 'react';
import { getApi } from '../../utils/Apis';
import { baseUrlStg } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { GoogleAdOptions } from '../../pages/googleAds/GoogleAdConstant';

const ErrorDisplay = ({ id }) => {
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(null);
  const [isButtonVisible, setButtonVisibility] = useState(true);
  const [visibleErrors, setVisibleErrors] = useState(1);

  const handleButtonClick = (errorId, actionName) => {
    switch (actionName) {
      case 'CAMPAIGN':
        window.location.href = `${GoogleAdOptions.Campaigns.editUrl}/${errorId?.campaign_id}`;
        break;
      case 'ADGROUP':
        window.location.href = `${GoogleAdOptions.AdGroups.editUrl}/${errorId?.google_ad_groups_id}`;
        break;
      case 'ADS':
        window.location.href = `${GoogleAdOptions.Ads.editUrl}/${errorId?.google_ad_id}`;
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    if (id == undefined || null) {
      return;
    }
    if (id) {
      try {
        const apiResponse = await getApi(
          `${baseUrlStg}/api/GoogleCampaignError/getGoogleErrorByCampaignId?campaign_id=${id}`,
        );

        if (!apiResponse?.response || apiResponse.response.length === 0) {
          setShow(false);
          return;
        }

        setShow(true);
        setResponse(apiResponse.response);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (!show || !isButtonVisible) {
    return null;
  }

  return (
    <div className='info-icon-body info-alert-box' id='facebook_error_divID'>
      <button
        className='close-slideshow'
        id='facebook_error_closeID'
        type='button'
        onClick={() => setButtonVisibility(false)}
      >
        <i className='icon-close'></i>
      </button>
      <div className='more-info-icon'>
        <i className='icon-close-round'></i>
      </div>
      <div className='more-info-text'>
        {response &&
          response.slice(0, visibleErrors).map((item, index) => (
            <Link
              key={index}
              onClick={() => handleButtonClick(item, item?.action_name)}
            >
              <h6>
                {item?.action_name} :{' '}
                {item?.campaign_name || item?.ad_group_name}
              </h6>
              <p>{item?.errors}</p>
            </Link>
          ))}
        {response && visibleErrors < response.length && (
          <div class='show-more-less'>
            <button
              class='btn-icon ms-auto'
              onClick={() =>
                setVisibleErrors((prevVisibleErrors) => prevVisibleErrors + 1)
              }
            >
              <i class='icon-plus'></i> Show More
            </button>
          </div>
        )}
        {visibleErrors > 1 && (
          <div class='show-more-less'>
            <button
              class='btn-icon ms-auto'
              onClick={() =>
                setVisibleErrors((prevVisibleErrors) => prevVisibleErrors - 1)
              }
            >
              <i class='icon-minus'></i> Show Less
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorDisplay;
