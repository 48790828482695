import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { GoogleAdOptions } from '../googleAds/GoogleAdConstant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import '../../assets/css/style.css';
import { baseUrlStg, userManagement } from '../../utils/constants';
import { getApi, postApi } from '../../utils/Apis';
import 'react-datepicker/dist/react-datepicker.module.css';
import CommonModal from '../../components/common/commonModal';

const UserCreation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: null,
    role: '',
    facebookAds: false,
    googleAds: false,
    dv360: false,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    role: '',
    platformAccess: '',
  });

  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;

  const GotoPage = userManagement?.userList;

  const genderOptions = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
  ];

  const roleOptions = [
    { label: 'Admin', value: 1 },
    { label: 'Creator', value: 2 },
    { label: 'Publisher', value: 3 },
  ];

  const goToListingPage = () => {
    navigate(GotoPage);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: inputValue });
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Check for each required field
    if (!formData?.name?.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    } else {
      newErrors.name = '';
    }

    if (!formData?.email?.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        formData?.email?.trim(),
      )
    ) {
      newErrors.email = 'Invalid email address';
      isValid = false;
    } else {
      newErrors.email = '';
    }

    if (!formData?.phone) {
      // newErrors.phone = 'Mobile Number is required';
      // isValid = false;
    } else if (
      formData.phone.toString().trim().length !== 10 ||
      !/^\d+$/.test(formData.phone.toString().trim())
    ) {
      newErrors.phone = 'Mobile Number must be a 10-digit numeric value';
      isValid = false;
    } else {
      newErrors.phone = '';
    }
    if (!formData?.gender) {
      newErrors.gender = 'Gender is required';
      isValid = false;
    } else {
      newErrors.gender = '';
    }

    if (!formData?.role) {
      newErrors.role = 'Role is required';
      isValid = false;
    } else {
      newErrors.role = '';
    }

    const selectedPlatforms = ['facebookAds', 'googleAds', 'dv360'].filter(
      (platform) => formData?.[platform],
    );

    // Check if no platform is selected or the role value is not 1
    if (formData?.role?.value !== 1) {
      newErrors.platformAccess = '';
      setErrors(newErrors);

      if (selectedPlatforms.length === 0) {
        // Set error message
        newErrors.platformAccess = 'At least one platform access is required';
        // Set validation flag to false
        isValid = false;
      } else {
        // Clear any existing error message
        newErrors.platformAccess = '';
      }
    }
    console.log('newErrors', newErrors);

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      console.log('Form has errors, please correct them', formData);
      return;
    }

    try {
      const platformAccessArray = [];

      if (formData.dv360) {
        platformAccessArray.push('DV360');
      }

      if (formData.googleAds) {
        platformAccessArray.push('Google');
      }

      if (formData.facebookAds) {
        platformAccessArray.push('Meta');
      }

      const payload = {
        userId: id || '',
        userName: formData?.name,
        emailAddress: formData?.email,
        mobile: formData?.phone,
        gender: formData?.gender?.value,
        roleId: formData?.role?.value,
        platformAccess:
          formData?.role?.value == 1 ? '' : platformAccessArray.join(','),
      };

      const apiEndpoint = id
        ? `${baseUrlStg}/api/UserManagement/UpdateUserById`
        : `${baseUrlStg}/api/UserManagement/CreateUser`;

      const response = await postApi(apiEndpoint, payload, {
        accept: '*/',
      });

      if (response?.data?.success === false) {
        console.error('Failed to create/update user:', response?.data?.message);
        setShowSuccessModal(true);
        setMsg(response?.data?.message);
      } else {
        setMsg(id ? 'User updated successfully' : 'User created successfully');
        setShowSuccessModal(true);
        getUserDetails();
      }
    } catch (error) {
      console.error('Error while submitting the form:', error);
    }
  };

  const getRoleLabel = (roleId) => {
    const role = roleOptions.find((option) => option.value == roleId);
    console.log('role ', role);
    return role ? role.label : '';
  };
  const getUserDetails = async () => {
    console.log('id', id);
    if (id) {
      // Call the getApi to fetch user details using the id
      try {
        const response = await getApi(
          `${baseUrlStg}/api/UserManagement/GetUserById?id=${id}`,
          {
            accept: '*/',
            'Content-Type': 'multipart/form-data',
          },
        );
        console.log('Response', response.response);
        const mappedRole = roleOptions.find(
          (option) => option.label == response.response.roleId,
        );
        console.log('MappedRole', mappedRole);

        // Update the formData state with the retrieved user details
        setFormData({
          name: response.response.name,
          email: response.response.emailAddress,
          phone: response.response.mobile,
          gender: {
            label: response.response.gender === 'M' ? 'Male' : 'Female',
            value: response.response.gender,
          },
          role: {
            label: mappedRole ? mappedRole.label : '',
            value: mappedRole ? mappedRole.value : '',
          },
          facebookAds: response.response.platformAccess.includes('Meta'),
          googleAds: response.response.platformAccess.includes('Google'),
          dv360: response.response.platformAccess.includes('DV360'),
        });
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [id]);
  const isAdminSelected = formData?.role?.value == 1;
  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb '>
              <h4>{id ? 'Update User' : 'Create New User'}</h4>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              <article className='side-right-form-wrap side-right-pad'>
                <div className='card-body'>
                  <div className='card-form form-panel'>
                    <div className='row align-items-center'>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Name*</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            name='name'
                            id='text'
                            placeholder='Name'
                            value={formData.name}
                            onChange={handleInputChange}
                            autoComplete='off'
                          />
                        </div>
                        <span className='error'>{errors.name}</span>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Email Address*</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='email'
                            name='email'
                            id='text'
                            placeholder='Email'
                            value={formData.email}
                            onChange={handleInputChange}
                            autoComplete='off'
                            disabled={id ? true : false}
                          />
                        </div>
                        <span className='error'>{errors.email}</span>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Mobile Number*</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            name='phone'
                            id='text'
                            placeholder='Mobile Number'
                            value={formData.phone}
                            onChange={handleInputChange}
                            maxLength={10}
                            minLength={10}
                            autoComplete='off'
                          />
                        </div>
                        <span className='error'>{errors.phone}</span>
                      </div>
                    </div>
                    <div className='row align-items-center'>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Gender*</label>
                        </div>
                        <div className='select-box select-multiple-components '>
                          <ReactSelect
                            options={genderOptions}
                            isMulti={false}
                            isSearchable={true}
                            value={formData.gender}
                            onChange={(selectedOption) =>
                              handleSelectChange('gender', selectedOption)
                            }
                            closeMenuOnSelect={true}
                          />
                        </div>
                        <span className='error'>{errors.gender}</span>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Role*</label>
                        </div>
                        <div className='select-box select-multiple-components '>
                          <ReactSelect
                            options={roleOptions}
                            isMulti={false}
                            isSearchable={true}
                            value={formData.role}
                            onChange={(selectedOption) =>
                              handleSelectChange('role', selectedOption)
                            }
                            closeMenuOnSelect={true}
                          />
                        </div>

                        <span className='error'>{errors.role}</span>
                      </div>
                    </div>
                  </div>
                  <div className='card-header-top'>
                    <div className='card-lt-pane'>
                      <h6>Platform Access*</h6>
                      <div className='action-btn-both action-btn-both-check d-flex '>
                        <div className='form-check-box'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='facebookAds'
                            name='facebookAds'
                            checked={!isAdminSelected && formData.facebookAds}
                            onChange={handleInputChange}
                            autoComplete='off'
                            disabled={isAdminSelected}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='facebookAds'
                          >
                            Meta Campaign
                          </label>
                        </div>
                        <div className='form-check-box'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='googleAds'
                            name='googleAds'
                            checked={!isAdminSelected && formData.googleAds}
                            onChange={handleInputChange}
                            autoComplete='off'
                            disabled={isAdminSelected}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='googleAds'
                          >
                            Google Ads
                          </label>
                        </div>
                        <div className='form-check-box'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='dv360'
                            name='dv360'
                            checked={!isAdminSelected && formData.dv360}
                            onChange={handleInputChange}
                            autoComplete='off'
                            disabled={isAdminSelected}
                          />
                          <label className='form-check-label' htmlFor='dv360'>
                            DV360
                          </label>
                        </div>
                      </div>
                      <span className='error'>{errors.platformAccess}</span>
                    </div>
                  </div>
                </div>
                <div className='mt-4'>
                  <button
                    className='primary-btn'
                    type='submit'
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer closePath={GotoPage} prevPath={GotoPage}></Footer>

      {showSuccessModal && (
        <CommonModal
          show={showSuccessModal}
          // handleClose={() => setShowSuccessModal(false)}
          // handleRedirection={() => goToListingPage()}
          handleClose={() => {
            goToListingPage();
          }}
          copy={msg}
          // isUpdatedStatus={true}
        />
      )}
    </div>
  );
};

export default UserCreation;
