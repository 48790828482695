import axios from 'axios';
import { toast } from 'react-toastify';
import { EnabledApproval, loginUrl } from './constants';

function getCookie(cookieName) {
  // Split document.cookie string into individual cookies
  const cookies = document.cookie.split(';');

  // Iterate over cookies to find the one with the specified name
  for (let cookie of cookies) {
    cookie = cookie.trim(); // Remove leading and trailing whitespace
    // Check if the cookie starts with the specified name
    if (cookie.startsWith(cookieName + '=')) {
      // Return the value of the cookie
      return JSON.stringify(cookie?.substring(cookieName?.length + 1));
    }
  }
  // Return null if the cookie is not found
  return null;
}

// const myCookieValue = getCookie('Token'); // Get Token
const myCookieValue = getCookie('Token'); // Test Token

const CustomHeader = {
  accept: 'text/plain',
  accessToken: 'Test',
  Authorization: myCookieValue?.substring(1, myCookieValue?.length - 1) || '',
  EnabledApproval: EnabledApproval,
};

export const handleApiError = (err) => {
  try {
    if (err.isAxiosError) {
      if (err.response && err.response.status === 401) {
        if (window.location.hostname !== 'localhost') {
          window.location.href = loginUrl;
        }
      } else if (err.response && err.response.status >= 500) {
        toast.error(<h6>Server Error. Please try again.</h6>);
      } else if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.message);
      }
    }
  } catch (error) {
    console.error('Error handling API error:', error);
  }
};
export const getApi = async (url, header) => {
  {
    let data = null;
    try {
      let res = await axios.get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
          ...header,
        },
      });

      data = res.data;
    } catch (err) {
      handleApiError(err);
    }

    return data;
  }
};

export const postApi = async (url, data, header) => {
  {
    let response = null;
    try {
      response = await axios.post(url, data, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
          ...header,
        },
      });
    } catch (err) {
      handleApiError(err);
    }

    return response;
  }
};
export const putApi = async (url, postIdToUpdate, data, header) => {
  {
    let response = null;
    try {
      response = await axios.put(url + postIdToUpdate, data, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
          ...header,
        },
      });
    } catch (err) {
      handleApiError(err);
    }

    return response;
  }
};
export const deleteApi = async (url, postIdToDelete, data, header) => {
  {
    let response = null;
    try {
      response = await axios.delete(url + postIdToDelete, data, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
          ...header,
        },
      });
    } catch (err) {
      handleApiError(err);
    }

    return response;
  }
};
