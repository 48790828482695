import React from 'react';
import Card from './Card';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import ReactSelect from './ReactSelect';
import { blockInvalidChar, handleFocus } from '../../utils/utils';

function BiddingForApp(props) {
  let subCamp = props?.subCamp;
  let bidStrategy = props?.bidStrategy;
  let setTargetCostPerAction = props?.setTargetCostPerAction;
  let cpaCheckBoxHandler = props?.cpaCheckBoxHandler;
  let waysToGetConversionsHandler = props?.waysToGetConversionsHandler;
  let waysToGetConversions = props?.waysToGetConversions;
  let payForTypeHandler = props?.payForTypeHandler;
  let cpaCostHandler = props?.cpaCostHandler;
  let cpprCostHandler = props?.cpprCostHandler;
  let cpiCostHandler = props?.cpiCostHandler;
  let roasCheckBoxHandler = props?.roasCheckBoxHandler;
  let viewableCPMHandler = props?.viewableCPMHandler;
  let googleCreateCampaignData = props?.googleCreateCampaignData;

  const appInstallOptions = [
    { label: 'Install volume', value: 'Install volume' },
  ];
  const appEngagementOptions = [
    { label: 'In-app actions', value: 'In-app actions' },
    { label: 'In-app action value', value: 'In-app action value' },
  ];
  const appPreRegistrationOptions = [
    { label: 'Pre-registration', value: 'Pre-registration' },
  ];
  return (
    <>
      {/* Bidding starts here */}
      {
        <>
          <Card>
            <CardHeader>
              <div className='card-lt-pane'>
                <h5>Bidding</h5>
              </div>
            </CardHeader>
            <CardHeader>
              <div className='card-lt-pane'>
                <h6>What do you want to focus on ?</h6>
              </div>
            </CardHeader>
            <CardBody>
              <div className='card-form form-panel'>
                <div className='row align-items-center'>
                  <div className='form-item'>
                    <div className='label-icon'>
                      <label>focus</label>
                    </div>
                    <ReactSelect
                      options={
                        subCamp == 'App_Installs'
                          ? appInstallOptions
                          : subCamp == 'App_Engagement'
                            ? appEngagementOptions
                            : subCamp == 'App_Pre_Registration'
                              ? appPreRegistrationOptions
                              : []
                      }
                      onChange={props?.bidStrategyHandler}
                      closeMenuOnSelect={true}
                      isDisabled={false}
                      value={[
                        {
                          label: bidStrategy,
                          value: bidStrategy,
                        },
                      ]}
                    />
                    <span className='error' id={`error-bidding_focus`}>
                      {props?.bidStrategyError}
                    </span>
                  </div>
                  {bidStrategy == 'Conversions' && (
                    <>
                      <div className='form-item'>
                        <div className='label-icon'>
                          <label>How do you want to get conversions?</label>
                        </div>
                        <ReactSelect
                          options={[
                            {
                              value: 'Manually set bids',
                              label: 'Manually set bids',
                            },
                            {
                              value: 'Automatically maximize conversions',
                              label: 'Automatically maximize conversions',
                            },
                          ]}
                          onChange={waysToGetConversionsHandler}
                          closeMenuOnSelect={true}
                          value={[
                            {
                              label: bidStrategy,
                              value: bidStrategy,
                            },
                          ]}
                        />
                        <span className='error' id='emailError'>
                          {/* {formErrors?.bidding_focus} */}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </CardBody>

            <CardBody></CardBody>

            <CardBody>
              <div className='action-btn-both action-btn-both-check row relative'>
                {/* //install volume */}
                {bidStrategy == 'Install volume' && (
                  <>
                    <div className='card-form form-panel'>
                      <div className='row align-items-center'>
                        <div className='form-item input-group-label col-6'>
                          <div className='budget-cont'>
                            <div className='label-icon'>
                              <label>Target cost per install</label>
                            </div>
                            <div className='input-group'>
                              <span className='input-group-text rs-text'>
                                <i className='icon-rupee' />
                              </span>
                              <input
                                type='number'
                                id='email'
                                placeholder='X.XX'
                                name='targetCpi'
                                onChange={cpiCostHandler}
                                onFocus={handleFocus}
                                onKeyDown={blockInvalidChar}
                                value={
                                  googleCreateCampaignData?.google_Binding
                                    ?.targetCostPerInstall
                                }
                              />
                              <span className='input-group-text inr-text rs-text'>
                                INR
                              </span>
                            </div>
                          </div>
                          <span className='error' id='emailError'>
                            {/* {formErrors?.targetSpend} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* //In-app actions */}
                {bidStrategy == 'In-app actions' && (
                  <>
                    <div className='card-form form-panel'>
                      <div className='row align-items-center'>
                        <div className='form-item input-group-label col-6'>
                          <div className='budget-cont'>
                            <div className='label-icon'>
                              <label>Target CPA</label>
                            </div>
                            <div className='input-group'>
                              <span className='input-group-text rs-text'>
                                <i className='icon-rupee' />
                              </span>
                              <input
                                type='number'
                                id='email'
                                placeholder='X.XX'
                                name='targetCpa'
                                // value={
                                //   googleCreateCampaignData?.google_Binding
                                //     ?.targetCpa
                                // }
                                // onChange={cpaCostHandler}
                                onChange={cpaCostHandler}
                                onFocus={handleFocus}
                                onKeyDown={blockInvalidChar}
                              />
                              <span className='input-group-text inr-text rs-text'>
                                INR
                              </span>
                            </div>
                          </div>
                          <span className='error' id='emailError'>
                            {/* {formErrors?.targetSpend} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {bidStrategy == 'Conversion value' && (
                  <>
                    <div className='form-check-box'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='targetRoas'
                        onChange={roasCheckBoxHandler}
                        id='inlineCheckbox1dc'
                        defaultValue='option1'
                        // defaultChecked={IstargetroasEnabled}
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineCheckbox1dc'
                      >
                        Set a target return on ad{' '}
                        <span className='opt-text'>(Optional)</span>
                      </label>
                    </div>
                  </>
                )}
                {/*In-app action value  */}
                {bidStrategy == 'In-app action value' && (
                  <>
                    <div className='card-form form-panel'>
                      <div className='row align-items-center'>
                        <div className='form-item input-group-label col-6'>
                          <div className='budget-cont'>
                            <div className='label-icon'>
                              <label>Target ROAS</label>
                            </div>

                            <div className='input-group'>
                              <span className='input-group-text rs-text'>
                                %
                              </span>
                              <input
                                type='number'
                                id='email'
                                placeholder='X.XX'
                                name='targetRoas'
                                // value={
                                //   googleCreateCampaignData?.google_Binding
                                //     ?.targetRoas
                                // }
                                onChange={cpaCostHandler}
                                onFocus={handleFocus}
                                onKeyDown={blockInvalidChar}
                              />
                            </div>
                          </div>
                          <span className='error' id='emailError'>
                            {/* {formErrors?.targetSpend} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* Pre-registrations */}
                {bidStrategy == 'Pre-registration' && (
                  <>
                    <div className='card-form form-panel'>
                      <div className='row align-items-center'>
                        <div className='form-item input-group-label col-6'>
                          <div className='budget-cont'>
                            <div className='label-icon'>
                              <label>Target cost per pre-registration</label>
                            </div>

                            <div className='input-group'>
                              <span className='input-group-text rs-text'>
                                <i className='icon-rupee' />
                              </span>
                              <input
                                type='number'
                                id='email'
                                placeholder='X.XX'
                                name='targetcppr'
                                // value={
                                //   googleCreateCampaignData?.google_Binding
                                //     ?.targetRoas
                                // }
                                onChange={cpprCostHandler}
                                onFocus={handleFocus}
                                onKeyDown={blockInvalidChar}
                              />
                            </div>
                          </div>
                          <span className='error' id='emailError'>
                            {/* {formErrors?.targetSpend} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='maximumCpcBidLimitValue'
                    // onChange={costTargetCheckBoxHandler}
                    id='inlineCheckbox1'
                    defaultValue='option1'
                    // defaultChecked={IsmaxcpcEnabled}
                  />
                  <label className='form-check-label' htmlFor='inlineCheckbox1'>
                    Set a maximum cost per click bid limit{' '}
                    <span className='opt-text'>(Optional)</span>
                  </label>
                </div> */}

                <span className='error' id='emailError'>
                  {/* Please enter valid email id */}
                </span>

                <span className='error' id='emailError'>
                  {/* Please enter valid email id */}
                </span>
              </div>
            </CardBody>
          </Card>
        </>
      }

      {/* Bidding ends here */}
    </>
  );
}

export default BiddingForApp;
