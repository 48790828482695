import {
  DV_CAMPAIGN_EXIST,
  DV_CAMPAIGN_NAME,
  DV_CREATIVE_TYPE,
  DV_Deals_And_Inventory_Packages,
  DV_FREQUENCY_CAP_TYPE,
  DV_FREQUENCY_LIMIT,
  DV_FREQUENCY_LIMIT_TYPE,
  DV_FREQUENCY_LIMIT_VALUE,
  DV_Groups,
  DV_KPI,
  DV_KPI_GOAL,
  DV_OVERALL_CAMPAIGN_GOAL,
  DV_PLANNED_END_DATE,
  DV_PLANNED_SPEND,
  DV_PLANNED_START_DATE,
  DV_Public_Inventory,
  DV_Quality,
  DV_STORE_CAMPAIGN_BY_ID,
  RESET_CAMPAIGN_STATE,
} from './CreateCampaignActionTypes';
import { getApi } from '../../utils/Apis';
import { baseUrlStg } from '../../utils/constants';

const setDVCampaignGoal = (payload) => {
  return {
    type: DV_OVERALL_CAMPAIGN_GOAL,
    payload: payload,
  };
};
const setCampaignName = (payload) => {
  return {
    type: DV_CAMPAIGN_NAME,
    payload: payload,
  };
};
const set_KPI = (payload) => {
  return {
    type: DV_KPI,
    payload: payload,
  };
};
const set_KPI_value = (payload) => {
  return {
    type: DV_KPI_GOAL,
    payload: payload,
  };
};
const setCreative = (payload) => {
  return {
    type: DV_CREATIVE_TYPE,
    payload: payload,
  };
};

const setPlannedSpend = (payload) => {
  return {
    type: DV_PLANNED_SPEND,
    payload: payload,
  };
};
const setPlannedStartDate = (payload) => {
  return {
    type: DV_PLANNED_START_DATE,
    payload: payload,
  };
};
const setPlannedEndDate = (payload) => {
  return {
    type: DV_PLANNED_END_DATE,
    payload: payload,
  };
};
const setFrequencyCapType = (payload) => {
  return {
    type: DV_FREQUENCY_CAP_TYPE,
    payload: payload,
  };
};
const setFrequencyLimit = (payload) => {
  return {
    type: DV_FREQUENCY_LIMIT,
    payload: payload,
  };
};
const setFrequencyLimitType = (payload) => {
  return {
    type: DV_FREQUENCY_LIMIT_TYPE,
    payload: payload,
  };
};
const setFrequencyLimitValue = (payload) => {
  return {
    type: DV_FREQUENCY_LIMIT_VALUE,
    payload: payload,
  };
};
const setQuality = (payload) => {
  return {
    type: DV_Quality,
    payload: payload,
  };
};
const setPublicInvetory = (payload) => {
  return {
    type: DV_Public_Inventory,
    payload: payload,
  };
};
const setDealsAndInventoryPackages = (payload) => {
  return {
    type: DV_Deals_And_Inventory_Packages,
    payload: payload,
  };
};
const setGroups = (payload) => {
  return {
    type: DV_Groups,
    payload: payload,
  };
};
const setTargettingSources = () => {
  return async (dispatch, getState) => {
    let response = await getApi(
      `${baseUrlStg}/api/TargetingSource/getAllTargetingSources`,
      { accessToken: 123123 },
    );
    try {
    } catch (err) {
      console.log(err);
    }
  };
};

const getCampaignDataById = (campaignId) => {
  return async (dispatch, getState) => {
    let response = await getApi(
      `${baseUrlStg}/api/Campaign/getCampaignByID?campaignID=${campaignId}`,
      { accessToken: 123123 },
    );
    try {
      // console.log(response?.response)
      let data = response?.response;
      if (response?.status == 200) {
        dispatch({
          type: DV_STORE_CAMPAIGN_BY_ID,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
const checkCampName = (campName) => {
  return async (dispatch, getState) => {
    let response = await getApi(
      `${baseUrlStg}/api/Campaign/getCampaignByName?campaign_name=${campName}`,
      { accessToken: '1234' },
    );
    try {
      console.log(response?.response);
      if (response?.response?.length > 0) {
        dispatch({
          type: DV_CAMPAIGN_EXIST,
          payload: true,
        });
      } else {
        dispatch({
          type: DV_CAMPAIGN_EXIST,
          payload: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetCampaignState = () => {
  return {
    type: RESET_CAMPAIGN_STATE,
  };
};

export {
  setDVCampaignGoal,
  setCampaignName,
  set_KPI,
  set_KPI_value,
  setCreative,
  setPlannedSpend,
  setPlannedStartDate,
  setPlannedEndDate,
  setFrequencyCapType,
  setFrequencyLimit,
  setFrequencyLimitType,
  setFrequencyLimitValue,
  setQuality,
  setPublicInvetory,
  setDealsAndInventoryPackages,
  setGroups,
  setTargettingSources,
  getCampaignDataById,
  checkCampName,
};
