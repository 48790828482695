import Modal from 'react-bootstrap/Modal';

const CommonModal = (props) => {
  return (
    <Modal
      show={props?.show}
      onHide={props?.handleClose}
      className='atd-modal approved-model'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img-cont'>
          <figure className='modal-icon'>
            <i className='icon-media-plan' />
          </figure>
        </div>
        <div className='modal-text'>
          <p>{props?.copy}</p>
        </div>
      </Modal.Body>

      <div className='modal-footer'>
        {/* Button will display in update case of DV360 CC, IO, LI start */}
        {props?.isUpdatedStatus && (
          <button
            type='button'
            className='primary-btn'
            onClick={props?.handleRedirection}
          >
            {props?.isUpdatedStatus && 'Go to listing'}
          </button>
        )}
        {/* Button will display in update case of DV360 CC, IO, LI end */}
        <button
          type='button'
          className='primary-btn'
          onClick={props?.handleClose}
        >
          {props?.isUpdatedStatus ? 'Continue editing' : 'Ok'}
        </button>
      </div>
    </Modal>
  );
};

export default CommonModal;
