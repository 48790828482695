import React from 'react';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';
import { baseUrlStg } from '../../utils/constants';
import Card from '../common/Card';

const Keywords = ({
  handleUploadFile,
  adGroupData,
  handleDownloadFile,
  handleRemoveFile,
  keywordIssueText,
  adGroupId,
}) => {
  return (
    <>
      <Card>
        <CardHeader>
          <h5>Keywords</h5>
        </CardHeader>
        <CardBody>
          <CardHeader>
            <h6>Upload Keywords</h6>
          </CardHeader>
          <p className='d-flex align-items-center'>
            Keywords are words or phrases that are used to match your ads with
            the terms people are searching for.
          </p>
        </CardBody>

        <CardBody>
          <div>
            <div class='info-icon-body mt-2'>
              <div class='more-info-icon'>
                <i class='icon-bulb'></i>
              </div>
              <div class='more-info-text'>
                <p className='d-flex align-items-center'>
                  Add upto 10,000 Keywords.
                </p>
              </div>
            </div>
            {/*  */}
            <div className='d-flex '>
              <div className='media-btn'>
                <div className='file-error'>
                  <div className='btn-icon btn-icon-primary btn-bedcrumb-act btn-file'>
                    <input
                      accept='.csv'
                      type='file'
                      id='imgFileIDVi'
                      multiple=''
                      onChange={(e) => handleUploadFile(e)}
                    />
                    <label htmlFor='imgFileIDVi'> Upload keywords</label>
                  </div>
                </div>
              </div>
              <div className='media-btn' style={{ marginLeft: '10px' }}>
                <div className='file-error'>
                  <div className='btn-icon btn-icon-primary btn-bedcrumb-act '>
                    <a
                      id='imgFileIDViddd'
                      href={`${baseUrlStg}/upload-templates/keywords-upload-template.csv`}
                      download='keywords-upload-template'
                      rel='noreferrer'
                    >
                      <label htmlFor='imgFileIDViddd'>
                        {' '}
                        Download sample file
                      </label>
                    </a>
                  </div>
                  {/* <span className="error" id="emailError">
                                        Please Select videos
                                      </span> */}
                </div>
              </div>
            </div>

            {adGroupData?.CSVFileName && (
              <div class='d-flex justify-content-space-around align-items-center'>
                <div className='d-flex'>
                  <p class='d-flex align-items-center'>
                    {adGroupData?.CSVFileName}
                  </p>
                  <div
                    role='button'
                    class='css-v7duua d-flex align-items-center'
                    aria-label='Remove Keywords'
                    onClick={handleRemoveFile}
                    style={{ marginLeft: '5px' }}
                  >
                    <svg
                      height='14'
                      width='14'
                      viewBox='0 0 20 20'
                      aria-hidden='true'
                      focusable='false'
                      class='css-8mmkcg'
                    >
                      <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                    </svg>
                  </div>
                </div>
                {adGroupId && adGroupId.length > 0 && (
                  <div className='media-btn mx-auto'>
                    <div className='file-error'>
                      <div className='btn-icon btn-icon-primary btn-bedcrumb-act '>
                        <a
                          id='imgFileIDViddd'
                          download='keywords-upload-template'
                          rel='noreferrer'
                          onClick={(e) =>
                            handleDownloadFile(adGroupData?.CSVFileName)
                          }
                        >
                          <label htmlFor='imgFileIDViddd'> Download CSV</label>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {keywordIssueText && keywordIssueText.length > 0 && (
              <p className='text-danger'>{keywordIssueText}</p>
            )}

            {/*  */}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Keywords;
