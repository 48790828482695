export const DV_Insertion_Name = 'DV_Insertion_Name';
export const DV_Insertion_Id = 'DV_Insertion_Id';
export const ADD_Budget = 'ADD_Budget';
export const ADD_Budget_Amount = 'ADD_Budget_Amount';
export const ADD_Budget_Start_Date = 'ADD_Budget_Start_Date';
export const ADD_Budget_End_Date = 'ADD_Budget_End_Date';
export const Remove_Budget = 'Remove_Budget';
export const DV_Pacing_First_Type = 'DV_Pacing_First_Type';
export const DV_Pacing_Second_Type = 'DV_Pacing_Second_Type';
export const DV_Pacing_Daily_Price = 'DV_Pacing_Daily_Price';
export const DV_Goal_Type = 'DV_Goal_Type';
export const DV_Goal_Value = 'DV_Goal_Value';
export const DV_Optimization_Type = 'DV_Optimization_Type';
export const DV_Optimization_Action = 'DV_Optimization_Action';
export const DV_Optimization_Checked_Status = 'DV_Optimization_Checked_Status';
export const DV_Optimization_CPM_Value = 'DV_Optimization_CPM_Value';
export const DV_Optimization_Priortize_Deal = 'DV_Optimization_Priortize_Deal';
export const DV_Frequency_Cap_Type = 'DV_Frequency_Cap_Type';
export const DV_Selected_Frequencey_Value = 'DV_Selected_Frequencey_Value';
export const DV_Quality = 'DV_Quality';
export const DV_Public_Inventory = 'DV_Public_Inventory';
export const DV_Deals_And_Inventory_Packages =
  'DV_Deals_And_Inventory_Packages';
export const DV_Groups = 'DV_Groups';
export const DV_Selected_Frequencey_Limit_Type =
  'DV_Selected_Frequencey_Limit_Type';
export const DV_Selected_Frequencey_Limit_Value =
  'DV_Selected_Frequencey_Limit_Value';
export const DV_Insertion_Orders_Lists = 'DV_Insertion_Orders_Lists';
export const DV_Budget_Type = 'DV_Budget_Type';
export const ADD_Budget_Type = 'ADD_Budget_Type';
export const DV_Prefilled_Values = 'DV_Prefilled_Values';
export const RESET_INSERTION_STATE = 'RESET_INSERTION_STATE';
