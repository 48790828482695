import ListingGroupUploadFile from './ListingGroupUploadFile';

const ListingGroups = (props) => {
  const items = [
    { label: 'Use all products', value: 'true' },
    { label: 'Use a selection of products', value: 'false' },
  ];

  return (
    <div className='card-primary-repet'>
      <div className='card-primary'>
        <div className='card-header-top d-flex'>
          <div className='card-lt-pane'>
            <h5>Listing Groups</h5>
          </div>
        </div>
        <div className='card-body'>
          <div className='card-form form-panel'>
            <div className='row align-items-center'>
              <div className='action-btn-cont'>
                <div className='action-btn-both action-btn-img-tag row'>
                  <div className='col-12 px-0'>
                    <div className='form-check-box'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='Use all products'
                        id='Use all products'
                        value='true'
                        onChange={props?.handleSetValue}
                        checked={
                          (props?.getSelectedListingGroupType == 'true' &&
                            true) ||
                          (props?.getSelectedListingGroupType == true && true)
                        }
                      />
                      <label
                        className='form-check-label'
                        htmlFor='Use all products'
                      >
                        <div className='label-multitext'>
                          {'Use all products'}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className='col-12 px-0'>
                    <div className='form-check-box'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='Use a selection of products'
                        id='Use a selection of products'
                        value='false'
                        onChange={props?.handleSetValue}
                        checked={
                          (props?.getSelectedListingGroupType == 'false' &&
                            true) ||
                          (props?.getSelectedListingGroupType == false && true)
                        }
                      />
                      <label
                        className='form-check-label'
                        htmlFor='Use a selection of products'
                      >
                        <div className='label-multitext'>
                          {'Use a selection of products'}
                        </div>
                      </label>
                    </div>
                  </div>
                  {props?.getSelectedListingGroupType == false ||
                  props?.getSelectedListingGroupType == 'false' ? (
                    <ListingGroupUploadFile
                      handleUploadFile={props?.handleUploadFile}
                      getCSVFileInfo={props?.getCSVFileInfo}
                      handleDownloadFile={props?.handleDownloadFile}
                      getCurrentPageStatus={props?.getCurrentPageStatus}
                      handleDownloadProductListingSampleFile={
                        props?.handleDownloadProductListingSampleFile
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='media-btn'>
            <div className='file-error'>
              {props?.listingGroupError && (
                <span className='error' id='emailError'>
                  {props?.listingGroupError}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListingGroups;
