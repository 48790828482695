import InfoText from '../common/InfoText';
import ReactSelect from '../common/ReactSelect';
import { googleAdAdditionalFormatOptions } from '../../utils/constants';

const AdditionalFormats = (props) => {
  return (
    <>
      <InfoText
        copy={"Select all options to optimize your ad's reach and performance."}
      />
      <ReactSelect
        options={googleAdAdditionalFormatOptions}
        isMulti={true}
        closeMenuOnSelect={true}
        onChange={props?.handleSetValue}
        value={props?.value}
      />
    </>
  );
};

export default AdditionalFormats;
