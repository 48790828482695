import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RejectModel from './RejectModel';
import { postApi } from '../../utils/Apis';
import { baseUrlStg } from '../../utils/constants';
import { GoogleAdOptions } from '../../pages/googleAds/GoogleAdConstant';
import { DV360Options } from '../../pages/DV360/DV360Constant';

const ApproveReject = (props) => {
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [getRemark, setRemark] = useState('');
  const [requestStatus, setRequestStatus] = useState(false);

  const navigate = useNavigate();
  const handleAPICall = async (payload) => {
    try {
      props?.setDisplayLoader(true);
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/ApproveCampaign`,
        payload,
      );
      if (response?.status == 200) {
        if (requestStatus) {
          props?.setThankYouApprovalPopupShowStatus(true);
          props?.setDisplayLoader(false);
        } else {
          props?.platform == 'GOOGLE'
            ? navigate(GoogleAdOptions.Campaigns.url)
            : navigate(DV360Options.Campaigns.url);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const handleApproveRejectCall = (type) => {
    setShowRejectModel(false);
    let payload = {
      platform: props?.platform,
      campaignId: props?.campaignId,
      action:
        type == 'Rejected API'
          ? type?.replace('Rejected API', 'Rejected')
          : type == 'Approved API'
            ? type?.replace('Approved API', 'Approved')
            : type,
      remark: getRemark || '',
      link: window.location.href,
      publisherId: props?.getUserInfo?.Userid,
      token: '',
    };
    if (type == 'Approved') {
      handleAPICall(payload);
    } else if (type == 'Rejected') {
      setShowRejectModel(true);
    } else if (type == 'Approved Request') {
      setShowRejectModel(true);
      setRequestStatus(true);
    } else {
      handleAPICall(payload);
    }
  };

  const handleRemark = (e) => {
    setRemark(e?.target?.value);
  };

  return (
    <>
      <div class='short-right-cont'>
        <button
          class='btn-icon btn-icon-primary approve-act'
          type='button'
          data-bs-toggle='modal'
          // onClick={(e) => handleApproveRejectCall('Approved')}
          onClick={(e) => handleApproveRejectCall('Approved Request')}
        >
          <i class='icon-check-mark'></i> Approve
        </button>
        <button
          class='btn-icon btn-icon-primary reject-act'
          type='button'
          data-bs-toggle='modal'
          onClick={(e) => handleApproveRejectCall('Rejected')}
        >
          <i class='icon-close'></i> Reject
        </button>
      </div>
      <RejectModel
        handleRejectCall={(e) =>
          requestStatus
            ? handleApproveRejectCall('Approved API')
            : handleApproveRejectCall('Rejected API')
        }
        handleCloseModel={(e) => setShowRejectModel(false)}
        showRejectModel={showRejectModel}
        handleRemark={handleRemark}
        requestStatus={requestStatus}
      />
    </>
  );
};

export default ApproveReject;
