// import './App.css';
import '../../assets/css/style.css';

import Sidebar from '../../components/common/Sidebar';
// import FormHeader from '../components/common/FormHeader';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';

import Footer from '../../components/common/Footer';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';

import { useEffect, useState } from 'react';

import ReactDatePicker from 'react-datepicker';
import Accordion from 'react-bootstrap/Accordion';

import ReactSelect from '../../components/common/ReactSelect';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDescriptions,
  addHeadlines,
  removeDescriptions,
  removeHeadlines,
  setCalls,
  setDisplayPath_0,
  setDisplayPath_1,
  setFinalURL,
  setFormData,
  setLeadForms,
  setSiteLinks,
  updateAdName,
  updateBusinessName,
  updateDescriptions,
  updateHeadlines,
  setVideoId,
  setCallToAction,
  setVideoHeading,
  setVideoLongHeading,
  setVideoDescription,
  setFinalUrlSuffix,
  setIsMobileUrlEnabled,
  setFinalMobileUrl,
  setCompanionBannerType,
  setTrackingTemplate,
  setCompanionBannerId,
  setAdditionalFormatOptions,
  setIsEnabledCallToActionStatus,
  setAccentColor,
  setCustomMainColor,
  setShowThisAdStatus,
  setCustomColorStatusEnabled,
  setSelectedCreateType,
  setResetForm,
  addLongHeadlines,
  removeLongHeadlines,
  updateLongHeadlines,
  addShortDescriptions,
  removeShortDescriptions,
  updateShortDescriptions,
  setAppUrl,
  setPrefillValues,
  setFinalURLPerformance,
} from '../../state/googleAd/GoogleAdActions';
import MediaWidget from '../mediaLibrary/MediaWidgets/MediaWidget';
import { downloadFile, renderMedia } from '../../utils/utils';
import { getApi, postApi, putApi } from '../../utils/Apis';
import {
  baseUrlStg,
  googleV1Url,
  EnabledApproval,
} from '../../utils/constants';
import { multiSelectVal } from '../../utils/validations';
import { json, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  googleCampaignTypeList,
  googleObjectiveList,
} from '../../state/googleCreateCampaign/CreateCampaignReducer';
import CommonModal from '../../components/common/commonModal';
import Loader from '../../components/common/Loader';
import CompanionBanner from '../../components/google/CompanionBanner';
import AdUrlOption from '../../components/google/AdUrlOption';
import SingleInput from '../../components/google/SingleInput';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import DisplaySelectedWidgetItem from '../../components/google/DisplaySelectedWidgetItem';
import AdditionalFormats from '../../components/google/AdditionalFormats';
import MoreOptions from '../../components/google/MoreOptions';
import SideTreeMenuGoogle from '../../components/google/SideTreeMenuGoogle';
import ChooseCreateAdType from '../../components/google/ChooseCreateAdType';
import DisplaySelectedItemsInAccordian from '../../components/common/DisplaySelectedItemsInAccordian';
import { GoogleAdOptions } from './GoogleAdConstant';
import AppUrl from '../../components/googleCampaign/AppUrl';
import SideTreeMenuView from './Components/SideTreeMenuView';
import SelectedMediaWidgetItems from '../../components/google/SelectedMediaWidgetItems';
import ImageCardBody from '../../components/common/ImageCardBody';
import DisplaySelectedAccordianItems from '../../components/common/DisplaySelectedAccordianItems';
import SelectApprovalPublisher from '../../components/common/SelectApprovalPublisher';
import ApprovalConfirmationPopup from '../../components/common/ApprovalConfirmationPopup';
import ThankYouApprovalPopup from '../../components/common/ThankYouApprovalPopup';
import { ApproveReject } from '../../components/ApproveReject/ApproveReject';
import ListingGroups from '../../components/google/ListingGroups';
import InfoText from '../../components/common/InfoText';

// import Option from 'react-select/dist/declarations/src/components/Option';

function GoogleAd() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  // images
  const [images, setImages] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showSucessModal, setSucessModelStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state?.googleAd);
  const stateLoginReducer = useSelector((state) => state?.loginReducer);
  const getSideTreeInfo = useSelector(
    (state) => state?.sideTreeGoogleReducer?.SideTreeGoogle,
  );
  const getUserInfo = stateLoginReducer?.userToken;
  const navigate = useNavigate();

  // query params
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const location = useLocation();

  let query = useQuery();
  let productFeedStatus = query.get('productFeedsStatus');
  let campaignSubtype = query.get('campaign_subtype');
  const { id } = useParams();
  //Set Current page status, weather page is in create or edit case.
  let getCurrentPageStatus = typeof id != 'undefined' ? true : false;
  //states to manage hide and show of fields based on campaign type and objective type - starts
  const [showLongHeadlines, setShowLongHeadlines] = useState(false);
  const [showHeadlines, setShowHeadlines] = useState(false);
  const [showShortDescription, setShowShortDescription] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showCallToAction, setShowCallToAction] = useState(false);
  const [showSignals, setShowSignals] = useState(false);
  const [showLogos, setShowLogos] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [showHTML, setShowHTML] = useState(false);
  // Added by Prashant
  const [showPhoneNumber, setShowPhoneNumber] = useState(true);
  const [showLeadForm, setShowLeadForm] = useState(true);
  const [showImages, setShowImages] = useState(true);
  const [showBusinessName, setShowBusinessName] = useState(true);
  const [showBusinessLogo, setShowBusinessLogo] = useState(true);
  const [showSiteLinks, setShowSiteLinks] = useState(true);
  const [showCallOuts, setShowCallOuts] = useState(true);
  const [showMoreAssetTypes, setShowMoreAssetTypes] = useState(true);
  const [showPrices, setShowPrices] = useState(true);
  const [showStructuredSnippets, setShowStructuredSnippets] = useState(true);
  const [showCompanionBanner, setShowCompanionBanner] = useState(false);
  const [showAdUrlOption, setShowAdUrlOption] = useState(false);
  const [showSingleHeadLine, setShowSingleHeadLine] = useState(false);
  const [showSingleDescription, setShowSingleDescription] = useState(false);
  const [showSingleLongHeadLine, setshowSingleLongHeadLine] = useState(false);
  const [showAssignVideoMediaWidget, setShowAssignVideoMediaWidget] =
    useState(false);
  const [showHTMLUpload, setShowHTMLUpload] = useState(false);
  const [getSelectedHTMLItems, setSelectedHTMLItems] = useState([]);
  const [showAssignUploadImage, setShowAssignUploadImage] = useState(false);
  const [selectedVideoItem, setSelectedVideoItem] = useState([]);
  const [selectedImageItem, setSelectedImageItem] = useState([]);
  const [currentCampaignTypeName, setCurrentCampaignTypeName] = useState('');
  const [getTrackingTemplateLists, setTrackingTemplateLists] = useState([]);
  const [getCallToActionLists, setCallToActionLists] = useState([]);
  const [showAssignVideo, setShowAssignVideo] = useState(false);
  const [showAdditionalFormats, setShowAdditionalFormats] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showDisplayPath, setShowDisplayPath] = useState(true);
  const [showChooseAdCreateType, setShowChooseAdCreateType] = useState(false);
  const [showMediaCards, setShowMediaCards] = useState(false);
  const [getSelectedCardsImages, setSelectedCardsImages] = useState([]);

  const [showFinalUrl, setShowFinalUrl] = useState(true);
  const [getCurrentCampaignSubType, setCurrentCampaignSubType] = useState('');
  const [getSelectedCallToActionVal, setSelectedCallToActionVal] = useState(0);
  const [getSelectedTrackingItemLocalState, setSelectedTrackingItemLocalState] =
    useState({});
  const [getLocalAddtionalFomatsValue, setLocalAddtionalFomatsValue] = useState(
    [],
  );
  const [getLocalCallToActionVal, setLocalCallToActionVal] = useState([]);
  const [showLandScapeImage, setShowLandScapeImage] = useState(false);
  const [showSquareImage, setShowSquareImage] = useState(false);
  const [showLogoLandImage, setShowLogoLandImage] = useState(true);
  const [showSquareLogoImage, setShowSquareLogoImage] = useState(true);
  const [showPotraitImage, setShowPotraitImage] = useState(false);
  const [getGoogleAdCoverCarousel, setGoogleAdCoverCarousel] = useState([
    {
      coverCarouselId: 0,
      googleAdId: 0,
      medId: 0,
      heading: '',
      finalUrl: '',
      finalUrlMobile: '',
      callToAction: null,
      isFinalMobileUrlEnable: false,
      language: 'English',
      status: 1,
      landscapeImage: [],
      squareImage: [],
      potraitImage: [],
    },
  ]);
  const [master, setMaster] = useState([]);
  const [getCurrentSelectedImageIndex, setCurrentSelectedImageIndex] =
    useState('');
  const [
    getApprovalPublisherModelShowStatus,
    setApprovalPublisherModelShowStatus,
  ] = useState(false);
  const [
    getApprovalCoinfirmationPopupShowStatus,
    setApprovalCoinfirmationPopupShowStatus,
  ] = useState(false);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getPublisherInfo, setPublisherInfo] = useState([]);
  const [getPublisherError, setPublisherError] = useState('');
  const [getCreateStatus, setCreateStatus] = useState(false);
  const [getCreateId, setCreateID] = useState('');
  const [isPublisher, setIsPublisher] = useState(false);
  const { handleAPICall } = ApproveReject();
  const [getSelectedListingGroupType, setSelectedListingGroupType] =
    useState(false);
  const [getCSVFileInfo, setCSVFileInfo] = useState({
    CSVFile: '',
    CSVFileName: '',
  });
  const [showListingGroupsStatus, setShowListingGroupsStatus] = useState(true);
  let getCurrentCampaignId = query.get('campaign_type_id');
  const [getAtdStatus, setAtdStatus] = useState('');
  //states to manage hide and show of fields based on campaign type and objective type - ends
  //campaign type and objective type based condition check to hide and show fileds -starts
  // useEffect(() => {
  //   console.log('states', state);
  // });

  //add by Dipak
  const [campaignId, setCampaignId] = useState('');
  const [googleAdId, setGoogleAdId] = useState('');
  useEffect(() => {
    if (query.get('campaign_id')) setCampaignId(query.get('campaign_id'));
  }, [query]);

  useEffect(() => {
    switch (query.get('objective_id')) {
      case `${googleObjectiveList?.Sales?.objective_id}`: //sales
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
              {
                setShowHeadlines(true);
                setShowDescription(true);
                setShowApps(true);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(false);
                setShowSignals(false);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setCurrentCampaignSubType('');
                setShowAdditionalFormats(false);
                setShowAdUrlOption(true);
                setShowImages(false);
                setShowBusinessLogo(false);
                setShowLandScapeImage(false);
                setShowSquareImage(false);
                setShowLogoLandImage(false);
                setShowSquareLogoImage(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            case `${googleCampaignTypeList?.['Performance Max']?.campaign_type_id}`: //performance max
              {
                setShowHeadlines(true);
                setShowDescription(false);
                setShowApps(false);
                setShowLongHeadlines(true);
                setShowShortDescription(true);
                setShowCallToAction(true);
                setShowSignals(false);
                setShowLogos(false);
                setShowBusinessLogo(false);
                setShowVideos(true);
                setShowHTML(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Performance Max']
                    ?.campaign_type_name,
                );
                setCurrentCampaignSubType('');
                setShowLandScapeImage(true);
                setShowSquareImage(true);
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowPotraitImage(true);
                setShowListingGroupsStatus(true);
              }
              break;
            case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand gen
              {
                setShowImages(false);
                setShowHeadlines(false);
                // setShowDescription(false);
                setShowApps(false);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(true);
                setShowAdUrlOption(true);
                setShowSignals(true);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setShowDisplayPath(false);
                setShowCallOuts(false);
                setShowPhoneNumber(false);
                setShowLeadForm(false);
                setShowSiteLinks(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowChooseAdCreateType(true);
                setShowBusinessLogo(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Demand Gen']?.campaign_type_name,
                );
                setShowMediaCards(true);
                setCurrentCampaignSubType('');
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowListingGroupsStatus(false);
              }
              break;
            case `${googleCampaignTypeList?.['Shopping']?.campaign_type_id}`: //Shopping
              {
                setShowFinalUrl(false);
                setShowImages(false);
                setShowHeadlines(false);
                setShowApps(false);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(false);
                setShowAdUrlOption(false);
                setShowSignals(false);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setShowDisplayPath(false);
                setShowCallOuts(false);
                setShowPhoneNumber(false);
                setShowLeadForm(false);
                setShowSiteLinks(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowChooseAdCreateType(false);
                setShowBusinessLogo(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Shopping']?.campaign_type_name,
                );
                setShowMediaCards(false);
                setCurrentCampaignSubType('');
                setShowLogoLandImage(false);
                setShowSquareLogoImage(false);
                setShowLandScapeImage(false);
                setShowSquareImage(false);
                setShowPotraitImage(false);
                setShowBusinessName(false);
                setShowDescription(false);
                setShowListingGroupsStatus(true);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.Leads?.objective_id}`: //Leads
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
              {
                setShowImages(false);
                setShowHeadlines(true);
                setShowDescription(true);
                setShowApps(true);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(false);
                setShowSignals(false);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setCurrentCampaignSubType('');
                setShowAdditionalFormats(false);
                setShowAdUrlOption(true);
                setShowLandScapeImage(false);
                setShowSquareImage(false);
                setShowLogoLandImage(false);
                setShowSquareLogoImage(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;

            case `${googleCampaignTypeList?.['Video']?.campaign_type_id}`: //Video
              {
                setShowPhoneNumber(false);
                setShowLeadForm(false);
                setShowHeadlines(false);
                setShowDescription(false);
                setShowImages(false);
                setShowApps(false);
                setShowBusinessName(false);
                setShowBusinessLogo(false);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setShowSiteLinks(false);
                setShowCallOuts(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowShortDescription(false);
                setShowLongHeadlines(false);
                setShowCallToAction(true);
                setShowCompanionBanner(true);
                setShowAdUrlOption(true);
                setShowSingleHeadLine(true);
                setShowSingleDescription(true);
                setshowSingleLongHeadLine(true);
                setShowAssignVideo(true);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Video']?.campaign_type_name,
                );
                setCurrentCampaignSubType('');
                setShowLandScapeImage(false);
                setShowSquareImage(false);
                setShowLogoLandImage(false);
                setShowSquareLogoImage(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            case `${googleCampaignTypeList?.['Display']?.campaign_type_id}`: //Display
              {
                setShowLeadForm(false);
                setShowPhoneNumber(false);
                setShowBusinessLogo(false);
                setShowApps(false);
                setShowHTML(false);
                setShowSiteLinks(false);
                setShowCallOuts(false);
                setShowLongHeadlines(false);
                setShowCallToAction(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowShortDescription(false);
                setShowMoreOptions(true);
                setShowHeadlines(true);
                setShowDescription(true);
                setshowSingleLongHeadLine(true);
                setShowLogos(false);
                setShowVideos(true);
                setShowAdUrlOption(true);
                setShowAdditionalFormats(true);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Display']?.campaign_type_name,
                );
                setCurrentCampaignSubType('');
                setShowLandScapeImage(true);
                setShowSquareImage(true);
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowImages(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Website Traffic']?.objective_id}`: //Website traffic
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.['Display']?.campaign_type_id}`: //Display
              {
                setShowLeadForm(false);
                setShowPhoneNumber(false);
                setShowBusinessLogo(false);
                setShowApps(false);
                setShowHTML(false);
                setShowSiteLinks(false);
                setShowCallOuts(false);
                setShowLongHeadlines(false);
                setShowCallToAction(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowShortDescription(false);
                setShowMoreOptions(true);
                setShowHeadlines(true);
                setShowDescription(true);
                setshowSingleLongHeadLine(true);
                setShowLogos(false);
                setShowVideos(true);
                setShowAdUrlOption(true);
                setShowAdditionalFormats(true);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Display']?.campaign_type_name,
                );
                setCurrentCampaignSubType('');
                setShowLandScapeImage(true);
                setShowSquareImage(true);
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowImages(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
              {
                setShowImages(false);
                setShowHeadlines(true);
                setShowDescription(true);
                setShowApps(true);
                setShowAdUrlOption(true);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(false);
                setShowSignals(false);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setCurrentCampaignSubType('');
                setShowAdditionalFormats(false);
                setShowAdUrlOption(true);
                setShowLandScapeImage(false);
                setShowSquareImage(false);
                setShowLogoLandImage(false);
                setShowSquareLogoImage(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand gen
              {
                setShowImages(false);
                setShowHeadlines(false);
                // setShowDescription(false);
                setShowApps(false);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(true);
                setShowAdUrlOption(true);
                setShowSignals(true);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setShowDisplayPath(false);
                setShowCallOuts(false);
                setShowPhoneNumber(false);
                setShowLeadForm(false);
                setShowSiteLinks(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowChooseAdCreateType(true);
                setShowBusinessLogo(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Demand Gen']?.campaign_type_name,
                );
                setShowMediaCards(true);
                setCurrentCampaignSubType('');
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowListingGroupsStatus(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Brand awareness and reach']?.objective_id}`: //Brand awareness and reach
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.['Display']?.campaign_type_id}`: //Display
              {
                setShowLeadForm(false);
                setShowPhoneNumber(false);
                setShowBusinessLogo(false);
                setShowApps(false);
                setShowHTML(false);
                setShowSiteLinks(false);
                setShowCallOuts(false);
                setShowLongHeadlines(false);
                setShowCallToAction(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowShortDescription(false);
                setShowMoreOptions(true);
                setShowHeadlines(true);
                setShowDescription(true);
                setshowSingleLongHeadLine(true);
                setShowLogos(false);
                setShowVideos(true);
                setShowAdUrlOption(true);
                setShowAdditionalFormats(true);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Display']?.campaign_type_name,
                );
                setCurrentCampaignSubType('');
                setShowLandScapeImage(true);
                setShowSquareImage(true);
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowImages(false);
                setShowPotraitImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Local store visits and promotion']?.objective_id}`: //Local store visits and promotion
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.['Performance Max']?.campaign_type_id}`: //Performance Max
              {
                setShowHeadlines(true);
                setShowDescription(false);
                setShowApps(false);
                setShowLongHeadlines(true);
                setShowShortDescription(true);
                setShowCallToAction(true);
                setShowSignals(false);
                setShowLogos(false);
                setShowBusinessLogo(false);
                setShowVideos(true);
                setShowHTML(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Performance Max']
                    ?.campaign_type_name,
                );
                setCurrentCampaignSubType('');
                setShowLandScapeImage(true);
                setShowSquareImage(true);
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowPotraitImage(true);
                setShowListingGroupsStatus(true);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['App promotion']?.objective_id}`: //App promotion
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.['App']?.campaign_type_id}`: //App
              {
                setShowLeadForm(false);
                setShowPhoneNumber(false);
                setShowBusinessLogo(false);
                setShowApps(false);
                setShowHTML(true);
                setShowSiteLinks(false);
                setShowCallOuts(false);
                setShowLongHeadlines(false);
                setShowCallToAction(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowShortDescription(false);
                setShowMoreOptions(false);
                setShowHeadlines(true);
                setShowDescription(true);
                setshowSingleLongHeadLine(false);
                setShowLogos(false);
                setShowVideos(true);
                setShowAdUrlOption(false);
                setShowAdditionalFormats(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['App']?.campaign_type_name,
                );
                setShowFinalUrl(false);
                setShowDisplayPath(false);
                setShowBusinessName(false);
                setCurrentCampaignSubType(campaignSubtype);
                setShowImages(false);
                setShowLandScapeImage(true);
                setShowSquareImage(true);
                setShowPotraitImage(true);
                setShowLogoLandImage(false);
                setShowSquareLogoImage(false);
                setShowListingGroupsStatus(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Product and brand consideration']?.objective_id}`: //Product and brand consideration
        {
          switch (query.get('campaign_type_id')) {
            case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand gen
              {
                setShowImages(false);
                setShowHeadlines(false);
                // setShowDescription(false);
                setShowApps(false);
                setShowLongHeadlines(false);
                setShowShortDescription(false);
                setShowCallToAction(true);
                setShowAdUrlOption(true);
                setShowSignals(true);
                setShowLogos(false);
                setShowVideos(false);
                setShowHTML(false);
                setShowDisplayPath(false);
                setShowCallOuts(false);
                setShowPhoneNumber(false);
                setShowLeadForm(false);
                setShowSiteLinks(false);
                setShowMoreAssetTypes(false);
                setShowPrices(false);
                setShowStructuredSnippets(false);
                setShowSignals(false);
                setShowChooseAdCreateType(true);
                setShowBusinessLogo(false);
                setCurrentCampaignTypeName(
                  googleCampaignTypeList?.['Demand Gen']?.campaign_type_name,
                );
                setShowMediaCards(true);
                setCurrentCampaignSubType('');
                setShowLogoLandImage(true);
                setShowSquareLogoImage(true);
                setShowListingGroupsStatus(false);
              }
              break;
            default:
          }
        }
        break;
      default:
    }
  });
  //campaign type and objective type based condition check to hide and show fileds -ends

  const finalURL_handler = (e) => {
    dispatch(setFinalURL(e));
  };

  const DisplayPath_0 = (e) => {
    // if(e?.target?.value?.length <= 4)
    dispatch(setDisplayPath_0(e));
  };
  const DisplayPath_1 = (e) => {
    dispatch(setDisplayPath_1(e));
  };

  const handleCalls = (e, index) => {
    // console.log("calls",e?.target?.checked,index)
    dispatch(setCalls(e?.target?.checked, index));
  };
  const handleLeadForms = (e, index) => {
    // console.log("leads",e?.target?.checked,index)
    dispatch(setLeadForms(e?.target?.checked, index));
  };
  const handleSiteLink = (e, index) => {
    // console.log("siteLinks",e?.target?.checked,index)
    dispatch(setSiteLinks(e?.target?.checked, index));
  };

  const [campaignTreeData, setCampaignTreeData] = useState([]);

  const [selectedCall, setSelectedCall] = useState('');
  const [selectedCallVal, setSelectedCallVal] = useState('');
  const [selectedLeadForm, setSelectedLeadForm] = useState('');
  const [selectedLeadFormVal, setSelectedLeadFormVal] = useState('');
  const [selectedSiteLinks, setSelectedSiteLinks] = useState('');
  const [getSelectedSiteLinksVal, setSelectedSiteLinksVal] = useState('');
  const [selectedCallOuts, setSelectedCallOuts] = useState('');
  const [selectedCallOutsVal, setSelectedCallOutsVal] = useState('');
  const [selectedPromotions, setSelectedPromotions] = useState('');
  const [selectedPromotionsVal, setSelectedPromotionsVal] = useState('');
  const [selectedPrices, setSelectedPrices] = useState('');
  const [selectedPricesVal, setSelectedPricesVal] = useState('');
  const [selectedStructuredsnippets, setSelectedStructuredsnippets] =
    useState('');
  const [selectedStructuredsnippetsVal, setSelectedStructuredsnippetsVal] =
    useState('');
  const [selectedApps, setSelectedApps] = useState('');
  const [selectedAppsVal, setSelectedAppsVal] = useState('');
  const selctedCalls = (data) => {
    setSelectedCall(multiSelectVal(data));
    setSelectedCallVal(data);
  };
  const selctedSiteLinks = (data) => {
    setSelectedSiteLinks(multiSelectVal(data));
    setSelectedSiteLinksVal(data);
  };
  const selctedLeadForm = (data) => {
    setSelectedLeadForm(data?.value);
    setSelectedLeadFormVal(data);
  };
  const selctedCallOuts = (data) => {
    setSelectedCallOuts(multiSelectVal(data));
    setSelectedCallOutsVal(data);
  };
  const selctedPromotions = (data) => {
    setSelectedPromotions(multiSelectVal(data));
    setSelectedPromotionsVal(data);
  };
  const selctedPrices = (data) => {
    setSelectedPrices(multiSelectVal(data));
    setSelectedPricesVal(data);
  };
  const selctedStructuredsnippets = (data) => {
    setSelectedStructuredsnippets(multiSelectVal(data));
    setSelectedStructuredsnippetsVal(data);
  };
  const selctedApps = (data) => {
    setSelectedApps(multiSelectVal(data));
    setSelectedAppsVal(data);
  };

  const handleUpdatePost = async (payload) => {
    try {
      const response = await putApi(
        `${googleV1Url}/updateGoogleAds`,
        '',
        payload,
      );
      if (response?.status == 200) {
        if (EnabledApproval) {
          setApprovalPublisherModelShowStatus(true);
        } else {
          setLoader(false);
          setSucessModelStatus(true);
        }
      }
      // console.log('payload', JSON?.stringify(payload));
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setFormErrors(validate());
    const imageIds = [];
    let logoIds = '';
    let landscapeImage = '';
    let squareImage = '';
    let landscapeLogo = '';
    let squareLogo = '';
    let potraitImage = '';
    let htmlIds = [];
    // Loop through the media data and extract mediaId values

    for (let i = 0; i < images.length; i++) {
      if (images[i]?.medId) {
        imageIds.push(images[i]?.medId);
      } else {
        imageIds.push(images[i]?.mediaId);
      }
    }

    // Get selected businessLogos info and set ids only.
    for (let i = 0; i < businessLogo.length; i++) {
      if (businessLogo[i]?.medId) {
        logoIds += `${businessLogo[i]?.medId},`;
      } else {
        logoIds += `${businessLogo[i]?.mediaId},`;
      }
    }

    // Get Selected videos IDs and append it to the image state.
    for (let i = 0; i < selectedVideoItem.length; i++) {
      if (selectedVideoItem[i].medId) {
        imageIds.push(selectedVideoItem[i].medId);
      } else {
        imageIds.push(selectedVideoItem[i].mediaId);
      }
    }

    // Set Additional Formats value
    let additionalFormatsArrays = [];
    getLocalAddtionalFomatsValue?.map((item) => {
      additionalFormatsArrays.push(item?.value);
    });

    // Set landscapeImage
    for (let i = 0; i < getLandScapeImages.length; i++) {
      if (getLandScapeImages[i]?.medId) {
        landscapeImage += `${getLandScapeImages[i]?.medId},`;
      } else {
        landscapeImage += `${getLandScapeImages[i]?.mediaId},`;
      }
    }

    // Set squareImage
    for (let i = 0; i < getMediaSquareImages.length; i++) {
      if (getMediaSquareImages[i]?.medId) {
        squareImage += `${getMediaSquareImages[i]?.medId},`;
      } else {
        squareImage += `${getMediaSquareImages[i]?.mediaId},`;
      }
    }

    // Set landscapeLogo
    for (let i = 0; i < getLogoLandScapeImages.length; i++) {
      if (getLogoLandScapeImages[i]?.medId) {
        landscapeLogo += `${getLogoLandScapeImages[i]?.medId},`;
      } else {
        landscapeLogo += `${getLogoLandScapeImages[i]?.mediaId},`;
      }
    }

    // Set squareLogo
    for (let i = 0; i < getSquareLogoImages.length; i++) {
      if (getSquareLogoImages[i]?.medId) {
        squareLogo += `${getSquareLogoImages[i]?.medId},`;
      } else {
        squareLogo += `${getSquareLogoImages[i]?.mediaId},`;
      }
    }

    // Set Potrait image
    for (let i = 0; i < getPotraitImages.length; i++) {
      if (getPotraitImages[i]?.medId) {
        potraitImage += `${getPotraitImages[i]?.medId},`;
      } else {
        potraitImage += `${getPotraitImages[i]?.mediaId},`;
      }
    }

    //Extract Images Ids and set it to the existing array.
    // console.log('getGoogleAdCoverCarousel1', getGoogleAdCoverCarousel);
    let mappingNewCards = getGoogleAdCoverCarousel?.map((item, index) => {
      let getLandscapeImageId = item?.landscapeImage?.[0]?.medId ||
        item?.landscapeImage?.[0]?.mediaId || [''];
      let getsquareImageId = item?.squareImage?.[0]?.medId ||
        item?.squareImage?.[0]?.mediaId || [''];
      let getpotraitImageId = item?.potraitImage?.[0]?.medId ||
        item?.potraitImage?.[0]?.mediaId || [''];
      let callToAction =
        item?.callToAction?.value || item?.callToAction?.toString() || '8';
      return {
        ...item,
        medId: 0,
        callToAction: callToAction,
        landscapeImage: getLandscapeImageId?.toString(),
        squareImage: getsquareImageId?.toString(),
        potraitImage: getpotraitImageId?.toString(),
      };
    });

    // console.log('getGoogleAdCoverCarousel2', mappingNewCards);

    //get selected HTML ids and set to the HTML variable.
    for (let i = 0; i < getSelectedHTMLItems.length; i++) {
      if (getSelectedHTMLItems[i].medId) {
        htmlIds.push(getSelectedHTMLItems[i].medId);
      } else {
        htmlIds.push(getSelectedHTMLItems[i].mediaId);
      }
    }

    setTimeout(async () => {
      if (Object.keys(validate()).length == 0) {
        setLoader(true);
        // Payload in create & update case.
        let payload = {
          googleAdId: state?.googleAdId || 0,
          finalUrl: state?.final_url || '',
          displayPath: state?.display_path_0,
          displayPathDomain: state?.display_path_1,
          heading:
            currentCampaignTypeName == 'Video' ||
            state?.creativeType == 'Products' ||
            state?.creativeType == 'Carousel'
              ? [state?.heading?.[state?.heading?.length - 1]]
              : state?.head_lines,
          description:
            currentCampaignTypeName == 'Performance Max'
              ? state?.short_descriptions?.slice(1)
              : currentCampaignTypeName == 'Video' ||
                  state?.creativeType == 'Products' ||
                  state?.creativeType == 'Carousel'
                ? [state?.description?.[state?.description?.length - 1]]
                : state?.descriptions,
          descriptionShort:
            currentCampaignTypeName == 'Performance Max'
              ? [state?.short_descriptions[0]]
              : [],
          imageIds: imageIds,
          // imageIds: ["imageIds"],
          businessName: state?.business_name,
          businessLogoPath:
            currentCampaignTypeName == 'Display' ||
            currentCampaignTypeName == 'Performance Max'
              ? logoIds?.replace(/,\s*$/, '')
              : businessLogo[0]?.mediaId || businessLogo[0]?.medId,
          // businessLogoPath: "string",
          leadFormsIds: [selectedLeadForm],
          siteLinksIds: [selectedSiteLinks],
          promotionTypeIds: [selectedPromotions],
          priceTypeIds: [selectedPrices],
          structuredSnippetTypeIds: [selectedStructuredsnippets],
          locationTypeIds: ['1'],
          appTypeIds: [selectedApps],
          callTypeIds: [selectedCall],
          googleAdGroupId: query.get('ad_groupId'),
          adName: state?.ad_name,
          //extra added for now demo
          headingLong:
            currentCampaignTypeName == 'Video' ||
            currentCampaignTypeName == 'Display'
              ? [state?.headingLong?.[state?.headingLong?.length - 1]]
              : currentCampaignTypeName == 'Performance Max'
                ? state?.long_head_lines
                : [state?.headingLong?.[state?.headingLong?.length - 1]],

          html5: htmlIds,
          signals: '',
          // companionBannerType: '',
          companionBannerId: state?.companionBannerId,
          displayPath1: state?.display_path_0,
          displayPath2: state?.display_path_1,
          // Params added by Prashant in Video & display ad case start
          callToAction: getLocalCallToActionVal?.value,
          trackingTemplate: getSelectedTrackingItemLocalState?.label,
          finalUrlSuffix: state?.finalUrlSuffix,
          isfinalmobileurlEnabled: state?.isfinalmobileurlEnabled,
          finalUrlMobile: state?.finalUrlMobile,
          companionBannerType: state?.companionBannerType,
          additionalFormatOptions: additionalFormatsArrays,
          calloutTypeIds: [selectedCallOuts],
          iscalltoactionEnabled: state?.iscalltoactionEnabled,
          customMainColor: state?.customMainColor,
          customAccentColor: state?.customAccentColor,
          iscolorEnabled: state?.iscolorEnabled,
          iscustomcolorEnabled: state?.iscustomcolorEnabled,
          creativeType:
            currentCampaignTypeName == 'Demand Gen'
              ? state?.creativeType || 'Image'
              : '',
          googleAdCoverCarousel: mappingNewCards,
          appUrl: state?.appUrl,
          // Params added by Prashant in Video & display ad case end
          createdBy: getUserInfo?.Userid,
          atdStatus:
            EnabledApproval && getCurrentPageStatus
              ? 'draft'
              : EnabledApproval
                ? 'draft'
                : 'In Moderation',
          status: true,
          updatedOn: typeof id != 'undefined' ? new Date() : new Date(),
          updatedBy: getUserInfo?.Userid,
          landscapeImage: landscapeImage?.replace(/,\s*$/, ''),
          squareImage: squareImage?.replace(/,\s*$/, ''),
          potraitImage: potraitImage?.replace(/,\s*$/, ''),
          landscapeLogo: landscapeLogo?.replace(/,\s*$/, ''),
          squareLogo: squareLogo?.replace(/,\s*$/, ''),
          isAllProductsEnabled: getSelectedListingGroupType || false,
        };

        //Check the selected type and based on that set the state.
        if (
          getSelectedListingGroupType == 'true' ||
          getSelectedListingGroupType == true
        ) {
          setCSVFileInfo({
            ...getCSVFileInfo,
            CSVFile: '',
            CSVFileName: '',
          });
        }

        //Condition Would call in the shopping type campaign.
        // if (
        //   (getCurrentCampaignId == 4 &&
        //     getCSVFileInfo?.CSVFileName?.length > 0 &&
        //     getSelectedListingGroupType == 'false') ||
        //   (getCurrentCampaignId == 2 &&
        //     getCSVFileInfo?.CSVFileName?.length > 0 &&
        //     getSelectedListingGroupType == 'false')
        // ) {
        //   handleUploadFileAPICall(getCSVFileInfo?.CSVFile);
        // }

        if (typeof id != 'undefined') {
          //Update API function call
          handleUpdatePost(payload);
        } else {
          // Create API function call
          // console.log('create-payload', JSON.stringify(payload));
          // if (getCurrentCampaignId == 4) {
          //   //Condition Would call in the shopping type campaign.
          //   handleUploadFileAPICall(getCSVFileInfo?.CSVFile);
          // }
          let response = await postApi(
            `${googleV1Url}/createGoogleAds`,
            payload,
            {
              accept: '*/',
            },
          );
          if (response?.data?.status == 200) {
            if (EnabledApproval) {
              setApprovalPublisherModelShowStatus(true);
              setCreateStatus(true);
              setCreateID(response?.data?.googleAdId);
            } else {
              setLoader(false);
              setShowModal(true);
              dispatch(setResetForm());
              dispatch(setAppUrl(''));
              handleLocalStateComponentReset();
            }
          } else {
            setLoader(false);
          }
        }
      }
    }, 100);
  };

  const handleAddHeadlines = () => {
    if (state?.head_lines?.length <= 14) dispatch(addHeadlines());
  };
  const handleAddLongHeadlines = () => {
    if (state?.long_head_lines?.length <= 4) dispatch(addLongHeadlines());
  };

  const handleHeadlines = (e, index) => {
    dispatch(
      updateHeadlines({
        data: e,
        index,
      }),
    );
  };
  const handleLongHeadlines = (e, index) => {
    dispatch(
      updateLongHeadlines({
        data: e,
        index,
      }),
    );
  };
  const handleAddDescriptions = () => {
    if (currentCampaignTypeName == 'Demand Gen') {
      if (state?.descriptions?.length <= 4) dispatch(addDescriptions());
    } else {
      if (state?.descriptions?.length <= 3) dispatch(addDescriptions());
    }
  };
  const handleAddShortDescriptions = () => {
    if (state?.short_descriptions?.length <= 4)
      dispatch(addShortDescriptions());
  };

  const handleDescriptions = (e, index) => {
    dispatch(
      updateDescriptions({
        data: e,
        index,
      }),
    );
  };
  const handleShortDescriptions = (e, index) => {
    dispatch(
      updateShortDescriptions({
        data: e,
        index,
      }),
    );
  };
  const businessNameHandler = (e) => {
    dispatch(updateBusinessName(e));
  };
  const handleAdName = (e) => {
    dispatch(updateAdName(e));
  };

  const handleDelHeadlines = (index) => {
    dispatch(removeHeadlines(index));
  };
  const handleDelLongHeadlines = (index) => {
    dispatch(removeLongHeadlines(index));
  };
  const handleDelDescriptions = (index) => {
    dispatch(removeDescriptions(index));
  };
  const handleDelShortDescriptions = (index) => {
    dispatch(removeShortDescriptions(index));
  };

  const formatDataForReactSelect = (optionList, label, id) => {
    let formatedData = optionList?.map((item) => {
      let option = {};
      option.label = item[label];
      option.value = item[id];
      return option;
    });
    // console.log('ress', formatedData);
    return formatedData;
  };
  const formatDataLeads = (options) => {
    // console.log('lead', options);
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      // option.label = `${item['heading']} - ${item['businessName']} - ${[
      //   item['description'],
      // ]}`;
      option.label = `${item['name']}`;
      return option;
    });
    // console.log("for",formattedOptions)
    return formattedOptions;
  };
  const formatDataSiteLinks = (options) => {
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      option.label = `${item['name']} - ${item['finalUrls']}`;
      return option;
    });
    return formattedOptions;
  };
  const formatDataCallouts = (options) => {
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      option.label = `${item['name']}`;
      return option;
    });
    return formattedOptions;
  };
  const formatDataPromotions = (options) => {
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      option.label = `${item['name']}`;
      return option;
    });
    return formattedOptions;
  };
  const formatDataPrices = (options) => {
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      option.label = `${item['name']}`;
      return option;
    });
    return formattedOptions;
  };
  const formatDataApps = (options) => {
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      option.label = `${item['name']}`;
      return option;
    });
    return formattedOptions;
  };
  const formatDataStructuredSnippets = (options) => {
    let formattedOptions = options?.map((item) => {
      let option = {};
      option.value = item['id'];
      option.label = `${item['name']}`;
      // let values = ""
      // for (let i = 0; i < Object.keys(item).length; i++)
      // {
      //   values += `${item["value"+i]}? ${item["value"+i]}:""`
      // }
      // option.values = values;
      return option;
    });
    return formattedOptions;
  };

  const [businessLogo, setBusinessLogo] = useState([]);
  const [logoDimension, setLogoDimension] = useState({ height: 0, width: 0 });
  const [logoerror, setLogoError] = useState('');

  const validate = () => {
    const errors = {};

    let finalURL = state?.final_url;
    if (currentCampaignTypeName != 'Performance Max') {
      let regex = /^(https?:\/\/)?(www\.)?[^\s/$.?#]+\.[^\s]*$/i;
      if (finalURL == '' && showFinalUrl)
        errors.finalURL = 'Please enter the final URL';
      else if (!regex.test(finalURL) && showFinalUrl) {
        errors.finalURL = 'Please enter the valid final URL';
      }
    }
    //ad name
    if (!state?.ad_name?.trim()) {
      errors.adName = 'Please enter the ad name';
    }

    //images
    // if (currentCampaignTypeName != 'Demand Gen') {
    //   if (images?.length == 0 && currentCampaignTypeName != 'Video') {
    //     errors.images = 'Please Select Atleast one Image';
    //   }
    // }
    //business name
    if (!state?.business_name?.trim() && showBusinessName) {
      errors.businessName = 'Please enter business name';
    }
    // headlines validations
    if (showLongHeadlines || showHeadlines) {
      let headlines = state?.head_lines;
      for (let i = 0; i < 3; i++) {
        if (headlines[i]?.length <= 0)
          errors.minHeadlines = 'Minimum 3 headlines are required.';
      }
      for (let i = 0; i < headlines?.length - 1; i++) {
        for (let j = i + 1; j < headlines?.length; j++) {
          if (headlines[i] == headlines[j])
            if (headlines[i] != '')
              errors.repeatedHeadlines = 'Headlines can not be same.';
        }
      }
    }

    // Longheadlines validations
    if (currentCampaignTypeName != 'Video') {
      if (showLongHeadlines) {
        let Longheadlines = state?.long_head_lines;
        for (let i = 0; i < 1; i++) {
          if (Longheadlines[i]?.length <= 0)
            errors.minLongHeadlines = 'Minimum 1 long headline is required.';
        }
        for (let i = 0; i < Longheadlines?.length - 1; i++) {
          for (let j = i + 1; j < Longheadlines?.length; j++) {
            if (Longheadlines[i] == Longheadlines[j])
              if (Longheadlines[i] != '')
                errors.repeatedLongHeadlines =
                  'Long headlines can not be same.';
          }
        }
      }
    }

    // Single long heading validation
    if (
      currentCampaignTypeName == 'Display' ||
      currentCampaignTypeName == 'Video'
    ) {
      if (state?.headingLong?.length == 0) {
        errors.singleHeadingError = 'Please enter the heading';
      } else if (
        state?.headingLong?.[state?.headingLong?.length - 1]?.length > 90
      ) {
        errors.singleHeadingError = 'Value to long';
      }
    }

    // descriptions validations
    if (
      currentCampaignTypeName != 'Video' ||
      currentCampaignTypeName != 'Demand Gen'
    ) {
      if (showDescription) {
        let descriptions = state?.descriptions;
        for (let i = 0; i < 2; i++) {
          if (descriptions[i]?.length <= 0)
            errors.minDescriptions = 'Minimum 2 descriptions are required.';
        }
        for (let i = 0; i < descriptions?.length - 1; i++) {
          for (let j = i + 1; j < descriptions?.length; j++) {
            if (descriptions[i] == descriptions[j])
              if (descriptions[i] != '')
                errors.repeatedDescriptions = 'Descriptions can not be same.';
          }
        }
      }
    }
    // short descriptions validations
    if (currentCampaignTypeName != 'Video') {
      if (showShortDescription) {
        let descriptions = state?.short_descriptions;
        for (let i = 0; i < 2; i++) {
          if (descriptions[i]?.length <= 0)
            errors.minShortDescriptions =
              'Minimum 2 descriptions are required.';
        }
        for (let i = 0; i < descriptions?.length - 1; i++) {
          for (let j = i + 1; j < descriptions?.length; j++) {
            if (descriptions[i] == descriptions[j])
              if (descriptions[i] != '')
                errors.repeatedShortDescriptions =
                  'Descriptions can not be same.';
          }
        }
      }
    }

    //Validate LandScape image field
    if (showLandScapeImage && getLandScapeImages?.length == 0) {
      errors.landScapeError = 'Please select atleast one image';
    }

    if (showSquareImage && getMediaSquareImages?.length == 0) {
      errors.squareImageError = 'Please select atleast one image';
    }

    if (
      showLogoLandImage &&
      getLogoLandScapeImages?.length == 0 &&
      getSquareLogoImages?.length == 0
    ) {
      errors.logoError = 'Please select atleast landScape logo or square logo';
    }

    if (
      showSquareLogoImage &&
      getLogoLandScapeImages?.length == 0 &&
      getSquareLogoImages?.length == 0
    ) {
      errors.logoError = 'Please select atleast landScape logo or square logo';
    }

    if (state?.creativeType == 'Carousel') {
      let getGoogleAdCoverCarouselItems = getGoogleAdCoverCarousel;
      for (let i = 0; i < getGoogleAdCoverCarouselItems?.length; i++) {
        if (
          getGoogleAdCoverCarouselItems[i].landscapeImage?.length <= 0 &&
          getGoogleAdCoverCarouselItems[i].squareImage?.length <= 0
        )
          errors.cardImagesError =
            'Please select atleast 1 image between landscape and square.';
      }
    }

    //Validate Listing groups for the shopping type.
    // if (getCurrentCampaignId == 4) {
    //   if (getSelectedListingGroupType == '') {
    //     errors.listingGroupError = 'Please Select Atleast one option';
    //   }
    // }

    // if (
    //   getCurrentCampaignSubType != 'App_Pre_Registration' &&
    //   getCurrentCampaignSubType != ''
    // ) {
    //   errors.appUrlError = 'Please add app URL';
    // }

    //Check the selected logos length.
    let getSelectedLogosCount =
      getLogoLandScapeImages?.length + getSquareLogoImages?.length;

    if (
      (currentCampaignTypeName == 'Demand Gen' &&
        state?.creativeType == 'Video' &&
        getSelectedLogosCount > 1) ||
      (currentCampaignTypeName == 'Demand Gen' &&
        state?.creativeType == 'Carousel' &&
        getSelectedLogosCount > 1)
    ) {
      errors.logoError =
        'Please upload upto 1 logo, including landscape and square images.';
    }

    if (
      (currentCampaignTypeName == 'Display' && getSelectedLogosCount > 5) ||
      (currentCampaignTypeName == 'Performance Max' &&
        getSelectedLogosCount > 5) ||
      (currentCampaignTypeName == 'Demand Gen' && getSelectedLogosCount > 5)
    ) {
      errors.logoError =
        'Please upload upto 5 logos, including landscape and square images.';
    }

    if (
      currentCampaignTypeName == 'Display' &&
      getLandScapeImages?.length + getMediaSquareImages?.length > 15
    ) {
      errors.landScapeError =
        'Please upload upto 15 images, including landscape and square images.';
      errors.squareImageError =
        'Please upload upto 15 images, including landscape and square images.';
    }

    if (
      (currentCampaignTypeName == 'Performance Max' &&
        getLandScapeImages?.length +
          getMediaSquareImages?.length +
          getPotraitImages?.length >
          20) ||
      (currentCampaignTypeName == 'App' &&
        getLandScapeImages?.length +
          getMediaSquareImages?.length +
          getPotraitImages?.length >
          20) ||
      (currentCampaignTypeName == 'Demand Gen' &&
        getLandScapeImages?.length +
          getMediaSquareImages?.length +
          getPotraitImages?.length >
          20)
    ) {
      errors.landScapeError =
        'Please upload upto 20 images, including landscape, square and potrait images.';
      errors.squareImageError =
        'Please upload upto 20 images, including landscape, square and potrait images.';
      errors.potraitImageError =
        'Please upload upto 20 images, including landscape, square and potrait images.';
    }

    console.log('err', errors);
    return errors;
  };
  // useEffect(() => {
  //   console.log('googleAd', images, state);
  // }, [images]);

  // media widget logic
  const [showMediaWidget, setShowMediaWidget] = useState(false);
  const [showMediaLandScapeWidget, setShowLandScapeMediaWidget] =
    useState(false);
  const [showMediaSquareImageWidget, setShowMediaSquareMediaWidget] =
    useState(false);
  const [showMediaLogoLandScapeWidget, setShowLogoLandScapeMediaWidget] =
    useState(false);
  const [showMediaSqaureLogoWidget, setShowSqaureLogoMediaWidget] =
    useState(false);
  const [showMediaCardLandScapeWidget, setShowCardLandScapeMediaWidget] =
    useState(false);
  const [showMediaCardSquareImageWidget, setShowCardSquareImageMediaWidget] =
    useState(false);
  const [showMediaCardPotraitImageWidget, setShowCardPotraitImageMediaWidget] =
    useState(false);
  const [showMediaPotraitWidget, setShowPotraitMediaWidget] = useState(false);
  const [getLandScapeImages, setLandScapeImages] = useState([]);
  const [getCardLandScapeImages, setCardLandScapeImages] = useState([]);
  const [getMediaSquareImages, setMediaSquareImages] = useState([]);
  const [getLogoLandScapeImages, setLogoLandScapeImages] = useState([]);
  const [getPotraitImages, setPotraitImages] = useState([]);
  const [getSquareLogoImages, setSquareLogoImages] = useState([]);
  const [getSelectedImagesCount, setSelectedImageCount] = useState(0);
  const [getSelectedLogosCount, setSelectedLogosCount] = useState(0);

  const [showMediaWidgetBusinessLogo, setShowMediaWidgetBusinessLogo] =
    useState(false);
  const [showMediaWidgetCards, setShowMediaWidgetCards] = useState(false);

  const openMediaWidget = () => {
    setShowMediaWidget(true);
  };

  // Landscape functions start
  const openLandScapeWidget = () => {
    setShowLandScapeMediaWidget(true);
  };

  const handleCloseMediaWidgetLandScape = (newImages) => {
    if (newImages && newImages.length > 0) {
      setLandScapeImages(() => [...newImages]);
    }
    setShowLandScapeMediaWidget(false);
  };
  // Landscape functions end

  // Square image functions start
  const openSquareWidget = () => {
    setShowMediaSquareMediaWidget(true);
  };

  const handleCloseMediaWidgetSquare = (newImages) => {
    if (newImages && newImages.length > 0) {
      setMediaSquareImages(() => [...newImages]);
    }
    setShowMediaSquareMediaWidget(false);
  };
  // Square image functions end

  // Logo Land scape image functions start
  const openLogoLandScapeWidget = () => {
    setShowLogoLandScapeMediaWidget(true);
  };

  const handleCloseMediaWidgetLogoLandScape = (newImages) => {
    if (newImages && newImages.length > 0) {
      setLogoLandScapeImages(() => [...newImages]);
    }
    setShowLogoLandScapeMediaWidget(false);
  };
  // Logo Land scape image functions end

  // Logo Square image functions start
  const openSquareLogoWidget = () => {
    setShowSqaureLogoMediaWidget(true);
  };

  const handleCloseMediaWidgetSquareLogo = (newImages) => {
    if (newImages && newImages.length > 0) {
      setSquareLogoImages(() => [...newImages]);
    }
    setShowSqaureLogoMediaWidget(false);
  };
  // Logo Square image functions end

  // Potrait image functions start
  const openPotraitWidget = () => {
    setShowPotraitMediaWidget(true);
  };

  const handleCloseMediaWidgetPotrait = (newImages) => {
    if (newImages && newImages.length > 0) {
      setPotraitImages(() => [...newImages]);
    }
    setShowPotraitMediaWidget(false);
  };
  // Potrait image functions end

  // Card Landscape functions start
  const openCardLandScapeWidget = (index) => {
    setShowCardLandScapeMediaWidget(true);
    setCurrentSelectedImageIndex(index);
  };

  const handleCloseMediaWidgetCardLandScape = (newImages) => {
    if (newImages && newImages.length > 0) {
      // console.log('newImage', newImages);
      insertCardItems(
        getCurrentSelectedImageIndex,
        'landscapeImage',
        newImages,
      );
    }
    setShowCardLandScapeMediaWidget(false);
    setCurrentSelectedImageIndex('');
  };
  // Card Landscape functions end

  // Card Landscape functions start
  const openCardSquareImageWidget = (index) => {
    setShowCardSquareImageMediaWidget(true);
    setCurrentSelectedImageIndex(index);
  };

  const handleCloseMediaWidgetCardSquareImage = (newImages) => {
    if (newImages && newImages.length > 0) {
      // console.log('newImage', newImages);
      insertCardItems(getCurrentSelectedImageIndex, 'squareImage', newImages);
    }
    setShowCardSquareImageMediaWidget(false);
    setCurrentSelectedImageIndex('');
  };
  // Card Landscape functions end

  // Card Potrait functions start
  const openCardPotraitImageWidget = (index) => {
    setShowCardPotraitImageMediaWidget(true);
    setCurrentSelectedImageIndex(index);
  };

  const handleCloseMediaWidgetCardPotraitImage = (newImages) => {
    if (newImages && newImages.length > 0) {
      // console.log('newImage', newImages);
      insertCardItems(getCurrentSelectedImageIndex, 'potraitImage', newImages);
    }
    setShowCardPotraitImageMediaWidget(false);
    setCurrentSelectedImageIndex('');
  };
  // Card Potrait functions end

  const handleCloseMediaWidget = (newImages) => {
    if (newImages && newImages.length > 0) {
      setImages(() => [...newImages]);
    }
    setShowMediaWidget(false);
  };

  const handleCloseMediaWidgetBusinessLogo = (newImages) => {
    if (newImages && newImages.length > 0) {
      setBusinessLogo(() => [...newImages]);
    }
    setShowMediaWidgetBusinessLogo(false);
  };

  useEffect(() => {
    dispatch(setFormData());
  }, []);

  useEffect(() => {
    //Set Land Scape true if the Creative type is empty.
    if (state?.creativeType == '' || state?.creativeType == 'Image') {
      setShowLandScapeImage(true);
      setShowPotraitImage(true);
      setShowSquareImage(true);
    } else {
      setShowLandScapeImage(false);
      setShowPotraitImage(false);
      setShowSquareImage(false);
    }
    if (
      state?.creativeType != 'Products' &&
      state?.creativeType != 'Carousel'
    ) {
      setShowDescription(true);
    } else {
      setShowDescription(false);
    }
  }, [state?.creativeType]);

  // useEffect(() => {
  //   console.log(
  //     'www',
  //     query.get(`objective_id`),
  //     query.get(`campaign_type_id`),
  //   );
  // });

  // Functions added by Prashant
  const openMediaWidgetVideoCampaign = (e) => {
    e.preventDefault();
    setShowAssignVideoMediaWidget(true);
  };
  const openMediaWidgetHTML = (e) => {
    e.preventDefault();
    setShowHTMLUpload(true);
  };

  const handleCloseMediaWidgetVideoCampign = (newImages) => {
    if (currentCampaignTypeName == 'Video') {
      if (newImages && newImages.length > 0) {
        setSelectedVideoItem(() => [...newImages]);
      }
    } else {
      if (newImages?.length > 0) {
        setSelectedVideoItem(newImages);
        dispatch(setVideoId(newImages?.[0]?.mediaId));
      } else {
        setSelectedVideoItem([]);
      }
    }
    setShowAssignVideoMediaWidget(false);
  };
  const handleCloseMediaWidgetHTML = (newImages) => {
    if (newImages && newImages.length > 0) {
      setSelectedHTMLItems(() => [...newImages]);
    }
    setShowHTMLUpload(false);
  };

  const openMediaWidgetVideoUploadImage = (e) => {
    e.preventDefault();
    setShowAssignUploadImage(true);
  };

  const handleCloseMediaWidgetUploadImage = (newImages) => {
    setShowAssignUploadImage(false);
    if (currentCampaignTypeName == 'Video') {
      if (newImages && newImages.length > 0) {
        setSelectedImageItem(() => [...newImages]);
      }
    } else {
      setSelectedImageItem(newImages);
      dispatch(setCompanionBannerId(newImages?.[0]?.mediaId));
    }
  };

  const handleCallToAction = (e) => {
    dispatch(setCallToAction(e?.value));
    setLocalCallToActionVal(e);
  };

  const handleSetHeadLineVal = (e) => {
    dispatch(setVideoHeading(e?.target?.value));
  };

  const handleSetLongHeadLineVal = (e) => {
    console.log('value', e?.target?.value);
    dispatch(setVideoLongHeading(e?.target?.value));
  };

  const handleSetDescriptionVal = (e) => {
    dispatch(setVideoDescription(e?.target?.value));
  };

  const handleSetTrackingTemplate = (e) => {
    dispatch(setTrackingTemplate(e?.label));
    setSelectedTrackingItemLocalState(e);
    // console.log('---pp', e);
  };

  const handleSetFinalUrlValue = (e) => {
    dispatch(setFinalUrlSuffix(e?.target?.value));
  };

  const handleSetIsMobileValue = (e) => {
    dispatch(setIsMobileUrlEnabled(e?.target?.checked));
  };

  const handleSetFinalMobileUrlValue = (e) => {
    dispatch(setFinalMobileUrl(e?.target?.value));
  };

  const handleSetCompanionBannerValue = (e) => {
    dispatch(setCompanionBannerType(e?.target?.value));
  };

  const handleGetCallToActionItems = async () => {
    let response = await getApi(`${googleV1Url}/getMasters`);
    setMaster(response?.data);
    try {
      let data = response?.data?.callToActions?.map((item) => {
        return { label: item?.name, value: item?.id };
      });
      setCallToActionLists(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetTrackingTemplateList = async (cID) => {
    console.log('handleSetTrackingTemplateList cId', cID);
    if (typeof cID !== 'undefined' || cID !== null) {
      let response = await getApi(
        `${baseUrlStg}/api/Taxonomy/GetCidListByAddIdOrCampaignId?campaignId=${cID}`,
      );
      try {
        let data = response?.map((item) => {
          return { label: item?.landingUrl, value: item?.cidId };
        });
        setTrackingTemplateLists(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    // let cId = query?.get('taxonomyCapmId');
    // handleSetTrackingTemplateList(cId);
    handleGetCallToActionItems();
  }, []);

  useEffect(() => {
    let cId = query?.get('taxonomyCapmId');
    if (cId) {
      handleSetTrackingTemplateList(cId);
    }
  }, [location]);
  const handleSetAdditionalFormat = (e) => {
    if (e?.length > 0) {
      dispatch(setAdditionalFormatOptions(e));
    }
    setLocalAddtionalFomatsValue(e);
  };

  const handleIsEnabledCallToActionValue = (e) => {
    dispatch(setIsEnabledCallToActionStatus(e?.target?.checked));
  };

  const handleIsCustomColorEnabledValue = (e) => {
    dispatch(setCustomColorStatusEnabled(e?.target?.checked));
  };

  const handleSetCustomMainColor = (e) => {
    dispatch(setCustomMainColor(e?.target?.value));
  };

  const handleSetAccentColor = (e) => {
    dispatch(setAccentColor(e?.target?.value));
  };

  const handleSetIsCustomColorEnabled = (e) => {
    dispatch(setShowThisAdStatus(e?.target?.checked));
  };

  const handleSetSelectedTypeVal = (e) => {
    dispatch(setSelectedCreateType(e?.target?.value));
  };

  const handleCloseMediaCards = (newImages) => {
    if (newImages && newImages.length > 0) {
      setSelectedCardsImages(() => [...newImages]);
    }
    setShowMediaWidgetCards(false);
  };

  let initialCards = {
    coverCarouselId: 1,
    googleAdId: 0,
    medId: 12,
    medFileName: '',
    medPath: '',
    medThumbnailPath: '',
    heading: '',
    finalUrl: '',
    isFinalMobileUrlEnable: false,
    finalUrlMobile: '',
    callToAction: '',
    language: 'English',
    landscapeImage: [],
    squareImage: [],
    potraitImage: [],
    status: 1,
  };

  // console.log('getSelectedCardsImages1', getSelectedCardsImages);
  const mappingSelectedCards = getSelectedCardsImages?.map((item) => {
    // console.log('item---', item);
    return {
      ...initialCards,
      coverCarouselId: typeof id != 'undefined' ? item?.coverCarouselId : 0,
      googleAdId: typeof id != 'undefined' ? item?.googleAdId : 0,
      medId:
        typeof id != 'undefined' ? item?.mediaId || item?.medId : item?.mediaId,
      medPath: typeof id != 'undefined' ? item?.medPath : '',
      mediaName: typeof id != 'undefined' ? item?.mediaName : '',
      medThumbnailPath: typeof id != 'undefined' ? item?.medThumbnailPath : '',
      heading: item?.heading || '',
      finalUrl: item?.finalUrl || '',
      isFinalMobileUrlEnable: item?.isFinalMobileUrlEnable || false,
      finalUrlMobile: item?.finalUrlMobile || '',
      callToAction: typeof id != 'undefined' ? item?.callToAction : 8,
      language: 'English',
      status: typeof id != 'undefined' ? item?.status : 1,
      landscapeImage: typeof id != 'undefined' ? item?.landscapeImage : [],
      squareImage: typeof id != 'undefined' ? item?.squareImage : [],
      potraitImage: typeof id != 'undefined' ? item?.potraitImage : [],
    };
  });
  // console.log('mappingSelectedCards', mappingSelectedCards);

  //side tree menu starts
  const handleSideTreeDataAndUpdate = async () => {
    if (query.get('campaign_id') && typeof id == undefined) {
      // New create case
      let response;
      if (query.get('campaign_id')) {
        response = await getApi(
          `${baseUrlStg}/api/v1/Google/GetGoogleCampaignWithAddGroupAndAdById?id=${query.get(
            'campaign_id',
          )}`,
          { accessToken: '1234' },
        );
      }
      try {
        if (response?.response?.length > 0) {
          setCampaignTreeData(response?.response);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (typeof id != 'undefined' || typeof id != undefined) {
      // Update or Edit case
      let response = await getApi(
        `${baseUrlStg}/api/GoogleListingDetails/GetAdsDetails?adId=${id}`,
      );

      if (response?.status) {
        setCampaignId(response?.response?.campaignId);
        setGoogleAdId(response?.response?.googleAdDetails?.googleAdId);
        // console.log('response', response?.response);
        dispatch(setPrefillValues(response?.response));
        // Set Logo & Video Ids
        setBusinessLogo(response?.response?.businessLogoData);
        let filterImages = response?.response?.imageMediaData?.filter((v) => {
          return v?.medType == 'Image';
        });
        let filterYotube = response?.response?.imageMediaData?.filter((v) => {
          return v?.medType == 'Youtube';
        });
        setAtdStatus(response?.response?.googleAdDetails?.atdStatus);
        setImages(filterImages);
        setSelectedVideoItem(filterYotube);
        //Set tracking template value
        setSelectedTrackingItemLocalState(
          response?.response?.trackingTemplateUrl,
        );

        // Set Additional format value
        let additionalFormatOptions =
          response?.response?.googleAdDetails?.additionalFormatOptions?.map(
            (item) => {
              return { label: item, value: item };
            },
          );

        setLocalAddtionalFomatsValue(additionalFormatOptions);

        //Set Call To Action Value
        let getSelectedCalltoAction = {
          label: response?.response?.callToAction?.value,
          value: response?.response?.callToAction?.id,
        };
        setLocalCallToActionVal(getSelectedCalltoAction);

        //Set Sitelinks values
        setSelectedSiteLinksVal(
          response?.response?.googleAdDetails?.siteLinksIds,
        );

        //Set Lead Form Values
        setSelectedLeadFormVal(
          response?.response?.googleAdDetails?.leadFormsIds,
        );

        //Set Calls values
        setSelectedCallVal(response?.response?.googleAdDetails?.callTypeIds);

        //Set Promotions Values
        setSelectedPromotionsVal(
          response?.response?.googleAdDetails?.promotionTypeIds,
        );

        //Set CallOuts Values
        setSelectedCallOutsVal(
          response?.response?.googleAdDetails?.calloutTypeIds,
        );

        //Set Prices Value
        setSelectedPricesVal(response?.response?.googleAdDetails?.priceTypeIds);

        //Set Structuredsnippets Values
        setSelectedStructuredsnippetsVal(
          response?.response?.googleAdDetails?.structuredSnippetTypeIds,
        );

        //Set APP values
        setSelectedAppsVal(response?.response?.googleAdDetails?.appTypeIds);

        //Set Cards values

        let getSelectedCards =
          response?.response?.googleAdDetails?.googleAdCoverCarousel?.map(
            (item) => {
              return {
                ...initialCards,
                coverCarouselId: item?.coverCarouselId,
                googleAdId: item?.googleAdId,
                mediaName: item?.medId?.[0]?.medFileName,
                medId: item?.medId?.[0]?.medId,
                medPath: item?.medId?.[0]?.medPath,
                medThumbnailPath: item?.medId?.[0]?.medThumbnailPath,
                heading: item?.heading || '',
                finalUrl: item?.finalUrl || '',
                isFinalMobileUrlEnable: item?.isFinalMobileUrlEnable || false,
                finalUrlMobile: item?.finalUrlMobile || '',
                callToAction: item?.callToAction,
                language: 'English',
                status: item?.status,
                landscapeImage: item?.landscapeImage || [],
                squareImage: item?.squareImage || [],
                potraitImage: item?.potraitImage || [],
              };
            },
          );

        setSelectedCardsImages(getSelectedCards);

        //Set LandScape values
        setLandScapeImages(response?.response?.googleAdDetails?.landscapeImage);
        //Set Square image
        setMediaSquareImages(response?.response?.googleAdDetails?.squareImage);
        //Set LandScape Logo values
        setLogoLandScapeImages(
          response?.response?.googleAdDetails?.landscapeLogo,
        );
        //Set Square Logo images
        setSquareLogoImages(response?.response?.googleAdDetails?.squareLogo);
        //Set Potrait Images
        setPotraitImages(response?.response?.googleAdDetails?.potraitImage);
        //Set New Cards Values
        setGoogleAdCoverCarousel(
          response?.response?.googleAdDetails?.googleAdCoverCarousel,
        );

        //Set Listing group type
        setSelectedListingGroupType(
          response?.response?.googleAdDetails?.isAllProductsEnabled,
        );
        //Set CSV file name
        setCSVFileInfo({
          ...getCSVFileInfo,
          CSVFileName: response?.response?.productFileName || '',
        });

        //Set HTML response data
        setSelectedHTMLItems(response?.response?.googleAdDetails?.html5);

        const getProductFeedStatus = `&productFeedsStatus=${
          getSideTreeInfo?.campaignSetting?.productFeeds == 1 ? true : false
        }`;

        navigate(
          `?objective_id=${response?.response?.objectiveId}&campaign_type_id=${
            response?.response?.campaignTypeId
          }&ad_groupId=${
            response?.response?.googleAdDetails?.googleAdGroupId
          }&taxonomyCapmId=${
            response?.response?.taxonomyCampaignId
          }&campaign_id=${response?.response?.campaignId}&campaign_subtype=${
            response?.response?.campaign_subtype || ''
          }${getProductFeedStatus}`,
        );
      }
    } else {
      setCampaignTreeData([]);
    }
  };

  useEffect(() => {
    if (typeof id == undefined || typeof id == 'undefined') {
      handleSideTreeDataAndUpdate();
    }
  }, []);

  useEffect(() => {
    handleSideTreeDataAndUpdate();
    dispatch(setFormData());
  }, [id]);
  //side tree menu ends
  const handlesetAppUrlVal = (e) => {
    dispatch(setAppUrl(e?.target?.value));
  };

  const handledeleteLogoItem = (medId) => {
    let getAfterDeletedVal = businessLogo?.filter(
      (item) => item?.medId != medId,
    );
    setBusinessLogo(getAfterDeletedVal);
  };

  const handledeleteVideoItem = (medId) => {
    let getAfterDeletedVal;
    if (currentCampaignTypeName == 'Demand Gen') {
      getAfterDeletedVal = selectedVideoItem?.filter(
        (item) => item?.mediaId != medId,
      );
    } else {
      getAfterDeletedVal = selectedVideoItem?.filter(
        (item) => item?.medId != medId,
      );
    }
    setSelectedVideoItem(getAfterDeletedVal);
  };

  const handleRedirection = () => {
    navigate(GoogleAdOptions?.Campaigns?.url);
  };

  //Check the images count and hide add image button accordingly
  useEffect(() => {
    if (showLandScapeImage && showSquareImage) {
      setSelectedImageCount(
        getLandScapeImages?.length + getMediaSquareImages?.length,
      );
    }
  }, [getLandScapeImages, getMediaSquareImages]);

  //Check the Logos count and hide add logos button accordingly
  useEffect(() => {
    if (showLogoLandImage && showSquareLogoImage) {
      setSelectedLogosCount(
        getSquareLogoImages?.length + getLogoLandScapeImages?.length,
      );
    }
  }, [getSquareLogoImages, getLogoLandScapeImages]);

  //New Cards Functions
  const handleAddCardsObj = () => {
    let data = {
      coverCarouselId: 0,
      googleAdId: 0,
      medId: 0,
      heading: '',
      finalUrl: '',
      finalUrlMobile: '',
      callToAction: null,
      isFinalMobileUrlEnable: false,
      language: 'English',
      status: 1,
      landscapeImage: [],
      squareImage: [],
      potraitImage: [],
    };
    setGoogleAdCoverCarousel((getGoogleAdCoverCarousel) => [
      ...getGoogleAdCoverCarousel,
      data,
    ]);
  };

  const insertCardItems = (index, field, data) => {
    const updatedData = [...getGoogleAdCoverCarousel]; // Create a copy of the original array
    updatedData[index][field] = data; // Update the specified field of the object at the given index
    setGoogleAdCoverCarousel(updatedData); // Update the state with the modified array
  };

  const handleRemoveCardImage = (index, field, data) => {
    let getFilterdItems = getGoogleAdCoverCarousel?.filter(
      (item) => item?.status == 1,
    );
    const updatedData = [...getFilterdItems]; // Create a copy of the original array
    updatedData[index][field] = ''; // Update the specified field of the object at the given index
    setGoogleAdCoverCarousel(updatedData); // Update the state with the modified array
  };

  const handleRemoveCard = (index) => {
    let items = getGoogleAdCoverCarousel?.filter((item) => item?.status == 1);
    let item = getGoogleAdCoverCarousel[index];
    item = { ...item, status: 0 };
    items = [...items.slice(0, index), item, ...items.slice(index + 1)];
    setGoogleAdCoverCarousel(items);
  };

  useEffect(() => {
    if (typeof id == 'undefined') {
      dispatch(setResetForm());
      dispatch(setAppUrl(''));
      handleLocalStateComponentReset();
    }
    setFormErrors({});
  }, [location]);

  //Save Ad As Draft functions
  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    setFormErrors(validateOnSaveDraft());
    const imageIds = [];
    let logoIds = '';
    let landscapeImage = '';
    let squareImage = '';
    let landscapeLogo = '';
    let squareLogo = '';
    let potraitImage = '';
    // Loop through the media data and extract mediaId values

    for (let i = 0; i < images.length; i++) {
      if (images[i]?.medId) {
        imageIds.push(images[i]?.medId);
      } else {
        imageIds.push(images[i]?.mediaId);
      }
    }

    // Get selected businessLogos info and set ids only.
    for (let i = 0; i < businessLogo.length; i++) {
      if (businessLogo[i]?.medId) {
        logoIds += `${businessLogo[i]?.medId},`;
      } else {
        logoIds += `${businessLogo[i]?.mediaId},`;
      }
    }

    // Get Selected videos IDs and append it to the image state.
    for (let i = 0; i < selectedVideoItem.length; i++) {
      if (selectedVideoItem[i].medId) {
        imageIds.push(selectedVideoItem[i].medId);
      } else {
        imageIds.push(selectedVideoItem[i].mediaId);
      }
    }

    // Set Additional Formats value
    let additionalFormatsArrays = [];
    getLocalAddtionalFomatsValue?.map((item) => {
      additionalFormatsArrays.push(item?.value);
    });

    // Set landscapeImage
    for (let i = 0; i < getLandScapeImages.length; i++) {
      if (getLandScapeImages[i]?.medId) {
        landscapeImage += `${getLandScapeImages[i]?.medId},`;
      } else {
        landscapeImage += `${getLandScapeImages[i]?.mediaId},`;
      }
    }

    // Set squareImage
    for (let i = 0; i < getMediaSquareImages.length; i++) {
      if (getMediaSquareImages[i]?.medId) {
        squareImage += `${getMediaSquareImages[i]?.medId},`;
      } else {
        squareImage += `${getMediaSquareImages[i]?.mediaId},`;
      }
    }

    // Set landscapeLogo
    for (let i = 0; i < getLogoLandScapeImages.length; i++) {
      if (getLogoLandScapeImages[i]?.medId) {
        landscapeLogo += `${getLogoLandScapeImages[i]?.medId},`;
      } else {
        landscapeLogo += `${getLogoLandScapeImages[i]?.mediaId},`;
      }
    }

    // Set squareLogo
    for (let i = 0; i < getSquareLogoImages.length; i++) {
      if (getSquareLogoImages[i]?.medId) {
        squareLogo += `${getSquareLogoImages[i]?.medId},`;
      } else {
        squareLogo += `${getSquareLogoImages[i]?.mediaId},`;
      }
    }

    // Set Potrait image
    for (let i = 0; i < getPotraitImages.length; i++) {
      if (getPotraitImages[i]?.medId) {
        potraitImage += `${getPotraitImages[i]?.medId},`;
      } else {
        potraitImage += `${getPotraitImages[i]?.mediaId},`;
      }
    }

    //Extract Images Ids and set it to the existing array.
    // console.log('getGoogleAdCoverCarousel1', getGoogleAdCoverCarousel);
    let mappingNewCards = getGoogleAdCoverCarousel?.map((item, index) => {
      let getLandscapeImageId = item?.landscapeImage?.[0]?.medId ||
        item?.landscapeImage?.[0]?.mediaId || [''];
      let getsquareImageId = item?.squareImage?.[0]?.medId ||
        item?.squareImage?.[0]?.mediaId || [''];
      let getpotraitImageId = item?.potraitImage?.[0]?.medId ||
        item?.potraitImage?.[0]?.mediaId || [''];

      return {
        ...item,
        medId: 0,
        callToAction: item?.callToAction?.value,
        landscapeImage: getLandscapeImageId?.toString(),
        squareImage: getsquareImageId?.toString(),
        potraitImage: getpotraitImageId?.toString(),
      };
    });

    setTimeout(async () => {
      if (Object.keys(validateOnSaveDraft()).length == 0) {
        try {
          setLoader(true);
          let payload = {
            googleAdId: state?.googleAdId || 0,
            finalUrl: state?.final_url || '',
            displayPath: state?.display_path_0,
            displayPathDomain: state?.display_path_1,
            heading:
              currentCampaignTypeName == 'Video' ||
              state?.creativeType == 'Products' ||
              state?.creativeType == 'Carousel'
                ? [state?.heading?.[state?.heading?.length - 1]]
                : state?.head_lines,

            description:
              currentCampaignTypeName == 'Performance Max'
                ? state?.short_descriptions?.slice(1)
                : currentCampaignTypeName == 'Video' ||
                    state?.creativeType == 'Products' ||
                    state?.creativeType == 'Carousel'
                  ? [state?.description?.[state?.description?.length - 1]]
                  : state?.descriptions,

            descriptionShort:
              currentCampaignTypeName == 'Performance Max'
                ? [state?.short_descriptions[0]]
                : [],
            imageIds: imageIds,
            // imageIds: ["imageIds"],
            businessName: state?.business_name,
            businessLogoPath:
              currentCampaignTypeName == 'Display' ||
              currentCampaignTypeName == 'Performance Max'
                ? logoIds?.replace(/,\s*$/, '')
                : businessLogo[0]?.mediaId || businessLogo[0]?.medId,
            // businessLogoPath: "string",
            leadFormsIds: [selectedLeadForm],
            siteLinksIds: [selectedSiteLinks],
            promotionTypeIds: [selectedPromotions],
            priceTypeIds: [selectedPrices],
            structuredSnippetTypeIds: [selectedStructuredsnippets],
            locationTypeIds: ['1'],
            appTypeIds: [selectedApps],
            callTypeIds: [selectedCall],
            googleAdGroupId: query.get('ad_groupId'),
            adName: state?.ad_name,
            //extra added for now demo
            headingLong:
              currentCampaignTypeName == 'Video' ||
              currentCampaignTypeName == 'Display'
                ? [state?.headingLong?.[state?.headingLong?.length - 1]]
                : currentCampaignTypeName == 'Performance Max'
                  ? state?.long_head_lines
                  : [state?.headingLong?.[state?.headingLong?.length - 1]],

            html5: [],
            signals: '',
            // companionBannerType: '',
            companionBannerId: state?.companionBannerId,
            displayPath1: state?.display_path_0,
            displayPath2: state?.display_path_1,
            // Params added by Prashant in Video & display ad case start
            callToAction: getLocalCallToActionVal?.value,
            trackingTemplate: getSelectedTrackingItemLocalState?.label,
            finalUrlSuffix: state?.finalUrlSuffix,
            isfinalmobileurlEnabled: state?.isfinalmobileurlEnabled,
            finalUrlMobile: state?.finalUrlMobile,
            companionBannerType: state?.companionBannerType,
            additionalFormatOptions: additionalFormatsArrays,
            calloutTypeIds: [selectedCallOuts],
            iscalltoactionEnabled: state?.iscalltoactionEnabled,
            customMainColor: state?.customMainColor,
            customAccentColor: state?.customAccentColor,
            iscolorEnabled: state?.iscolorEnabled,
            iscustomcolorEnabled: state?.iscustomcolorEnabled,
            creativeType: state?.creativeType,
            googleAdCoverCarousel: mappingNewCards,
            appUrl: state?.appUrl,
            // Params added by Prashant in Video & display ad case end
            createdBy: getUserInfo?.Userid,
            atdStatus:
              EnabledApproval && typeof id != 'undefined'
                ? 'draft'
                : state?.atdStatus,
            createdOn: new Date(),
            status: true,
            updatedBy: typeof id != 'undefined' ? state?.updatedBy || 4 : 0,
            landscapeImage: landscapeImage?.replace(/,\s*$/, ''),
            squareImage: squareImage?.replace(/,\s*$/, ''),
            potraitImage: potraitImage?.replace(/,\s*$/, ''),
            landscapeLogo: landscapeLogo?.replace(/,\s*$/, ''),
            squareLogo: squareLogo?.replace(/,\s*$/, ''),
          };
          // console.log('saveDarftpayload', payload);
          setLoader(false);
          let response = await postApi(`${googleV1Url}/SaveAdsDraft`, payload, {
            accessToken: '2323',
          });
          // console.log('savedraftresponse', response);
          if (
            response != null &&
            typeof id != 'undefined' &&
            response?.data?.status == 200
          ) {
            setSucessModelStatus(true);
            navigate(`/google-ad/ads/edit/${response?.data?.['googleAdID']}`);
          } else {
            setSucessModelStatus(true);
            navigate(`/google-ad/ads/edit/${response?.data?.googleAdsID}`);
          }
        } catch (err) {
          console.log('errr', err);
        }
      }
    }, 20);
  };

  const validateOnSaveDraft = () => {
    const errors = {};
    //ad name
    if (!state?.ad_name?.trim()) {
      errors.adName = 'Please Enter the Ad name';
    }
    return errors;
  };

  const handleApprovalPublisher = () => {
    if (getPublisherInfo?.length == 0) {
      setPublisherError('Please Choose atleast one option');
    } else {
      setApprovalCoinfirmationPopupShowStatus(true);
      setApprovalPublisherModelShowStatus(false);
      setPublisherError('');
    }
  };

  const handleApprovalPublisherRequest = async () => {
    try {
      let campId = getCurrentPageStatus ? campaignId : query.get('campaign_id');

      let payload = {
        platform: 'GOOGLE',
        userId: getUserInfo?.Userid,
        publisherId: getPublisherInfo?.value,
        campaignId: campId,
        link: `${window.location.origin}/google-ad/campaignsView/${campId}`,
      };

      setLoader(true);
      setApprovalCoinfirmationPopupShowStatus(false);
      // console.log('API', payload);
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/SendToPublisherApproval`,
        payload,
      );

      if (response?.status) {
        if (isPublisher) {
          setLoader(true);
          setApprovalCoinfirmationPopupShowStatus(false);
          let campaignId = query.get('campaign_id');
          handleAPICall('GOOGLE', campaignId, getPublisherInfo?.value).then(
            (res) => {
              if (res == 200) {
                setThankYouApprovalPopupShowStatus(true);
              }
            },
          );
        } else {
          setThankYouApprovalPopupShowStatus(true);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSetPublisherId = (e) => {
    if (e == null) {
      setPublisherInfo([]);
      setIsPublisher(false);
    } else {
      setPublisherInfo(e);
      e?.value == getUserInfo?.Userid && setIsPublisher(true);
    }
  };

  const handleCloseModel = () => {
    if (getCreateStatus) {
      navigate(`${GoogleAdOptions.Ads.editUrl}/${getCreateId}`);
    }
    setApprovalPublisherModelShowStatus(false);
    setApprovalCoinfirmationPopupShowStatus(false);
    setLoader(false);
    setPublisherInfo([]);
    setIsPublisher(false);
  };
  const GoogleSideTree = useSelector(
    (state) => state?.sideTreeGoogleReducer?.SideTreeGoogle,
  );

  useEffect(() => {
    if (
      googleCampaignTypeList?.['Performance Max']?.campaign_type_id ==
        GoogleSideTree?.campaignTypeId &&
      GoogleSideTree?.merchantCenterAccount
    ) {
      const selectedMerchant = GoogleSideTree?.merchantCenterAccount;
      const selectedObject = master?.merchentCenterAccount?.find(
        (item) => item.id == selectedMerchant,
      );

      if (selectedObject) {
        dispatch(setFinalURLPerformance(selectedObject?.urlFinal));
      }
    }
  }, [GoogleSideTree?.merchantCenterAccount, master]);

  const handleSetValue = (e) => {
    setSelectedListingGroupType(e?.target?.value);
  };

  const handleUploadFile = (e) => {
    setCSVFileInfo({
      ...getCSVFileInfo,
      CSVFile: e.target.files[0],
      CSVFileName: e.target.files[0]?.name,
    });
  };
  const handleUploadFileAPICall = async (file) => {
    let fileUploadPayload = {
      GoogleAdGroupId: query.get('ad_groupId'),
      ProductFile: file,
      CreatedBy: getUserInfo?.Userid,
      ResourceId: '',
      CreatedOn: '',
    };

    try {
      let response;
      if (getCurrentPageStatus) {
        response = await putApi(
          `${baseUrlStg}/api/GoogleProduct/UpdategoogleProduct`,
          '',
          fileUploadPayload,
          {
            accept: '*/',
            'Content-Type': 'multipart/form-data',
          },
        );
      } else {
        response = await postApi(
          `${baseUrlStg}/api/GoogleProduct/UploadGoogleProductList`,
          fileUploadPayload,
          {
            accept: '*/',
            'Content-Type': 'multipart/form-data',
          },
        );
      }
      if (response?.data?.status == 200) {
        return true;
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDownloadFile = (fileName) => {
    let endPoint = `${baseUrlStg}/api/GoogleProduct/GetSampleProductCSVFile`;
    downloadFile(endPoint, fileName);
  };

  const handleLocalStateComponentReset = () => {
    setSelectedCallVal('');
    setSelectedListingGroupType('');
    setCSVFileInfo({
      ...getCSVFileInfo,
      CSVFile: '',
      CSVFileName: '',
    });
    setShowLeadForm('');
    setLandScapeImages([]);
    setMediaSquareImages([]);
    setPotraitImages([]);
    setLogoLandScapeImages([]);
    setSquareLogoImages([]);
    setSelectedVideoItem([]);
    setSelectedSiteLinksVal('');
    setLocalCallToActionVal('');
    setSelectedCallOutsVal('');
    setSelectedPromotionsVal('');
    setSelectedPricesVal('');
    setSelectedStructuredsnippetsVal('');
    setSelectedAppsVal('');
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box padding-bottom-extra media-section-left'>
            {/* <Header /> */}

            {/* header code starts */}
            <header>
              <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
                <div className='top-right-bar'>
                  <div className='btn-box'>
                    <button
                      type='button'
                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                      onClick={(e) => handleSaveAsDraft(e)}
                    >
                      Save as draft
                    </button>
                    <button
                      type='button'
                      className='primary-btn close-btn'
                      onClick={() => {
                        navigate('/google-ad/campaign-listing');
                      }}
                    >
                      <i className='icon-close'></i>
                    </button>
                  </div>
                </div>
              </div>
            </header>
            {/* header code ends */}
            <section className='side-menu-form d-flex'>
              {/* side tree menu code  starts*/}
              <SideTreeMenuView
                campaignId={campaignId}
                IdAndType={{
                  id: googleAdId,
                  type: GoogleAdOptions.Ads.name,
                }}
              />
              {/* side tree menu code ends */}
              <article className='side-right-form-wrap side-right-pad view-edit-tab-remove'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        {/* code goes here */}
                        <Card>
                          <CardHeader>
                            <h5>Ads</h5>
                          </CardHeader>
                          {showChooseAdCreateType && (
                            <>
                              <CardHeader>
                                <h6>Choose which type of ad to create</h6>
                              </CardHeader>
                              <ChooseCreateAdType
                                handleSetSelectedType={handleSetSelectedTypeVal}
                                selectedType={state?.creativeType}
                                productFeedStatus={productFeedStatus}
                                disabledStatus={getCurrentPageStatus}
                              />
                            </>
                          )}
                          <CardHeader>
                            <h6>Ad Name*</h6>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Ad Name*</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      name='Ad Name'
                                      value={state?.ad_name}
                                      id='text'
                                      placeholder='Enter the name'
                                      onChange={handleAdName}
                                      // readOnly={getCurrentPageStatus}
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    {formErrors?.adName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          <CardBody>
                            {/* Assign creative start */}
                            {/* {showAssignVideo && (
                              <div className='media-btn'>
                                <div className='file-error'>
                                  <div
                                    className='btn-icon btn-file-icon'
                                    onClick={openMediaWidgetVideoCampaign}
                                  >
                                    <i className='icon-plus-circle' />
                                    <label htmlFor='imgFileIDVib'>
                                      Assign video
                                      <ErrorDisplay
                                        val={formErrors?.assignCreatives}
                                      ></ErrorDisplay>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            {(showAssignVideo &&
                              currentCampaignTypeName == 'Video') ||
                              (state?.creativeType == 'Video' &&
                                selectedVideoItem?.length > 0 && (
                                  <DisplaySelectedWidgetItem
                                    handledeleteItem={handledeleteVideoItem}
                                    item={selectedVideoItem}
                                  />
                                ))} */}
                            {/* Assign creative end */}
                            {showFinalUrl && (
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <h6 className='card-header-title'>
                                    Final URL*
                                  </h6>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Final URL*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='text'
                                        id='email'
                                        placeholder='Enter URL'
                                        onChange={finalURL_handler}
                                        name='final_url'
                                        value={state?.final_url}
                                        readOnly={
                                          googleCampaignTypeList?.[
                                            'Performance Max'
                                          ]?.campaign_type_id ==
                                            GoogleSideTree?.campaignTypeId &&
                                          GoogleSideTree?.merchantCenterAccount
                                        }
                                        disabled={
                                          googleCampaignTypeList?.[
                                            'Performance Max'
                                          ]?.campaign_type_id ==
                                            GoogleSideTree?.campaignTypeId &&
                                          GoogleSideTree?.merchantCenterAccount
                                        }
                                      />
                                    </div>
                                    {formErrors?.finalURL && (
                                      <span className='error' id='emailError'>
                                        {formErrors?.finalURL}
                                      </span>
                                    )}
                                  </div>

                                  <div class='info-icon-body mt-2'>
                                    <div class='more-info-icon'>
                                      <i class='icon-bulb'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>https://www.koo.com</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </CardBody>
                          {showDisplayPath ||
                          state?.creativeType == 'Video' ||
                          state?.creativeType == 'Products' ? (
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <h6 className='card-header-title'>
                                    Display path
                                  </h6>
                                  <p className='mb-2'>www.example.com</p>

                                  <div className='form-item col-6 sword-line'>
                                    <div className='s-line'>/</div>
                                    <div className='input-group'>
                                      <input
                                        type='text'
                                        id='email'
                                        onChange={DisplayPath_0}
                                        name='display_path'
                                        // placeholder="0"
                                        value={state?.display_path_0}
                                      />
                                    </div>
                                    {/* <span className="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                    {/* <small className="select-time">0 / 15</small> */}
                                  </div>

                                  <div className='form-item col-6 sword-line'>
                                    <div className='s-line'>/</div>
                                    <div className='input-group'>
                                      <input
                                        type='text'
                                        id='email'
                                        onChange={DisplayPath_1}
                                        name='display_path'
                                        value={state?.display_path_1}
                                        // placeholder="0"
                                      />
                                    </div>
                                    {/* <span className="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                    {/* <small className="select-time">{state?.display_path && state?.display_path?.split("/")[2]?.length} / 15</small> */}
                                  </div>
                                  <div className='card-body'>
                                    <div className='info-icon-body'>
                                      <div className='more-info-icon'>
                                        <i className='icon-bulb' />
                                      </div>
                                      <div className='more-info-text'>
                                        <p>
                                          The "Path" fields are part of your
                                          display URL and can be up to 15
                                          characters each.
                                        </p>
                                        <p>
                                          To create your display URL, Google Ads
                                          will combine the domain (for example,
                                          "www.google.com" in
                                          www.google.com/nonprofits) from your
                                          final URL and the path text (for
                                          example, "nonprofits" in
                                          www.google.com/nonprofits). The
                                          display URL gives potential customers
                                          a clear idea of what webpage they'll
                                          reach once they click your ad, so your
                                          path text should describe your ad's
                                          landing page.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          ) : (
                            <></>
                          )}
                          {showPhoneNumber && (
                            <>
                              <div className='divder-form'></div>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <h6 className='card-header-title'>Calls</h6>
                                  <p>select a phone number</p>

                                  <section className='group-section-data'>
                                    <CardBody>
                                      <ReactSelect
                                        options={formatDataForReactSelect(
                                          state?.calls,
                                          'phoneNumber',
                                          'id',
                                        )}
                                        onChange={selctedCalls}
                                        isMulti={true}
                                        value={selectedCallVal}
                                        closeMenuOnSelect={true}
                                      />
                                      {/* {state?.calls?.map((item, index) => {
                                        return (
                                          <div className='form-check-box col-6 mb-2'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              id={'inlineCheckbox91' + index}
                                              defaultValue='Display'
                                              name='creative'
                                              onChange={(e) =>
                                                handleCalls(e, index)
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={
                                                'inlineCheckbox91' + index
                                              }
                                            >
                                              <div className='label-multitext'>
                                                {item?.number}
                                              </div>
                                            </label>
                                          </div>
                                        );
                                      })} */}
                                      {/* <span class="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                    </CardBody>
                                  </section>
                                </div>
                              </CardBody>
                            </>
                          )}
                          {showLeadForm && (
                            <>
                              <div className='divder-form'></div>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <h6 className='card-header-title'>
                                    Lead forms
                                  </h6>
                                  <p>Select a form</p>

                                  <section className='group-section-data'>
                                    <CardBody>
                                      <ReactSelect
                                        options={formatDataLeads(
                                          state?.lead_forms,
                                        )}
                                        isMulti={false}
                                        onChange={selctedLeadForm}
                                        closeMenuOnSelect={true}
                                        value={selectedLeadFormVal}
                                      />
                                      {/* <div className='action-btn-both action-btn-both-check row relative mb-4'> */}
                                      {/* <div className='col-8 d-flex flex-wrap'> */}
                                      {/* {state?.lead_forms?.map((item, index) => {
                                        return (
                                          <div className='form-check-box col-6 mb-2'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              id={'inlineCheckbox94' + index}
                                              defaultValue='Audio'
                                              name='creative'
                                              onChange={(e) =>
                                                handleLeadForms(e, index)
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={
                                                'inlineCheckbox94' + index
                                              }
                                            >
                                              <div className='label-multitext'>
                                                {item?.name}
                                              </div>
                                            </label>

                                          </div>
                                        );
                                      })} */}
                                      {/* </div> */}
                                      {/* <span class="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                      {/* </div> */}
                                      {/* <div class="info-icon-body mt-2">
                                    <div className="more-info-icon">
                                      <i className="icon-exclam"></i>
                                    </div>
                                    <div class="more-info-text">
                                      <p>
                                        Because you selected leads as a campaign
                                        goal, you should add a lead form to use
                                        with your ads.
                                      </p>
                                    </div>
                                  </div> */}
                                    </CardBody>
                                  </section>

                                  {/* <div className="form-panel-group-add">
                                <div className="row align-items-center">
                                  <div className="form-item col-6">
                                    <div className="label-icon">
                                      <label>Headline</label>
                                    </div>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        id="datepicker"
                                        placeholder="Headline"
                                      />
                                    </div>
                                    <small className="select-time">
                                      0 / 50
                                    </small>
                                    <span className="error" id="emailError">
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className="form-item col-6">
                                    <div className="label-icon">
                                      <label>Business Name</label>
                                    </div>

                                    <ReactSelect
                                      name="Frequency limit exposure"
                                      placeholder="Business"
                                      // options={[
                                      //   "Months",
                                      //   "Weeks",
                                      //   "Days",
                                      //   "Hours",
                                      //   "Minutes",
                                      // ]}
                                      // onChangeHandler={onChangeHandler}
                                    ></ReactSelect>
                                    <small className="select-time">
                                      0 / 50
                                    </small>

                                    <span className="error" id="emailError">
                                      Please enter valid email id
                                    </span>
                                  </div>

                                  <div className="form-item col-12 mt-4 mb-3">
                                    <div className="label-icon">
                                      <label>Description</label>
                                    </div>
                                    <div className="input-sizer stacked">
                                      <TextareaAutosize />
                                    </div>
                                    <span className="error" id="emailError">
                                      Please enter valid email id
                                    </span>
                                    <small className="select-time">
                                      15 / 200
                                    </small>
                                  </div>
                                </div>

                                <CardHeader>
                                  <h6>Questions</h6>
                                  <p>
                                    To change the information you ask for after
                                    saving your campaign, you will have to
                                    create a new form
                                  </p>
                                </CardHeader>

                                <CardBody>
                                  <div className="action-btn-cont">
                                    <div className="action-btn-both action-btn-no-icon row">
                                      <h6 className="card-header-title">
                                        Contact information
                                      </h6>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault96"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault96"
                                          >
                                            <div className="label-multitext">
                                              Name
                                            </div>
                                          </label>
                                        </div>
                                        <div className="dv-from-check-field">
                                          <div className="action-btn-cont">
                                            <div className="action-btn-both action-btn-no-icon row">
                                              <div className="col-12 px-0">
                                                <div className="form-check-box">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault97"
                                                    defaultChecked=""
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault97"
                                                  >
                                                    <div className="label-multitext">
                                                      <p className="mt-0">
                                                        {" "}
                                                        First name and last name
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="col-12 px-0">
                                                <div className="form-check-box">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault98"
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault98"
                                                  >
                                                    <div className="label-multitext">
                                                      <p className="mt-0">
                                                        {" "}
                                                        Full name
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault99"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault99"
                                          >
                                            <div className="label-multitext">
                                              City
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault100"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault100"
                                          >
                                            <div className="label-multitext">
                                              Phone number
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault101"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault101"
                                          >
                                            <div className="label-multitext">
                                              Country
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault103"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault103"
                                          >
                                            <div className="label-multitext">
                                              Email
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault104"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault104"
                                          >
                                            <div className="label-multitext">
                                              Street address
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="action-btn-both action-btn-no-icon row mt-4">
                                      <h6 className="card-header-title">
                                        Work information
                                      </h6>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault105"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault105"
                                          >
                                            <div className="label-multitext">
                                              Company name
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault106"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault106"
                                          >
                                            <div className="label-multitext">
                                              Work email
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault107"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault107"
                                          >
                                            <div className="label-multitext">
                                              Job title
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-6 px-0">
                                        <div className="form-check-box">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault108"
                                            defaultChecked=""
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexRadioDefault108"
                                          >
                                            <div className="label-multitext">
                                              Work phone number
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <h6 className="card-header-title">
                                    Additional information
                                  </h6>
                                  <div className="card-form-days dv-card-form-days mt-3">
                                    <div className="row align-items-center">
                                      <div className="action-btn-cont">
                                        <div
                                          className="action-btn-both action-btn-no-icon row"
                                          name="Frequency cap"
                                          // onChange={onChangeHandler}
                                        >
                                          <div className="col-12 d-flex px-0">
                                            <div className="form-check-box">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Frequency cap"
                                                id="flexRadioDefault3hh"
                                                value="limit frequency to"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="flexRadioDefault3hh"
                                              >
                                                Are you over
                                              </label>
                                            </div>
                                            <div className="form-item d-flex align-items-center me-3">
                                              <ReactSelect
                                                name="Frequency limit exposure"
                                                placeholder="0"
                                                // options={[
                                                //   "Months",
                                                //   "Weeks",
                                                //   "Days",
                                                //   "Hours",
                                                //   "Minutes",
                                                // ]}
                                                // onChangeHandler={
                                                //   onChangeHandler
                                                // }
                                              ></ReactSelect>
                                              <label>years of age? </label>
                                              <span
                                                className="error px-0"
                                                id="emailError"
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <h6 className="card-header-title">
                                    Qualifying questions
                                  </h6>
                                  <div className="row align-items-center">
                                    <div className="form-item">
                                      <div className="label-icon">
                                        <label>Language</label>
                                      </div>
                                      <ReactSelect
                                        name="Frequency limit exposure"
                                        placeholder="English"
                                        // options={[
                                        //   "Months",
                                        //   "Weeks",
                                        //   "Days",
                                        //   "Hours",
                                        //   "Minutes",
                                        // ]}
                                        // onChangeHandler={onChangeHandler}
                                      ></ReactSelect>
                                      <span className="error" id="emailError">
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <div className="form-panel-group-add ques-pannel">
                                    <div className="ques-header">
                                      <div className="s-line">=</div>
                                      <CardHeader>
                                        <p>Question</p>
                                        <h6>
                                          Which vehicle make are you interested
                                          in?
                                        </h6>
                                      </CardHeader>
                                    </div>

                                    <div className="row align-items-center m-3">
                                      <h6>Answer type</h6>
                                      <div className="form-item">
                                        <div className="label-icon">
                                          <label>Multiple choice</label>
                                        </div>
                                        <ReactSelect
                                          name="Frequency limit exposure"
                                          placeholder="Multiple-choice"
                                          // options={[
                                          //   "Months",
                                          //   "Weeks",
                                          //   "Days",
                                          //   "Hours",
                                          //   "Minutes",
                                          // ]}
                                          // onChangeHandler={onChangeHandler}
                                        ></ReactSelect>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                      </div>
                                    </div>

                                    <div className="row align-items-center m-3 mb-0">
                                      <h6>Answer type (up to 12)</h6>

                                      <div className="ques-header-rept row">
                                        <div className="col-8">
                                          <div className="form-item ques-rept-box">
                                            <div className="ques-wrap-remove">
                                              <div className="s-line">=</div>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  id="datepicker"
                                                  placeholder="Headline"
                                                />
                                              </div>
                                              <small className="select-time">
                                                0 / 50
                                              </small>
                                            </div>
                                            <div className="media-btn">
                                              <button
                                                type="button"
                                                className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                              >
                                                <i className="icon-delete"></i>
                                                Remove answer
                                              </button>
                                            </div>
                                          </div>
                                          <div className="form-item ques-rept-box">
                                            <div className="ques-wrap-remove">
                                              <div className="s-line">=</div>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  id="datepicker"
                                                  placeholder="Headline"
                                                />
                                              </div>
                                              <span
                                                className="error"
                                                id="emailError"
                                              >
                                                Please enter valid email id
                                              </span>
                                              <small className="select-time">
                                                0 / 50
                                              </small>
                                            </div>
                                            <div className="media-btn">
                                              <button
                                                type="button"
                                                className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                              >
                                                <i className="icon-delete"></i>
                                                Remove answer
                                              </button>
                                            </div>
                                          </div>
                                          <div className="form-item ques-rept-box">
                                            <div className="ques-wrap-remove">
                                              <div className="s-line">=</div>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  id="datepicker"
                                                  placeholder="Headline"
                                                />
                                              </div>
                                              <span
                                                className="error"
                                                id="emailError"
                                              >
                                                Please enter valid email id
                                              </span>
                                              <small className="select-time">
                                                0 / 50
                                              </small>
                                            </div>
                                            <div className="media-btn">
                                              <button
                                                type="button"
                                                className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                              >
                                                <i className="icon-delete"></i>
                                                Remove answer
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="add-section">
                                          <button
                                            type="button"
                                            className="outline-btn"
                                          >
                                            Add answer
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="add-section">
                                    <button className="btn-add-plus">
                                      <i className="icon-plus-circle" />
                                      Question
                                    </button>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <div className="row align-items-center">
                                    <div className="form-item">
                                      <div className="label-icon">
                                        <label>Privacy policy URL</label>
                                      </div>
                                      <div className="input-group">
                                        <input
                                          type="email"
                                          id="email"
                                          placeholder="Type URL"
                                          name="Planned spend"
                                        />
                                      </div>
                                      <span className="error" id="emailError">
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardHeader>
                                  <h6>Background image</h6>
                                </CardHeader>
                                <CardBody>
                                  <div className="slideshow-box-group-rept">
                                    <div className="slideshow-box d-flex">
                                      <div className="slideshow-imgbox d-flex align-items-center">
                                        <img
                                          src={slidePlaceHolder}
                                          alt="Slide Show"
                                        />
                                      </div>
                                      <div className="slideshow-cont">
                                        <div className="slideshow-cont-top">
                                          <p>
                                            <i className="icon-images"></i>
                                            1600 x 400
                                          </p>
                                        </div>
                                        <div className="slideshow-cont-bottom">
                                          <button
                                            type="button"
                                            className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                      <button className="close-slideshow">
                                        <i className="icon-close" />
                                      </button>
                                    </div>
                                  </div>
                                </CardBody>

                                <div className="divder-form"></div>

                                <section className="group-section-data">
                                  <CardHeader>
                                    <h6>Create form submission message</h6>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="row align-items-center">
                                      <div className="form-item col-12">
                                        <div className="label-icon">
                                          <label>Headline</label>
                                        </div>
                                        <div className="input-group">
                                          <input
                                            type="email"
                                            id="email"
                                            placeholder="Thank you"
                                            name="Planned spend"
                                          />
                                        </div>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                        <small className="select-time">
                                          0 / 50
                                        </small>
                                      </div>
                                      <div className="form-item col-12">
                                        <div className="label-icon">
                                          <label>Description</label>
                                        </div>
                                        <div className="input-sizer stacked">
                                          <TextareaAutosize placeholder="We'll contact you soon" />
                                        </div>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                        <small className="select-time">
                                          0 / 200
                                        </small>
                                      </div>
                                      <div className="form-item col-12">
                                        <div className="label-icon">
                                          <label>Call-to-action</label>
                                        </div>
                                        <ReactSelect
                                          name="Frequency limit exposure"
                                          placeholder="Visit site"
                                          // options={[
                                          //   "Months",
                                          //   "Weeks",
                                          //   "Days",
                                          //   "Hours",
                                          //   "Minutes",
                                          // ]}
                                          // onChangeHandler={onChangeHandler}
                                        ></ReactSelect>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div className="form-item col-12">
                                        <div className="label-icon">
                                          <label>Call to action URL</label>
                                        </div>
                                        <div className="input-group">
                                          <input
                                            type="email"
                                            id="email"
                                            placeholder="Type URL"
                                            name="Planned spend"
                                          />
                                        </div>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                      </div>
                                    </div>
                                  </CardBody>
                                </section>

                                <div className="divder-form"></div>

                                <section className="group-section-data">
                                  <CardHeader>
                                    <h6>Select a call-to-action for your ad</h6>
                                    <p>
                                      Select a compelling call-to-action that
                                      empowers people to engage with your
                                      business or service
                                    </p>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="row align-items-center">
                                      <div className="form-item">
                                        <div className="label-icon">
                                          <label>Call-to-action</label>
                                        </div>
                                        <ReactSelect
                                          name="Frequency limit exposure"
                                          placeholder="Visit site"
                                          // options={[
                                          //   "Months",
                                          //   "Weeks",
                                          //   "Days",
                                          //   "Hours",
                                          //   "Minutes",
                                          // ]}
                                          // onChangeHandler={onChangeHandler}
                                        ></ReactSelect>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div className="form-item">
                                        <div className="label-icon">
                                          <label>
                                            Call to action description
                                          </label>
                                        </div>
                                        <div className="input-sizer stacked">
                                          <TextareaAutosize placeholder="Type description" />
                                        </div>
                                        <span className="error" id="emailError">
                                          Please enter valid email id
                                        </span>
                                        <small className="select-time">
                                          0 / 30
                                        </small>
                                      </div>
                                    </div>
                                  </CardBody>
                                </section>

                                <div className="divder-form"></div>

                                <section className="group-section-data">
                                  <CardBody>
                                    <div className="carosuel-accordion-full">
                                      <section className="accordion-primary-cont">
                                        <article className="accordion-data-cont">
                                          <Accordion>
                                            <Accordion.Item eventKey="0">
                                              <Accordion.Header>
                                                <div className="accordion-data">
                                                  <h4>Lead delivery option</h4>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className="accordion-body-inner">
                                                  <CardHeader>
                                                    <h6 className="mt-0">
                                                      Download collected leads
                                                      in ad extensions table
                                                    </h6>
                                                    <p>
                                                      You can only download
                                                      leads that've been
                                                      collected within the last
                                                      30 days
                                                    </p>
                                                    <h6>
                                                      Webhook integration
                                                      (optional)
                                                    </h6>
                                                    <p>
                                                      Send lead form data
                                                      directly to your CDM or
                                                      CRM software in real time.
                                                      Otherwise, you can
                                                      download data within the
                                                      last 30 days from the
                                                      extensions table.
                                                    </p>
                                                  </CardHeader>
                                                  <CardBody>
                                                    <div className="row align-items-center">
                                                      <div className="form-item">
                                                        <div className="label-icon">
                                                          <label>
                                                            Webhook URL
                                                          </label>
                                                        </div>
                                                        <div className="input-group">
                                                          <input
                                                            type="email"
                                                            id="email"
                                                            placeholder="Type URL"
                                                            name="Planned spend"
                                                          />
                                                        </div>
                                                        <span
                                                          className="error"
                                                          id="emailError"
                                                        >
                                                          Please enter valid
                                                          email id
                                                        </span>
                                                      </div>
                                                      <div className="form-item">
                                                        <div className="label-icon">
                                                          <label>Key</label>
                                                        </div>
                                                        <div className="input-group">
                                                          <input
                                                            type="email"
                                                            id="email"
                                                            placeholder="Type key"
                                                            name="Planned spend"
                                                          />
                                                        </div>
                                                        <span
                                                          className="error"
                                                          id="emailError"
                                                        >
                                                          Please enter valid
                                                          email id
                                                        </span>
                                                        <small className="select-time">
                                                          0 / 50
                                                        </small>
                                                      </div>
                                                    </div>
                                                  </CardBody>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                              <Accordion.Header>
                                                <div className="accordion-data">
                                                  <h4>Lead form type</h4>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className="accordion-body-inner">
                                                  <CardHeader>
                                                    <p>
                                                      Optimize for leads with
                                                      higher intent or for more
                                                      leads overall.
                                                    </p>
                                                  </CardHeader>
                                                  <CardBody>
                                                    <div className="row align-items-center">
                                                      <div className="action-btn-cont">
                                                        <div className="action-btn-both action-btn-no-icon row">
                                                          <div className="col-12 px-0">
                                                            <div className="form-check-box">
                                                              <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault50"
                                                                defaultChecked=""
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor="flexRadioDefault50"
                                                              >
                                                                <div className="label-multitext">
                                                                  More volume
                                                                  <p>
                                                                    This may
                                                                    result in
                                                                    leads with
                                                                    lower
                                                                    intent.
                                                                  </p>
                                                                </div>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className="col-12 px-0">
                                                            <div className="form-check-box">
                                                              <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault51"
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor="flexRadioDefault51"
                                                              >
                                                                <div className="label-multitext">
                                                                  More qualified
                                                                  <p>
                                                                    This may
                                                                    result in
                                                                    fewer leads
                                                                    or a higher
                                                                    cost per
                                                                    lead.
                                                                  </p>
                                                                </div>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </CardBody>
                                                  <div className="divder-form"></div>
                                                  <CardHeader>
                                                    <h6>
                                                      You acknowledge and agree
                                                      that the following terms
                                                      govern your use of lead
                                                      forms:
                                                    </h6>
                                                    <p>
                                                      You will request and use
                                                      information from users (1)
                                                      only as reasonably
                                                      necessary for the limited
                                                      purpose described in your
                                                      lead forms and (2) in
                                                      accordance with your
                                                      privacy policy and any
                                                      additional disclosures
                                                      that you include in your
                                                      lead forms. You will not
                                                      sell the information
                                                      provided by users or
                                                      otherwise abuse that
                                                      information (for example,
                                                      by spamming users with
                                                      repeated phone calls or
                                                      emails). You will provide
                                                      users with clear and
                                                      comprehensive information
                                                      about data collection and
                                                      processing, including any
                                                      sharing of users'
                                                      information with third
                                                      parties, and obtain
                                                      consent for that
                                                      collection and processing
                                                      where legally required.
                                                      You will not license or
                                                      otherwise disclose the
                                                      information you receive
                                                      from users to a data
                                                      broker. You will comply
                                                      with all applicable laws
                                                      and regulations when
                                                      contacting users or
                                                      otherwise using the
                                                      information they provide,
                                                      including any marketing or
                                                      spam regulations that may
                                                      apply.
                                                    </p>
                                                    <h6>
                                                      You also acknowledge and
                                                      agree that the following
                                                      terms govern your use of
                                                      the lead form webhook:
                                                    </h6>
                                                    <p>
                                                      You will comply with all
                                                      webhook instructions
                                                      provided by Google,
                                                      including properly
                                                      configuring an endpoint to
                                                      handle POST requests.
                                                      Google is not responsible
                                                      for errors resulting from
                                                      your misconfiguration of
                                                      the webhook. The webhook
                                                      is provided in beta form.
                                                      Google may discontinue,
                                                      deprecate, or change the
                                                      webhook at any time
                                                      without notice, and Google
                                                      does not guarantee
                                                      availability, delivery of
                                                      data, or technical
                                                      support. Your use of the
                                                      webhook is governed by the
                                                      Google API Terms of
                                                      Service, available at
                                                      https://developers.google.com/terms/
                                                      together with these terms.
                                                    </p>
                                                  </CardHeader>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </article>
                                      </section>
                                    </div>
                                  </CardBody>
                                  <div className="media-btn mt-4">
                                    <div className="file-error">
                                      <button
                                        type="button"
                                        className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn-icon btn-icon-primary btn-grey-primary"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </section>
                              </div> */}

                                  {/* <div className="add-section">
                                <button className="btn-add-plus">
                                  <i className="icon-plus-circle" />
                                  Lead forms
                                </button>
                              </div> */}
                                </div>
                              </CardBody>
                            </>
                          )}
                          <div className='divder-form'></div>
                          {/* headlines start*/}
                          {showHeadlines ||
                          (state?.creativeType != 'Carousel' &&
                            state?.creativeType != 'Products' &&
                            getCurrentCampaignId != 4) ? (
                            <>
                              <CardHeader>
                                <h6>
                                  Headlines* {state?.head_lines?.length}/15
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center relative headline-cont-rept'>
                                    {state?.head_lines?.map((item, index) => {
                                      return (
                                        <div className='form-item col-6 headline-rept-box'>
                                          <div className='headline-wrap-remove'>
                                            <div className='label-icon'>
                                              <label>
                                                Headline
                                                {index <= 2 ? '*' : null}
                                              </label>
                                            </div>
                                            <div className='input-group'>
                                              <input
                                                type='text'
                                                id='email'
                                                placeholder='Headline'
                                                onChange={(e) =>
                                                  handleHeadlines(e, index)
                                                }
                                                value={
                                                  state?.head_lines?.[index]
                                                }
                                              />
                                            </div>
                                          </div>
                                          {state?.head_lines?.length > 3 && (
                                            <div className='media-btn'>
                                              <button
                                                type='button'
                                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                onClick={() =>
                                                  handleDelHeadlines(index)
                                                }
                                              >
                                                <i className='icon-delete' />
                                              </button>
                                            </div>
                                          )}

                                          {/* <small className="select-time">
                                        10 / 30
                                      </small> */}
                                        </div>
                                      );
                                    })}
                                    {/* <span className="error error-one" id="emailError">Please enter valid email id</span> */}
                                    {(formErrors?.minHeadlines ||
                                      formErrors?.repeatedHeadlines) && (
                                      <span
                                        className='error error-one'
                                        id='emailError'
                                      >
                                        {formErrors?.minHeadlines}{' '}
                                        {formErrors?.repeatedHeadlines}
                                      </span>
                                    )}
                                  </div>
                                  <div class='info-icon-body mt-2'>
                                    <div class='more-info-icon'>
                                      <i class='icon-bulb'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>
                                        At least 3 headlines are required and
                                        upto 30 characters are allowed per
                                        headline and all headlines should be
                                        unique.
                                      </p>
                                    </div>
                                  </div>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={handleAddHeadlines}
                                    >
                                      <i className='icon-plus-circle' />
                                      Headline
                                    </button>
                                  </div>
                                </div>
                              </CardBody>

                              <div className='divder-form'></div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* headlines end*/}
                          {/* Longheadlines start*/}
                          {showLongHeadlines && (
                            <>
                              <CardHeader>
                                <h6>
                                  Long Headlines{' '}
                                  {`${[...state?.long_head_lines]?.length}`}/5
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center relative headline-cont-rept'>
                                    {[...state?.long_head_lines]?.map(
                                      (item, index) => {
                                        return (
                                          <div className='form-item col-6 headline-rept-box'>
                                            <div className='headline-wrap-remove'>
                                              <div className='label-icon'>
                                                <label>
                                                  Long Headline
                                                  {index <= 0 ? '*' : null}
                                                </label>
                                              </div>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  id='email'
                                                  placeholder='Long Headline'
                                                  onChange={(e) =>
                                                    handleLongHeadlines(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  value={
                                                    currentCampaignTypeName ==
                                                    'Display'
                                                      ? state
                                                          ?.long_head_lines?.[
                                                          state?.long_head_lines
                                                            ?.length - 1
                                                        ]
                                                      : state
                                                          ?.long_head_lines?.[
                                                          index
                                                        ]
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {[...state?.long_head_lines]
                                              .length > 1 && (
                                              <div className='media-btn'>
                                                <button
                                                  type='button'
                                                  className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                  onClick={() =>
                                                    handleDelLongHeadlines(
                                                      index,
                                                    )
                                                  }
                                                >
                                                  <i className='icon-delete' />
                                                </button>
                                              </div>
                                            )}

                                            {/* <small className='select-time'>
                                              10 / 30
                                            </small> */}
                                          </div>
                                        );
                                      },
                                    )}

                                    {/* <span className="error error-one" id="emailError">Please enter valid email id</span> */}
                                    {(formErrors?.minLongHeadlines ||
                                      formErrors?.repeatedLongHeadlines) && (
                                      <span
                                        className='error error-one'
                                        id='emailError'
                                      >
                                        {formErrors?.minLongHeadlines}{' '}
                                        {formErrors?.repeatedLongHeadlines}
                                      </span>
                                    )}
                                  </div>
                                  <div class='info-icon-body mt-2'>
                                    <div class='more-info-icon'>
                                      <i class='icon-bulb'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>
                                        At least 1 headline is required and upto
                                        90 characters are allowed per headline
                                        and all headlines should be unique.
                                      </p>
                                    </div>
                                  </div>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      // onClick={handleAddHeadlines}
                                      onClick={handleAddLongHeadlines}
                                    >
                                      <i className='icon-plus-circle' />
                                      Long Headline
                                    </button>
                                  </div>
                                </div>
                              </CardBody>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* Longheadlines end*/}
                          {/* Descriptions start */}
                          {showDescription ||
                          (state?.creativeType != 'Carousel' &&
                            state?.creativeType != 'Products' &&
                            getCurrentCampaignId != 4 &&
                            getCurrentCampaignId != 2) ? (
                            <>
                              <CardHeader>
                                <h6>
                                  Descriptions* {state?.descriptions?.length}/{' '}
                                  {currentCampaignTypeName == 'Demand Gen'
                                    ? 5
                                    : 4}
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center relative headline-cont-rept'>
                                    {state?.descriptions?.map((item, index) => {
                                      return (
                                        <div className='form-item col-12 headline-rept-box'>
                                          <div className='headline-wrap-remove'>
                                            <div className='label-icon'>
                                              <label>
                                                Description
                                                {index <= 1 ? '*' : null}
                                              </label>
                                            </div>
                                            <div className='input-group'>
                                              <input
                                                type='text'
                                                id='email'
                                                placeholder='Description'
                                                onChange={(e) =>
                                                  handleDescriptions(e, index)
                                                }
                                                value={
                                                  state?.descriptions[index]
                                                }
                                              />
                                            </div>
                                          </div>
                                          {state?.descriptions?.length > 2 && (
                                            <div className='media-btn'>
                                              <button
                                                type='button'
                                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                onClick={() =>
                                                  handleDelDescriptions(index)
                                                }
                                              >
                                                <i className='icon-delete' />
                                              </button>
                                            </div>
                                          )}

                                          {/* <span className="error" id="emailError">
                                    Please enter valid email id
                                  </span> */}

                                          {/* <small className="select-time">10 / 90</small> */}
                                        </div>
                                      );
                                    })}
                                    {/* <span className="error error-one" id="emailError">Please enter valid email id</span> */}
                                    {(formErrors?.minDescriptions ||
                                      formErrors?.repeatedDescriptions) && (
                                      <span
                                        className='error error-one'
                                        id='emailError'
                                      >
                                        {formErrors?.minDescriptions}{' '}
                                        {formErrors?.repeatedDescriptions}
                                      </span>
                                    )}
                                  </div>
                                  <div class='info-icon-body mt-2'>
                                    <div class='more-info-icon'>
                                      <i class='icon-bulb'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>
                                        At least 2 descriptions are required and
                                        upto 90 characters are allowed per
                                        description and all descriptions should
                                        be unique.
                                      </p>
                                    </div>
                                  </div>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={handleAddDescriptions}
                                    >
                                      <i className='icon-plus-circle' />
                                      Description
                                    </button>
                                  </div>
                                </div>
                              </CardBody>

                              <div className='divder-form'></div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* Descriptions end */}
                          {/* short descriptions start */}
                          {showShortDescription && (
                            <>
                              <CardHeader>
                                <h6>
                                  Short descriptions *
                                  {`${state?.short_descriptions?.length}`}/5
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center relative headline-cont-rept'>
                                    {[...state?.short_descriptions]?.map(
                                      (item, index) => {
                                        return (
                                          <div className='form-item col-12 headline-rept-box'>
                                            <div className='headline-wrap-remove'>
                                              <div className='label-icon'>
                                                <label>
                                                  {index == 0
                                                    ? 'ShortDescription'
                                                    : 'Description'}
                                                  {index <= 1 ? '*' : null}
                                                </label>
                                              </div>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  id='email'
                                                  placeholder={
                                                    index == 0
                                                      ? 'ShortDescription'
                                                      : 'Description'
                                                  }
                                                  onChange={(e) =>
                                                    handleShortDescriptions(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  value={
                                                    state?.short_descriptions[
                                                      index
                                                    ]
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {[...state?.short_descriptions]
                                              .length > 2 && (
                                              <div className='media-btn'>
                                                <button
                                                  type='button'
                                                  className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                  onClick={() =>
                                                    handleDelShortDescriptions(
                                                      index,
                                                    )
                                                  }
                                                >
                                                  <i className='icon-delete' />
                                                </button>
                                              </div>
                                            )}

                                            {/* <span className="error" id="emailError">
                                    Please enter valid email id
                                  </span> */}

                                            {/* <small className="select-time">10 / 90</small> */}
                                          </div>
                                        );
                                      },
                                    )}
                                    {/* <span className="error error-one" id="emailError">Please enter valid email id</span> */}
                                    {(formErrors?.minShortDescriptions ||
                                      formErrors?.repeatedShortDescriptions) && (
                                      <span
                                        className='error error-one'
                                        id='emailError'
                                      >
                                        {formErrors?.minShortDescriptions}{' '}
                                        {formErrors?.repeatedShortDescriptions}
                                      </span>
                                    )}
                                  </div>
                                  <div class='info-icon-body mt-2'>
                                    <div class='more-info-icon'>
                                      <i class='icon-bulb'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>
                                        At least 2 descriptions are required and
                                        upto 60 characters are allowed per
                                        description and all descriptions should
                                        be unique.
                                      </p>
                                    </div>
                                  </div>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      // onClick={handleAddDescriptions}
                                      onClick={handleAddShortDescriptions}
                                    >
                                      <i className='icon-plus-circle' />
                                      Description
                                    </button>
                                  </div>
                                </div>
                              </CardBody>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* short descriptions end */}
                          {/* LandScape Image start */}
                          {(state?.creativeType == 'Image' &&
                            getCurrentCampaignId != 4) ||
                          (showLandScapeImage && getCurrentCampaignId != 4) ? (
                            <>
                              <ImageCardBody
                                title={'Land Scape Image (1.91:1)'}
                                subTitle={
                                  currentCampaignTypeName ==
                                    'Performance Max' ||
                                  currentCampaignTypeName == 'Demand Gen'
                                    ? 'Add upto 20 Images'
                                    : 'Add upto 15 Images'
                                }
                              >
                                {getLandScapeImages?.length > 0 && (
                                  <div className='card-body'>
                                    <div className='form-panel-group-add'>
                                      <div className='media-list media-landscape'>
                                        <SelectedMediaWidgetItems
                                          items={getLandScapeImages}
                                          callBackFunction={setLandScapeImages}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={openLandScapeWidget}
                                    >
                                      <i className='icon-plus-circle' />{' '}
                                      LandScape mage
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <span className='error' id='emailError'>
                                        {formErrors?.landScapeError}
                                      </span>
                                    </div>
                                  </div>

                                  <div className='divder-form'></div>
                                </>
                              </ImageCardBody>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* LandScape Image end */}
                          {/* Square Image start */}
                          {(state?.creativeType == 'Image' &&
                            getCurrentCampaignId != 4) ||
                          (showSquareImage && getCurrentCampaignId != 4) ? (
                            <>
                              <ImageCardBody
                                title={'Square Image (1:01)'}
                                subTitle={''}
                              >
                                {getMediaSquareImages?.length > 0 && (
                                  <div className='card-body'>
                                    <div className='form-panel-group-add'>
                                      <div className='media-list media-landscape'>
                                        <SelectedMediaWidgetItems
                                          items={getMediaSquareImages}
                                          callBackFunction={
                                            setMediaSquareImages
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={openSquareWidget}
                                    >
                                      <i className='icon-plus-circle' /> Square
                                      Image
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <span className='error' id='emailError'>
                                        {formErrors?.squareImageError}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='divder-form'></div>
                                </>
                              </ImageCardBody>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* Square Image end */}
                          {/* Potrait Image start */}
                          {(state?.creativeType == 'Image' &&
                            getCurrentCampaignId != 4) ||
                          (showPotraitImage && getCurrentCampaignId != 4) ? (
                            <>
                              <ImageCardBody
                                title={'Potrait Image (4:05)'}
                                subTitle={''}
                              >
                                {getPotraitImages?.length > 0 && (
                                  <div className='card-body'>
                                    <div className='form-panel-group-add'>
                                      <div className='media-list media-landscape'>
                                        <SelectedMediaWidgetItems
                                          items={getPotraitImages}
                                          callBackFunction={setPotraitImages}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={openPotraitWidget}
                                    >
                                      <i className='icon-plus-circle' /> Potrait
                                      Image
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <span className='error' id='emailError'>
                                        {formErrors?.potraitImageError}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='divder-form'></div>
                                </>
                              </ImageCardBody>
                              <div className='divder-form'></div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* Potrait Image end */}
                          {/* LandScape Logo start */}
                          {showLogoLandImage && (
                            <>
                              <ImageCardBody
                                title={'LandScape Logo (4:01)'}
                                subTitle={
                                  state?.creativeType == 'Video'
                                    ? 'Add upto 1 Logo'
                                    : 'Add upto 5 Logos'
                                }
                              >
                                {getLogoLandScapeImages?.length > 0 && (
                                  <div className='card-body'>
                                    <div className='form-panel-group-add'>
                                      <div className='media-list media-landscape'>
                                        <SelectedMediaWidgetItems
                                          items={getLogoLandScapeImages}
                                          callBackFunction={
                                            setLogoLandScapeImages
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={openLogoLandScapeWidget}
                                    >
                                      <i className='icon-plus-circle' />{' '}
                                      LandScape Logo
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <span className='error' id='emailError'>
                                        {formErrors?.logoError}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='divder-form'></div>
                                </>
                              </ImageCardBody>
                            </>
                          )}
                          {/* LandScape Logo end */}
                          {/* Square Logo start */}
                          {showSquareLogoImage && (
                            <>
                              <ImageCardBody
                                title={'Square Logo (1:01)'}
                                subTitle={''}
                              >
                                {getSquareLogoImages?.length > 0 && (
                                  <div className='card-body'>
                                    <div className='form-panel-group-add'>
                                      <div className='media-list media-landscape'>
                                        <SelectedMediaWidgetItems
                                          items={getSquareLogoImages}
                                          callBackFunction={setSquareLogoImages}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <>
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={openSquareLogoWidget}
                                    >
                                      <i className='icon-plus-circle' /> Square
                                      Logo
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <span className='error' id='emailError'>
                                        {formErrors?.logoError}
                                      </span>
                                    </div>
                                  </div>

                                  <div className='divder-form'></div>
                                </>
                              </ImageCardBody>
                            </>
                          )}
                          {/* Square Logo end */}
                          {/* {state?.creativeType == 'Image' || showImages ? (
                            <>
                              <CardBody>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center'>
                                    <h6 className='card-header-title'>
                                      Images
                                    </h6>
                                    {images?.length == 0 && (
                                      <p>
                                        {`Add upto ${
                                          currentCampaignTypeName ==
                                            'Demand Gen' ||
                                          currentCampaignTypeName ==
                                            'Performance Max' ||
                                          currentCampaignTypeName == 'App'
                                            ? 20
                                            : 15
                                        } images`}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </CardBody>
                              <CardBody>
                                <div className='hide-show'>
                                  <div className='carosuel-accordion-full'>
                                    <section className='accordion-primary-cont d-flex'>
                                      <article className='accordion-data-cont'>
                                        <Accordion defaultActiveKey='0'>
                                          {[...images].map((item, index) => {
                                            return (
                                              <>
                                                <div className='slideshow-box-group-rept'>
                                                  <div className='slideshow-box d-flex'>
                                                    <div className='slideshow-imgbox d-flex align-items-center'>
                                                      {renderMedia(item)}
                                                    </div>
                                                    <div className='slideshow-cont'>
                                                      <div className='slideshow-cont-top'>
                                                        <p>
                                                          <i className='icon-images'></i>
                                                          {item?.mediaName ||
                                                            item?.medFileName}
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <button
                                                      className='close-slideshow'
                                                      onClick={() =>
                                                        setImages((prev) => [
                                                          ...prev.slice(
                                                            0,
                                                            index,
                                                          ),
                                                          ...prev.slice(
                                                            index + 1,
                                                          ),
                                                        ])
                                                      }
                                                    >
                                                      <i className='icon-close' />
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })}

                                        </Accordion>
                                      </article>
                                    </section>
                                  </div>
                                </div>

                                <div className='media-btn'>
                                  <div className='file-error'>
                                    <div
                                      className='btn-icon btn-file-icon'
                                      onClick={openMediaWidget}
                                    >
                                      <i className='icon-plus-circle' />
                                      <label htmlFor='imgFileIDVi'>
                                        Add images
                                      </label>
                                    </div>
                                    <span className='error' id='emailError'>
                                      {formErrors?.images}
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                              <div className='divder-form'></div>
                            </>
                          ) : (
                            <></>
                          )} */}
                          {showBusinessName && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Business name*</h6>
                                  <p>Add business name</p>
                                </CardHeader>
                                <CardBody>
                                  <div className='card-form form-panel'>
                                    <div className='row align-items-center'>
                                      <div className='form-item'>
                                        <div className='label-icon'>
                                          <label>Business name*</label>
                                        </div>
                                        <div className='input-group'>
                                          <input
                                            type='text'
                                            id='email'
                                            placeholder='Type business name'
                                            onChange={businessNameHandler}
                                            value={state?.business_name}
                                          />
                                        </div>
                                        {/* <small className="select-time">
                                      0 / 25
                                    </small> */}
                                        <span className='error' id='emailError'>
                                          {formErrors?.businessName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class='info-icon-body mt-2'>
                                    <div class='more-info-icon'>
                                      <i class='icon-bulb'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>maximum 25 characters are allowed.</p>
                                    </div>
                                  </div>
                                </CardBody>
                              </section>

                              <div className='divder-form'></div>
                            </>
                          )}
                          {showBusinessLogo && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6> Business Logo</h6>
                                  {businessLogo?.length == 0 && (
                                    <p>Add business Logo</p>
                                  )}
                                </CardHeader>
                                <CardBody>
                                  {[...businessLogo]?.map((item, index) => {
                                    return (
                                      <div
                                        className='slideshow-box-group-rept'
                                        key={index}
                                      >
                                        <div className='slideshow-box d-flex'>
                                          <div className='slideshow-imgbox d-flex align-items-center'>
                                            {renderMedia(item)}
                                          </div>
                                          <div className='slideshow-cont'>
                                            <div className='slideshow-cont-top'>
                                              <p>
                                                <i className='icon-images'></i>
                                                {item?.mediaName ||
                                                  item?.medFileName}
                                              </p>
                                            </div>
                                          </div>
                                          <button
                                            className='close-slideshow'
                                            onClick={() => setBusinessLogo([])}
                                          >
                                            <i className='icon-close' />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {businessLogo?.length == 0 && (
                                    <div className='add-section'>
                                      <button
                                        className='btn-add-plus'
                                        onClick={() =>
                                          setShowMediaWidgetBusinessLogo(true)
                                        }
                                      >
                                        <i className='icon-plus-circle' />
                                        Add Business Logo
                                      </button>
                                    </div>
                                  )}

                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      {logoerror && (
                                        <span className='error' id='emailError'>
                                          {logoerror}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </CardBody>
                              </section>

                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* logos starts */}
                          {showLogos && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Logos</h6>
                                  <p>
                                    Add upto{' '}
                                    {currentCampaignTypeName == 'Demand Gen'
                                      ? 1
                                      : 5}{' '}
                                    Logo
                                  </p>
                                </CardHeader>
                                <CardBody>
                                  {[]?.map((item, index) => {
                                    return (
                                      <div
                                        className='slideshow-box-group-rept'
                                        key={index}
                                      >
                                        <div className='slideshow-box d-flex'>
                                          <div className='slideshow-imgbox d-flex align-items-center'>
                                            {/* {renderMedia(item)} */}
                                          </div>
                                          <div className='slideshow-cont'>
                                            <div className='slideshow-cont-top'>
                                              <p>
                                                <i className='icon-images'></i>
                                                {/* {item?.mediaName} */}
                                              </p>
                                            </div>
                                          </div>
                                          <button
                                            className='close-slideshow'
                                            // onClick={() => setBusinessLogo([])}
                                          >
                                            <i className='icon-close' />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {businessLogo?.length > 0 && (
                                    <DisplaySelectedWidgetItem
                                      handledeleteItem={handledeleteLogoItem}
                                      item={businessLogo}
                                    />
                                  )}
                                  {
                                    <div className='add-section'>
                                      <button
                                        className='btn-add-plus'
                                        onClick={() =>
                                          setShowMediaWidgetBusinessLogo(true)
                                        }
                                      >
                                        {currentCampaignTypeName ==
                                          'Demand Gen' &&
                                        businessLogo?.length == 0 ? (
                                          <>
                                            <i className='icon-plus-circle' />
                                            Add Logos
                                          </>
                                        ) : (
                                          currentCampaignTypeName !=
                                            'Demand Gen' && (
                                            <>
                                              <i className='icon-plus-circle' />
                                              Add Logos
                                            </>
                                          )
                                        )}
                                      </button>
                                    </div>
                                  }

                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      {logoerror && (
                                        <span className='error' id='emailError'>
                                          {logoerror}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* logos ends */}
                          {/* Cards starts */}
                          {/* {showMediaCards &&
                            state?.creativeType == 'Carousel' && (
                              <>
                                <section className='group-section-data'>
                                  <CardHeader>
                                    <h6>Cards</h6>
                                    <p>Add upto 10 cards</p>
                                  </CardHeader>
                                  <CardBody>
                                    <DisplaySelectedItemsInAccordian
                                      images={getSelectedCardsImages}
                                      setSelectedCardsImages={
                                        setSelectedCardsImages
                                      }
                                      getCallToActionLists={
                                        getCallToActionLists
                                      }
                                    />
                                    {
                                      <div className='add-section'>
                                        <button
                                          className='btn-add-plus'
                                          onClick={() =>
                                            setShowMediaWidgetCards(true)
                                          }
                                        >
                                          <i className='icon-plus-circle' />
                                          Add Cards
                                        </button>
                                      </div>
                                    }
                                  </CardBody>
                                </section>
                                <div className='divder-form'></div>
                              </>
                            )} */}
                          {/* Cards ends */}
                          {/* News Cards start */}
                          {showMediaCards &&
                            state?.creativeType == 'Carousel' && (
                              <div className='card-primary-repet'>
                                <div className='card-primary'>
                                  <div className='card-header-top'>
                                    <div className='card-lt-pane'>
                                      <h5>Cards</h5>
                                      <p>Add upto 10 cards</p>
                                    </div>
                                  </div>

                                  <div className='hide-show'>
                                    <div className='card-body'>
                                      <div className='carosuel-accordion-full'>
                                        <section className='accordion-primary-cont'>
                                          {getGoogleAdCoverCarousel
                                            ?.filter(
                                              (item) => item?.status == 1,
                                            )
                                            ?.map((item, index) => (
                                              <DisplaySelectedAccordianItems
                                                index={index}
                                                item={item}
                                                openCardLandScapeWidget={() =>
                                                  openCardLandScapeWidget(index)
                                                }
                                                openCardSquareImageWidget={() =>
                                                  openCardSquareImageWidget(
                                                    index,
                                                  )
                                                }
                                                openCardPotraitImageWidget={() =>
                                                  openCardPotraitImageWidget(
                                                    index,
                                                  )
                                                }
                                                callBackFunction={
                                                  insertCardItems
                                                }
                                                getCallToActionLists={
                                                  getCallToActionLists
                                                }
                                                removeCardImage={
                                                  handleRemoveCardImage
                                                }
                                                error={
                                                  formErrors?.cardImagesError
                                                }
                                                removeCard={handleRemoveCard}
                                                getCurrentPageStatus={
                                                  getCurrentPageStatus
                                                }
                                              />
                                            ))}
                                        </section>
                                        {getGoogleAdCoverCarousel?.filter(
                                          (item) => item?.status == 1,
                                        )?.length < 10 && (
                                          <div className='add-section'>
                                            <button
                                              className='btn-add-plus'
                                              onClick={handleAddCardsObj}
                                            >
                                              <i className='icon-plus-circle' />
                                              Add Cards
                                            </button>
                                          </div>
                                        )}
                                        <div className='media-btn'>
                                          <div className='file-error'>
                                            <span
                                              className='error'
                                              id='emailError'
                                            >
                                              {formErrors?.cardImagesError}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='divder-form'></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* News Cards end */}
                          {/* videos starts */}
                          {showVideos || state?.creativeType == 'Video' ? (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Videos</h6>
                                  <p>{`Add upto ${
                                    currentCampaignTypeName == 'App' ? 20 : 5
                                  } Videos`}</p>
                                </CardHeader>
                                <CardBody>
                                  {selectedVideoItem?.length > 0 && (
                                    <DisplaySelectedWidgetItem
                                      handledeleteItem={handledeleteVideoItem}
                                      item={selectedVideoItem}
                                    />
                                  )}
                                  {/* {businessLogo?.length == 0 && (
                                    <div className='add-section'>
                                      <button
                                        className='btn-add-plus'
                                        onClick={openMediaWidgetVideoCampaign}
                                      >
                                        <i className='icon-plus-circle' />
                                        Add Videos
                                      </button>
                                    </div>
                                  )} */}
                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={openMediaWidgetVideoCampaign}
                                    >
                                      <i className='icon-plus-circle' />
                                      Add Videos
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      {logoerror && (
                                        <span className='error' id='emailError'>
                                          {logoerror}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* videos ends */}
                          {/* HTML starts */}
                          {showHTML && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>HTML5</h6>
                                  <p>Add upto 20 HTML5 file</p>
                                </CardHeader>
                                <CardBody>
                                  {getSelectedHTMLItems?.map((item, index) => {
                                    return (
                                      <div
                                        className='slideshow-box-group-rept'
                                        key={index}
                                      >
                                        <div className='slideshow-box d-flex'>
                                          <div className='slideshow-imgbox d-flex align-items-center'>
                                            {renderMedia(item)}
                                          </div>
                                          <div className='slideshow-cont'>
                                            <div className='slideshow-cont-top'>
                                              <p>
                                                <i className='icon-images'></i>
                                                {item?.mediaName ||
                                                  item?.medFileName}
                                              </p>
                                            </div>
                                          </div>
                                          <button
                                            className='close-slideshow'
                                            onClick={() =>
                                              setSelectedHTMLItems((prev) => [
                                                ...prev.slice(0, index),
                                                ...prev.slice(index + 1),
                                              ])
                                            }
                                          >
                                            <i className='icon-close' />
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className='add-section'>
                                    <button
                                      className='btn-add-plus'
                                      onClick={(e) => openMediaWidgetHTML(e)}
                                    >
                                      <i className='icon-plus-circle' />
                                      Add HTML5
                                    </button>
                                  </div>

                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      {logoerror && (
                                        <span className='error' id='emailError'>
                                          {logoerror}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* HTML ends */}
                          {showSiteLinks || state?.creativeType == 'Video' ? (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Sitelinks</h6>
                                  <p className='mb-2'>
                                    Select 4 or more to maximize performance
                                  </p>
                                </CardHeader>
                                <CardBody>
                                  <ReactSelect
                                    options={formatDataSiteLinks(
                                      state?.site_links,
                                    )}
                                    isMulti={true}
                                    onChange={selctedSiteLinks}
                                    value={getSelectedSiteLinksVal}
                                    closeMenuOnSelect={true}
                                  />
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* call to action starts */}
                          {showCallToAction && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Call to action</h6>
                                </CardHeader>
                                <CardBody>
                                  <ReactSelect
                                    options={getCallToActionLists}
                                    closeMenuOnSelect={true}
                                    onChange={handleCallToAction}
                                    value={getLocalCallToActionVal}
                                  />
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* call to action ends */}
                          {/* Single headline start */}
                          {showSingleHeadLine ||
                          state?.creativeType == 'Products' ||
                          state?.creativeType == 'Carousel' ? (
                            <SingleInput
                              label={'Headline'}
                              totalStringLength={15}
                              handleSetValue={handleSetHeadLineVal}
                              bgLineDisplay={true}
                              value={
                                state?.heading?.[state?.heading?.length - 1]
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {/* Single headline end */}
                          {/* Single Long Headline start */}
                          {showSingleLongHeadLine ||
                          state?.creativeType == 'Video' ? (
                            <SingleInput
                              label={'Long Headline'}
                              totalStringLength={90}
                              handleSetValue={handleSetLongHeadLineVal}
                              bgLineDisplay={true}
                              error={formErrors?.singleHeadingError}
                              totalLength={
                                state?.headingLong?.[
                                  state?.headingLong?.length - 1
                                ]?.length
                              }
                              value={
                                state?.headingLong?.[
                                  state?.headingLong?.length - 1
                                ]
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {/* Single Long Headline end */}
                          {/* Single Description start */}
                          {showSingleDescription ||
                          state?.creativeType == 'Products' ||
                          state?.creativeType == 'Carousel' ? (
                            <SingleInput
                              label={'Description'}
                              totalStringLength={70}
                              handleSetValue={handleSetDescriptionVal}
                              bgLineDisplay={true}
                              totalLength={
                                state?.description?.[
                                  state?.description?.length - 1
                                ]?.length
                              }
                              value={
                                state?.description?.[
                                  state?.description?.length - 1
                                ]
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {/* Single Description end */}
                          {/* Addtional format options start */}
                          {showAdditionalFormats && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Additional format options</h6>
                                </CardHeader>
                                <AdditionalFormats
                                  handleSetValue={handleSetAdditionalFormat}
                                  value={getLocalAddtionalFomatsValue}
                                />
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* Addtional format options end */}
                          {/* Add Url option start */}
                          {showAdUrlOption && (
                            <AdUrlOption
                              trackingTemplateOptions={getTrackingTemplateLists}
                              handleSetValue={handleSetTrackingTemplate}
                              handleSetFinalUrl={handleSetFinalUrlValue}
                              handleSetIsMobile={handleSetIsMobileValue}
                              handleSetFinalMobileUrl={
                                handleSetFinalMobileUrlValue
                              }
                              handleSetIsMobileStatus={
                                state?.isfinalmobileurlEnabled
                              }
                              finalUrlSuffix={state?.finalUrlSuffix}
                              finalUrlMobile={state?.finalUrlMobile}
                              trackingTemplateVal={
                                getSelectedTrackingItemLocalState
                              }
                            />
                          )}
                          {/* Add Url option end */}
                          {/* More options start */}
                          {showMoreOptions && (
                            <section className='group-section-data'>
                              <CardHeader>
                                <h6>More options</h6>
                              </CardHeader>
                              <MoreOptions
                                handleIsEnabledCallToAction={
                                  handleIsEnabledCallToActionValue
                                }
                                handleIsCustomColorEnabled={
                                  handleIsCustomColorEnabledValue
                                }
                                getCallToActionLists={getCallToActionLists}
                                handleCallToAction={handleCallToAction}
                                setCustomMainColor={handleSetCustomMainColor}
                                setAccentColor={handleSetAccentColor}
                                setIsCustomColorEnabled={
                                  handleSetIsCustomColorEnabled
                                }
                                callToActionValue={state?.iscalltoactionEnabled}
                                customColorValue={state?.iscolorEnabled}
                                customMainColor={state?.customMainColor}
                                customAccentColor={state?.customAccentColor}
                                iscustomcolorEnabled={
                                  state?.iscustomcolorEnabled
                                }
                                callToActionSelectedVal={
                                  getLocalCallToActionVal
                                }
                              />
                            </section>
                          )}
                          {/* More options end */}
                          {/* Companion banner start */}
                          {showCompanionBanner && (
                            <>
                              <section className='group-section-data'>
                                <CardBody>
                                  <CardHeader>
                                    <h6>Companion banner (computers only)</h6>
                                  </CardHeader>
                                  <CompanionBanner
                                    openMediaWidgetVideoUploadImage={
                                      openMediaWidgetVideoUploadImage
                                    }
                                    handleSetCompanionBanner={
                                      handleSetCompanionBannerValue
                                    }
                                    selectedValue={state?.companionBannerType}
                                  />
                                </CardBody>
                                {selectedImageItem?.length > 0 && (
                                  <DisplaySelectedWidgetItem
                                    handledeleteItem={handledeleteItem}
                                    item={selectedImageItem}
                                  />
                                )}
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}

                          {/* Companion banner end */}
                          {/* <div className="divder-form"></div> */}
                          {showCallOuts && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h6>Callouts</h6>
                                  <p>Add more business information</p>
                                </CardHeader>
                                <CardBody>
                                  <ReactSelect
                                    options={formatDataCallouts(
                                      state?.callouts,
                                    )}
                                    isMulti={true}
                                    onChange={selctedCallOuts}
                                    closeMenuOnSelect={true}
                                    value={selectedCallOutsVal}
                                  />
                                  {/* <div className='card-form form-panel'> */}
                                  {/* <div className='action-btn-both action-btn-both-check row relative mb-4'> */}
                                  {/* <div className='col-8 d-flex flex-wrap'> */}
                                  {/* {state?.callouts?.map((item, index) => {
                                        return (
                                          <div className='form-check-box col-6 mb-2'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              id={'inlineCheckbox9' + index}
                                              defaultValue='Display'
                                              name='creative'
                                              // onChange={onChangeHandler}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={'inlineCheckbox9' + index}
                                            >
                                              <div className='label-multitext'>
                                                {item?.name}
                                              </div>
                                            </label>
  
  
                                          </div>
                                        );
                                      })} */}
                                  {/* </div> */}
                                  {/* <span class="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                  {/* </div> */}
                                  {/* </div> */}
                                  {/* <div className="add-section">
                                  <button className="btn-add-plus">
                                    <i className="icon-plus-circle" />
                                    Add callout text
                                  </button>
                                </div> */}
                                </CardBody>
                              </section>
                              {/* <div className="divder-form"></div> */}
                              <div className='divder-form'></div>
                            </>
                          )}
                          {showMoreAssetTypes && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h5>More asset types (0/5)</h5>
                                  <p>
                                    Improve your ad performance and make your ad
                                    more interactive by adding more details
                                    about your business and website
                                  </p>
                                  <h6>Promotions</h6>
                                  <p>Select promotions</p>
                                </CardHeader>
                                <CardBody>
                                  <ReactSelect
                                    options={formatDataPromotions(
                                      state?.promotions,
                                    )}
                                    isMulti={true}
                                    onChange={selctedPromotions}
                                    value={selectedPromotionsVal}
                                    closeMenuOnSelect={true}
                                  />
                                  {/* <div className='card-form form-panel'> */}
                                  {/* <div className='action-btn-both action-btn-both-check row relative mb-4'> */}
                                  {/* <div className='col-8 d-flex flex-wrap'> */}
                                  {/* {state?.promotions?.map((item, index) => {
                                        return (
                                          <div className='form-check-box col-8 mb-2'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              id={'inlineCheckboxx9o' + index}
                                              defaultValue='Display'
                                              name='creative'
                                              //onChange={onChangeHandler}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={
                                                'inlineCheckboxx9o' + index
                                              }
                                            >
                                              <div className='label-multitext'>
                                                {item?.name}
                                              </div>
                                            </label>
  
  
                                          </div>
                                        );
                                      })} */}
                                  {/* </div> */}
                                  {/* <span class="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                  {/* </div> */}
                                  {/* </div> */}
                                  {/* <div className="add-section">
                                  <button className="btn-add-plus">
                                    <i className="icon-plus-circle" />
                                    Add Promotions
                                  </button>
                                </div> */}
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {showPrices && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h5>Prices</h5>
                                  <p>Ad prices</p>
                                  <h6>Campaign-level prices</h6>
                                  <p>
                                    Select prices to this campaign. Any prices
                                    added here can be used across campaigns.
                                  </p>
                                </CardHeader>
                                <CardBody>
                                  <ReactSelect
                                    options={formatDataPrices(state?.prices)}
                                    isMulti={true}
                                    onChange={selctedPrices}
                                    value={selectedPricesVal}
                                    closeMenuOnSelect={true}
                                  />
                                  {/* <div className='card-form form-panel'> */}
                                  {/* <div className='action-btn-both action-btn-both-check row relative mb-4'> */}
                                  {/* <div className='col-8 d-flex flex-wrap'> */}
                                  {/* {state?.prices?.map((item, index) => {
                                      return (
                                        <div className='form-check-box col-8 mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id={'inlineCheckbox9fv' + index}
                                            defaultValue='Display'
                                            name='creative'
                                            //onChange={onChangeHandler}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor={
                                              'inlineCheckbox9fv' + index
                                            }
                                          >
                                            <div className='label-multitext'>
                                              {item?.name}
                                            </div>
                                          </label>


                                        </div>
                                      );
                                    })} */}
                                  {/* </div> */}
                                  {/* <span class="error" id="emailError">
                                    Please enter valid email id
                                  </span> */}
                                  {/* </div> */}
                                  {/* </div> */}
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {showStructuredSnippets && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h5>Structured snippets</h5>
                                  <p>Select snippets of text</p>
                                </CardHeader>

                                <div className='card-form form-panel'>
                                  <CardBody>
                                    <ReactSelect
                                      options={formatDataStructuredSnippets(
                                        state?.structured_snippets,
                                      )}
                                      isMulti={true}
                                      onChange={selctedStructuredsnippets}
                                      value={selectedStructuredsnippetsVal}
                                      closeMenuOnSelect={true}
                                    />
                                    {/* <h6 className="card-header-title">Values</h6> */}
                                    {/* <div className='action-btn-both action-btn-both-check row relative mb-4'> */}
                                    {/* <div className='col-8 d-flex flex-wrap'> */}
                                    {/* {state?.structured_snippets?.map(
                                        (item, index) => {
                                          return (
                                            <div className='form-check-box col-8 mb-2'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id={'inlineCheckbox9vb' + index}
                                                defaultValue='Display'
                                                name='creative'
                                                //onChange={onChangeHandler}
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor={
                                                  'inlineCheckbox9vb' + index
                                                }
                                              >
                                                <div className='label-multitext'>
                                                  {item?.name}
                                                </div>
                                              </label>
  
  
                                            </div>
                                          );
                                        },
                                      )} */}
                                    {/* </div> */}
                                    {/* <span class="error" id="emailError">
                                      Please enter valid email id
                                    </span> */}
                                    {/* </div> */}
                                  </CardBody>
                                </div>
                                {/* <div className="add-section">
                                <button className="btn-add-plus">
                                  <i className="icon-plus-circle" />
                                  New structured snippet
                                </button>
                              </div> */}
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* apps start */}
                          {showApps && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h5>Apps</h5>
                                  <p>Select apps</p>
                                </CardHeader>
                                <CardBody>
                                  <ReactSelect
                                    options={formatDataApps(state?.apps)}
                                    isMulti={true}
                                    onChange={selctedApps}
                                    value={selectedAppsVal}
                                    closeMenuOnSelect={true}
                                  />
                                </CardBody>
                              </section>

                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* apps end */}
                          {/* signals starts */}
                          {showSignals && (
                            <>
                              <section className='group-section-data'>
                                <CardHeader>
                                  <h5>Signals</h5>
                                  {/* <p>Select apps</p> */}
                                </CardHeader>
                                <CardBody>
                                  {/* <ReactSelect

                              /> */}
                                </CardBody>
                              </section>
                              <div className='divder-form'></div>
                            </>
                          )}
                          {/* signals ends */}
                          {/* App url start */}
                          {getCurrentCampaignSubType !=
                            'App_Pre_Registration' &&
                            getCurrentCampaignSubType != '' && (
                              <AppUrl
                                handlesetAppUrlVal={handlesetAppUrlVal}
                                value={state?.appUrl}
                                appUrlError={formErrors?.appUrlError}
                              />
                            )}
                          {/* App url end */}
                        </Card>
                        {/* {showListingGroupsStatus && (
                          <ListingGroups
                            handleSetValue={handleSetValue}
                            getSelectedListingGroupType={
                              getSelectedListingGroupType
                            }
                            handleUploadFile={handleUploadFile}
                            getCSVFileInfo={getCSVFileInfo}
                            handleDownloadFile={handleDownloadFile}
                            listingGroupError={formErrors?.listingGroupError}
                            getCurrentPageStatus={getCurrentPageStatus}
                          />
                        )} */}
                      </article>
                    </div>
                  </div>

                  {/* view starts */}
                  <div
                    className='tab-pane fade'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        <div className='card-primary'>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Campaign name</h5>
                              <p>New Awareness Campaign</p>
                              <p>ID: 23857805967620728</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Special ad categories</h5>
                              <p>No categories declared</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Buying type</h5>
                              <p>Awareness</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Ad sets</h5>
                              <p className='add-data'>Please add: Ad sets</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Format</h5>
                              <p>Image and Video</p>
                            </div>
                            <div className='card-rt-pane d-flex me-auto align-items-center'>
                              <figure className='prev-icon'>
                                <img src={atdplaceholder} alt='' />
                              </figure>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* view ends */}
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  ></div>
                </div>
              </article>
            </section>
          </div>
          <CommonModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
              navigate('/google-ad/campaign-listing');
            }}
            copy={'Your Google Ads Campaign has been successfully created.'}
          />

          {/* LandScape Media widget start */}
          <MediaWidget
            show={showMediaLandScapeWidget}
            handleClose={handleCloseMediaWidgetLandScape}
            setImages={setLandScapeImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={getLandScapeImages}
            ratioStatus={true}
            ratio={'1.91'}
          />
          {/* LandScape Media widget end */}

          {/* Square Media widget start */}
          <MediaWidget
            show={showMediaSquareImageWidget}
            handleClose={handleCloseMediaWidgetSquare}
            setImages={setMediaSquareImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={getMediaSquareImages}
            ratioStatus={true}
            ratio={'1.00'}
          />
          {/* Square Media widget end */}

          {/* Logo Land Scape Media widget start */}
          <MediaWidget
            show={showMediaLogoLandScapeWidget}
            handleClose={handleCloseMediaWidgetLogoLandScape}
            setImages={setLogoLandScapeImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={getLogoLandScapeImages}
            ratioStatus={true}
            ratio={'4.00'}
          />
          {/* Logo Land Scape Media widget end */}

          {/* Square logo Media widget start */}
          <MediaWidget
            show={showMediaSqaureLogoWidget}
            handleClose={handleCloseMediaWidgetSquareLogo}
            setImages={setSquareLogoImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={getSquareLogoImages}
            ratioStatus={true}
            ratio={'1.00'}
          />
          {/* Square logo Media widget end */}

          {/* Potrait Media widget start */}
          <MediaWidget
            show={showMediaPotraitWidget}
            handleClose={handleCloseMediaWidgetPotrait}
            setImages={setPotraitImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={getPotraitImages}
            ratioStatus={true}
            ratio={'0.80'}
          />
          {/* Potrait Media widget end */}

          <MediaWidget
            show={showMediaWidget}
            handleClose={handleCloseMediaWidget}
            setImages={setImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={images}
          />
          {/* Business logo */}
          <MediaWidget
            show={showMediaWidgetBusinessLogo}
            handleClose={handleCloseMediaWidgetBusinessLogo}
            setImages={setImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={businessLogo}
            onlyOneImage={
              currentCampaignTypeName == 'Display' ||
              currentCampaignTypeName == 'Demand Gen' ||
              currentCampaignTypeName == 'Performance Max'
                ? false
                : true
            } // In video type user can select only single image and in display type user can select multiple images.
            total={5}
          />
          {/* Video type */}
          {showAssignVideoMediaWidget && (
            <MediaWidget
              show={showAssignVideoMediaWidget}
              handleClose={handleCloseMediaWidgetVideoCampign}
              setImages={setSelectedVideoItem}
              platformType={'Google Ad'}
              selectedTab={'video'}
              images={selectedVideoItem}
              onlyOneImage={
                currentCampaignTypeName == 'Display' ||
                currentCampaignTypeName == 'Performance Max' ||
                currentCampaignTypeName == 'App' ||
                currentCampaignTypeName == 'Demand Gen'
                  ? false
                  : true
              } // In video type user can select only single image and in display type user can select multiple images.
              flag={'Youtube'}
              total={
                currentCampaignTypeName == 'App'
                  ? 20
                  : currentCampaignTypeName == 'Display'
                    ? 100
                    : 5
              }
            />
          )}
          {/* Image Type */}
          {showAssignUploadImage && (
            <MediaWidget
              show={showAssignUploadImage}
              handleClose={handleCloseMediaWidgetUploadImage}
              setImages={setImages}
              platformType={'Google Ad'}
              selectedTab={'Display'}
              images={images}
              onlyOneImage={true}
              flag={'Display'}
              total={
                currentCampaignTypeName == 'Demand Gen' ||
                currentCampaignTypeName == 'Performance Max' ||
                currentCampaignTypeName == 'App'
                  ? 20
                  : 15
              }
            />
          )}
          {/* Cards images */}
          {
            <MediaWidget
              show={showMediaWidgetCards}
              handleClose={handleCloseMediaCards}
              setImages={setSelectedCardsImages}
              platformType={'Google Ad'}
              selectedTab={'Display'}
              images={getSelectedCardsImages}
              onlyOneImage={false}
              flag={'Display'}
              total={10}
              ratioStatus={true}
              ratio={'1.91'}
            />
          }
          {/* HTML Type */}
          {showHTMLUpload && (
            <MediaWidget
              show={showHTMLUpload}
              handleClose={handleCloseMediaWidgetHTML}
              // setImages={setSelectedHTMLItems}
              platformType={'Google Ad'}
              selectedTab={'Html'}
              images={getSelectedHTMLItems}
              flag={'Html'}
              onlyOneImage={false}
            />
          )}
          {/* Show Success model start */}
          <CommonModal
            show={showSucessModal}
            handleClose={() => setSucessModelStatus(false)}
            handleRedirection={() => handleRedirection()}
            copy={'Ad has been successfully saved.'}
            isUpdatedStatus={true}
          />
          {/* Show Success model end */}
          {/* Card LandScape Media widget start */}
          <MediaWidget
            show={showMediaCardLandScapeWidget}
            handleClose={handleCloseMediaWidgetCardLandScape}
            // setImages={setCardLandScapeImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={[]}
            ratioStatus={true}
            ratio={'1.91'}
            total={1}
          />
          {/* Card LandScape Media widget end */}

          {/* Card Square Image Media widget start */}
          <MediaWidget
            show={showMediaCardSquareImageWidget}
            handleClose={handleCloseMediaWidgetCardSquareImage}
            // setImages={setCardLandScapeImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={[]}
            ratioStatus={true}
            ratio={'1.00'}
            total={1}
          />
          {/* Card Square Image Media widget end */}

          {/* Card Potrait Image Media widget start */}
          <MediaWidget
            show={showMediaCardPotraitImageWidget}
            handleClose={handleCloseMediaWidgetCardPotraitImage}
            // setImages={setCardLandScapeImages}
            platformType={'Google Ad'}
            selectedTab={'Display'}
            images={[]}
            ratioStatus={true}
            ratio={'0.80'}
            total={1}
          />
          {/* Card Potrait Image Media widget end */}
          {getApprovalPublisherModelShowStatus && (
            <SelectApprovalPublisher
              type={'Google'}
              handleApprovalPublisher={handleApprovalPublisher}
              handleSetPublisherId={handleSetPublisherId}
              getPublisherInfo={getPublisherInfo}
              getPublisherError={getPublisherError}
              handleCloseModel={handleCloseModel}
            />
          )}
          {getApprovalCoinfirmationPopupShowStatus && (
            <ApprovalConfirmationPopup
              type={'Google'}
              handleApprovalPublisherRequest={handleApprovalPublisherRequest}
              handleCloseModel={handleCloseModel}
              isPublisher={isPublisher}
            />
          )}
          {getThankYouApprovalPopupShowStatus && (
            <ThankYouApprovalPopup
              handleRedirect={handleRedirection}
              type={'Google'}
              approved={isPublisher ? 'approved' : ''}
            />
          )}
          <Footer
            prevPath={GoogleAdOptions.AdGroups.newGoogleAdGroup}
            nextBtnlabel={typeof id != 'undefined' ? 'Save' : 'Submit'}
            getAtdStatus={getAtdStatus}
            handleSubmit={handleNext}
          >
            {' '}
          </Footer>
          {loader && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default GoogleAd;
