import '../../../assets/css/style.css';
import Sidebar from '../../../components/common/Sidebar';
import SideTreeMenu from '../../../components/common/SideTreeMenu';
import Footer from '../../../components/common/Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { useEffect } from 'react';
import { DV360Options } from '../DV360Constant';
import DataDetails from './Component/DataDetails';
import { useState } from 'react';
import moment from 'moment';
import { capitalizeFirstLetter, checkForNull } from '../../../utils/utils';
import Loader from '../../../components/common/Loader';
import SideTreeMenuView from '../Component/SideTreeMenuView';
import DvErrorDisplay from '../../../components/dv360/dvErrorDisplay';
import ApproveReject from '../../../components/common/ApproveReject';
import { useSelector } from 'react-redux';
import ReviewLog from '../../googleAds/ReviewLog/ReviewLog';
import ThankYouApprovalPopup from '../../../components/common/ThankYouApprovalPopup';
import DisplayCampaignStatus from '../../../components/common/DisplayCampaignStatus';

const CampaignsView = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [campaignError, setCampaignError] = useState('');
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const [getCurrentStatus, setCurrentStatus] = useState('');
  const [isViewPage, setIsViewPage] = useState(true);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getApprovalFlag, setApprovalFlag] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  useEffect(() => {
    if (query.get('platForm') === DV360Options.Campaigns.platform) {
      setIsViewPage(false);
    } else {
      setIsViewPage(true);
    }
  }, [query.get('platForm')]);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `${baseUrlStg}/api/Campaign/getCampaignByID?campaignID=${id}`,
      );
      if (response) {
        setData(response.response);
        setCampaignError(response.response['campaign_id']);
        setCurrentStatus(response.response?.moderation_status);
        setApprovalFlag(response?.approvalButton);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    campaign_name = '-',
    campaign_id = '-',
    kpi_type = '-',
    kpi_value = '-',
    creative_type = '-',
    planned_spend = '-',
    planned_startdate = '-',
    planned_enddate = '-',
    frequency_cap_limit_value = '-',
    frequency_limit_type = '-',
    dv360_campaign_id = '-',
    frequency_limit = '-',
    frequency_cap_type = '-',
  } = data || {};

  const campaignListUrl = DV360Options.Campaigns.url;

  const goToListingPage = () => {
    navigate(campaignListUrl);
  };

  const handleRedirection = () => {
    navigate(campaignListUrl);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              <SideTreeMenuView
                id={id}
                IdAndType={{ id: id, type: DV360Options.Campaigns.name }}
                AtdStatus={capitalizeFirstLetter(data?.moderation_status)}
                Dv360Status={capitalizeFirstLetter(data?.is_enabled) || 'NA'}
              />
              <article className='side-right-form-wrap side-right-pad'>
                <div className='tab-new-form'>
                  <div className='camp-tab-cont d-flex'>
                    <div className='short-left-bar'>
                      <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <Link
                          className={`primary-btn ${
                            isViewPage ? 'active' : ''
                          }`}
                          to={`${DV360Options.Campaigns.viewPageUrl}/${id}`}
                        >
                          <i className='icon-show-password'></i> View
                        </Link>
                        {getCurrentStatus == 'in_moderation' ? (
                          <Link
                            class='primary-btn disable-button'
                            title='Entry under moderation: Editing disabled until review completion.'
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        ) : (
                          <Link
                            class='primary-btn'
                            to={DV360Options.Campaigns.editUrl + `/${id}`}
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        )}
                        <Link
                          className={`primary-btn ${
                            !isViewPage ? 'active' : ''
                          }`}
                          to={`${DV360Options.Campaigns.viewPageUrl}/${id}?platForm=${DV360Options.Campaigns.platform}&form=${DV360Options.Campaigns.reviewForm}`}
                        >
                          <i className='icon-history'></i> Review Log
                        </Link>
                      </div>
                    </div>
                    {getCurrentStatus == 'in_moderation' &&
                      getUserInfo?.roles == 'Publisher' &&
                      getApprovalFlag && (
                        <ApproveReject
                          platform={'DV360'}
                          campaignId={id}
                          getUserInfo={getUserInfo}
                          setThankYouApprovalPopupShowStatus={
                            setThankYouApprovalPopupShowStatus
                          }
                          getThankYouApprovalPopupShowStatus={
                            getThankYouApprovalPopupShowStatus
                          }
                          setDisplayLoader={setDisplayLoader}
                        />
                      )}
                  </div>
                </div>
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  <DvErrorDisplay id={campaignError} />

                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        {isViewPage ? (
                          <div className='card-primary'>
                            <DisplayCampaignStatus
                              moderationStatus={getCurrentStatus}
                              publishedAt={data?.updated_at}
                            />
                            <DataDetails
                              Label={'Campaign name'}
                              LabelFor={campaign_name}
                            />
                            <DataDetails
                              Label={'DV360 campaign id'}
                              LabelFor={dv360_campaign_id}
                            />
                            <DataDetails Label={'KPI'} LabelFor={kpi_type} />
                            <DataDetails
                              Label={'KPI Value'}
                              LabelFor={kpi_value}
                            />
                            <DataDetails
                              Label={'Creative type you expect to use'}
                              LabelFor={creative_type}
                            />
                            <DataDetails
                              Label={'Planned spend'}
                              LabelFor={planned_spend}
                            />

                            <DataDetails
                              Label={'Start date'}
                              LabelFor={moment(planned_startdate).format(
                                'DD-MMM-YYYY',
                              )}
                            />
                            <DataDetails
                              Label={'End date'}
                              LabelFor={
                                moment(planned_enddate).format('DD-MMM-YYYY') ||
                                '-'
                              }
                            />

                            <DataDetails
                              Label={'Frequency Cap'}
                              LabelFor={
                                frequency_cap_type === 'no limit'
                                  ? 'No Limit'
                                  : `Limit frequency to ${checkForNull(
                                      frequency_cap_limit_value,
                                    )} ${
                                      frequency_limit_type ===
                                      'Lifetime of this campaign'
                                        ? 'Lifetime of this campaign'
                                        : `exposures per ${checkForNull(
                                            frequency_limit,
                                          )} ${checkForNull(
                                            frequency_limit_type,
                                          )}`
                                    }`
                              }
                            />
                          </div>
                        ) : (
                          <ReviewLog
                            id={id}
                            form={DV360Options.Campaigns.reviewForm}
                            platForm={DV360Options.Campaigns.platform}
                          ></ReviewLog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  >
                    History
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer
        closePath={campaignListUrl}
        prevPath={campaignListUrl}
        // nextBtnlabel='Next'
        // nextPath={campaignListUrl}
      ></Footer>
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          type={'Dv360'}
          approved={'approved'}
        />
      )}
      {displayLoader && <Loader />}
    </div>
  );
};

export default CampaignsView;
