import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';

const Devices = (props) => {
  return (
    <>
      <CardBody>
        {/* Operating system start */}
        <div className='card-form form-panel'>
          <div className='row align-items-center'>
            <div className='form-item input-group-label col-4'>
              <ReactSelect
                options={props?.listing}
                closeMenuOnSelect={true}
                onChange={props?.handleSelectOption}
                // value={props?.listing?.find(
                //   (val) => val?.label == props?.firstDropdownVal,
                // )}
                // value={[
                //   {
                //     label: props?.firstDropdownVal,
                //     value: props?.firstDropdownVal,
                //   },
                // ]}
                value={props?.firstDropdownVal}
              />
            </div>
            <div
              className='form-item input-group-label col-4'
              // style={{
              //   display: `${props?.minorOptions?.length > 0 ? '' : 'none'}`,
              // }}
            >
              <ReactSelect
                options={props?.minorOptions}
                closeMenuOnSelect={true}
                onChange={props?.handleSelectFinalOption}
                isDisabled={props?.minorOptions?.length > 0 ? false : true}
                value={props?.secondDropdownVal}
              />
            </div>
            <div
              className='form-item input-group-label col-4'
              // style={{
              //   display: `${props?.minorOptions?.length > 0 ? '' : 'none'}`,
              // }}
            >
              <ReactSelect
                options={props?.majorOptions}
                closeMenuOnSelect={true}
                onChange={props?.handleFinalSelectedOptions}
                isDisabled={props?.majorOptions?.length > 0 ? false : true}
                // value={props?.majorOptions?.find(
                //   (val) => val?.label == props?.thirdDropdownVal,
                // )}
                value={props?.thirdDropdownVal}
              />
            </div>
          </div>
        </div>
        {/* Operating system end */}
      </CardBody>
    </>
  );
};

export default Devices;
