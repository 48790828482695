import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { DV360Options } from '../DV360Constant';
import { capitalizeFirstLetter } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';

function SideTreeMenuView({ id, IdAndType, AtdStatus = '', Dv360Status = '' }) {
  let isCreateMode = false;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      if (id) {
        setIsLoading(true);
        const response = await getApi(
          `${baseUrlStg}/api/Campaign/GetCampaignWithInsertionOrderAndLineItemByID?campaignID=${id}`,
        );
        if (response) {
          setData(response.response[0]);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);

  let campaignName = data?.campaign_name;
  let campaignId = data?.campaign_id;
  let insertionOrders = data?.insertionOrders;
  const navigate = useNavigate();

  let Id = IdAndType?.id || '12';
  let Type = IdAndType?.type || DV360Options.Campaigns.name;

  useEffect(() => {
    if (Id) {
      if (Type == DV360Options.Campaigns.name) {
        setSelectedItemId(-1);
        setSelectedItemType(DV360Options.Campaigns.name);
      } else if (Type == DV360Options.Insertion_Order.name) {
        setSelectedItemId(Id);
        setSelectedItemType(DV360Options.Insertion_Order.name);
      } else if (Type == DV360Options.Line_Item.name) {
        setSelectedItemId(Id);
        setSelectedItemType(DV360Options.Line_Item.name);
      }
    }
  }, [Id]);

  useEffect(() => {
    // Scroll the active item into view
    setTimeout(() => {
      if (selectedItemId) {
        const element = document.getElementById(
          `item-${selectedItemType}-${selectedItemId}`,
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        console.log('first', { selectedItemId, element, selectedItemType });
      }
    }, 2000);
  }, [selectedItemId, selectedItemType]);

  const handleClick = () => {
    console.log('handleClick');
  };

  const handleDirection = () => {
    console.log('handleDirection');
  };

  const statusClass = {
    Draft: 'status-draft',
    'In Moderation': 'status-moderation',
    Published: 'status-published',
    Rejected: 'status-rejected',
    api_error: 'status-error',
    'Api Error': 'status-error',
    'Published(Enabled)': 'status-published',
    Enabled: 'status-published',
    'Published(Disabled)': 'status-na-disable',
    Disabled: 'status-na-disable',
    draft: 'status-draft',
    in_moderation: 'status-moderation',
    null: 'status-na-disable',
    NA: 'status-na-disable',
  };
  const atdStatusClassName = `${statusClass[AtdStatus]} status-cont-top view`;
  const Dv360StatusClassName = `${statusClass[Dv360Status]} status-cont-top view`;

  const Dv360StatusText =
    Dv360Status?.toLowerCase() == 'enabled'
      ? 'Published(Enabled)'
      : Dv360Status;

  return (
    <>
      <article className='side-tree-menu'>
        {isLoading ? (
          <div>
            <Skeleton height={40} count={5} />
          </div>
        ) : (
          <>
            <div className={atdStatusClassName}>
              <div className='status-cont-left'>
                <div className='status-lt'>
                  <span>ATD Status :</span>
                  <span>{AtdStatus}</span>
                </div>
              </div>
              <br />

              <div className='status-btn'>
                <button
                  type='button'
                  className='primary-btn close-btn'
                  href='javascript:void(0)'
                >
                  <i className='icon-right-arrow'></i>
                </button>
              </div>
            </div>
            {/* <br /> */}
            <div className={Dv360StatusClassName}>
              <div className='status-cont-left'>
                <div className='status-lt'>
                  <span>DV360 Status :</span>
                  <span>{Dv360StatusText}</span>
                </div>
              </div>

              <div className='status-btn'>
                <button
                  type='button'
                  className='primary-btn close-btn'
                  href='javascript:void(0)'
                >
                  <i className='icon-right-arrow'></i>
                </button>
              </div>
            </div>
            {/* <br /> */}
            <div className='nav-left scroll-vertical scroll-vertical-status'>
              <nav className='navbar'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link
                      id={`item-${DV360Options.Campaigns.name}-${campaignId}`}
                      className={`nav-link ${campaignName ? '' : 'active'} ${
                        selectedItemType == DV360Options.Campaigns.name
                          ? 'active'
                          : ''
                      }`}
                      aria-current='page'
                      to={`${DV360Options.Campaigns.viewPageUrl}/${campaignId}`}
                      onClick={(e) => {
                        handleClick(DV360Options.Campaigns.name);
                        handleDirection(
                          DV360Options.Campaigns.name,
                          campaignId,
                        );
                      }}
                    >
                      <i className='icon-camopaign'></i>
                      <span>{campaignName || ''}</span>
                      {/* <span>New Campaign</span> */}
                    </Link>
                  </li>
                  {insertionOrders?.length > 0
                    ? [...insertionOrders].map((insertionOrder, index) => {
                        return (
                          <li className='nav-item' key={index}>
                            <Link
                              id={`item-${DV360Options.Insertion_Order.name}-${insertionOrder?.insertion_order_id}`}
                              to={`${DV360Options.Insertion_Order.viewPageUrl}/${insertionOrder?.insertion_order_id}`}
                              className={`nav-link ${
                                selectedItemType ==
                                  DV360Options.Insertion_Order.name &&
                                selectedItemId ==
                                  insertionOrder?.insertion_order_id
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={(e) => handleClick(insertionOrder)}
                            >
                              <i className='icon-ad-set'></i>
                              <span>
                                {' '}
                                {insertionOrder?.insertion_order_name}
                              </span>
                            </Link>
                            <ul>
                              {insertionOrder?.lineItems?.length > 0 ? (
                                [...insertionOrder?.lineItems]?.map(
                                  (lineItem, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          id={`item-${DV360Options.Line_Item.name}-${lineItem?.line_item_id}`}
                                          to={`${DV360Options.Line_Item.viewPageUrl}/${lineItem?.line_item_id}`}
                                          href='#'
                                          className={`nav-link ${
                                            selectedItemType ==
                                              DV360Options.Line_Item.name &&
                                            selectedItemId ==
                                              lineItem?.line_item_id
                                              ? 'active'
                                              : ''
                                          }`}
                                          onClick={(e) => handleClick(lineItem)}
                                        >
                                          <i className='icon-ad'></i>
                                          <span>
                                            {lineItem?.line_item_name}
                                          </span>
                                        </Link>
                                      </li>
                                    );
                                  },
                                )
                              ) : insertionOrders?.length > 0 &&
                                isCreateMode ? (
                                <li>
                                  <a
                                    href='#'
                                    className={`nav-link ${
                                      selectedItemId == null ? 'active' : ''
                                    }`}
                                    onClick={() =>
                                      handleClick(DV360Options.Line_Item.name)
                                    }
                                  >
                                    <i className='icon-ad'></i>
                                    <span>New Line Item</span>
                                  </a>
                                </li>
                              ) : (
                                ''
                              )}
                            </ul>
                          </li>
                        );
                      })
                    : campaignName && isCreateMode
                      ? null
                      : ''}
                </ul>
              </nav>
            </div>
          </>
        )}
      </article>
    </>
  );
}

export default SideTreeMenuView;
