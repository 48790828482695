import React from 'react';

import noDataFound from '../../assets/images/404.png';
import Sidebar from '../../components/common/Sidebar';
const Error404 = () => {
  return (
    <main>
      <div className='main-container facebook-step-one'>
        <div className='container'>
          <div className='fb-panel'>
            <Sidebar />
            <div className='right-side-box padding-bottom-extra media-section-left'>
              <div className='that-miss-wrap'>
                <div className='img-wrap'>
                  <img src={noDataFound} alt='' />
                </div>
                <h2>404 - PAGE NOT FOUND</h2>
                <p>
                  The page you are looking for might have been removed had its
                  name changed or is temporarily unavailable
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Error404;
