import React from 'react';
import Card from '../common/Card';
import CardBody from '../common/CardBody';
import CardHeader from '../common/CardHeader';
import { blockInvalidChar, handleFocus } from '../../utils/utils';

const BidCPC = ({ value, onChange, formErrors }) => {
  return (
    <Card>
      <CardHeader>
        <h5>
          Bid
          <span
            className='label-information tooltips tool-bottom'
            tooltip='Cascading Style Sheets'
            tooltip-position='top'
          >
            <i className='icon-info'></i>
            <span>
              The bid is the maximum cost-per-click (max. CPC) bid for the "All
              products" product group in this ad group. You can subdivide "All
              products" and set specific bids for each of the additional product
              groups you create.
            </span>
          </span>
        </h5>
        <h6>Enter your cost-per-click (CPC) bid</h6>
      </CardHeader>
      <CardBody>
        <div className='card-form form-panel'>
          <div className='row align-items-center'>
            <div className='form-item col-12'>
              <div className='label-icon'>
                <label>Bid</label>
              </div>
              <div className='input-group'>
                <input
                  type='number'
                  name='Bid CPC'
                  value={value}
                  id='number'
                  placeholder='&#x20B9; Bid'
                  onChange={onChange}
                  onFocus={handleFocus}
                  onKeyDown={blockInvalidChar}
                />
              </div>
              <span className='error' id='cpcError'>
                {formErrors?.adGroupName}
              </span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BidCPC;
