import {
  DV_Optimization_Type,
  DV_Line_Item_Name,
  DV_Inventory_Source,
  DV_Targetting,
  DV_Conversion_Counting,
  DV_Quality,
  DV_Selected_Line_Item,
  DV_Flight_Details,
  DV_Selected_Start_Date,
  DV_Selected_End_Date,
  DV_Selected_Frequencey_Type,
  DV_Selected_Exposures_Type,
  DV_Selected_Frequencey_Value,
  DV_Selected_Exposures_Value,
  DV_Optimized_Target,
  DV_Budget_Spacing_Type,
  DV_Budget_Spacing_Amount,
  DV_Budget_Spacing_First_Type,
  DV_Budget_Spacing_Second_Type,
  DV_Public_Inventory,
  DV_Deals_And_Inventory_Packages,
  DV_Groups,
  DV_Budget_Spacing_Daily_Price,
  DV_Budget_Spacing_Second_Option_Type,
  DV_Automated_Bidding_Option_One_Value,
  DV_Automated_Bidding_Option_Two_Value,
  DV_Automated_Bidding_Exceed_Avg,
  DV_Automated_Bidding_Exceed_Amount,
  DV_Automated_Bidding_Prioritize_Deal,
  DV_Automated_Bidding_Type,
  DV_Fixed_Bid_Val,
  DV_Creative_Optimization,
  DV_Automate_Bidding_Prioritize_Target_Cpm,
  DV_Budget_Type,
  DV_Prefilled_Values,
  DV_Reset_Values,
} from './CreateCampaignActionTypes';

const setLineItemSelected = (payload) => {
  return {
    type: DV_Selected_Line_Item,
    payload: payload,
  };
};

const setBudgetType = (payload) => {
  return {
    type: DV_Budget_Type,
    payload: payload,
  };
};
const setLineItemName = (payload) => {
  return {
    type: DV_Line_Item_Name,
    payload: payload,
  };
};

const setOptimizationType = (payload) => {
  return {
    type: DV_Optimization_Type,
    payload: payload,
  };
};
const setQuality = (payload) => {
  return {
    type: DV_Quality,
    payload: payload,
  };
};
const setPublicInvetory = (payload) => {
  return {
    type: DV_Public_Inventory,
    payload: payload,
  };
};
const setDealsAndInventoryPackages = (payload) => {
  return {
    type: DV_Deals_And_Inventory_Packages,
    payload: payload,
  };
};
const setGroups = (payload) => {
  return {
    type: DV_Groups,
    payload: payload,
  };
};

const setFlightType = (payload) => {
  return {
    type: DV_Flight_Details,
    payload: payload,
  };
};

const setFlightStartDate = (payload) => {
  return {
    type: DV_Selected_Start_Date,
    payload: payload,
  };
};

const setFlightEndDate = (payload) => {
  return {
    type: DV_Selected_End_Date,
    payload: payload,
  };
};

const setSelectedOptimizedTarget = (payload) => {
  return {
    type: DV_Optimized_Target,
    payload: payload,
  };
};

const setSelectedFrequenceyType = (payload) => {
  return {
    type: DV_Selected_Frequencey_Type,
    payload: payload,
  };
};

const setExposuresType = (payload) => {
  return {
    type: DV_Selected_Exposures_Type,
    payload: payload,
  };
};

const setExposuresValue = (payload) => {
  return {
    type: DV_Selected_Exposures_Value,
    payload: payload,
  };
};

const setFrequencyVal = (payload) => {
  return {
    type: DV_Selected_Frequencey_Value,
    payload: payload,
  };
};

const setBudgetSpacingType = (payload) => {
  return {
    type: DV_Budget_Spacing_Type,
    payload: payload,
  };
};

const setBudgetSpacingAmount = (payload) => {
  return {
    type: DV_Budget_Spacing_Amount,
    payload: payload,
  };
};

const setBudgetSpacingFirstType = (payload) => {
  return {
    type: DV_Budget_Spacing_First_Type,
    payload: payload,
  };
};

const setBudgetSpacingSecondType = (payload) => {
  return {
    type: DV_Budget_Spacing_Second_Type,
    payload: payload,
  };
};
const setBudgetSpacingDailyPrice = (payload) => {
  return {
    type: DV_Budget_Spacing_Daily_Price,
    payload: payload,
  };
};
const setBudgetSpacingSecondOptionType = (payload) => {
  return {
    type: DV_Budget_Spacing_Second_Option_Type,
    payload: payload,
  };
};
const setAutomatedBiddingOptionOneVal = (payload) => {
  return {
    type: DV_Automated_Bidding_Option_One_Value,
    payload: payload,
  };
};
const setAutomatedBiddingOptionTwoVal = (payload) => {
  return {
    type: DV_Automated_Bidding_Option_Two_Value,
    payload: payload,
  };
};
const setAutomatedBiddingExceedAvg = (payload) => {
  return {
    type: DV_Automated_Bidding_Exceed_Avg,
    payload: payload,
  };
};
const setAutomatedBiddingType = (payload) => {
  return {
    type: DV_Automated_Bidding_Type,
    payload: payload,
  };
};
const setAutomatedBiddingExceedAmount = (payload) => {
  return {
    type: DV_Automated_Bidding_Exceed_Amount,
    payload: payload,
  };
};
const setAutomatedBiddingPrioritizeDeal = (payload) => {
  return {
    type: DV_Automated_Bidding_Prioritize_Deal,
    payload: payload,
  };
};
const setFixedBiddingPrice = (payload) => {
  return {
    type: DV_Fixed_Bid_Val,
    payload: payload,
  };
};
const setCreativeOptimizationVal = (payload) => {
  return {
    type: DV_Creative_Optimization,
    payload: payload,
  };
};
const setAutomatedBiddingPrioritizeTargetCpm = (payload) => {
  return {
    type: DV_Automate_Bidding_Prioritize_Target_Cpm,
    payload: payload,
  };
};
const preFillValues = (payload) => {
  return {
    type: DV_Prefilled_Values,
    payload: payload,
  };
};

const resetValues = (payload) => {
  return {
    type: DV_Reset_Values,
    payload: payload,
  };
};

export {
  setLineItemSelected,
  setOptimizationType,
  setLineItemName,
  setQuality,
  setPublicInvetory,
  setFlightType,
  setFlightStartDate,
  setFlightEndDate,
  setSelectedFrequenceyType,
  setSelectedOptimizedTarget,
  setFrequencyVal,
  setExposuresType,
  setBudgetSpacingType,
  setBudgetSpacingAmount,
  setBudgetSpacingFirstType,
  setBudgetSpacingSecondType,
  setDealsAndInventoryPackages,
  setGroups,
  setBudgetSpacingDailyPrice,
  setBudgetSpacingSecondOptionType,
  setAutomatedBiddingOptionOneVal,
  setAutomatedBiddingOptionTwoVal,
  setAutomatedBiddingExceedAvg,
  setAutomatedBiddingExceedAmount,
  setAutomatedBiddingPrioritizeDeal,
  setAutomatedBiddingType,
  setFixedBiddingPrice,
  setExposuresValue,
  setCreativeOptimizationVal,
  setAutomatedBiddingPrioritizeTargetCpm,
  setBudgetType,
  preFillValues,
  resetValues,
};
