import Modal from 'react-bootstrap/Modal';

const CommonEnableDisableModal = (props) => {
  return (
    <Modal
      show={props?.show}
      onHide={props?.handleClose}
      className='atd-modal approved-model'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img-cont'>
          <figure class='modal-icon modal-remove'>
            <i class='icon-close'></i>
          </figure>
        </div>
        <div className='modal-text'>
          <h3>Are you sure?</h3>
          <p>{props?.copy}</p>
        </div>
      </Modal.Body>

      <div className='modal-footer'>
        <button
          type='button'
          class='primary-btn cancel-btn'
          data-bs-dismiss='modal'
          id='btncancel'
          onClick={props?.handleClose}
        >
          Cancel
        </button>
        <button
          type='button'
          className={
            props?.type == 'Enable'
              ? 'primary-btn primary-remove'
              : 'primary-btn primary-remove'
          }
          onClick={() => props?.handleAction(props?.type)}
          id='btndisabled'
        >
          {props?.type == 'Enable' ? props?.type : props?.type}
        </button>
      </div>
    </Modal>
  );
};

export default CommonEnableDisableModal;
