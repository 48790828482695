import '../../../assets/css/style.css';
import Sidebar from '../../../components/common/Sidebar';
import Footer from '../../../components/common/Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { useEffect } from 'react';
import DataDetails from './Components/DataDetails';
import { useState } from 'react';
import moment from 'moment';
import { GoogleAdOptions } from '../GoogleAdConstant';
import SideTreeMenuView from '../Components/SideTreeMenuView';
import ErrorDisplay from '../../../components/google/ErrorDisplay';
import ReviewLog from '../ReviewLog/ReviewLog';
import ApproveReject from '../../../components/common/ApproveReject';
import { useSelector } from 'react-redux';
import ThankYouApprovalPopup from '../../../components/common/ThankYouApprovalPopup';
import DisplayCampaignStatus from '../../../components/common/DisplayCampaignStatus';
import Loader from '../../../components/common/Loader';

const GoogleCampaignVIew = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isViewPage, setIsViewPage] = useState(true);
  const [campaignIdState, setCampaignIdState] = useState(false);
  const { id } = useParams();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const [getCurrentStatus, setCurrentStatus] = useState('');
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getApprovalFlag, setApprovalFlag] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getApi(
        `${baseUrlStg}/api/GoogleListingDetails/GoogleCampaignDetails?campaignId=${id}`,
      );
      if (response) {
        setData(response?.googleCampaignDetails);
        setCampaignIdState(response?.googleCampaignDetails?.campaignId);
        setCurrentStatus(response?.googleCampaignDetails?.atdStatus);
        setApprovalFlag(response?.approvalButton);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    campaignName = '-',
    googleCampaignId = '-',
    campaignObjective = '-',
    campaignNetwork = '-',
    campainType = '-',
    focusType = '-',
    campaignBudget = '-',
    campaignLanguage = '-',
    campaignLocation = '-',
    campaignStatus = '-',
    campaignStartDate = '-',
    campaignEndeDate = '-',
    campaignId,
  } = data || {};

  const GotoPage = GoogleAdOptions.Campaigns.url;

  useEffect(() => {
    if (query.get('platForm') === GoogleAdOptions.Campaigns.platform) {
      setIsViewPage(false);
    } else {
      setIsViewPage(true);
    }
  }, [query.get('platForm')]);
  const goToListingPage = () => {
    navigate(GotoPage);
  };

  const handleRedirection = () => {
    navigate(GoogleAdOptions.Campaigns.url);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              <SideTreeMenuView
                campaignId={id}
                IdAndType={{ id: id, type: GoogleAdOptions.Campaigns.name }}
              />
              <article className='side-right-form-wrap side-right-pad'>
                <div className='tab-new-form'>
                  <div className='camp-tab-cont d-flex'>
                    <div className='short-left-bar'>
                      <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <Link
                          className={`primary-btn ${
                            isViewPage ? 'active' : ''
                          }`}
                          to={`${GoogleAdOptions.Campaigns.viewPageUrl}/${id}`}
                        >
                          <i className='icon-show-password'></i> View
                        </Link>
                        {getCurrentStatus == 'In Moderation' ? (
                          <Link
                            class='primary-btn disable-button'
                            title='Entry under moderation: Editing disabled until review completion.'
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        ) : (
                          <Link
                            class='primary-btn'
                            to={GoogleAdOptions.Campaigns.editUrl + `/${id}`}
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        )}
                        <Link
                          className={`primary-btn ${
                            !isViewPage ? 'active' : ''
                          }`}
                          to={`${GoogleAdOptions.Campaigns.viewPageUrl}/${id}?platForm=${GoogleAdOptions.Campaigns.platform}&form=${GoogleAdOptions.Campaigns.reviewForm}`}
                        >
                          <i className='icon-history'></i> Review Log
                        </Link>
                      </div>
                    </div>
                    {getCurrentStatus == 'In Moderation' &&
                      getUserInfo?.roles == 'Publisher' &&
                      getApprovalFlag && (
                        <ApproveReject
                          platform={'GOOGLE'}
                          campaignId={campaignIdState}
                          getUserInfo={getUserInfo}
                          setThankYouApprovalPopupShowStatus={
                            setThankYouApprovalPopupShowStatus
                          }
                          getThankYouApprovalPopupShowStatus={
                            getThankYouApprovalPopupShowStatus
                          }
                          setDisplayLoader={setDisplayLoader}
                        />
                      )}
                  </div>
                </div>
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  <ErrorDisplay id={id} />
                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      {isViewPage ? (
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <DisplayCampaignStatus
                              moderationStatus={getCurrentStatus}
                              publishedAt={
                                data?.googleCampaignDetails?.modifiedOn
                              }
                            />
                            <DataDetails
                              Label={'Campaign name'}
                              LabelFor={campaignName}
                            />
                            <DataDetails
                              Label={'ATD Campaign id'}
                              LabelFor={campaignId}
                            />
                            <DataDetails
                              Label={'Google Campaign id'}
                              LabelFor={googleCampaignId}
                            />
                            <DataDetails
                              Label={'Campaign Objective'}
                              LabelFor={campaignObjective}
                            />
                            <DataDetails
                              Label={'Campaign Type'}
                              LabelFor={campainType}
                            />
                            {Array.isArray(campaignNetwork) &&
                              campaignNetwork.length > 0 && (
                                <DataDetails
                                  Label={'Campaign Network'}
                                  LabelFor={campaignNetwork}
                                />
                              )}
                            <DataDetails
                              Label={'Bidding Type '}
                              LabelFor={focusType}
                            />
                            <DataDetails
                              Label={'Campaign Budget'}
                              LabelFor={campaignBudget}
                            />

                            {Array.isArray(campaignLanguage) &&
                              campaignLanguage.length > 0 && (
                                <DataDetails
                                  Label={'Campaign Language'}
                                  LabelFor={campaignLanguage?.map(
                                    (item) => item.language,
                                  )}
                                />
                              )}

                            <DataDetails
                              Label={'Campaign Location'}
                              LabelFor={campaignLocation}
                            />

                            <DataDetails
                              Label={'Start date'}
                              LabelFor={moment(campaignStartDate).format(
                                'DD-MMM-YYYY',
                              )}
                            />
                            <DataDetails
                              Label={'End date'}
                              LabelFor={
                                moment(campaignEndeDate).format(
                                  'DD-MMM-YYYY',
                                ) || '-'
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <ReviewLog
                          id={id}
                          form={GoogleAdOptions.Campaigns.reviewForm}
                          platForm={GoogleAdOptions.Campaigns.platform}
                        ></ReviewLog>
                      )}
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  >
                    History
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer
        closePath={GotoPage}
        prevPath={GotoPage}
        // nextBtnlabel='Next'
        // nextPath={GotoPage}
      ></Footer>
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          type={'Google'}
          approved={'approved'}
        />
      )}
      {displayLoader && <Loader />}
    </div>
  );
};

export default GoogleCampaignVIew;
