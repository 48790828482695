import ReactSelect from '../common/ReactSelect';
import { googleCampaignGoals } from '../../utils/constants';
import Card from '../common/Card';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';

const CampaignGoal = (props) => {
  const { selectedCampaignGoal, selectedConversionGoalId } = props;

  return (
    <>
      <Card>
        <CardHeader>
          <div className='card-lt-pane'>
            <h5>Campaign goal</h5>
          </div>
        </CardHeader>
        <CardBody>
          <div className='card-form form-panel'>
            <div className='row align-items-center'>
              <div className='form-item'>
                <div className='label-icon'>
                  <label>Options</label>
                </div>
                <div className='select-box'>
                  <ReactSelect
                    options={googleCampaignGoals}
                    onChange={props?.handleSetCampaignGoalVal}
                    closeMenuOnSelect={true}
                    value={googleCampaignGoals?.find(
                      (goal) => goal?.value === selectedCampaignGoal,
                    )}
                  />
                </div>
                {/* <span className='error' id='emailError'>
                  Please enter valid email id
                </span> */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {/* start */}
      <Card>
        <CardHeader>
          <div className='card-lt-pane'>
            <h5>Conversion goals</h5>
          </div>
        </CardHeader>
        <CardBody>
          <div className='card-form form-panel'>
            <div className='row align-items-center'>
              <div className='form-item'>
                <div className='label-icon'>
                  <label>Options</label>
                </div>
                <div className='select-box'>
                  <ReactSelect
                    options={props?.options}
                    onChange={props?.handleSetConversionGoal}
                    closeMenuOnSelect={true}
                    value={props?.options?.find(
                      (goal) => goal?.value == selectedConversionGoalId,
                    )}
                  />
                </div>
                {/* <span className='error' id='emailError'>
                  Please enter valid email id
                </span> */}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {/* end */}
    </>
  );
};

export default CampaignGoal;
