import React, { useState, useEffect, useId, useMemo, useCallback } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Loader from '../../../components/common/Loader';
import ReactTable from '../../../components/common/ReactTable';
import { TableStatusForIs_enabled, baseUrlStg } from '../../../utils/constants';
import { postApi } from '../../../utils/Apis';
import ReactPaginate from 'react-paginate';
import _debounce from 'lodash/debounce';
import { DV360Options } from '../DV360Constant';
import HeaderTopBar from '../Component/HeaderTopBar';
import DeleteModal from './Component/DeleteModal';
import { capitalizeFirstLetter, capitalizeWords } from '../../../utils/utils';
import SimpleSelectBox from '../../../components/common/SimpleSelectBox';
import CommonEnableDisableModal from '../../../components/common/CommonEnableDisableModal';
import CommonSuccessModal from '../../../components/common/CommonSuccessModal';
import ReactSelect from '../../../components/common/ReactSelect';
import NavHeading from '../../../components/common/NavHeading';
import { CommonExport } from '../../../components/Export/CommonExport';
import BulkUploadCommonModal from '../../../components/common/BulkUploadCommonModal';
import { DV360Status } from '../DVConstatnt';

const InsertionOrderListing = () => {
  const [currentTab, setCurrentTab] = useState(
    DV360Options.Insertion_Order.name,
  );
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    fileType: [],
    dimensions: [],
    dates: {
      startDate: '',
      endDate: '',
    },
    creativeName: '',
    moderation_status: [],
    is_enabled: '',
  });

  const [creativeNameFilter, setCreativeNameFilter] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterApply, setFilterApply] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [campaignName, setCampaignName] = useState('');
  // for delete
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const deleteUrl = DV360Options.Insertion_Order.deleteAllUrl;
  const [getShowEnabledDisabledModal, setShowEnabledDisabledModal] =
    useState(false);
  const [getEnabledSelectedType, setEnabledSelectedType] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [getRequstedType, setRequestedType] = useState('');
  const [getBulkUploadModelStatus, setBulkUploadModelStatus] = useState(false);
  const navigate = useNavigate();
  const { handleAPICall } = CommonExport();

  const initialState = {
    campaign_name: '',
    from_date: '',
    to_date: '',
    pageNumber: currentPage,
    pageSize: itemsPerPage,
    // pageNumber: 0,
    // pageSize: 10,
  };

  const [request, setRequest] = useState(initialState);

  const fetchData = async () => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrlStg}/api/InsertionOrder/getInsertionOrdersListByNameDateRangeWithPaging`,
      headers: {
        accept: 'text/plain',
        accessToken: '12321321',
      },
      data: {
        insertion_order_name: campaignName,
        from_date: filters.dates.startDate,
        to_date: filters.dates.endDate,
        pageNumber: request.pageNumber,
        pageSize: Number(itemsPerPage),
      },
    };

    try {
      setIsLoading(true);
      const response = await postApi(
        config.url,
        {
          insertion_order_name: campaignName,
          from_date: filters.dates.startDate,
          to_date: filters.dates.endDate,
          pageNumber: request.pageNumber,
          pageSize: Number(itemsPerPage),
          moderation_status: filters.moderation_status,
          is_enabled: filters.is_enabled,
        },
        config.headers,
      );

      setData(response.data.response);
      setTotalRecords(response.data.totalCount);
      setTotalPages(Math.ceil(response.data.totalCount / Number(itemsPerPage)));
      if (response?.data?.response) {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [itemsPerPage, currentPage, filters]);

  const handleMenuDropdown = () => {
    // navigate('/dv-360/create-insertion-order/new', {
    navigate(DV360Options?.Campaigns?.newUrl, {
      state: { isCreateFlow: true },
    });
  };

  const setDates = (e, { startDate, endDate }) => {
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: startDate.format('YYYY/MM/DD'),
          endDate: endDate.format('YYYY/MM/DD'),
        },
      };
    });
    setRequest((preValue) => {
      return {
        ...preValue,
        from_date: startDate.format('YYYY/MM/DD'),
        to_date: endDate.format('YYYY/MM/DD'),
      };
    });
    checkFilterApplied();
  };

  const onCancelDate = (e, { startDate, endDate }) => {
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: '',
          endDate: '',
        },
      };
    });
    setRequest((preValue) => {
      return {
        ...preValue,
        from_date: '',
        to_date: '',
      };
    });
    checkFilterApplied();
  };

  const setTab = (tab) => {
    ClearFilters(DV360Options.Insertion_Order.name);
    setCurrentPage(0);
    setCurrentTab(tab);
  };

  //clear filters
  const ClearFilters = (type) => {
    switch (type) {
      case DV360Options.Insertion_Order.name:
        setCurrentPage(0);
        setFilters({
          fileType: [],
          dimensions: [],
          dates: {
            startDate: '',
            endDate: '',
          },
          creativeName: '',
        });
        setCreativeNameFilter(false);
        setFilterApply(false);
        break;

      case 'dates':
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            dates: {
              startDate: '',
              endDate: '',
            },
          };
        });
        checkFilterApplied();
        break;

      default:
        break;
    }
  };

  const checkFilterApplied = () => {
    if (filters.dates.startDate === '' && filters.dates.endDate === '') {
      setFilterApply(false);
    } else {
      setFilterApply(true);
    }
  };

  const setActiveTab = (tab) => {
    setTab(tab);
    if (tab === DV360Options.Campaigns.name) {
      navigate(DV360Options.Campaigns.url);
    } else if (tab === DV360Options.Insertion_Order.name) {
      navigate(DV360Options.Insertion_Order.url);
    } else {
      navigate(DV360Options.Line_Item.url);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Insertion order name',
        accessor: 'insertion_order_name',
        Cell: ({ row }) => (
          <Link
            to={`${DV360Options.Insertion_Order.viewPageUrl}/${row.original.insertion_order_id}`}
            onClick={() => handleEdit(row.original.insertion_order_name)}
          >
            {row.original.insertion_order_name}
          </Link>
        ),
      },
      {
        Header: 'ATD Status',
        accessor: 'moderation_status',
        Cell: ({ row }) =>
          row.original.moderation_status ? (
            <span>
              {capitalizeFirstLetter(
                capitalizeWords(row.original.moderation_status),
              )}
            </span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'DV360 Status',
        accessor: 'is_enabled',
        Cell: ({ row }) => (
          <span>{TableStatusForIs_enabled[row.original.is_enabled]}</span>
        ),
      },
      {
        Header: 'Insertion Order ID',
        accessor: 'dv360_insertionorder_id',
        Cell: ({ row }) =>
          row.original.dv360_insertionorder_id ? (
            <span>{row.original.dv360_insertionorder_id}</span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'Type',
        accessor: 'insertion_order_type',
      },
      {
        Header: 'Budget',
        accessor: (row) => {
          const totalBudget = row.budgets.reduce(
            (acc, budget) => acc + budget.budget_value,
            0,
          );
          return `&#x20B9; ${totalBudget}`;
        },
        Cell: ({ value }) => (
          <span dangerouslySetInnerHTML={{ __html: value }} />
        ),
      },
      {
        Header: 'Goal',
        accessor: 'goal_value',
        Cell: ({ row }) =>
          row.original.goal_value ? (
            <span>
              &#x20B9; {row.original.goal_value} {row.original.goal_type}
            </span>
          ) : null,
      },
      {
        Header: 'Start date',
        accessor: 'flight_startdate',
        Cell: ({ row }) => (
          <span>
            {row.original.flight_startdate
              ? moment(row.original.flight_startdate).format('DD-MMM-YYYY')
              : '-'}
          </span>
        ),
      },
      {
        Header: 'End date',
        accessor: 'flight_enddate',
        Cell: ({ row }) => (
          <span>
            {row.original.flight_enddate
              ? moment(row.original.flight_enddate).format('DD-MMM-YYYY')
              : '-'}
          </span>
        ),
      },
      {
        Header: 'Created date',
        accessor: 'created_at',
        Cell: ({ row }) => (
          <span>
            {moment(row.original.created_at)
              .format('DD-MMM-YYYY')
              .toUpperCase()}
          </span>
        ),
      },
      {
        Header: 'Edit',
        accessor: 'edit.id',
        Cell: ({ row }) =>
          row.original.moderation_status == 'in_moderation' ? (
            <Link
              class='disable-button'
              title='Entry under moderation: Editing disabled until review completion.'
            >
              Edit
            </Link>
          ) : (
            <Link
              to={`/dv-360/create-insertion-order/${row.original.insertion_order_id}`}
              onClick={() => handleEdit(row.original.insertion_order_id)}
            >
              Edit
            </Link>
          ),
      },
      {
        Header: 'Delete',
        accessor: 'delete.id',
        Cell: ({ row }) => (
          <Link
            href=''
            onClick={() => handleDelete(row.original.insertion_order_id)}
          >
            Delete
          </Link>
        ),
      },
      // Add more columns as needed
    ],
    [],
  );

  const handleEdit = (id) => {
    // navigate(`/dv-360/create-campaign/edit/${id}`);
  };

  const handleDelete = (id) => {
    setShow(true);
    setDeleteId([id]);
  };

  const clearSearch = (e) => {
    setFilters((preValue) => ({
      ...preValue,
      creativeName: '',
    }));
    setCampaignName('');
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    setRequest((prevValue) => ({
      ...prevValue,
      pageNumber: selectedPage.selected + 1,
    }));
  };
  const handleChange = (e) => {
    setCampaignName(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = () => {
    fetchData(campaignName);
  };

  const handleClose = () => setShow(false);

  const doneWithDelete = () => {
    fetchData();
  };

  // for Delete
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = useCallback((selectedRowsIds) => {
    setSelectedRows(selectedRowsIds);
  }, []);

  const handleMultipleDelete = () => {
    const deleteId = selectedRows.map((row) => row.insertion_order_id);
    setShow(true);
    setDeleteId(deleteId);
  };

  const handleEnabledDisabledVal = (e) => {
    setEnabledSelectedType(e.target.value);
    if (e.target.value != '') {
      setShowEnabledDisabledModal(true);
    }
  };

  const handleAction = async (type) => {
    try {
      let typeStatus = type == 'Enable' ? 1 : 0;
      setShowEnabledDisabledModal(false);
      setRequestedType(type);
      setIsLoading(true);
      const getSelectedIds = selectedRows.map((row) => row.insertion_order_id);
      const endPoint = `${baseUrlStg}/api/InsertionOrder/PauseActiveMultipleInsertionOrderByID?modified_by=${4}&is_enabled=${typeStatus}`;
      const response = await postApi(endPoint, getSelectedIds).catch(
        (error) => {
          console.error('Failed to Enable & Disable :', error);
          throw error;
        },
      );
      if (response && response?.data.status === 200) {
        setShowSuccessModal(true);
        fetchData();
        setIsLoading(false);
        setEnabledSelectedType('');
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Failed to Enable & Disable :', err);
    }
  };

  // Status For ATD and DV360S
  // Code For DV360 and ATD STATUS

  const [selectedValues, setSelectedValues] = useState([]);

  const handleDV360Status = (selectedOptions) => {
    // Update the state with the selected values
    setSelectedValues(selectedOptions);
    // Extract and log only the values to the console
    const selectedValuesOnly = selectedOptions?.map((option) => option.value);
    console.log('Selected Values For DV360 STATUS:', selectedValuesOnly);

    setFilters((prevState) => {
      return {
        ...prevState,
        moderation_status: selectedValuesOnly,
      };
    });
  };

  // code for handleATDStatus
  const [selectedValuesATD, setSelectedValuesATD] = useState([]);

  const handleATDStatus = (selectedOptionATD) => {
    setSelectedValuesATD(selectedOptionATD);
    const selectedValuesOnlyATD = selectedOptionATD?.map(
      (option) => option.value,
    );
    console.log('Selected Values For ATD STATUS:', selectedValuesOnlyATD);

    setFilters((prevState) => {
      return {
        ...prevState,
        is_enabled: selectedValuesOnlyATD,
      };
    });
  };
  // End of code

  const handleExport = () => {
    const deleteId = selectedRows.map((row) => row.insertion_order_id);
    handleAPICall(3, deleteId, 'dv360', 'Insertion-order');
  };

  return (
    <div>
      {isLoading && <Loader />}
      {data ? (
        <>
          <main>
            <div className='main-container facebook-step-one'>
              <div className='container'>
                <div className='fb-panel'>
                  <Sidebar />
                  <div className='right-side-box padding-bottom-extra media-section-left'>
                    <NavHeading HeadingName={'DV360 Campaigns'} />
                    <div className='search-bar-cont'>
                      <div className='search'>
                        <div className='search-close'>
                          <input
                            type='text'
                            id='searchtextid'
                            placeholder='Search'
                            value={campaignName}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                          />
                          <button
                            className='close-btn'
                            id='ClearMediaSearchID'
                            onClick={clearSearch}
                          >
                            <i className='icon-close'></i>
                          </button>
                        </div>
                        <button
                          className='btn-icon btn-icon-primary'
                          id='searchstringid'
                          onClick={handleSearch}
                        >
                          <i className='icon-search'></i>
                        </button>
                        <div className='date-range-select'>
                          <>
                            <DateRangePicker
                              onApply={setDates}
                              onCancel={onCancelDate}
                              isClearable={true}
                            >
                              <input
                                type='text'
                                className='daterange'
                                name='daterangefilter'
                                id='startenddateid'
                              />
                            </DateRangePicker>
                          </>
                        </div>
                        {/* <div className='date-range-select'>
                          <input
                            type='text'
                            className='daterange'
                            name='daterangefilter'
                            id='startenddateid'
                            placeholder='select date'
                          />
                        </div> */}
                      </div>
                    </div>

                    {/* <Header /> */}
                    <section className='side-menu-form d-flex'>
                      <article className='side-right-form-wrap'>
                        <HeaderTopBar
                          setActiveTab={setActiveTab}
                          currentTab={currentTab}
                          setCurrentTab={setCurrentTab}
                          options={DV360Options}
                        />

                        <div className='card-primary'>
                          <div className='media-fillter-btns'>
                            <div className='fillter-btns  d-flex justify-content-between'>
                              <div className='fillter-left d-flex align-items-center'>
                                <div className='dropdown-select DpdrpDownSlct'>
                                  <button
                                    onClick={handleMenuDropdown}
                                    className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                  >
                                    <i className='icon-plus'></i> Create
                                  </button>
                                  <button
                                    type='button'
                                    class='btn-icon btn-icon-primary no-text'
                                    id='facebookdltid'
                                    onClick={() => handleMultipleDelete()}
                                    disabled={selectedRows.length === 0}
                                  >
                                    <i class='icon-delete'></i>
                                  </button>
                                  <button
                                    type='button'
                                    class='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    id='facebookdltid'
                                    onClick={() => handleExport()}
                                    disabled={selectedRows.length === 0}
                                  >
                                    <i class='icon-export'></i> Export
                                  </button>
                                  <button
                                    type='button'
                                    class='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    id='facebookdltid'
                                    onClick={() =>
                                      setBulkUploadModelStatus(true)
                                    }
                                  >
                                    <i class='icon-import'></i> Import
                                  </button>
                                </div>
                                {/* Enable & Disable button start */}
                                <div class='select-box'>
                                  <SimpleSelectBox
                                    name='quality'
                                    placeholder='Choose control'
                                    options={['Disable', 'Enable']}
                                    value={getEnabledSelectedType}
                                    onChangeHandler={handleEnabledDisabledVal}
                                    disabled={
                                      selectedRows?.length > 0 ? false : true
                                    }
                                  ></SimpleSelectBox>
                                </div>
                              </div>
                              <div className='fillter-right d-flex align-items-center'>
                                <div className='me-2'>
                                  <ReactSelect
                                    isSearchable={false}
                                    placeholder='ATD Status'
                                    isMulti={true}
                                    options={[
                                      {
                                        label: 'Draft',
                                        value: 'draft',
                                      },

                                      {
                                        label: 'In Moderation',
                                        value: 'in_moderation',
                                      },
                                      {
                                        label: 'Published',
                                        value: 'published',
                                      },
                                      {
                                        label: 'Api Error',
                                        value: 'api_error',
                                      },
                                    ]}
                                    onChange={handleDV360Status} // Handle the change event
                                    value={selectedValues} // Set the selected values
                                  />
                                </div>
                                <ReactSelect
                                  className='select-box'
                                  isSearchable={false}
                                  placeholder='DV360 Status'
                                  isMulti={true}
                                  options={DV360Status}
                                  onChange={handleATDStatus}
                                  value={selectedValuesATD}
                                ></ReactSelect>
                              </div>
                            </div>
                            <div className='fillter-options'>
                              {filters.dates.startDate &&
                                filters.dates.endDate && (
                                  <button
                                    className='clear-btn'
                                    onClick={() => [
                                      ClearFilters(
                                        DV360Options.Insertion_Order.name,
                                      ),
                                      setRequest(initialState),
                                    ]}
                                  >
                                    Clear All
                                  </button>
                                )}

                              {filters.dates.startDate &&
                                filters.dates.endDate && (
                                  <span>
                                    Date Range:{' '}
                                    {
                                      <>
                                        <b>
                                          {moment(
                                            filters.dates.startDate,
                                          ).format('DD/MM/YYYY')}
                                          -
                                          {moment(filters.dates.endDate).format(
                                            'DD/MM/YYYY',
                                          )}
                                        </b>
                                        <button
                                          type='button'
                                          onClick={() => ClearFilters('dates')}
                                        >
                                          <i className='icon-close' />
                                        </button>
                                      </>
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                          <div
                            className='dataTables_length'
                            id='Facebookcampaigntable_length'
                          >
                            <label>
                              Show{' '}
                              <select
                                name='Facebookcampaigntable_length'
                                aria-controls='Facebookcampaigntable'
                                value={itemsPerPage}
                                onChange={(e) => [
                                  setItemsPerPage(Number(e.target.value)),
                                  // setItemsPerPage(0),
                                ]}
                              >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                          <ReactTable
                            columns={columns}
                            data={data}
                            totalCount={totalRecords}
                            onSelectionChange={handleSelectionChange}
                          />
                          <br />
                          {data && (
                            <div className='media-entries dataTables_paginate'>
                              <div className='show-entries'>
                                {data.length > 0 && (
                                  <p>
                                    Showing{' '}
                                    {currentPage === 1
                                      ? 1
                                      : (currentPage - 1) * itemsPerPage +
                                        1}{' '}
                                    to{' '}
                                    {Number(currentPage - 1) *
                                      Number(itemsPerPage) +
                                      data.length}{' '}
                                    of {totalRecords} entries
                                  </p>
                                )}
                              </div>
                              <div className='media-pagination'>
                                {totalRecords > itemsPerPage && (
                                  <div className='media-pagination dataTables_paginate'>
                                    <ReactPaginate
                                      breakLabel={'...'}
                                      marginPagesDisplayed={1}
                                      previousLabel={<button>Previous</button>}
                                      nextLabel={<button>Next</button>}
                                      pageCount={totalPages}
                                      onPageChange={handlePageChange}
                                      forcePage={currentPage - 1}
                                      renderOnZeroPageCount={null}
                                      pageRangeDisplayed={5}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      ) : (
        <Sidebar />
      )}
      <DeleteModal
        handleClose={handleClose}
        show={show}
        key={useId()}
        deleteId={deleteId}
        doneWithDelete={doneWithDelete}
        deleteUrl={deleteUrl}
        from={DV360Options.Insertion_Order.name}
      ></DeleteModal>

      <CommonEnableDisableModal
        show={getShowEnabledDisabledModal}
        handleClose={() => setShowEnabledDisabledModal(false)}
        copy={
          getEnabledSelectedType == 'Enable'
            ? 'You want to activate Insertion Order(s)? Note that changes may impact Insertion order effectiveness.'
            : 'You want to deactivate Insertion Order(s)? This action may affect your current advertising effectiveness.'
        }
        type={getEnabledSelectedType}
        handleAction={handleAction}
      />

      {showSuccessModal && (
        <CommonSuccessModal
          show={true}
          copy={`Insertion Order has been successfully ${getRequstedType}.`}
          handleClose={() => setShowSuccessModal(false)}
        />
      )}
      {getBulkUploadModelStatus && (
        <BulkUploadCommonModal
          copy={'Upload DV360 Campaigns'}
          setBulkUploadModelStatus={() => setBulkUploadModelStatus(false)}
          pageType={'DV360'}
        />
      )}
    </div>
  );
};

export default InsertionOrderListing;
