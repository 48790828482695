import React, { useState, useEffect } from 'react';
import { getApi } from '../../utils/Apis';
import { baseUrlStg } from '../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';

const DvErrorDisplay = ({ id }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(null);
  const [isButtonVisible, setButtonVisibility] = useState(true);

  const handleClick = (id, name) => {
    if (name == 'CAMPAIGN') {
      navigate(`/dv-360/create-campaign/${response[0]?.dv_campaign_id}`);
    } else if (name == 'INSERTION' || 'INSERTION ORDER') {
      navigate(
        `/dv-360/create-insertion-order/${response[0]?.insertion_order_id}`,
      );
    } else {
      navigate(`/dv-360/create-line-item/${response[0]?.line_item_id}`);
    }
  };

  const apiUrl = `${baseUrlStg}/api/DvCampaignError/getErrorByCampaignId?campaign_id=${id}`;

  const handleCloseButtonClick = () => {
    // Update the state to hide the button
    setButtonVisibility(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const apiResponse = await getApi(apiUrl);

          if (!apiResponse?.response || apiResponse.response.length === 0) {
            setShow(false);
            return;
          }

          setShow(true);
          setResponse(apiResponse.response);
          // console.log('apiResponse:', apiResponse.response);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [apiUrl]);

  return (
    <>
      {show && isButtonVisible && (
        <div
          className='info-icon-body info-alert-box'
          id='facebook_error_divID'
        >
          <button
            className='close-slideshow'
            id='facebook_error_closeID'
            type='button'
            onClick={handleCloseButtonClick}
          >
            <i className='icon-close'></i>
          </button>
          {/* <div className='more-info-icon'>
            <i className='icon-close-round'></i>
          </div> */}
          <div className='more-info-text '>
            {response && (
              <>
                {(() => {
                  switch (response[0]?.action_name) {
                    case 'CAMPAIGN':
                      return (
                        <>
                          <Link
                            onClick={() =>
                              handleClick(
                                response[0]?.dv_campaign_id,
                                response[0]?.action_name,
                              )
                            }
                          >
                            <h6>
                              {response[0]?.action_name} :{' '}
                              {response[0]?.campaign_name}
                            </h6>
                            <p>{response[0]?.errors}</p>
                          </Link>
                        </>
                      );
                    case 'INSERTION ORDER' || 'INSERTION':
                      return (
                        <>
                          <Link
                            onClick={() =>
                              handleClick(
                                response[0]?.insertion_order_id,
                                response[0]?.action_name,
                              )
                            }
                          >
                            <h6>
                              {response[0]?.action_name} :{' '}
                              {response[0]?.insertion_order_name}
                            </h6>
                            <p>{response[0]?.errors}</p>
                          </Link>
                        </>
                      );
                    case 'LINE ITEM' || 'LINEITEM':
                      return (
                        <>
                          <Link
                            onClick={() =>
                              handleClick(
                                response[0]?.line_item_id,
                                response[0]?.action_name,
                              )
                            }
                          >
                            <h6>
                              {response[0]?.action_name} :{' '}
                              {response[0]?.line_item_name}
                            </h6>
                            <p>{response[0]?.errors}</p>
                          </Link>
                        </>
                      );
                    default:
                      return null;
                  }
                })()}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DvErrorDisplay;
