import '../../../assets/css/style.css';
import Sidebar from '../../../components/common/Sidebar';
import Footer from '../../../components/common/Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { useEffect } from 'react';
import DataDetails from './Components/DataDetails';
import { useState } from 'react';
import { GoogleAdOptions } from '../GoogleAdConstant';
import FsLightbox from 'fslightbox-react';
import SideTreeMenuView from '../Components/SideTreeMenuView';
import ErrorDisplay from '../../../components/google/ErrorDisplay';
import { downloadFile, isValidArray } from '../../../utils/utils';
import ReviewLog from '../ReviewLog/ReviewLog';
import ApproveReject from '../../../components/common/ApproveReject';
import { useSelector } from 'react-redux';
import ThankYouApprovalPopup from '../../../components/common/ThankYouApprovalPopup';
import DisplayCampaignStatus from '../../../components/common/DisplayCampaignStatus';
import Loader from '../../../components/common/Loader';

const AdsView = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [medPathsOfData, setMedPathsOfData] = useState([]);
  const [campaignId, setCampaignId] = useState('');
  const [isViewPage, setIsViewPage] = useState(true);
  const [getCurrentStatus, setCurrentStatus] = useState('');
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getApprovalFlag, setApprovalFlag] = useState(false);
  const [getCurrentCampaignTypeId, setCurrentCampaignTypeId] = useState();
  const [getFileName, setFileName] = useState('');

  const [landscapeImageToggler, setLandscapeImageToggler] = useState(false);
  const [landscapeLogoToggler, setLandscapeLogoToggler] = useState(false);
  const [squareImageToggler, setSquareImageToggler] = useState(false);
  const [squareLogoToggler, setSquareLogoToggler] = useState(false);
  const [portraitImageToggler, setPortraitImageToggler] = useState(false);
  const [landscapeImage, setLandscapeImage] = useState([]);
  const [landscapeLogo, setLandscapeLogo] = useState([]);
  const [squareImage, setSquareImage] = useState([]);
  const [squareLogo, setSquareLogo] = useState([]);
  const [portraitImage, setPortraitImage] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const fetchData = async () => {
    try {
      const response = await getApi(
        `${baseUrlStg}/api/GoogleListingDetails/GetAdsDetails?adId=${id}`,
      );

      if (response) {
        setData(response?.response?.googleAdDetails);
        setCampaignId(response?.response?.campaignId);
        setMedPathsOfData(
          response?.response?.imageMediaData?.map(
            (item) => item?.medPath || item?.youtubeUrl,
          ),
        );
        setLandscapeImage(
          response?.response?.googleAdDetails?.landscapeImage?.map(
            (item) => item?.medPath,
          ),
        );
        setLandscapeLogo(
          response?.response?.googleAdDetails?.landscapeLogo?.map(
            (item) => item?.medPath,
          ),
        );
        setSquareImage(
          response?.response?.googleAdDetails?.squareImage?.map(
            (item) => item?.medPath,
          ),
        );
        setSquareLogo(
          response?.response?.googleAdDetails?.squareLogo?.map(
            (item) => item?.medPath,
          ),
        );
        setPortraitImage(
          response?.response?.googleAdDetails?.potraitImage?.map(
            (item) => item?.medPath,
          ),
        );
        setCurrentStatus(response?.response?.googleAdDetails?.atdStatus);
        setApprovalFlag(response?.approvalButton);
        setCurrentCampaignTypeId(response?.response?.campaignTypeId);
        setFileName(response?.response?.productFileName);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    adName = '-',
    googleAdId = '-',
    finalUrl = '-',
    displayPath1 = '-',
    displayPath2 = '-',
    callNumbers = '-',
    leadFormsIds = '-',
    headLine = '-',
    description = '-',
    imagePath,
    businessName,
    businessLogo,
    siteLink,
    promosion,
    price,
    structureSnipets,
    apps,
    heading,
    adGoogleRefId,
    leadForm,
  } = data || {};

  // const medThumbnailPath =
  const GotoPage = GoogleAdOptions.Ads.url;

  useEffect(() => {
    if (query.get('platForm') === GoogleAdOptions.Ads.platform) {
      setIsViewPage(false);
    } else {
      setIsViewPage(true);
    }
  }, [query.get('platForm')]);
  const goToListingPage = () => {
    navigate(GotoPage);
  };

  const [toggler, setToggler] = useState(false);
  const [businessToggler, setBusinessToggler] = useState(false);

  const handleRedirection = () => {
    navigate(GoogleAdOptions.Ads.url);
  };

  const handleDownloadFile = (getFileName) => {
    let endPoint = `${baseUrlStg}/api/GoogleProduct/GetProductListByAdGroupId?adGroupId=${data?.googleAdGroupId}`;
    downloadFile(endPoint, getFileName);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              {data && (
                <SideTreeMenuView
                  campaignId={campaignId}
                  IdAndType={{
                    id: id,
                    type: GoogleAdOptions.Ads.name,
                  }}
                />
              )}
              <article className='side-right-form-wrap side-right-pad'>
                <div className='tab-new-form'>
                  <div className='camp-tab-cont d-flex'>
                    <div className='short-left-bar'>
                      <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <Link
                          className={`primary-btn ${
                            isViewPage ? 'active' : ''
                          }`}
                          to={`${GoogleAdOptions.Ads.viewPageUrl}/${id}`}
                        >
                          <i className='icon-show-password'></i> View
                        </Link>
                        {getCurrentStatus == 'In Moderation' ? (
                          <Link
                            class='primary-btn disable-button'
                            title='Entry under moderation: Editing disabled until review completion.'
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        ) : (
                          <Link
                            class='primary-btn'
                            to={GoogleAdOptions.Ads.editUrl + `/${id}`}
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        )}
                        <Link
                          className={`primary-btn ${
                            !isViewPage ? 'active' : ''
                          }`}
                          to={`${GoogleAdOptions.Ads.viewPageUrl}/${id}?platForm=${GoogleAdOptions.Ads.platform}&form=${GoogleAdOptions.Ads.reviewForm}`}
                        >
                          <i className='icon-history'></i> Review Log
                        </Link>
                      </div>
                    </div>
                    {getCurrentStatus == 'In Moderation' &&
                      getUserInfo?.roles == 'Publisher' &&
                      getApprovalFlag && (
                        <ApproveReject
                          platform={'GOOGLE'}
                          campaignId={campaignId}
                          getUserInfo={getUserInfo}
                          setThankYouApprovalPopupShowStatus={
                            setThankYouApprovalPopupShowStatus
                          }
                          getThankYouApprovalPopupShowStatus={
                            getThankYouApprovalPopupShowStatus
                          }
                          setDisplayLoader={setDisplayLoader}
                        />
                      )}
                  </div>
                </div>
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  <ErrorDisplay id={campaignId} />
                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    {' '}
                    {data && (
                      <div className='rv-details-cont'>
                        <div className='card-primary-repet'>
                          {isViewPage ? (
                            <div className='card-primary'>
                              <DisplayCampaignStatus
                                moderationStatus={getCurrentStatus}
                                publishedAt={data?.modifiedOn}
                              />
                              <DataDetails
                                Label={'Ad Name'}
                                LabelFor={adName}
                              />
                              <DataDetails
                                Label={'ATD Ad id'}
                                LabelFor={googleAdId}
                              />
                              <DataDetails
                                Label={'Google Ad id'}
                                LabelFor={adGoogleRefId}
                              />
                              {getCurrentCampaignTypeId != 4 && (
                                <>
                                  <DataDetails
                                    Label={'Final URL'}
                                    LabelFor={finalUrl}
                                  />
                                  <DataDetails
                                    Label={'Display Path'}
                                    LabelFor={[displayPath1, displayPath2]}
                                  />
                                  {isValidArray(callNumbers) && (
                                    <DataDetails
                                      Label={'Calls'}
                                      LabelFor={
                                        Array.isArray(callNumbers) &&
                                        `${callNumbers.map((call) => {
                                          return call.phoneNumber;
                                        })}`
                                      }
                                    />
                                  )}

                                  {Array.isArray(leadForm) &&
                                    leadForm.map((leadForm, index) => {
                                      return (
                                        <div key={index}>
                                          <p>Heading: {leadForm?.heading}</p>
                                          <p>
                                            Business Name :{' '}
                                            {leadForm?.businessName}
                                          </p>
                                          <p>
                                            Description :{' '}
                                            {leadForm?.description}
                                          </p>
                                        </div>
                                      );
                                    })}

                                  <DataDetails
                                    Label={'Heading'}
                                    LabelFor={
                                      Array.isArray(heading) &&
                                      `${heading.map((headLine) => {
                                        return headLine;
                                      })}`
                                    }
                                  />
                                  {/* map */}
                                  <DataDetails
                                    Label={'Description'}
                                    LabelFor={
                                      Array.isArray(description) &&
                                      `${description.map((description) => {
                                        return description;
                                      })}`
                                    }
                                  />

                                  {landscapeImage?.length > 0 && (
                                    <div className='footer-btn align-items-center '>
                                      <DataDetails
                                        Label={'landscape Image '}
                                        LabelFor={''}
                                      />

                                      <button
                                        className='primary-btn footer-close-btn'
                                        onClick={() =>
                                          setLandscapeImageToggler(
                                            !landscapeImageToggler,
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                      <FsLightbox
                                        toggler={landscapeImageToggler}
                                        sources={landscapeImage}
                                        showThumbsOnMount={true}
                                      />
                                    </div>
                                  )}

                                  {landscapeLogo?.length > 0 && (
                                    <div className='footer-btn align-items-center '>
                                      <DataDetails
                                        Label={'Landscape Logo'}
                                        LabelFor={''}
                                      />

                                      <button
                                        className='primary-btn footer-close-btn'
                                        onClick={() =>
                                          setLandscapeLogoToggler(
                                            !landscapeLogoToggler,
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                      <FsLightbox
                                        toggler={landscapeLogoToggler}
                                        sources={landscapeLogo}
                                        showThumbsOnMount={true}
                                      />
                                    </div>
                                  )}

                                  {squareImage?.length > 0 && (
                                    <div className='footer-btn align-items-center '>
                                      <DataDetails
                                        Label={'squareImage Image '}
                                        LabelFor={''}
                                      />
                                      <button
                                        className='primary-btn footer-close-btn'
                                        onClick={() =>
                                          setSquareImageToggler(
                                            !squareImageToggler,
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                      <FsLightbox
                                        toggler={squareImageToggler}
                                        sources={squareImage}
                                        showThumbsOnMount={true}
                                      />
                                    </div>
                                  )}

                                  {squareLogo?.length > 0 && (
                                    <div className='footer-btn align-items-center '>
                                      <DataDetails
                                        Label={'Square Logo Image '}
                                        LabelFor={''}
                                      />
                                      <button
                                        className='primary-btn footer-close-btn'
                                        onClick={() =>
                                          setSquareLogoToggler(
                                            !squareLogoToggler,
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                      <FsLightbox
                                        toggler={squareLogoToggler}
                                        sources={squareLogo}
                                        showThumbsOnMount={true}
                                      />
                                    </div>
                                  )}

                                  {portraitImage?.length > 0 && (
                                    <div className='footer-btn align-items-center '>
                                      <DataDetails
                                        Label={'Portrait Image '}
                                        LabelFor={''}
                                      />
                                      <button
                                        className='primary-btn footer-close-btn'
                                        onClick={() =>
                                          setPortraitImageToggler(
                                            !portraitImageToggler,
                                          )
                                        }
                                      >
                                        Preview
                                      </button>
                                      <FsLightbox
                                        toggler={portraitImageToggler}
                                        sources={portraitImage}
                                        showThumbsOnMount={true}
                                      />
                                    </div>
                                  )}
                                  <DataDetails
                                    Label={'Business Name'}
                                    LabelFor={businessName}
                                  />
                                  {businessLogo?.[0]?.medPath && (
                                    <div className='footer-btn align-items-center '>
                                      <DataDetails
                                        Label={'Business Logo'}
                                        LabelFor={''}
                                      />
                                      <button
                                        className='primary-btn footer-close-btn'
                                        onClick={() =>
                                          setBusinessToggler(!businessToggler)
                                        }
                                      >
                                        Business Logo Preview
                                      </button>
                                      <FsLightbox
                                        toggler={businessToggler}
                                        sources={
                                          businessLogo && [
                                            businessLogo[0].medPath,
                                          ]
                                        }
                                      />
                                    </div>
                                  )}
                                  {/* sitelinkText,finalUrl show with - */}
                                  {isValidArray(siteLink) && (
                                    <>
                                      <DataDetails Label={'Site Link'} />{' '}
                                      {Array.isArray(siteLink) &&
                                        siteLink.map((value, index) => {
                                          return (
                                            <div key={index}>
                                              <p>
                                                Site link Text -{' '}
                                                {value?.sitelinkText}
                                              </p>
                                              <p>
                                                Final Url - {value.finalUrl}
                                              </p>
                                            </div>
                                          );
                                        })}
                                    </>
                                  )}
                                  {isValidArray(promosion) && (
                                    <DataDetails
                                      Label={'Promotion'}
                                      LabelFor={
                                        Array.isArray(promosion) &&
                                        `${promosion.map((value) => {
                                          return `${value.occasion} - ${value.promotionType} - ${value.promotionTypeValue} - ${value.item}`;
                                        })}`
                                      }
                                    />
                                  )}
                                  {isValidArray(price) && (
                                    <DataDetails
                                      Label={'Prices'}
                                      LabelFor={
                                        Array.isArray(price) &&
                                        `${price.map((value) => {
                                          return `${value.header} - ${value.amount} - ${value.description}`;
                                        })}`
                                      }
                                    />
                                  )}
                                  {isValidArray(structureSnipets) && (
                                    <DataDetails
                                      Label={'Structured Snippets'}
                                      LabelFor={
                                        Array.isArray(structureSnipets) &&
                                        `${structureSnipets.map((value) => {
                                          return `${value.haederType} - ${value.value1} - ${value.value1} - ${value.value2} - ${value.value2} - ${value.value3} - ${value.value4} - ${value.value5} - ${value.value6} - ${value.value7} - ${value.value8} - ${value.value9} - ${value.value10}`;
                                        })}`
                                      }
                                    />
                                  )}
                                  {isValidArray(apps) && (
                                    <DataDetails
                                      Label={'Apps'}
                                      // LabelFor={apps}
                                      LabelFor={
                                        Array.isArray(apps) &&
                                        `${apps.map((value) => {
                                          return `${value.lookupAppUrl} - ${value.linkText}`;
                                        })}`
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <ReviewLog
                              id={id}
                              form={GoogleAdOptions.Ads.reviewForm}
                              platForm={GoogleAdOptions.Ads.platform}
                            ></ReviewLog>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  >
                    History
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer
        closePath={GoogleAdOptions.Campaigns.url}
        prevPath={GotoPage}
        // nextBtnlabel='Next'
        // nextPath={GotoPage}
      ></Footer>
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          type={'Google'}
          approved={'approved'}
        />
      )}
      {displayLoader && <Loader />}
    </div>
  );
};

export default AdsView;
