import { combineReducers } from 'redux';
import { googleCreateCampaign } from './googleCreateCampaign/CreateCampaignReducer';
import { googleAd } from './googleAd/GoogleAdReducer';
import { googleAdGroup } from './googleAdGroup/googleAdGroupReducer';
import { DvCreateCampaign } from './DVCreateCampaign/CreateCampaignReducer'; // Updated import
import { DvCreateLineItem } from './DVLineItem/CreateCampaignReducer';
import { DvInsertionOrder } from './DVInsertionOrder/CreateCampaignReducer';
import { TexonomyCreateMediaPlane } from './TexonomyCreateMediaPlan/CreateMediaPlaneReducer';
import { TaxonomyCreateCampaign } from './TaxonomyCreatecampaign/TaxonomyCreateCampaignReducer';
import { TaxonomyCreateAd } from './TaxonomyCreateAd/TaxonomyCreateAdReducer';
import { loginReducer } from './Login/LoginReducer';
import { sideTreeGoogleReducer } from './SideTreeGoogle/SideTreeReducer';

export const rootReducer = combineReducers({
  googleCreateCampaign,
  googleAd,
  DvCreateCampaign,
  DvCreateLineItem,
  DvInsertionOrder,
  TexonomyCreateMediaPlane,
  TaxonomyCreateCampaign,
  TaxonomyCreateAd,
  googleAdGroup,
  loginReducer,
  sideTreeGoogleReducer,
});
