export const DV_OVERALL_CAMPAIGN_GOAL = 'DV_OVERALL_CAMPAIGN_GOAL';
export const DV_CAMPAIGN_NAME = 'DV_CAMPAIGN_NAME';
export const DV_KPI = 'DV_KPI';
export const DV_KPI_GOAL = 'DV_KPI_GOAL';
export const DV_CREATIVE_TYPE = 'DV_CREATIVE_TYPE';
export const DV_PLANNED_SPEND = 'DV_PLANNED_SPEND';
export const DV_PLANNED_START_DATE = 'DV_PLANNED_START_DATE';
export const DV_PLANNED_END_DATE = 'DV_PLANNED_END_DATE';
export const DV_FREQUENCY_CAP_TYPE = 'DV_FREQUENCY_CAP_TYPE';
export const DV_FREQUENCY_LIMIT = 'DV_FREQUENCY_LIMIT';
export const DV_FREQUENCY_LIMIT_TYPE = 'DV_FREQUENCY_LIMIT_TYPE';
export const DV_FREQUENCY_LIMIT_VALUE = 'DV_FREQUENCY_LIMIT_VALUE';
export const DV_Quality = 'DV_Quality';
export const DV_Public_Inventory = 'DV_Public_Inventory';
export const DV_Deals_And_Inventory_Packages =
  'DV_Deals_And_Inventory_Packages';
export const DV_Groups = 'DV_Groups';
export const DV_TARGETTING_SOURCES = 'DV_TARGETTING_SOURCES';
export const DV_CAMPAIGN_NAME_CHECK = 'DV_CAMPAIGN_NAME_CHECK';
export const DV_CAMPAIGN_EXIST = 'DV_CAMPAIGN_EXIST';

//update campaign

export const DV_GET_CAMPAIGN_BY_ID = 'DV_GET_CAMPAIGN_BY_ID';
export const DV_STORE_CAMPAIGN_BY_ID = 'DV_STORE_CAMPAIGN_BY_ID';
export const RESET_CAMPAIGN_STATE = 'RESET_CAMPAIGN_STATE';
