import {
  TAXONOMY_CAMPAIGN_NAME,
  TAXONOMY_CAMPAIGN_PRODUCT_NAME,
  TAXONOMY_CAMPAIGN_ADVERTISER_NAME,
  TAXONOMY_CAMPAIGN_SUBCATEGORY,
  TAXONOMY_CAMPAIGN_PRECISION_JOB_OR_RO_NUMBER,
  TAXONOMY_CAMPAIGN_GENDER,
  TAXONOMY_CAMPAIGN_AGE,
  TAXONOMY_CAMPAIGN_PHASE,
  TAXONOMY_CAMPAIGN_CHANNEL,
  TAXONOMY_CAMPAIGN_DSP_PUBLISHER,
  TAXONOMY_CAMPAIGN_CAMPAIGN_TYPE,
  TAXONOMY_CAMPAIGN_BUY_TYPE_RATE_TYPE,
  TAXONOMY_CAMPAIGN_CAMPAIGN_KPI,
  TAXONOMY_CAMPAIGN_OBJECTIVE,
  TAXONOMY_CAMPAIGN_AUDIENCE_TYPE,
  TAXONOMY_CAMPAIGN_FREE_FORM,
  TAXONOMY_CAMPAIGN_UNIQUE_ID,
  TAXONOMY_CAMPAIGN_START_DATE,
  TAXONOMY_CAMPAIGN_MATCH_TYPE,
  TAXONOMY_CAMPAIGN_SIZMMEK_TRACKING,
  TAXONOMY_CAMPAIGN_CLEAR_FORM,
} from './TaxonomyCreateCampaignActionTypes';

const setTaxonomyCampaignName = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_NAME,
    payload: payload,
  };
};

const setTaxonomyCampaignProductName = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_PRODUCT_NAME,
    payload: payload,
  };
};

const setTaxonomyCampaignAdvertiser = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_ADVERTISER_NAME,
    payload: payload,
  };
};

const setTaxonomyCampaignSubcategory = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_SUBCATEGORY,
    payload: payload,
  };
};

const setTaxonomyCampaignJobOrRoNumber = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_PRECISION_JOB_OR_RO_NUMBER,
    payload: payload,
  };
};

const setTaxonomyCampaignGender = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_GENDER,
    payload: payload,
  };
};

const setTaxonomyCampaignAge = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_AGE,
    payload: payload,
  };
};

const setTaxonomyCampaignPhase = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_PHASE,
    payload: payload,
  };
};

const setTaxonomyCampaignChannel = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_CHANNEL,
    payload: payload,
  };
};

const setTaxonomyCampaignDspPublisher = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_DSP_PUBLISHER,
    payload: payload,
  };
};

const setTaxonomyCampaignType = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_CAMPAIGN_TYPE,
    payload: payload,
  };
};

const setTaxonomyCampaignBuyType = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_BUY_TYPE_RATE_TYPE,
    payload: payload,
  };
};

const setTaxonomyCampaignKPI = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_CAMPAIGN_KPI,
    payload: payload,
  };
};

const setTaxonomyCampaignObjective = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_OBJECTIVE,
    payload: payload,
  };
};

const setTaxonomyCampaignAudienceType = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_AUDIENCE_TYPE,
    payload: payload,
  };
};

const setTaxonomyCampaignFreeForm = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_FREE_FORM,
    payload: payload,
  };
};

const setTaxonomyCampaignUniqueId = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_UNIQUE_ID,
    payload: payload,
  };
};

const setTaxonomyCampaignStartDate = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_START_DATE,
    payload: payload,
  };
};

const setTaxonomyCampaignMatchType = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_MATCH_TYPE,
    payload: payload,
  };
};

const setTaxonomyCampaignSizmekTracking = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_SIZMMEK_TRACKING,
    payload: payload,
  };
};

const setTaxonomyCampaignClearForm = (payload) => {
  return {
    type: TAXONOMY_CAMPAIGN_CLEAR_FORM,
    payload: payload,
  };
};

export {
  setTaxonomyCampaignName,
  setTaxonomyCampaignProductName,
  setTaxonomyCampaignAdvertiser,
  setTaxonomyCampaignSubcategory,
  setTaxonomyCampaignJobOrRoNumber,
  setTaxonomyCampaignGender,
  setTaxonomyCampaignAge,
  setTaxonomyCampaignPhase,
  setTaxonomyCampaignChannel,
  setTaxonomyCampaignDspPublisher,
  setTaxonomyCampaignType,
  setTaxonomyCampaignBuyType,
  setTaxonomyCampaignKPI,
  setTaxonomyCampaignObjective,
  setTaxonomyCampaignAudienceType,
  setTaxonomyCampaignFreeForm,
  setTaxonomyCampaignUniqueId,
  setTaxonomyCampaignStartDate,
  setTaxonomyCampaignMatchType,
  setTaxonomyCampaignSizmekTracking,
  setTaxonomyCampaignClearForm,
};
