export const CAMPAIGN_OBJECTIVE_AND_TYPE = 'CAMPAIGN_OBJECTIVE_AND_TYPE';
export const CAMPAIGN_GOAL_CHECKBOX = 'CAMPAIGN_GOAL_CHECKBOX';
export const CAMPAIGN_GOAL_WEBSITE_VISITS = 'CAMPAIGN_GOAL_WEBSITE_VISITS';
export const CAMPAIGN_GOAL_PHONE_CALLS = 'CAMPAIGN_GOAL_PHONE_CALLS';
export const CAMPAIGN_GOAL_APP_PLATFORM = 'CAMPAIGN_GOAL_APP_PLATFORM';
export const CAMPAIGN_LIST = 'CAMPAIGN_LIST';
export const CAMPAIGN_GOAL_APP_NAME = 'CAMPAIGN_GOAL_APP_NAME';
export const GOOGLE_CAMPAIGN_BIDDING_FOCUS = 'GOOGLE_CAMPAIGN_BIDDING_FOCUS';
export const GOOGLE_CAMPAIGN_BIDDING_TARGET_COST_CHECKBOX =
  'GOOGLE_CAMPAIGN_BIDDING_TARGET_COST_CHECKBOX';
export const GOOGLE_CAMPAIGN_BIDDING_CPC_TARGET =
  'GOOGLE_CAMPAIGN_BIDDING_CPC_TARGET';
export const GOOGLE_CAMPAIGN_BIDDING_CPA_COST =
  'GOOGLE_CAMPAIGN_BIDDING_CPA_COST';
export const GOOGLE_CAMPAIGN_BIDDING_CPI_COST =
  'GOOGLE_CAMPAIGN_BIDDING_CPI_COST';
export const GOOGLE_CAMPAIGN_BIDDING_CPPR_COST =
  'GOOGLE_CAMPAIGN_BIDDING_CPPR_COST';
export const GOOGLE_CAMPAIGN_NETOWRK_ID = 'GOOGLE_CAMPAIGN_NETOWRK_ID';
export const GOOGLE_CAMPAIGN_START_DATE = 'GOOGLE_CAMPAIGN_START_DATE';
export const GOOGLE_CAMPAIGN_END_DATE = 'GOOGLE_CAMPAIGN_END_DATE';
export const GOOGLE_CAMPAIGN_ADD_SCHEDULE = 'GOOGLE_CAMPAIGN_ADD_SCHEDULE';
export const GOOGLE_CAMPAIGN_ADD_SCHEDULE_SELECT_DAYS =
  'GOOGLE_CAMPAIGN_ADD_SCHEDULE_SELECT_DAYS';
export const GOOGLE_CAMPAIGN_ADD_SCHEDULE_TIME =
  'GOOGLE_CAMPAIGN_ADD_SCHEDULE_TIME';
export const GOOGLE_CAMPAIGN_REMOVE_SCHEDULE =
  'GOOGLE_CAMPAIGN_REMOVE_SCHEDULE';
export const GOOGLE_CAMPAIGN_CAMPAIGN_URL = 'GOOGLE_CAMPAIGN_CAMPAIGN_URL';
export const GOOGLE_CAMPAIGN_BUDGET = 'GOOGLE_CAMPAIGN_BUDGET';
export const GOOGLE_CAMPAIGN_ADD_PARAM = 'GOOGLE_CAMPAIGN_ADD_PARAM';
export const GOOGLE_CAMPAIGN_CUS_PARAM = 'GOOGLE_CAMPAIGN_CUS_PARAM';
export const GOOGLE_CAMPAIGN_CAMPAIGN_NAME = 'GOOGLE_CAMPAIGN_CAMPAIGN_NAME';
export const GOOGLE_CAMPAIGN_TRACKING_TEMPLATE =
  'GOOGLE_CAMPAIGN_TRACKING_TEMPLATE';
export const GOOGLE_CAMPAIGN_CAMPAIGN_URL_TEMPLATE =
  'GOOGLE_CAMPAIGN_CAMPAIGN_URL_TEMPLATE';
export const GOOGLE_CAMPAIGN_BIDDING_AD_LOCATION =
  'GOOGLE_CAMPAIGN_BIDDING_AD_LOCATION';
export const GOOGLE_CAMPAIGN_BRAND_RESTRICTED_LIST =
  'GOOGLE_CAMPAIGN_BRAND_RESTRICTED_LIST';
export const GOOGLE_CAMPAIGN_BRAND_RESTRICTED =
  'GOOGLE_CAMPAIGN_BRAND_RESTRICTED';
export const GOOGLE_CAMPAIGN_GET_MASTER_API = 'GOOGLE_CAMPAIGN_GET_MASTER_API';
export const GOOGLE_CAMPAIGN_SET_WEB_URL_DISPLAY =
  'GOOGLE_CAMPAIGN_SET_WEB_URL_DISPLAY';
export const GOOGLE_CAMPAIGN_CPA_CHECKBOX_HANDLER =
  'GOOGLE_CAMPAIGN_CPA_CHECKBOX_HANDLER';
export const GOOGLE_CAMPAIGN_ROAS_CHECKBOX_HANDLER =
  'GOOGLE_CAMPAIGN_ROAS_CHECKBOX_HANDLER';
export const GOOGLE_CAMPAIGN_CPC_CHECKBOX_HANDLER =
  'GOOGLE_CAMPAIGN_CPC_CHECKBOX_HANDLER';
export const GOOGLE_CAMPAIGN_MERCHENT_CENTER_ACCOUNT =
  'GOOGLE_CAMPAIGN_MERCHENT_CENTER_ACCOUNT';
export const GOOGLE_CAMPAIGN_OPTIMIZE_CAMPAIGN_CHECK =
  'GOOGLE_CAMPAIGN_OPTIMIZE_CAMPAIGN_CHECK';
export const GOOGLE_CAMPAIGN_BID_HIGHER_FOR_NEW_CUSTOMERS_CHECK =
  'GOOGLE_CAMPAIGN_BID_HIGHER_FOR_NEW_CUSTOMERS_CHECK';
export const GOOGLE_CAMPAIGN_BID_ONLY_FOR_NEW_CUSTOMERS_CHECK =
  'GOOGLE_CAMPAIGN_BID_ONLY_FOR_NEW_CUSTOMERS_CHECK';
export const GOOGLE_CAMPAIGN_TEXT_ASSET_CHECK =
  'GOOGLE_CAMPAIGN_TEXT_ASSET_CHECK';
export const GOOGLE_CAMPAIGN_FINAL_URL_CHECK =
  'GOOGLE_CAMPAIGN_FINAL_URL_CHECK';
export const GOOGLE_CAMPAIGN_WAYS_TO_GET_CONVERSIONS =
  'GOOGLE_CAMPAIGN_WAYS_TO_GET_CONVERSIONS';
export const GOOGLE_CAMPAIGN_PAY_FOR_TYPE = 'GOOGLE_CAMPAIGN_PAY_FOR_TYPE';
export const GOOGLE_CAMPAIGN_VIEWABLE_CPM = 'GOOGLE_CAMPAIGN_VIEWABLE_CPM';
export const GOOGLE_CAMPAIGN_SET_SELECTED_OPERATING_TYPE =
  'GOOGLE_CAMPAIGN_SET_SELECTED_OPERATING_TYPE';
export const GOOGLE_CAMPAIGN_ADD_OPERATING_SYSTEM =
  'GOOGLE_CAMPAIGN_ADD_OPERATING_SYSTEM';
export const GOOGLE_CAMPAIGN_SET_SELECTED_DEVICE_MODEL =
  'GOOGLE_CAMPAIGN_SET_SELECTED_DEVICE_MODEL';
export const GOOGLE_CAMPAIGN_SET_SELECTED_FINAL_DEVICE =
  'GOOGLE_CAMPAIGN_SET_SELECTED_FINAL_DEVICE';
export const GOOGLE_CAMPAIGN_ADD_DEVICE_MODEL =
  'GOOGLE_CAMPAIGN_ADD_DEVICE_MODEL';
export const GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM =
  'GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM';
export const GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_SUBCHILD =
  'GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_SUBCHILD';
export const GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_FINALVAL =
  'GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_FINALVAL';
export const GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL =
  'GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL';
export const GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_SUBCHILD =
  'GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_SUBCHILD';
export const GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_FINALVAL =
  'GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_FINALVAL';
export const GOOGLE_CAMPAIGN_ADD_CAMPAIGN_GOAL =
  'GOOGLE_CAMPAIGN_ADD_CAMPAIGN_GOAL';
export const GOOGLE_CAMPAIGN_ADD_CONVERSION_GOAL =
  'GOOGLE_CAMPAIGN_ADD_CONVERSION_GOAL';
export const GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_TARGET_COST_PER_ACTION_VAL =
  'GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_TARGET_COST_PER_ACTION_VAL';
export const GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_LANG_ENABLE_DESABLE_STATUS =
  'GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_LANG_ENABLE_DESABLE_STATUS';
export const GOOGLE_CAMPAIGN_ADD_LANGAUGES = 'GOOGLE_CAMPAIGN_ADD_LANGAUGES';
export const GOOGLE_CAMPAIGN_ADD_DEVICE_TYPE =
  'GOOGLE_CAMPAIGN_ADD_DEVICE_TYPE';
export const GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_BUDGET_AMOUNT =
  'GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_BUDGET_AMOUNT';
export const GOOGLE_CAMPAIGN_RESET_FORM = 'GOOGLE_CAMPAIGN_RESET_FORM';
export const GOOGLE_CAMPAIGN_PRODUCT_FEED = 'GOOGLE_CAMPAIGN_PRODUCT_FEED';
export const GOOGLE_CAMPAIGN_PRODUCT_MERCHENT_CENTER =
  'GOOGLE_CAMPAIGN_PRODUCT_MERCHENT_CENTER';
export const GOOGLE_CAMPAIGN_ADD_NETWORKS = 'GOOGLE_CAMPAIGN_ADD_NETWORKS';
export const GOOGLE_CAMPAIGN_AD_ROTATION = 'GOOGLE_CAMPAIGN_AD_ROTATION';
export const GOOGLE_CAMPAIGN_CONTENTLABEL = 'GOOGLE_CAMPAIGN_CONTENTLABEL';
export const GOOGLE_CAMPAIGN_CONTENTTYPE = 'GOOGLE_CAMPAIGN_CONTENTTYPE';
export const GOOGLE_CAMPAIGN_SENSTIVECONTENT =
  'GOOGLE_CAMPAIGN_SENSTIVECONTENT';
export const GOOGLE_CAMPAIGN_CAMPAIGN_SUBTYPE_SHOPPING =
  'GOOGLE_CAMPAIGN_CAMPAIGN_SUBTYPE_SHOPPING';
export const GOOGLE_CAMPAIGN_CAMPAIGN_INVENTORY_FILTER_SHOPPING =
  'GOOGLE_CAMPAIGN_CAMPAIGN_INVENTORY_FILTER_SHOPPING';
export const GOOGLE_CAMPAIGN_CAMPAIGN_LOCAL_PRODUCTS_SHOPPING =
  'GOOGLE_CAMPAIGN_CAMPAIGN_LOCAL_PRODUCTS_SHOPPING';
export const GOOGLE_CAMPAIGN_CAMPAIGN_CAMPAIGN_PRIORITY_SHOPPING =
  'GOOGLE_CAMPAIGN_CAMPAIGN_CAMPAIGN_PRIORITY_SHOPPING';
export const GOOGLE_CAMPAIGN_LOCATION_TYPE = 'GOOGLE_CAMPAIGN_LOCATION_TYPE';
export const GOOGLE_CAMPAIGN_AD_SPECIFIC_TARGETTING_DEVICES =
  'GOOGLE_CAMPAIGN_AD_SPECIFIC_TARGETTING_DEVICES';
export const GOOGLE_CAMPAIGN_APP_PLATFORM_APP =
  'GOOGLE_CAMPAIGN_APP_PLATFORM_APP';
export const GOOGLE_CAMPAIGN_APP_NAME_APP = 'GOOGLE_CAMPAIGN_APP_NAME_APP';
export const GOOGLE_CAMPAIGN_IS_DATA_FEED_APP =
  'GOOGLE_CAMPAIGN_IS_DATA_FEED_APP';
export const GOOGLE_CAMPAIGN_DATA_FEED_APP_TYPE =
  'GOOGLE_CAMPAIGN_DATA_FEED_APP_TYPE';
export const GOOGLE_CAMPAIGN_CONVERSION_TRACKING =
  'GOOGLE_CAMPAIGN_CONVERSION_TRACKING';
export const GOOGLE_CAMPAIGN_GET_CAMPAIGN_DATA =
  'GOOGLE_CAMPAIGN_GET_CAMPAIGN_DATA';
export const GOOGLE_CAMPAIGN_LANGUAGE = 'GOOGLE_CAMPAIGN_LANGUAGE';
export const GOOGLE_CAMPAIGN_AUD = 'GOOGLE_CAMPAIGN_AUD';
export const GOOGLE_CAMPAIGN_CAMPAIGN_URL_ON_PERFORMANCE =
  'GOOGLE_CAMPAIGN_CAMPAIGN_URL_ON_PERFORMANCE';
