import SimpleSelectBox from '../common/SimpleSelectBox';

const AutomateAndBudgetCase = (props) => {
  return (
    <>
      <div className='card-body'>
        <div className='info-icon-body'>
          <div className='more-info-icon'>
            <i className='icon-lock' />
          </div>
          <div className='more-info-text'>
            <p>Budget is set at the insertion order level.</p>
          </div>
        </div>
      </div>
      <div class='card-form form-panel form-panel-group-add form-panel-no-border'>
        <div class='row align-items-center'>
          <div class='form-item col-box-3'>
            <SimpleSelectBox
              placeholder='Flight'
              // readOnly={true}
              disabled={true}
              value='Flight'
              options={['Flight']}
            ></SimpleSelectBox>
          </div>
          <div class='form-item col-box-3'>
            <SimpleSelectBox
              placeholder='ASAP'
              // readOnly={true}
              disabled={true}
              value='ASAP'
              options={['ASAP']}
            ></SimpleSelectBox>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomateAndBudgetCase;
