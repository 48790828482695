import {
  CAMPAIGN_GOAL_APP_NAME,
  CAMPAIGN_GOAL_APP_PLATFORM,
  CAMPAIGN_GOAL_CHECKBOX,
  CAMPAIGN_GOAL_PHONE_CALLS,
  CAMPAIGN_GOAL_WEBSITE_VISITS,
  CAMPAIGN_LIST,
  CAMPAIGN_OBJECTIVE_AND_TYPE,
  GOOGLE_CAMPAIGN_ADD_PARAM,
  GOOGLE_CAMPAIGN_ADD_SCHEDULE,
  GOOGLE_CAMPAIGN_ADD_SCHEDULE_SELECT_DAYS,
  GOOGLE_CAMPAIGN_ADD_SCHEDULE_TIME,
  GOOGLE_CAMPAIGN_BIDDING_AD_LOCATION,
  GOOGLE_CAMPAIGN_BIDDING_CPA_COST,
  GOOGLE_CAMPAIGN_BIDDING_FOCUS,
  GOOGLE_CAMPAIGN_BIDDING_TARGET_COST,
  GOOGLE_CAMPAIGN_BIDDING_TARGET_COST_CHECKBOX,
  GOOGLE_CAMPAIGN_BID_HIGHER_FOR_NEW_CUSTOMERS_CHECK,
  GOOGLE_CAMPAIGN_BID_ONLY_FOR_NEW_CUSTOMERS_CHECK,
  GOOGLE_CAMPAIGN_BRAND_RESTRICTED,
  GOOGLE_CAMPAIGN_BRAND_RESTRICTED_LIST,
  GOOGLE_CAMPAIGN_BUDGET,
  GOOGLE_CAMPAIGN_CAMPAIGN_NAME,
  GOOGLE_CAMPAIGN_CAMPAIGN_URL,
  GOOGLE_CAMPAIGN_CAMPAIGN_URL_TEMPLATE,
  GOOGLE_CAMPAIGN_CPA_CHECKBOX_HANDLER,
  GOOGLE_CAMPAIGN_CPC_CHECKBOX_HANDLER,
  GOOGLE_CAMPAIGN_CUS_PARAM,
  GOOGLE_CAMPAIGN_END_DATE,
  GOOGLE_CAMPAIGN_FINAL_URL_CHECK,
  GOOGLE_CAMPAIGN_GET_MASTER_API,
  GOOGLE_CAMPAIGN_MERCHENT_CENTER_ACCOUNT,
  GOOGLE_CAMPAIGN_NETOWRK_ID,
  GOOGLE_CAMPAIGN_OPTIMIZE_CAMPAIGN_CHECK,
  GOOGLE_CAMPAIGN_PAY_FOR_TYPE,
  GOOGLE_CAMPAIGN_REMOVE_SCHEDULE,
  GOOGLE_CAMPAIGN_ROAS_CHECKBOX_HANDLER,
  GOOGLE_CAMPAIGN_SET_WEB_URL_DISPLAY,
  GOOGLE_CAMPAIGN_START_DATE,
  GOOGLE_CAMPAIGN_TEXT_ASSET_CHECK,
  GOOGLE_CAMPAIGN_TRACKING_TEMPLATE,
  GOOGLE_CAMPAIGN_VIEWABLE_CPM,
  GOOGLE_CAMPAIGN_WAYS_TO_GET_CONVERSIONS,
  GOOGLE_CAMPAIGN_SET_SELECTED_OPERATING_TYPE,
  GOOGLE_CAMPAIGN_ADD_OPERATING_SYSTEM,
  GOOGLE_CAMPAIGN_SET_SELECTED_DEVICE_MODEL,
  GOOGLE_CAMPAIGN_SET_SELECTED_FINAL_DEVICE,
  GOOGLE_CAMPAIGN_ADD_DEVICE_MODEL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM,
  GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_SUBCHILD,
  GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_FINALVAL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_SUBCHILD,
  GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_FINALVAL,
  GOOGLE_CAMPAIGN_ADD_CAMPAIGN_GOAL,
  GOOGLE_CAMPAIGN_ADD_CONVERSION_GOAL,
  GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_TARGET_COST_PER_ACTION_VAL,
  GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_LANG_ENABLE_DESABLE_STATUS,
  GOOGLE_CAMPAIGN_ADD_LANGAUGES,
  GOOGLE_CAMPAIGN_ADD_DEVICE_TYPE,
  GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_BUDGET_AMOUNT,
  GOOGLE_CAMPAIGN_RESET_FORM,
  GOOGLE_CAMPAIGN_PRODUCT_FEED,
  GOOGLE_CAMPAIGN_PRODUCT_MERCHENT_CENTER,
  GOOGLE_CAMPAIGN_ADD_NETWORKS,
  GOOGLE_CAMPAIGN_AD_ROTATION,
  GOOGLE_CAMPAIGN_CONTENTLABEL,
  GOOGLE_CAMPAIGN_CONTENTTYPE,
  GOOGLE_CAMPAIGN_SENSTIVECONTENT,
  GOOGLE_CAMPAIGN_CAMPAIGN_SUBTYPE_SHOPPING,
  GOOGLE_CAMPAIGN_CAMPAIGN_INVENTORY_FILTER_SHOPPING,
  GOOGLE_CAMPAIGN_CAMPAIGN_LOCAL_PRODUCTS_SHOPPING,
  GOOGLE_CAMPAIGN_CAMPAIGN_CAMPAIGN_PRIORITY_SHOPPING,
  GOOGLE_CAMPAIGN_LOCATION_TYPE,
  GOOGLE_CAMPAIGN_AD_SPECIFIC_TARGETTING_DEVICES,
  GOOGLE_CAMPAIGN_APP_PLATFORM_APP,
  GOOGLE_CAMPAIGN_APP_NAME_APP,
  GOOGLE_CAMPAIGN_BIDDING_CPI_COST,
  GOOGLE_CAMPAIGN_BIDDING_CPPR_COST,
  GOOGLE_CAMPAIGN_IS_DATA_FEED_APP,
  GOOGLE_CAMPAIGN_DATA_FEED_APP_type,
  GOOGLE_CAMPAIGN_DATA_FEED_APP_TYPE,
  GOOGLE_CAMPAIGN_CONVERSION_TRACKING,
  GOOGLE_CAMPAIGN_GET_CAMPAIGN_DATA,
  GOOGLE_CAMPAIGN_LANGUAGE,
  GOOGLE_CAMPAIGN_AUD,
  GOOGLE_CAMPAIGN_BIDDING_CPC_TARGET,
  GOOGLE_CAMPAIGN_CAMPAIGN_URL_ON_PERFORMANCE,
} from './CreateCampaignActionTypes';
import { getApi } from '../../utils/Apis';
import { baseUrlStg, googleV1Url } from '../../utils/constants';

const setCampaignObjectiveAndType = (payload) => {
  return {
    type: CAMPAIGN_OBJECTIVE_AND_TYPE,
    payload: payload,
  };
};
const setAdRotation = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_AD_ROTATION,
    payload: payload,
  };
};
const setCampaignGoalCheck = (payload) => {
  return {
    type: CAMPAIGN_GOAL_CHECKBOX,
    payload: payload,
  };
};
const setWebsiteVisits = (payload) => {
  return {
    type: CAMPAIGN_GOAL_WEBSITE_VISITS,
    payload: payload,
  };
};
const setWebsiteurlDisplay = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_SET_WEB_URL_DISPLAY,
    payload: payload,
  };
};
const setPhoneCalls = (payload) => {
  return {
    type: CAMPAIGN_GOAL_PHONE_CALLS,
    payload: payload,
  };
};
const setAppPlatform = (payload) => {
  return {
    type: CAMPAIGN_GOAL_APP_PLATFORM,
    payload: payload,
  };
};
const setAppName = (payload) => {
  return {
    type: CAMPAIGN_GOAL_APP_NAME,
    payload: payload,
  };
};
const setAppNameForApp = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_APP_NAME_APP,
    payload: payload,
  };
};
const setCampaignList = (payload) => {
  return async (dispatch, getState) => {
    let response = await getApi(
      `${baseUrlStg}/api/Taxonomy/GetCampaignListByIdentifier?campaignIdentifier=Google`,
    );
    try {
      let data = response?.map((item) => {
        return { label: item?.campignName, value: item?.campaignId };
      });
      // console.log("res", data);
      dispatch({
        type: CAMPAIGN_LIST,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const setCampaignName = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_NAME,
    payload: payload,
  };
};
const setMerchantCenterAccount = (payload) => {
  console.log('setMerchantCenterAccount', payload);
  return {
    type: GOOGLE_CAMPAIGN_MERCHENT_CENTER_ACCOUNT,
    payload: payload,
  };
};
const setTrackingTemplatesList = (e) => {
  let value = e?.value;
  return async (dispatch) => {
    if (typeof value !== 'undefined' || value !== null) {
      let response = await getApi(
        `${baseUrlStg}/api/Taxonomy/GetCidListByAddIdOrCampaignId?campaignId=${value}`,
      );
      // console.log("res", response);
      try {
        let data = response?.map((item) => {
          return { label: item?.landingUrl, value: item?.cidId };
        });
        // console.log("rest", data);
        dispatch({
          type: GOOGLE_CAMPAIGN_TRACKING_TEMPLATE,
          payload: data,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
};
const setGoogleBiddingFocus = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_FOCUS,
    payload: payload,
  };
};
const setWaysToGetConversions = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_WAYS_TO_GET_CONVERSIONS,
    payload: payload,
  };
};
const setPayForType = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_PAY_FOR_TYPE,
    payload: payload,
  };
};

const setTargetcostCheckState = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_TARGET_COST_CHECKBOX,
    payload: payload,
  };
};
const setOptimizeCampaignCheck = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_OPTIMIZE_CAMPAIGN_CHECK,
    payload: payload,
  };
};
const setBidHigherCheck = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BID_HIGHER_FOR_NEW_CUSTOMERS_CHECK,
    payload: payload,
  };
};
const setBidNewCusCheck = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BID_ONLY_FOR_NEW_CUSTOMERS_CHECK,
    payload: payload,
  };
};
const setTextAssetCheck = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_TEXT_ASSET_CHECK,
    payload: payload,
  };
};
const setFinalUrlCheck = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_FINAL_URL_CHECK,
    payload: payload,
  };
};
const setcpaCheckBox = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CPA_CHECKBOX_HANDLER,
    payload: payload,
  };
};
const setroasCheckBox = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ROAS_CHECKBOX_HANDLER,
    payload: payload,
  };
};
const setcpcCheckBox = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CPC_CHECKBOX_HANDLER,
    payload: payload,
  };
};
const setTargetCpacost = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_CPA_COST,
    payload: payload,
  };
};

const setCPCTarget = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_CPC_TARGET,
    payload: payload,
  };
};
const setTargetCpicost = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_CPI_COST,
    payload: payload,
  };
};
const setIsDataFeed = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_IS_DATA_FEED_APP,
    payload: payload,
  };
};
const setDataFeedType = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_DATA_FEED_APP_TYPE,
    payload: payload,
  };
};
const setTargetCpprcost = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_CPPR_COST,
    payload: payload,
  };
};
const setViewableCPM = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_VIEWABLE_CPM,
    payload: payload,
  };
};
const setAdLocation = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BIDDING_AD_LOCATION,
    payload: payload,
  };
};
const setStartDate = (payload) => {
  // console.log("payloaddd", payload);
  return {
    type: GOOGLE_CAMPAIGN_START_DATE,
    payload: payload,
  };
};
const setEndDate = (payload) => {
  // console.log("payloadd", payload);
  return {
    type: GOOGLE_CAMPAIGN_END_DATE,
    payload: payload,
  };
};
const setRestrictedBrandList = (payload) => {
  return async (dispatch) => {
    let response = await getApi(`${googleV1Url}/getBrandRestricted`);
    // console.log("res", response);
    try {
      let data = response?.response?.map((item) => {
        return {
          label: item?.brandName,
          value: item?.googleBrandrestrictedid,
        };
      });
      // console.log("rest", data);
      dispatch({
        type: GOOGLE_CAMPAIGN_BRAND_RESTRICTED_LIST,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};
const setNetworkId = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_NETOWRK_ID,
    payload: payload,
  };
};
const setBrandRestricted = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BRAND_RESTRICTED,
    payload: payload,
  };
};
const setLanguagee = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_LANGUAGE,
    payload: payload,
  };
};
const setAudienceSeg = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_AUD,
    payload: payload,
  };
};
const setConversionTracking = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CONVERSION_TRACKING,
    payload: payload,
  };
};
const addSchedule = () => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SCHEDULE,
  };
};

const setSelecteDays = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SCHEDULE_SELECT_DAYS,
    payload: { selected_day: e, index },
  };
};
const setLocationType = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_LOCATION_TYPE,
    payload: payload,
  };
};

const adScheduleTime = (time, index, timeType) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SCHEDULE_TIME,
    payload: {
      time,
      index,
      timeType,
    },
  };
};
const removeSchedule = (index) => {
  return {
    type: GOOGLE_CAMPAIGN_REMOVE_SCHEDULE,
    payload: {
      index,
    },
  };
};

const setCampaignURL = (e, type) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_URL,
    payload: {
      data: e,
      type: type,
    },
  };
};

const setCampaignURLOnPerformance = (key, value) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_URL_ON_PERFORMANCE,
    payload: {
      key: key,
      value: value,
    },
  };
};

const setCampaignURLTemplate = (e, type) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_URL_TEMPLATE,
    payload: {
      data: e,
      type: type,
    },
  };
};

const setCampaignBudget = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_BUDGET,
    payload: payload,
  };
};
const setCampType = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_SUBTYPE_SHOPPING,
    payload: payload,
  };
};
const setInventoryFilter = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_INVENTORY_FILTER_SHOPPING,
    payload: payload,
  };
};
const setCampaignPriority = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_CAMPAIGN_PRIORITY_SHOPPING,
    payload: payload,
  };
};
const setLocalProducts = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CAMPAIGN_LOCAL_PRODUCTS_SHOPPING,
    payload: payload,
  };
};

const addParam = () => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_PARAM,
  };
};

const setCustomParam = (e, index, type) => {
  return {
    type: GOOGLE_CAMPAIGN_CUS_PARAM,
    payload: {
      data: e,
      index,
      type,
    },
  };
};

export const getMasterApi = (payload) => {
  return async (dispatch, getState) => {
    let response = await getApi(`${googleV1Url}/getMasters`);
    // console.log("res", response);
    try {
      if (response?.status == 200) {
        let data = response?.data;

        dispatch({
          type: GOOGLE_CAMPAIGN_GET_MASTER_API,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
// Added by prashant
const setSelectedOperatingSystem = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_SET_SELECTED_OPERATING_TYPE,
    payload: payload,
  };
};

const setSelectedCampaignGoal = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_CAMPAIGN_GOAL,
    payload: payload,
  };
};

const setSelectedConversionGoal = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_CONVERSION_GOAL,
    payload: payload,
  };
};

const setProductFeedStatus = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_PRODUCT_FEED,
    payload: payload,
  };
};

const setProductMerchentId = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_PRODUCT_MERCHENT_CENTER,
    payload: payload,
  };
};

const setSelectedDemandGenCostPerActionVal = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_TARGET_COST_PER_ACTION_VAL,
    payload: payload,
  };
};

const setSelectedDemandGenLangEnableDesableStatus = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_LANG_ENABLE_DESABLE_STATUS,
    payload: payload,
  };
};

const setSelectedLangauges = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_LANGAUGES,
    payload: payload,
  };
};

const setSelectedDeviceType = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_DEVICE_TYPE,
    payload: payload,
  };
};
const setDemandGenBudgetAmount = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_BUDGET_AMOUNT,
    payload: payload,
  };
};

const addOperatingSystem = () => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_OPERATING_SYSTEM,
  };
};

const setSelectedDeviceModel = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_SET_SELECTED_DEVICE_MODEL,
    payload: payload,
  };
};

const setSelectedFinalDeviceModel = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_SET_SELECTED_FINAL_DEVICE,
    payload: payload,
  };
};

const addDeviceModel = () => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_DEVICE_MODEL,
  };
};

// Set Value to Operating system start
const addSelectedOperatingSystemVal = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM,
    payload: { selectedOperatingSystem: e, index },
  };
};

const addSelectedOperatingSystemSubChildVal = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_SUBCHILD,
    payload: { selectedMinimumValue: e, index },
  };
};

const addSelectedOperatingSystemFinalChildVal = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_FINALVAL,
    payload: { selectedMaximumValue: e, index },
  };
};
// Set Value to Operating system end
// Set Value to Device model start
const addSelectedDeviceModelVal = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL,
    payload: { selectedDeviceModel: e, index },
  };
};

const addSelectedDeviceModelSubChildVal = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_SUBCHILD,
    payload: { selectedSubChild: e, index },
  };
};

const addSelectedDeviceModelFinalChildVal = (e, index) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_FINALVAL,
    payload: { selectedFinalChild: e, index },
  };
};

const addSelectedNetworks = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_ADD_NETWORKS,
    payload: payload,
  };
};
// Set Value to Device model end

const resetForm = () => {
  return {
    type: GOOGLE_CAMPAIGN_RESET_FORM,
  };
};

const setContentLabels = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CONTENTLABEL,
    payload: payload,
  };
};

const setContentType = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_CONTENTTYPE,
    payload: payload,
  };
};

const setSensitiveContent = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_SENSTIVECONTENT,
    payload: payload,
  };
};

const setSpecificTargetingDevices = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_AD_SPECIFIC_TARGETTING_DEVICES,
    payload: payload,
  };
};
const setAppPlatformForApp = (payload) => {
  return {
    type: GOOGLE_CAMPAIGN_APP_PLATFORM_APP,
    payload: payload,
  };
};
const getCampaignDataById = (campaignId) => {
  return async (dispatch, getState) => {
    try {
      const response = await getApi(
        `${baseUrlStg}/api/v1/Google/GetGoogleCampaign?id=${campaignId}`,
        { accessToken: 123123 },
      );
      if (response && response.data) {
        dispatch({
          type: GOOGLE_CAMPAIGN_GET_CAMPAIGN_DATA,
          payload: response.data,
        });
      } else {
        console.error('Invalid response format:', response);
      }
    } catch (err) {
      console.error('Error fetching campaign data:', err);
    }
  };
};

export {
  getCampaignDataById,
  setCampaignObjectiveAndType,
  setCampaignGoalCheck,
  setWebsiteVisits,
  setPhoneCalls,
  setAppPlatform,
  setCampaignList,
  setAppName,
  setGoogleBiddingFocus,
  setTargetcostCheckState,
  setCPCTarget,
  setTargetCpacost,
  setTargetCpicost,
  setTargetCpprcost,
  setNetworkId,
  setStartDate,
  setEndDate,
  addSchedule,
  setSelecteDays,
  adScheduleTime,
  removeSchedule,
  setCampaignURL,
  setCampaignBudget,
  addParam,
  setCustomParam,
  setCampaignName,
  setTrackingTemplatesList,
  setCampaignURLTemplate,
  setAdLocation,
  setRestrictedBrandList,
  setBrandRestricted,
  setWebsiteurlDisplay,
  setcpaCheckBox,
  setroasCheckBox,
  setcpcCheckBox,
  setMerchantCenterAccount,
  setOptimizeCampaignCheck,
  setBidHigherCheck,
  setBidNewCusCheck,
  setTextAssetCheck,
  setFinalUrlCheck,
  setWaysToGetConversions,
  setPayForType,
  setViewableCPM,
  setSelectedOperatingSystem,
  addOperatingSystem,
  setSelectedDeviceModel,
  setSelectedFinalDeviceModel,
  addDeviceModel,
  addSelectedOperatingSystemVal,
  addSelectedOperatingSystemSubChildVal,
  addSelectedOperatingSystemFinalChildVal,
  addSelectedDeviceModelVal,
  addSelectedDeviceModelSubChildVal,
  addSelectedDeviceModelFinalChildVal,
  setSelectedCampaignGoal,
  setSelectedConversionGoal,
  setSelectedDemandGenCostPerActionVal,
  setSelectedDemandGenLangEnableDesableStatus,
  setSelectedLangauges,
  setSelectedDeviceType,
  setDemandGenBudgetAmount,
  resetForm,
  setProductMerchentId,
  setProductFeedStatus,
  addSelectedNetworks,
  setAdRotation,
  setContentLabels,
  setContentType,
  setSensitiveContent,
  setCampType,
  setInventoryFilter,
  setLocalProducts,
  setCampaignPriority,
  setLocationType,
  setSpecificTargetingDevices,
  setAppPlatformForApp,
  setAppNameForApp,
  setIsDataFeed,
  setDataFeedType,
  setConversionTracking,
  setLanguagee,
  setAudienceSeg,
  setCampaignURLOnPerformance,
};
