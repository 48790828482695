import React from 'react';
import Card from '../common/Card';
import CardHeader from '../common/CardHeader';

const OptimizeTarget = ({ value }) => {
  return (
    <Card>
      <CardHeader>
        <div className='card-header-top'>
          <div className='card-lt-pane'>
            <h5 className='d-flex align-items-center'>
              Optimized targeting
              <span
                className='label-information tooltips tool-bottom'
                tooltip='Cascading Style Sheets'
                tooltip-position='top'
              >
                <i className='icon-info'></i>
                <span>
                  Information such as your selected audience, landing page, and
                  assets are used to find people likely to convert. Your
                  targeting signals may see reduced traffic if better
                  performance is found elsewhere.{' '}
                </span>
              </span>
            </h5>
            <p className='text'>
              {' '}
              Optimized targeting helps you get more conversions within your
              budget. Google may find people beyond your selected audience.
            </p>
            <div className='action-btn-both action-btn-both-check row relative'>
              <div className='form-check-box col-4'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckbox9'
                  defaultValue='Display'
                  name='creative'
                  checked={value?.length > 0 ? true : false}
                />
                <label className='form-check-label' htmlFor='inlineCheckbox9'>
                  Use optimized targeting
                </label>
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

export default OptimizeTarget;
