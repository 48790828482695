export const TAXONOMY_AD_AD_ID = 'TAXONOMY_AD_AD_ID';
export const TAXONOMY_AD_USP_FOCUS = 'TAXONOMY_AD_USP_FOCUS';
export const TAXONOMY_AD_CREATIVE_TYPE = 'TAXONOMY_AD_CREATIVE_TYPE';
export const TAXONOMY_AD_CONCEPT_NAME = 'TAXONOMY_AD_CONCEPT_NAME';
export const TAXONOMY_AD_CONTENT_TYPE = 'TAXONOMY_AD_CONTENT_TYPE';
export const TAXONOMY_AD_CALL_TO_ACTION = 'TAXONOMY_AD_CALL_TO_ACTION';
export const TAXONOMY_AD_CREATIVE_SIZE = 'TAXONOMY_AD_CREATIVE_SIZE';
export const TAXONOMY_AD_AV_DURATION = 'TAXONOMY_AD_AV_DURATION';
export const TAXONOMY_AD_LANGUAGE = 'TAXONOMY_AD_LANGUAGE';
export const TAXONOMY_AD_UNIQUE_ID = 'TAXONOMY_AD_UNIQUE_ID';
export const Taxonomy_AD_CAMPAIGN_ID = 'Taxonomy_AD_CAMPAIGN_ID';
export const TAXONOMY_AD_RESET_FORM = 'TAXONOMY_AD_RESET_FORM';
