import { Accordion } from 'react-bootstrap';
import CardBody from './CardBody';
import CardHeader from './CardHeader';
import ReactSelect from './ReactSelect';
import SingleInput from '../google/SingleInput';
import CardItem from './CardItem';
import AddCardMedia from './AddCardMedia';

const DisplaySelectedAccordianItems = (props) => {
  //   console.log('props', props);
  return (
    <>
      <article className='accordion-data-cont'>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <div className='accordion-data'>
                <h4>Card {props?.index + 1}</h4>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className='accordion-body-inner'>
                <div className='card-body'>
                  <div className='form-panel-group-add media-list-col-3'>
                    <div className='media-list media-landscape'>
                      {/* Land Scape image div start */}
                      {props?.item?.landscapeImage?.length > 0 && (
                        <>
                          <CardItem
                            src={
                              props?.item?.landscapeImage?.[0]?.mediaPath ||
                              props?.item?.landscapeImage?.[0]?.medPath
                            }
                            alt={
                              props?.item?.landscapeImage?.[0]?.mediaName ||
                              props?.item?.landscapeImage?.[0]?.medFileName
                            }
                            mediaName={
                              props?.item?.landscapeImage?.[0]?.mediaName ||
                              props?.item?.landscapeImage?.[0]?.medFileName
                            }
                            handleRemoveCardImage={props?.removeCardImage}
                            type={'landscapeImage'}
                            index={props?.index}
                            id={props?.item?.landscapeImage?.[0]?.medId}
                            title={'LandScape Image'}
                            ratio={'1.91:1'}
                          />
                          {/* <div className='slideshow-box d-flex'>
                      <div className='slideshow-imgbox d-flex align-items-center'>
                        <img
                          src={
                            props?.item?.landscapeImage?.[0]?.mediaPath ||
                            props?.item?.landscapeImage?.[0]?.medPath
                          }
                          alt={
                            props?.item?.landscapeImage?.[0]?.mediaName ||
                            props?.item?.landscapeImage?.[0]?.medFileName
                          }
                        />
                      </div>
                      <div className='divder-form'></div>
                      <div className='slideshow-cont'>
                        <div className='slideshow-cont-top'>
                          <h3>
                            {props?.item?.landscapeImage?.[0]?.mediaName ||
                              props?.item?.landscapeImage?.[0]?.medFileName}
                          </h3>
                        </div>
                      </div>
                      <button className='close-slideshow'>
                        <i className='icon-close' />
                      </button>
                    </div> */}
                        </>
                      )}
                      {props?.item?.landscapeImage?.length == 0 && (
                        <AddCardMedia
                          onClickHandle={props?.openCardLandScapeWidget}
                          title={'LandScape Image'}
                          length={props?.item?.landscapeImage?.length}
                        />
                      )}
                      {/* Land Scape image div end */}
                      {/* square  image div start */}
                      {props?.item?.squareImage?.length > 0 && (
                        <>
                          <CardItem
                            src={
                              props?.item?.squareImage?.[0]?.mediaPath ||
                              props?.item?.squareImage?.[0]?.medPath
                            }
                            alt={
                              props?.item?.squareImage?.[0]?.mediaName ||
                              props?.item?.squareImage?.[0]?.medFileName
                            }
                            mediaName={
                              props?.item?.squareImage?.[0]?.mediaName ||
                              props?.item?.squareImage?.[0]?.medFileName
                            }
                            handleRemoveCardImage={props?.removeCardImage}
                            type={'squareImage'}
                            index={props?.index}
                            id={props?.item?.squareImage?.[0]?.medId}
                            title={'Square Image'}
                            ratio={'1:01'}
                          />
                        </>
                      )}
                      {props?.item?.squareImage?.length == 0 && (
                        <AddCardMedia
                          onClickHandle={props?.openCardSquareImageWidget}
                          title={'Square Image'}
                          length={props?.item?.squareImage?.length}
                        />
                      )}
                      {/* square  image div end */}
                      {/* Potrait  image div start */}
                      {props?.item?.potraitImage?.length > 0 && (
                        <>
                          <CardItem
                            src={
                              props?.item?.potraitImage?.[0]?.mediaPath ||
                              props?.item?.potraitImage?.[0]?.medPath
                            }
                            alt={
                              props?.item?.potraitImage?.[0]?.mediaName ||
                              props?.item?.potraitImage?.[0]?.medFileName
                            }
                            mediaName={
                              props?.item?.potraitImage?.[0]?.mediaName ||
                              props?.item?.potraitImage?.[0]?.medFileName
                            }
                            handleRemoveCardImage={props?.removeCardImage}
                            type={'potraitImage'}
                            index={props?.index}
                            id={props?.item?.potraitImage?.[0]?.medId}
                            title={'Potrait Image'}
                            ratio={'4:05'}
                          />
                        </>
                      )}
                      {props?.item?.potraitImage?.length == 0 && (
                        <AddCardMedia
                          onClickHandle={props?.openCardPotraitImageWidget}
                          title={'Potrait Image'}
                          length={props?.item?.potraitImage?.length}
                        />
                      )}
                      {/* Potrait  image div end */}
                    </div>
                  </div>
                </div>
                {/* <span className='error' id='emailError'>
                    {formErrors?.landScapeError}
                  </span> */}
                <CardBody>
                  <SingleInput
                    label={'Headline'}
                    totalStringLength={15}
                    handleSetValue={(e) =>
                      props?.callBackFunction(
                        props?.index,
                        'heading',
                        e?.target?.value,
                      )
                    }
                    bgLineDisplay={true}
                    value={props?.item?.heading}
                  />
                  <SingleInput
                    label={'Final Url'}
                    handleSetValue={(e) =>
                      props?.callBackFunction(
                        props?.index,
                        'finalUrl',
                        e?.target?.value,
                      )
                    }
                    bgLineDisplay={true}
                    value={props?.item?.finalUrl}
                  />
                  <div class='info-icon-body mt-2'>
                    <div class='more-info-text'>
                      <p>https://www.koo.com</p>
                    </div>
                  </div>
                  <div className='action-btn-both action-btn-both-check row'>
                    <div className='form-check-box'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='inlineCheckbox1'
                        handleSetValue={(e) =>
                          props?.callBackFunction(
                            props?.index,
                            'isFinalMobileUrlEnable',
                            e?.target?.checked,
                          )
                        }
                        checked={props?.item?.isFinalMobileUrlEnable}
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineCheckbox1'
                      >
                        Use a different final URL for mobile
                      </label>
                    </div>
                  </div>
                  {props?.item?.isFinalMobileUrlEnable && (
                    <SingleInput
                      label={'Final URL for mobile'}
                      handleSetValue={(e) =>
                        props?.callBackFunction(
                          props?.index,
                          'finalUrlMobile',
                          e?.target?.value,
                        )
                      }
                      value={props?.item?.finalUrlMobile}
                    />
                  )}
                  {
                    <>
                      <section className='group-section-data'>
                        <CardHeader>
                          <h6>Call to action</h6>
                        </CardHeader>
                        <CardBody>
                          <ReactSelect
                            options={props?.getCallToActionLists}
                            closeMenuOnSelect={true}
                            onChange={(e) =>
                              props?.callBackFunction(
                                props?.index,
                                'callToAction',
                                e?.value,
                              )
                            }
                            value={props?.getCallToActionLists?.find((item) => {
                              return (
                                item?.value == props?.item?.callToAction?.value
                              );
                            })}
                          />
                        </CardBody>
                      </section>
                      <div className='divder-form'></div>
                    </>
                  }
                </CardBody>
              </div>
              <div
                className='d-flex justify-content-end'
                onClick={() => props?.removeCard(props?.index)}
                style={{
                  marginTop: '13px',
                }}
              >
                <button
                  type='button'
                  className='btn-icon btn-icon-primary btn-bedcrumb-act '
                >
                  <i className='icon-delete' />
                  Remove
                </button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </article>
    </>
  );
};

export default DisplaySelectedAccordianItems;
