import React, { useState, useEffect, useId, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import _debounce from 'lodash/debounce';
import { useMemo } from 'react';
import Sidebar from '../../components/common/Sidebar';
import Loader from '../../components/common/Loader';
import ReactTable from '../../components/common/ReactTable';
import { baseUrlStg, userManagement } from '../../utils/constants';
import { getApi, postApi } from '../../utils/Apis';
import { DV360Options } from '../DV360/DV360Constant';
import { capitalizeFirstLetter } from '../../utils/utils';
import NavHeading from '../../components/common/NavHeading';
import Dropdown from 'react-bootstrap/Dropdown';
import DeleteUser from './DeleteUser';
import CommonEnableDisableModal from '../../components/common/CommonEnableDisableModal';
import HeaderTopBar from '../DV360/Component/HeaderTopBar';

const UserList = () => {
  const userTab = {
    'All Users': {
      name: 'All Users',
      icon: 'icon-Group-92',
    },
    Disabled: {
      name: 'Disabled Users',
      icon: 'icon-disable-user',
    },
    // 'Deleted Users': {
    //   name: 'Deleted Users',
    //   icon: ' icon-close-round',
    // },
  };

  const [currentTab, setCurrentTab] = useState(userTab['All Users'].name);
  const [data, setData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [campaignName, setCampaignName] = useState('');

  const [isDelete, setIsDelete] = useState({
    show: false,
    id: false,
  });
  const [filters, setFilters] = useState({
    name: '',
    pageNumber: currentPage,
    pageSize: itemsPerPage,
    deleteUser: '',
    role: '',
    disableUser: '',
  });

  const navigate = useNavigate();

  const fetchData = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrlStg}/api/UserManagement/GetUserList?page=${currentPage}&limit=${itemsPerPage}&search=${campaignName}&deleteUser=${filters?.deleteUser}&role=${filters?.role}&disableUser=${filters?.disableUser}`,
      headers: {
        accept: 'text/plain',
      },
    };

    try {
      setIsLoading(true);
      const response = await getApi(config.url, config.headers);
      console.log('response fetch', response);
      setData(response.response);
      setTotalRecords(response.totalCountUser);
      setTotalPages(Math.ceil(response.totalCountUser / Number(itemsPerPage)));
      if (response?.response) {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    console.log('check filters', filters);
  }, [itemsPerPage, currentPage, filters]);

  // Campaign Status Starts From Here

  useEffect(() => {
    console.log('check currentPage', currentPage);
  }, [currentPage]);

  const handleMenuDropdown = () => {
    navigate(userManagement?.createUser);
  };

  const setTab = (tab) => {
    // ClearFilters(userTab['All Users'].name);
    setCurrentPage(1);
    setCurrentTab(tab);
  };

  const handleRowClick = (row) => {
    console.log('handleRowClick', row);
    setSelectedRow(row.index == selectedRow ? null : row.index);
  };

  const handleDeleteClick = (id) => {
    // Handle delete logic
    setIsDelete({
      show: true,
      id: id,
    });
  };

  const handleChangePasswordClick = () => {
    // Handle change password logic
    console.log('Change Password clicked for row:', selectedRow);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const [getShowEnabledDisabledModal, setShowEnabledDisabledModal] =
    useState(false);

  const [isEnabled, setIsEnabled] = useState({
    userStatus: false,
    userId: '',
  });
  const handleChangeEnableDisable = (is_enabled) => {
    setShowEnabledDisabledModal(true);
    setIsEnabled((prev) => ({
      ...prev,
      userStatus: is_enabled?.original?.userStatus,
      userId: is_enabled?.original?.userId,
    }));
  };

  const handleAction = async () => {
    const payload = [isEnabled?.userId];

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrlStg}/api/GoogleAdListing/Enabale-disable-user?status=${!isEnabled?.userStatus}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json-patch+json',
      },
      data: payload,
    };

    try {
      const response = await postApi(config.url, config.data, config.headers);

      if (response.data.success) {
        setShowEnabledDisabledModal(false);
        fetchData();
      }
    } catch (error) {
      console.error('Error while performing action:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
          <Link
            to={`${userManagement.userDetail}/${row.original.userId}`}
            // onClick={() => handleEdit(row.original.userId)}
          >
            {row.original.name}
          </Link>
        ),
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
        Cell: ({ row }) =>
          row.original.emailAddress ? (
            <span>{row.original.emailAddress}</span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Cell: ({ row }) => (
          <span>{[row.original.gender] == 'M' ? 'Male' : 'Female'}</span>
        ),
      },
      {
        Header: 'Role',
        accessor: 'roleId',
        Cell: ({ row }) =>
          row.original.roleId ? <span>{row.original.roleId}</span> : '-',
      },

      {
        Header: 'Platform Access',
        accessor: 'platformAccess',
        Cell: ({ row }) =>
          row.original.platformAccess ? (
            <span>
              {row.original.platformAccess.map((item, index) => {
                const capitalizedItem = capitalizeFirstLetter(item);
                return index === 0 ? capitalizedItem : `, ${capitalizedItem}`;
              })}
            </span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'Created On',
        accessor: 'createdAt',
        Cell: ({ row }) => (
          <span>
            {row.original.createdAt
              ? moment(row.original.createdAt).format('DD-MMM-YYYY HH:mm')
              : '-'}
          </span>
        ),
      },
      {
        Header: 'Last Log In On',
        accessor: 'lastLogIn',
        Cell: ({ row }) => (
          <span>
            {row.original.lastLogIn
              ? moment(row.original.lastLogIn).format('DD-MMM-YYYY HH:mm')
              : '-'}
          </span>
        ),
      },

      {
        Header: 'Status',
        accessor: 'userStatus',
        Cell: ({ row }) => (
          <div class='form-check form-switch'>
            <label class='switch'>
              <input
                type='checkbox'
                id='togBtn'
                checked={row?.original?.userStatus}
                onChange={() => handleChangeEnableDisable(row)}
              />
              <small class='slider round'>
                <span class='on'>Enable</span>
                <span class='off'>Disable</span>
              </small>
            </label>
          </div>
        ),
      },

      {
        Header: 'Edit',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div
            className='actions-container'
            onClick={() => handleRowClick(row)}
          >
            <Link
              to={
                row?.original?.userStatus &&
                `${userManagement?.updateUser}/${row.original.userId}`
              }
            >
              Edit
            </Link>
          </div>
        ),
      },
    ],
    [],
  );

  const handleEdit = (id) => {
    console.log('id', id);
    navigate(`${userManagement?.updateUser}/${id}`);
  };

  const clearSearch = (e) => {
    setFilters((preValue) => ({
      ...preValue,
      creativeName: '',
    }));
    setCampaignName('');
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    setFilters((prevValue) => ({
      ...prevValue,
      pageNumber: selectedPage.selected + 1,
    }));
  };
  const handleChange = (e) => {
    setCampaignName(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = () => {
    fetchData(campaignName);
  };

  const goToListingPage = () => {
    console.log('goToListingPage clicked');
    fetchData();
  };

  // for Delete
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = useCallback((selectedRowsIds) => {
    setSelectedRows(selectedRowsIds);
  }, []);

  const setActiveTab = (tab) => {
    setTab(tab);

    if (tab === 'All Users') {
      setFilters((prevValue) => ({
        ...prevValue,
        deleteUser: '',
        disableUser: '',
      }));
      // } else if (tab === userTab['Deleted Users']?.name) {
      //   setFilters((prevValue) => ({
      //     ...prevValue,
      //     deleteUser: '1',
      //     disableUser: '',
      //   }));
    } else if (tab === userTab.Disabled.name) {
      setFilters((prevValue) => ({
        ...prevValue,
        disableUser: '1',
        deleteUser: '',
      }));
    }
  };
  // End of code
  return (
    <>
      <div>
        {isLoading && <Loader />}
        {data ? (
          <>
            <main>
              <div className='main-container facebook-step-one'>
                <div className='container'>
                  <div className='fb-panel'>
                    <Sidebar />
                    <div className='right-side-box media-section-left'>
                      <NavHeading HeadingName={'User Management'} />
                      <div className='search-bar-cont'>
                        <div className='search'>
                          <div className='search-close'>
                            <input
                              type='text'
                              id='searchtextid'
                              placeholder='Search'
                              value={campaignName}
                              onChange={handleChange}
                              onKeyDown={handleKeyPress}
                            />
                            <button
                              className='close-btn'
                              id='ClearMediaSearchID'
                              onClick={clearSearch}
                            >
                              <i className='icon-close'></i>
                            </button>
                          </div>
                          <button
                            className='btn-icon btn-icon-primary'
                            id='searchstringid'
                            onClick={handleSearch}
                          >
                            <i className='icon-search'></i>
                          </button>
                        </div>
                      </div>

                      {/* <Header /> */}
                      <section className='side-menu-form d-flex'>
                        <article className='side-right-form-wrap'>
                          <HeaderTopBar
                            setActiveTab={setActiveTab}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            options={userTab}
                          />
                          <div className='card-primary'>
                            <div className='media-fillter-btns'>
                              <div className='fillter-btns d-flex justify-content-between'>
                                <div className='fillter-left d-flex align-items-center'>
                                  <div className='dropdown-select DpdrpDownSlct'>
                                    <button
                                      onClick={handleMenuDropdown}
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      <i className='icon-plus'></i> Create New
                                      User
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className='dataTables_length'
                              id='Facebookcampaigntable_length'
                            >
                              <label>
                                Show{' '}
                                <select
                                  name='Facebookcampaigntable_length'
                                  aria-controls='Facebookcampaigntable'
                                  value={itemsPerPage}
                                  onChange={(e) => [
                                    setItemsPerPage(Number(e.target.value)),
                                  ]}
                                >
                                  <option value='10'>10</option>
                                  <option value='25'>25</option>
                                  <option value='50'>50</option>
                                  <option value='100'>100</option>
                                </select>{' '}
                                entries
                              </label>
                            </div>

                            <ReactTable
                              columns={columns}
                              data={data}
                              totalCount={totalRecords}
                              showCheckbox={false}
                              onSelectionChange={handleSelectionChange}
                            />
                            {data && (
                              <div className='media-entries dataTables_paginate'>
                                <div className='show-entries'>
                                  {data.length > 0 && (
                                    <p>
                                      Showing{' '}
                                      {currentPage === 1
                                        ? 1
                                        : (currentPage - 1) * itemsPerPage +
                                          1}{' '}
                                      to{' '}
                                      {Number(currentPage - 1) *
                                        Number(itemsPerPage) +
                                        data.length}{' '}
                                      of {totalRecords} entries
                                    </p>
                                  )}
                                </div>
                                <div className='media-pagination'>
                                  {totalRecords > itemsPerPage && (
                                    <div className='media-pagination dataTables_paginate'>
                                      <br />
                                      <ReactPaginate
                                        breakLabel={'...'}
                                        marginPagesDisplayed={1}
                                        previousLabel={
                                          <button>Previous</button>
                                        }
                                        nextLabel={<button>Next</button>}
                                        pageCount={totalPages}
                                        onPageChange={handlePageChange}
                                        forcePage={currentPage - 1}
                                        renderOnZeroPageCount={null}
                                        pageRangeDisplayed={5}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </article>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : (
          <Sidebar />
        )}

        <DeleteUser
          show={isDelete.show}
          id={isDelete.id}
          handleClose={() =>
            setIsDelete((prev) => ({
              ...prev,
              show: false,
            }))
          }
          handleDelete={() =>
            setIsDelete((prev) => ({
              ...prev,
              show: false,
            }))
          }
          afterDelete={() => {
            setIsDelete((prev) => ({ ...prev, show: false }));
            goToListingPage();
          }}
        ></DeleteUser>
        <CommonEnableDisableModal
          show={getShowEnabledDisabledModal}
          handleClose={() => setShowEnabledDisabledModal(false)}
          copy={
            !isEnabled?.userStatus
              ? 'Do you want to Enable this User'
              : 'Do you want to Disable this User'
          }
          type={!isEnabled?.userStatus ? 'Enable' : 'Disable'}
          handleAction={handleAction}
        />
      </div>
    </>
  );
};

export default UserList;
