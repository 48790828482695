import {
  TAXONOMY_CAMPAIGN_NAME,
  TAXONOMY_CAMPAIGN_PRODUCT_NAME,
  TAXONOMY_CAMPAIGN_ADVERTISER_NAME,
  TAXONOMY_CAMPAIGN_SUBCATEGORY,
  TAXONOMY_CAMPAIGN_PRECISION_JOB_OR_RO_NUMBER,
  TAXONOMY_CAMPAIGN_GENDER,
  TAXONOMY_CAMPAIGN_AGE,
  TAXONOMY_CAMPAIGN_PHASE,
  TAXONOMY_CAMPAIGN_CHANNEL,
  TAXONOMY_CAMPAIGN_DSP_PUBLISHER,
  TAXONOMY_CAMPAIGN_CAMPAIGN_TYPE,
  TAXONOMY_CAMPAIGN_BUY_TYPE_RATE_TYPE,
  TAXONOMY_CAMPAIGN_CAMPAIGN_KPI,
  TAXONOMY_CAMPAIGN_OBJECTIVE,
  TAXONOMY_CAMPAIGN_AUDIENCE_TYPE,
  TAXONOMY_CAMPAIGN_FREE_FORM,
  TAXONOMY_CAMPAIGN_UNIQUE_ID,
  TAXONOMY_CAMPAIGN_START_DATE,
  TAXONOMY_CAMPAIGN_MATCH_TYPE,
  TAXONOMY_CAMPAIGN_SIZMMEK_TRACKING,
  TAXONOMY_CAMPAIGN_CLEAR_FORM,
} from './TaxonomyCreateCampaignActionTypes';

const initialState = {
  campaign_name: '',
  product_name: '',
  advertiser_name: null,
  subcategory: null,
  job_or_ro_number: '',
  gender: null,
  age: null,
  phase: null,
  channel: null,
  dsp_publisher: null,
  campaign_type: null,
  buy_type_rate_type: null,
  campaign_kpi: null,
  objective: null,
  audience_type: null,
  free_form: '',
  unique_id: '',
  start_date: '',
  match_type: null,
  sizmek_tracking: null,
};

export const TaxonomyCreateCampaign = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case TAXONOMY_CAMPAIGN_NAME: {
      const value = payload;
      return { ...state, campaign_name: value };
    }
    case TAXONOMY_CAMPAIGN_PRODUCT_NAME: {
      const value = payload;
      return { ...state, product_name: value };
    }
    case TAXONOMY_CAMPAIGN_ADVERTISER_NAME: {
      const value = payload;
      return { ...state, advertiser_name: value };
    }
    case TAXONOMY_CAMPAIGN_SUBCATEGORY: {
      const value = payload;
      return { ...state, subcategory: value };
    }
    case TAXONOMY_CAMPAIGN_PRECISION_JOB_OR_RO_NUMBER: {
      const value = payload;
      return { ...state, job_or_ro_number: value };
    }

    case TAXONOMY_CAMPAIGN_GENDER: {
      const value = payload;
      return { ...state, gender: value };
    }

    case TAXONOMY_CAMPAIGN_AGE: {
      const value = payload;
      return { ...state, age: value };
    }
    case TAXONOMY_CAMPAIGN_PHASE: {
      const value = payload;
      return { ...state, phase: value };
    }

    case TAXONOMY_CAMPAIGN_CHANNEL: {
      const value = payload;
      return { ...state, channel: value };
    }

    case TAXONOMY_CAMPAIGN_DSP_PUBLISHER: {
      const value = payload;
      return { ...state, dsp_publisher: value };
    }

    case TAXONOMY_CAMPAIGN_CAMPAIGN_TYPE: {
      const value = payload;
      return { ...state, campaign_type: value };
    }

    case TAXONOMY_CAMPAIGN_BUY_TYPE_RATE_TYPE: {
      const value = payload;
      return { ...state, buy_type_rate_type: value };
    }

    case TAXONOMY_CAMPAIGN_CAMPAIGN_KPI: {
      const value = payload;
      return { ...state, campaign_kpi: value };
    }

    case TAXONOMY_CAMPAIGN_OBJECTIVE: {
      const value = payload;
      return { ...state, objective: value };
    }

    case TAXONOMY_CAMPAIGN_AUDIENCE_TYPE: {
      const value = payload;
      return { ...state, audience_type: value };
    }

    case TAXONOMY_CAMPAIGN_FREE_FORM: {
      const value = payload;
      return { ...state, free_form: value };
    }

    case TAXONOMY_CAMPAIGN_UNIQUE_ID: {
      const value = payload;
      return { ...state, unique_id: value };
    }

    case TAXONOMY_CAMPAIGN_START_DATE: {
      const value = payload;
      return { ...state, start_date: value };
    }

    case TAXONOMY_CAMPAIGN_MATCH_TYPE: {
      const value = payload;
      return { ...state, match_type: value };
    }

    case TAXONOMY_CAMPAIGN_SIZMMEK_TRACKING: {
      const value = payload;
      return { ...state, sizmek_tracking: value };
    }

    case TAXONOMY_CAMPAIGN_CLEAR_FORM: {
      const value = initialState;
      return value;
    }

    default:
      return state;
  }
};
