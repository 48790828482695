import CardHeader from '../common/CardHeader';
import Card from '../common/Card';
import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';
import CommonSmallDescription from '../common/CommonSmallDescription';

const LocationAndLanguage = (props) => {
  return (
    <>
      <Card>
        <CardBody>
          <div className='action-btn-both action-btn-both-check row relative'>
            <div className='form-check-box'>
              <input
                className='form-check-input'
                type='checkbox'
                id='lang-checkbox'
                defaultValue='option1'
                onChange={props?.handleLangEnableDesable}
                checked={
                  props?.campaignLevelLocationLanguage == 1 ? true : false
                }
              />
              <label className='form-check-label' htmlFor='lang-checkbox'>
                Enable campaign level location and language targeting{' '}
              </label>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div className='card-lt-pane'>
            <h5>Location</h5>
            <CommonSmallDescription copy={`Select locations to target`} />
          </div>
        </CardHeader>
        <CardBody>
          <div className='card-form form-panel card-form-days dv-card-form-days'>
            <div className='row align-items-center'>
              <div className='action-btn-cont'>
                <div
                  className='action-btn-both action-btn-no-icon row'
                  name='location'
                >
                  <div className='col-12 d-flex px-0'>
                    <div className='form-check-box'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='location'
                        id='location'
                        defaultChecked
                        value='india'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='flexRadioDefault2Nolimit'
                      >
                        India
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div className='card-lt-pane'>
            <h5>Languages</h5>
            <CommonSmallDescription
              copy={`Select the languages your customers speak.`}
            />
          </div>
        </CardHeader>
        <CardBody>
          <ReactSelect
            onChange={props?.handleLanguageSelect}
            isMulti={true}
            options={props?.languageList}
            closeMenuOnSelect={true}
            value={[
              {
                label: 'English',
                value: 1,
              },
            ]}
            isDisabled={props?.getCurrentPageStatus}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default LocationAndLanguage;
