// import './App.css';
import '../../assets/css/style.css';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import SideTreeMenu from '../../components/common/SideTreeMenu';
// import FormHeader from "../../components/common/FormHeader";
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import InfoText from '../../components/common/InfoText';
import AdvancedCardHeader from '../../components/common/AdvancedCardHeader';
import AddSection from '../../components/common/AddSection';
import CurrencyInputField from '../../components/common/CurrencyInputField';
import Button from '../../components/common/Button';
import DateSelectField from '../../components/common/DateSelectField';
import noCreativesAssigned from '../../assets/images/creative.png';
import creative from '../../assets/images/creative.png';
import slidePlaceHolder from '../../assets/images/media-lib1.jpg';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { Fragment, useEffect, useState } from 'react';
import Footer from '../../components/common/Footer';
// import MultipleSelect from "../../components/common/MultipleSelect";
// import DaysInputField from "../../components/common/DaysInputField";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
// import moment from "moment/moment";
import TextareaAutosize from 'react-textarea-autosize';
import ReactSelect from '../../components/common/ReactSelect';

const creativeArr = [];
function RtbAudio() {
  const initialvalues = {
    // code
  };
  const [RtbAudioData, setRtbAudioData] = useState(initialvalues);
  const [formErrors, setFormErrors] = useState({});
  const [date, setdate] = useState('');
  const [date1, setdate1] = useState('');
  const [automateBidding, setAutomateBidding] = useState('');
  const [fixedBid, setFixedBid] = useState('');
  const [lineItemName, setLineItemName] = useState('');
  const [error, setError] = useState(''); // Set your initial error message

  const handleLineItemNameChange = (event) => {
    const value = event.target.value;
    setLineItemName(value);

    // Check if the value is valid (you can customize this validation)
    if (value.trim() === '') {
      setError('Line item name is required');
    } else {
      setError('');
    }
  };

  Array.prototype.remove = function (value) {
    this.splice(this.indexOf(value), 1);
  };

  const onChangeHandler = (e) => {};

  const handleSubmit = (e) => {
    setFormErrors(validate());
  };
  const validate = (formData) => {
    const errors = {};
    // code
    return errors;
  };
  useEffect(() => {
    console.log(RtbAudioData, 'data');
    console.log(formErrors);
  });
  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box active padding-bottom-extra'>
            {/* <Header /> */}
            {/* header starts here */}

            <header>
              <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
                <div className='top-left-bar'>
                  <ul className='nav-bedcrumb d-flex'>
                    <li>
                      <button
                        className='btn-icon btn-icon-primary btn-bedcrumb '
                        type='button'
                      >
                        <i className='icon-camopaign'></i> New Campaign
                      </button>
                    </li>
                    <li>
                      <button
                        className='btn-icon btn-icon-primary btn-bedcrumb '
                        type='button'
                      >
                        <i className='icon-ad-set'></i> New insertion order
                      </button>
                    </li>
                    <li>
                      <button
                        className='btn-icon btn-icon-primary btn-bedcrumb btn-bedcrumb-act'
                        type='button'
                      >
                        <i className='icon-ad'></i> New Line item
                      </button>
                    </li>
                  </ul>
                </div>
                <div className='top-right-bar'>
                  <div className='btn-box'>
                    <button
                      type='button'
                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                    >
                      Save as draft
                    </button>
                    <button type='button' className='primary-btn close-btn'>
                      <i className='icon-close'></i>
                    </button>
                  </div>
                </div>
              </div>
            </header>
            {/* header ends here */}
            <section className='side-menu-form d-flex'>
              {/* <SideTreeMenu /> */}

              {/* sidetreemenu starts */}

              <article className='side-tree-menu'>
                {/* <div className="status-cont-top">
                  <div className="status-lt">
                    <span>Status:</span>
                    <span><strong>Published</strong> (Enabled)</span>
                  </div>
                  <div className="status-btn">
                    <button type="button" className="primary-btn close-btn" href="javascript:void(0)">
                      <i className="icon-right-arrow"></i>
                    </button>
                  </div>
                </div> */}
                <div className='nav-left scroll-vertical'>
                  <nav className='navbar'>
                    <ul className='navbar-nav'>
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          aria-current='page'
                          href='javascript:void(0)'
                        >
                          <i className='icon-camopaign'></i>
                          <span>New Campaign</span>
                        </a>
                        <div className='dropdown'>
                          <button
                            type='button'
                            className='primary-btn close-btn dot-btn-primary'
                            id='dropdownTable'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='icon-meni-hori'></i>
                          </button>
                          <ul
                            className='dropdown-menu dropdown-menu-lg-end'
                            aria-labelledby='dropdownTable'
                          >
                            <li>
                              <a className='dropdown-item' href='#'>
                                Action
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Another action
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className='nav-item'>
                        <a href='javascript:void(0)' className='nav-link '>
                          <i className='icon-ad-set'></i>
                          <span> New insertion order</span>
                        </a>
                        <div className='dropdown'>
                          <button
                            type='button'
                            className='primary-btn close-btn dot-btn-primary'
                            id='dropdownMenuButton2'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='icon-meni-hori'></i>
                          </button>
                          <ul
                            className='dropdown-menu dropdown-menu-lg-end'
                            aria-labelledby='dropdownMenuButton2'
                          >
                            <li>
                              <a className='dropdown-item' href='#'>
                                Duplicate
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Copy{' '}
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                        <ul>
                          <li>
                            <a href='#' className='nav-link active'>
                              <i className='icon-ad'></i>
                              <span>New Line item</span>
                            </a>
                          </li>
                          {/* <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                          <i className="icon-ad-set"></i>
                          <span> New awareness ad set 2</span>
                        </a>
                        <ul>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i>
                              <span>New awareness ad 1</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </article>

              {/* sidetree menu ends */}
              <article className='side-right-form-wrap'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        {/* 
                                                {/* <Card>
                                                    <CardHeader>
                                                        <h5 className="d-flex align-items-center">
                                                            insertion order type
                                                            {/* <span className="label-information tooltips tool-bottom" tooltip="Cascading Style Sheets"
                                                                tooltip-position="top"> <i className="icon-info"></i></span> */}
                        {/* </h5>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont pt-3 pb-3">
                                                                    <div
                                                                        className="action-btn-both row"
                                                                        onChange={(e) => {
                                                                            onChangeHandler(e);
                                                                        }}
                                                                    >
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="1"
                                                                                id="flexRadioDefault1"
                                                                                defaultChecked
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault1"
                                                                            >
                                                                                <i className="icon-display"></i>
                                                                                Display
                                                                                {/* <i className="icon-info"></i> */}
                        {/* </label> */}
                        {/* </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="2"
                                                                                id="flexRadioDefault2"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault2"
                                                                            >
                                                                                <i className="icon-video-one"></i>Video
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="2"
                                                                                id="flexRadioDefault21"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault21"
                                                                            >
                                                                                <i className="icon-traffic"></i>Audio
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="3"
                                                                                id="flexRadioDefault6"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault6"
                                                                            >
                                                                                <i className="icon-ytube-part"></i>{" "}
                                                                                YouTube & partners video
                                                                            </label>
                                                                        </div> 
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="3"
                                                                                id="flexRadioDefault61"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault61"
                                                                            >
                                                                                <i className="icon-sales"></i> YouTube &
                                                                                partners audio
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>  */}
                        <Card>
                          <div class='card-primary'>
                            <div class='card-header-top d-flex'>
                              <div class='card-lt-pane'>
                                <h5>Line item name</h5>
                              </div>
                              <div class='me-auto card-switch-rigth'>
                                <div class='form-panel select-with-side-label'>
                                  <div class='form-item'></div>
                                </div>
                              </div>
                            </div>
                            <div class='card-body'>
                              <div class='card-form form-panel'>
                                <div class='row align-items-center'>
                                  <div class='form-item col-12'>
                                    <div class='label-icon'>
                                      {/* <label>Line item name</label> */}
                                    </div>
                                    {error && (
                                      <span
                                        className='error-message'
                                        style={{ color: 'red' }}
                                      >
                                        {error}
                                      </span>
                                    )}
                                    <div class='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Enter line item name'
                                        pattern='^[A-Za-z0-9\s]+$'
                                        value={lineItemName}
                                        onChange={handleLineItemNameChange}
                                        onBlur={handleLineItemNameChange}
                                      />
                                      {/* {error && (
                                        <span
                                          className="error-message"
                                          style={{ color: "red" }}
                                        >
                                        {error}
                                        </span>
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                        {/* Media type Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Media type</h5>
                                <h6>Audio</h6>
                                <p>Run Audio ads across YouTube & partners</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Media type Ends Here */}

                        {/* Type Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Type</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Brand awareness and reach
                                            <p>
                                              Reach a broad audience and build
                                              awareness
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Type Ends Here */}

                        {/* Ad format Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Ad format</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Audio Ads
                                            <p>
                                              Share your message with instream
                                              audio ads
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Ad format Ends Here */}

                        <Card>
                          <div className='card-header-top'>
                            <div className='card-lt-pane'>
                              <h5>Note</h5>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Note</label>
                                  </div>
                                  <div className='input-sizer stacked'>
                                    {/* <textarea
                                                                            // onInput="this.parentNode.dataset.value = this.value"
                                                                            rows={1}
                                                                            placeholder="Type Reason"
                                                                        /> */}
                                    <TextareaAutosize />
                                  </div>

                                  {/* <span className="error" id="emailError">
                                                                            Please enter valid email id
                                                                        </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </article>
                    </div>
                  </div>
                  {/* view starts */}
                  <div
                    className='tab-pane fade'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        <div className='card-primary'>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Campaign name</h5>
                              <p>New Awareness Campaign</p>
                              <p>ID: 23857805967620728</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Special ad categories</h5>
                              <p>No categories declared</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Buying type</h5>
                              <p>Awareness</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Ad sets</h5>
                              <p className='add-data'>Please add: Ad sets</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Format</h5>
                              <p>Image and Video</p>
                            </div>
                            <div className='card-rt-pane d-flex me-auto align-items-center'>
                              <figure className='prev-icon'>
                                <img
                                  src={atdplaceholder}
                                  alt='Alternative Text'
                                />
                              </figure>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* view ends */}
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  ></div>
                </div>
              </article>
            </section>
          </div>
          <Footer
            prevPath='/dv-360-insertion-order'
            handleSubmit={handleSubmit}
            nextBtnlabel='Submit'
          ></Footer>
        </div>
      </div>
    </div>
  );
}

export default RtbAudio;
