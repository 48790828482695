import '../../assets/css/style.css';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import SideTreeMenu from '../../components/common/SideTreeMenu';
import FormHeader from '../../components/common/FormHeader';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import InfoText from '../../components/common/InfoText';
import AdvancedCardHeader from '../../components/common/AdvancedCardHeader';
// import AddSection from "../../components/common/AddSection";
// import CurrencyInputField from "../../components/common/CurrencyInputField";
import Button from '../../components/common/Button';
// import DateSelectField from "../../components/common/DateSelectField";
// import creative from "../../assets/images/creative.png";
// import slideshow from "../../assets/images/slideshow.jpg";
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { useState } from 'react';
import Footer from '../../components/common/Footer';
// import MultipleSelect from "../../components/common/MultipleSelect";
// import DaysInputField from "../../components/common/DaysInputField";
import noCreativesAssigned from '../../assets/images/creative.png';
import slidePlaceHolder from '../../assets/images/slideshow.jpg';
import accorPlaceHolder from '../../assets/images/awareness.png';
import searchImg from '../../assets/images/search.svg';
// import performanceMax from "../../assets/images/search.svg";
import displayImg from '../../assets/images/display.svg';
import shoppingImg from '../../assets/images/shopping.svg';
import videoImg from '../../assets/images/video.svg';
import discoveryImg from '../../assets/images/discovery.svg';
import ReactSelect from '../../components/common/ReactSelect';
import TextareaAutosize from 'react-textarea-autosize';
import Accordion from 'react-bootstrap/Accordion';
import logoSet from '../../assets/images/logo-small.svg';
import Modal from 'react-bootstrap/Modal';

const creativeArr = [];
function Common() {
  const [createCampignData, setCreateCampignData] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  Array.prototype.remove = function (value) {
    this.splice(this.indexOf(value), 1);
  };

  const onChangeHandler = (e) => {
    // console.log(e.target.type)
    if (e.target.type == 'checkbox') {
      if (e.target.checked) {
        creativeArr.push(e.target.value);
        // console.log(creativeArr,"ooooooooooo")
      } else {
        let Item = creativeArr.find((item) => item == e.target.value);
        creativeArr.remove(Item);
        // console.log(creativeArr,"ooooooooooo")
      }

      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: creativeArr };
      });
    } else {
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };
  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box active padding-bottom-extra'>
            <Header />
            <section className='side-menu-form d-flex'>
              <SideTreeMenu />
              <article className='side-right-form-wrap'>
                <FormHeader />
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        {/* <Card>
                                                    <CardHeader>
                                                        <h5 className="d-flex align-items-center">
                                                            Overall campaign goal
                                                            <span className="label-information tooltips tool-bottom" tooltip="Cascading Style Sheets"
                                                                tooltip-position="top"> <i className="icon-info"></i></span>
                                                        </h5>
                                                        <p>Raise awareness of my brand or product</p>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont pt-3 pb-3">
                                                                    <div className="action-btn-both row">
                                                                        <div className="form-check-box col-12">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                id="flexRadioDefault1" defaultChecked />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                <i className="icon-awareness"></i>
                                                                                Raise awareness of my brand or product
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                id="flexRadioDefault2" />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                <i className="icon-traffic"></i>Drive online action or visits
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                id="flexRadioDefault6" />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                                                <i className="icon-sales"></i> Drive offline or in-store sales
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                id="flexRadioDefault3" />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                                <i className="icon-engagement"></i> Drive app installs or engagements
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardHeader><h5 className="d-flex align-items-center">Frequency cap</h5></CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel card-form-days dv-card-form-days">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont">
                                                                    <h6 className="act-title-primary">Exposure Frequency</h6>
                                                                    <div className="action-btn-both action-btn-no-icon row">
                                                                        <div className="col-12 d-flex px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                    id="flexRadioDefault2" checked />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                    No limit
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 d-flex px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                    id="flexRadioDefault3" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                                    Limit frequency to</label>
                                                                            </div>
                                                                            <div className="form-item d-flex align-items-center me-3">
                                                                                <input type="email" id="email" placeholder="Enter a value" />
                                                                                <label>exposures per </label>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                            <div className="form-item d-flex align-items-center">
                                                                                <SimpleSelectBox></SimpleSelectBox>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="action-btn-cont">
                                                                    <h6 className="act-title-primary">View Frequency</h6>
                                                                    <div className="action-btn-both action-btn-no-icon row">

                                                                        <div className="col-12 d-flex px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                    id="flexRadioDefault2" checked />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                    No limit
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 d-flex px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                    id="flexRadioDefault3" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                                    Limit frequency to</label>
                                                                            </div>
                                                                            <div className="form-item d-flex align-items-center me-3">
                                                                                <input type="email" id="email" placeholder="Enter a value" />
                                                                                <label>exposures per </label>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                            <div className="form-item d-flex align-items-center">
                                                                                <div className="select-box">
                                                                                    <SimpleSelectBox></SimpleSelectBox>
                                                                                </div>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                    <CardBody>
                                                        <InfoText></InfoText>
                                                    </CardBody>
                                                    <AdvancedCardHeader>
                                                        <div className='card-lt-pane'>
                                                            <h6>Public Inventory</h6>

                                                            <div className="dv-header-icon">
                                                                <div className="dv-icon-outer d-flex">
                                                                    <div className="dv-icon">
                                                                        <i className="icon-check-mark"></i>
                                                                    </div>
                                                                    <div className="dv-data-icon">
                                                                        <p>Target the following regions</p>
                                                                        <div className="dv-inner-icon d-flex">
                                                                            <p className="dv-text-g">India (Country)</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="dv-header-icon">
                                                                <div className="dv-icon-outer d-flex">
                                                                    <div className="dv-icon">
                                                                        <i className="icon-cut"></i>
                                                                    </div>
                                                                    <div className="dv-data-icon">
                                                                        <p>Exclude the following regions</p>
                                                                        <div className="dv-inner-icon d-flex">
                                                                            <span className="dv-icon">
                                                                                <i className="icon-lock"></i>
                                                                            </span>
                                                                            <p className="dv-text-g">Afghanistan (Country)</p>
                                                                        </div>
                                                                        <div className="dv-inner-icon d-flex">
                                                                            <span className="dv-icon">
                                                                                <i className="icon-lock"></i>
                                                                            </span>
                                                                            <p className="dv-text-g">Derogatory</p>
                                                                        </div>
                                                                        <a className="url-txt" href="#">show 265 more</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AdvancedCardHeader>

                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <div className="card-lt-pane">
                                                            <h5>Budget</h5>
                                                            <p>Budget and pacing depend on both insertion order and line item settings.</p>
                                                            <h6 className="d-flex align-items-center">
                                                                Budget
                                                                <span className="label-information tooltips" tooltip="
                                  <ul>
                                    <li>Supported image format: BMP, DIB, HEIC, HEIF, IFF, JFIF, JP2, JPE, JPEG, JPG, PNG, PSD, TIF, TIFF, WBMP, WEBP, XBM</li>
                                    <li>Supported video format: 3g2, 3gp, 3gpp, asf, avi, dat, divx, dv, f4v, flv, gif, m2ts, m4v, mkv, mod, mov, mp4, mpe, mpeg, mpeg4, mpg, mts, nsv, ogm, ogv, qt, tod, ts, vob, and wmv</li>
                                  </ul>
                                  " tooltip-position="top"> <i className="icon-info"></i><span></span></span>
                                                            </h6>
                                                            <p>Specify this insertion order's flight dates and budget segments</p>
                                                        </div>
                                                        <div className="me-auto card-switch-rigth">

                                                            <div className="form-panel select-with-side-label">
                                                                <div className="form-item">
                                                                    <div className="label-icon">
                                                                        <label>Budget Type</label>
                                                                        <span className="label-information tooltips" tooltip="Cascading Style Sheets" tooltip-position="top"> <i className="icon-info"></i><span>Cascading Style Sheets</span></span>
                                                                    </div>
                                                                    <SimpleSelectBox></SimpleSelectBox>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        {[...Array(2)].map((item, index) => {
                                                            return (<Fragment key={index}>
                                                                <div className="card-form form-panel form-panel-group-add" key={index}>
                                                                    <div className="row align-items-center">

                                                                        <div className="form-item input-group-label col-box-3 col-box-3-inr">
                                                                            <div className="label-icon">
                                                                                <label>Budget<sup>*</sup></label>
                                                                            </div>
                                                                            <div className="input-group">
                                                                                <span className="input-group-text rs-text">
                                                                                    <i className="icon-rupee"></i>
                                                                                </span>
                                                                                <input type="email" id="email" placeholder="X.XX" />
                                                                                <span className="input-group-text inr-text rs-text">INR</span>
                                                                            </div>
                                                                            <span className="error" id="emailError">Please enter valid email id</span>
                                                                        </div>

                                                                        <div className="form-item input-group-label col-box-3">
                                                                            <div className="label-icon">
                                                                                <label>Start date</label>
                                                                            </div>
                                                                            <div className="input-group input-width-less">
                                                                                <span className="input-group-text rs-text">
                                                                                    <i className="icon-calendar"></i>
                                                                                </span>
                                                                                <input type="text" id="datepicker" placeholder="DD/MM/YYYY" />
                                                                            </div>
                                                                            <span className="error" id="emailError">Please enter valid email id</span>
                                                                        </div>

                                                                        <div className="form-item input-group-label col-box-3">
                                                                            <div className="label-icon">
                                                                                <label>End date</label>
                                                                            </div>
                                                                            <div className="input-group input-width-less">
                                                                                <span className="input-group-text rs-text">
                                                                                    <i className="icon-calendar"></i>
                                                                                </span>
                                                                                <input type="text" id="datepicker1" placeholder="DD/MM/YYYY" />
                                                                            </div>
                                                                            <span className="error" id="emailError">Please enter valid email id</span>
                                                                        </div>


                                                                        <div className="form-item">
                                                                            <div className="label-icon">
                                                                                <label>Description</label>
                                                                            </div>
                                                                            <div className="input-sizer stacked">
                                                                                <textarea oninput="this.parentNode.dataset.value = this.value" rows="1" placeholder="Type Description"></textarea>
                                                                            </div>
                                                                            <span className="error" id="emailError">Please enter valid email id</span>
                                                                        </div>

                                                                        <div className="media-btn">
                                                                            <button type="button" className="btn-icon btn-icon-primary btn-bedcrumb-act">
                                                                                <i className="icon-delete"></i>Remove
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Fragment>)
                                                        })}
                                                        <AddSection></AddSection>
                                                    </CardBody>
                                                    <CardHeader>
                                                        <h6>Pacing</h6>
                                                        <p>How do you want to spend the flight budget?</p>
                                                    </CardHeader>
                                                    <div className="card-form form-panel form-panel-group-add form-panel-no-border">
                                                        <div className="row align-items-center">

                                                            <div className="form-item col-box-3">
                                                                <div className="label-icon">
                                                                    <label>Select</label>
                                                                </div>
                                                                <SimpleSelectBox></SimpleSelectBox>
                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                            </div>

                                                            <div className="form-item col-box-3">
                                                                <div className="label-icon">
                                                                    <label>Select</label>
                                                                </div>
                                                                <SimpleSelectBox></SimpleSelectBox>
                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                            </div>

                                                            <CurrencyInputField></CurrencyInputField>

                                                        </div>
                                                    </div>
                                                    <CardHeader>
                                                        <h6>Optimization</h6>
                                                        <p>How would you like to optimize?</p>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont">
                                                                    <div className="action-btn-both action-btn-no-icon row">

                                                                        <div className="col-12 px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault50" checked="" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault50">
                                                                                    Automate bid &amp; budget at insertion order level
                                                                                    <p>Allow system to automatically adjust bids and shift budget to
                                                                                        better-performing line items.</p>
                                                                                </label>
                                                                            </div>
                                                                            <div className="dv-from-check-field">
                                                                                <div className="form-item">
                                                                                    <div className="label-icon">
                                                                                        <label>Select</label>
                                                                                    </div>
                                                                                    <SimpleSelectBox></SimpleSelectBox>
                                                                                    <span className="error" id="emailError">Please enter valid email id</span>
                                                                                </div>
                                                                                <p className="about-info-text">while prioritizing spending my full budget
                                                                                    (recommended)</p>


                                                                                <div className="d-flex dv-check-box">

                                                                                    <div className="action-btn-both action-btn-both-check row">
                                                                                        <div className="form-check-box">
                                                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox7" value="option1" />
                                                                                            <label className="form-check-labe2" htmlFor="inlineCheckbox7">
                                                                                                Do not exceed average CPM of
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <CurrencyInputField></CurrencyInputField>

                                                                                </div>

                                                                                <div className="dv-check-box">

                                                                                    <div className="action-btn-both action-btn-both-check row">
                                                                                        <div className="form-check-box">
                                                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox8" value="option1" />
                                                                                            <label className="form-check-labe2" htmlFor="inlineCheckbox8">
                                                                                                Prioritize deals over open auction inventory
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault51" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault51">
                                                                                    Control bid and budget at the line item level

                                                                                </label>
                                                                            </div>

                                                                            <div className="dv-from-check-field">


                                                                                <div className="dv-check-box">

                                                                                    <div className="action-btn-both action-btn-both-check row">
                                                                                        <div className="form-check-box">
                                                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox8" value="option1" />
                                                                                            <label className="form-check-labe2" htmlFor="inlineCheckbox8">
                                                                                                Prioritize deals over open auction inventory
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                    <CardHeader>
                                                        <h6 className="d-flex align-items-center">Frequency cap</h6>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel card-form-days dv-card-form-days">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont">
                                                                    <div className="action-btn-both action-btn-no-icon row">

                                                                        <div className="col-12 d-flex px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked="" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                    No limit
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 d-flex px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                                    Limit frequency to</label>
                                                                            </div>
                                                                            <div className="form-item d-flex align-items-center me-3">
                                                                                <input type="email" id="email" placeholder="Enter a value" />
                                                                                <label>exposures per </label>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                            <div className="form-item d-flex align-items-center">
                                                                                <SimpleSelectBox></SimpleSelectBox>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                    <CardBody>
                                                        <InfoText></InfoText>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <div className="card-lt-pane">
                                                            <h5>Partner costs</h5>
                                                            <h6 className="d-flex align-items-center">
                                                                CPM Fees
                                                                <span className="label-information tooltips" tooltip="Cascading Style Sheets" tooltip-position="top"> <i className="icon-info"></i><span>Cascading Style Sheets</span></span>
                                                            </h6>
                                                        </div>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        {[...Array(2)].map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className="card-form form-panel form-panel-group-add" key={index}>
                                                                        <h6>CPM Fee 1</h6>
                                                                        <div className="remove-box-group d-flex">

                                                                            <div className="row align-items-center">

                                                                                <CurrencyInputField></CurrencyInputField>

                                                                                <div className="form-item col-box-3">
                                                                                    <div className="label-icon">
                                                                                        <label>Type</label>
                                                                                    </div>

                                                                                    <SimpleSelectBox></SimpleSelectBox>
                                                                                    <span className="error" id="emailError">Enter the link you want to show on your
                                                                                        ad</span>
                                                                                </div>

                                                                                <div className="col-box-3 col-wid-12">
                                                                                    <div className="dv-check-box-group mb-3">

                                                                                        <div className="action-btn-both action-btn-both-check row">
                                                                                            <div className="form-check-box">
                                                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox88" value="option1" />
                                                                                                <label className="form-check-labe2" htmlFor="inlineCheckbox88">Invoiced</label>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div className="media-btn">
                                                                                <Button></Button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </CardBody>
                                                    <AddSection></AddSection>
                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <h5>Flight dates</h5>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont">
                                                                    <div className="action-btn-both action-btn-no-icon row">

                                                                        <div className="col-12 px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault50" checked="" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault50">
                                                                                    Use same dates as insertion order
                                                                                    <p>Jul 18, 2023 - Aug 18, 2023</p>
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 px-0">
                                                                            <div className="form-check-box">
                                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault51" />
                                                                                <label className="form-check-label" htmlFor="flexRadioDefault51">
                                                                                    Custom dates

                                                                                </label>
                                                                            </div>

                                                                            <div className="dv-from-check-field">


                                                                                <div className="row align-items-center">
                                                                                    <div className="form-item input-group-label col-6">
                                                                                        <DateSelectField></DateSelectField>
                                                                                    </div>
                                                                                    <div className="form-item input-group-label col-6">
                                                                                        <DateSelectField></DateSelectField>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <h5>Flight dates</h5>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="form-item input-group-label col-6">
                                                                    <DateSelectField></DateSelectField>
                                                                </div>
                                                                <div className="form-item input-group-label col-6">
                                                                    <DateSelectField></DateSelectField>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <div className="card-lt-pane">
                                                            <h5>Additional details</h5>
                                                            <p>Add more details to improve your recommendations.</p>
                                                            <h6>Landing page URLs</h6>
                                                        </div>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        {[...Array(2)].map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className="card-form form-panel form-panel-group-add" key={index}>
                                                                        <div className="remove-box-group d-flex">
                                                                            <div className="row align-items-center">
                                                                                <div className="form-item col-box-3">
                                                                                    <div className="label-icon">
                                                                                        <label>Landing page URLs</label>
                                                                                    </div>
                                                                                    <div className="input-group">
                                                                                        <input type="email" id="email" placeholder="Type URL" />
                                                                                    </div>
                                                                                    <span className="error" id="emailError">Please enter valid email id</span>
                                                                                </div>

                                                                            </div>

                                                                            <div className="media-btn">
                                                                                <Button></Button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })}
                                                        <AddSection></AddSection>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <div className="card-lt-pane">
                                                            <h5>Creatives</h5>
                                                            <p>Advanced ad serving rate: ₹0.00 CPM</p>
                                                        </div>
                                                        <div className="me-auto card-switch-rigth">

                                                            <div className="form-panel select-with-side-label">
                                                                <div className="form-item">
                                                                    <div className="label-icon">
                                                                        <label>Creative optimization</label>
                                                                        <span className="label-information tooltips" tooltip="Cascading Style Sheets" tooltip-position="top"> <i className="icon-info"></i><span>Cascading Style Sheets</span></span>
                                                                    </div>
                                                                    <SimpleSelectBox></SimpleSelectBox>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        <div className="crt-box-data">
                                                            <div className="crt-img-cont">
                                                                <figure className="crt-img">
                                                                    <img src={creative} />
                                                                </figure>
                                                                <div className="crt-text">
                                                                    <p>No creatives assigned</p>
                                                                </div>
                                                            </div>


                                                            <div className="media-btn">
                                                                <Button></Button>
                                                            </div>

                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardHeader>
                                                        <h5>Product feed</h5>
                                                        <p>Define who you want to see your ads.</p>
                                                        <div className="action-btn-both action-btn-both-check row">
                                                            <div className="form-check-box">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Use a Google Merchant Center
                                                                    feed to show products with your ads</label>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <h6 className="card-header-title">Merchant Center account</h6>
                                                        <div className="search-bar-cont search-bar-border">
                                                            <div className="search">
                                                                <div className="search-close">
                                                                    <input type="text" placeholder="Search Saved Audience" />
                                                                    <button className="close-btn">
                                                                        <i className="icon-close"></i>
                                                                    </button>
                                                                </div>
                                                                <button className="btn-icon btn-icon-primary">
                                                                    <i className="icon-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <CardHeader>
                                                            <h6>Related videos</h6>
                                                            <p>Add videos related to your video ads to increase engagement</p>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="slideshow-box-group-rept">
                                                                {[...Array(2)].map((item, index) => {
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <div className="slideshow-box d-flex" key={index}>
                                                                                <div className="slideshow-imgbox d-flex align-items-center">
                                                                                    <img src={slideshow} alt="" />
                                                                                </div>
                                                                                <div className="slideshow-cont">
                                                                                    <div className="slideshow-cont-top">
                                                                                        <h3 className="tag-link">
                                                                                            <a href="javascript:void(0)" className="url-txt"> Protect Your UAN With
                                                                                                CENTURO</a>
                                                                                        </h3>
                                                                                        <p>
                                                                                            by Michelle Phan 2059626 views
                                                                                            An easy fun way to mattify your nail polish with just steam! Who else is
                                                                                            going
                                                                                            to try this out? Make sure your nails are wet, either nail polish or top
                                                                                            coat.
                                                                                            Quickly …
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <button className="close-slideshow">
                                                                                    <i className="icon-close"></i>
                                                                                </button>
                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                })}


                                                                <div className="media-btn">
                                                                    <div className="file-error">
                                                                        <div className="btn-icon btn-icon-primary btn-bedcrumb-act btn-file">
                                                                            <input type="file" id="imgFileIDVi" multiple="" />
                                                                            <label htmlFor="imgFileIDVi"> Add Video</label>
                                                                        </div>
                                                                        <span className="error" id="emailError">Please Select videos</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardHeader>
                                                        <h5>Call to action (Optional)</h5>
                                                        <h6>Call to action</h6>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="form-item col-12">
                                                                    <div className="label-icon">
                                                                        <label>Call to action</label>
                                                                    </div>
                                                                    <div className="input-group">
                                                                        <input type="email" id="email" placeholder="Type call to action" />
                                                                    </div>
                                                                    <span className="error" id="emailError">Please enter valid email id</span>
                                                                    <small className="select-time">0 / 10</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <AdvancedCardHeader>
                                                        <div className="card-lt-pane">
                                                            <h5>Conversion tracking</h5>
                                                            <h6 className="d-flex align-items-center">
                                                                Select the activity or conversion that represents a successful conversion
                                                            </h6>
                                                        </div>
                                                    </AdvancedCardHeader>
                                                    <CardBody>
                                                        {[...Array(1)].map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className="card-form form-panel form-panel-group-add" >
                                                                        <div className="row">
                                                                            <div className="card-header-top card-header-group col-6">
                                                                                <div className="card-lt-pane">
                                                                                    <h6>Floodlight activity</h6>
                                                                                    <p className="d-flex align-items-center">
                                                                                        PC~_TT~AS_TD~Click-To-Retailer_PP~Product…
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-header-top card-header-group col-6">
                                                                                <div className="card-lt-pane">
                                                                                    <h6>Floodlight activity ID</h6>
                                                                                    <p className="d-flex align-items-center">
                                                                                        11358800
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row align-items-center">

                                                                            <div className="form-item input-group-label input-group-label-end-box col-box-3">
                                                                                <div className="label-icon">
                                                                                    <label>Post-click window</label>
                                                                                </div>
                                                                                <div className="input-group">
                                                                                    <input type="email" id="email" placeholder="X.XX" />
                                                                                    <span className="input-group-text inr-text rs-text">Days</span>
                                                                                </div>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>
                                                                            <div className="form-item input-group-label input-group-label-end-box col-box-3">
                                                                                <div className="label-icon">
                                                                                    <label>Post-click window</label>
                                                                                </div>
                                                                                <div className="input-group">
                                                                                    <input type="email" id="email" placeholder="X.XX" />
                                                                                    <span className="input-group-text inr-text rs-text">Days</span>
                                                                                </div>
                                                                                <span className="error" id="emailError">Please enter valid email id</span>
                                                                            </div>


                                                                            <div className="media-btn">
                                                                                <button type="button" className="btn-icon btn-icon-primary btn-bedcrumb-act">
                                                                                    <i className="icon-delete"></i>Remove
                                                                                </button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })}

                                                        <AddSection></AddSection>
                                                    </CardBody>
                                                </Card> */}

                        {/* create compaign */}

                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Overall campaign goal
                              <span
                                className='label-information tooltips tool-bottom'
                                tooltip='Cascading Style Sheets'
                                tooltip-position='top'
                              >
                                {' '}
                                {/* <i className='icon-info'></i> */}
                              </span>
                            </h5>
                            <p>Raise awareness of my brand or product</p>
                            <button
                              type='button'
                              className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              onClick={handleShow}
                            >
                              Preview
                            </button>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont pt-3 pb-3'>
                                  <div
                                    className='action-btn-both row'
                                    onChange={(e) => {
                                      onChangeHandler(e);
                                    }}
                                  >
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='1'
                                        id='flexRadioDefault1'
                                        defaultChecked
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault1'
                                      >
                                        <i className='icon-awareness'></i>
                                        Raise awareness of my brand or product
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='2'
                                        id='flexRadioDefault2'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault2'
                                      >
                                        <i className='icon-traffic'></i>Drive
                                        online action or visits
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='3'
                                        id='flexRadioDefault6'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault6'
                                      >
                                        <i className='icon-sales'></i> Drive
                                        offline or in-store sales
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='4'
                                        id='flexRadioDefault3'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault3'
                                      >
                                        <i className='icon-engagement'></i>{' '}
                                        Drive app installs or engagements
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Choose your objective
                            </h5>
                            <p>
                              Select an objective to tailor your experience to
                              the goals and settings that will work best for
                              your campaign
                            </p>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont pt-3 pb-3'>
                                  <div
                                    className='action-btn-both action-btn-icon-tag row'
                                    onChange={(e) => {
                                      onChangeHandler(e);
                                    }}
                                  >
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='1'
                                        id='flexRadioDefault1'
                                        defaultChecked
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault1'
                                      >
                                        <i className='icon-awareness'></i>
                                        <div className='label-multitext'>
                                          {' '}
                                          Sales
                                          <p>
                                            Drive sales online, in app, by
                                            phone, or in store
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='2'
                                        id='flexRadioDefault2'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault2'
                                      >
                                        <i className='icon-traffic'></i>
                                        <div className='label-multitext'>
                                          {' '}
                                          Leads
                                          <p>
                                            Get leads and other conversions by
                                            encouraging customers to take action
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='3'
                                        id='flexRadioDefault6'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault6'
                                      >
                                        <i className='icon-sales'></i> Drive
                                        offline or in-store sales
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='4'
                                        id='flexRadioDefault3'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault3'
                                      >
                                        <i className='icon-engagement'></i>{' '}
                                        Drive app installs or engagements
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Select a campaign type Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Select a campaign type</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-img-tag row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={searchImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Search
                                              <p>
                                                Get in front of high-intent
                                                customers at the right time on
                                                Google Search
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={searchImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Performance Max
                                              <p>
                                                Reach audiences across all of
                                                Google with a single campaign.
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={displayImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Display
                                              <p>
                                                Reach customers across 3 million
                                                sites and apps with engaging
                                                creative
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={shoppingImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Shopping
                                              <p>
                                                Showcase your products to
                                                shoppers as they explore what to
                                                buy
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={videoImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Video
                                              <p>
                                                Reach viewers on YouTube and get
                                                conversions
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={discoveryImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Discovery
                                              <p>
                                                Run ads on YouTube, Gmail,
                                                Discover, and more
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Select a campaign type Ends Here */}

                        {/* Bidding Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Bidding</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Options</label>
                                    </div>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Select Page
                                        </option>
                                        <option value='coffee'>
                                          Conversions
                                        </option>
                                        <option value='tea'>Tea</option>
                                        <option value='milk'>Milk</option>
                                      </select>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='action-btn-both action-btn-both-check row relative'>
                                <div className='form-check-box'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='inlineCheckbox1'
                                    defaultValue='option1'
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='inlineCheckbox1'
                                  >
                                    Set a target cost per action{' '}
                                    <span className='opt-text'>(Optional)</span>
                                  </label>
                                </div>
                                <span className='error' id='emailError'>
                                  Please enter valid email id
                                </span>
                              </div>

                              <div class='info-icon-body mt-4'>
                                <div class='more-info-icon'>
                                  <i class='icon-bulb'></i>
                                </div>
                                <div class='more-info-text'>
                                  <p>
                                    Alternative bid strategies like portfolios
                                    are available in settings after you create
                                    your campaign
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Bidding Ends Here */}

                        {/* Campaign settings Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Campaign settings</h5>
                                <p>
                                  To reach the right people, start by defining
                                  key settings for your campaign
                                </p>
                                <h6>Networks</h6>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-img-tag row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault501'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault501'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={searchImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Search Network
                                              <p>
                                                Ads can appear near Google
                                                Search results and other Google
                                                sites when people search for
                                                terms that are relevant to your
                                                keywords
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault511'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault511'
                                          >
                                            <figure className='crt-img'>
                                              <img
                                                src={displayImg}
                                                alt='No creatives assigned'
                                              />
                                            </figure>
                                            <div className='label-multitext'>
                                              Display Network
                                              <p>
                                                Easy way to get additional
                                                conversions at similar or lower
                                                costs than Search with unused
                                                Search budget.
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <CardHeader>
                              <h6>Start and end dates</h6>
                            </CardHeader>

                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>Start date</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>End date</label>
                                    </div>
                                    <div className='input-group time'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </CardBody>

                            <CardHeader>
                              <h6 className='d-flex align-items-center'>
                                Ad schedule
                              </h6>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel form-panel-group-add'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-box-3'>
                                    <div className='label-icon'>
                                      <label>Select Days</label>
                                    </div>
                                    <SimpleSelectBox
                                      placeholder='All Days'
                                      options={[
                                        'CPM',
                                        'CPC',
                                        'CPA',
                                        'CTR',
                                        'Viewable%',
                                        'CPV',
                                        'CPIAVC',
                                        'other KPI',
                                      ]}
                                      onChangeHandler={onChangeHandler}
                                    ></SimpleSelectBox>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-box-3'>
                                    <div className='label-icon'>
                                      <label>Start time</label>
                                    </div>
                                    <div className='input-group input-width-less'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-clock' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='00:00'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-box-3'>
                                    <div className='label-icon'>
                                      <label>End time</label>
                                    </div>
                                    <div className='input-group input-width-less'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-clock' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker1'
                                        placeholder='00:00'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Description</label>
                                    </div>
                                    <div className='input-sizer stacked'>
                                      <textarea
                                        oninput='this.parentNode.dataset.value = this.value'
                                        rows={1}
                                        placeholder='Type Description'
                                        defaultValue={''}
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='media-btn'>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      <i className='icon-delete' />
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> Add budgets
                                </button>
                              </div>
                            </CardBody>
                            <CardBody>
                              {/* <div className="card-body"> */}
                              <div className='info-icon-body'>
                                <div className='more-info-icon'>
                                  {/* <i className='icon-info' /> */}
                                </div>
                                <div className='more-info-text'>
                                  <p>
                                    Based on account time zone: (GMT+05:30)
                                    India Standard Time
                                  </p>
                                  <p>
                                    Saving this removes the settings you changed
                                    and adds new ones, resetting any performance
                                    data
                                  </p>
                                </div>
                              </div>
                              {/* </div> */}
                            </CardBody>
                            <CardHeader>
                              <h6>Brand restrictions</h6>
                              <p>
                                Your ads will only show on searches that match
                                your keywords and mention selected brands,
                                including related products and services. Brand
                                restrictions will limit search traffic, so apply
                                only necessary brands.
                              </p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Options</label>
                                    </div>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Select Page
                                        </option>
                                        <option value='coffee'>
                                          Conversions
                                        </option>
                                        <option value='tea'>Tea</option>
                                        <option value='milk'>Milk</option>
                                      </select>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                            <CardBody>
                              {/* <div className="card-body"> */}
                              <div className='info-icon-body'>
                                <div className='more-info-icon'>
                                  {/* <i className='icon-info' /> */}
                                </div>
                                <div className='more-info-text'>
                                  <p>
                                    To add brand restrictions, turn on broad
                                    match keywords for this campaign. All new
                                    and existing keywords will be converted to
                                    broad match.
                                  </p>
                                </div>
                              </div>
                              {/* </div> */}
                            </CardBody>
                          </div>
                        </div>
                        {/* Campaign settings Ends Here */}

                        <Card>
                          <CardHeader>
                            <h5>Campign Name</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Campaign Name</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      name='campaignName'
                                      value={createCampignData?.campaignName}
                                      id='text'
                                      placeholder='New Awareness Compaign'
                                      onChange={onChangeHandler}
                                    />
                                  </div>
                                  {/* <span className="error" id="emailError">Please enter valid email id</span> */}
                                  {/* <small className="select-time">0 / 10</small> */}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Use these conversion goals to improve Sales Start Here */}
                        <Card>
                          <CardHeader>
                            <h5>Use these conversion goals to improve Sales</h5>
                            <p>
                              Conversion goals labeled as account default will
                              use data from all of your campaigns to improve
                              your bid strategy and campaign performance, even
                              if they don't seem directly related to Sales.z
                            </p>
                          </CardHeader>
                          <CardBody>
                            <div className='short-data-table'>
                              <div className='table-responsive'>
                                <table className='atd-table dataTable no-footer'>
                                  <thead>
                                    <tr>
                                      <td>Conversion Goals</td>
                                      <td>Conversion Source</td>
                                      <td>Conversion Actions</td>
                                      <td>Value</td>
                                      <td>Other</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className='odd'>
                                      <td>
                                        <i className='icon-add-shop icon-style50' />
                                        <strong className='me-2'>
                                          Add to cart
                                        </strong>
                                        (account default)
                                      </td>
                                      <td>Mobile App</td>
                                      <td>
                                        <i className='icon-exclam' />
                                        <a
                                          className='exclam-btn'
                                          href='/taxonomy_media_campaign/Campaign?id=58'
                                        >
                                          3 actions
                                        </a>
                                      </td>
                                      <td>Dynamic</td>
                                      <td>
                                        <div className='dropdown'>
                                          <button
                                            type='button'
                                            className='close-btn'
                                            id='dropdownMenuButton2'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <i className='icon-meni-hori' />
                                          </button>{' '}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className='odd'>
                                      <td>
                                        <i className='icon-barcode icon-style50' />
                                        <strong className='me-2'>
                                          Begin checkout
                                        </strong>
                                        (account default)
                                      </td>
                                      <td>Mobile App</td>
                                      <td>
                                        <a
                                          className='exclam-btn'
                                          href='/taxonomy_media_campaign/Campaign?id=58'
                                        >
                                          3 actions
                                        </a>
                                      </td>
                                      <td>Dynamic</td>
                                      <td>
                                        <div className='dropdown'>
                                          <button
                                            type='button'
                                            className='close-btn'
                                            id='dropdownMenuButton2'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <i className='icon-meni-hori' />
                                          </button>{' '}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className='odd'>
                                      <td>
                                        <i className='icon-credit icon-style50' />
                                        <strong className='me-2'>
                                          Purchases
                                        </strong>
                                        (account default)
                                      </td>
                                      <td>Mobile App</td>
                                      <td>
                                        <a
                                          className='exclam-btn'
                                          href='/taxonomy_media_campaign/Campaign?id=58'
                                        >
                                          3 actions
                                        </a>
                                      </td>
                                      <td>Dynamic</td>
                                      <td>
                                        <div className='dropdown'>
                                          <button
                                            type='button'
                                            className='close-btn'
                                            id='dropdownMenuButton2'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <i className='icon-meni-hori' />
                                          </button>{' '}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className='odd'>
                                      <td>
                                        <i className='icon-target icon-style50' />
                                        <strong className='me-2'>Other</strong>
                                        (account default)
                                      </td>
                                      <td>Mobile App</td>
                                      <td>
                                        <i className='icon-exclam' />
                                        <a
                                          className='exclam-btn'
                                          href='/taxonomy_media_campaign/Campaign?id=58'
                                        >
                                          3 actions
                                        </a>
                                      </td>
                                      <td>Dynamic</td>
                                      <td>
                                        <div className='dropdown'>
                                          <button
                                            type='button'
                                            className='close-btn'
                                            id='dropdownMenuButton2'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <i className='icon-meni-hori' />
                                          </button>{' '}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className='odd'>
                                      <td>
                                        <i className='icon-preview-outlined icon-style50' />
                                        <strong className='me-2'>
                                          Page views
                                        </strong>
                                        (account default)
                                      </td>
                                      <td>Mobile App</td>
                                      <td>
                                        <a
                                          className='exclam-btn'
                                          href='/taxonomy_media_campaign/Campaign?id=58'
                                        >
                                          3 actions
                                        </a>
                                      </td>
                                      <td>Dynamic</td>
                                      <td>
                                        <div className='dropdown'>
                                          <button
                                            type='button'
                                            className='close-btn'
                                            id='dropdownMenuButton2'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <i className='icon-meni-hori' />
                                          </button>{' '}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className='odd'>
                                      <td>
                                        <i className='icon-perm icon-style50' />
                                        <strong className='me-2'>
                                          Submit lead forms
                                        </strong>
                                        (account default)
                                      </td>
                                      <td>Mobile App</td>
                                      <td>
                                        <i className='icon-exclam' />
                                        <a
                                          className='exclam-btn'
                                          href='/taxonomy_media_campaign/Campaign?id=58'
                                        >
                                          3 actions
                                        </a>
                                      </td>
                                      <td>Dynamic</td>
                                      <td>
                                        <div className='dropdown'>
                                          <button
                                            type='button'
                                            className='close-btn'
                                            id='dropdownMenuButton2'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                          >
                                            <i className='icon-meni-hori' />
                                          </button>{' '}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Use these conversion goals to improve Sales Ends Here */}

                        {/* Select the ways you'd like to reach your goal Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>
                                  Select the ways you'd like to reach your goal
                                </h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault508'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault508'
                                          >
                                            Website visits
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>
                                                Your business website
                                              </label>
                                            </div>
                                            {/* <div className="select-multiple-components"> */}
                                            <ReactSelect />
                                            {/* </div> */}
                                            <span
                                              className='error ps-0'
                                              id='emailError'
                                            >
                                              Please enter valid email id
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Phone calls
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='dv-check-box'>
                                            <div className='row align-items-center'>
                                              <div className='form-item col-6'>
                                                <div className='label-icon'>
                                                  <label>Country</label>
                                                </div>
                                                <div className='select-box'>
                                                  <select
                                                    name='drinks'
                                                    required=''
                                                  >
                                                    <option
                                                      value=''
                                                      disabled=''
                                                      selected=''
                                                    >
                                                      United States
                                                    </option>
                                                    <option value='coffee'>
                                                      Coffee
                                                    </option>
                                                    <option value='tea'>
                                                      Tea
                                                    </option>
                                                    <option value='milk'>
                                                      Milk
                                                    </option>
                                                  </select>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='emailError'
                                                >
                                                  Please enter valid email id
                                                </span>
                                              </div>
                                              <div className='form-item col-6'>
                                                <div className='label-icon'>
                                                  <label>Phone Number</label>
                                                </div>
                                                <div className='input-group'>
                                                  <input
                                                    type='email'
                                                    id='email'
                                                    placeholder='Type Phone Number'
                                                  />
                                                </div>
                                                <span
                                                  className='error'
                                                  id='emailError'
                                                >
                                                  Please enter valid email id
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Store visits
                                            <p>
                                              Enter location on the next step
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            App downloads
                                            <p>
                                              Select your mobile app's platform
                                            </p>
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-no-icon row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault50'
                                                    defaultChecked=''
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50'
                                                  >
                                                    Android
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault51'
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault51'
                                                  >
                                                    IOS
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <CardHeader>
                                            <div class='card-lt-pane'>
                                              <h6>Look up your app</h6>
                                            </div>

                                            <div className='form-item'>
                                              <div className='label-icon'>
                                                <label>
                                                  Your business website
                                                </label>
                                              </div>
                                              {/* <div className="select-multiple-components"> */}
                                              <ReactSelect />
                                              {/* </div> */}
                                              <span
                                                className='error ps-0'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </CardHeader>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Select the ways you'd like to reach your goal Ends Here */}

                        <Card>
                          <CardHeader>
                            <h5>Campign Budget</h5>
                            <h6 className='d-flex align-items-center'>
                              KPI
                              <span
                                className='label-information tooltips'
                                tooltip='tootltip'
                                tooltip-position='top'
                              >
                                {/* <i className='icon-info'></i> */}
                                <span></span>
                              </span>
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-6'>
                                  <div className='label-icon'>
                                    <label>KPI</label>
                                    <span
                                      className='label-information tooltips'
                                      tooltip='Cascading Style Sheets'
                                      tooltip-position='top'
                                    >
                                      {' '}
                                      {/* <i className='icon-info' /> */}
                                    </span>
                                  </div>
                                  {/* <div className="select-box">
                                                                        <select name="drinks" required="">
                                                                            <option value="" disabled="" selected="">
                                                                                KPI Goal
                                                                            </option>
                                                                            <option value="coffee">Coffee</option>
                                                                            <option value="tea">Tea</option>
                                                                            <option value="milk">Milk</option>
                                                                        </select>
                                                                    </div> */}
                                  <SimpleSelectBox
                                    name='kpi'
                                    placeholder='Choose KPI'
                                    options={[
                                      'CPM',
                                      'CPC',
                                      'CPA',
                                      'CTR',
                                      'Viewable%',
                                      'CPV',
                                      'CPIAVC',
                                      'other KPI',
                                    ]}
                                    onChangeHandler={onChangeHandler}
                                  ></SimpleSelectBox>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                {/* currency field */}
                                {createCampignData?.kpi == 'Viewable%' ? (
                                  <div className='form-item input-group-label col-6'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>KPI Goal</label>
                                      </div>
                                      <div className='input-group'>
                                        {/* <span className="input-group-text rs-text">
                                                                             <i className="icon-rupee" />
                                                                         </span> */}
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder=''
                                          onChange={onChangeHandler}
                                          name='kpi goal'
                                        />
                                        <span className='input-group-text inr-text rs-text'>
                                          %
                                        </span>
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                ) : createCampignData?.kpi == 'other KPI' ? (
                                  <div className='form-item col-6'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>Type KPI</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder=''
                                          name='kpi goal'
                                          onChange={onChangeHandler}
                                        />
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                ) : (
                                  <div className='form-item input-group-label col-6'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>KPI Goal</label>
                                      </div>
                                      <div className='input-group'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-rupee' />
                                        </span>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder=''
                                          name='kpi goal'
                                          onChange={onChangeHandler}
                                        />
                                        <span className='input-group-text inr-text rs-text'>
                                          INR
                                        </span>
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                )}
                                {/* end of currency field */}

                                {/* <div className="col-12">
                                                                    <div className="or-line">
                                                                        or
                                                                    </div>
                                                                </div> */}

                                {/* <div className="form-item col-6">
                                                                    <div className="label-icon">
                                                                        <label>KPI</label>
                                                                        <span
                                                                            className="label-information tooltips"
                                                                            tooltip="Cascading Style Sheets"
                                                                            tooltip-position="top"
                                                                        >
                                                                            {" "}
                                                                            <i className="icon-info" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="select-box">
                                                                        <select name="drinks" required="">
                                                                            <option value="" disabled="" selected="">
                                                                                KPI Goal
                                                                            </option>
                                                                            <option value="coffee">Coffee</option>
                                                                            <option value="tea">Tea</option>
                                                                            <option value="milk">Milk</option>
                                                                        </select>
                                                                    </div>
                                                                    <span className="error" id="emailError">
                                                                        Please enter valid email id
                                                                    </span>
                                                                </div> */}
                                {/* plain text field */}
                                {/* <div className="form-item col-6">
                                                                    <div className="budget-cont">
                                                                        <div className="label-icon">
                                                                            <label>Type KPI</label>
                                                                        </div>
                                                                        <div className="input-group">
                                                                            <input type="email" id="email" placeholder="X.XX" />
                                                                        </div>
                                                                    </div>
                                                                    <span className="error" id="emailError">
                                                                        Please enter valid email id
                                                                    </span>
                                                                </div> */}
                                {/* end of plain text field */}
                              </div>
                            </div>
                          </CardBody>

                          <CardHeader>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6 className='d-flex align-items-center'>
                                  Creative type you expect to use
                                </h6>
                                <div className='action-btn-both action-btn-both-check row relative'>
                                  <div className='col-6 d-flex flex-wrap'>
                                    <div className='form-check-box col-4'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        Display
                                      </label>
                                    </div>
                                    <div className='form-check-box col-4'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox10'
                                        defaultValue='Video'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox10'
                                      >
                                        Video
                                      </label>
                                    </div>
                                    <div className='form-check-box col-4'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox11'
                                        defaultValue='Audio'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox11'
                                      >
                                        Audio
                                      </label>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardHeader>

                          <CardHeader>
                            <h6 className='d-flex align-items-center'>
                              Planned spend{' '}
                              <span className='opt-text'>Optional</span>
                              <span
                                className='label-information tooltips'
                                tooltip='tootltip'
                                tooltip-position='top'
                              >
                                {/* <i className='icon-info'></i> */}
                                <span></span>
                              </span>
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item input-group-label col-6'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Amount</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-rupee' />
                                      </span>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='X.XX'
                                        name='Planned spend'
                                        onChange={onChangeHandler}
                                      />
                                      <span className='input-group-text inr-text rs-text'>
                                        INR
                                      </span>
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>

                          <CardHeader>
                            <h6 className='d-flex align-items-center'>
                              Planned dates
                              <span
                                className='label-information tooltips'
                                tooltip='tootltip'
                                tooltip-position='top'
                              >
                                {/* <i className='icon-info'></i> */}
                                <span></span>
                              </span>
                            </h6>
                          </CardHeader>

                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item input-group-label col-6'>
                                  <div className='label-icon'>
                                    <label>Start date</label>
                                  </div>
                                  <div className='input-group'>
                                    <span className='input-group-text rs-text'>
                                      <i className='icon-calendar' />
                                    </span>
                                    <input
                                      type='text'
                                      id='datepicker'
                                      placeholder='DD/MM/YYYY'
                                      name='planned Start date'
                                      onChange={onChangeHandler}
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div className='form-item input-group-label col-6'>
                                  <div className='label-icon'>
                                    <label>End date ∙ Optional</label>
                                  </div>
                                  <div className='input-group'>
                                    <span className='input-group-text rs-text'>
                                      <i className='icon-calendar' />
                                    </span>
                                    <input
                                      type='text'
                                      id='datepicker'
                                      placeholder='DD/MM/YYYY'
                                      name='planned end date'
                                      onChange={onChangeHandler}
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Frequency cap
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel card-form-days dv-card-form-days'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div
                                    className='action-btn-both action-btn-no-icon row'
                                    name='Frequency cap'
                                    onChange={onChangeHandler}
                                  >
                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='Frequency cap'
                                          id='flexRadioDefault2Nolimit'
                                          defaultChecked
                                          value='no limit'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault2Nolimit'
                                        >
                                          No limit
                                        </label>
                                      </div>
                                    </div>

                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='Frequency cap'
                                          id='flexRadioDefault3hh'
                                          value='limit frequency to'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault3hh'
                                        >
                                          Limit frequency to
                                        </label>
                                      </div>
                                      <div className='form-item d-flex align-items-center me-3'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Enter a value'
                                          name='frequency limit value'
                                          onChange={onChangeHandler}
                                        />
                                        <label>exposures per </label>
                                        <span
                                          className='error px-0'
                                          id='emailError'
                                        >
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div className='form-item d-flex align-items-center'>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Lifetime of this campaign'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                          onChangeHandler={onChangeHandler}
                                        ></SimpleSelectBox>
                                        <span
                                          className='error px-0'
                                          id='emailError'
                                        >
                                          Please enter valid email id
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          <CardBody>
                            <InfoText></InfoText>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5>Inventory source</h5>
                            <p>
                              New insertion orders and line items in this
                              campaign will inherit these settings.
                            </p>
                          </CardHeader>

                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <h6 className='card-header-title'>Quality</h6>
                                  <SimpleSelectBox></SimpleSelectBox>
                                </div>
                                <div className='form-item col-12'>
                                  <h6 className='card-header-title'>
                                    Public Inventory
                                  </h6>
                                  <div className='label-icon'>
                                    <label>Public Inventory</label>
                                  </div>
                                  {/* <div className="select-multiple-components"> */}
                                  <ReactSelect />
                                  {/* </div> */}
                                </div>
                                <div className='form-item col-12'>
                                  <h6 className='card-header-title'>
                                    Deals and inventory packages.
                                  </h6>
                                  <div className='label-icon'>
                                    <label>Deals and inventory packages</label>
                                  </div>
                                  {/* <div className="select-multiple-components"> */}
                                  <ReactSelect />
                                  {/* </div> */}
                                </div>
                                <div className='form-item col-12'>
                                  <h6 className='card-header-title'>Groups</h6>
                                  <div className='label-icon'>
                                    <label>Groups</label>
                                  </div>
                                  {/* <div className="select-multiple-components"> */}
                                  <ReactSelect />
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5>Targetting</h5>
                            <p>
                              New insertion orders and line items in this
                              campaign will inherit these settings.
                            </p>
                          </CardHeader>
                          <CardBody>
                            <SimpleSelectBox></SimpleSelectBox>
                          </CardBody>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Demographics</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        All genders,ages,parental statuses and
                                        household incomes.{' '}
                                      </p>
                                      {/* <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h6>Geography</h6>
                              <div className='dv-header-icon'>
                                <div className='dv-icon-outer d-flex'>
                                  <div className='dv-icon'>
                                    <i className='icon-check-mark' />
                                  </div>
                                  <div className='dv-data-icon'>
                                    <p>Target the following regions</p>
                                    <div className='dv-inner-icon d-flex'>
                                      <p className='dv-text-g'>
                                        India (Country)
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='dv-header-icon'>
                                <div className='dv-icon-outer d-flex'>
                                  <div className='dv-icon'>
                                    <i className='icon-check-mark' />
                                  </div>
                                  <div className='dv-data-icon'>
                                    <p>Target the following regions</p>
                                    <div className='dv-inner-icon d-flex'>
                                      <p className='dv-text-g'>
                                        India (Country)
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Language</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>All languages </p>
                                      {/* <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Brand safety</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        No inventory group selected.{' '}
                                      </p>
                                      {/* <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                        </Card>

                        {/* Budget Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Budget</h5>
                                <p>
                                  Budget and pacing depend on both insertion
                                  order and line item settings.
                                </p>
                                <h6 className='d-flex align-items-center'>
                                  Budget
                                </h6>
                                <p>
                                  Specify this insertion order's flight dates
                                  and budget segments
                                </p>
                              </div>
                              <div className='me-auto card-switch-rigth'>
                                <div className='form-panel select-with-side-label'>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Budget Type</label>
                                    </div>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Choose File
                                        </option>
                                        <option value=''>INR</option>
                                        <option value='coffee'>
                                          Impressions
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel form-panel-group-add'>
                                <div className='row align-items-center'>
                                  <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                    <div className='label-icon'>
                                      <label>
                                        Budget<sup>*</sup>
                                      </label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-rupee' />
                                      </span>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder=''
                                      />
                                      <span className='input-group-text inr-text rs-text'>
                                        INR
                                      </span>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-box-3'>
                                    <div className='label-icon'>
                                      <label>Start date</label>
                                    </div>
                                    <div className='input-group input-width-less'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-box-3'>
                                    <div className='label-icon'>
                                      <label>End date</label>
                                    </div>
                                    <div className='input-group input-width-less'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker1'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Description</label>
                                    </div>
                                    <div className='input-sizer stacked'>
                                      <textarea
                                        oninput='this.parentNode.dataset.value = this.value'
                                        rows={1}
                                        placeholder='Type Description'
                                        defaultValue={''}
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='media-btn'>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      <i className='icon-delete' />
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> Add budgets
                                </button>
                              </div>
                            </div>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>Pacing</h6>
                                <p>
                                  How do you want to spend the flight budget?
                                </p>
                              </div>
                            </div>
                            <div className='card-form form-panel form-panel-group-add form-panel-no-border'>
                              <div className='row align-items-center'>
                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Daily
                                      </option>
                                      <option value='coffee'>Coffee</option>
                                      <option value='tea'>Tea</option>
                                      <option value='milk'>Milk</option>
                                    </select>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Even
                                      </option>
                                      <option value='coffee'>Coffee</option>
                                      <option value='tea'>Tea</option>
                                      <option value='milk'>Milk</option>
                                    </select>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div className='col-12'>
                                  <div className='or-line'>or</div>
                                </div>

                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Daily
                                      </option>
                                      <option value='coffee'>Coffee</option>
                                      <option value='tea'>Tea</option>
                                      <option value='milk'>Milk</option>
                                    </select>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Even
                                      </option>
                                      <option value='coffee'>Coffee</option>
                                      <option value='tea'>Tea</option>
                                      <option value='milk'>Milk</option>
                                    </select>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                  <div className='label-icon'>
                                    <label>Amount</label>
                                  </div>
                                  <div className='input-group'>
                                    <span className='input-group-text rs-text'>
                                      <i className='icon-rupee' />
                                    </span>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='X.XX'
                                    />
                                    <span className='input-group-text inr-text rs-text'>
                                      INR
                                    </span>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>Goal</h6>
                                <p>What goal would you like to focus on?</p>
                              </div>
                            </div>
                            <div className='card-form form-panel form-panel-group-add form-panel-no-border'>
                              <div className='row align-items-center'>
                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Daily
                                      </option>
                                      <option value='coffee'>Coffee</option>
                                      <option value='tea'>Tea</option>
                                      <option value='milk'>Milk</option>
                                    </select>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                  <div className='label-icon'>
                                    <label>Amount</label>
                                  </div>
                                  <div className='input-group'>
                                    <span className='input-group-text rs-text'>
                                      <i className='icon-rupee' />
                                    </span>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder=''
                                    />
                                    <span className='input-group-text inr-text rs-text'>
                                      INR
                                    </span>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>Billable outcome</h6>
                                <p>What would you like to pay for?</p>
                              </div>
                            </div>
                            <div className='card-form form-panel form-panel-group-add form-panel-no-border'>
                              <div className='row align-items-center'>
                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Impressions
                                      </option>
                                      <option value='coffee'>Coffee</option>
                                      <option value='tea'>Tea</option>
                                      <option value='milk'>Milk</option>
                                    </select>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>Optimization</h6>
                                <p>How would you like to optimize?</p>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Automate bid &amp; budget at
                                            insertion order level
                                            <p>
                                              Allow system to automatically
                                              adjust bids and shift budget to
                                              better-performing line items.
                                            </p>
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  Maximize viewable impressions
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            <span
                                              className='error ps-0'
                                              id='emailError'
                                            >
                                              Please enter valid email id
                                            </span>
                                          </div>
                                          <p className='about-info-text'>
                                            while prioritizing spending my full
                                            budget (recommended)
                                          </p>
                                          <div className='d-flex dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox7'
                                                  defaultValue='option1'
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox7'
                                                >
                                                  Do not exceed average CPM of
                                                </label>
                                              </div>
                                            </div>
                                            <div className='form-item input-group-label'>
                                              <div className='label-icon'>
                                                <label>Amount</label>
                                              </div>
                                              <div className='input-group'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-rupee' />
                                                </span>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder=''
                                                />
                                                <span className='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                              <span
                                                className='error px-0'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                          <div className='dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox8'
                                                  defaultValue='option1'
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox8'
                                                >
                                                  Prioritize deals over open
                                                  auction inventory
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Control bid and budget at the line
                                            item level
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox8'
                                                  defaultValue='option1'
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox8'
                                                >
                                                  Prioritize deals over open
                                                  auction inventory
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6 className='d-flex align-items-center'>
                                  Frequency cap
                                </h6>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel card-form-days dv-card-form-days'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 d-flex px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault2'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault2'
                                          >
                                            No limit
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 d-flex px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault3'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault3'
                                          >
                                            Limit frequency to
                                          </label>
                                        </div>
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <input
                                            type='email'
                                            id='email'
                                            placeholder='Enter a value'
                                          />
                                          <label>exposures per </label>
                                          <span
                                            className='error px-0'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div className='form-item d-flex align-items-center'>
                                          <div className='select-box'>
                                            <select name='drinks' required=''>
                                              <option
                                                value=''
                                                disabled=''
                                                selected=''
                                              >
                                                Lifetime of this campaign
                                              </option>
                                              <option value='coffee'>
                                                Coffee
                                              </option>
                                              <option value='tea'>Tea</option>
                                              <option value='milk'>Milk</option>
                                            </select>
                                          </div>
                                          <span
                                            className='error px-0'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='info-icon-body'>
                                <div className='more-info-icon'>
                                  <i className='icon-bulb' />
                                </div>
                                <div className='more-info-text'>
                                  <p>
                                    You can also set specific frequency caps on
                                    each insertion order and line item in this
                                    campaign.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Budget Ends Here */}

                        {/* Note Start Hear */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Note</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Note</label>
                                    </div>
                                    <div className='input-sizer stacked'>
                                      <textarea
                                        oninput='this.parentNode.dataset.value = this.value'
                                        rows={1}
                                        placeholder='Type Reason'
                                      />
                                    </div>

                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Note Ends Hear */}

                        {/* Line Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Line item name</h5>
                              </div>
                              <div className='me-auto card-switch-rigth'>
                                <div className='form-panel select-with-side-label'>
                                  <div className='form-item'>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Draft
                                        </option>
                                        <option value=''>INR</option>
                                        <option value='coffee'>
                                          Impressions
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Line item name</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Enter line item name'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Line Ends Here */}

                        {/* Media type Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Media type</h5>
                                <h6>Video</h6>
                                <p>Run video ads across YouTube & partners</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Media type Ends Here */}

                        {/* Type Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Type</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Brand awareness and reach
                                            <p>
                                              Reach a broad audience and build
                                              awareness
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Product and brand consideration
                                            <p>
                                              Encourage people to explore your
                                              products or services
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Drive conversions
                                            <p>
                                              Optimize toward traffic, leads, or
                                              sales with YouTube video action
                                              campaigns
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Type Ends Here */}

                        {/* Ad format Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Ad format</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Efficient reach (bumper, skippable
                                            in-stream)
                                            <p>
                                              Reach more unique users at a lower
                                              cost with bumper ads, skippable
                                              in-stream ads, or a mix of both
                                              types.
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Non-skippable ads
                                            <p>
                                              Share your message with up to
                                              15-second non-skippable in-stream
                                              ads
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Ad sequence
                                            <p>
                                              Tell your story by showing ads in
                                              a particular sequence with a mix
                                              of formats
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Target frequency
                                            <p>
                                              Reach the same people more than
                                              once with skippable in-stream ads,
                                              non-skippable in-stream ads, or
                                              bumper ads.
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Ad format Ends Here */}

                        {/* Optimized targeting Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Optimized targeting</h5>

                                <div className='action-btn-both action-btn-both-check row'>
                                  <div className='form-check-box'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='inlineCheckbox1'
                                      defaultValue='option1'
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor='inlineCheckbox1'
                                    >
                                      Use optimized targeting
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='info-icon-body'>
                                <div className='more-info-icon'>
                                  <i className='icon-bulb' />
                                </div>
                                <div className='more-info-text'>
                                  <p>
                                    Optimized targeting may help you reach your
                                    campaign's goals by finding new people
                                    beyond your selected audience list.
                                  </p>
                                  <p>
                                    For this line item to be eligible to use
                                    optimized targeting, use audience list
                                    targeting and turn on automated bidding.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Optimized targeting Ends Here */}

                        {/* Flight dates Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Flight dates</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Use same dates as insertion order
                                            <p>Jul 18, 2023 - Aug 18, 2023</p>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Custom dates
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='row align-items-center'>
                                            <div className='form-item input-group-label col-6'>
                                              <div className='label-icon'>
                                                <label>Start date</label>
                                              </div>
                                              <div className='input-group'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-calendar' />
                                                </span>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='DD/MM/YYYY'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item input-group-label col-6'>
                                              <div className='label-icon'>
                                                <label>End date</label>
                                              </div>
                                              <div className='input-group time'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-calendar' />
                                                </span>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='DD/MM/YYYY'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Flight dates Ends Here */}

                        {/* Budget Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Budget</h5>
                                <p>
                                  Budget and pacing depend on both insertion
                                  order and line item settings.
                                </p>
                              </div>
                              <div className='me-auto card-switch-rigth'>
                                <div className='form-panel select-with-side-label'>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Budget Type</label>
                                    </div>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Choose File
                                        </option>
                                        <option value=''>INR</option>
                                        <option value='coffee'>
                                          Impressions
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>Budget & pacing</h6>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            {' '}
                                            Unlimited up to insertion order's
                                            budget.
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0 d-flex'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                        </div>

                                        <div className='card-form form-panel form-panel-group-add mt-0 form-panel-no-border ps-5'>
                                          <div className='form-item input-group-label col-5'>
                                            <div className='budget-cont'>
                                              <div className='label-icon'>
                                                <label>Amount</label>
                                              </div>
                                              <div className='input-group'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-rupee' />
                                                </span>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder='X.XX'
                                                  name='Planned spend'
                                                  onChange={onChangeHandler}
                                                />
                                                <span className='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                            </div>
                                            <span
                                              className='error ps-0'
                                              id='emailError'
                                            >
                                              Please enter valid email id
                                            </span>
                                          </div>
                                          <div className='row align-items-center'>
                                            <div className='form-item col-box-3'>
                                              <div className='label-icon'>
                                                <label>Select</label>
                                              </div>
                                              <div className='select-box'>
                                                <select
                                                  name='drinks'
                                                  required=''
                                                >
                                                  <option
                                                    value=''
                                                    disabled=''
                                                    selected=''
                                                  >
                                                    Daily
                                                  </option>
                                                  <option value='coffee'>
                                                    Coffee
                                                  </option>
                                                  <option value='tea'>
                                                    Tea
                                                  </option>
                                                  <option value='milk'>
                                                    Milk
                                                  </option>
                                                </select>
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item col-box-3'>
                                              <div className='label-icon'>
                                                <label>Select</label>
                                              </div>
                                              <div className='select-box'>
                                                <select
                                                  name='drinks'
                                                  required=''
                                                >
                                                  <option
                                                    value=''
                                                    disabled=''
                                                    selected=''
                                                  >
                                                    Even
                                                  </option>
                                                  <option value='coffee'>
                                                    Coffee
                                                  </option>
                                                  <option value='tea'>
                                                    Tea
                                                  </option>
                                                  <option value='milk'>
                                                    Milk
                                                  </option>
                                                </select>
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='col-12'>
                                              <div className='or-line'>or</div>
                                            </div>

                                            <div className='form-item col-box-3'>
                                              <div className='label-icon'>
                                                <label>Select</label>
                                              </div>
                                              <div className='select-box'>
                                                <select
                                                  name='drinks'
                                                  required=''
                                                >
                                                  <option
                                                    value=''
                                                    disabled=''
                                                    selected=''
                                                  >
                                                    Daily
                                                  </option>
                                                  <option value='coffee'>
                                                    Coffee
                                                  </option>
                                                  <option value='tea'>
                                                    Tea
                                                  </option>
                                                  <option value='milk'>
                                                    Milk
                                                  </option>
                                                </select>
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item col-box-3'>
                                              <div className='label-icon'>
                                                <label>Select</label>
                                              </div>
                                              <div className='select-box'>
                                                <select
                                                  name='drinks'
                                                  required=''
                                                >
                                                  <option
                                                    value=''
                                                    disabled=''
                                                    selected=''
                                                  >
                                                    Even
                                                  </option>
                                                  <option value='coffee'>
                                                    Coffee
                                                  </option>
                                                  <option value='tea'>
                                                    Tea
                                                  </option>
                                                  <option value='milk'>
                                                    Milk
                                                  </option>
                                                </select>
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                              <div className='label-icon'>
                                                <label>Amount</label>
                                              </div>
                                              <div className='input-group'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-rupee' />
                                                </span>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder=''
                                                />
                                                <span className='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Control bid and budget at the line
                                            item level
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox8'
                                                  defaultValue='option1'
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox8'
                                                >
                                                  Prioritize deals over open
                                                  auction inventory
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>Bid strategy</h6>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Automate bidding
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  Maximize viewable impressions
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            <span
                                              className='error ps-0'
                                              id='emailError'
                                            >
                                              Please enter valid email id
                                            </span>
                                          </div>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  while prioritizing spending my
                                                  full budget (recommended)
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            <span
                                              className='error ps-0'
                                              id='emailError'
                                            >
                                              Please enter valid email id
                                            </span>
                                          </div>

                                          <div className='d-flex dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox7'
                                                  defaultValue='option1'
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox7'
                                                >
                                                  Do not exceed average CPM of
                                                </label>
                                              </div>
                                            </div>
                                            <div className='form-item input-group-label'>
                                              <div className='label-icon'>
                                                <label>Amount</label>
                                              </div>
                                              <div className='input-group'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-rupee' />
                                                </span>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder='X.XX'
                                                />
                                                <span className='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                              <span
                                                className='error px-0'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                          <div className='dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox8'
                                                  defaultValue='option1'
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox8'
                                                >
                                                  Prioritize deals over open
                                                  auction inventory
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Fixed bid
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-item input-group-label col-5'>
                                                <div className='label-icon'>
                                                  <label>Amount</label>
                                                </div>
                                                <div className='input-group'>
                                                  <span className='input-group-text rs-text'>
                                                    <i className='icon-rupee' />
                                                  </span>
                                                  <input
                                                    type='email'
                                                    id='email'
                                                    placeholder=''
                                                  />
                                                  <span className='input-group-text inr-text rs-text'>
                                                    INR
                                                  </span>
                                                </div>
                                                <span
                                                  className='error px-0'
                                                  id='emailError'
                                                >
                                                  Please enter valid email id
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6 className='d-flex align-items-center'>
                                  Frequency cap
                                </h6>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel card-form-days dv-card-form-days'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 d-flex px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault2'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault2'
                                          >
                                            No limit
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 d-flex px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault3'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault3'
                                          >
                                            Limit frequency to
                                          </label>
                                        </div>
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <input
                                            type='email'
                                            id='email'
                                            placeholder='Enter a value'
                                          />
                                          <label>exposures per </label>
                                          <span
                                            className='error px-0'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div className='form-item d-flex align-items-center'>
                                          <div className='select-box'>
                                            <select name='drinks' required=''>
                                              <option
                                                value=''
                                                disabled=''
                                                selected=''
                                              >
                                                Lifetime of this campaign
                                              </option>
                                              <option value='coffee'>
                                                Coffee
                                              </option>
                                              <option value='tea'>Tea</option>
                                              <option value='milk'>Milk</option>
                                            </select>
                                          </div>
                                          <span
                                            className='error px-0'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Budget Ends Here */}

                        {/* Creatives Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Creatives</h5>
                                <p className='price-highlight'>
                                  Advanced ad serving rate: ₹0.00 CPM
                                </p>
                              </div>
                              <div className='me-auto card-switch-rigth'>
                                <div className='form-panel select-with-side-label'>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Creative optimization</label>
                                      <span
                                        className='label-information tooltips'
                                        tooltip='Cascading Style Sheets'
                                        tooltip-position='top'
                                      >
                                        {' '}
                                        {/* <i className='icon-info' /> */}
                                      </span>
                                    </div>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Choose File
                                        </option>
                                        <option value='coffee'>
                                          Completions
                                        </option>
                                        <option value='coffee'>
                                          Time on screen
                                        </option>
                                        <option value='coffee'>
                                          Match bidding strategy
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='crt-box-data'>
                                <div className='crt-img-cont'>
                                  <figure className='crt-img'>
                                    <img
                                      src={noCreativesAssigned}
                                      alt='No creatives assigned'
                                    />
                                  </figure>
                                  <div className='crt-text'>
                                    <p>No creatives assigned</p>
                                  </div>
                                </div>
                                <div className='media-btn'>
                                  <button
                                    type='button'
                                    className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                  >
                                    Assign creatives
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Creatives Ends Here */}

                        {/* Prodcut Feed Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Product feed</h5>
                                <p>Define who you want to see your ads.</p>
                                <div className='action-btn-both action-btn-both-check row'>
                                  <div className='form-check-box'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='inlineCheckbox1'
                                      defaultValue='option1'
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor='inlineCheckbox1'
                                    >
                                      Use a Google Merchant Center feed to show
                                      products with your ads
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body'>
                              <h6 className='card-header-title'>
                                Merchant Center account
                              </h6>
                              <div className='search-bar-cont search-bar-border'>
                                <div className='search'>
                                  <div className='search-close'>
                                    <input
                                      type='text'
                                      placeholder='Search Saved Audience'
                                    />
                                    <button className='close-btn'>
                                      <i className='icon-close' />
                                    </button>
                                  </div>
                                  <button className='btn-icon btn-icon-primary'>
                                    <i className='icon-search' />
                                  </button>
                                </div>
                              </div>
                              <div className='card-header-top'>
                                <div className='card-lt-pane'>
                                  <h6>Related videos</h6>
                                  <p>
                                    Add videos related to your video ads to
                                    increase engagement
                                  </p>
                                </div>
                              </div>
                              <div className='card-body'>
                                <div className='slideshow-box-group-rept'>
                                  <div className='slideshow-box d-flex'>
                                    <div className='slideshow-imgbox d-flex align-items-center'>
                                      <img
                                        src={slidePlaceHolder}
                                        alt='Slide Show'
                                      />
                                    </div>
                                    <div className='slideshow-cont'>
                                      <div className='slideshow-cont-top'>
                                        <h3 className='tag-link'>
                                          <a
                                            href={'http://google.com'}
                                            className='url-txt'
                                          >
                                            Protect Your UAN With CENTURO
                                          </a>
                                        </h3>
                                        <p>
                                          by Michelle Phan 2059626 views An easy
                                          fun way to mattify your nail polish
                                          with just steam! Who else is going to
                                          try this out? Make sure your nails are
                                          wet, either nail polish or top coat.
                                          Quickly …
                                        </p>
                                      </div>
                                    </div>
                                    <button className='close-slideshow'>
                                      <i className='icon-close' />
                                    </button>
                                  </div>
                                  <div className='slideshow-box d-flex'>
                                    <div className='slideshow-imgbox d-flex align-items-center'>
                                      <img
                                        src={slidePlaceHolder}
                                        alt='Slide Show'
                                      />
                                    </div>
                                    <div className='slideshow-cont'>
                                      <div className='slideshow-cont-top'>
                                        <h3 className='tag-link'>
                                          <a
                                            href={'http://google.com'}
                                            className='url-txt'
                                          >
                                            Protect Your UAN With CENTURO
                                          </a>
                                        </h3>
                                        <p>
                                          by Michelle Phan 2059626 views An easy
                                          fun way to mattify your nail polish
                                          with just steam! Who else is going to
                                          try this out? Make sure your nails are
                                          wet, either nail polish or top coat.
                                          Quickly …
                                        </p>
                                      </div>
                                    </div>
                                    <button className='close-slideshow'>
                                      <i className='icon-close' />
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <div className='btn-icon btn-icon-primary btn-bedcrumb-act btn-file'>
                                        <input
                                          type='file'
                                          id='imgFileIDVi'
                                          multiple=''
                                        />
                                        <label htmlFor='imgFileIDVi'>
                                          {' '}
                                          Add Video
                                        </label>
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please Select videos
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Prodcut Feed Ends Here */}

                        {/* Conversion tracking Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Conversion tracking</h5>
                                <h6 className='d-flex align-items-center'>
                                  Select the activity or conversion that
                                  represents a successful conversion
                                </h6>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel form-panel-group-add'>
                                <div className='row'>
                                  <div className='card-header-top card-header-group col-6'>
                                    <div className='card-lt-pane'>
                                      <h6>Floodlight activity</h6>
                                      <p className='d-flex align-items-center'>
                                        PC~_TT~AS_TD~Click-To-Retailer_PP~Product…
                                      </p>
                                    </div>
                                  </div>
                                  <div className='card-header-top card-header-group col-6'>
                                    <div className='card-lt-pane'>
                                      <h6>Floodlight activity ID</h6>
                                      <p className='d-flex align-items-center'>
                                        11358800
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className='row align-items-center'>
                                  <div className='form-item input-group-label input-group-label-end-box col-box-3'>
                                    <div className='label-icon'>
                                      <label>Post-click window</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='X.XX'
                                      />
                                      <span className='input-group-text inr-text rs-text'>
                                        Days
                                      </span>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label input-group-label-end-box col-box-3'>
                                    <div className='label-icon'>
                                      <label>Post-click window</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder=''
                                      />
                                      <span className='input-group-text inr-text rs-text'>
                                        Days
                                      </span>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='media-btn'>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      <i className='icon-delete' />
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> Add
                                  conversion Floodlight activities
                                </button>
                              </div>
                            </div>

                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Conversions</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Count all conversions'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h6>
                                  Select the attribution model to use for
                                  conversion measurement
                                </h6>
                              </div>
                            </div>

                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Conversions</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Primary model'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Conversion tracking Ends Here */}

                        <Card>
                          <CardHeader>
                            <h5>Ad group name</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Ad group name</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      name='campaignName'
                                      value={createCampignData?.campaignName}
                                      id='text'
                                      placeholder='Type ad group name'
                                      onChange={onChangeHandler}
                                    />
                                  </div>
                                  {/* <span className="error" id="emailError">Please enter valid email id</span> */}
                                  {/* <small className="select-time">0 / 10</small> */}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Ad format Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Ad format</h5>
                                <p>
                                  Choose which products from your Google
                                  Merchant Center account can show below ads in
                                  this ad group (112558730 - Samsung.com).
                                </p>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            All products
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Specific products
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Custom label
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            No products
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Ad format Ends Here */}

                        <Card>
                          <CardHeader>
                            <h5>Bid strategy</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item input-group-label col-5'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Target CPM</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-rupee' />
                                      </span>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='X.XX'
                                        name='kpi goal'
                                        onChange={onChangeHandler}
                                      />
                                      <span className='input-group-text inr-text rs-text'>
                                        INR
                                      </span>
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5>Ad name</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Ad name</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Type ad name'
                                        name='kpi goal'
                                        onChange={onChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Video Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Video</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='search-bar-cont search-bar-border'>
                                <div className='search'>
                                  <div className='search-close'>
                                    <input
                                      type='text'
                                      placeholder='Enter YouTube URL'
                                    />
                                    <button className='close-btn'>
                                      <i className='icon-close' />
                                    </button>
                                  </div>
                                  <button className='btn-icon btn-icon-primary'>
                                    <i className='icon-search' />
                                  </button>
                                </div>
                              </div>
                              <div className='card-body'>
                                <div className='slideshow-box-group-rept'>
                                  <div className='slideshow-box d-flex'>
                                    <div className='slideshow-imgbox d-flex align-items-center'>
                                      <img
                                        src={slidePlaceHolder}
                                        alt='Slide Show'
                                      />
                                    </div>
                                    <div className='slideshow-cont'>
                                      <div className='slideshow-cont-top'>
                                        <h3 className='tag-link'>
                                          <a
                                            href={'http://google.com'}
                                            className='url-txt'
                                          >
                                            Protect Your UAN With CENTURO
                                          </a>
                                        </h3>
                                        <p>
                                          by Michelle Phan 2059626 views An easy
                                          fun way to mattify your nail polish
                                          with just steam! Who else is going to
                                          try this out? Make sure your nails are
                                          wet, either nail polish or top coat.
                                          Quickly …
                                        </p>
                                      </div>
                                      <div className='slideshow-cont-bottom'>
                                        <button
                                          type='button'
                                          className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                    <button className='close-slideshow'>
                                      <i className='icon-close' />
                                    </button>
                                  </div>
                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <div className='btn-icon btn-icon-primary btn-bedcrumb-act btn-file'>
                                        <input
                                          type='file'
                                          id='imgFileIDVi'
                                          multiple=''
                                        />
                                        <label htmlFor='imgFileIDVi'>
                                          {' '}
                                          Ad format
                                        </label>
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please Select videos
                                      </span>
                                    </div>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-grey-primary'
                                    >
                                      Skippable
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Video Ends Here */}

                        <Card>
                          <CardHeader>
                            <h5>URL</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>
                                  Display URL
                                </h6>
                                <div className='form-item'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Display URL</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Enter URL'
                                        name='kpi goal'
                                        onChange={onChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <h6 className='card-header-title'>
                                  Landing page URL
                                </h6>
                                <div className='form-item'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Landing page URL</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Enter URL'
                                        name='kpi goal'
                                        onChange={onChangeHandler}
                                      />
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Companion banner Start Hear */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Companion banner</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            Use auto generated image from your
                                            channel banner (recommended)
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-12 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault51'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault51'
                                          >
                                            Upload my own image
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='media-btn'>
                                <div className='file-error'>
                                  <div className='btn-icon btn-icon-primary btn-bedcrumb-act btn-file'>
                                    <input
                                      type='file'
                                      id='imgFileIDVi'
                                      multiple=''
                                    />
                                    <label htmlFor='imgFileIDVi'>
                                      {' '}
                                      Upload or assign companion banner
                                    </label>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please Upload or assign companion banner
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Companion banner Ends Hear */}

                        <Card>
                          <CardHeader>
                            <h5>
                              Call to action{' '}
                              <span className='opt-text'>(Optional)</span>
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>
                                  Call to action
                                </h6>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Call to action</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='Type call to action'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                  <small className='select-time'>0 / 10</small>
                                </div>
                                <h6 className='card-header-title'>Headline</h6>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Headline</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='Type cHeadline'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                  <small className='select-time'>0 / 10</small>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/* Insertion order name Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Insertion order name</h5>
                              </div>
                              <div className='me-auto card-switch-rigth'>
                                <div className='form-panel select-with-side-label'>
                                  <div className='form-item'>
                                    <div className='select-box'>
                                      <select name='drinks' required=''>
                                        <option
                                          value=''
                                          disabled=''
                                          selected=''
                                        >
                                          Draft
                                        </option>
                                        <option value=''>INR</option>
                                        <option value='coffee'>
                                          Impressions
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-9'>
                                    <div className='label-icon'>
                                      <label>Insertion order name</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='text'
                                        id='text'
                                        placeholder='Type insertion order name'
                                      />{' '}
                                    </div>
                                    {/* <span className="error" id="emailError">Please enter valid email id</span>
                                                                <small className="select-time">0 / 10</small> */}
                                  </div>
                                  <div class='col-3'>
                                    <button class='btn-icon btn-icon-primary'>
                                      <i class='icon-plus'></i>
                                      Create
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </div>
                        </div>
                        {/* Insertion order name Ends Here */}

                        {/* Flight dates Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Flight dates</h5>
                              </div>
                            </div>
                            <div className='card-body'>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>Start date</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>End date</label>
                                    </div>
                                    <div className='input-group time'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Flight dates Ends Here */}

                        {/* Line item cretive Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top'>
                              <div className='card-lt-pane'>
                                <h5>Line Item Creatives</h5>
                              </div>
                            </div>

                            <div className='hide-show'>
                              <div className='card-body'>
                                <div className='carosuel-accordion-full'>
                                  <section className='accordion-primary-cont'>
                                    <article className='accordion-data-cont'>
                                      <Accordion defaultActiveKey='0'>
                                        <Accordion.Item eventKey='0'>
                                          <Accordion.Header>
                                            <div className='accordion-data-icon'>
                                              <i className='icon-double-menu' />
                                            </div>
                                            <div className='accordion-data-img'>
                                              <img
                                                src={accorPlaceHolder}
                                                alt='Slide Show'
                                              />
                                            </div>
                                            <div className='accordion-data'>
                                              <h4>AdobeStock_130700352.jpeg</h4>
                                              <p>
                                                Enter headline, description and
                                                URL
                                              </p>
                                            </div>
                                          </Accordion.Header>

                                          <Accordion.Body>
                                            <div className='accordion-body-inner'>
                                              <div className='slideshow-box d-flex'>
                                                <div className='slideshow-imgbox d-flex align-items-center'>
                                                  <img
                                                    src={slidePlaceHolder}
                                                    alt='Slide Show'
                                                  />
                                                </div>
                                                <div className='slideshow-cont'>
                                                  <div className='slideshow-cont-top'>
                                                    <h3>
                                                      AdobeStock_130700352.jpeg
                                                    </h3>
                                                    <p>1600 x 400</p>
                                                    <span
                                                      className='error'
                                                      id='emailError'
                                                    >
                                                      Please enter valid email
                                                      id
                                                    </span>
                                                  </div>
                                                </div>
                                                <button className='close-slideshow'>
                                                  <i className='icon-close' />
                                                </button>
                                              </div>

                                              <div className='card-header-top d-flex'>
                                                <div className='card-lt-pane'>
                                                  <h6 className='mt-0'>
                                                    Landing page URL{' '}
                                                  </h6>
                                                </div>
                                              </div>
                                              <div className='card-body'>
                                                <div className='card-form form-panel'>
                                                  <div className='row align-items-center'>
                                                    <div className='form-item'>
                                                      <div className='label-icon'>
                                                        <label>
                                                          Landing page URL
                                                        </label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <input
                                                          type='text'
                                                          placeholder='https://www.samsung.com/in?cid=in_pd_display-dmp_digit--dv360_…'
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className='card-body'>
                                                <div className='card-header-top d-flex'>
                                                  <div className='card-lt-pane'>
                                                    <h6 className='d-flex align-items-center'>
                                                      Land Scape Image (1.91:1)
                                                    </h6>
                                                    <p>Add upto 15 images</p>
                                                  </div>
                                                </div>

                                                <div className='form-panel-group-add'>
                                                  <div className='media-list media-landscape'>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                            <div className='media-btn'>
                                                              <button className='btn-icon btn-icon-primary'>
                                                                <i class='icon-plus'></i>
                                                                Add Images
                                                              </button>
                                                            </div>
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className='add-section'>
                                                  <button className='btn-add-plus'>
                                                    <i className='icon-plus-circle' />{' '}
                                                    LandScape mage
                                                  </button>
                                                </div>

                                                <div className='divder-form'></div>
                                              </div>

                                              <div className='card-body'>
                                                <div className='card-header-top d-flex'>
                                                  <div className='card-lt-pane'>
                                                    <h6 className='d-flex align-items-center'>
                                                      Land Scape Image (1.91:1)
                                                    </h6>
                                                    <p>Add upto 15 images</p>
                                                  </div>
                                                </div>

                                                <div className='form-panel-group-add media-list-col-3'>
                                                  <div className='media-list media-landscape'>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediabox-cont text-center'>
                                                          <h3>
                                                            Wide{' '}
                                                            <span>
                                                              (1.91:1)
                                                            </span>
                                                          </h3>
                                                        </div>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className='media-card'>
                                                      <div className='select-media'>
                                                        <div className='mediaimg-box'>
                                                          <div className='media-img'>
                                                            <img
                                                              src={
                                                                atdplaceholder
                                                              }
                                                              alt=''
                                                            />
                                                          </div>
                                                          <div className='media-social'>
                                                            <i class='icon-delete'></i>
                                                          </div>
                                                        </div>
                                                        <div className='mediabox-cont'>
                                                          <h3>Image-21.png</h3>
                                                          <p>
                                                            800 x 535px • 56 KB
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className='add-section'>
                                                  <button className='btn-add-plus'>
                                                    <i className='icon-plus-circle' />{' '}
                                                    LandScape mage
                                                  </button>
                                                </div>

                                                <div className='divder-form'></div>
                                              </div>

                                              <div className='d-flex justify-content-end'>
                                                <button
                                                  type='button'
                                                  className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                >
                                                  <i className='icon-delete' />
                                                  Remove
                                                </button>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </article>
                                  </section>
                                  <div className='dropdown-filter'>
                                    <button
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                      type='button'
                                      id='defaultDropdown'
                                      data-bs-toggle='dropdown'
                                      data-bs-auto-close='true'
                                      aria-expanded='false'
                                    >
                                      Assign creatives
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Line item cretive Ends Here */}

                        {/* Ads Start here */}
                        <Card>
                          <CardHeader>
                            <h5>Ads</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>Final URL</h6>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Final URL</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='Enter URL'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                              <div class='info-icon-body mt-2'>
                                <div class='more-info-text'>
                                  <p>
                                    This will be used to suggest headlines and
                                    descriptions
                                  </p>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>
                                  Display path
                                </h6>
                                <p className='mb-2'>www.example.com</p>

                                <div className='form-item col-6 sword-line'>
                                  <div className='s-line'>/</div>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='0'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                  <small className='select-time'>0 / 15</small>
                                </div>

                                <div className='form-item col-6 sword-line'>
                                  <div className='s-line'>/</div>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='0'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                  <small className='select-time'>0 / 15</small>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          <div className='divder-form'></div>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <h6 className='card-header-title'>Calls</h6>
                              <p>Add a phone number</p>

                              <section className='group-section-data'>
                                <CardBody>
                                  <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                    <div className='col-8 d-flex flex-wrap'>
                                      <div className='form-check-box col-6 mb-2'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='inlineCheckbox9'
                                          defaultValue='Display'
                                          name='creative'
                                          onChange={onChangeHandler}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='inlineCheckbox9'
                                        >
                                          <div className='label-multitext'>
                                            (201) 555-0123
                                          </div>
                                        </label>

                                        <span className='icon-click'>
                                          <a href='#' className='outline-btn'>
                                            <i className='icon-edit'></i>
                                          </a>
                                        </span>
                                      </div>

                                      <div className='form-check-box col-6 mb-2'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='inlineCheckbox11'
                                          defaultValue='Audio'
                                          name='creative'
                                          onChange={onChangeHandler}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='inlineCheckbox11'
                                        >
                                          <div className='label-multitext'>
                                            (201) 555-0123
                                          </div>
                                        </label>
                                        <span className='icon-click'>
                                          <a href='#' className='outline-btn'>
                                            <i className='icon-edit'></i>
                                          </a>
                                        </span>
                                      </div>
                                      <div className='form-check-box col-6 mb-2'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='inlineCheckbox11'
                                          defaultValue='Audio'
                                          name='creative'
                                          onChange={onChangeHandler}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='inlineCheckbox11'
                                        >
                                          <div className='label-multitext'>
                                            (201) 555-0123
                                          </div>
                                        </label>
                                        <span className='icon-click'>
                                          <a href='#' className='outline-btn'>
                                            <i className='icon-edit'></i>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                    <span class='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </CardBody>
                                <div class='info-icon-body mt-2'>
                                  <div className='more-info-icon'>
                                    <i className='icon-exclam'></i>
                                  </div>
                                  <div class='more-info-text'>
                                    <p>
                                      Because you selected phone calls as a
                                      campaign goal, add a call asset to use
                                      with your ads.
                                    </p>
                                  </div>
                                </div>
                              </section>

                              <div className='form-panel-group-add'>
                                <section className='group-section-data'>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-6'>
                                      <h6>Edit call</h6>
                                      <div className='label-icon'>
                                        <label>Phone number</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='(201) 555-0123'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-6'>
                                      <h6>Conversion action</h6>
                                      <div className='label-icon'>
                                        <label>Conversion action</label>
                                      </div>

                                      <SimpleSelectBox
                                        name='Frequency limit exposure'
                                        placeholder='Lifetime of this campaign'
                                        options={[
                                          'Months',
                                          'Weeks',
                                          'Days',
                                          'Hours',
                                          'Minutes',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>

                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                  <div class='info-icon-body mt-2 mb-3'>
                                    <div class='more-info-text'>
                                      <p>Example: (201) 555-0123</p>
                                    </div>
                                  </div>
                                </section>

                                <section className='group-section-data'>
                                  <h6>Call reporting is turned on</h6>
                                  <div className='form-panel-group-add'>
                                    <div className='remove-box-group d-flex'>
                                      <div className='row align-items-center'>
                                        <div className='form-item col-box-3'>
                                          <div className='label-icon'>
                                            <label>Select Days</label>
                                          </div>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure'
                                            placeholder='Lifetime of this campaign'
                                            options={[
                                              'Months',
                                              'Weeks',
                                              'Days',
                                              'Hours',
                                              'Minutes',
                                            ]}
                                            onChangeHandler={onChangeHandler}
                                          ></SimpleSelectBox>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div className='form-item input-group-label col-box-3'>
                                          <div className='label-icon'>
                                            <label>Start time</label>
                                          </div>
                                          <div className='input-group input-width-less'>
                                            <span className='input-group-text rs-text'>
                                              <i className='icon-clock' />
                                            </span>
                                            <input
                                              type='text'
                                              id='datepicker'
                                              placeholder='00:00'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div className='form-item input-group-label col-box-3'>
                                          <div className='label-icon'>
                                            <label>End time</label>
                                          </div>
                                          <div className='input-group input-width-less'>
                                            <span className='input-group-text rs-text'>
                                              <i className='icon-clock' />
                                            </span>
                                            <input
                                              type='text'
                                              id='datepicker1'
                                              placeholder='00:00'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                      <div className='media-btn'>
                                        <button
                                          type='button'
                                          className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                        >
                                          <i className='icon-delete' />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='add-section'>
                                    <button className='btn-add-plus'>
                                      <i className='icon-plus-circle' /> Add
                                      schedule
                                    </button>
                                  </div>

                                  <div class='info-icon-body mt-2 mb-3'>
                                    <div class='more-info-text'>
                                      <p>
                                        Based on account time zone: (GMT+05:30)
                                        India Standard Time
                                      </p>
                                    </div>
                                  </div>
                                </section>

                                <div className='media-btn'>
                                  <div className='file-error'>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      Save
                                    </button>
                                  </div>
                                  <button
                                    type='button'
                                    className='btn-icon btn-icon-primary btn-grey-primary'
                                  >
                                    Cencel
                                  </button>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  New Calls
                                </button>
                              </div>
                            </div>
                          </CardBody>
                          <div className='divder-form'></div>

                          <CardBody>
                            <div className='card-form form-panel'>
                              <h6 className='card-header-title'>Lead forms</h6>
                              <p>Add a form</p>

                              <section className='group-section-data'>
                                <CardBody>
                                  <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                    <div className='col-8 d-flex flex-wrap'>
                                      <div className='form-check-box col-6 mb-2'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='inlineCheckbox11'
                                          defaultValue='Audio'
                                          name='creative'
                                          onChange={onChangeHandler}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='inlineCheckbox11'
                                        >
                                          <div className='label-multitext'>
                                            (201) 555-0123
                                          </div>
                                        </label>
                                        <span className='icon-click'>
                                          <a href='#' className='outline-btn'>
                                            <i className='icon-edit'></i>
                                          </a>
                                        </span>
                                      </div>
                                      <div className='form-check-box col-6 mb-2'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='inlineCheckbox11'
                                          defaultValue='Audio'
                                          name='creative'
                                          onChange={onChangeHandler}
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='inlineCheckbox11'
                                        >
                                          <div className='label-multitext'>
                                            (201) 555-0123
                                          </div>
                                        </label>
                                        <span className='icon-click'>
                                          <a href='#' className='outline-btn'>
                                            <i className='icon-edit'></i>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                    <span class='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div class='info-icon-body mt-2'>
                                    <div className='more-info-icon'>
                                      <i className='icon-exclam'></i>
                                    </div>
                                    <div class='more-info-text'>
                                      <p>
                                        Because you selected leads as a campaign
                                        goal, you should add a lead form to use
                                        with your ads.
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </section>

                              <div className='form-panel-group-add'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Headline</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='Headline'
                                      />
                                    </div>
                                    <small className='select-time'>
                                      0 / 50
                                    </small>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Business Name</label>
                                    </div>

                                    <SimpleSelectBox
                                      name='Frequency limit exposure'
                                      placeholder='Business'
                                      options={[
                                        'Months',
                                        'Weeks',
                                        'Days',
                                        'Hours',
                                        'Minutes',
                                      ]}
                                      onChangeHandler={onChangeHandler}
                                    ></SimpleSelectBox>
                                    <small className='select-time'>
                                      0 / 50
                                    </small>

                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>

                                  <div className='form-item col-12 mt-4 mb-3'>
                                    <div className='label-icon'>
                                      <label>Description</label>
                                    </div>
                                    <div className='input-sizer stacked'>
                                      <TextareaAutosize />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      15 / 200
                                    </small>
                                  </div>
                                </div>

                                <CardHeader>
                                  <h6>Questions</h6>
                                  <p>
                                    To change the information you ask for after
                                    saving your campaign, you will have to
                                    create a new form
                                  </p>
                                </CardHeader>

                                <CardBody>
                                  <div className='action-btn-cont'>
                                    <div className='action-btn-both action-btn-no-icon row'>
                                      <h6 className='card-header-title'>
                                        Contact information
                                      </h6>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Name
                                            </div>
                                          </label>
                                        </div>
                                        <div className='dv-from-check-field'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-no-icon row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault50'
                                                    defaultChecked=''
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50'
                                                  >
                                                    <div className='label-multitext'>
                                                      <p className='mt-0'>
                                                        {' '}
                                                        First name and last name
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault51'
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault51'
                                                  >
                                                    <div className='label-multitext'>
                                                      <p className='mt-0'>
                                                        {' '}
                                                        Full name
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              City
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Phone number
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Country
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Email
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Street address
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='action-btn-both action-btn-no-icon row mt-4'>
                                      <h6 className='card-header-title'>
                                        Work information
                                      </h6>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Company name
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Work email
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Job title
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-6 px-0'>
                                        <div className='form-check-box'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            name='flexRadioDefault'
                                            id='flexRadioDefault50'
                                            defaultChecked=''
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioDefault50'
                                          >
                                            <div className='label-multitext'>
                                              Work phone number
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <h6 className='card-header-title'>
                                    Additional information
                                  </h6>
                                  <div className='card-form-days dv-card-form-days mt-3'>
                                    <div className='row align-items-center'>
                                      <div className='action-btn-cont'>
                                        <div
                                          className='action-btn-both action-btn-no-icon row'
                                          name='Frequency cap'
                                          onChange={onChangeHandler}
                                        >
                                          <div className='col-12 d-flex px-0'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                name='Frequency cap'
                                                id='flexRadioDefault3hh'
                                                value='limit frequency to'
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor='flexRadioDefault3hh'
                                              >
                                                Are you over
                                              </label>
                                            </div>
                                            <div className='form-item d-flex align-items-center me-3'>
                                              <SimpleSelectBox
                                                name='Frequency limit exposure'
                                                placeholder='0'
                                                options={[
                                                  'Months',
                                                  'Weeks',
                                                  'Days',
                                                  'Hours',
                                                  'Minutes',
                                                ]}
                                                onChangeHandler={
                                                  onChangeHandler
                                                }
                                              ></SimpleSelectBox>
                                              <label>years of age? </label>
                                              <span
                                                className='error px-0'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <h6 className='card-header-title'>
                                    Qualifying questions
                                  </h6>
                                  <div className='row align-items-center'>
                                    <div className='form-item'>
                                      <div className='label-icon'>
                                        <label>Language</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='Frequency limit exposure'
                                        placeholder='English'
                                        options={[
                                          'Months',
                                          'Weeks',
                                          'Days',
                                          'Hours',
                                          'Minutes',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <div className='form-panel-group-add ques-pannel'>
                                    <div className='ques-header'>
                                      <div className='s-line'>=</div>
                                      <CardHeader>
                                        <p>Question</p>
                                        <h6>
                                          Which vehicle make are you interested
                                          in?
                                        </h6>
                                      </CardHeader>
                                    </div>

                                    <div className='row align-items-center m-3'>
                                      <h6>Answer type</h6>
                                      <div className='form-item'>
                                        <div className='label-icon'>
                                          <label>Multiple choice</label>
                                        </div>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Multiple-choice'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                          onChangeHandler={onChangeHandler}
                                        ></SimpleSelectBox>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                    </div>

                                    <div className='row align-items-center m-3 mb-0'>
                                      <h6>Answer type (up to 12)</h6>

                                      <div className='ques-header-rept row'>
                                        <div className='col-8'>
                                          <div className='form-item ques-rept-box'>
                                            <div className='ques-wrap-remove'>
                                              <div className='s-line'>=</div>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='Headline'
                                                />
                                              </div>
                                              <small className='select-time'>
                                                0 / 50
                                              </small>
                                            </div>
                                            <div className='media-btn'>
                                              <button
                                                type='button'
                                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                              >
                                                <i className='icon-delete'></i>
                                                Remove answer
                                              </button>
                                            </div>
                                          </div>
                                          <div className='form-item ques-rept-box'>
                                            <div className='ques-wrap-remove'>
                                              <div className='s-line'>=</div>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='Headline'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                              <small className='select-time'>
                                                0 / 50
                                              </small>
                                            </div>
                                            <div className='media-btn'>
                                              <button
                                                type='button'
                                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                              >
                                                <i className='icon-delete'></i>
                                                Remove answer
                                              </button>
                                            </div>
                                          </div>
                                          <div className='form-item ques-rept-box'>
                                            <div className='ques-wrap-remove'>
                                              <div className='s-line'>=</div>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='Headline'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                              <small className='select-time'>
                                                0 / 50
                                              </small>
                                            </div>
                                            <div className='media-btn'>
                                              <button
                                                type='button'
                                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                              >
                                                <i className='icon-delete'></i>
                                                Remove answer
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='add-section'>
                                          <button
                                            type='button'
                                            className='outline-btn'
                                          >
                                            Add answer
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='add-section'>
                                    <button className='btn-add-plus'>
                                      <i className='icon-plus-circle' />
                                      Question
                                    </button>
                                  </div>
                                </CardBody>

                                <CardBody>
                                  <div className='row align-items-center'>
                                    <div className='form-item'>
                                      <div className='label-icon'>
                                        <label>Privacy policy URL</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Type URL'
                                          name='Planned spend'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>

                                <CardHeader>
                                  <h6>Background image</h6>
                                </CardHeader>
                                <CardBody>
                                  <div className='slideshow-box-group-rept'>
                                    <div className='slideshow-box d-flex'>
                                      <div className='slideshow-imgbox d-flex align-items-center'>
                                        <img
                                          src={slidePlaceHolder}
                                          alt='Slide Show'
                                        />
                                      </div>
                                      <div className='slideshow-cont'>
                                        <div className='slideshow-cont-top'>
                                          <p>
                                            <i className='icon-images'></i>
                                            1600 x 400
                                          </p>
                                        </div>
                                        <div className='slideshow-cont-bottom'>
                                          <button
                                            type='button'
                                            className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                      <button className='close-slideshow'>
                                        <i className='icon-close' />
                                      </button>
                                    </div>
                                  </div>
                                </CardBody>

                                <div className='divder-form'></div>

                                <section className='group-section-data'>
                                  <CardHeader>
                                    <h6>Create form submission message</h6>
                                  </CardHeader>
                                  <CardBody>
                                    <div className='row align-items-center'>
                                      <div className='form-item col-12'>
                                        <div className='label-icon'>
                                          <label>Headline</label>
                                        </div>
                                        <div className='input-group'>
                                          <input
                                            type='email'
                                            id='email'
                                            placeholder='Thank you'
                                            name='Planned spend'
                                          />
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                        <small className='select-time'>
                                          0 / 50
                                        </small>
                                      </div>
                                      <div className='form-item col-12'>
                                        <div className='label-icon'>
                                          <label>Description</label>
                                        </div>
                                        <div className='input-sizer stacked'>
                                          <TextareaAutosize placeholder="We'll contact you soon" />
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                        <small className='select-time'>
                                          0 / 200
                                        </small>
                                      </div>
                                      <div className='form-item col-12'>
                                        <div className='label-icon'>
                                          <label>Call-to-action</label>
                                        </div>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Visit site'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                          onChangeHandler={onChangeHandler}
                                        ></SimpleSelectBox>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div className='form-item col-12'>
                                        <div className='label-icon'>
                                          <label>Call to action URL</label>
                                        </div>
                                        <div className='input-group'>
                                          <input
                                            type='email'
                                            id='email'
                                            placeholder='Type URL'
                                            name='Planned spend'
                                          />
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                    </div>
                                  </CardBody>
                                </section>

                                <div className='divder-form'></div>

                                <section className='group-section-data'>
                                  <CardHeader>
                                    <h6>Select a call-to-action for your ad</h6>
                                    <p>
                                      Select a compelling call-to-action that
                                      empowers people to engage with your
                                      business or service
                                    </p>
                                  </CardHeader>
                                  <CardBody>
                                    <div className='row align-items-center'>
                                      <div className='form-item'>
                                        <div className='label-icon'>
                                          <label>Call-to-action</label>
                                        </div>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Visit site'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                          onChangeHandler={onChangeHandler}
                                        ></SimpleSelectBox>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div className='form-item'>
                                        <div className='label-icon'>
                                          <label>
                                            Call to action description
                                          </label>
                                        </div>
                                        <div className='input-sizer stacked'>
                                          <TextareaAutosize placeholder='Type description' />
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                        <small className='select-time'>
                                          0 / 30
                                        </small>
                                      </div>
                                    </div>
                                  </CardBody>
                                </section>

                                <div className='divder-form'></div>

                                <section className='group-section-data'>
                                  <CardBody>
                                    <div className='carosuel-accordion-full'>
                                      <section className='accordion-primary-cont'>
                                        <article className='accordion-data-cont'>
                                          <Accordion>
                                            <Accordion.Item eventKey='0'>
                                              <Accordion.Header>
                                                <div className='accordion-data'>
                                                  <h4>Lead delivery option</h4>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className='accordion-body-inner'>
                                                  <CardHeader>
                                                    <h6 className='mt-0'>
                                                      Download collected leads
                                                      in ad extensions table
                                                    </h6>
                                                    <p>
                                                      You can only download
                                                      leads that've been
                                                      collected within the last
                                                      30 days
                                                    </p>
                                                    <h6>
                                                      Webhook integration
                                                      (optional)
                                                    </h6>
                                                    <p>
                                                      Send lead form data
                                                      directly to your CDM or
                                                      CRM software in real time.
                                                      Otherwise, you can
                                                      download data within the
                                                      last 30 days from the
                                                      extensions table.
                                                    </p>
                                                  </CardHeader>
                                                  <CardBody>
                                                    <div className='row align-items-center'>
                                                      <div className='form-item'>
                                                        <div className='label-icon'>
                                                          <label>
                                                            Webhook URL
                                                          </label>
                                                        </div>
                                                        <div className='input-group'>
                                                          <input
                                                            type='email'
                                                            id='email'
                                                            placeholder='Type URL'
                                                            name='Planned spend'
                                                          />
                                                        </div>
                                                        <span
                                                          className='error'
                                                          id='emailError'
                                                        >
                                                          Please enter valid
                                                          email id
                                                        </span>
                                                      </div>
                                                      <div className='form-item'>
                                                        <div className='label-icon'>
                                                          <label>Key</label>
                                                        </div>
                                                        <div className='input-group'>
                                                          <input
                                                            type='email'
                                                            id='email'
                                                            placeholder='Type key'
                                                            name='Planned spend'
                                                          />
                                                        </div>
                                                        <span
                                                          className='error'
                                                          id='emailError'
                                                        >
                                                          Please enter valid
                                                          email id
                                                        </span>
                                                        <small className='select-time'>
                                                          0 / 50
                                                        </small>
                                                      </div>
                                                    </div>
                                                  </CardBody>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey='1'>
                                              <Accordion.Header>
                                                <div className='accordion-data'>
                                                  <h4>Lead form type</h4>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className='accordion-body-inner'>
                                                  <CardHeader>
                                                    <p>
                                                      Optimize for leads with
                                                      higher intent or for more
                                                      leads overall.
                                                    </p>
                                                  </CardHeader>
                                                  <CardBody>
                                                    <div className='row align-items-center'>
                                                      <div className='action-btn-cont'>
                                                        <div className='action-btn-both action-btn-no-icon row'>
                                                          <div className='col-12 px-0'>
                                                            <div className='form-check-box'>
                                                              <input
                                                                className='form-check-input'
                                                                type='radio'
                                                                name='flexRadioDefault'
                                                                id='flexRadioDefault50'
                                                                defaultChecked=''
                                                              />
                                                              <label
                                                                className='form-check-label'
                                                                htmlFor='flexRadioDefault50'
                                                              >
                                                                <div className='label-multitext'>
                                                                  More volume
                                                                  <p>
                                                                    This may
                                                                    result in
                                                                    leads with
                                                                    lower
                                                                    intent.
                                                                  </p>
                                                                </div>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className='col-12 px-0'>
                                                            <div className='form-check-box'>
                                                              <input
                                                                className='form-check-input'
                                                                type='radio'
                                                                name='flexRadioDefault'
                                                                id='flexRadioDefault51'
                                                              />
                                                              <label
                                                                className='form-check-label'
                                                                htmlFor='flexRadioDefault51'
                                                              >
                                                                <div className='label-multitext'>
                                                                  More qualified
                                                                  <p>
                                                                    This may
                                                                    result in
                                                                    fewer leads
                                                                    or a higher
                                                                    cost per
                                                                    lead.
                                                                  </p>
                                                                </div>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </CardBody>
                                                  <div className='divder-form'></div>
                                                  <CardHeader>
                                                    <h6>
                                                      You acknowledge and agree
                                                      that the following terms
                                                      govern your use of lead
                                                      forms:
                                                    </h6>
                                                    <p>
                                                      You will request and use
                                                      information from users (1)
                                                      only as reasonably
                                                      necessary for the limited
                                                      purpose described in your
                                                      lead forms and (2) in
                                                      accordance with your
                                                      privacy policy and any
                                                      additional disclosures
                                                      that you include in your
                                                      lead forms. You will not
                                                      sell the information
                                                      provided by users or
                                                      otherwise abuse that
                                                      information (for example,
                                                      by spamming users with
                                                      repeated phone calls or
                                                      emails). You will provide
                                                      users with clear and
                                                      comprehensive information
                                                      about data collection and
                                                      processing, including any
                                                      sharing of users'
                                                      information with third
                                                      parties, and obtain
                                                      consent for that
                                                      collection and processing
                                                      where legally required.
                                                      You will not license or
                                                      otherwise disclose the
                                                      information you receive
                                                      from users to a data
                                                      broker. You will comply
                                                      with all applicable laws
                                                      and regulations when
                                                      contacting users or
                                                      otherwise using the
                                                      information they provide,
                                                      including any marketing or
                                                      spam regulations that may
                                                      apply.
                                                    </p>
                                                    <h6>
                                                      You also acknowledge and
                                                      agree that the following
                                                      terms govern your use of
                                                      the lead form webhook:
                                                    </h6>
                                                    <p>
                                                      You will comply with all
                                                      webhook instructions
                                                      provided by Google,
                                                      including properly
                                                      configuring an endpoint to
                                                      handle POST requests.
                                                      Google is not responsible
                                                      for errors resulting from
                                                      your misconfiguration of
                                                      the webhook. The webhook
                                                      is provided in beta form.
                                                      Google may discontinue,
                                                      deprecate, or change the
                                                      webhook at any time
                                                      without notice, and Google
                                                      does not guarantee
                                                      availability, delivery of
                                                      data, or technical
                                                      support. Your use of the
                                                      webhook is governed by the
                                                      Google API Terms of
                                                      Service, available at
                                                      https://developers.google.com/terms/
                                                      together with these terms.
                                                    </p>
                                                  </CardHeader>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </article>
                                      </section>
                                    </div>
                                  </CardBody>
                                  <div className='media-btn mt-4'>
                                    <div className='file-error'>
                                      <button
                                        type='button'
                                        className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-grey-primary'
                                    >
                                      Cencel
                                    </button>
                                  </div>
                                </section>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  Lead forms
                                </button>
                              </div>
                            </div>
                          </CardBody>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h6>Headlines 2/15</h6>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center headline-cont-rept'>
                                  <div className='form-item col-6 headline-rept-box'>
                                    <div className='headline-wrap-remove'>
                                      <div className='label-icon'>
                                        <label>Quote*</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Get A Quote Today'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        10 / 30
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='media-btn'>
                                      <button
                                        type='button'
                                        className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                      >
                                        <i className='icon-delete' />
                                      </button>
                                    </div>
                                  </div>

                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Call*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Call Us Today'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 30
                                    </small>
                                  </div>
                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Headline*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Headline'
                                      />
                                    </div>

                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 30
                                    </small>
                                  </div>

                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Headline*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Headline'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 30
                                    </small>
                                  </div>

                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Headline*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Headline'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 30
                                    </small>
                                  </div>

                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Headline*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Headline'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 30
                                    </small>
                                  </div>
                                </div>
                                <div class='info-icon-body mt-2'>
                                  <div class='more-info-text'>
                                    <p>At least 3 headlines are required</p>
                                  </div>
                                </div>
                                <div className='add-section'>
                                  <button className='btn-add-plus'>
                                    <i className='icon-plus-circle' />
                                    Headline
                                  </button>
                                </div>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h6>Descriptions 0/4</h6>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Description*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Description'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      10 / 90
                                    </small>
                                  </div>

                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Description*</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Description'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 90
                                    </small>
                                  </div>
                                </div>
                                <div className='add-section'>
                                  <button className='btn-add-plus'>
                                    <i className='icon-plus-circle' />
                                    Description
                                  </button>
                                </div>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>Images</h6>
                                <p>Add images</p>
                              </div>
                            </div>
                          </CardBody>

                          <CardBody>
                            <div className='hide-show'>
                              <div className='carosuel-accordion-full'>
                                <section className='accordion-primary-cont d-flex'>
                                  <article className='accordion-data-cont'>
                                    <Accordion defaultActiveKey='0'>
                                      <Accordion.Item eventKey='0'>
                                        <Accordion.Header>
                                          <div className='accordion-data-icon'>
                                            <i className='icon-double-menu' />
                                          </div>
                                          <div className='accordion-data-img'>
                                            <img
                                              src={accorPlaceHolder}
                                              alt='Slide Show'
                                            />
                                          </div>
                                          <div className='accordion-data'>
                                            <h4>AdobeStock_130700352.jpeg</h4>
                                            <p>
                                              Enter headline, description and
                                              URL
                                            </p>
                                          </div>
                                        </Accordion.Header>

                                        <Accordion.Body>
                                          <div className='accordion-body-inner'>
                                            <div className='slideshow-box d-flex'>
                                              <div className='slideshow-imgbox d-flex align-items-center'>
                                                <img
                                                  src={slidePlaceHolder}
                                                  alt='Slide Show'
                                                />
                                              </div>
                                              <div className='slideshow-cont'>
                                                <div className='slideshow-cont-top'>
                                                  <h3>
                                                    AdobeStock_130700352.jpeg
                                                  </h3>
                                                  <p>1600 x 400</p>
                                                  <span
                                                    className='error'
                                                    id='emailError'
                                                  >
                                                    Please enter valid email id
                                                  </span>
                                                </div>
                                                <div className='slideshow-cont-bottom'>
                                                  <button
                                                    type='button'
                                                    className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                  >
                                                    Edit
                                                  </button>
                                                  <button
                                                    type='button'
                                                    className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                  >
                                                    Change image
                                                  </button>
                                                </div>
                                              </div>
                                              <button className='close-slideshow'>
                                                <i className='icon-close' />
                                              </button>
                                            </div>

                                            <CardHeader>
                                              <h6>Advanced options</h6>
                                              <p>Asset scheduling</p>
                                              <p>
                                                Select when your assets will be
                                                eligible to show
                                              </p>
                                            </CardHeader>

                                            <CardBody>
                                              <div className='card-form form-panel'>
                                                <div className='row align-items-center'>
                                                  <div className='form-item input-group-label col-6'>
                                                    <div className='label-icon'>
                                                      <label>Start date</label>
                                                    </div>
                                                    <div className='input-group'>
                                                      <span className='input-group-text rs-text'>
                                                        <i className='icon-calendar' />
                                                      </span>
                                                      <input
                                                        type='text'
                                                        id='datepicker'
                                                        placeholder='DD/MM/YYYY'
                                                      />
                                                    </div>
                                                    <span
                                                      className='error'
                                                      id='emailError'
                                                    >
                                                      Please enter valid email
                                                      id
                                                    </span>
                                                  </div>
                                                  <div className='form-item input-group-label col-6'>
                                                    <div className='label-icon'>
                                                      <label>End date</label>
                                                    </div>
                                                    <div className='input-group time'>
                                                      <span className='input-group-text rs-text'>
                                                        <i className='icon-calendar' />
                                                      </span>
                                                      <input
                                                        type='text'
                                                        id='datepicker'
                                                        placeholder='DD/MM/YYYY'
                                                      />
                                                    </div>
                                                    <span
                                                      className='error'
                                                      id='emailError'
                                                    >
                                                      Please enter valid email
                                                      id
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </CardBody>

                                            <CardBody>
                                              <div className='card-form form-panel form-panel-group-add'>
                                                <div className='remove-box-group d-flex'>
                                                  <div className='row align-items-center'>
                                                    <div className='form-item col-box-3'>
                                                      <div className='label-icon'>
                                                        <label>
                                                          Select Days
                                                        </label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <input
                                                          type='email'
                                                          id='email'
                                                          placeholder='All Days'
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                    <div className='form-item input-group-label col-box-3'>
                                                      <div className='label-icon'>
                                                        <label>
                                                          Start time
                                                        </label>
                                                      </div>
                                                      <div className='input-group input-width-less'>
                                                        <span className='input-group-text rs-text'>
                                                          <i className='icon-clock' />
                                                        </span>
                                                        <input
                                                          type='text'
                                                          id='datepicker'
                                                          placeholder='00:00'
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                    <div className='form-item input-group-label col-box-3'>
                                                      <div className='label-icon'>
                                                        <label>End time</label>
                                                      </div>
                                                      <div className='input-group input-width-less'>
                                                        <span className='input-group-text rs-text'>
                                                          <i className='icon-clock' />
                                                        </span>
                                                        <input
                                                          type='text'
                                                          id='datepicker1'
                                                          placeholder='00:00'
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                    <div className='form-item input-group-label col-box-3'>
                                                      <div className='label-icon'>
                                                        <label>End time</label>
                                                      </div>
                                                      <div className='input-group input-width-less'>
                                                        <span className='input-group-text rs-text'>
                                                          <i className='icon-clock' />
                                                        </span>
                                                        <input
                                                          type='text'
                                                          id='datepicker1'
                                                          placeholder='00:00'
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className='media-btn'>
                                                    <button
                                                      type='button'
                                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                    >
                                                      <i className='icon-delete' />
                                                      Remove
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className='add-section'>
                                                <button className='btn-add-plus'>
                                                  <i className='icon-plus-circle' />{' '}
                                                  Add schedule
                                                </button>
                                              </div>
                                              <div class='info-icon-body mt-2 mb-3'>
                                                <div class='more-info-text'>
                                                  <p>
                                                    Based on account time zone:
                                                    (GMT+05:30) India Standard
                                                    Time
                                                  </p>
                                                </div>
                                              </div>
                                            </CardBody>

                                            <div className='d-flex justify-content-end'>
                                              <button
                                                type='button'
                                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                              >
                                                <i className='icon-delete' />
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </article>
                                </section>
                              </div>
                            </div>

                            <div className='media-btn'>
                              <div className='file-error'>
                                <div className='btn-icon btn-file-icon'>
                                  <input
                                    type='file'
                                    id='imgFileIDVi'
                                    multiple=''
                                  />
                                  <i className='icon-plus-circle' />
                                  <label htmlFor='imgFileIDVi'>
                                    Add images
                                  </label>
                                </div>
                                <span className='error' id='emailError'>
                                  Please Select images
                                </span>
                              </div>
                            </div>
                          </CardBody>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h6>Business name</h6>
                              <p>Add business name</p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item'>
                                    <div className='label-icon'>
                                      <label>Business name</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Type business name'
                                      />
                                    </div>
                                    <small className='select-time'>
                                      0 / 25
                                    </small>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h6> Business Logo</h6>
                              <p>Add business Logo</p>
                            </CardHeader>
                            <CardBody>
                              <div className='slideshow-box-group-rept'>
                                <div className='slideshow-box d-flex'>
                                  <div className='slideshow-imgbox d-flex align-items-center'>
                                    <img src={logoSet} alt='cheil ATD' />
                                  </div>
                                  <div className='slideshow-cont'>
                                    <div className='slideshow-cont-top'>
                                      <p>
                                        <i className='icon-images'></i>
                                        1600 x 400
                                      </p>
                                    </div>
                                    <div className='slideshow-cont-bottom'>
                                      <button
                                        type='button'
                                        className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                  <button className='close-slideshow'>
                                    <i className='icon-close' />
                                  </button>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  Add Business Logo
                                </button>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h6>Sitelinks</h6>
                              <p className='mb-2'>
                                Add 4 or more to maximize performance
                              </p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                  <div className='col-8 d-flex flex-wrap'>
                                    <div className='form-check-box col-6 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        <div className='label-multitext'>
                                          Sitelink 1
                                        </div>
                                      </label>

                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>

                                    <div className='form-check-box col-6 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox11'
                                        defaultValue='Audio'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox11'
                                      >
                                        <div className='label-multitext'>
                                          Sitelink 1
                                        </div>
                                      </label>
                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>

                                <div className='form-panel-group-add'>
                                  <h6 className='card-header-title'>
                                    Sitelink 1
                                  </h6>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Sitelink text</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Type text'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 25
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Description line 1*</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Type Description'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 35
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Description line 2*</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Type Description'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 35
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Final URL</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Type URL'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 35
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  Add sitelink
                                </button>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardBody>
                              <h6 className='card-header-title'>
                                Sitelink URL options
                              </h6>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>Tracking Template</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Tracking Template'
                                          name='kpi goal'
                                        />
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div class='info-icon-body mt-2 mb-2'>
                                    <div class='more-info-text'>
                                      <p>
                                        Example:
                                        https://www.trackingtemplate.foo/?url=
                                        {''}
                                        &id=5
                                      </p>
                                    </div>
                                  </div>

                                  <div className='form-item'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>Final URL suffix</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Final URL suffix'
                                          name='kpi goal'
                                        />
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div class='info-icon-body mt-2 mb-2'>
                                    <div class='more-info-text'>
                                      <p>
                                        Example: param1=value1&param2=value2
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>

                            <CardBody>
                              <div className='card-form form-panel'>
                                <h6 className='card-header-title'>
                                  Custom parameters
                                </h6>

                                <div className='row align-items-center mb-3'>
                                  <div className='form-item col-6'>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='{_Name.                   }'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>

                                  <div className='form-item col-6 sword-line'>
                                    <div className='s-line'>=</div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Value'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>

                                <div className='add-section'>
                                  <button className='btn-add-plus'>
                                    <i className='icon-plus-circle' />
                                    Add parameters
                                  </button>
                                </div>
                              </div>

                              <div className='action-btn-both action-btn-both-check row'>
                                <div className='form-check-box'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='inlineCheckbox1'
                                    defaultValue='option1'
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='inlineCheckbox1'
                                  >
                                    Use a different final URL for mobile
                                  </label>
                                </div>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h5>Advanced options</h5>
                              <p>Asset scheduling</p>
                              <p>
                                Select when your assets will be eligible to show
                              </p>
                            </CardHeader>

                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>Start date</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>End date</label>
                                    </div>
                                    <div className='input-group time'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </CardBody>

                            <CardBody>
                              <div className='card-form form-panel form-panel-group-add'>
                                <div className='remove-box-group d-flex'>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-box-3'>
                                      <div className='label-icon'>
                                        <label>Select Days</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='All Days'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item input-group-label col-box-3'>
                                      <div className='label-icon'>
                                        <label>Start time</label>
                                      </div>
                                      <div className='input-group input-width-less'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-clock' />
                                        </span>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='00:00'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item input-group-label col-box-3'>
                                      <div className='label-icon'>
                                        <label>End time</label>
                                      </div>
                                      <div className='input-group input-width-less'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-clock' />
                                        </span>
                                        <input
                                          type='text'
                                          id='datepicker1'
                                          placeholder='00:00'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                  <div className='media-btn'>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      <i className='icon-delete' />
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> Add
                                  schedule
                                </button>
                              </div>
                              <div class='info-icon-body mt-2 mb-3'>
                                <div class='more-info-text'>
                                  <p>
                                    Based on account time zone: (GMT+05:30)
                                    India Standard Time
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                            <div className='media-btn'>
                              <div className='file-error'>
                                <button
                                  type='button'
                                  className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                >
                                  Save
                                </button>
                              </div>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-grey-primary'
                              >
                                Cencel
                              </button>
                            </div>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h6>Callouts</h6>
                              <p>Add more business information</p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                  <div className='col-8 d-flex flex-wrap'>
                                    <div className='form-check-box col-6 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        <div className='label-multitext'>
                                          Callouts 1
                                        </div>
                                      </label>

                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>

                                    <div className='form-check-box col-6 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox11'
                                        defaultValue='Audio'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox11'
                                      >
                                        <div className='label-multitext'>
                                          Callouts 2
                                        </div>
                                      </label>
                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>

                                <div className='form-panel-group-add'>
                                  <h6 className='card-header-title'>
                                    Add new callout
                                  </h6>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Callout text 1</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Callout text 1'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 25
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Callout text 2</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Callout text 2'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 25
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Callout text 3</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Callout text 3'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 25
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Callout text 4</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='Callout text 4'
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 25
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  Add callout text
                                </button>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h5>Advanced options</h5>
                              <p>Asset scheduling</p>
                              <p>
                                Select when your assets will be eligible to show
                              </p>
                            </CardHeader>

                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>Start date</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item input-group-label col-6'>
                                    <div className='label-icon'>
                                      <label>End date</label>
                                    </div>
                                    <div className='input-group time'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-calendar' />
                                      </span>
                                      <input
                                        type='text'
                                        id='datepicker'
                                        placeholder='DD/MM/YYYY'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </CardBody>

                            <CardBody>
                              <div className='card-form form-panel form-panel-group-add'>
                                <div className='remove-box-group d-flex'>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-box-3'>
                                      <div className='label-icon'>
                                        <label>Select Days</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='All Days'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item input-group-label col-box-3'>
                                      <div className='label-icon'>
                                        <label>Start time</label>
                                      </div>
                                      <div className='input-group input-width-less'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-clock' />
                                        </span>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='00:00'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item input-group-label col-box-3'>
                                      <div className='label-icon'>
                                        <label>End time</label>
                                      </div>
                                      <div className='input-group input-width-less'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-clock' />
                                        </span>
                                        <input
                                          type='text'
                                          id='datepicker1'
                                          placeholder='00:00'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>
                                  <div className='media-btn'>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    >
                                      <i className='icon-delete' />
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> Add
                                  schedule
                                </button>
                              </div>
                              <div class='info-icon-body mt-2 mb-3'>
                                <div class='more-info-text'>
                                  <p>
                                    Based on account time zone: (GMT+05:30)
                                    India Standard Time
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                            <div className='media-btn'>
                              <div className='file-error'>
                                <button
                                  type='button'
                                  className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                >
                                  Save
                                </button>
                              </div>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-grey-primary'
                              >
                                Cencel
                              </button>
                            </div>
                          </section>
                        </Card>
                        {/* Ads Ends here */}

                        {/* More asset types Start here */}
                        <Card>
                          <section className='group-section-data'>
                            <CardHeader>
                              <h5>More asset types (0/5)</h5>
                              <p>
                                Improve your ad performance and make your ad
                                more interactive by adding more details about
                                your business and website
                              </p>
                              <h6>Promotions</h6>
                              <p>Add promotions</p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                  <div className='col-8 d-flex flex-wrap'>
                                    <div className='form-check-box col-8 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        <div className='label-multitext'>
                                          Festival - ₹200 off Name ₹200.00
                                        </div>
                                      </label>

                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>

                                <div className='form-panel-group-add'>
                                  <h6 className='card-header-title'>
                                    Add new promotion
                                  </h6>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Occasion</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='None'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='col-12'>
                                      <div class='info-icon-body mt-3 mb-3'>
                                        <div class='more-info-text'>
                                          <p>
                                            Choose an occasion or select a date
                                            range in the Advanced options below.
                                            Otherwise, this asset will be
                                            eligible to show continuously.
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Language</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='English'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Currency</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='INR'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Promotion type</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='Monetary discount'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item input-group-label col-6'>
                                      <div className='label-icon'>
                                        <label>Amount</label>
                                      </div>
                                      <div className='input-group'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-rupee' />
                                        </span>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder=''
                                          name='Planned spend'
                                          onChange={onChangeHandler}
                                        />
                                        <span className='input-group-text inr-text rs-text'>
                                          INR
                                        </span>
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Item</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Type here'
                                          name='Planned spend'
                                          onChange={onChangeHandler}
                                        />
                                      </div>
                                      <small className='select-time'>
                                        0 / 20
                                      </small>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item col-12'>
                                      <div className='label-icon'>
                                        <label>Final URL</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Type URL'
                                          name='Planned spend'
                                          onChange={onChangeHandler}
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Promotion details</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='None'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='col-12 mb-4'>
                                      <CardHeader>
                                        <h6>Displayed promotion dates</h6>
                                        <p>Show the dates of your promotion</p>
                                      </CardHeader>
                                    </div>

                                    <div className='form-item input-group-label col-6'>
                                      <div className='label-icon'>
                                        <label>Start date</label>
                                      </div>
                                      <div className='input-group'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-calendar' />
                                        </span>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='DD/MM/YYYY'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item input-group-label col-6'>
                                      <div className='label-icon'>
                                        <label>End date</label>
                                      </div>
                                      <div className='input-group'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-calendar' />
                                        </span>
                                        <input
                                          type='text'
                                          id='datepicker'
                                          placeholder='DD/MM/YYYY'
                                        />
                                      </div>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>

                                  <CardBody>
                                    <h6 className='card-header-title'>
                                      Sitelink URL options
                                    </h6>
                                    <div className='row align-items-center'>
                                      <div className='form-item'>
                                        <div className='budget-cont'>
                                          <div className='label-icon'>
                                            <label>Tracking Template</label>
                                          </div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Tracking Template'
                                              name='kpi goal'
                                            />
                                          </div>
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div class='info-icon-body mt-2 mb-2'>
                                        <div class='more-info-text'>
                                          <p>
                                            Example:
                                            https://www.trackingtemplate.foo/?url=
                                            {''}
                                            &id=5
                                          </p>
                                        </div>
                                      </div>

                                      <div className='form-item'>
                                        <div className='budget-cont'>
                                          <div className='label-icon'>
                                            <label>Final URL suffix</label>
                                          </div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Final URL suffix'
                                              name='kpi goal'
                                            />
                                          </div>
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div class='info-icon-body mt-2 mb-2'>
                                        <div class='more-info-text'>
                                          <p>
                                            Example: param1=value1&param2=value2
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>

                                  <section className='group-section-data'>
                                    <CardBody>
                                      <h6 className='card-header-title'>
                                        Custom parameters
                                      </h6>
                                      <div className='row align-items-center mb-3'>
                                        <div className='form-item col-6'>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='{_Name.                   }'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>

                                        <div className='form-item col-6 sword-line'>
                                          <div className='s-line'>=</div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Value'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                      <div className='add-section'>
                                        <button className='btn-add-plus'>
                                          <i className='icon-plus-circle' />
                                          Add parameters
                                        </button>
                                      </div>
                                    </CardBody>
                                  </section>

                                  <div className='action-btn-both action-btn-both-check row'>
                                    <div className='form-check-box'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox1'
                                        defaultValue='option1'
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox1'
                                      >
                                        Use a different final URL for mobile
                                      </label>
                                    </div>
                                  </div>

                                  <div className='divder-form'></div>

                                  <section className='group-section-data'>
                                    <CardHeader>
                                      <h5>Advanced options</h5>
                                      <p>Asset scheduling</p>
                                      <p>
                                        Select when your assets will be eligible
                                        to show
                                      </p>
                                    </CardHeader>

                                    <CardBody>
                                      <div className='row align-items-center'>
                                        <div className='form-item input-group-label col-6'>
                                          <div className='label-icon'>
                                            <label>Start date</label>
                                          </div>
                                          <div className='input-group'>
                                            <span className='input-group-text rs-text'>
                                              <i className='icon-calendar' />
                                            </span>
                                            <input
                                              type='text'
                                              id='datepicker'
                                              placeholder='DD/MM/YYYY'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div className='form-item input-group-label col-6'>
                                          <div className='label-icon'>
                                            <label>End date</label>
                                          </div>
                                          <div className='input-group time'>
                                            <span className='input-group-text rs-text'>
                                              <i className='icon-calendar' />
                                            </span>
                                            <input
                                              type='text'
                                              id='datepicker'
                                              placeholder='DD/MM/YYYY'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                    </CardBody>

                                    <CardBody>
                                      <div className='form-panel-group-add'>
                                        <div className='remove-box-group d-flex'>
                                          <div className='row align-items-center'>
                                            <div className='form-item col-box-3'>
                                              <div className='label-icon'>
                                                <label>Select Days</label>
                                              </div>
                                              <div className='input-group'>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder='All Days'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item input-group-label col-box-3'>
                                              <div className='label-icon'>
                                                <label>Start time</label>
                                              </div>
                                              <div className='input-group input-width-less'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-clock' />
                                                </span>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='00:00'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item input-group-label col-box-3'>
                                              <div className='label-icon'>
                                                <label>End time</label>
                                              </div>
                                              <div className='input-group input-width-less'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-clock' />
                                                </span>
                                                <input
                                                  type='text'
                                                  id='datepicker1'
                                                  placeholder='00:00'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                          <div className='media-btn'>
                                            <button
                                              type='button'
                                              className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                            >
                                              <i className='icon-delete' />
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='add-section'>
                                        <button className='btn-add-plus'>
                                          <i className='icon-plus-circle' /> Add
                                          schedule
                                        </button>
                                      </div>
                                      <div class='info-icon-body mt-2 mb-3'>
                                        <div class='more-info-text'>
                                          <p>
                                            Based on account time zone:
                                            (GMT+05:30) India Standard Time
                                          </p>
                                        </div>
                                      </div>
                                    </CardBody>
                                    <div className='media-btn'>
                                      <div className='file-error'>
                                        <button
                                          type='button'
                                          className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <button
                                        type='button'
                                        className='btn-icon btn-icon-primary btn-grey-primary'
                                      >
                                        Cencel
                                      </button>
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  Add Promotions
                                </button>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h5>Prices</h5>
                              <p>Ad prices</p>
                              <h6>Campaign-level prices</h6>
                              <p>
                                Add prices to this campaign. Any prices added
                                here can be used across campaigns.
                              </p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                  <div className='col-8 d-flex flex-wrap'>
                                    <div className='form-check-box col-8 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        <div className='label-multitext'>
                                          Name - ₹0.00
                                        </div>
                                      </label>

                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>

                                <div className='form-panel-group-add'>
                                  <h6 className='card-header-title'>
                                    Add new price
                                  </h6>
                                  <div className='row align-items-center'>
                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Language</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='English'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Type</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='Brands'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Currency</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='USD'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>

                                    <div className='form-item col-6'>
                                      <div className='label-icon'>
                                        <label>Price qualifier</label>
                                      </div>
                                      <SimpleSelectBox
                                        name='kpi'
                                        placeholder='No qualifier'
                                        options={[
                                          'CPM',
                                          'CPC',
                                          'CPA',
                                          'CTR',
                                          'Viewable%',
                                          'CPV',
                                          'CPIAVC',
                                          'other KPI',
                                        ]}
                                        onChangeHandler={onChangeHandler}
                                      ></SimpleSelectBox>
                                      <span className='error' id='emailError'>
                                        Please enter valid email id
                                      </span>
                                    </div>
                                  </div>

                                  <section className='group-section-data'>
                                    <div className='carosuel-accordion-full mt-4'>
                                      <section className='accordion-primary-cont'>
                                        <article className='accordion-data-cont'>
                                          <Accordion defaultActiveKey='0'>
                                            <Accordion.Item eventKey='0'>
                                              <Accordion.Header>
                                                <div className='accordion-data-icon'>
                                                  <i className='icon-double-menu' />
                                                </div>
                                                <div className='accordion-data'>
                                                  <h4>Price asset</h4>
                                                </div>
                                              </Accordion.Header>

                                              <Accordion.Body>
                                                <div className='accordion-body-inner'>
                                                  <div className='row align-items-center'>
                                                    <div className='form-item'>
                                                      <div className='label-icon'>
                                                        <label>Header</label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <input
                                                          type='text'
                                                          placeholder='Type header'
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                      <small className='select-time'>
                                                        0 / 25
                                                      </small>
                                                    </div>
                                                    <div className='form-item input-group-label col-6'>
                                                      <div className='label-icon'>
                                                        <label>Amount</label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <span className='input-group-text rs-text'>
                                                          <i className='icon-rupee' />
                                                        </span>
                                                        <input
                                                          type='email'
                                                          id='email'
                                                          placeholder='X.XX'
                                                          name='Planned spend'
                                                          onChange={
                                                            onChangeHandler
                                                          }
                                                        />
                                                        <span className='input-group-text inr-text rs-text'>
                                                          INR
                                                        </span>
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                    <div className='form-item col-6'>
                                                      <div className='label-icon'>
                                                        <label>Units</label>
                                                      </div>
                                                      <SimpleSelectBox
                                                        name='kpi'
                                                        placeholder='No units'
                                                        options={[
                                                          'CPM',
                                                          'CPC',
                                                          'CPA',
                                                          'CTR',
                                                          'Viewable%',
                                                          'CPV',
                                                          'CPIAVC',
                                                          'other KPI',
                                                        ]}
                                                        onChangeHandler={
                                                          onChangeHandler
                                                        }
                                                      ></SimpleSelectBox>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>

                                                    <div className='form-item col-12'>
                                                      <div className='label-icon'>
                                                        <label>
                                                          Description
                                                        </label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <input
                                                          type='email'
                                                          id='email'
                                                          placeholder='Type description'
                                                          name='Planned spend'
                                                          onChange={
                                                            onChangeHandler
                                                          }
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                      <small className='select-time'>
                                                        0 / 25
                                                      </small>
                                                    </div>

                                                    <div className='form-item col-12'>
                                                      <div className='label-icon'>
                                                        <label>Final URL</label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <input
                                                          type='email'
                                                          id='email'
                                                          placeholder='http://www.example.com/page'
                                                          name='Planned spend'
                                                          onChange={
                                                            onChangeHandler
                                                          }
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>

                                                    <div className='form-item col-12'>
                                                      <div className='label-icon'>
                                                        <label>
                                                          Mobile final URL
                                                          (Optional)
                                                        </label>
                                                      </div>
                                                      <div className='input-group'>
                                                        <input
                                                          type='email'
                                                          id='email'
                                                          placeholder='http://www.m.example.com/page'
                                                          name='Planned spend'
                                                          onChange={
                                                            onChangeHandler
                                                          }
                                                        />
                                                      </div>
                                                      <span
                                                        className='error'
                                                        id='emailError'
                                                      >
                                                        Please enter valid email
                                                        id
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className='d-flex justify-content-end mt-3'>
                                                    <button
                                                      type='button'
                                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                    >
                                                      <i className='icon-delete' />
                                                      Remove
                                                    </button>
                                                  </div>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>
                                        </article>
                                      </section>
                                    </div>
                                    <div className='add-section'>
                                      <button className='btn-add-plus'>
                                        <i className='icon-plus-circle' />
                                        Add a price asset
                                      </button>
                                    </div>
                                  </section>

                                  <section className='group-section-data'>
                                    <CardBody>
                                      <h6 className='card-header-title'>
                                        URL options
                                      </h6>
                                      <div className='row align-items-center'>
                                        <div className='form-item'>
                                          <div className='budget-cont'>
                                            <div className='label-icon'>
                                              <label>Tracking Template</label>
                                            </div>
                                            <div className='input-group'>
                                              <input
                                                type='email'
                                                id='email'
                                                placeholder='Tracking Template'
                                                name='kpi goal'
                                              />
                                            </div>
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div class='info-icon-body mt-2 mb-2'>
                                          <div class='more-info-text'>
                                            <p>
                                              Example:
                                              https://www.trackingtemplate.foo/?url=
                                              {''}
                                              &id=5
                                            </p>
                                          </div>
                                        </div>

                                        <div className='form-item'>
                                          <div className='budget-cont'>
                                            <div className='label-icon'>
                                              <label>Final URL suffix</label>
                                            </div>
                                            <div className='input-group'>
                                              <input
                                                type='email'
                                                id='email'
                                                placeholder='Final URL suffix'
                                                name='kpi goal'
                                              />
                                            </div>
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div class='info-icon-body mt-2 mb-2'>
                                          <div class='more-info-text'>
                                            <p>
                                              Example:
                                              param1=value1&param2=value2
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </section>

                                  <section className='group-section-data'>
                                    <CardBody>
                                      <h6 className='card-header-title'>
                                        Custom parameters
                                      </h6>
                                      <div className='row align-items-center mb-3'>
                                        <div className='form-item col-6'>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='{_Name.                   }'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>

                                        <div className='form-item col-6 sword-line'>
                                          <div className='s-line'>=</div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Value'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                      <div className='add-section'>
                                        <button className='btn-add-plus'>
                                          <i className='icon-plus-circle' />
                                          Add parameters
                                        </button>
                                      </div>
                                    </CardBody>
                                  </section>

                                  <div className='media-btn'>
                                    <div className='file-error'>
                                      <button
                                        type='button'
                                        className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <button
                                      type='button'
                                      className='btn-icon btn-icon-primary btn-grey-primary'
                                    >
                                      Cencel
                                    </button>
                                  </div>

                                  {/* <section className="group-section-data">

                                    <CardHeader>
                                      <h5>Advanced options</h5>
                                      <p>Asset scheduling</p>
                                      <p>
                                        Select when your assets will be
                                        eligible to show
                                      </p>
                                    </CardHeader>

                                    <CardBody>

                                      <div className="row align-items-center">
                                        <div className="form-item input-group-label col-6">
                                          <div className="label-icon">
                                            <label>Start date</label>
                                          </div>
                                          <div className="input-group">
                                            <span className="input-group-text rs-text">
                                              <i className="icon-calendar" />
                                            </span>
                                            <input
                                              type="text"
                                              id="datepicker"
                                              placeholder="DD/MM/YYYY"
                                            />
                                          </div>
                                          <span
                                            className="error"
                                            id="emailError"
                                          >
                                            Please enter valid email
                                            id
                                          </span>
                                        </div>
                                        <div className="form-item input-group-label col-6">
                                          <div className="label-icon">
                                            <label>End date</label>
                                          </div>
                                          <div className="input-group time">
                                            <span className="input-group-text rs-text">
                                              <i className="icon-calendar" />
                                            </span>
                                            <input
                                              type="text"
                                              id="datepicker"
                                              placeholder="DD/MM/YYYY"
                                            />
                                          </div>
                                          <span
                                            className="error"
                                            id="emailError"
                                          >
                                            Please enter valid email
                                            id
                                          </span>
                                        </div>
                                      </div>

                                    </CardBody>

                                    <CardBody>
                                      <div className="form-panel-group-add">
                                        <div className="remove-box-group d-flex">
                                          <div className="row align-items-center">
                                            <div className="form-item col-box-3">
                                              <div className="label-icon">
                                                <label>
                                                  Select Days
                                                </label>
                                              </div>
                                              <div className="input-group">
                                                <input
                                                  type="email"
                                                  id="email"
                                                  placeholder="All Days"
                                                />
                                              </div>
                                              <span
                                                className="error"
                                                id="emailError"
                                              >
                                                Please enter valid email
                                                id
                                              </span>
                                            </div>
                                            <div className="form-item input-group-label col-box-3">
                                              <div className="label-icon">
                                                <label>
                                                  Start time
                                                </label>
                                              </div>
                                              <div className="input-group input-width-less">
                                                <span className="input-group-text rs-text">
                                                  <i className="icon-clock" />
                                                </span>
                                                <input
                                                  type="text"
                                                  id="datepicker"
                                                  placeholder="00:00"
                                                />
                                              </div>
                                              <span
                                                className="error"
                                                id="emailError"
                                              >
                                                Please enter valid email
                                                id
                                              </span>
                                            </div>
                                            <div className="form-item input-group-label col-box-3">
                                              <div className="label-icon">
                                                <label>End time</label>
                                              </div>
                                              <div className="input-group input-width-less">
                                                <span className="input-group-text rs-text">
                                                  <i className="icon-clock" />
                                                </span>
                                                <input
                                                  type="text"
                                                  id="datepicker1"
                                                  placeholder="00:00"
                                                />
                                              </div>
                                              <span
                                                className="error"
                                                id="emailError"
                                              >
                                                Please enter valid email
                                                id
                                              </span>
                                            </div>
                                          </div>
                                          <div className="media-btn">
                                            <button
                                              type="button"
                                              className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                            >
                                              <i className="icon-delete" />
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="add-section">
                                        <button className="btn-add-plus">
                                          <i className="icon-plus-circle" />{" "}
                                          Add schedule
                                        </button>
                                      </div>
                                      <div class="info-icon-body mt-2 mb-3">
                                        <div class="more-info-text">
                                          <p>
                                            Based on account time zone:
                                            (GMT+05:30) India Standard
                                            Time
                                          </p>
                                        </div>
                                      </div>
                                    </CardBody>
                                    <div className="media-btn">
                                      <div className="file-error">
                                        <button
                                          type="button"
                                          className="btn-icon btn-icon-primary btn-bedcrumb-act"
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <button
                                        type="button"
                                        className="btn-icon btn-icon-primary btn-grey-primary"
                                      >
                                        Cencel
                                      </button>
                                    </div>
                                  </section> */}
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  New Price
                                </button>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h5>Add new structured snippet</h5>
                            </CardHeader>

                            <div className='card-form form-panel'>
                              <CardBody>
                                <h6 className='card-header-title'>Header</h6>
                                <div className='row align-items-center'>
                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Language</label>
                                    </div>
                                    <SimpleSelectBox
                                      name='kpi'
                                      placeholder='English'
                                      options={[
                                        'CPM',
                                        'CPC',
                                        'CPA',
                                        'CTR',
                                        'Viewable%',
                                        'CPV',
                                        'CPIAVC',
                                        'other KPI',
                                      ]}
                                      onChangeHandler={onChangeHandler}
                                    ></SimpleSelectBox>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                  <div className='form-item col-6'>
                                    <div className='label-icon'>
                                      <label>Header type</label>
                                    </div>
                                    <SimpleSelectBox
                                      name='kpi'
                                      placeholder='Select header type'
                                      options={[
                                        'CPM',
                                        'CPC',
                                        'CPA',
                                        'CTR',
                                        'Viewable%',
                                        'CPV',
                                        'CPIAVC',
                                        'other KPI',
                                      ]}
                                      onChangeHandler={onChangeHandler}
                                    ></SimpleSelectBox>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                  </div>
                                </div>
                              </CardBody>

                              <CardBody>
                                <h6 className='card-header-title'>Values</h6>
                                <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                  <div className='col-8 d-flex flex-wrap'>
                                    <div className='form-check-box col-8 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        <div className='label-multitext'>
                                          Header type, Value 1, Value 2, Value 3
                                        </div>
                                      </label>

                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </CardBody>

                              <div className='form-panel-group-add'>
                                <h6 className='card-header-title'>
                                  Add new structured snippet
                                </h6>
                                <div className='row align-items-center'>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Value 1</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Type value 1'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 25
                                    </small>
                                  </div>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Value 2</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Type value 2'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 25
                                    </small>
                                  </div>
                                  <div className='form-item col-12'>
                                    <div className='label-icon'>
                                      <label>Value 3</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Type value 3'
                                      />
                                    </div>
                                    <span className='error' id='emailError'>
                                      Please enter valid email id
                                    </span>
                                    <small className='select-time'>
                                      0 / 25
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='add-section'>
                              <button className='btn-add-plus'>
                                <i className='icon-plus-circle' />
                                New structured snippet
                              </button>
                            </div>
                          </section>

                          <div className='divder-form'></div>

                          <section className='group-section-data'>
                            <CardHeader>
                              <h5>Apps</h5>
                              <p>Add apps</p>
                            </CardHeader>
                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='action-btn-both action-btn-both-check row relative mb-4'>
                                  <div className='col-8 d-flex flex-wrap'>
                                    <div className='form-check-box col-8 mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='inlineCheckbox9'
                                        defaultValue='Display'
                                        name='creative'
                                        onChange={onChangeHandler}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='inlineCheckbox9'
                                      >
                                        <div className='label-multitext'>
                                          www.appurl.com
                                        </div>
                                      </label>

                                      <span className='icon-click'>
                                        <a href='#' className='outline-btn'>
                                          <i className='icon-edit'></i>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <span class='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>

                                <div className='form-panel-group-add'>
                                  <h6 className='card-header-title'>
                                    Add new app
                                  </h6>
                                  <p>Select your mobile app's platform</p>

                                  <CardBody>
                                    <div className='action-btn-cont'>
                                      <div className='action-btn-both action-btn-no-icon row'>
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefault'
                                              id='flexRadioDefault50'
                                              defaultChecked=''
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault50'
                                            >
                                              Android
                                            </label>
                                          </div>
                                        </div>
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefault'
                                              id='flexRadioDefault51'
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault51'
                                            >
                                              IOS
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>

                                  <CardBody>
                                    <h6 className='card-header-title'>
                                      Look up your app
                                    </h6>
                                    <div className='search-bar-cont search-bar-border'>
                                      <div className='search'>
                                        <div className='search-close'>
                                          <input
                                            type='text'
                                            placeholder='Search Saved Audience'
                                          />
                                          <button className='close-btn'>
                                            <i className='icon-close' />
                                          </button>
                                        </div>
                                        <button className='btn-icon btn-icon-primary'>
                                          <i className='icon-search' />
                                        </button>
                                      </div>
                                    </div>
                                  </CardBody>

                                  <CardBody>
                                    <div className='row align-items-center'>
                                      <div className='form-item col-12'>
                                        <div className='label-icon'>
                                          <label>Link text</label>
                                        </div>
                                        <div className='input-group'>
                                          <input
                                            type='email'
                                            id='email'
                                            placeholder='Type text'
                                            name='Planned spend'
                                            onChange={onChangeHandler}
                                          />
                                        </div>
                                        <small className='select-time'>
                                          0 / 20
                                        </small>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                    </div>
                                  </CardBody>

                                  <CardBody>
                                    <h6 className='card-header-title'>
                                      App URL options
                                    </h6>
                                    <div className='row align-items-center'>
                                      <div className='form-item'>
                                        <div className='budget-cont'>
                                          <div className='label-icon'>
                                            <label>Tracking Template</label>
                                          </div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Tracking Template'
                                              name='kpi goal'
                                            />
                                          </div>
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div class='info-icon-body mt-2 mb-2'>
                                        <div class='more-info-text'>
                                          <p>
                                            Example:
                                            https://www.trackingtemplate.foo/?url=
                                            {''}
                                            &id=5
                                          </p>
                                        </div>
                                      </div>

                                      <div className='form-item'>
                                        <div className='budget-cont'>
                                          <div className='label-icon'>
                                            <label>Final URL suffix</label>
                                          </div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Final URL suffix'
                                              name='kpi goal'
                                            />
                                          </div>
                                        </div>
                                        <span className='error' id='emailError'>
                                          Please enter valid email id
                                        </span>
                                      </div>
                                      <div class='info-icon-body mt-2 mb-2'>
                                        <div class='more-info-text'>
                                          <p>
                                            Example: param1=value1&param2=value2
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>

                                  <section className='group-section-data'>
                                    <CardBody>
                                      <h6 className='card-header-title'>
                                        Custom parameters
                                      </h6>
                                      <div className='row align-items-center mb-3'>
                                        <div className='form-item col-6'>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='{_Name.                   }'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>

                                        <div className='form-item col-6 sword-line'>
                                          <div className='s-line'>=</div>
                                          <div className='input-group'>
                                            <input
                                              type='email'
                                              id='email'
                                              placeholder='Value'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                      <div className='add-section'>
                                        <button className='btn-add-plus'>
                                          <i className='icon-plus-circle' />
                                          Add parameters
                                        </button>
                                      </div>
                                    </CardBody>
                                  </section>

                                  <div className='divder-form'></div>

                                  <section className='group-section-data'>
                                    <CardHeader>
                                      <h5>Advanced options</h5>
                                      <p>Asset scheduling</p>
                                      <p>
                                        Select when your assets will be eligible
                                        to show
                                      </p>
                                    </CardHeader>

                                    <CardBody>
                                      <div className='row align-items-center'>
                                        <div className='form-item input-group-label col-6'>
                                          <div className='label-icon'>
                                            <label>Start date</label>
                                          </div>
                                          <div className='input-group'>
                                            <span className='input-group-text rs-text'>
                                              <i className='icon-calendar' />
                                            </span>
                                            <input
                                              type='text'
                                              id='datepicker'
                                              placeholder='DD/MM/YYYY'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                        <div className='form-item input-group-label col-6'>
                                          <div className='label-icon'>
                                            <label>End date</label>
                                          </div>
                                          <div className='input-group time'>
                                            <span className='input-group-text rs-text'>
                                              <i className='icon-calendar' />
                                            </span>
                                            <input
                                              type='text'
                                              id='datepicker'
                                              placeholder='DD/MM/YYYY'
                                            />
                                          </div>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            Please enter valid email id
                                          </span>
                                        </div>
                                      </div>
                                    </CardBody>

                                    <CardBody>
                                      <div className='form-panel-group-add'>
                                        <div className='remove-box-group d-flex'>
                                          <div className='row align-items-center'>
                                            <div className='form-item col-box-3'>
                                              <div className='label-icon'>
                                                <label>Select Days</label>
                                              </div>
                                              <div className='input-group'>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder='All Days'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item input-group-label col-box-3'>
                                              <div className='label-icon'>
                                                <label>Start time</label>
                                              </div>
                                              <div className='input-group input-width-less'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-clock' />
                                                </span>
                                                <input
                                                  type='text'
                                                  id='datepicker'
                                                  placeholder='00:00'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                            <div className='form-item input-group-label col-box-3'>
                                              <div className='label-icon'>
                                                <label>End time</label>
                                              </div>
                                              <div className='input-group input-width-less'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-clock' />
                                                </span>
                                                <input
                                                  type='text'
                                                  id='datepicker1'
                                                  placeholder='00:00'
                                                />
                                              </div>
                                              <span
                                                className='error'
                                                id='emailError'
                                              >
                                                Please enter valid email id
                                              </span>
                                            </div>
                                          </div>
                                          <div className='media-btn'>
                                            <button
                                              type='button'
                                              className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                            >
                                              <i className='icon-delete' />
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='add-section'>
                                        <button className='btn-add-plus'>
                                          <i className='icon-plus-circle' /> Add
                                          schedule
                                        </button>
                                      </div>
                                      <div class='info-icon-body mt-2 mb-3'>
                                        <div class='more-info-text'>
                                          <p>
                                            Based on account time zone:
                                            (GMT+05:30) India Standard Time
                                          </p>
                                        </div>
                                      </div>
                                    </CardBody>
                                    <div className='media-btn'>
                                      <div className='file-error'>
                                        <button
                                          type='button'
                                          className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                        >
                                          Save
                                        </button>
                                      </div>
                                      <button
                                        type='button'
                                        className='btn-icon btn-icon-primary btn-grey-primary'
                                      >
                                        Cencel
                                      </button>
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  New structured snippet
                                </button>
                              </div>
                            </CardBody>
                          </section>

                          <div className='divder-form'></div>

                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>Locations</h6>
                                <p className='mb-2'>Add snippets of text</p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {/* Ads Ends here */}

                        {/* Add URL options Start Hree */}
                        <Card>
                          <CardHeader>
                            <h5>Add URL options</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Tracking Template</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Tracking Template'
                                        name='kpi goal'
                                      />
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div class='info-icon-body mt-2 mb-2'>
                                  <div class='more-info-text'>
                                    <p>
                                      Example:
                                      https://www.trackingtemplate.foo/?url={''}
                                      &id=5
                                    </p>
                                  </div>
                                </div>

                                <div className='form-item'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Final URL suffix</label>
                                    </div>
                                    <div className='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Final URL suffix'
                                        name='kpi goal'
                                      />
                                    </div>
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                                <div class='info-icon-body mt-2 mb-2'>
                                  <div class='more-info-text'>
                                    <p>Example: param1=value1&param2=value2</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <h6 className='card-header-title'>
                                  Custom parameters
                                </h6>
                                <div className='form-item col-6'>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='{_Name.                   }'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>

                                <div className='form-item col-6 sword-line'>
                                  <div className='s-line'>=</div>
                                  <div className='input-group'>
                                    <input
                                      type='email'
                                      id='email'
                                      placeholder='Value'
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    Please enter valid email id
                                  </span>
                                </div>
                              </div>
                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' />
                                  Add parameters
                                </button>
                              </div>
                            </div>
                            <div className='media-btn'>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                              >
                                Test
                              </button>
                            </div>
                            <div className='action-btn-both action-btn-both-check row'>
                              <div className='form-check-box'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='inlineCheckbox1'
                                  defaultValue='option1'
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='inlineCheckbox1'
                                >
                                  Use a different final URL for mobile
                                </label>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {/* Add URL options Start Hree */}

                        {/* Campaign Name Start Here */}
                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h5>Campaign Name</h5>
                              </div>
                            </div>

                            <CardBody>
                              <div className='card-form form-panel'>
                                <div className='row align-items-center'>
                                  <div className='form-item col-9'>
                                    <div className='label-icon'>
                                      <label> Campaign name </label>{' '}
                                    </div>

                                    {/* <div className="select-multiple-components select-multiple-inside"> */}
                                    <ReactSelect
                                      isMulti={true}
                                      isSearchable={true}
                                      isDisabled={false}
                                    />
                                    {/* </div> */}
                                  </div>
                                  <div class='col-3'>
                                    <button class='btn-icon btn-icon-primary'>
                                      <i class='icon-plus'></i>
                                      Create
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </div>
                        </div>
                        {/* Campaign Name Ends Here */}

                        <div className='card-primary-repet'>
                          <div className='card-primary'>
                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h6 className='d-flex align-items-center'>
                                  Land Scape Image (1.91:1)
                                </h6>
                                <p>Add upto 15 images</p>
                              </div>
                            </div>

                            <div className='card-body'>
                              <div className='form-panel-group-add'>
                                <div className='media-list media-landscape'>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> LandScape
                                  mage
                                </button>
                              </div>

                              <div className='divder-form'></div>
                            </div>

                            <div className='card-header-top d-flex'>
                              <div className='card-lt-pane'>
                                <h6 className='d-flex align-items-center'>
                                  Land Scape Image (1.91:1)
                                </h6>
                                <p>Add upto 15 images</p>
                              </div>
                            </div>

                            <div className='card-body'>
                              <div className='form-panel-group-add'>
                                <div className='media-list media-landscape'>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='media-card'>
                                    <div className='select-media'>
                                      <div className='mediaimg-box'>
                                        <div className='media-img'>
                                          <img src={atdplaceholder} alt='' />
                                        </div>
                                        <div className='media-social'>
                                          <i class='icon-delete'></i>
                                        </div>
                                      </div>
                                      <div className='mediabox-cont'>
                                        <h3>Image-21.png</h3>
                                        <p>800 x 535px • 56 KB</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='add-section'>
                                <button className='btn-add-plus'>
                                  <i className='icon-plus-circle' /> LandScape
                                  mage
                                </button>
                              </div>

                              <div className='divder-form'></div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  {/* view starts */}
                  <div
                    className='tab-pane fade'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        <div className='card-primary'>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Campaign name</h5>
                              <p>New Awareness Campaign</p>
                              <p>ID: 23857805967620728</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Special ad categories</h5>
                              <p>No categories declared</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Buying type</h5>
                              <p>Awareness</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Ad sets</h5>
                              <p className='add-data'>Please add: Ad sets</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Format</h5>
                              <p>Image and Video</p>
                            </div>
                            <div className='card-rt-pane d-flex me-auto align-items-center'>
                              <figure className='prev-icon'>
                                <img src={atdplaceholder} alt='' />
                              </figure>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* view ends */}
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  ></div>
                </div>
              </article>
            </section>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            className='atd-modal approved-model'
          >
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='modal-img-cont'>
                <figure className='modal-icon modal-success'>
                  <i className='icon-check-mark' />
                </figure>
              </div>
              <div className='modal-text'>
                <h3>Thank you for approving the request!</h3>
                <p>
                  The campaign has been sent to Facebook for approval and will
                  be publish in sometime
                </p>
              </div>
            </Modal.Body>

            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn'
                onClick={handleClose}
              >
                ok
              </button>
            </div>

            {/* <Modal.Footer>
              <Button variant="secondary" >
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
          </Modal>

          <Footer nextBtnlabel='Next'></Footer>
        </div>
      </div>
    </div>
  );
}

export default Common;
