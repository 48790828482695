import React from 'react';

function InfoText(props) {
  return (
    <>
      <div className='info-icon-body'>
        {/* <div className='more-info-icon'>
          <i className='icon-bulb'></i>
        </div> */}
        <div className='more-info-text'>
          <p>{props?.copy}</p>
        </div>
      </div>
    </>
  );
}

export default InfoText;
