import React, { useState } from 'react';
import CommonModal from '../../components/common/commonModal';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/common/Sidebar';
import { metaListingUrl } from '../../utils/constants';

const APIError = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const handleClose = () => {
    setShowModal(false);
    window.location.href = metaListingUrl;
  };
  return (
    <main>
      <div className='main-container facebook-step-one'>
        <div className='container'>
          <div className='fb-panel'>
            <Sidebar />
            <div className='right-side-box padding-bottom-extra media-section-left'>
              <div className='that-miss-wrap'>
                <div className='img-wrap'>
                  <img src={''} alt='' />
                </div>
                <h2></h2>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        show={showModal}
        handleClose={handleClose}
        handleRedirection={() => navigate('/')}
        copy={'Sever Error. Please try again'}
      />
    </main>
  );
};

export default APIError;
