import React from 'react';

const Loader = () => {
  return (
    <div className='loader-overlay' hidden=''>
      <div className='preloader' />
      <div className='preloader2' />
    </div>
  );
};

export default Loader;
