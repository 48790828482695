import React, { useState, useRef } from 'react';
import Sidebar from '../../components/common/Sidebar';
import axios from 'axios';
import { AxiosError } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import uploadImg from '../../assets/images/upload-img.svg';
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from 'react';
import Loader from '../../components/common/Loader';
import Modal from 'react-bootstrap/Modal';
import { baseUrlStg } from '../../utils/constants';
import { getCookies } from '../../utils/utils';
import { useSelector } from 'react-redux';

export const UploadFiles = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [editInputValue, setEditInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const [youtubeData, setYoutubeData] = useState({
    url: '',
    fileName: '',
    duration: '00:00:00',
  });
  const [youtubeFormError, setYoutubeFormError] = useState({});
  const [uploadPopup, setUploadPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fileType, platformType } = location?.state;
  const [supportedFiletypes, setSupportedFileTypes] = useState([]);
  const [supportedFiletypesOg, setSupportedFileTypesOg] = useState([]);
  const [alerts, setAlerts] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  console.log(fileType, platformType);

  console.log('step 2 selected files', selectedFiles);
  console.log('step 3 selected files', fileList);
  console.log('youtubeLink list ', youtubeData);
  console.log('youtubeLink list ', youtubeFormError);
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  useEffect(() => {
    getCreativeTypes();
  }, []);
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;

  const getCreativeTypes = () => {
    let url = `${baseUrlStg}/api/media-upload/Platform-details`;
    if (platformType === 'Google Ad') {
      url = `${baseUrlStg}/api/media-upload/Platform-details?platform=${'Google_Ad'}&MediaType=${fileType}`;
    } else {
      url = `${baseUrlStg}/api/media-upload/Platform-details?platform=${platformType}&MediaType=${fileType}`;
    }
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((result) => {
        setSupportedFileTypesOg(result.data[0].supportedExtensions);
        const extensions = result.data[0].supportedExtensions.map(
          (ele) => `.${ele}`,
        );
        console.log(extensions);
        setSupportedFileTypes(extensions);
      })
      .catch((err) => {
        setApiError(err.response);
      });
  };

  //onchange event of file input
  const handleOnChnageInput = (e) => {
    const files = e?.target?.files;
    if (files?.length > 0) {
      addFiles(files);
    }
  };
  // file drag and drop
  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    addFiles(files);
  };

  //common function for add file
  const addFiles = (files) => {
    let pendigUploads = 0;

    for (let i = 0; i < fileList.length; i++) {
      if (!fileList[i].success) {
        pendigUploads = pendigUploads + 1;
      }
    }
    console.log('uploded', pendigUploads);
    if (pendigUploads == 10) {
      console.log('refuse...');
      // alert('You are only allowed to upload a maximum of 10 files at a time');
      setAlertMessage(() => {
        return {
          message:
            'You are only allowed to upload a maximum of 10 files at a time.',
          type: 'file',
        };
      });
      setAlerts(true);
      return;
    }

    if (fileList.length > 0) {
      const removeUploaded = fileList.filter((ele) => !ele.success);
      setSelectedFiles(() => removeUploaded);
      setFileList(() => removeUploaded);
    }

    if (files?.length > 0) {
      let newFiles = [];

      if (pendigUploads < 10) {
        for (let i = 0; i < files?.length; i++) {
          if (pendigUploads + newFiles?.length < 10) {
            if (
              files[i]?.type?.includes(fileType?.toLowerCase()) ||
              (fileType &&
                fileType === 'Html5' &&
                files[i]?.type?.includes('zip')) ||
              supportedFiletypesOg?.includes(
                files[i]?.name?.split('.').slice(-1)[0],
              )
            ) {
              newFiles.push(files[i]);
            }
          } else {
            setAlertMessage(() => {
              return {
                message:
                  'You are only allowed to upload a maximum of 10 files at a time.',
                type: 'edit',
              };
            });
            setAlerts(true);
            // alert('You are only allowed to upload a maximum of 10 files at a time');
            break;
          }
        }
        console.log('newFiles', newFiles);
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setFileList((prevFiles) => [...prevFiles, ...newFiles]);
      }
    }
  };

  //delete file from list
  const handleDeleteFile = (index) => {
    console.log(index);

    const filterSelectedFiles = selectedFiles.filter((ele, i) => i != index);
    setSelectedFiles(filterSelectedFiles);

    const filterFileList = fileList.filter((ele, i) => i != index);
    setFileList(filterFileList);
  };

  //edit file name
  const handleEditFileName = (index) => {
    const ind = selectedFiles.findIndex((ele) => ele.edit);

    if (ind != -1) {
      if (!editInputValue) {
        setInputError(true);
      } else {
        if (ind == index) {
          console.log('inside 122');

          const saveEditName = selectedFiles.map((ele, i) => {
            if (i == index) {
              const newName = ele.name.split('.');
              ele = new File(
                [ele],
                `${editInputValue}.${newName[newName.length - 1]}`,
                { type: ele.type },
              );
              let newfile = ele;
              delete newfile['edit'];

              return newfile;
            }
            return ele;
          });
          setInputError(false);
          setEditInputValue('');
          setFileList(saveEditName);
          setSelectedFiles(saveEditName);
        } else {
          setAlertMessage(() => {
            return {
              message: 'You can edit one file at a time.',
              type: 'file',
            };
          });
          setAlerts(true);
          // alert('you can edit one file at a time')
          return;
        }
      }
    }

    if (ind == -1) {
      const editEnableInput = selectedFiles.map((file, i) => {
        if (i == index) {
          console.log('inside 1');
          if (!file['edit']) {
            file['edit'] = true;
            setEditInputValue(file.name.split('.')[0]);
            return file;
          }
        }
        return file;
      });
      setFileList(editEnableInput);
      setSelectedFiles(editEnableInput);
    }
  };

  //cancel button navigation
  const handleCancel = () => {
    console.log('cancel');
    navigate('/media-library/listing');
  };

  // file upload function
  const handleUpload = async () => {
    const editIndex = fileList.findIndex((ele) => ele.edit);
    console.log('editIndex', editIndex);
    if (editIndex != -1) {
      return;
    }

    setIsUploading(true);
    if (selectedFiles && selectedFiles.length > 0) {
      // selectedFiles.forEach(async (file, i) => {
      for (let i = 0; i < fileList.length; i++) {
        if (!fileList[i].success) {
          delete fileList[i].success;
          let formdata = new FormData();
          formdata.append('media', fileList[i]);
          console.log(
            'getUserInfo?.Userid',
            getUserInfo?.Userid,
            typeof getUserInfo?.Userid,
          );
          const createdBy = getUserInfo?.Userid;
          let apiURL = ``;

          if (platformType === 'Google Ad') {
            switch (fileType) {
              case 'Image':
                apiURL = `${baseUrlStg}/api/media-upload/image-upload?platform=${'Google_Ad'}&createdBy=${createdBy}`;
                break;
              case 'Video':
                apiURL = `${baseUrlStg}/api/media-upload/video-upload?platform=${'Google_Ad'}&createdBy=${createdBy}`;
                break;
              case 'Html5':
                apiURL = `${baseUrlStg}/api/media-upload/zip-upload?platform=${'Google_Ad'}&createdBy=${createdBy}`;
                break;
              case 'Audio':
                apiURL = `${baseUrlStg}/api/media-upload/audio-upload?platform=${'Google_Ad'}&createdBy=${createdBy}`;
                break;
              default:
                break;
            }
          } else {
            switch (fileType) {
              case 'Image':
                apiURL = `${baseUrlStg}/api/media-upload/image-upload?platform=${platformType}&createdBy=${createdBy}`;
                break;
              case 'Video':
                apiURL = `${baseUrlStg}/api/media-upload/video-upload?platform=${platformType}&createdBy=${createdBy}`;
                break;
              case 'Html5':
                apiURL = `${baseUrlStg}/api/media-upload/zip-upload?platform=${platformType}&createdBy=${createdBy}`;
                break;
              case 'Audio':
                apiURL = `${baseUrlStg}/api/media-upload/audio-upload?platform=${platformType}&createdBy=${createdBy}`;
                break;
              default:
                break;
            }
          }

          if (fileType?.toLowerCase() === 'image') {
            console.log(fileList[i]);
            const img = new Image();
            img.src = URL.createObjectURL(fileList[i]);
            console.log('img src', URL.createObjectURL(fileList[i]));
            console.log('after');
            img.onload = () => {
              console.log(
                `imageHeight ${img.height} and image width ${img.width}`,
              );
            };
            img.onerror = (err) => {
              console.log('img error');
              console.error(err);
            };
          }

          const fileExtension = fileList[i]?.name?.split('.');
          const setFileUploading = fileList.map((ele, ind) => {
            if (i == ind) {
              ele.loading = true;
              return ele;
            }
            return ele;
          });

          setFileList(setFileUploading);

          await axios({
            method: 'post',
            url: apiURL,
            data: formdata,
            headers: { 'Content-Type': 'multipart/form-data', ...CustomHeader },
          })
            .then(async (res) => {
              if (res && res?.data?.success) {
                if (
                  fileList[i]?.type?.match('video') &&
                  (fileExtension[fileExtension.length - 1] === 'mp4' ||
                    fileList[i].type.split('/').includes('mp4'))
                ) {
                  await uploadVideoThumbnail(
                    fileList[i],
                    res.data.uploadedFiles[0],
                  ).then((response) => {
                    console.log('upload thumbnail res', response);
                    const updatedFiles = fileList.map((ele, ind) => {
                      if (i == ind) {
                        ele.success = true;
                        ele.loading = false;
                        ele.failed = false;
                        ele.message = res.data.message;
                        return ele;
                      }
                      return ele;
                    });

                    setFileList(updatedFiles);
                  });
                } else {
                  const updatedFiles = fileList.map((ele, ind) => {
                    if (i == ind) {
                      ele.success = true;
                      ele.loading = false;
                      ele.failed = false;
                      ele.message = res.data.message;
                      return ele;
                    }
                    return ele;
                  });

                  setFileList(updatedFiles);
                }
                //File upload success
              } else {
                // file upload failed
                const updatedFiles = fileList.map((ele, ind) => {
                  if (i == ind) {
                    ele.success = false;
                    ele.failed = true;
                    ele.loading = false;
                    ele.message = res.data.message;
                    return ele;
                  }
                  return ele;
                });

                setFileList(updatedFiles);
              }
              // setIsLoading(false)
            })
            .catch((error) => {
              // API error

              console.log('errererr', error);
              if (!error?.response) {
                setApiError('No Server Response');
              } else if (error?.code === AxiosError.ERR_NETWORK) {
                setApiError('Network Error');
              } else if (error.response?.status === 404) {
                setApiError('404 - Not Found');
              } else if (error?.code) {
                setApiError('Code: ' + error.code);
              } else {
                setApiError('Unknown Error');
              }

              const updatedFiles = fileList.map((ele, ind) => {
                if (i == ind) {
                  ele.success = false;
                  ele.failed = true;
                  ele.loading = false;
                  ele.message = error.response.data.message
                    ? error.response.data.message
                    : 'Failed to upload';
                  return ele;
                }
                return ele;
              });

              setFileList(updatedFiles);

              setIsUploading(false);
            });
        }
      }
      // })
    }

    setIsUploading(false);
  };

  const uploadVideoThumbnail = async (file, uniqueId) => {
    return new Promise(async (resolve, reject) => {
      generateVideoThumbnails(file, 0).then(async (thumb) => {
        console.log('thummmbbbbbbbb', file.name, thumb);
        // setThumb(res)
        const newThumb = thumb.split(':')[1];
        console.log('neewThumbbb', newThumb);
        const createdBy = getUserInfo?.Userid;
        const requestData = {
          thumbnailData: newThumb,
          // Add the createdBy field here
        };

        await axios({
          method: 'post',
          url: `${baseUrlStg}/api/media-upload/upload-thumbnail?uniquefilename=${uniqueId}&createdBy=${createdBy}`,
          data: newThumb,
          headers: {
            'Content-Type': 'application/json-patch+json',
            ...CustomHeader,
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    });
  };

  const formatFileSize = (bytes, decimalPoint) => {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleEditInput = (e) => {
    // console.log('editInput', e.target.value)
    if (!e.target.value) {
      setInputError(true);
    } else {
      setInputError(false);
    }
    setEditInputValue(e.target.value);
  };

  const importFileandPreview = (file, revoke) => {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      let preview = window.URL.createObjectURL(file);
      // remove reference
      if (revoke) {
        window.URL.revokeObjectURL(preview);
      }
      console.log(preview);
      setTimeout(() => {
        resolve(preview);
      }, 100);
    });
  };

  const LoaderSpinner = () => {
    return <Spinner animation='border' variant='info' />;
  };

  /**
   *
   * @param videoFile {FIle} // the video file
   * @param numberOfThumbnails {number} //number of thumbnails you want to generate
   * @returns {string[]} // an array of base64 thumbnails images
   *
   */
  const generateVideoThumbnails = async (videoFile, numberOfThumbnails) => {
    let thumbnail = [];
    let fractions = 0;
    return new Promise(async (resolve, reject) => {
      console.log('generateVideoThumbnails');
      if (!videoFile.type?.includes('video')) reject('not a valid video file');
      await getVideoDuration(videoFile).then(async (duration) => {
        // divide the video timing into particular timestamps in respective to number of thumbnails
        // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
        // we will use this timestamp to take snapshots
        // console.log('duration',duration, duration / numberOfThumbnails)
        // for (let i = 0; i <= duration; i += duration / numberOfThumbnails) {
        //   fractions.push(Math.floor(i));
        // }
        console.log('getVideoDuration fhfhfh', duration);
        fractions = Math.floor(duration / 2);
        thumbnail = getVideoThumbnail(videoFile, fractions);
        resolve(thumbnail);
      });
      reject('something went wront');
    });
  };

  const getVideoThumbnail = (file, videoTimeInSeconds) => {
    return new Promise((resolve, reject) => {
      console.log('getVideoThumbnail 1');
      if (file.type.match('video')) {
        importFileandPreview(file).then((urlOfFIle) => {
          console.log('getVideoThumbnail 2');
          var video = document.createElement('video');
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
          };

          video.addEventListener('loadeddata', function () {
            console.log('video.addEventListener 3');

            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
          });
          var snapImage = function () {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext('2d')
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              URL.revokeObjectURL(urlOfFIle);
              resolve(image);
            }
            return success;
          };
          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = urlOfFIle;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.currentTime = videoTimeInSeconds;
          video.play();
        });
      } else {
        reject('file not valid');
      }
    });
  };

  /**
   *
   * @param videoFile {File}
   * @returns {number} the duration of video in seconds
   */
  const getVideoDuration = (videoFile) => {
    return new Promise((resolve, reject) => {
      console.log('getVideoDuration 1');
      if (videoFile) {
        console.log('getVideoDuration 2');
        if (videoFile.type.match('video')) {
          console.log('getVideoDuration');

          importFileandPreview(videoFile)
            .then((url) => {
              console.log('getVideoDuration 3', url);

              try {
                let video = document.createElement('video');
                console.log(video);
                video.addEventListener('loadeddata', function () {
                  resolve(video.duration);
                });
                console.log(video);
                video.preload = 'metadata';
                video.src = url;
                console.log(video);
                // Load video in Safari / IE11
                video.muted = true;
                video.playsInline = true;
                video.play();
              } catch (error) {
                reject(0);
              }
              //  window.URL.revokeObjectURL(url);
            })
            .catch(() => {
              reject(0);
            });
        }
      } else {
        reject(0);
      }
    });
  };

  const onChangeYoutubeField = (event) => {
    event.persist();

    let name = event.target.name;
    let val = event.target.value;

    setYoutubeData({
      ...youtubeData,
      [name]: val,
    });
  };

  const uploadYoutubeUrl = () => {
    if (Object.keys(validate()).length > 0) {
      setYoutubeFormError(validate());
      return;
    }
    setIsLoading(true);
    console.log('outside the function ');
    if (Object.keys(validate()).length == 0) {
      console.log('inside youtube url block', youtubeData);
      let apiURL = '';
      const createdBy = getUserInfo?.Userid;
      if (platformType === 'Google Ad') {
        apiURL = `${baseUrlStg}/api/media-upload/youtube-url-upload?platform=${'Google_Ad'}&createdBy=${createdBy}`;
      } else {
        apiURL = `${baseUrlStg}/api/media-upload/youtube-url-upload?platform=${platformType}&createdBy=${createdBy}`;
      }

      const requestData = {
        ...youtubeData,
        // Add the createdBy field here
      };

      axios({
        method: 'post',
        url: apiURL,
        data: requestData,
        headers: {
          'Content-Type': 'application/json-patch+json',
          ...CustomHeader,
        },
      })
        .then((res) => {
          if (res.data.success) {
            // alert(res.data.message)
            setYoutubeData({
              fileName: '',
              url: '',
              duration: '00:00:00',
            });
            setYoutubeFormError({});
            setIsLoading(false);
            setUploadPopup(true);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setApiError(err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  const validate = () => {
    const errors = {};

    if (youtubeData.fileName === '' || !youtubeData.fileName) {
      errors.fileName = 'Please Enter the Name';
    } else if (youtubeData.fileName && youtubeData.fileName.length > 150) {
      errors.fileName = '150 length';
    }
    var regex =
      /^(?:https?:\/\/)?(www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (youtubeData.url === '' || !youtubeData.url) {
      errors.url = 'Please Enter the Youtube Url';
    } else if (!regex.test(youtubeData.url)) {
      errors.url = 'Please Enter Valid Youtube Url';
    }

    if (youtubeData.duration === '00:00:00' || !youtubeData.duration) {
      errors.duration = 'Please Enter the Video Duration';
    }

    return errors;
  };
  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container facebook-step-one'>
          <div className='container'>
            <div className='fb-panel'>
              <Sidebar />
              <div className='right-side-box padding-bottom-extra media-section-left'>
                <header>
                  <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb'>
                    <div className='top-left-bar'>
                      {fileType?.toLowerCase() == 'youtube url' ? (
                        <h3>Add Youtube URL</h3>
                      ) : (
                        <h3>Upload Files</h3>
                      )}
                    </div>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={handleCancel}
                          type='button'
                          className='primary-btn close-btn'
                        >
                          <i className='icon-close' />
                        </button>
                      </div>
                    </div>
                  </div>
                </header>
                {fileType?.toLowerCase() != 'youtube url' && (
                  <section className='side-menu-form '>
                    {fileType?.toLowerCase() === 'video' &&
                      platformType.toLowerCase() === 'facebook' && (
                        <div className='uploadInfo'>
                          {/* <i className='icon-info'></i> */}
                          <p>
                            Uploading video on facebook takes time, as the
                            platform processes them first. Once processed, you
                            can use them in your ads
                          </p>
                        </div>
                      )}
                    <div className='media-modal-cont upload-fileCont'>
                      <div className='media-modal-left'>
                        <div
                          className='dropzone-wrapper'
                          draggable='true'
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <div className='dropzone-desc'>
                            <div className='img-wrap'>
                              <img src={uploadImg} alt='Upload File' />
                            </div>
                            <p>
                              Drop your files here.
                              <br /> or <span>Browse</span>
                            </p>
                          </div>
                          <input
                            type='file'
                            name='img_logo'
                            className='dropzone'
                            onChange={handleOnChnageInput}
                            multiple
                            // accept="image/*,video/*"
                            // accept={`${fileType?.toLowerCase() == 'image' ? 'image/*' : ''}${fileType?.toLowerCase() == 'video' ? 'video/*' : ''}  ${fileType?.toLowerCase() == 'html' ? '.html' : ''} ${fileType?.toLowerCase() == 'audio' ? 'audio/*' : ''}`}
                            accept={supportedFiletypes.toString()}
                          />
                        </div>
                      </div>

                      <div className='media-modal-right'>
                        {fileList.map((file, i) => {
                          return (
                            <div className='media-upload-list' key={i}>
                              <div className='upload-img'>
                                {fileType.toLowerCase() === 'image' ? (
                                  <i className='icon-image' />
                                ) : null}
                                {fileType.toLowerCase() === 'video' ? (
                                  <i className='icon-video' />
                                ) : null}
                                {fileType.toLowerCase() === 'audio' ? (
                                  <i className='icon-audio-fill' />
                                ) : null}
                                {fileType.toLowerCase() === 'html5' ? (
                                  <i className='icon-html' />
                                ) : null}
                              </div>
                              <div className='upload-cont'>
                                {file.edit && (
                                  <div className='card-form form-panel'>
                                    <div className='row align-items-center'>
                                      <div className='form-item col-8'>
                                        <div className='input-group'>
                                          <input
                                            type='text'
                                            value={editInputValue}
                                            onChange={handleEditInput}
                                            placeholder='Edit Image Name'
                                          />
                                        </div>
                                        {inputError && (
                                          <span className='error'>
                                            Please Enter Valid Name
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {!file.edit && (
                                  <>
                                    <h3>{file.name}</h3>
                                    <p>{formatFileSize(file.size)}</p>
                                  </>
                                )}
                                {
                                  <div>
                                    {file.failed && (
                                      <span className='error reletive'>
                                        {file.message
                                          ? file.message
                                          : 'Failed to Upload!'}
                                      </span>
                                    )}
                                    {/* {file.success && <span className="error">{file.message }</span>} */}
                                  </div>
                                }

                                {/* <div className="progress">
                            <div
                              className="progress-bar progress-bar-success"
                              role="progressbar"
                              aria-valuenow={40}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: "40%" }}
                            ></div>
                          </div> */}
                              </div>
                              <div className='upload-btn'>
                                {file.success ? (
                                  <div className='success'>
                                    <i className='icon-check-mark' />
                                  </div>
                                ) : // (<><button className="close edit-link" onClick={() => handleEditFileName(i)}>
                                //   <i className="icon-edit"  />
                                // </button>
                                //   <button className="close" onClick={() => handleDeleteFile(i)}>
                                //     <i className="icon-close" />
                                //   </button></>)
                                !file.loading ? (
                                  <>
                                    {!file.failed && (
                                      <button
                                        className={`${
                                          file.edit
                                            ? 'success'
                                            : 'close edit-link'
                                        }`}
                                        disabled={isUploading}
                                        onClick={() => handleEditFileName(i)}
                                      >
                                        <i
                                          className={`${
                                            file.edit
                                              ? 'icon-check-mark'
                                              : 'icon-edit'
                                          }`}
                                        />
                                      </button>
                                    )}
                                    {!file.edit && (
                                      <button
                                        className='close'
                                        onClick={() => handleDeleteFile(i)}
                                        disabled={isUploading}
                                      >
                                        <i className='icon-close' />
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <LoaderSpinner />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}
                {fileType?.toLowerCase() == 'youtube url' && (
                  <section className='card-primary'>
                    <div class=''>
                      <div class='card-header-top d-flex'>
                        <div class='form-check-label'>
                          <h6 class='d-flex align-items-center'>Name</h6>
                        </div>
                      </div>
                      <div class='card-body'>
                        <div class='card-form form-panel'>
                          <div class='row align-items-center'>
                            <div class='form-item col-12'>
                              <div class='label-icon'>
                                <label>Name</label>
                              </div>
                              <div class='input-group'>
                                <input
                                  type='text'
                                  id='email'
                                  name='fileName'
                                  value={youtubeData?.fileName}
                                  onChange={onChangeYoutubeField}
                                  placeholder='Name your video file'
                                />
                              </div>
                              {youtubeFormError.fileName && (
                                <span class='error' id='emailError'>
                                  {youtubeFormError.fileName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=''>
                      <div class='card-header-top d-flex'>
                        <div class='form-check-label'>
                          <h6 class='d-flex align-items-center'>Youtube Url</h6>
                        </div>
                      </div>
                      <div class='card-body'>
                        <div class='card-form form-panel'>
                          <div class='row align-items-center'>
                            <div class='form-item col-12'>
                              <div class='label-icon'>
                                <label>Youtube Url</label>
                              </div>
                              <div class='input-group'>
                                <input
                                  type='text'
                                  id='email'
                                  name='url'
                                  value={youtubeData?.url}
                                  onChange={onChangeYoutubeField}
                                  placeholder='Paste the link of your video file'
                                />
                              </div>
                              {youtubeFormError.url && (
                                <span class='error' id='emailError'>
                                  {youtubeFormError.url}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=''>
                      <div class='card-header-top d-flex'>
                        <div class='card-lt-pane'>
                          <h6 class='d-flex align-items-center'>
                            Video Duration
                          </h6>
                        </div>
                      </div>
                      <div class='card-body'>
                        <div class='card-form form-panel'>
                          <div class='row align-items-center'>
                            <div class='form-item col-12'>
                              <div class='label-icon'>
                                <label>Video Duration</label>
                              </div>
                              <div class='input-group'>
                                <input
                                  type='time'
                                  id='email'
                                  name='duration'
                                  value={youtubeData?.duration}
                                  onChange={onChangeYoutubeField}
                                  placeholder='Enter your video duration'
                                  step='2'
                                  min='12:59:59'
                                />
                              </div>
                              {youtubeFormError.duration && (
                                <span class='error' id='emailError'>
                                  {youtubeFormError.duration}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </section>
                )}
              </div>
              <footer className='footer-cont media-section-left'>
                <div className='footer-main'>
                  <div className='footer-btn'>
                    <div className='footer-left-cont'>
                      {fileType?.toLowerCase() != 'youtube url' && (
                        <p>
                          {selectedFiles.length}/10{' '}
                          {selectedFiles.length === 1 ? 'file' : 'files'} added
                        </p>
                      )}
                    </div>
                    <div className='footer-right-cont'>
                      {fileType?.toLowerCase() != 'youtube url' && (
                        <button
                          onClick={handleUpload}
                          disabled={isUploading || selectedFiles.length == 0}
                          className='primary-btn'
                        >
                          Upload
                        </button>
                      )}
                      {fileType?.toLowerCase() == 'youtube url' && (
                        <button
                          onClick={uploadYoutubeUrl}
                          className='primary-btn'
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </main>
      {/* Modal */}
      <Modal
        show={uploadPopup}
        onHide={() => setUploadPopup(false)}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-success'>
              <i className='icon-check-mark' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Video uploaded successfully</h3>
            {/* <p>
              The campaign has been sent to Facebook for approval and will be publish in
              sometime
            </p> */}
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => setUploadPopup(false)}
          >
            Ok
          </button>
        </div>
      </Modal>
      <Modal
        show={alerts}
        onHide={() => {
          setAlerts(false);
          setAlertMessage({
            message: '',
            type: '',
          });
        }}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-inactive'>
              <i
                className={`${
                  alertMessage?.type === 'file' ? 'icon-file ' : ''
                }${alertMessage?.type === 'edit' ? 'icon-edit ' : ''}`}
              />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>{alertMessage?.message}</h3>
            {/* <p>
              The campaign has been sent to Facebook for approval and will be publish in
              sometime
            </p> */}
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => {
              setAlerts(false);
              setAlertMessage({
                message: '',
                type: '',
              });
            }}
          >
            Ok
          </button>
        </div>

        {/* <Modal.Footer>
              <Button variant="secondary" >
                Close
              </Button>
              <Button variant="primary" onClick={deleteItems}>
                Save Changes
              </Button>
            </Modal.Footer> */}
      </Modal>
      <div
        className='modal fade atd-modal approved-model'
        id='approvedState'
        tabIndex={-1}
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='modal-img-cont'>
                <figure className='modal-icon modal-success'>
                  <i className='icon-check-mark' />
                </figure>
              </div>
              <div className='modal-text'>
                <h3>Thank you for approving the request!</h3>
                <p>
                  The campaign has been sent to Facebook for approval and will
                  be publish in sometime
                </p>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn'
                data-bs-dismiss='modal'
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Approved Modal Start Here */}
      <div
        className='modal fade atd-modal reject-model'
        id='rejectState'
        tabIndex={-1}
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='modal-img-cont'>
                <figure className='modal-icon modal-remove'>
                  <i className='icon-close' />
                </figure>
              </div>
              <div className='modal-text'>
                <h3>Rejection of request</h3>
                <p>Please type reason for rejection of request</p>
              </div>
              <div className='form-panel'>
                <div className='form-item-cont row'>
                  <div className='form-item col-12'>
                    <label>Type Reason</label>
                    <div className='input-sizer stacked'>
                      <textarea
                        // oninput="this.parentNode.dataset.value = this.value"
                        rows={1}
                        placeholder='Type Reason'
                        defaultValue={''}
                      />
                    </div>
                    <span className='error'> Please Type Reason </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn cancel-btn'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='primary-btn primary-remove'
                data-bs-dismiss='modal'
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Approved Modal Ends Here */}
      {/* Approved Modal Start Here */}
      <div
        className='modal fade atd-modal media-modal'
        id='selectfile'
        tabIndex={-1}
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3>Select Files</h3>
              <button
                type='button'
                className='primary-btn'
                data-bs-dismiss='modal'
              >
                <i className='icon-close' />
              </button>
            </div>
            <div className='modal-body'>
              <div className='tab-new-form'>
                <div className='camp-tab-cont d-flex'>
                  <div className='short-left-bar'>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                      <button
                        className='primary-btn active'
                        id='edit-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#edit'
                        type='button'
                        role='tab'
                        aria-selected='true'
                      >
                        <i className='icon-edit' /> All
                      </button>
                      <button
                        className='primary-btn'
                        id='review-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#review'
                        type='button'
                        role='tab'
                        aria-selected='false'
                      >
                        <i className='icon-show-password' />
                        Images
                      </button>
                      <button
                        className='primary-btn'
                        id='history-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#history'
                        type='button'
                        role='tab'
                        aria-selected='false'
                      >
                        <i className='icon-media-plan' />
                        Videos
                      </button>
                    </div>
                  </div>
                </div>
                <div className='tab-content scroll-vertical' id='SelectMedia'>
                  <div
                    className='tab-pane fade show active'
                    id='all'
                    role='tabpanel'
                    aria-labelledby='all-tab'
                  >
                    <div className='card-primary'>
                      <div className='media-fillter-btns'>
                        <div className='fillter-btns d-flex'>
                          <div className='fillter-left d-flex align-items-center'>
                            <div className='dropdown-select'>
                              <button
                                className='btn-icon btn-icon-primary btn-bedcrumb-act dropdown-icon'
                                type='button'
                                id='dropdownMenuButton2'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                By Dimensions
                              </button>
                              <ul
                                className='dropdown-menu '
                                aria-labelledby='dropdownMenuButton2'
                              >
                                <li>
                                  <a
                                    className='dropdown-item'
                                    href='javascript:void(0)'
                                  >
                                    1200 x 628{' '}
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className='dropdown-item'
                                    href='javascript:void(0)'
                                  >
                                    1080 x 1080{' '}
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className='dropdown-item'
                                    href='javascript:void(0)'
                                  >
                                    1080 x 1920{' '}
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className='dropdown-item'
                                    href='javascript:void(0)'
                                  >
                                    600 x 315{' '}
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                    />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className='select-box select-multiple'>
                              <select
                                className='selectpicker'
                                multiple=''
                                aria-label='Default select example'
                                data-live-search='true'
                              >
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                                <option value={4}>Four</option>
                              </select>
                            </div>
                            <div className='dropdown-select'>
                              <button
                                className='btn-icon btn-icon-primary btn-bedcrumb-act dropdown-icon daterange'
                                type='button'
                                name='daterange'
                                aria-expanded='false'
                              >
                                Date Range
                              </button>
                            </div>
                          </div>
                          <div className='fillter-right ml-auto'>
                            <button
                              type='button'
                              className='btn-icon single-icon btn-icon-primary btn-bedcrumb-act'
                            >
                              <i className='icon-delete' />
                            </button>
                            <button
                              type='button'
                              className='btn-icon btn-icon-primary'
                            >
                              <i className='icon-plus' />
                              Upload
                            </button>
                          </div>
                        </div>
                        <div className='fillter-options'>
                          <button className='clear-btn'>Clear All</button>
                          <span>
                            File Type: <b>Image</b>{' '}
                            <a href=''>
                              <i className='icon-close' />
                            </a>
                          </span>
                          <span>
                            Dimensions: 1600 x 800, 800 x 600, 640 x 320{' '}
                            <a href=''>
                              <i className='icon-close' />
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h6 className='mt-0'>
                            <i className='icon-images' />
                            Account images
                          </h6>
                        </div>
                        <div className='me-auto card-switch-rigth'>
                          <p>
                            Showing 1 to 3 of 3 entries <a href='#'>See all</a>
                          </p>
                        </div>
                      </div>
                      <div className='media-list'>
                        <div className='media-card select-media-card'>
                          <input type='checkbox' />
                          <div className='select-media'>
                            <div className='mediaimg-box'>
                              <div className='media-img'>
                                <img src='images/media-lib1.jpg' alt='' />
                              </div>
                            </div>
                            <div className='mediabox-cont'>
                              <h3>Image-21.png</h3>
                              <p>800 x 535px • 56 KB</p>
                            </div>
                          </div>
                        </div>
                        <div className='media-card select-media-card'>
                          <input type='checkbox' />
                          <div className='select-media'>
                            <div className='mediaimg-box'>
                              <div className='media-img'>
                                <img src='images/media-lib1.jpg' alt='' />
                              </div>
                            </div>
                            <div className='mediabox-cont'>
                              <h3>Image-21.png</h3>
                              <p>800 x 535px • 56 KB</p>
                            </div>
                          </div>
                        </div>
                        <div className='media-card select-media-card'>
                          <input type='checkbox' />
                          <div className='select-media'>
                            <div className='mediaimg-box'>
                              <div className='media-img'>
                                <img src='images/media-lib1.jpg' alt='' />
                              </div>
                            </div>
                            <div className='mediabox-cont'>
                              <h3>Image-21.png</h3>
                              <p>800 x 535px • 56 KB</p>
                            </div>
                          </div>
                        </div>
                        <div className='media-card select-media-card'>
                          <input type='checkbox' />
                          <div className='select-media'>
                            <div className='mediaimg-box'>
                              <div className='media-img'>
                                <img src='images/media-lib1.jpg' alt='' />
                              </div>
                            </div>
                            <div className='mediabox-cont'>
                              <h3>Image-21.png</h3>
                              <p>800 x 535px • 56 KB</p>
                            </div>
                          </div>
                        </div>
                        <div className='media-card select-media-card'>
                          <input type='checkbox' />
                          <div className='select-media'>
                            <div className='mediaimg-box'>
                              <div className='media-img'>
                                <img src='images/media-lib1.jpg' alt='' />
                              </div>
                            </div>
                            <div className='mediabox-cont'>
                              <h3>Image-21.png</h3>
                              <p>800 x 535px • 56 KB</p>
                            </div>
                          </div>
                        </div>
                        <div className='media-card select-media-card'>
                          <input type='checkbox' />
                          <div className='select-media'>
                            <div className='mediaimg-box'>
                              <div className='media-img'>
                                <img src='images/media-lib1.jpg' alt='' />
                              </div>
                            </div>
                            <div className='mediabox-cont'>
                              <h3>Image-21.png</h3>
                              <p>800 x 535px • 56 KB</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='images'
                    role='tabpanel'
                    aria-labelledby='images-tab'
                  >
                    Images
                  </div>
                  <div
                    className='tab-pane fade'
                    id='video'
                    role='tabpanel'
                    aria-labelledby='video-tab'
                  >
                    Video
                  </div>
                </div>
              </div>
              <div className='selected-files'>
                <h4>1 Image Selected</h4>
                <div className='files'>
                  <div className='img-file'>
                    <img src='images/media-lib1.jpg' alt='' />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn cancel-btn'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='primary-btn '
                data-bs-dismiss='modal'
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Approved Modal Ends Here */}

      <div>
        {/* <Modal> */}

        {/* </Modal> */}
      </div>
    </>
  );
};
