import moment from 'moment';

import ReactDatePicker from 'react-datepicker';

const CommonDatePicker = (props) => {
  return (
    <>
      <div className='label-icon'>
        <label>{props?.label}</label>
      </div>
      <div className='input-group'>
        <span className='input-group-text rs-text'>
          <i className='icon-calendar' />
        </span>
        <ReactDatePicker
          onChange={(e) => props?.changeHandler(e)}
          selected={props?.selectedDate}
          dateFormat='dd/MM/yyyy'
          showMonthDropdown
          showYearDropdown
          placeholderText='DD/MM/YYYY'
          name='flight_start_date'
          minDate={moment().toDate()}
        />
      </div>
    </>
  );
};

export default CommonDatePicker;
