const AudioComponent = (props) => {
  return (
    <>
      <input
        className='form-check-input'
        type='radio'
        name='campaignGoal'
        value='audio'
        id='flexRadioDefault1'
        onClick={() => props?.handleLineItemSelect('audio')}
        checked={props?.selected_line_item == 'audio' && true}
      />
      <label className='form-check-label' htmlFor='flexRadioDefault1'>
        <i className='icon-audio-fill'></i>
        Audio
      </label>
    </>
  );
};

export default AudioComponent;
