export const roleMapping = {
  Admin: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: true,
    Meta: false,
    GoogleAds: false,
    Dv360: false,
    MediaLibrary: false,
  },
  CreatorMeta: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: true,
    GoogleAds: false,
    Dv360: false,
    MediaLibrary: true,
  },
  CreatorGoogle: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: false,
    GoogleAds: true,
    Dv360: false,
    MediaLibrary: true,
  },
  CreatorDV360: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: false,
    GoogleAds: false,
    Dv360: true,
    MediaLibrary: true,
  },
  PublisherMeta: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: true,
    GoogleAds: false,
    Dv360: false,
    MediaLibrary: true,
  },
  PublisherGoogle: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: false,
    GoogleAds: true,
    Dv360: false,
    MediaLibrary: true,
  },
  PublisherDV360: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: false,
    GoogleAds: false,
    Dv360: true,
    MediaLibrary: true,
  },
  CreatorGoogleDV360: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: false,
    GoogleAds: true,
    Dv360: true,
    MediaLibrary: true,
  },
  CreatorGoogleDV360Meta: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: true,
    GoogleAds: true,
    Dv360: true,
    MediaLibrary: true,
  },
  PublisherGoogleDV360: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: false,
    GoogleAds: true,
    Dv360: true,
    MediaLibrary: true,
  },
  PublisherGoogleDv360Meta: {
    TaxonomyManager: true,
    TaxonomyManagerAllExceptCreateMediaPlanButton: true,
    UserManagement: false,
    Meta: true,
    GoogleAds: true,
    Dv360: true,
    MediaLibrary: true,
  },
};

// getRoleMapping;
export function getRoleMapping(user) {
  if (user.roles.toLowerCase() === 'admin') {
    // If the user is an admin, return an object with all values set to false
    return {
      meta: false,
      google: false,
      dv360: false,
    };
  }

  // If the user is not an admin, proceed with setting platform values based on user's access
  let platforms = user.platformAccess.split(',');

  // Create an object with default values set to false
  let platformInfo = {
    meta: false,
    google: false,
    dv360: false,
  };

  // Set values to true based on the platforms in the user object
  platforms.forEach((platform) => {
    let platformKey = platform.trim().toLowerCase();
    if (platformInfo.hasOwnProperty(platformKey)) {
      platformInfo[platformKey] = true;
    }
  });

  return platformInfo;
}
