import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/common/Sidebar';
import Footer from '../../components/common/Footer';
import NavHeading from '../../components/common/NavHeading';
import { useNavigate, useParams } from 'react-router-dom';
import { getApi, postApi } from '../../utils/Apis';
import { baseUrlStg, userManagement } from '../../utils/constants';
import DeleteUser from './DeleteUser';
import './Userflow.css';
import { extractInitials } from '../../utils/utils';
import moment from 'moment';
import CommonEnableDisableModal from '../../components/common/CommonEnableDisableModal';

const UserDetail = () => {
  const navigate = useNavigate();
  const [getShowEnabledDisabledModal, setShowEnabledDisabledModal] =
    useState(false);

  const GotoPage = userManagement?.userList;
  const goToListingPage = () => {
    navigate(GotoPage);
  };

  const genderOptions = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
  ];

  const roleOptions = [
    { label: 'Admin', value: 1 },
    { label: 'Creator', value: 2 },
    { label: 'Publisher', value: 3 },
  ];

  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: null,
    role: '',
    facebookAds: false,
    googleAds: false,
    dv360: false,
    userStatus: false,
  });

  const [isDelete, setIsDelete] = useState(false);
  const [isEnabled, setIsEnabled] = useState(formData?.userStatus);

  const getUserDetails = async () => {
    if (id) {
      // Call the getApi to fetch user details using the id
      try {
        const response = await getApi(
          `${baseUrlStg}/api/UserManagement/GetUserById?id=${id}`,
          {
            accept: '*/',
            'Content-Type': 'multipart/form-data',
          },
        );
        const mappedRole = roleOptions.find(
          (option) => option?.label == response?.response?.roleId,
        );

        // Update the formData state with the retrieved user details
        setFormData({
          ...response,
          name: response.response.name,
          email: response.response.emailAddress,
          phone: response.response.mobile,
          gender: {
            label: response.response.gender === 'M' ? 'Male' : 'Female',
            value: response.response.gender,
          },
          role: {
            label: mappedRole ? mappedRole?.label : '',
            value: mappedRole ? mappedRole?.value : '',
          },
          facebookAds: response.response.platformAccess.includes('Meta'),
          googleAds: response.response.platformAccess.includes('Google'),
          dv360: response.response.platformAccess.includes('DV360'),
          userStatus: response?.response?.userStatus,
        });
        setIsEnabled(response?.response?.userStatus);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    }
  };
  useEffect(() => {
    getUserDetails();
  }, [id]);

  const handleAction = async () => {
    const payload = [id];

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrlStg}/api/GoogleAdListing/Enabale-disable-user?status=${!isEnabled}`,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json-patch+json',
      },
      data: payload,
    };

    try {
      const response = await postApi(config.url, config.data, config.headers);

      if (response.data.success) {
        setShowEnabledDisabledModal(false);
        getUserDetails();
      } else {
        setShowEnabledDisabledModal(false);
        getUserDetails();
      }
    } catch (error) {
      console.error('Error while performing action:', error);
    }
  };
  const handleChange = () => {
    setShowEnabledDisabledModal(true);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <NavHeading HeadingName={'Profile Details'} />

            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i
                      className='icon-close'
                      onClick={() => goToListingPage()}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <main>
              <div class='main-container facebook-step-one'>
                <div class='container'>
                  <div class='fb-panel'>
                    <article class='side-right-form-wrap'>
                      <div class='tab-new-form'>
                        <div class='user-camp-box d-flex'>
                          <div class='short-left-bar'>
                            <a
                              href='#'
                              class='primary-btn active'
                              onClick={() => goToListingPage()}
                            >
                              Back
                            </a>
                          </div>
                          <div class='short-right-cont'>
                            {/* <button
                              class='btn-icon btn-icon-primary reject-act'
                              type='button'
                              data-bs-toggle='modal'
                              data-bs-target='#rejectState'
                              onClick={() => setIsDelete(true)}
                            >
                              <i class='icon-delete'></i> Delete
                            </button> */}

                            {isEnabled && (
                              <button
                                class='btn-icon btn-icon-primary approve-act'
                                type='button'
                                data-bs-toggle='modal'
                                data-bs-target='#approvedState'
                                onClick={() =>
                                  navigate(`${userManagement.updateUser}/${id}`)
                                }
                              >
                                <i class='icon-edit'></i> Edit User Details
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class='tab-content scroll-vertical'>
                        <div class='user-details-cont'>
                          <div class='card-primary-repet'>
                            <div class='card-primary'>
                              <div class='user-profile-wrap'>
                                <div class='user-profile-details d-flex'>
                                  <div class='short-left-bar'>
                                    <div class='profile-box'>
                                      <h3>{extractInitials(formData.name)}</h3>
                                      {/* <small class='profile-dot'></small> */}
                                    </div>
                                    <div class='user-tag-role'>
                                      <h5>{formData.name}</h5>
                                      <p>Role:{formData.role.label} </p>
                                      <p class='pl-tag'>
                                        Platform Access:{' '}
                                        {formData.response?.platformAccess.map(
                                          (item, index) => (
                                            <span key={index}>{item}</span>
                                          ),
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div class='short-right-cont'>
                                    <div class='form-check form-switch'>
                                      <label class='switch'>
                                        <input
                                          type='checkbox'
                                          id='togBtn'
                                          checked={isEnabled}
                                          onChange={() => handleChange()}
                                        />
                                        <small class='slider round'>
                                          <span class='on'>Enable</span>
                                          <span class='off'>Disable</span>
                                        </small>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class='user-details-data'>
                                <div class='user-heading'>
                                  <h6>Personal Information</h6>
                                </div>
                                <div class='user-details-mess'>
                                  <h5>Name</h5>
                                  <p>{formData?.name}</p>
                                </div>
                                {/* <div class='user-details-mess'>
                                  <h5>Last Name</h5>
                                  <p>Kannojia</p>
                                </div> */}
                                <div class='user-details-mess'>
                                  <h5>Email Address</h5>
                                  <p>{formData?.email}</p>
                                </div>
                                <div class='user-details-mess'>
                                  <h5>Gender</h5>
                                  <p>
                                    {formData?.gender == 'M'
                                      ? 'Male'
                                      : 'Female'}
                                  </p>
                                </div>

                                <div class='user-details-mess'>
                                  <h5>Created on</h5>
                                  <p>
                                    {moment(
                                      formData?.response?.createdAt,
                                    ).format('DD-MMM-YYYY HH:mm')}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>

                    <footer class='footer-cont'>
                      <div class='footer-main'>
                        <div class='footer-btn'>
                          <div class='footer-left-cont'>
                            <button class='primary-btn footer-close-btn'>
                              Close
                            </button>
                          </div>
                          <div class='footer-right-cont'>
                            <button
                              class='primary-btn footer-close-btn'
                              onClick={() => goToListingPage()}
                            >
                              Back
                            </button>
                            <button class='primary-btn'>Next</button>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

      <Footer closePath={GotoPage} prevPath={GotoPage}></Footer>

      <DeleteUser
        show={isDelete}
        id={id}
        handleClose={() => setIsDelete(false)}
        handleDelete={() => setIsDelete(false)}
        afterDelete={() => goToListingPage()}
      ></DeleteUser>

      <CommonEnableDisableModal
        show={getShowEnabledDisabledModal}
        handleClose={() => setShowEnabledDisabledModal(false)}
        copy={
          !isEnabled
            ? 'Do you want to Enable this User'
            : 'Do you want to Disable this User'
        }
        type={!isEnabled ? 'Enable' : 'Disable'}
        handleAction={handleAction}
      />
    </div>
  );
};

export default UserDetail;
