import React from 'react';
import Card from '../common/Card';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';
import { useId } from 'react';
import { generateRandomId } from '../../utils/utils';
import { GoogleStatus } from '../../pages/googleAds/GoogleAdConstant';

const id = generateRandomId(4);

const LanguagesUI = ({
  googleLanguages,
  setSelectAllLanguage,
  selectAllLanguage,
  handleLanguages,
  options,
}) => {
  return (
    <Card>
      <CardHeader>
        <h5>
          Choose Languages
          <span
            className='label-information tooltips tool-bottom'
            tooltip='Cascading Style Sheets'
            tooltip-position='top'
          >
            <i className='icon-info'></i>
            <span>
              Language targeting allows you to restrict where your ads can
              appear based on the user's language settings and the language of
              the site. Ads can be shown to people who understand any, but not
              necessarily all of the languages you select.
            </span>
          </span>
        </h5>
        <h6>Select the languages your customers speak.</h6>
      </CardHeader>
      <CardBody>
        {/* <div className='form-check-box'>
          <input
            className='form-check-input'
            type='checkbox'
            name='targetCpa'
            onChange={(event) => setSelectAllLanguage(event.target.checked)}
            id={id}
            defaultValue='option1'
            defaultChecked={!!selectAllLanguage}
            disabled={googleLanguages?.length > 0 ? true : false}
          />
          <label className='form-check-label' htmlFor={id}>
            <h6> &nbsp; All Languages</h6>
          </label>
        </div>

        <br /> */}
        <div className='form-item '>
          <div className='select-box select-multiple-components '>
            <ReactSelect
              placeholder={'Select Languages'}
              isSearchable={true}
              isClearable={false}
              closeMenuOnSelect={true}
              value={googleLanguages}
              isMulti={true}
              // isDisabled={!!selectAllLanguage}
              options={options}
              onChange={handleLanguages}
            />
          </div>
          <div className='error' id='nameError'>
            {/* {fromErrors.AudienceSegment} */}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default LanguagesUI;
