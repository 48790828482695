import axios from 'axios';
import ReactDOM from 'react-dom';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactSelect from '../../../components/common/ReactSelect';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import noDataFound from '../../../assets/images/no-media.png';
import { Link, useNavigate } from 'react-router-dom';
import { UploadFilesWidget } from './UploadFilesWidget';
import { useSelector } from 'react-redux';
import { getCookies, renderMedia } from '../../../utils/utils';
import { tab } from '@testing-library/user-event/dist/tab';
import { baseUrlStg } from '../../../utils/constants';
import { aspectRatio } from '../mediaConstant';

const MediaWidget = ({
  show,
  handleClose,
  platformType,
  images,
  onlyOneImage,
  flag,
  total = 100,
  ratioStatus,
  ratio,
}) => {
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState(['html5', 'image']);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [platformDimensions, setPlatformDimensions] = useState([]);
  const [filterApply, setFilterApply] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false);
  const [apiError, setApiError] = useState('');
  const [showDeletePopup, setShowDeletePupup] = useState(false);
  const [openModalData, setOpenModalData] = useState(null);
  // const [show, setShow] = useState(true);
  const [widgetCreativeNameFilter, setWidgetCreativeNameFilter] =
    useState(false);
  const [creativeNameValue, setCreativeNameValue] = useState('');
  const [creativeNameError, setCreativeNameError] = useState(false);

  const [widgetTabData, setWidgetTabData] = useState([]);

  const itemsPerPage = 24;
  const [filters, setFilters] = useState({
    fileType: [],
    dimensions: [],
    aspectRatio: [],
    dates: {
      startDate: '',
      endDate: '',
    },
    creativeName: '',
  });
  const widgetCreativeRef = useRef();

  // Upload Files for MediaWidget
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedOption, setSelectedOption] = useState('');
  const [isShowWidget, setIsShowWidget] = useState(false);
  const [selecTedFileType, setSelecTedFileType] = useState('');
  const [selectedPlatformType, setSelectedPlatformType] = useState();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState([]);
  const [lastChecked, setLastChecked] = useState(null);
  const [getSelectedItemsLength, setSelectedItemsLength] = useState(1);
  const [getSelectedIds, setSelectedIds] = useState([]);
  // for lineItem media widget click
  const state = useSelector((state) => state);
  const DvCreateLineItemData = state?.DvCreateLineItem;
  const lineItem_type = DvCreateLineItemData?.selected_line_item || 'Display';
  // console.log('lineItem_type', lineItem_type);
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  // Button for Upload Only Image
  const handleOptionChange = () => {
    setSelecTedFileType(currentTab);
    setSelectedPlatformType(platformType);
    setIsShowWidget(true);
  };

  // Button for Upload Only All
  const handleOptionChangeReactSelect = (event) => {
    setSelecTedFileType(event[0].label);
    setSelectedPlatformType(platformType);
    setIsShowWidget(true);
  };

  let options = [
    { value: 'image', label: 'Image' },
    { value: 'HTML5', label: 'HTML' },
  ];

  //items Select for delete

  // const handleInputChecked = (event) => {
  //   const parsedValue = parseInt(event.target.value);

  //   if (onlyOneImage) {
  //     console.log('Check the only one image', lastChecked);
  //     if (lastChecked !== parsedValue) {
  //       setDeleteIds([parsedValue]);
  //       setSelectedData(data.filter((item) => item.mediaId === parsedValue));
  //     } else {
  //       setDeleteIds([]);
  //       setSelectedData([]);
  //     }
  //     setLastChecked(parsedValue);
  //   } else {
  //     if (event.target.checked) {
  //       setDeleteIds((prevIds) => [...prevIds, parsedValue]);
  //       const selectedMedia = data.filter(
  //         (item) => item.mediaId === parsedValue,
  //       );
  //       setSelectedData((prevData) => [...prevData, ...selectedMedia]);
  //     } else {
  //       const removeId = deleteIds.filter((id) => id !== parsedValue);
  //       setDeleteIds(removeId);

  //       const updatedData = selectedData.filter(
  //         (item) => item.mediaId !== parsedValue,
  //       );
  //       setSelectedData(updatedData);
  //     }
  //   }
  // };

  const handleInputChecked = (event) => {
    const parsedValue = parseInt(event.target.value);

    //store the selected item count start
    setSelectedIds([...getSelectedIds, parsedValue]);
    if (event?.target?.checked) {
      setSelectedItemsLength(selectedData?.length + 1);
    } else {
      setSelectedItemsLength(selectedData?.length - 1);
    }
    //store the selected item count end

    if (onlyOneImage) {
      console.log('Check the only one image', lastChecked);
      if (lastChecked !== parsedValue) {
        setDeleteIds([parsedValue]);
        setSelectedData(data.filter((item) => item.mediaId === parsedValue));
      } else {
        setDeleteIds([]);
        setSelectedData([]);
      }
      setLastChecked(parsedValue);
    } else {
      if (event.target.checked) {
        setDeleteIds((prevIds) => [...prevIds, parsedValue]);
        const selectedMedia = data.filter(
          (item) => item.mediaId === parsedValue,
        );
        setSelectedData((prevData) => [...prevData, ...selectedMedia]);
      } else {
        const removeId = deleteIds.filter((id) => id !== parsedValue);
        setDeleteIds(removeId);

        const updatedData = selectedData.filter(
          (item) => item.mediaId !== parsedValue,
        );
        setSelectedData(updatedData);
      }
    }
  };

  // Item select to check disbale functionality
  useEffect(() => {
    setSelectedItemsLength(images?.length);
    setSelectedIds([]);
    images?.map((v) => {
      setSelectedIds([...getSelectedIds, v?.mediaId || v?.medId]);
    });
  }, [show]);

  useEffect(() => {
    setDeleteIds(
      images?.map((image) => image?.id || image?.mediaId || image?.medId),
    );
    setSelectedData(images);
  }, [images]);

  const deleteitem = (id) => {
    if (id) {
      let postData = id;

      setIsLoading(true);
      axios({
        method: 'post',
        url: `${baseUrlStg}/api/MediaListing/deleteMedia`,
        data: postData,
        headers: {
          'Content-Type': 'application/json',
          ...CustomHeader,
        },
      })
        .then((result) => {
          if (result.data.status) {
            getData();
            setShowDeletePupup(false);
            setOpenModalData(null);
            // alert(result.data.message)
            setIsLoading(false);
          } else {
            // alert('Something went wrong!')
            setShowDeletePupup(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error.message);
          setIsLoading(false);
        })
        .then((result) => {
          if (result.data.status) {
            getData();
            setShow(false);
            setOpenModalData(null);
            alert(result.data.message);
            setIsLoading(false);
          } else {
            alert('Something went wrong!');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error.message);
          setIsLoading(false);
        });
    }
  };

  const deleteConformation = () => {
    if (deleteIds.length === 0) {
      return null;
    }

    setShowDeletePupup(true);
  };

  const deleteComfirmationClose = () => {
    setShowDeleteConfirmationPopup(false);
  };

  useEffect(() => {
    getData();
  }, [currentTab, currentPage, filters]);

  useEffect(() => {
    getWidgetDimensionData();
  }, []);

  function displaySelectedValue(lineItem_type) {
    if (lineItem_type == 'Display') {
      if (platformType === 'Google Ad') {
        setWidgetTabData(['Image']);
        setFilters((filter) => {
          return {
            ...filter,
            fileType: ['Image'],
          };
        });
        setCurrentTab('Image');
      } else {
        setWidgetTabData(['Html', 'Image']);
        setFilters((filter) => {
          return {
            ...filter,
            fileType: ['Html', 'Image'],
          };
        });
        setCurrentTab('All');
      }
    } else if (lineItem_type == 'video') {
      setWidgetTabData(['Video']);
      setCurrentTab('Video');
      setFilters((filter) => {
        return {
          ...filter,
          fileType: ['video'],
        };
      });
    } else if (lineItem_type === 'audio') {
      setWidgetTabData(['Audio']);
      setCurrentTab('Audio');
      setFilters((filter) => {
        return {
          ...filter,
          fileType: ['audio'],
        };
      });
    }
  }

  // New function Added by prashant start
  function displaySelectedValueNew(flag) {
    if (flag == 'Display') {
      if (platformType === 'Google Ad') {
        setWidgetTabData(['Image']);
        setFilters((filter) => {
          return {
            ...filter,
            fileType: ['Image'],
          };
        });
        setCurrentTab('Image');
      } else {
        setWidgetTabData(['Html', 'Image']);
        setFilters((filter) => {
          return {
            ...filter,
            fileType: ['Html', 'Image'],
          };
        });
        setCurrentTab('All');
      }
    } else if (flag == 'Youtube') {
      setWidgetTabData(['Youtube']);
      setCurrentTab('Youtube');
      setFilters((filter) => {
        return {
          ...filter,
          fileType: ['Youtube'],
        };
      });
    } else if (flag == 'video') {
      setWidgetTabData(['video']);
      setCurrentTab('video');
      setFilters((filter) => {
        return {
          ...filter,
          fileType: ['video'],
        };
      });
    } else if (flag == 'Html') {
      setWidgetTabData(['Html']);
      setCurrentTab('Html');
      setFilters((filter) => {
        return {
          ...filter,
          fileType: ['Html'],
        };
      });
    }
  }
  // New function Added by prashant end
  //  DropDown Code
  const getWidgetDimensionData = () => {
    const url = `${baseUrlStg}/api/MediaListing/PlatFormDimensionDropdown?Platform=${platformType}`;
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((result) => {
        console.log('result', result);
        setPlatformDimensions(result.data?.dimensionDropdown);
      })
      .catch((err) => {
        setApiError(err.response);
      });
  };

  useEffect(() => {
    displaySelectedValue(lineItem_type);
  }, [lineItem_type]);

  // Added by Prashant start
  useEffect(() => {
    displaySelectedValueNew(flag);
  }, [flag]);
  // Added by Prashant end

  //pagination page change handle
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // set Tab function

  const setTab = (tab) => {
    ClearFilters('All');
    setCurrentPage(0);
    setCurrentTab(tab);
    if (tab === 'All') {
      if (platformType === 'Google Ad') {
        tab = 'image';
      } else {
        (tab = 'image'), 'html';
      }
    }
    setFilters((filter) => {
      return {
        ...filter,
        fileType: [tab],
      };
    });
  };

  //clear filters
  const ClearFilters = (type) => {
    switch (type) {
      case 'All':
        setCurrentPage(0);
        setFilters({
          fileType: [],
          dimensions: [],
          dates: {
            startDate: '',
            endDate: '',
          },
          creativeName: '',
        });
        setCreativeNameValue('');
        setWidgetCreativeNameFilter(false);
        setFilterApply(false);
        break;
      case 'dimensions':
        // onClear()
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            dimensions: [],
          };
        });
        checkFilterApplied();
        break;
      case 'creativeName':
        // onClear()
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            creativeName: '',
          };
        });
        setCreativeNameValue('');
        checkFilterApplied();
        break;
      case 'dates':
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            dates: {
              startDate: '',
              endDate: '',
            },
          };
        });
        checkFilterApplied();
        break;

      default:
        break;
    }
  };

  // MediaListing Code
  const getData = async () => {
    let URL = `${baseUrlStg}/api/MediaListing/All_Meadia?Platform=${platformType}&page=${
      currentPage + 1
    }&limit=${itemsPerPage}`;

    if (filters?.fileType && filters?.fileType.length > 0) {
      setFilterApply(true);
      filters?.fileType.forEach((type) => {
        URL = URL + `&Filetype=${type}`;
      });
    }

    if (filters?.dimensions && filters?.dimensions.length > 0) {
      const Dimensions = filters?.dimensions.map((type) => type.value);

      setFilterApply(true);
      filters?.dimensions.forEach((type) => {
        URL = URL + `&Dimensions=${type.value}`;
      });
    }

    if (filters?.dates.startDate && filters?.dates.endDate) {
      setFilterApply(true);
      URL =
        URL +
        `&StartDate=${filters?.dates.startDate}&EndDate=${filters?.dates.endDate}`;
    }

    if (filters?.creativeName) {
      setFilterApply(true);
      URL = URL + `&CreativeName=${filters?.creativeName}`;
    }

    if (ratioStatus) {
      URL = URL + `&Ratio=${ratio}`;
    }

    if (filters?.aspectRatio && filters?.aspectRatio.length > 0) {
      const aspectRatios = filters?.aspectRatio.map((type) => type.value);
      console.log('filetypwess in api', aspectRatios);
      setFilterApply(true);
      filters?.aspectRatio.forEach((type) => {
        URL = URL + `&Ratio=${type.value}`;
      });
    }

    try {
      setIsLoading(true);
      await axios
        .get(URL, {
          headers: {
            accept: 'text/plain',
            ...CustomHeader,
          },
        })
        .then((resp) => {
          if (resp.data.data) {
            setData(resp.data.data);
            setTotalPages(Math.ceil(resp.data.totalRecords / itemsPerPage));
            setTotalRecords(resp.data.totalRecords);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      setApiError(error);
      setIsLoading(false);
    }
  };

  //
  const showHideCreative = () => {
    if (widgetCreativeNameFilter) {
      if (!creativeNameValue) {
        setCreativeNameError(false);
      }
      setWidgetCreativeNameFilter(false);
    } else {
      setWidgetCreativeNameFilter(true);
    }
  };

  // UseEffect for Creative Name Filter
  useEffect(() => {
    const handler = (event) => {
      if (
        widgetCreativeNameFilter &&
        widgetCreativeRef.current &&
        !widgetCreativeRef.current.contains(event.target)
      ) {
        setCreativeNameError(false);
        setWidgetCreativeNameFilter(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  const handleCreativeInput = (event) => {
    if (!event.target.value) {
      setCreativeNameError(true);
    } else {
      setCreativeNameError(false);
    }
    setCreativeNameValue(capitalizeFirstLetter(event.target.value));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const setCreativeName = () => {
    if (!creativeNameValue) {
      setCreativeNameError(true);
      return;
    }
    setCreativeNameError(false);
    setWidgetCreativeNameFilter(false);
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        creativeName: creativeNameValue,
      };
    });
  };

  // DatePickerRange
  const checkFilterApplied = () => {
    if (
      filters.creativeName === '' ||
      (filters.dates.startDate === '' && filters.dates.endDate === '') ||
      filters.dimensions.length === 0 ||
      filters.fileType.length === 0
    ) {
      setFilterApply(false);
    } else {
      setFilterApply(true);
    }
  };

  const setDates = (e, { startDate, endDate }) => {
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: startDate.format('YYYY/MM/DD'),
          endDate: endDate.format('YYYY/MM/DD'),
        },
      };
    });
    checkFilterApplied();
  };

  const onCancelDate = (e, { startDate, endDate }) => {
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: '',
          endDate: '',
        },
      };
    });
    checkFilterApplied();
  };

  // By DimensionCode
  const handleDimensionsChange = (e) => {
    setCurrentPage(0);
    setFilters((prevVal) => {
      return { ...prevVal, dimensions: e };
    });
    checkFilterApplied();
  };

  const handleAspectRatio = (e) => {
    console.log(e);
    setCurrentPage(0);
    setFilters((prevVal) => {
      return { ...prevVal, aspectRatio: e };
    });
    checkFilterApplied();
  };

  const formatFileSize = (bytes, decimalPoint) => {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const MenuItems = ({ items, depthLevel, addRef }) => {
    let ref = useRef();
    const [dropdown, setDropdown] = useState(false);
    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener('mousedown', handler);
      document.addEventListener('touchstart', handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener('mousedown', handler);
        document.removeEventListener('touchstart', handler);
      };
    }, [dropdown]);

    const onMouseEnter = () => {
      window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
      window.innerWidth > 960 && setDropdown(false);
    };

    return (
      <div
        className='menu-drpdwn-items'
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {items.submenu ? (
          <>
            <button
              type='button'
              aria-haspopup='menu'
              aria-expanded={dropdown ? 'true' : 'false'}
              onClick={() => setDropdown((prev) => !prev)}
            >
              <span>{items.title}</span> <i className='icon-right-arrow'></i>
              {/* {depthLevel > 0 ? (
                <span className="icon-right-arrow"></span>
              ) : (
                <span className="arrow" />
              )} */}
            </button>
            <Dropdown
              submenus={items.submenu}
              dropdown={dropdown}
              depthLevel={depthLevel}
            />
          </>
        ) : (
          <Link
            className='btn-icon btn-icon-primary drpdwn-btn'
            to={items.url}
            state={{ fileType: items.title, platformType: items.type }}
          >
            {items.title}
          </Link>
        )}
      </div>
    );
  };

  const Dropdown = ({ submenus, dropdown, depthLevel }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';
    return (
      <ul
        className={`dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}
        style={{ display: `${dropdown ? 'block' : 'none'}` }}
      >
        {submenus.map((submenu, index) => (
          <MenuItems items={submenu} key={index} depthLevel={depthLevel} />
        ))}
      </ul>
    );
  };

  // const handleImportsClose = () => {
  //   const newImages = selectedData.map((item) => {
  //     return {
  //       ...item,
  //       // imgName: item.mediaName,
  //       // url: item.mediaPath,
  //       // id: item.mediaId,
  //     };
  //   });
  //   // setImages(newImages);
  //   handleClose(newImages);
  // };
  const handleImportsClose = () => {
    if (selectedData.length === 0) {
      setErrorMessage('Please Select Media');
      return; // Do not proceed with importing if no data is selected
    } else {
      setErrorMessage('');
    }

    const newImages = selectedData.map((item) => ({
      ...item,
      // imgName: item.mediaName,
      // url: item.mediaPath,
      // id: item.mediaId,
    }));

    handleClose(newImages);
  };

  const deletedItems = data.filter((item) => deleteIds.includes(item.mediaId));
  return (
    <Modal
      show={show}
      onHide={(e) => {
        handleClose(e);
        setErrorMessage('');
      }}
      className='listing-lib-modal approved-model'
    >
      <>
        {!isShowWidget && (
          <Modal.Header>
            <h3>Select Files</h3>

            <button
              type='button'
              className='primary-btn'
              data-bs-dismiss='modal'
              onClick={(e) => {
                handleClose(e);
                setErrorMessage('');
              }}
            >
              <i className='icon-close' />
            </button>
          </Modal.Header>
        )}

        <Modal.Body>
          <h5>{onlyOneImage && 'Select Only One Image'}</h5>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {!isShowWidget && (
            <div className='widget-cont'>
              <div className='tab-new-form'>
                <div className='camp-tab-cont d-flex'>
                  <div className='short-left-bar'>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                      {lineItem_type == 'Display' &&
                        platformType !== 'Google Ad' && (
                          <button
                            className={`primary-btn ${
                              currentTab == 'All' && 'active'
                            }`}
                            id='edit-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#edit'
                            type='button'
                            role='tab'
                            aria-selected='true'
                            onClick={() => setTab('All')}
                          >
                            <i className='icon-media' /> All
                          </button>
                        )}
                      {widgetTabData &&
                        widgetTabData.map((tab, index) => (
                          <button
                            className={`primary-btn ${
                              currentTab == tab && 'active'
                            }`}
                            key={index}
                            id='review-tab'
                            data-bs-toggle='tab'
                            data-bs-target='#review'
                            type='button'
                            role='tab'
                            aria-selected='false'
                            onClick={() => setTab(tab)}
                          >
                            {/* <i className='icon-show-password' />
                            {tab} */}
                            {tab == 'Html' ? (
                              <>
                                <i className='icon-html' />
                                {tab}
                              </>
                            ) : (
                              <>
                                <i className='icon-image' />
                                {tab}
                              </>
                            )}
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='tab-content scroll-vertical' id='SelectMedia'>
                  <div
                    className='tab-pane fade show active'
                    id='all'
                    role='tabpanel'
                    aria-labelledby='all-tab'
                  >
                    <div className='card-primary'>
                      {' '}
                      <div className='media-fillter-btns'>
                        <div className='fillter-btns d-flex'>
                          {' '}
                          <div className='fillter-left d-flex align-items-center'>
                            <div className='dropdown-select'>
                              <div className='dropdown-select'>
                                {currentTab === 'Audio' ? null : (
                                  <ReactSelect
                                    options={platformDimensions}
                                    value={filters.dimensions}
                                    isMulti={true}
                                    defaultValue={[]}
                                    placeholder={'By Dimensions'}
                                    isSearchable={false}
                                    showHideCreative
                                    onChange={handleDimensionsChange}
                                    isClearable={false}
                                  />
                                )}
                              </div>
                              <ul
                                className='dropdown-menu '
                                aria-labelledby='dropdownMenuButton2'
                              >
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>{' '}
                              </ul>{' '}
                            </div>

                            <div
                              className='dropdown-select createinptWrap'
                              ref={widgetCreativeRef}
                            >
                              <button
                                className='btn-icon btn-icon-primary btn-bedcrumb-act dropdown-icon daterange'
                                type='button'
                                name='daterange'
                                aria-expanded='false'
                                onClick={showHideCreative}
                              >
                                Creative Name
                              </button>
                              {widgetCreativeNameFilter && (
                                <div className='d-flex createinpt'>
                                  <input
                                    type='text'
                                    value={creativeNameValue}
                                    onChange={handleCreativeInput}
                                    placeholder='Enter Name'
                                  />
                                  <div className='applybtnWrap'>
                                    {' '}
                                    <button
                                      className='applybtn'
                                      onClick={setCreativeName}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='dropdown-select'>
                              <div className='dropdown-select'>
                                <DateRangePicker
                                  onApply={setDates}
                                  onCancel={onCancelDate}
                                  isClearable={true}
                                  initialSettings={{
                                    endDate: new Date(),
                                    maxDate: new Date(),
                                  }}
                                  setEndDate={moment().toDate()}
                                >
                                  <button className='btn-icon btn-icon-primary btn-bedcrumb-act dropdown-icon'>
                                    Date Range
                                  </button>
                                </DateRangePicker>
                              </div>
                            </div>
                            <div className='dropdown-select'>
                              {currentTab == 'All' && (
                                <ReactSelect
                                  className='dropdown-select'
                                  options={options}
                                  placeholder='Upload'
                                  id='uploadOptions'
                                  isSearchable={false}
                                  value={selectedOption}
                                  onChange={handleOptionChangeReactSelect}
                                  isMulti={true}
                                  defaultValue={[]}
                                />
                              )}
                            </div>

                            <div className='dropdown-select'>
                              <ReactSelect
                                options={aspectRatio}
                                value={filters.aspectRatio}
                                isMulti={true}
                                placeholder='Aspect Ratio'
                                isSearchable={true}
                                onChange={handleAspectRatio}
                              />
                            </div>

                            {currentTab !== 'All' && (
                              <button
                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                onClick={handleOptionChange}
                              >
                                <i className='icon-plus'></i>
                                Upload
                              </button>
                            )}
                          </div>
                          <div className='fillter-right ms-auto d-flex'>
                            <button
                              type='button'
                              className='btn-icon single-icon btn-icon-primary btn-bedcrumb-act me-0'
                              disabled={!deleteIds}
                              onClick={deleteConformation}
                            >
                              <i className='icon-delete' />
                            </button>
                          </div>
                        </div>
                        <div className='fillter-options'>
                          {(filters.dimensions.length > 0 ||
                            (filters.dates.startDate &&
                              filters.dates.endDate) ||
                            filters.creativeName) && (
                            <button
                              className='clear-btn'
                              onClick={() => ClearFilters('All')}
                            >
                              Clear All
                            </button>
                          )}
                          {filters.dimensions.length > 0 && (
                            <span>
                              Dimensions:{' '}
                              {filters.dimensions &&
                                filters.dimensions.map((ele, i) => {
                                  return (
                                    <>
                                      {filters.dimensions.length > 1 && i != 0
                                        ? ','
                                        : null}{' '}
                                      <b>{ele.label}</b>
                                      <button
                                        type='button'
                                        onClick={() =>
                                          ClearFilters('dimensions')
                                        }
                                        key={i}
                                      >
                                        <i className='icon-close' />
                                      </button>
                                    </>
                                  );
                                })}
                            </span>
                          )}
                          {filters.creativeName && (
                            <span>
                              Creative Name: <b>{filters.creativeName}</b>
                              <button
                                type='button'
                                onClick={() => ClearFilters('creativeName')}
                              >
                                <i className='icon-close' />
                              </button>
                            </span>
                          )}
                          {filters.dates.startDate && filters.dates.endDate && (
                            <span>
                              Date Range:{' '}
                              {
                                <>
                                  <b>
                                    {moment(filters.dates.startDate).format(
                                      'DD/MM/YYYY',
                                    )}
                                    -
                                    {moment(filters.dates.endDate).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </b>
                                  <button
                                    type='button'
                                    onClick={() => ClearFilters('dates')}
                                  >
                                    <i className='icon-close' />
                                  </button>
                                </>
                              }
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane icon-title-left'>
                          {/* <h6 className='mt-0'>
                            <i className='icon-media' />
                            Account Media
                          </h6> */}
                          {currentTab === 'All' ? (
                            <h6 className='mt-0'>
                              <i className='icon-file' /> {''}
                              Account Media
                            </h6>
                          ) : currentTab === 'Image' ? (
                            <h6 className='mt-0'>
                              <i className='icon-image' />
                              {''}
                              Account images
                            </h6>
                          ) : currentTab === 'Html' ? (
                            <h6 className='mt-0'>
                              <i className='icon-html' />
                              Account Html
                            </h6>
                          ) : currentTab === 'Video' ? (
                            <h6 className='mt-0'>
                              <i className='icon-video' />
                              {''}
                              Account Video
                            </h6>
                          ) : currentTab === 'Audio' ? (
                            <h6 className='mt-0'>
                              <i className='icon-sound' />
                              {''}
                              {''}
                              Account Audio
                            </h6>
                          ) : null}
                        </div>
                        <div className='me-auto card-switch-rigth'>
                          <p></p>
                        </div>
                      </div>
                      {data?.length == 0 && filterApply && (
                        <div className='that-miss-wrap'>
                          <div className='img-wrap'>
                            <img src={noDataFound} alt='' />
                          </div>
                          <h2>That's miss</h2>
                          <p>
                            Sorry,that filter combination has no results. Please
                            try different criteria
                          </p>
                        </div>
                      )}
                      {data && data.length > 1 && (
                        <div className='media-entries'>
                          <div className='show-entries'>
                            {data.length > 0 && (
                              <p>
                                Showing{' '}
                                {currentPage == 0
                                  ? 1
                                  : Math.ceil(
                                      currentPage * itemsPerPage -
                                        itemsPerPage +
                                        (itemsPerPage + 1),
                                    )}{' '}
                                to{' '}
                                {Math.ceil(
                                  currentPage * itemsPerPage + data.length,
                                )}{' '}
                                of {totalRecords} entries
                              </p>
                            )}
                          </div>
                          <div className='media-pagination'>
                            <div className='media-pagination'>
                              <ReactPaginate
                                breakLabel={'...'}
                                marginPagesDisplayed={1}
                                previousLabel={<button>Previous</button>}
                                nextLabel={<button>Next</button>}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                forcePage={currentPage}
                                renderOnZeroPageCount={null}
                                pageRangeDisplayed={5}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      )}
                      <div className='media-list'>
                        {data.length > 0 &&
                          data?.map((item, e) => (
                            <div className='media-card' key={e}>
                              <div className='radioOption-Item'>
                                <input
                                  type='checkbox'
                                  name='BannerTypes'
                                  id={item?.mediaId}
                                  value={item?.mediaId}
                                  onChange={(e) => handleInputChecked(e)}
                                  checked={
                                    deleteIds?.includes(item?.mediaId) ||
                                    deleteIds?.includes(item?.medId)
                                  }
                                  disabled={
                                    getSelectedIds?.includes(item?.mediaId)
                                      ? false
                                      : getSelectedItemsLength == total
                                        ? true
                                        : false
                                  }
                                />
                                <label htmlFor={item?.mediaId}>
                                  <i className='icon-check-mark' />
                                </label>
                              </div>
                              <div
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal'
                                // onClick={(e) => handleShow(e, item)}
                              >
                                <div className='mediaimg-box'>
                                  <div className='media-img'>
                                    {renderMedia(item)}
                                  </div>
                                </div>
                                <div className='mediabox-cont'>
                                  <h3>{item?.mediaName}</h3>
                                  <p>
                                    {item?.videoDuration
                                      ? item?.videoDuration
                                      : ''}
                                    {item?.fileType?.toLowerCase() ===
                                      'image' &&
                                      item?.mediaHeight &&
                                      item?.mediaHeight &&
                                      `${item?.mediaWidth} x ${item?.mediaHeight}px`}
                                    &nbsp;
                                    {item?.mediaSize?.split(' ')[0] &&
                                      `• ${formatFileSize(
                                        parseInt(
                                          item?.mediaSize?.split(' ')[0] * 1024,
                                        ),
                                      )}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {data && data.length > 1 && (
                        <div className='media-entries'>
                          <div className='show-entries'>
                            {
                              <p>
                                Showing{' '}
                                {currentPage == 0
                                  ? 1
                                  : Math.ceil(
                                      currentPage * itemsPerPage -
                                        itemsPerPage +
                                        (itemsPerPage + 1),
                                    )}{' '}
                                to{' '}
                                {Math.ceil(
                                  currentPage * itemsPerPage + data.length,
                                )}{' '}
                                of {totalRecords} entries
                              </p>
                            }
                          </div>
                          <div className='media-pagination'>
                            <ReactPaginate
                              breakLabel={'...'}
                              marginPagesDisplayed={1}
                              previousLabel={<button>Previous</button>}
                              nextLabel={<button>Next</button>}
                              pageCount={totalPages}
                              onPageChange={handlePageChange}
                              forcePage={currentPage}
                              renderOnZeroPageCount={null}
                              pageRangeDisplayed={5}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='images'
                    role='tabpanel'
                    aria-labelledby='images-tab'
                  >
                    Images
                  </div>
                  <div
                    className='tab-pane fade'
                    id='video'
                    role='tabpanel'
                    aria-labelledby='video-tab'
                  >
                    Video
                  </div>
                </div>
              </div>
            </div>
          )}
          {isShowWidget && (
            <UploadFilesWidget
              fileType={selecTedFileType}
              platformType={selectedPlatformType}
              setIsShowWidget={setIsShowWidget}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className='widgetFoot'>
            <div className='widgetFootLeft'>
              {selectedData.map((data, index) => {
                return (
                  <div className='widgetfootSelectImg' key={index}>
                    {renderMedia(data)}
                  </div>
                );
              })}
            </div>
            <div>
              <button
                style={{ marginRight: '10px' }}
                type='button'
                className='primary-btn cancel-btn'
                data-bs-dismiss='modal'
                onClick={(e) => {
                  handleClose(e);
                  setErrorMessage('');
                }}
              >
                Cancel
              </button>
              <button
                type='button'
                className='primary-btn '
                data-bs-dismiss='modal'
                onClick={(e) => {
                  handleImportsClose(e);
                }}
              >
                Import
              </button>
              {/* Display error message if there is one */}
            </div>
          </div>
        </Modal.Footer>
      </>

      {/* delete item on deatil page confirmation modal*/}
      <Modal
        show={showDeletePopup}
        onHide={() => setShowDeletePupup(!showDeletePopup)}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-remove'>
              <i className='icon-delete' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Are you sure you want to delete the selected file?</h3>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => setShowDeletePupup(!showDeletePopup)}
          >
            Cancel
          </button>
          <button
            type='button'
            className='primary-btn'
            onClick={() => deleteitem(deleteIds)}
          >
            ok
          </button>
        </div>
      </Modal>
    </Modal>
  );
};
export default MediaWidget;
