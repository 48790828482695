import React from 'react';

function CardBody(props) {
  return (
    <>
      <div className='card-body'>{props.children}</div>
    </>
  );
}

export default CardBody;
