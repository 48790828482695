import React, { useMemo, useState } from 'react';
import moment from 'moment';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';

const ReactTable = ({ columns, data }) => {
  const [currentPage, setCurrentPage] = useState(0); // Track current page
  const itemsPerPage = 10; // Define items per page

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true, // Enable manual pagination
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { globalFilter } = state;

  const handleInputChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
  };

  const paginatedData = useMemo(() => {
    return page;
  }, [page]);

  // Calculate total budget value
  const totalBudget = useMemo(() => {
    return data.reduce((total, row) => {
      return total + row.budget_value;
    }, 0);
  }, [data]);

  // Find min start date and max end date
  const minStartDate = useMemo(() => {
    return moment.min(data.map((row) => moment(row.budget_startdate)));
  }, [data]);

  const maxEndDate = useMemo(() => {
    return moment.max(data.map((row) => moment(row.budget_enddate)));
  }, [data]);

  return (
    <div className='short-data-table'>
      <input
        style={{ display: 'none' }}
        value={globalFilter || ''}
        onChange={handleInputChange}
        placeholder='Search...'
      />
      {data.length === 0 ? (
        <div className='no-data-message'>
          <p>No data found</p>
        </div>
      ) : (
        <table {...getTableProps()} className='atd-table dataTable no-footer'>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`sorting ${
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'sorting sorting_desc'
                          : 'sorting sorting_asc'
                        : ''
                    }`}
                    style={{ width: column.width }}
                    aria-label={column.Header}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {paginatedData.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className='short-data-table-cell'
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr style={{ backgroundColor: 'var(--neutrals-color05)' }}>
              {footerGroups.map((footerGroup) =>
                footerGroup.headers.map((column) => (
                  <td key={column.id} {...column.getFooterProps()}>
                    {column.id === 'budget_value' ? (
                      <strong>Total ₹ {totalBudget}</strong>
                    ) : (
                      ''
                    )}
                    {column.id === 'budget_startdate' ? (
                      <strong>{minStartDate.format('DD-MMM-YYYY')}</strong>
                    ) : (
                      ''
                    )}
                    {column.id === 'budget_enddate' ? (
                      <strong>{maxEndDate.format('DD-MMM-YYYY')}</strong>
                    ) : (
                      ''
                    )}
                  </td>
                )),
              )}
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  );
};

export default ReactTable;
