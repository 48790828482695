import React from 'react';

function Header(props) {
  return (
    <>
      <header>
        <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
          {/* <div className='top-left-bar'>
            <ul className='nav-bedcrumb d-flex'>
              <li>
                <button
                  className='btn-icon btn-icon-primary btn-bedcrumb btn-bedcrumb-act'
                  type='button'
                >
                  <i className='icon-camopaign'></i> New Campaign
                </button>
              </li>
              <li>
                <button
                  className='btn-icon btn-icon-primary btn-bedcrumb'
                  type='button'
                >
                  <i className='icon-ad-set'></i> New insertion order
                </button>
              </li>
              <li>
                <button
                  className='btn-icon btn-icon-primary btn-bedcrumb'
                  type='button'
                >
                  <i className='icon-ad'></i> New Line item
                </button>
              </li>
            </ul>
          </div> */}
          <div className='top-right-bar'>
            <div className='btn-box'>
              <button
                type='button'
                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                onClick={props?.saveHandler}
              >
                Save as draft
              </button>
              <button
                type='button'
                className='primary-btn close-btn'
                onClick={props?.redirectToDashboard}
              >
                <i className='icon-close'></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
