import { ProgressBar } from 'react-bootstrap';

const ProgressBarComponent = (props) => {
  let percentage = props?.getProgressBarCount;
  return (
    <div className='custom-progressBar'>
      <div className='custom-loader-text'>Loading... {percentage}%</div>
      <div className='progressBar'>
        <ProgressBar className='progress-bar-success'
          now={percentage}
        // label={`${percentage}%`}
        />
        {props?.getShowPreparingMsgStatus && (
          <p className='loader-msg-display'>Preparing to upload data...Please Wait</p>
        )}
        {props?.getShowAlmostThereMsgStatus && (
          <p className='loader-msg-display'>Almost there, just a moment...</p>
        )}
        {props?.getShowFinalMsgStatus && (
          <p className='loader-msg-display'>
            Finishing up the upload process. We appreciate your patience...
          </p>
        )}
      </div>
    </div>
  );
};
export default ProgressBarComponent;
