import React from 'react';

function SimpleSelectBox(props) {
  let value = props?.value;
  let valueIndex = props?.options?.findIndex((item) => item == value);
  return (
    <>
      <div className='select-box'>
        <select
          name={props.name}
          required=''
          onChange={props.onChangeHandler}
          disabled={props.disabled}
        >
          <option value='' disabled='' selected=''>
            {props.placeholder}
          </option>
          {props?.options?.map((option, index) => {
            return (
              <>
                <option
                  value={option}
                  key={index}
                  selected={valueIndex == index}
                >
                  {option}
                </option>
              </>
            );
          })}
        </select>
      </div>
    </>
  );
}

export default SimpleSelectBox;
