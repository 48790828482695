import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

import ReactSelect from './ReactSelect';
import { getApi } from '../../utils/Apis';
import { baseUrlStg } from '../../utils/constants';
import CardBody from './CardBody';
const SelectApprovalPublisher = (props) => {
  const [getPublisherLists, setPublisherLists] = useState([]);
  const navigate = useNavigate();
  const handleGetPublisherLists = async () => {
    let response = await getApi(
      `${baseUrlStg}/api/GoogleAdListing/Publisher-name?platform=${props?.type}`,
    );
    try {
      if (response?.statusCode == 200) {
        setPublisherLists(response?.valueLabelList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetPublisherLists();
  }, []);

  return (
    <Modal
      show={true}
      className='atd-modal approved-model'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img-cont'></div>
        <h3>Select Approving Publisher</h3>
        <p>The selected publisher will be notified to approve the campaign</p>
        <div className='card-primary'>
          <CardBody>
            <div className='card-form form-panel'>
              <div className='row align-items-center'>
                <div className='form-item'>
                  <div className='budget-cont'>
                    <div className='label-icon'>
                      <label>Select publisher</label>
                    </div>
                    <ReactSelect
                      options={getPublisherLists}
                      isMulti={false}
                      placeholder={'Select publisher'}
                      closeMenuOnSelect={true}
                      onChange={props?.handleSetPublisherId}
                      value={getPublisherLists?.find((item) => {
                        return item?.value == props?.getPublisherInfo?.value;
                      })}
                    />
                  </div>
                  <span className='error'>{props?.getPublisherError}</span>
                </div>
              </div>
            </div>
          </CardBody>
        </div>
      </Modal.Body>

      <div className='modal-footer'>
        <button
          type='button'
          className='primary-btn footer-close-btn'
          onClick={props?.handleCloseModel}
        >
          cancel
        </button>
        <button
          type='button'
          className='primary-btn'
          onClick={props?.handleApprovalPublisher}
        >
          Select
        </button>
      </div>
    </Modal>
  );
};
export default SelectApprovalPublisher;
