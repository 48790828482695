export const DV360Options = {
  Campaigns: {
    name: 'Campaigns',
    url: '/dv-360/campaigns-listing',
    icon: 'icon-camopaign',
    viewPageUrl: '/dv-360/campaignsView',
    deleteUrl: 'api/Campaign/deleteCampaignByID?campaignID=',
    deleteAllUrl: 'api/Campaign/multipleDeleteCampaignByID',
    ControlAllUrl: '/api/Campaign/PauseActiveMultipleCampaignByID',
    newUrl: '/dv-360/create-campaign/new',
    editUrl: '/dv-360/create-campaign',
    reviewForm: 'campaign',
    platform: 'dv360',
  },
  Insertion_Order: {
    name: 'Insertion Order',
    url: '/dv-360/insertion-order-listing',
    icon: 'icon-ad-set',
    viewPageUrl: '/dv-360/insertionOrderView',
    deleteUrl: 'api/InsertionOrder/deleteInsertionOrderByID?insertionOrderID=',
    deleteAllUrl: 'api/InsertionOrder/multipleDeleteInsertionOrderByID',
    editUrl: '/dv-360/create-insertion-order',
    reviewForm: 'insertion order',
    platform: 'dv360',
  },
  Line_Item: {
    name: 'Line Item',
    url: '/dv-360/lineItem-listing',
    icon: 'icon-ad',
    viewPageUrl: '/dv-360/lineItemView',
    deleteUrl: 'api/LineItem/deleteLineItemByID?lineItemID=',
    deleteAllUrl: 'api/LineItem/multipleDeleteLineItemByID',
    editUrl: '/dv-360/create-line-item',
    reviewForm: 'line item',
    platform: 'dv360',
  },
};
