import {
  GOOGLE_AD_FINAL_MOBILE_URL,
  GOOGLE_AD_FINAL_URL_SUFFIX,
  GOOGLE_AD_GROUP_AD_GROUP_HOUSEHOLD_INCOME,
  GOOGLE_AD_GROUP_AD_GROUP_NAME,
  GOOGLE_AD_GROUP_AD_GROUP_PRODUCT_ID,
  GOOGLE_AD_GROUP_AUDIENCE,
  GOOGLE_AD_GROUP_CAMPAIGN_ID,
  GOOGLE_AD_GROUP_CAMPAIGN_LEVEL_LOCATION_LANGUAGE,
  GOOGLE_AD_GROUP_CSV_FILE,
  GOOGLE_AD_GROUP_DEMOGRAPHIC_AGE,
  GOOGLE_AD_GROUP_DEMOGRAPHIC_GENDER,
  GOOGLE_AD_GROUP_DEMOGRAPHIC_PARENTAL_STATUS,
  GOOGLE_AD_GROUP_LANGUAGE,
  GOOGLE_AD_GROUP_NAME,
  GOOGLE_AD_GROUP_OPTIMIZED_TARGETING,
  GOOGLE_AD_GROUP_PLACEMENTS,
  GOOGLE_AD_GROUP_PREFILLED_VALUES,
  GOOGLE_AD_GROUP_SHOW_ALL_PRODUCTS,
  GOOGLE_AD_GROUP_TOPICS,
  GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM,
  GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM_INFO,
  GOOGLE_AD_GROUP_URL_OPTIONS_SUFFIX,
  GOOGLE_AD_GROUP_URL_OPTIONS_TRACKING_TEMPLATES,
  GOOGLE_AD_GROUP_URL_OPTION_VALUE,
  GOOGLE_AD_IS_MOBILE_URL_ENABLED,
  GOOGLE_AD_TRACKING_TEMPLATE,
  RESET_GOOGLE_AD_GROUP_STATE,
  SET_BID_CPC,
} from './googleAdGroupActionTypes';

const initialState = {
  AdGroupName: '',
  Audience: '',
  CampaignId: '',
  CampaignLevelLocationLanguage: false,
  CSVFile: '',
  CSVFileName: '',
  DemographicAge: [],
  DemographicGender: [],
  DemographicParentalStatus: [],
  DemographicHouseholdIncome: [],
  OptimizedTargeting: true,
  Placements: '',
  ShowAllProducts: false,
  Language: [],
  GoogleProductId: '',
  Topics: '',
  'URLOptions.CusParam': '',
  'URLOptions.CusParamInfo': '',
  'URLOptions.Suffix': '',
  'URLOptions.TrackingTemplates': { label: '', value: '' },
  UrlOptionValue: 0,
  IsMobileUrlEnabled: '',
  FinalMobileUrl: '',
  CpcBid: '',
};

export const googleAdGroup = (state = initialState, { type, payload }) => {
  switch (type) {
    case GOOGLE_AD_GROUP_NAME: {
      return { ...state, AdGroupName: payload };
    }
    case GOOGLE_AD_GROUP_CSV_FILE: {
      if (payload) {
        return { ...state, CSVFile: payload, CSVFileName: payload?.name };
      } else return { ...state };
    }
    case GOOGLE_AD_GROUP_CAMPAIGN_LEVEL_LOCATION_LANGUAGE: {
      return { ...state, CampaignLevelLocationLanguage: payload };
    }
    case GOOGLE_AD_GROUP_URL_OPTIONS_TRACKING_TEMPLATES: {
      return { ...state, 'URLOptions.TrackingTemplates': payload };
    }
    case GOOGLE_AD_GROUP_TOPICS: {
      return { ...state, Topics: payload };
    }
    case GOOGLE_AD_GROUP_AUDIENCE: {
      return { ...state, Audience: payload };
    }
    case GOOGLE_AD_GROUP_DEMOGRAPHIC_GENDER: {
      return { ...state, DemographicGender: payload };
    }
    case GOOGLE_AD_GROUP_AD_GROUP_NAME: {
      return { ...state, AdGroupName: payload };
    }
    case GOOGLE_AD_GROUP_SHOW_ALL_PRODUCTS: {
      return { ...state, ShowAllProducts: payload };
    }
    case GOOGLE_AD_GROUP_OPTIMIZED_TARGETING: {
      return { ...state, OptimizedTargeting: payload };
    }
    case GOOGLE_AD_GROUP_CAMPAIGN_ID: {
      return { ...state, CampaignId: payload };
    }
    case GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM: {
      return { ...state, 'URLOptions.CusParam': payload };
    }
    case GOOGLE_AD_GROUP_DEMOGRAPHIC_PARENTAL_STATUS: {
      return { ...state, DemographicParentalStatus: payload };
    }
    case GOOGLE_AD_GROUP_URL_OPTION_VALUE: {
      return { ...state, UrlOptionValue: payload };
    }
    case GOOGLE_AD_GROUP_PLACEMENTS: {
      return { ...state, Placements: payload };
    }
    case GOOGLE_AD_GROUP_URL_OPTIONS_SUFFIX: {
      return { ...state, 'URLOptions.Suffix': payload };
    }
    case GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM_INFO: {
      return { ...state, 'URLOptions.CusParamInfo': payload };
    }
    case GOOGLE_AD_GROUP_DEMOGRAPHIC_AGE: {
      return { ...state, DemographicAge: payload };
    }
    case GOOGLE_AD_GROUP_AD_GROUP_HOUSEHOLD_INCOME: {
      return { ...state, DemographicHouseholdIncome: payload };
    }
    case GOOGLE_AD_GROUP_AD_GROUP_PRODUCT_ID: {
      return { ...state, GoogleProductId: payload };
    }
    case GOOGLE_AD_GROUP_LANGUAGE: {
      return { ...state, Language: payload };
    }

    case GOOGLE_AD_TRACKING_TEMPLATE: {
      return {
        ...state,
        'URLOptions.TrackingTemplates': {
          value: payload.value,
          label: payload.label,
        },
      };
    }
    case GOOGLE_AD_FINAL_URL_SUFFIX: {
      return { ...state, 'URLOptions.Suffix': payload };
    }

    case GOOGLE_AD_IS_MOBILE_URL_ENABLED: {
      return { ...state, IsMobileUrlEnabled: payload };
    }
    case GOOGLE_AD_FINAL_MOBILE_URL: {
      return { ...state, FinalMobileUrl: payload };
    }

    case SET_BID_CPC: {
      return { ...state, CpcBid: payload };
    }

    case RESET_GOOGLE_AD_GROUP_STATE: {
      return initialState;
    }

    case GOOGLE_AD_GROUP_PREFILLED_VALUES: {
      const convertedTopicData = payload?.topics?.map((item) => {
        const lastPathElement = JSON.parse(item?.path)?.pop();
        const label = `${lastPathElement}`;

        return {
          label: label,
          value: item?.topicId,
        };
      });
      return {
        ...state,
        AdGroupName: payload.adGroupName,
        Audience: payload.audience?.map((audience) => ({
          label: audience.name,
          value: audience.googleAudienceSegmentsid,
        })),
        CampaignId: payload.campaignId,
        CampaignLevelLocationLanguage: false,
        CSVFile: '',
        CSVFileName: payload?.googleKeywords[0]?.fileName,
        DemographicAge: payload?.googleAdGroupDemographics[0]?.age?.map(
          (age) => ({
            label: age,
            value: age,
          }),
        ),
        DemographicGender: payload?.googleAdGroupDemographics[0]?.gender?.map(
          (gender) => ({
            label: gender,
            value: gender,
          }),
        ),
        DemographicParentalStatus:
          payload?.googleAdGroupDemographics[0]?.parentalStatus?.map(
            (parentalStatus) => ({
              label: parentalStatus,
              value: parentalStatus,
            }),
          ),
        DemographicHouseholdIncome:
          payload?.googleAdGroupDemographics[0]?.householdIncome?.map(
            (householdIncome) => ({
              label: householdIncome,
              value: householdIncome,
            }),
          ),
        OptimizedTargeting: payload?.optimizedTargeting,
        Placements: payload?.placements?.map((placement) => ({
          label: placement?.placementName,
          value: placement?.placementId,
        })),
        ShowAllProducts: payload?.showAllProducts,
        Language: payload?.language.map((language) => ({
          label: language.language,
          value: language.id,
        })),
        GoogleProductId: payload?.googleProductId?.map((productId) => ({
          label: productId,
          value: productId,
        })),
        Topics: convertedTopicData,
        'URLOptions.CusParam':
          payload?.googleCampaignUrlData[0]?.cusParam || '',
        'URLOptions.CusParamInfo':
          payload?.googleCampaignUrlData[0]?.cusParamInfo || '',
        'URLOptions.Suffix': payload?.googleCampaignUrlData[0]?.suffix || '',
        'URLOptions.TrackingTemplates': {
          label: payload?.trackingTemplateUrl?.label || '',
          value: payload?.trackingTemplateUrl?.value || '',
        },
        UrlOptionValue: 0,
        IsMobileUrlEnabled: '',
        FinalMobileUrl: '',
        CpcBid: payload?.cpcBid || '',
      };
    }

    default:
      return state;
  }
};
