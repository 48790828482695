import {
  MEDIA_DIVISION,
  MEDIA_PRODUCT_NAME,
  MEDIA_PHASE,
  MEDIA_QUARTER,
  MEDIA_FUNDING_SOURCE,
  MEDIA_CAMPAIGN_ID,
  MEDIA_QT_NUMBER,
  MEDIA_CLEAR_FORM,
} from './CreateMediaPlaneActionTypes';

const setMediaDivision = (payload) => {
  return {
    type: MEDIA_DIVISION,
    payload: payload,
  };
};

const setMediaProductName = (payload) => {
  return {
    type: MEDIA_PRODUCT_NAME,
    payload: payload,
  };
};

const setMediaPhase = (payload) => {
  return {
    type: MEDIA_PHASE,
    payload: payload,
  };
};

const setMediaQuarter = (payload) => {
  return {
    type: MEDIA_QUARTER,
    payload: payload,
  };
};

const setMediaFundingSource = (payload) => {
  return {
    type: MEDIA_FUNDING_SOURCE,
    payload: payload,
  };
};

const setMediaCampaignID = (payload) => {
  return {
    type: MEDIA_CAMPAIGN_ID,
    payload: payload,
  };
};

const setMediaQtNumber = (payload) => {
  return {
    type: MEDIA_QT_NUMBER,
    payload: payload,
  };
};

const clearMediaFormValue = (payload) => {
  return {
    type: MEDIA_CLEAR_FORM,
    payload: payload,
  };
};

export {
  setMediaDivision,
  setMediaProductName,
  setMediaPhase,
  setMediaQuarter,
  setMediaFundingSource,
  setMediaCampaignID,
  setMediaQtNumber,
  clearMediaFormValue,
};
