import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { loginUrl } from '../../utils/constants';
import { setLoginToken } from '../../state/Login/LoginAction';

const LoginComponent = (props) => {
  const [cookies, setCookie] = useCookies(['Token']);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let token = cookies?.Token?.Token;
    let currentDomain = window.location.hostname;
    if (token) {
      dispatch(setLoginToken(cookies?.Token));
    }

    if (
      token?.length == 0 ||
      (typeof token == 'undefined' && currentDomain !== 'localhost')
    ) {
      window.location.replace(loginUrl);
    }
  }, [location]);

  return <div>{props.children}</div>;
};
export default LoginComponent;
