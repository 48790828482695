import CardBody from './CardBody';

const CommonInfoComponent = (props) => {
  return (
    <>
      <CardBody>
        <div className='info-icon-body'>
          <div className='more-info-icon'>
            <i className={props?.iconClass ? props?.iconClass : 'icon-info'} />
          </div>
          <div className='more-info-text'>
            {Array.isArray(props?.info) ? (
              props?.info?.map((v, i) => <p key={i}>{v}</p>)
            ) : (
              <p>{props?.info}</p>
            )}
          </div>
        </div>
      </CardBody>
    </>
  );
};

export default CommonInfoComponent;
