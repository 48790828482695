import ErrorDisplay from '../common/ErrorDisplay';
import SimpleSelectBox from '../common/SimpleSelectBox';

const BidAndStrategy = (props) => {
  return (
    <>
      {props?.optimizationQueryTypeFirstPosition ==
      'Automate bid and budget at insertion order level' ? (
        <div className='card-body'>
          <div className='info-icon-body'>
            <div className='more-info-icon'>
              <i className='icon-lock' />
            </div>
            <div className='more-info-text'>
              <p>Bid strategy is set at the insertion order level.</p>
              <p>Spend full budget and Maximize quality impressions</p>
              <p>Where "quality impressions" are defined as:Viewable</p>
            </div>
          </div>
        </div>
      ) : (
        <div className='col-12 px-0'>
          <div className='form-check-box'>
            <input
              className='form-check-input'
              type='radio'
              name='automated_bidding'
              id='flexRadioDefault503'
              value='automated_bidding'
              onChange={props?.handleToggleAutomateBidding}
              checked={
                props?.automated_bidding_type == 'automated_bidding'
                  ? true
                  : false
              }
            />
            <label className='form-check-label' htmlFor='flexRadioDefault503'>
              Automate bidding
            </label>
          </div>
          {props?.automated_bidding_type == 'automated_bidding' ? (
            <div className='dv-from-check-field'>
              <div className='form-item'>
                <div className='label-icon'>
                  <label>Select</label>
                </div>
                <div className='select-box'>
                  <SimpleSelectBox
                    name='quality'
                    placeholder='Select...'
                    options={
                      props?.optimizationQueryTypeFirstPosition ==
                        'Control bid and budget at the line item level' &&
                      props?.selected_line_item == 'Display'
                        ? props?.automateBiddingOptiWithThreeValDisplay
                        : props?.optimizationQueryTypeFirstPosition ==
                              'Control bid and budget at the line item level' &&
                            props?.selected_line_item == 'video'
                          ? props?.automateBiddingOptiWithFiveValVideo
                          : props?.optimizationQueryTypeFirstPosition ==
                                'Control bid and budget at the line item level' &&
                              props?.selected_line_item == 'audio'
                            ? props?.automateBiddingOptiWithOneValAudio
                            : props?.automateBiddingOptiWithThreeValDisplay
                    }
                    value={props?.automated_bidding_option_one_value}
                    onChangeHandler={props?.hanldeAutomatedBiddingOptionsOne}
                  />
                </div>
                {console.log(props)}

                <ErrorDisplay
                  val={props?.automated_bidding_option_one_value_error}
                />
              </div>
              <div className='form-item'>
                <div className='label-icon'>
                  <label>Select</label>
                </div>
                <div className='select-box'>
                  <SimpleSelectBox
                    name='quality'
                    placeholder='Select...'
                    options={
                      props?.optimizationQueryTypeFirstPosition ==
                        'Control bid and budget at the line item level' &&
                      props?.selected_line_item == 'audio'
                        ? props?.automateBiddingSecondOptiWithOneValAudio
                        : props?.automated_bidding_option_one_value ==
                            'Maximize conversions'
                          ? props?.automateBiddingSecondOptiWithOneValAudio
                          : props?.automated_bidding_option_one_value ==
                              'Maximize clicks'
                            ? props?.automateBiddingOptionsTwoCPC
                            : props?.automated_bidding_option_one_value ==
                                'Maximize viewable impressions'
                              ? props?.automateBiddingOptionsTwo
                              : props?.automateBiddingOptionsTwo
                    }
                    value={props?.automated_bidding_option_two_value}
                    onChangeHandler={props?.hanldeAutomatedBiddingOptionsTwo}
                  />
                </div>
                <ErrorDisplay
                  val={props?.automated_bidding_option_two_value_error}
                />
                {/* Below div will be display if the second drop down value would be selected "while prioritizing a target viewable CPM" */}
                {props?.automated_bidding_option_two_value ==
                  'while prioritizing a target viewable CPM' ||
                props?.automated_bidding_option_two_value ==
                  'while prioritizing a target viewable CPA' ||
                props?.automated_bidding_option_two_value ==
                  'while prioritizing a target viewable CPC' ? (
                  <div className='form-item input-group-label'>
                    <div className='label-icon'>
                      <label>Amount</label>
                    </div>
                    <div className='input-group'>
                      <span className='input-group-text rs-text'>
                        <i className='icon-rupee' />
                      </span>
                      <input
                        type='email'
                        id='automate_bidding_prioritize_target_cpm1'
                        name='automate_bidding_prioritize_target_cpm1'
                        placeholder='X.XX'
                        onChange={
                          props?.hanldeAutomatedBiddingPrioritizeTargetCPA
                        }
                        value={props?.automate_bidding_prioritize_target_cpm}
                      />
                      <span className='input-group-text inr-text rs-text'>
                        INR
                      </span>
                      <ErrorDisplay
                        val={props?.getAutomatedBiddingPrioritizeTargetCPAError}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className='d-flex dv-check-box'>
                <div className='action-btn-both action-btn-both-check row'>
                  <div className='form-check-box'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='inlineCheckbox7'
                      defaultValue='automated_bidding_exceed_avg'
                      onChange={props?.hanldeAutomatedBiddingExceedAvg}
                      disabled={
                        props?.automated_bidding_option_one_value ==
                          'Maximize viewable impressions' &&
                        props?.automated_bidding_option_two_value ==
                          'while prioritizing a target viewable CPM'
                          ? true
                          : false
                      }
                      checked={
                        props?.automated_bidding_exceed_avg ? true : false
                      }
                    />
                    <label
                      className='form-check-labe2'
                      htmlFor='inlineCheckbox7'
                    >
                      Do not exceed average CPM of
                    </label>
                  </div>
                </div>
                <div className='form-item input-group-label'>
                  <div className='label-icon'>
                    <label>Amount</label>
                  </div>
                  <div className='input-group'>
                    <span className='input-group-text rs-text'>
                      <i className='icon-rupee' />
                    </span>
                    <input
                      type='email'
                      id='automated_bidding_exceed_amount'
                      name='automated_bidding_exceed_amount'
                      placeholder='X.XX'
                      onChange={props?.hanldeAutomatedBiddingExceedAmount}
                      value={props?.automated_bidding_exceed_amount}
                      disabled={
                        props?.automated_bidding_option_one_value ==
                          'Maximize viewable impressions' &&
                        props?.automated_bidding_option_two_value ==
                          'while prioritizing a target viewable CPM'
                          ? true
                          : !props?.automated_bidding_exceed_avg
                            ? true
                            : false
                      }
                    />
                    <span className='input-group-text inr-text rs-text'>
                      INR
                    </span>
                    <ErrorDisplay
                      val={props?.getAutomatedBiddingExceedAmountError}
                    />
                  </div>
                </div>
              </div>
              {/* Above div will be desable if the second option value would be selected
                                    "while prioritizing a target viewable CPM" */}
              <div className='dv-check-box'>
                <div className='action-btn-both action-btn-both-check row'>
                  <div className='form-check-box'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='inlineCheckbox8'
                      defaultValue='automated_bidding_prioritize_deal'
                      onChange={props?.hanldeAutomatedBiddingPrioritizeDeal}
                      disabled={
                        props?.automated_bidding_option_two_value ==
                        'while prioritizing a target viewable CPM'
                          ? true
                          : false
                      }
                      checked={
                        props?.automated_bidding_prioritize_deal ? true : false
                      }
                    />
                    <label
                      className='form-check-labe2'
                      htmlFor='inlineCheckbox8'
                    >
                      Prioritize deals over open auction inventory
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className='col-12 px-0'>
            <div className='form-check-box'>
              <input
                className='form-check-input'
                type='radio'
                name='fixed_bid'
                id='flexRadioDefault511'
                value='Fixed bid'
                // DvCreateLineItemData
                onChange={props?.handleToggleAutomateBidding}
                checked={
                  props?.automated_bidding_type == 'Fixed bid' ? true : false
                }
              />
              <label className='form-check-label' htmlFor='flexRadioDefault511'>
                Fixed bid
              </label>
              {/* <span className='error'>{props?.errorMsg}</span> */}
            </div>
            {props?.automated_bidding_type == 'Fixed bid' && (
              <div className='dv-from-check-field'>
                <div className='dv-check-box'>
                  <div className='action-btn-both action-btn-both-check row'>
                    <div className='form-item input-group-label col-5'>
                      <div className='label-icon'>
                        <label>Amount</label>
                      </div>
                      <div className='input-group'>
                        <span className='input-group-text rs-text'>
                          <i className='icon-rupee' />
                        </span>
                        <input
                          type='email'
                          id='email'
                          placeholder='X.XX'
                          onChange={props?.handleFixedBidVal}
                          value={props?.fixed_bid_val}
                        />
                        <span className='input-group-text inr-text rs-text'>
                          INR
                        </span>
                        {/* {console.log(props)} */}
                        <ErrorDisplay val={props?.numberCheckFixedBidError} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <span className='error-message' style={{ color: 'red' }}>
            {props?.bid_strategy_error}
          </span>
        </div>
      )}
    </>
  );
};

export default BidAndStrategy;
