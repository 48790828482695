import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import ReactTable from '../../../components/common/ReactTable';
import { getApi } from '../../../utils/Apis';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/common/Loader';
import { toast, ToastContainer } from 'react-toastify';

import NavHeading from '../../../components/common/NavHeading';
import { baseUrlStg } from '../../../utils/constants';

const TaxomonyCampaignAdsListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [mediaAndcampaignData, setMediaAndCampaignData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const campaign_id = searchParams.get('campaign_id')
    ? parseInt(searchParams.get('campaign_id'))
    : null;

  useEffect(() => {
    getAllCampaignAds(searchInputValue);
  }, []);

  const getAllCampaignAds = async (InputValue) => {
    try {
      setIsLoading(true);
      let url = `${baseUrlStg}/api/TaxonomyAdCreate/GetTaxonomyAdList?campaignId=${campaign_id}&page=${
        currentPage + 1
      }&limit=${itemsPerPage}`;
      if (InputValue) {
        url = url + `&search=${InputValue}`;
      }
      const res = await getApi(url);

      if (res.success) {
        setData(res.taxonomyAdList);
        setTotalRecords(res.totalRecords);
        setTotalPages(Math.ceil(res.totalRecords / Number(itemsPerPage)));
        setMediaAndCampaignData(res?.mediaAndCampaignName);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSearchInput = (event) => {
    const value = event?.target?.value?.trim();

    setSearchInputValue(value);
  };

  const handleclearSearch = () => {
    if (!searchInputValue) {
      return;
    }
    setCurrentPage(0);
    setSearchInputValue((prev) => (prev = ''));
    getAllCampaignAds('');
  };

  const getSearchResult = () => {
    if (!searchInputValue) {
      return;
    }
    setCurrentPage(0);
    getAllCampaignAds(searchInputValue);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'adId',
        Cell: ({ row }) => {
          return currentPage * itemsPerPage + (row.index + 1);
        },
      },
      {
        Header: 'Ads',
        accessor: 'adName',
      },
      {
        Header: 'Ads Name Length',
        accessor: 'adLength',
      },
      {
        Header: 'View CID',
        accessor: 'cIdCount',
        Cell: ({ row }) => (
          <Link
            class='btn-icon btn-icon-primary btn-td-grey'
            to={`/taxonomy-manager/CID-structer-list?ad_id=${row.original.adId}`}
          >
            <i class='icon-line-dot'></i> View CID({row.original.cIdCount})
          </Link>
        ),
      },
      {
        Header: 'Create CID',
        Cell: ({ row }) => (
          <Link
            class='btn-icon btn-icon-primary'
            to={`/taxonomy-manager/create-cid?campaign_id=${campaign_id}&ad_id=${row.original.adId}`}
          >
            <i class='icon-plus'></i>Create CID
          </Link>
        ),
      },
    ],
    [currentPage, itemsPerPage],
  );

  // for Delete if we have to add delete functionality then set showcheckbox prop of react table true

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = useCallback((selectedRowsIds) => {
    setSelectedRows(selectedRowsIds);
  }, []);

  const handleMultipleDelete = () => {
    const deleteId = selectedRows.map((row) => row.campaign_id);

    setShow(true);

    setDeleteId(deleteId);
  };

  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container taxonomy-dashboard facebook-dashboard'>
          <div className='container'>
            <div className='taxonomy-panel'>
              <div>
                <Sidebar />
              </div>
              <div className='right-side-box media-section-left'>
                <header>
                  <NavHeading HeadingName={'Taxonomy Manager'} />

                  <div className='search-bar-cont'>
                    <div className='search'>
                      <div className='search-close'>
                        <input
                          type='text'
                          placeholder='Search Ads'
                          value={searchInputValue}
                          onChange={handleSearchInput}
                        />
                        <button
                          onClick={handleclearSearch}
                          className='close-btn'
                        >
                          <i className='icon-close'></i>
                        </button>
                      </div>
                      <button
                        onClick={getSearchResult}
                        className='btn-icon btn-icon-primary'
                      >
                        <i className='icon-search'></i>
                      </button>
                    </div>
                  </div>
                  <div class='search-cont-result'>
                    <ul>
                      <li>
                        <span>
                          <i class='icon-edit-box'></i>
                        </span>
                        <span>
                          <strong> Media Id: </strong>
                          {mediaAndcampaignData?.mediaPlanName}
                        </span>
                      </li>
                      <li>
                        <span>
                          <i class='icon-camopaign'></i>
                        </span>
                        <span>
                          <strong> Campaign Id: </strong>
                          {mediaAndcampaignData?.campaignName}
                        </span>
                      </li>
                    </ul>
                  </div>
                </header>

                <div className='camp-tab-cont'>
                  <a href='#' className='primary-btn active'>
                    <i className='icon-camopaign'></i>My Ads
                  </a>
                </div>

                <div className='short-entry-cont'>
                  <div className='short-left-bar'>
                    <button
                      onClick={() => {
                        navigate(
                          `/taxonomy-manager/create-ad?campaign_id=${campaign_id}`,
                        );
                      }}
                      className='btn-icon btn-icon-primary btn-selectd'
                    >
                      <i className='icon-plus'></i>Create New Ad
                    </button>
                    <button
                      onClick={() =>
                        navigate(
                          `/taxonomy-manager/media-campaign-list?mediaplan_id=${mediaAndcampaignData.mediaPlanId}`,
                        )
                      }
                      className='btn-icon btn-icon-primary active'
                    >
                      <i className='icon-camopaign'></i>Show My Campaigns
                    </button>
                    <button
                      onClick={() =>
                        navigate(`/taxonomy-manager/media-plan-list`)
                      }
                      className='btn-icon btn-icon-primary active'
                    >
                      <i className='icon-edit-box'></i>Show my Media Plans
                    </button>
                  </div>
                  <div class='sd-Table'>
                    <div class='table-responsive'>
                      {data.length > 0 && (
                        <div
                          id='example_wrapper'
                          class='dataTables_wrapper no-footer'
                        >
                          <div
                            className='dataTables_length'
                            id='Facebookcampaigntable_length'
                          >
                            <label>
                              Show{' '}
                              <select
                                name='Facebookcampaigntable_length'
                                aria-controls='Facebookcampaigntable'
                                value={itemsPerPage}
                                onChange={(e) => [
                                  setItemsPerPage(Number(e.target.value)),
                                  setCurrentPage(0),
                                ]}
                              >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                        </div>
                      )}
                      <ReactTable
                        columns={columns}
                        data={data}
                        totalCount={totalRecords}
                        showCheckbox={false}
                        onSelectionChange={handleSelectionChange}
                      />
                      {data && (
                        <div className='media-entries dataTables_paginate'>
                          <div className='show-entries'>
                            {data.length > 0 && (
                              <p>
                                Showing{' '}
                                {currentPage == 0
                                  ? 1
                                  : Math.ceil(
                                      currentPage * itemsPerPage +
                                        (itemsPerPage + 1) -
                                        itemsPerPage,
                                    )}{' '}
                                to{' '}
                                {Math.ceil(
                                  currentPage * itemsPerPage + data.length,
                                )}{' '}
                                of {totalRecords} entries
                              </p>
                            )}
                          </div>
                          <div className='media-pagination'>
                            <div className='media-pagination dataTables_paginate'>
                              <ReactPaginate
                                breakLabel={'...'}
                                marginPagesDisplayed={1}
                                previousLabel={<button>Previous</button>}
                                nextLabel={<button>Next</button>}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                forcePage={currentPage}
                                renderOnZeroPageCount={null}
                                pageRangeDisplayed={5}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TaxomonyCampaignAdsListing;
