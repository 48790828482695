import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import '../../assets/css/style.css';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import InfoText from '../../components/common/InfoText';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { useEffect, useState } from 'react';
import Footer from '../../components/common/Footer';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { resetCampaignState } from '../../state/DVCreateCampaign/CreateCampaignActions';
import {
  checkCampName,
  getCampaignDataById,
  setCampaignName,
  setCreative,
  setDVCampaignGoal,
  setDealsAndInventoryPackages,
  setFrequencyCapType,
  setFrequencyLimit,
  setFrequencyLimitType,
  setFrequencyLimitValue,
  setGroups,
  setPlannedEndDate,
  setPlannedSpend,
  setPlannedStartDate,
  setPublicInvetory,
  setQuality,
  setTargettingSources,
  set_KPI,
  set_KPI_value,
} from '../../state/DVCreateCampaign/CreateCampaignActions';
import {
  multiSelectVal,
  prefillSelect,
  lineItemUrlFormat,
} from '../../utils/validations';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { EnabledApproval, baseUrlStg } from '../../utils/constants';
import Loader from '../../components/common/Loader';
import CommonModal from '../../components/common/commonModal';
import { postApi, putApi, getApi } from '../../utils/Apis';
import SideTreeMenuForCreate from '../../components/dv360/SideTreeMenuForCreate';
import DvErrorDisplay from '../../components/dv360/dvErrorDisplay';
import SelectApprovalPublisher from '../../components/common/SelectApprovalPublisher';
import ApprovalConfirmationPopup from '../../components/common/ApprovalConfirmationPopup';
import ThankYouApprovalPopup from '../../components/common/ThankYouApprovalPopup';
import { ApproveReject } from '../../components/ApproveReject/ApproveReject';
import { blockInvalidChar, handleFocus } from '../../utils/utils';

const creativeArr = [];
function CreateCampaign() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const CreateCampaignData = state?.DvCreateCampaign;

  const goalList = {
    awareness: 'Raise awareness of my brand or product',
    DriveOnline: 'Drive online action or visits',
    DriveOffline: 'Drive offline or in-store sales',
    DriveApp: 'Drive app installs or engagements',
  };
  const publicInventoryOptions = [
    { label: 'Adform', value: '1' },
    { label: 'AdsWizz', value: '2' },
    { label: 'Aja', value: '3' },
    {
      label: 'Dailymotion',
      value: '4',
    },
    {
      label: 'Digital Turbine',
      value: '5',
    },
    { label: 'Equativ', value: '6' },
    { label: 'Five', value: '7' },
    { label: 'Fluct', value: '8' },
    {
      label: 'FreeWheel SSP',
      value: '9',
    },
  ];
  const dealsAndInventoryPackagesOptions = [
    {
      label: '$150k+ HHI on CTV Devices',
      value: '1',
    },
    {
      label: '$50k+ HHI on CTV Devices',
      value: '2',
    },
    {
      label: '0.10%+ CTR Content - GumGum Marketplace',
      value: '3',
    },
    {
      label: '0.2%+ High CTR AMER-US Display All Devices Always On',
      value: '4',
    },
    {
      label: '0.30%+ CTR Content - GumGum Marketplace',
      value: '5',
    },
    {
      label: '1F-DV360-OneFOX-121422',
      value: '6',
    },
    {
      label: '1ur-460529l_DGT_SAMSUNG_RB_underDelivery_20220823',
      value: '7',
    },
    {
      label: "1ur-460533l_IN_CNT_Samsung_PG_ROS_Aug'22",
      value: '8',
    },
    {
      label: '202452210INTLGOOGLETILBOTHINATFCPDENGNEWSRCH2FOR',
      value: '9',
    },
  ];
  const groupsOptions = [
    { label: 'test1', value: '1' },
    { label: 'test2', value: '2' },
    { label: 'test3', value: '3' },
    { label: 'test4', value: '4' },
    { label: 'test5', value: '5' },
  ];

  const initialvalues = {
    campaignGoal: 1,
    campaignName: '',
    kpi: '',
    ['kpi goal']: '',
    creative: [],
    ['Frequency cap']: '',
    ['planned Start date']: '',
  };
  const params = useParams();
  const [createCampignData, setCreateCampignData] = useState(initialvalues);
  const [formErrors, setFormErrors] = useState({});
  const [numberPlannedSped, setNumberPlannedSped] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);

  const [isUpdate, setIsUpdate] = useState();
  const [timer, setTimer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [campaignTreeData, setCampaignTreeData] = useState({});
  const [UpdateTreeOnsaveDraft, setUpdateTreeOnsaveDraft] = useState(false);
  const [showTreeMenu, setShowTreeMenu] = useState(false);
  let re = /^[0-9\b]+$/;

  // const [campaignName, setCampaignName] = useState("");
  const [kpi, setkpi] = useState('CPM');
  const navigate = useNavigate();
  const location = useLocation();
  const dataFromRoute = location.state;
  const [
    getApprovalPublisherModelShowStatus,
    setApprovalPublisherModelShowStatus,
  ] = useState(false);
  const [
    getApprovalCoinfirmationPopupShowStatus,
    setApprovalCoinfirmationPopupShowStatus,
  ] = useState(false);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getPublisherInfo, setPublisherInfo] = useState([]);
  const [getPublisherError, setPublisherError] = useState('');
  const getUserInfo = state?.loginReducer?.userToken;
  const { handleAPICall } = ApproveReject();
  const [isPublisher, setIsPublisher] = useState(false);
  Array.prototype.remove = function (value) {
    this.splice(this.indexOf(value), 1);
  };

  useEffect(() => {
    if (isUpdate) {
      dispatch(getCampaignDataById(params['id']));
    }
  }, [isUpdate]);

  useEffect(() => {
    setIsUpdate(params['id']);
  }, [params['id']]);

  useEffect(() => {
    setIsUpdate(params['id']);
  }, [params['id']]);

  useEffect(() => {
    if (typeof params['id'] == 'undefined') {
      dispatch(resetCampaignState());
    }
  }, []);

  const handleSideTreeData = async () => {
    // console.log(params['id'],"kkk")
    if (isUpdate || params['id']) {
      let response = await getApi(
        `${baseUrlStg}/api/Campaign/GetCampaignWithInsertionOrderAndLineItemByID?campaignID=${params['id']}`,
        { accessToken: '1234' },
      );
      try {
        if (response?.response?.length > 0) {
          setCampaignTreeData(response?.response?.[0]);
          setShowTreeMenu(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setCampaignTreeData({});
      setShowTreeMenu(true);
    }
  };
  //side tree menu data
  // useEffect(() => {
  //   console.log("kk")
  //   handleSideTreeData();
  // },[params['id']]);
  useEffect(() => {
    handleSideTreeData();
    setShowTreeMenu(false);
  }, [params['id'], UpdateTreeOnsaveDraft, location]);

  const onChangeHandler = (e) => {
    if (e.target.type == 'checkbox') {
      if (e.target.checked) {
        creativeArr.push(e.target.value);
      } else {
        let Item = creativeArr.find((item) => item == e.target.value);
        creativeArr.remove(Item);
      }

      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: creativeArr };
      });
    } else if (e.target.name == 'Frequency cap') {
      if (e.target.value == 'no limit') {
        setCreateCampignData((prev) => {
          return {
            ...prev,
            [e.target.name]: e.target.value,
            'Frequency limit exposure': '',
            'frequency limit value': '',
          };
        });
      }
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }

    // console.log("jkjkl", e.target.value)
  };

  const DVCampaignGoalHandler = (e) => {
    dispatch(setDVCampaignGoal(e));
  };

  const campaignNameHandler = (e) => {
    dispatch(setCampaignName(e));
  };

  const kpiHandler = (e) => {
    // console.log("state",e)
    setkpi(e?.target?.value);
    dispatch(set_KPI(e));
  };

  // Prashant Chavan Code
  // const kpiValueHandler = (e) => {
  //   dispatch(set_KPI_value(e));
  // };

  // Suraj Patankar Code
  // const kpiValueHandler = (e) => {
  //   const inputValue = e.target.value;
  //   // Validate if the input is greater than 0
  //   if (parseFloat(inputValue) > 0 || inputValue === '') {
  //     dispatch(set_KPI_value(e));
  //   } else {
  //     // You can handle the case where the input is 0 or less than 0
  //     // For example, you can display an error message or reset the input
  //     console.log('Invalid input. Please enter a value greater than 0.');
  //     // You may choose to reset the input or handle it differently based on your requirements.
  //   }
  // };

  const kpiValueHandler = (e) => {
    const inputValue = e.target.value;

    // Validate if the input is greater than 0 and not exceeding 1000
    if (
      (parseFloat(inputValue) > 0 && parseFloat(inputValue) <= 1000) ||
      inputValue === ''
    ) {
      dispatch(set_KPI_value(e));
    } else {
      // Handle the case where the input is 0, less than 0, or exceeding 1000
      console.log('Invalid input. Please enter a value between 0 and 1000.');
      // You may choose to reset the input or handle it differently based on your requirements.
    }
  };

  const creativeHandler = (e) => {
    dispatch(setCreative(e));
  };

  // Prashant Chavan Code
  // const plannnedSpendHandler = (e) => {
  //   let currentVal = e.target.value;
  //   // if (/^0/.test(currentVal)) {
  //   //   currentVal.replace(/^0/, "");
  //   //   setNumberPlannedSped("The value should be greater than 0");
  //   // } else {
  //   //   if (currentVal === "" || re.test(currentVal)) {
  //   dispatch(setPlannedSpend(currentVal));
  //   // } else {
  //   //   setNumberPlannedSped("Enter a monetary value");
  //   // }
  //   // }
  // };

  const plannnedSpendHandler = (e) => {
    let currentVal = e.target.value;

    // Remove leading zeros
    currentVal = currentVal.replace(/^0+/, '');

    // Check if the value is less than 0
    if (parseFloat(currentVal) < 0) {
      // If it's less than 0, set the value to 0
      dispatch(setPlannedSpend(0));
    } else {
      // Otherwise, set the planned spend to the entered value
      dispatch(setPlannedSpend(currentVal));
    }
  };

  const StartDateHandler = (e) => {
    dispatch(setPlannedStartDate(e));
  };
  const EndDateHandler = (e) => {
    dispatch(setPlannedEndDate(e));
  };

  const frequencyCapHandler = (e) => {
    if (e?.target?.type == 'radio') dispatch(setFrequencyCapType(e));
  };

  const frequencyLimitHandler = (e) => {
    dispatch(setFrequencyLimit(e));
  };

  const frequencyLimitTypeHandler = (e) => {
    dispatch(setFrequencyLimitType(e));
  };
  const frequencyCapLimitValueHandler = (e) => {
    dispatch(setFrequencyLimitValue(e));
  };

  const qualityValueHandler = (e) => {
    dispatch(setQuality(e.target.value));
  };

  const publicInventoryhandler = (val) => {
    let values = multiSelectVal(val);
    dispatch(setPublicInvetory(values));
  };

  const dealInventoryHandler = (val) => {
    let values = multiSelectVal(val);
    dispatch(setDealsAndInventoryPackages(values));
  };

  const groupsHandle = (val) => {
    let values = multiSelectVal(val);
    dispatch(setGroups(values));
  };

  const handleCampaignCheckName = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (e?.target?.value?.trim()) {
        dispatch(checkCampName(e?.target?.value));
      }
    }, 800);

    setTimer(newTimer);
  };

  const handleRedirection = () => {
    navigate(`/dv-360/campaigns-listing`);
  };

  const handleRedirectionToNextPage = (cID) => {
    navigate(
      `/dv-360/create-insertion-order/new?campaign_id=${cID}&flag=multiple`,
    );
  };

  const handleRedirectionToNextPageOfLineItem = (insertionOrder) => {
    const returnUrl = lineItemUrlFormat(insertionOrder);
    navigate(returnUrl);
  };

  const handleSubmit = async (e) => {
    setFormErrors(validate(CreateCampaignData));
    e.preventDefault();
    setTimeout(async () => {
      if (!isUpdate) {
        //create campaign
        if (Object.keys(validate(CreateCampaignData)).length == 0) {
          setDisplayLoader(true);
          try {
            let payload = {
              campaign_id: 0,
              // "google_campaign_id": "123testcampaignid_updated",
              campaign_name: CreateCampaignData?.campaign_name,
              campaign_goal: CreateCampaignData?.campaign_goal,
              kpi_type: CreateCampaignData?.kpi_type,
              kpi_value: CreateCampaignData?.kpi_value,
              creative_type: CreateCampaignData?.creative_type,
              planned_spend: CreateCampaignData?.planned_spend || 0,
              planned_startdate: CreateCampaignData?.planned_startdate,
              planned_enddate: CreateCampaignData?.planned_enddate,
              frequency_cap_type: CreateCampaignData?.frequency_cap_type,
              frequency_limit: CreateCampaignData?.frequency_limit,
              frequency_limit_type: CreateCampaignData?.frequency_limit_type,
              frequency_cap_limit_value:
                CreateCampaignData?.frequency_cap_limit_value,
              invsource_quality: CreateCampaignData?.invsource_quality,
              invsource_publicinventoryid:
                CreateCampaignData?.invsource_publicinventoryid,
              invsource_dealspackagesid:
                CreateCampaignData?.invsource_dealspackagesid,
              invsource_groupid: CreateCampaignData?.invsource_groupid,
              targetingsource_id: CreateCampaignData?.targetingsource_id,
              moderation_status: EnabledApproval ? 'draft' : 'in_moderation',
              rejection_reason: CreateCampaignData?.rejection_reason,
              created_by: getUserInfo?.Userid,
              status: CreateCampaignData?.status,
              is_enabled: 1,
              created_at: moment().toDate(),
            };
            let response = await postApi(
              `${baseUrlStg}/api/Campaign/createCampaign`,
              payload,
              { accessToken: '2323' },
            );
            let campaignID = response?.data?.campaignID;
            if (response) {
              // console.log("res",response)
              navigate(
                `/dv-360/create-insertion-order/new?campaign_id=${campaignID}`,
                { state: { isCreateFlow: dataFromRoute?.isCreateFlow } },
              );
              dispatch(resetCampaignState());
            }
          } catch (err) {
            console.log('craetecamapign err', err);
          }
        }
      } else {
        // update campaign
        if (Object.keys(validate(CreateCampaignData)).length == 0) {
          setDisplayLoader(true);
          try {
            let payload = {
              campaign_id: CreateCampaignData?.campaign_id,
              // "google_campaign_id": "123testcampaignid_updated",
              campaign_name: CreateCampaignData?.campaign_name,
              campaign_goal: CreateCampaignData?.campaign_goal,
              kpi_type: CreateCampaignData?.kpi_type,
              kpi_value: CreateCampaignData?.kpi_value,
              creative_type: CreateCampaignData?.creative_type,
              planned_spend: CreateCampaignData?.planned_spend || 0,
              planned_startdate: CreateCampaignData?.planned_startdate,
              planned_enddate: CreateCampaignData?.planned_enddate,
              frequency_cap_type: CreateCampaignData?.frequency_cap_type,
              frequency_limit: CreateCampaignData?.frequency_limit,
              frequency_limit_type: CreateCampaignData?.frequency_limit_type,
              frequency_cap_limit_value:
                CreateCampaignData?.frequency_cap_limit_value,
              invsource_quality: CreateCampaignData?.invsource_quality,
              invsource_publicinventoryid:
                CreateCampaignData?.invsource_publicinventoryid,
              invsource_dealspackagesid:
                CreateCampaignData?.invsource_dealspackagesid,
              invsource_groupid: CreateCampaignData?.invsource_groupid,
              targetingsource_id: CreateCampaignData?.targetingsource_id,
              moderation_status: EnabledApproval
                ? 'draft'
                : CreateCampaignData?.moderation_status,
              rejection_reason: CreateCampaignData?.rejection_reason,
              created_by: getUserInfo?.Userid,
              status: CreateCampaignData?.status,
              is_enabled: 1,
              created_at: moment().toDate(),
            };
            let response = await putApi(
              `${baseUrlStg}/api/Campaign/updateCampaign`,
              '',
              payload,
              { accessToken: '2323' },
            );
            if (response?.status == 200) {
              if (EnabledApproval) {
                setApprovalPublisherModelShowStatus(true);
              } else {
                setShowModal(true);
                setDisplayLoader(false);
              }
            }
          } catch (err) {
            console.log('craetecamapign err', err);
          }
        }
      }
    }, 100);
  };

  const validate = (formData) => {
    const errors = {};

    if (CreateCampaignData?.campaign_exist) {
      errors.campaignName = 'Campaign Name already Exist';
    }
    if (formData?.campaign_name?.trim() == '') {
      errors.campaignName = 'Campaign name required';
    }
    if (formData?.creative_type == 0) {
      errors.creative = 'Please select creatives';
    }
    // if (formData?.planned_spend?.length > 0 && formData?.planned_spend <= 0) {
    //   errors.planned_spend = "Planned spend can not be neagtive or zero";
    // }
    if (!formData?.planned_startdate) {
      errors.plannedStartdate = 'Start date is required';
    }

    // if (formData?.kpi_value <= 0) {
    //   errors.kpigoal = "Kpi goal can not be negative or zero";
    // }
    // if (!formData?.["kpi_value"]) {
    //   errors.kpigoal = "Kpi goal is required.";
    // }
    if (formData?.['kpi_type']?.length == 0) {
      errors.kpi = 'Kpi is required';
    }
    if (formData?.['kpi_type']) {
      let kpiType = formData?.['kpi_type'];
      if (
        kpiType == 'CPM' ||
        kpiType == 'CPC' ||
        kpiType == 'CPA' ||
        kpiType == 'CPV' ||
        kpiType == 'CPIAVC'
      ) {
        if (formData?.kpi_value < 0) {
          errors.kpigoal = 'Kpi goal can not be negative';
        }
        // code
        // code
        if (formData?.kpi_value > 999999999998) {
          errors.kpigoal = 'Enter smaller value';
        }
        if (!formData?.['kpi_value']) {
          errors.kpigoal = 'Kpi goal is required.';
        }
      } else if (kpiType == 'other KPI') {
        if (!formData?.['kpi_value']) {
          errors.kpigoal = 'Kpi goal is required.';
        }
      } else if (kpiType == 'Viewable%' || kpiType == 'CTR') {
        if (formData?.kpi_value < 1 || formData?.kpi_value > 100) {
          errors.kpigoal = 'Kpi goal has to be between 1 and 100';
        }
        if (!formData?.['kpi_value']) {
          errors.kpigoal = 'Kpi goal is required.';
        }
      }
    }
    if (!formData?.['kpi_value']) {
      errors.kpigoal = 'Kpi goal is required.';
    }
    // if (CreateCampaignData?.planned_spend) {
    if (!formData?.['planned_spend']) {
      let spend = CreateCampaignData?.planned_spend;
      // if (spend < 1) {
      //   errors.spend = 'Kpi goal has to be greater than zero';
      // }
      // if (spend > 999999999) {
      //   errors.spend = 'Enter smaller value';
      // }
      // if (spend > -1) {
      //   errors.spend = 'Enter positive values';
      // }
      // console.log('Number(spend) :: ', Number(spend));
      // if (Number(spend) <= 0) {
      //   errors.spend = 'Enter a value greater than zero';
      // }
      if (isNaN(spend)) {
        errors.spend = 'Enter a valid value';
      }
      // if (isNaN(spend) || spend == '') {
      //   errors.spend = 'Amount is required';
      // }
    }
    if (CreateCampaignData?.frequency_cap_type.toLowerCase() != 'no limit') {
      if (!CreateCampaignData?.['frequency_limit_type'].trim()) {
        errors.frequency_limit_type = 'field required';
      }
      if (!CreateCampaignData?.frequency_limit) {
        errors.frequency_limit = 'field required';
      }
      if (CreateCampaignData?.frequency_cap_limit_value <= 0) {
        errors.frequency_cap_limit_value = 'Enter positive values';
      }
      if (!CreateCampaignData?.frequency_cap_limit_value) {
        errors.frequency_cap_limit_value = 'field required';
      }
    }
    // if (formData?.invsource_quality == '') {
    //   errors.qualityErr = 'Field is required.';
    // }
    // if (formData?.invsource_publicinventoryid?.length == 0) {
    //   errors.invsource_publicinventoryid = 'Field is required.';
    // }
    // if (formData?.invsource_dealspackagesid?.length == 0) {
    //   errors.invsource_dealspackagesid = 'Field is required.';
    // }
    // if (formData?.invsource_groupid?.length == 0) {
    //   errors.invsource_groupid = 'Field is required.';
    // }
    // if (formData?.planned_spend == "") {
    //   errors.spend = "Enter a value";
    // }
    // console.log("err",errors)
    return errors;
  };

  //save as draft -starts
  const handleSaveAsDraft = async () => {
    setFormErrors(validateOnSaveDraft(CreateCampaignData));

    setTimeout(async () => {
      if (Object.keys(validateOnSaveDraft(CreateCampaignData)).length == 0) {
        try {
          let payload = {
            campaign_id: params['id'] || 0,
            // "google_campaign_id": "123testcampaignid_updated",
            campaign_name: CreateCampaignData?.campaign_name,
            campaign_goal: CreateCampaignData?.campaign_goal,
            kpi_type: CreateCampaignData?.kpi_type,
            kpi_value: CreateCampaignData?.kpi_value || 0,
            creative_type: CreateCampaignData?.creative_type,
            planned_spend: CreateCampaignData?.planned_spend || 0,
            planned_startdate: CreateCampaignData?.planned_startdate,
            planned_enddate: CreateCampaignData?.planned_enddate,
            frequency_cap_type: CreateCampaignData?.frequency_cap_type,
            frequency_limit: CreateCampaignData?.frequency_limit,
            frequency_limit_type: CreateCampaignData?.frequency_limit_type,
            frequency_cap_limit_value:
              CreateCampaignData?.frequency_cap_limit_value,
            invsource_quality: CreateCampaignData?.invsource_quality,
            invsource_publicinventoryid:
              CreateCampaignData?.invsource_publicinventoryid,
            invsource_dealspackagesid:
              CreateCampaignData?.invsource_dealspackagesid,
            invsource_groupid: CreateCampaignData?.invsource_groupid,
            targetingsource_id: CreateCampaignData?.targetingsource_id,
            moderation_status: 'draft',
            rejection_reason: '',
            created_by: getUserInfo?.Userid,
            status: CreateCampaignData?.status,
            is_enabled: 0,
            created_at: moment().toDate(),
          };
          setDisplayLoader(true);

          let response = await postApi(
            `${baseUrlStg}/api/Campaign/SaveCampaignDraft`,
            payload,
            { accessToken: '2323' },
          );
          setDisplayLoader(false);
          if (response?.data?.status == 200) {
            // console.log("res",response)
            setShowModal(true);
            setUpdateTreeOnsaveDraft((prev) => !prev);
            // alert('success');
            navigate(`/dv-360/create-campaign/${response?.data?.campaignID}`, {
              state: { isCreateFlow: dataFromRoute?.isCreateFlow },
            });
          }
        } catch (err) {
          console.log('saveAsDraftcamapign err', err);
        }

        // let response = await getApi(`{}/api/Campaign/SaveCampaignDraft`)
      }
    }, 20);
  };

  const validateOnSaveDraft = (formData) => {
    let errors = {};
    if (formData?.campaign_name?.trim() == '') {
      errors.campaignName = 'Campaign name required';
    }
    if (formData?.campaign_goal?.trim() == '') {
      errors.campaignGoal = 'Campaign Goal required';
    }
    return errors;
  };

  //save as draft ends

  // useEffect(() => {
  //   console.log("state", CreateCampaignData);
  // });
  useEffect(() => {
    dispatch(setTargettingSources());
  }, []);

  // Redux dispatch methods end
  const handleApprovalPublisher = () => {
    if (getPublisherInfo?.length == 0) {
      setPublisherError('Please Choose atleast one option');
    } else {
      setApprovalCoinfirmationPopupShowStatus(true);
      setApprovalPublisherModelShowStatus(false);
      setPublisherError('');
    }
  };

  const handleApprovalPublisherRequest = async () => {
    try {
      let payload = {
        platform: 'DV360',
        userId: getUserInfo?.Userid,
        publisherId: getPublisherInfo?.value,
        campaignId: CreateCampaignData?.campaign_id,
        link: `${window.location.origin}/dv-360/campaignsView/${CreateCampaignData?.campaign_id}`,
      };

      setApprovalCoinfirmationPopupShowStatus(false);
      setDisplayLoader(false);
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/SendToPublisherApproval`,
        payload,
      );

      if (response?.status) {
        if (isPublisher) {
          setApprovalCoinfirmationPopupShowStatus(false);
          setDisplayLoader(false);
          let campaignId = CreateCampaignData?.campaign_id;
          handleAPICall('DV360', campaignId, getPublisherInfo?.value).then(
            (res) => {
              if (res == 200) {
                setThankYouApprovalPopupShowStatus(true);
              }
            },
          );
        } else {
          setThankYouApprovalPopupShowStatus(true);
          setDisplayLoader(true);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSetPublisherId = (e) => {
    if (e == null) {
      setPublisherInfo([]);
      setIsPublisher(false);
    } else {
      setPublisherInfo(e);
      e?.value == getUserInfo?.Userid && setIsPublisher(true);
    }
  };

  const handleCloseModel = () => {
    setApprovalPublisherModelShowStatus(false);
    setApprovalCoinfirmationPopupShowStatus(false);
    setShowModal(false);
    setPublisherInfo([]);
    setDisplayLoader(false);
  };

  const redirectToDashboard = () => {
    navigate('/dv-360/campaigns-listing');
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <Header
              saveHandler={handleSaveAsDraft}
              redirectToDashboard={redirectToDashboard}
            />
            <section className='side-menu-form d-flex'>
              {dataFromRoute?.isCreateFlow ? (
                <SideTreeMenuForCreate
                  campaignTreeData={campaignTreeData}
                  IdAndType={{ id: params['id'], type: 'campaign' }}
                  isCreateMode={true}
                  handleRedirectionToNextPage={handleRedirectionToNextPage}
                  handleRedirectionToNextPageOfLineItem={
                    handleRedirectionToNextPageOfLineItem
                  }
                  showTreeMenu={true}
                  pageType={'campaign'}
                  // AtdStatus={campaignTreeData?.moderation_status}
                  // Dv360Status={campaignTreeData?.moderation_status || 'NA'}
                />
              ) : (
                <SideTreeMenuForCreate
                  campaignTreeData={campaignTreeData}
                  IdAndType={{ id: params['id'], type: 'campaign' }}
                  isCreateMode={false}
                  handleRedirectionToNextPage={handleRedirectionToNextPage}
                  handleRedirectionToNextPageOfLineItem={
                    handleRedirectionToNextPageOfLineItem
                  }
                  showTreeMenu={showTreeMenu}
                  pageType={'campaign'}
                  AtdStatus={campaignTreeData?.moderation_status}
                  Dv360Status={campaignTreeData?.moderation_status || 'NA'}
                />
              )}
              <article className='side-right-form-wrap side-right-pad view-edit-tab-remove'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  {isUpdate && <DvErrorDisplay id={params['id']} />}
                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Overall campaign goal
                              <span
                                className='label-information tooltips tool-bottom'
                                tooltip='Cascading Style Sheets'
                                tooltip-position='top'
                              >
                                {' '}
                                {/* <i className='icon-info'></i> */}
                              </span>
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont pt-3 pb-3'>
                                  <div
                                    className='action-btn-both row'
                                    onChange={(e) => {
                                      DVCampaignGoalHandler(e);
                                    }}
                                  >
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value={goalList?.awareness}
                                        id='flexRadioDefault1'
                                        checked={
                                          CreateCampaignData?.campaign_goal ==
                                          goalList?.awareness
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault1'
                                      >
                                        <i className='icon-awareness'></i>
                                        Raise awareness of my brand or product
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value={goalList?.DriveOnline}
                                        id='flexRadioDefault2'
                                        checked={
                                          CreateCampaignData?.campaign_goal ==
                                          goalList?.DriveOnline
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault2'
                                      >
                                        <i className='icon-traffic'></i>Drive
                                        online action or visits
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value={goalList?.DriveOffline}
                                        id='flexRadioDefault6'
                                        checked={
                                          CreateCampaignData?.campaign_goal ==
                                          goalList?.DriveOffline
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault6'
                                      >
                                        <i className='icon-sales'></i> Drive
                                        offline or in-store sales
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value={goalList?.DriveApp}
                                        id='flexRadioDefault3'
                                        checked={
                                          CreateCampaignData?.campaign_goal ==
                                          goalList?.DriveApp
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault3'
                                      >
                                        <i className='icon-engagement'></i>{' '}
                                        Drive app installs or engagements
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5>Campaign Name*</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Campaign Name*</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      name='campaignName'
                                      value={CreateCampaignData?.campaign_name}
                                      id='text'
                                      placeholder='Enter campaign name'
                                      onChange={campaignNameHandler}
                                      // onBlur={handleCampaignCheckName}
                                      onKeyUp={handleCampaignCheckName}
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    {formErrors.campaignName}
                                  </span>
                                  {/* <small className="select-time">0 / 10</small> */}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5>Campaign Budget*</h5>
                            <h6 className='d-flex align-items-center'>
                              KPI*
                              <span
                                className='label-information tooltips'
                                tooltip='tootltip'
                                tooltip-position='top'
                              >
                                {/* <i className='icon-info'></i> */}
                                <span></span>
                              </span>
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-6'>
                                  <div className='label-icon'>
                                    <label>KPI*</label>
                                    <span
                                      className='label-information tooltips'
                                      tooltip='Cascading Style Sheets'
                                      tooltip-position='top'
                                    >
                                      {' '}
                                      {/* <i className='icon-info' /> */}
                                    </span>
                                  </div>

                                  <SimpleSelectBox
                                    value={CreateCampaignData?.kpi_type}
                                    name='kpi'
                                    placeholder='Select'
                                    options={[
                                      'CPM',
                                      'CPC',
                                      'CPA',
                                      'CTR',
                                      'Viewable%',
                                      // "CPV",
                                      'CPIAVC',
                                      'Other KPI',
                                    ]}
                                    onChangeHandler={kpiHandler}
                                  ></SimpleSelectBox>
                                  <span className='error' id='emailError'>
                                    {formErrors.kpi}
                                  </span>
                                </div>
                                {/* currency field */}
                                {kpi == 'Viewable%' || kpi == 'CTR' ? (
                                  <div className='form-item input-group-label col-6'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>KPI Goal</label>
                                      </div>
                                      <div className='input-group'>
                                        {/* <span className="input-group-text rs-text">
                                                                             <i className="icon-rupee" />
                                                                         </span> */}
                                        <input
                                          type='number'
                                          id='email'
                                          placeholder=''
                                          onChange={kpiValueHandler}
                                          name='kpi goal'
                                          value={CreateCampaignData?.kpi_value}
                                          onFocus={handleFocus}
                                          onKeyDown={blockInvalidChar}
                                        />
                                        <span className='input-group-text inr-text rs-text'>
                                          %
                                        </span>
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      {formErrors.kpigoal}
                                    </span>
                                  </div>
                                ) : kpi == 'other KPI' ? (
                                  <div className='form-item col-6'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>Type KPI</label>
                                      </div>
                                      <div className='input-group'>
                                        <input
                                          type='text'
                                          id='email'
                                          placeholder=''
                                          name='kpi goal'
                                          onChange={kpiValueHandler}
                                          value={CreateCampaignData?.kpi_value}
                                        />
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      {formErrors.kpigoal}
                                    </span>
                                  </div>
                                ) : (
                                  <div className='form-item input-group-label col-6'>
                                    <div className='budget-cont'>
                                      <div className='label-icon'>
                                        <label>KPI Goal*</label>
                                      </div>
                                      <div className='input-group'>
                                        <span className='input-group-text rs-text'>
                                          <i className='icon-rupee' />
                                        </span>
                                        <input
                                          type='number'
                                          id='email'
                                          placeholder='' //X.XX placeholder removed from here
                                          name='kpi goal'
                                          onChange={kpiValueHandler}
                                          value={CreateCampaignData?.kpi_value}
                                          onWheel={(e) => e.target.blur()}
                                          onFocus={handleFocus}
                                        />
                                        <span className='input-group-text inr-text rs-text'>
                                          INR
                                        </span>
                                      </div>
                                    </div>
                                    <span className='error' id='emailError'>
                                      {formErrors.kpigoal}
                                    </span>
                                  </div>
                                )}
                                {/* end of currency field */}

                                {/* <div className="col-12">
                                                                    <div className="or-line">
                                                                        or
                                                                    </div>
                                                                </div> */}

                                {/* <div className="form-item col-6">
                                                                    <div className="label-icon">
                                                                        <label>KPI</label>
                                                                        <span
                                                                            className="label-information tooltips"
                                                                            tooltip="Cascading Style Sheets"
                                                                            tooltip-position="top"
                                                                        >
                                                                            {" "}
                                                                            <i className="icon-info" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="select-box">
                                                                        <select name="drinks" required="">
                                                                            <option value="" disabled="" selected="">
                                                                                KPI Goal
                                                                            </option>
                                                                            <option value="coffee">Coffee</option>
                                                                            <option value="tea">Tea</option>
                                                                            <option value="milk">Milk</option>
                                                                        </select>
                                                                    </div>
                                                                    <span className="error" id="emailError">
                                                                        Please enter valid email id
                                                                    </span>
                                                                </div> */}
                                {/* plain text field */}
                                {/* <div className="form-item col-6">
                                                                    <div className="budget-cont">
                                                                        <div className="label-icon">
                                                                            <label>Type KPI</label>
                                                                        </div>
                                                                        <div className="input-group">
                                                                            <input type="email" id="email" placeholder="X.XX" />
                                                                        </div>
                                                                    </div>
                                                                    <span className="error" id="emailError">
                                                                        Please enter valid email id
                                                                    </span>
                                                                </div> */}
                                {/* end of plain text field */}
                              </div>
                            </div>
                          </CardBody>

                          {/* <CardHeader>
                            <div className="card-header-top">
                              <div className="card-lt-pane">
                                <h6 className="d-flex align-items-center">
                                  Creative type you expect to use
                                </h6>
                                <div className="action-btn-both action-btn-both-check row relative">
                                  <div className="col-6 d-flex flex-wrap">
                                    <div className="form-check-box col-4">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox9"
                                        defaultValue="Display"
                                        name="creative"
                                        // onChange={creativeHandler}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineCheckbox9"
                                      >
                                        Display
                                      </label>
                                    </div>
                                    <div className="form-check-box col-4">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox10"
                                        defaultValue="Video"
                                        name="creative"
                                        // onChange={creativeHandler}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineCheckbox10"
                                      >
                                        Video
                                      </label>
                                    </div>
                                    <div className="form-check-box col-4">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox11"
                                        defaultValue="Audio"
                                        name="creative"
                                        // onChange={creativeHandler}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineCheckbox11"
                                      >
                                        Audio
                                      </label>
                                    </div>
                                  </div>
                                  <span className="error" id="emailError">
                                    {formErrors.creative}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardHeader> */}

                          <CardHeader>
                            <h6 className='d-flex align-items-center'>
                              Planned spend{' '}
                              <span className='opt-text'>Optional</span>
                              <span
                                className='label-information tooltips'
                                tooltip='tootltip'
                                tooltip-position='top'
                              >
                                {/* <i className='icon-info'></i> */}
                                <span></span>
                              </span>
                            </h6>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item input-group-label col-6'>
                                  <div className='budget-cont'>
                                    <div className='label-icon'>
                                      <label>Amount</label>
                                    </div>
                                    <div className='input-group'>
                                      <span className='input-group-text rs-text'>
                                        <i className='icon-rupee' />
                                      </span>
                                      <input
                                        type='number'
                                        id='email'
                                        placeholder='X.XX'
                                        name='campaignBudget'
                                        onChange={plannnedSpendHandler}
                                        value={
                                          CreateCampaignData?.planned_spend
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        onFocus={handleFocus}
                                      />
                                      <span className='input-group-text inr-text rs-text'>
                                        INR
                                      </span>
                                    </div>
                                  </div>
                                  {/* <span className="error" id="emailError">
                                    {numberPlannedSped}
                                  </span> */}
                                  <span className='error'>
                                    {formErrors?.spend}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>

                          <CardHeader>
                            <h6 className='d-flex align-items-center'>
                              Planned dates
                              <span
                                className='label-information tooltips'
                                tooltip='tootltip'
                                tooltip-position='top'
                              >
                                {/* <i className='icon-info'></i> */}
                                <span></span>
                              </span>
                            </h6>
                          </CardHeader>

                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item input-group-label col-6'>
                                  <div className='label-icon'>
                                    <label>Start date*</label>
                                  </div>
                                  <div className='input-group'>
                                    <span className='input-group-text rs-text'>
                                      <i className='icon-calendar' />
                                    </span>
                                    {/* <input type="text" id="datepicker" placeholder="DD/MM/YYYY" name='planned Start date' onChange={onChangeHandler} /> */}
                                    {/* <ReactDatePicker
                                      selected={
                                        state?.DvCreateCampaign
                                          ?.planned_startdate &&
                                        new Date(
                                          state?.DvCreateCampaign?.planned_startdate,
                                        )
                                      }
                                      onChange={StartDateHandler}
                                      dateFormat='dd/MM/yyyy'
                                      showMonthDropdown
                                      showYearDropdown
                                      minDate={new Date()}
                                      placeholderText='DD/MM/YYYY'
                                      disabled={
                                        isUpdate &&
                                        moment(
                                          state?.DvCreateCampaign
                                            ?.planned_startdate,
                                        ).isBefore(new Date())
                                      }
                                    /> */}
                                    <ReactDatePicker
                                      selected={
                                        state?.DvCreateCampaign
                                          ?.planned_startdate &&
                                        new Date(
                                          state?.DvCreateCampaign?.planned_startdate,
                                        )
                                      }
                                      onChange={StartDateHandler}
                                      dateFormat='dd/MM/yyyy'
                                      showMonthDropdown
                                      showYearDropdown
                                      minDate={new Date()}
                                      placeholderText='DD/MM/YYYY'
                                      readOnly={
                                        isUpdate &&
                                        moment(
                                          state?.DvCreateCampaign
                                            ?.planned_startdate,
                                        ).isBefore(moment().add(1, 'day'))
                                      }
                                      disabled={
                                        isUpdate &&
                                        moment(
                                          state?.DvCreateCampaign
                                            ?.planned_startdate,
                                        ).isBefore(moment().add(1, 'day'))
                                      }
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    {formErrors.plannedStartdate}
                                  </span>
                                </div>
                                <div className='form-item input-group-label col-6'>
                                  <div className='label-icon'>
                                    <label>End date ∙ Optional</label>
                                  </div>
                                  <div className='input-group'>
                                    <span className='input-group-text rs-text'>
                                      <i className='icon-calendar' />
                                    </span>
                                    {/* <input type="text" id="datepicker" placeholder="DD/MM/YYYY" name='planned end date' onChange={onChangeHandler} /> */}
                                    <ReactDatePicker
                                      selected={
                                        state?.DvCreateCampaign
                                          ?.planned_enddate &&
                                        new Date(
                                          state?.DvCreateCampaign?.planned_enddate,
                                        )
                                      }
                                      onChange={EndDateHandler}
                                      dateFormat='dd/MM/yyyy'
                                      showMonthDropdown
                                      showYearDropdown
                                      placeholderText='DD/MM/YYYY'
                                      disabled={
                                        !state?.DvCreateCampaign
                                          ?.planned_startdate
                                      }
                                      minDate={
                                        state?.DvCreateCampaign
                                          ?.planned_startdate &&
                                        new Date(
                                          moment(
                                            state?.DvCreateCampaign
                                              ?.planned_startdate,
                                          )
                                            .add(1, 'd')
                                            .format(),
                                        )
                                      }
                                      // minDate={
                                      //   new Date(
                                      //     state?.DvCreateCampaign?.planned_startdate
                                      //   )
                                      // }
                                    />
                                  </div>
                                  {/* <span className="error" id="emailError">
                                                                        Please enter valid email id
                                                                    </span> */}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Frequency cap*
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel card-form-days dv-card-form-days'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div
                                    className='action-btn-both action-btn-no-icon row'
                                    name='Frequency cap'
                                    onChange={(e) => {
                                      // onChangeHandler(e);
                                      frequencyCapHandler(e);
                                    }}
                                  >
                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='Frequency cap'
                                          id='flexRadioDefault2Nolimit'
                                          checked={
                                            CreateCampaignData?.frequency_cap_type ==
                                            'no limit'
                                          }
                                          value='no limit'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault2Nolimit'
                                        >
                                          No limit
                                        </label>
                                      </div>
                                    </div>

                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='Frequency cap'
                                          id='flexRadioDefault3hh'
                                          value='limit frequency to'
                                          checked={
                                            CreateCampaignData?.frequency_cap_type !=
                                            'no limit'
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault3hh'
                                        >
                                          Limit frequency to
                                        </label>
                                      </div>
                                      <div className='form-item d-flex align-items-center me-3'>
                                        <input
                                          disabled={
                                            CreateCampaignData?.frequency_cap_type ==
                                            'no limit'
                                          }
                                          // disabled={
                                          //   createCampignData[
                                          //     "Frequency cap"
                                          //   ] != "limit frequency to"
                                          // }
                                          value={
                                            CreateCampaignData?.frequency_cap_type !=
                                            'no limit'
                                              ? CreateCampaignData?.frequency_cap_limit_value
                                              : ''
                                          }
                                          // value={
                                          //   createCampignData[
                                          //     "frequency limit value"
                                          //   ]
                                          // }
                                          type='number'
                                          id='email'
                                          placeholder='Enter a value'
                                          name='frequency limit value'
                                          onChange={frequencyLimitHandler}
                                          onWheel={(e) => e.target.blur()}
                                          onFocus={handleFocus}
                                        />
                                        <label>exposures per </label>
                                        <span className='error' id='emailError'>
                                          {formErrors.frequency_cap_limit_value}
                                        </span>
                                      </div>

                                      {CreateCampaignData?.frequency_limit_type ==
                                        'Months' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure months'
                                            placeholder='Select'
                                            options={['1', '2']}
                                            onChangeHandler={
                                              frequencyCapLimitValueHandler
                                            }
                                            value={
                                              CreateCampaignData?.frequency_limit
                                            }
                                          ></SimpleSelectBox>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            {formErrors.frequency_limit}
                                          </span>
                                        </div>
                                      )}
                                      {CreateCampaignData?.frequency_limit_type ==
                                        'Weeks' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure weeks'
                                            placeholder='Select'
                                            options={['1', '2', '3', '4']}
                                            onChangeHandler={
                                              frequencyCapLimitValueHandler
                                            }
                                            value={
                                              CreateCampaignData?.frequency_cap_type !=
                                              'no limit'
                                                ? CreateCampaignData?.frequency_limit_type
                                                : ''
                                            }
                                          ></SimpleSelectBox>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            {formErrors.frequency_limit}
                                          </span>
                                        </div>
                                      )}
                                      {CreateCampaignData?.frequency_limit_type ==
                                        'Days' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure days'
                                            placeholder='Select'
                                            options={[
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                            ]}
                                            onChangeHandler={
                                              frequencyCapLimitValueHandler
                                            }
                                            value={
                                              CreateCampaignData?.frequency_limit
                                            }
                                          ></SimpleSelectBox>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            {formErrors.frequency_limit}
                                          </span>
                                        </div>
                                      )}
                                      {CreateCampaignData?.frequency_limit_type ==
                                        'Hours' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure hours'
                                            placeholder='Select'
                                            options={[...Array(23)].map(
                                              (item, index) =>
                                                (index + 1).toString(),
                                            )}
                                            onChangeHandler={
                                              frequencyCapLimitValueHandler
                                            }
                                            value={
                                              CreateCampaignData?.frequency_cap_type !=
                                              'no limit'
                                                ? CreateCampaignData?.frequency_limit
                                                : ''
                                            }
                                          ></SimpleSelectBox>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            {formErrors.frequency_limit}
                                          </span>
                                        </div>
                                      )}
                                      {CreateCampaignData?.frequency_limit_type ==
                                        'Minutes' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure minutes'
                                            placeholder='Select'
                                            options={[...Array(59)].map(
                                              (item, index) =>
                                                (index + 1).toString(),
                                            )}
                                            onChangeHandler={
                                              frequencyCapLimitValueHandler
                                            }
                                            value={
                                              CreateCampaignData?.frequency_cap_type !=
                                              'no limit'
                                                ? CreateCampaignData?.frequency_limit
                                                : ''
                                            }
                                          ></SimpleSelectBox>
                                          <span
                                            className='error'
                                            id='emailError'
                                          >
                                            {formErrors.frequency_limit}
                                          </span>
                                        </div>
                                      )}
                                      <div className='form-item d-flex align-items-center'>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Lifetime of this Campaign'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                          onChangeHandler={(e) => {
                                            // onChangeHandler(e);
                                            frequencyLimitTypeHandler(e);
                                          }}
                                          // disabled={
                                          //   createCampignData[
                                          //     "Frequency cap"
                                          //   ] != "limit frequency to"
                                          // }
                                          disabled={
                                            CreateCampaignData?.frequency_cap_type ==
                                            'no limit'
                                          }
                                          value={
                                            CreateCampaignData?.frequency_cap_type !=
                                            'no limit'
                                              ? CreateCampaignData?.frequency_limit_type
                                              : ''
                                          }
                                        ></SimpleSelectBox>
                                        <span className='error' id='emailError'>
                                          {formErrors.frequency_limit_type}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          <CardBody>
                            <InfoText></InfoText>
                          </CardBody>
                        </Card>
                      </article>
                    </div>
                  </div>
                </div>
              </article>
              {/* view starts */}
              <div
                className='tab-pane fade'
                id='review'
                role='tabpanel'
                aria-labelledby='review-tab'
              >
                <div className='rv-details-cont'>
                  <div className='card-primary-repet'>
                    <div className='card-primary'>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Campaign name</h5>
                          <p>New Awareness Campaign</p>
                          <p>ID: 23857805967620728</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Special ad categories</h5>
                          <p>No categories declared</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Buying type</h5>
                          <p>Awareness</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Ad sets</h5>
                          <p className='add-data'>Please add: Ad sets</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Format</h5>
                          <p>Image and Video</p>
                        </div>
                        <div className='card-rt-pane d-flex me-auto align-items-center'>
                          <figure className='prev-icon'>
                            <img src={atdplaceholder} />
                          </figure>
                          <button
                            type='button'
                            className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                          >
                            Preview
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* view ends */}
              <div
                className='tab-pane fade'
                id='history'
                role='tabpanel'
                aria-labelledby='history-tab'
              ></div>
            </section>
          </div>
        </div>
      </div>
      {displayLoader && <Loader />}
      <CommonModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        copy={'Campaign has been successfully saved as Draft.'}
      />
      {isUpdate && (
        <CommonModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleRedirection={() => handleRedirection()}
          copy={'Campaign has been successfully saved.'}
          isUpdatedStatus={isUpdate}
        />
      )}
      {getApprovalPublisherModelShowStatus && (
        <SelectApprovalPublisher
          type={'dv360'}
          handleApprovalPublisher={handleApprovalPublisher}
          handleSetPublisherId={handleSetPublisherId}
          getPublisherInfo={getPublisherInfo}
          getPublisherError={getPublisherError}
          handleCloseModel={handleCloseModel}
        />
      )}
      {getApprovalCoinfirmationPopupShowStatus && (
        <ApprovalConfirmationPopup
          type={'dv360'}
          handleApprovalPublisherRequest={handleApprovalPublisherRequest}
          handleCloseModel={handleCloseModel}
        />
      )}
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          approved={isPublisher ? 'approved' : ''}
        />
      )}
      <Footer
        // nextPath="/dv-360-insertion-order"
        closePath={'/dv-360/campaigns-listing'}
        nextBtnlabel={isUpdate ? 'Save' : 'Next'}
        handleSubmit={handleSubmit}
        getAtdStatus={campaignTreeData?.moderation_status}
      ></Footer>
    </div>
  );
}

export default CreateCampaign;
