import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import ReactTable from '../../../components/common/ReactTable';
import { getApi } from '../../../utils/Apis';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/common/Loader';
import { toast, ToastContainer } from 'react-toastify';

import NavHeading from '../../../components/common/NavHeading';
import { baseUrlStg } from '../../../utils/constants';
import axios from 'axios';
import { getCookies } from '../../../utils/utils';
import { useSelector } from 'react-redux';
const TaxonomyMediaPlanListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState('');
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  const navigate = useNavigate();

  useEffect(() => {
    getAllMediaPlans(searchInputValue);
  }, [itemsPerPage, currentPage]);

  const getAllMediaPlans = async (InputValue) => {
    try {
      setIsLoading(true);

      let url = `${baseUrlStg}/api/taxonomy-media/GetMediaPlanList?page=${
        currentPage + 1
      }&limit=${itemsPerPage}`;
      if (InputValue) {
        url = url + `&search=${InputValue}`;
      }
      const res = await getApi(url);
      if (res.success) {
        setData(res.getMediaPlanList);
        setTotalRecords(res.totalRecords);
        setTotalPages(Math.ceil(res.totalRecords / Number(itemsPerPage)));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSearchInput = (event) => {
    const value = event?.target?.value?.trim();

    setSearchInputValue(value);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleNavigate = () => {
    navigate('/taxonomy-manager/create-media-plan');
  };

  const getSearchResult = () => {
    if (!searchInputValue) {
      return;
    }
    setCurrentPage(0);
    getAllMediaPlans(searchInputValue);
  };

  const handleclearSearch = () => {
    if (!searchInputValue) {
      return;
    }
    setCurrentPage(0);
    setSearchInputValue((prev) => (prev = ''));
    getAllMediaPlans('');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'planid',
        Cell: ({ row }) => {
          return currentPage * itemsPerPage + (row.index + 1);
        },
      },
      {
        Header: 'Plan Name',
        accessor: 'planName',
      },
      {
        Header: 'QT Number',
        accessor: 'qtNumber',
      },
      {
        Header: 'Campaign List',
        accessor: 'campaignCount',
        Cell: ({ row }) => (
          <Link
            className='btn-icon btn-icon-primary btn-td-grey'
            to={`/taxonomy-manager/media-campaign-list?mediaplan_id=${row.original.planid}`}
          >
            <i className='icon-line-dot'></i> Campaign List(
            {row.original.campaignCount})
          </Link>
        ),
      },
      {
        Header: 'Create Campaign',
        Cell: ({ row }) => (
          <Link
            className='btn-icon btn-icon-primary'
            to={`/taxonomy-manager/create-campaign?mediaplan_id=${row.original.planid}`}
          >
            <i className='icon-plus'></i>Create Campaign
          </Link>
        ),
      },
      {
        Header: 'Download',
        Cell: ({ row }) => (
          <button
            onClick={() =>
              handleFileDownload(row.original.planid, row?.original?.qtNumber)
            }
            className='btn-icon btn-download'
          >
            <i className='icon-download'></i>
          </button>
        ),
      },
    ],
    [currentPage, itemsPerPage],
  );

  const handleFileDownload = async (id, qtNumber) => {
    const downloadUrl = `${baseUrlStg}/api/TaxonomyAdCreate/GetExcelFile?mediaplanId=${id}`;
    let res = await axios.get(downloadUrl, {
      headers: {
        accept: 'text/plain',
        ...CustomHeader,
      },
      responseType: 'blob',
    });
    const fileURL = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = fileURL;
    let fileName = `MediaPlan_${qtNumber}.xls`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  // const handleFileDownload = (id) => {
  //   const downloadUrl = `${baseUrlStg}/api/TaxonomyAdCreate/GetExcelFile?mediaplanId=${id}`;
  //   const link = document.createElement('a');
  //   link.href = downloadUrl;
  //   document.body.appendChild(link);
  //   link.click();
  // };

  // for Delete if we have to add delete functionality then set showcheckbox prop of react table true

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = useCallback((selectedRowsIds) => {
    setSelectedRows(selectedRowsIds);
  }, []);

  const handleMultipleDelete = () => {
    const deleteId = selectedRows.map((row) => row.campaign_id);

    setShow(true);

    setDeleteId(deleteId);
  };

  const LoginReducer = useSelector((state) => state?.loginReducer);
  const user = LoginReducer?.userToken;

  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container taxonomy-dashboard facebook-dashboard'>
          <div className='container'>
            <div className='taxonomy-panel'>
              <div>
                <Sidebar />
              </div>
              <div className='right-side-box padding-bottom-extra media-section-left'>
                <header>
                  <NavHeading HeadingName={'Taxonomy Manager'} />

                  <div className='search-bar-cont'>
                    <div className='search'>
                      <div className='search-close'>
                        <input
                          type='text'
                          placeholder='Search Media Plan'
                          value={searchInputValue}
                          onChange={handleSearchInput}
                        />
                        <button
                          onClick={handleclearSearch}
                          className='close-btn'
                        >
                          <i className='icon-close'></i>
                        </button>
                      </div>
                      <button
                        onClick={getSearchResult}
                        className='btn-icon btn-icon-primary'
                      >
                        <i className='icon-search'></i>
                      </button>
                    </div>
                  </div>
                </header>

                <div className='camp-tab-cont'>
                  <a href='#' className='primary-btn active'>
                    <i className='icon-camopaign'></i> Media Plans
                  </a>
                </div>

                <div className='short-entry-cont'>
                  {/* {user?.roles?.toLowerCase() == 'admin' && ( */}
                  <div className='short-left-bar'>
                    <button
                      onClick={handleNavigate}
                      className={`btn-icon btn-icon-primary ${
                        user?.roles?.toLowerCase() !== 'admin'
                          ? 'btn-selected'
                          : ''
                      } `}
                      title={'Only Admin can create media plan'}
                      disabled={user?.roles?.toLowerCase() !== 'admin'}
                    >
                      <i className='icon-plus'></i>Create New media plan
                    </button>
                  </div>
                  {/* )} */}
                  <div className='sd-Table'>
                    <div className='table-responsive'>
                      {data.length > 0 && (
                        <div
                          id='example_wrapper'
                          className='dataTables_wrapper no-footer'
                        >
                          <div
                            className='dataTables_length'
                            id='Facebookcampaigntable_length'
                          >
                            <label>
                              Show{' '}
                              <select
                                name='Facebookcampaigntable_length'
                                aria-controls='Facebookcampaigntable'
                                value={itemsPerPage}
                                onChange={(e) => [
                                  setItemsPerPage(Number(e.target.value)),
                                  // setItemsPerPage(0),
                                  setCurrentPage(0),
                                ]}
                              >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>
                        </div>
                      )}
                      <ReactTable
                        columns={columns}
                        data={data}
                        totalCount={totalRecords}
                        showCheckbox={false}
                        onSelectionChange={handleSelectionChange}
                      />
                      {data && (
                        <div className='media-entries dataTables_paginate'>
                          <div className='show-entries'>
                            {data.length > 0 && (
                              <p>
                                Showing{' '}
                                {currentPage == 0
                                  ? 1
                                  : Math.ceil(
                                      currentPage * itemsPerPage +
                                        (itemsPerPage + 1) -
                                        itemsPerPage,
                                    )}{' '}
                                to{' '}
                                {Math.ceil(
                                  currentPage * itemsPerPage + data.length,
                                )}{' '}
                                of {totalRecords} entries
                              </p>
                            )}
                          </div>
                          <div className='media-pagination'>
                            <div className='media-pagination dataTables_paginate'>
                              <ReactPaginate
                                breakLabel={'...'}
                                marginPagesDisplayed={1}
                                previousLabel={<button>Previous</button>}
                                nextLabel={<button>Next</button>}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                forcePage={currentPage}
                                renderOnZeroPageCount={null}
                                pageRangeDisplayed={5}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TaxonomyMediaPlanListing;
