import '../../../assets/css/style.css';
import { Link, useNavigate } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import ReactTable from '../../../components/common/ReactTable';
import { GoogleAdOptions } from '../GoogleAdConstant';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { RemarkCell } from './RemarkCall';
import ReactPaginate from 'react-paginate';

const ReviewLog = ({ id, platForm, form }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const fetchData = async () => {
    try {
      const response = await getApi(
        `${baseUrlStg}/api/GoogleAdListing/logs?id=${id}&platform=${platForm}&form=${form}&page=${currentPage}&limit=${itemsPerPage}`,
      );
      if (response) {
        setData(response?.logs);
        setTotalRecords(response.totalItems);
        setTotalRecords(response?.totalItems);
        setTotalPages(Math.ceil(response?.totalItems / Number(itemsPerPage)));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = useCallback((selectedRowsIds) => {
    setSelectedRows(selectedRowsIds);
  }, []);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => (
          <span style={{ fontWeight: 'bold' }}>{row.original.date || '-'}</span>
        ),
      },
      {
        Header: 'Assigned By',
        accessor: 'created_by',
        Cell: ({ row }) => {
          return <span>{row.original.created_by || '-'}</span>;
        },
      },
      {
        Header: 'Assigned To',
        accessor: 'publisherName',
        Cell: ({ row }) => {
          return <span>{row.original.publisherName || '-'}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'moderation_status',
        Cell: ({ row }) => {
          const status = row.original.moderation_status || '-';
          let color = 'black';
          let text = capitalizeFirstLetter(status);
          switch (status) {
            case 'Published':
            case 'published':
            case 'Published(Enabled)':
              color = '#0d6efd';
              break;
            case 'In Moderation':
            case 'in_moderation':
              color = '#ffc107';
              break;
            case 'Error':
            case 'Rejected':
              color = 'red';
              break;
            case 'Approve':
              color = 'green';
              break;
            case 'Draft':
            case 'draft':
            case 'Published(Disabled)':
              color = 'gray';
            default:
              break;
          }
          return <span style={{ color, fontWeight: 'bold' }}>{text}</span>;
        },
      },
      {
        Header: 'Remark',
        accessor: 'remark',
        // Cell: ({ row }) =>
        //   row.original.remark ? (
        //     <span>{capitalizeFirstLetter(row.original.remark)}</span>
        //   ) : (
        //     '-'
        //   ),
        Cell: RemarkCell,
      },
      // Add more columns as needed
    ],
    [],
  );

  return (
    <div className='card-primary-repet'>
      <ReactTable
        columns={columns}
        data={data}
        totalCount={totalRecords}
        onSelectionChange={handleSelectionChange}
        showCheckbox={false}
      />
      {data && (
        <div className='media-entries dataTables_paginate'>
          <div className='show-entries'>
            {data.length > 0 && (
              <p>
                Showing{' '}
                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}{' '}
                to{' '}
                {Number(currentPage - 1) * Number(itemsPerPage) + data.length}{' '}
                of {totalRecords} entries
              </p>
            )}
          </div>
          <div className='media-pagination'>
            {totalRecords > itemsPerPage && (
              <div className='media-pagination dataTables_paginate'>
                <ReactPaginate
                  breakLabel={'...'}
                  marginPagesDisplayed={1}
                  previousLabel={<button>Previous</button>}
                  nextLabel={<button>Next</button>}
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  forcePage={currentPage - 1}
                  renderOnZeroPageCount={null}
                  pageRangeDisplayed={5}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewLog;
