const AddCardMedia = (props) => {
  return (
    <>
      <div
        className={
          props?.length == 0 ? 'media-card default-img-class' : 'media-card'
        }
      >
        <div className='select-media'>
          <div className='mediaimg-box'>
            <div className='media-img'>
              <div class='media-btn'>
                <button
                  class='btn-icon btn-icon-primary'
                  onClick={props?.onClickHandle}
                >
                  <i class='icon-plus'></i>
                  {props?.title}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddCardMedia;
