import {
  GOOGLE_AD_FINAL_MOBILE_URL,
  GOOGLE_AD_FINAL_URL_SUFFIX,
  GOOGLE_AD_GROUP_AD_GROUP_HOUSEHOLD_INCOME,
  GOOGLE_AD_GROUP_AD_GROUP_PRODUCT_ID,
  GOOGLE_AD_GROUP_AUDIENCE,
  GOOGLE_AD_GROUP_CSV_FILE,
  GOOGLE_AD_GROUP_DEMOGRAPHIC_AGE,
  GOOGLE_AD_GROUP_DEMOGRAPHIC_GENDER,
  GOOGLE_AD_GROUP_DEMOGRAPHIC_PARENTAL_STATUS,
  GOOGLE_AD_GROUP_LANGUAGE,
  GOOGLE_AD_GROUP_NAME,
  GOOGLE_AD_GROUP_OPTIMIZED_TARGETING,
  GOOGLE_AD_GROUP_PLACEMENTS,
  GOOGLE_AD_GROUP_TOPICS,
  GOOGLE_AD_IS_MOBILE_URL_ENABLED,
  GOOGLE_AD_TRACKING_TEMPLATE,
  RESET_GOOGLE_AD_GROUP_STATE,
  SET_BID_CPC,
  GOOGLE_AD_GROUP_PREFILLED_VALUES,
} from './googleAdGroupActionTypes';

export const setAdGroupName = (e) => {
  let name = e;
  return {
    type: GOOGLE_AD_GROUP_NAME,
    payload: name,
  };
};
export const setAdGroupCSVFile = (e) => {
  let file = e;
  console.log('e file', file);
  return {
    type: GOOGLE_AD_GROUP_CSV_FILE,
    payload: file,
  };
};

export const setTopics = (e) => {
  let topics = e;
  return {
    type: GOOGLE_AD_GROUP_TOPICS,
    payload: topics,
  };
};

export const setAudience = (e) => {
  let audience = e;
  return {
    type: GOOGLE_AD_GROUP_AUDIENCE,
    payload: audience,
  };
};

export const setDemographicGender = (e) => {
  let gender = e;
  return {
    type: GOOGLE_AD_GROUP_DEMOGRAPHIC_GENDER,
    payload: gender,
  };
};

export const setOptimizedTargeting = (e) => {
  let optimizedTargeting = e;
  return {
    type: GOOGLE_AD_GROUP_OPTIMIZED_TARGETING,
    payload: optimizedTargeting,
  };
};

export const setDemographicParentalStatus = (e) => {
  let parentalStatus = e;
  return {
    type: GOOGLE_AD_GROUP_DEMOGRAPHIC_PARENTAL_STATUS,
    payload: parentalStatus,
  };
};

export const setDemographicHouseholdIncome = (e) => {
  let householdIncome = e;
  return {
    type: GOOGLE_AD_GROUP_AD_GROUP_HOUSEHOLD_INCOME,
    payload: householdIncome,
  };
};

export const setPlacements = (e) => {
  let placements = e;
  return {
    type: GOOGLE_AD_GROUP_PLACEMENTS,
    payload: placements,
  };
};

export const setDemographicAge = (e) => {
  let age = e;
  return {
    type: GOOGLE_AD_GROUP_DEMOGRAPHIC_AGE,
    payload: age,
  };
};

export const setGoogleProductId = (e) => {
  let productId = e;
  return {
    type: GOOGLE_AD_GROUP_AD_GROUP_PRODUCT_ID,
    payload: productId,
  };
};

export const setLanguage = (e) => {
  let language = e;

  return {
    type: GOOGLE_AD_GROUP_LANGUAGE,
    payload: language,
  };
};

export const setTrackingTemplate = (payload) => {
  return {
    type: GOOGLE_AD_TRACKING_TEMPLATE,
    payload: payload,
  };
};

export const setFinalUrlSuffix = (payload) => {
  return {
    type: GOOGLE_AD_FINAL_URL_SUFFIX,
    payload: payload,
  };
};

export const setIsMobileUrlEnabled = (payload) => {
  return {
    type: GOOGLE_AD_IS_MOBILE_URL_ENABLED,
    payload: payload,
  };
};

export const setFinalMobileUrl = (payload) => {
  return {
    type: GOOGLE_AD_FINAL_MOBILE_URL,
    payload: payload,
  };
};

export const setBidCpc = (payload) => {
  return {
    type: SET_BID_CPC,
    payload: payload,
  };
};

export const resetGoogleAdGroupState = () => ({
  type: RESET_GOOGLE_AD_GROUP_STATE,
});

export const setPrefilledValues = (payload) => ({
  type: GOOGLE_AD_GROUP_PREFILLED_VALUES,
  payload: payload,
});
