import React from 'react';
import { checkForNull } from '../../../../utils/utils';

const DataDetails = ({ Label, LabelFor }) => {
  return (
    <div className='card-header-top d-flex'>
      <div className='card-lt-pane'>
        <h5>{Label}</h5>
        <p>{checkForNull(LabelFor)}</p>
      </div>
    </div>
  );
};

export default DataDetails;
