import Accordion from 'react-bootstrap/Accordion';
import { renderMedia } from '../../utils/utils';

const SelectedMediaWidgetItems = ({ items, callBackFunction }) => {
  return (
    <>
      {[...items].map((item, index) => {
        return (
          <>
            <div className='media-card'>
              <div className='select-media'>
                <div className='mediaimg-box'>
                  <div className='media-img'>{renderMedia(item)}</div>
                  <div
                    className='media-social'
                    onClick={() =>
                      callBackFunction((prev) => [
                        ...prev.slice(0, index),
                        ...prev.slice(index + 1),
                      ])
                    }
                  >
                    <i class='icon-delete'></i>
                  </div>
                </div>
                <div className='mediabox-cont'>
                  <h3>{item?.mediaName || item?.medFileName}</h3>
                </div>
              </div>
            </div>
            {/* <div className='slideshow-box-group-rept'>
                        <div className='slideshow-box d-flex'>
                          <div className='slideshow-imgbox d-flex align-items-center'>
                            {renderMedia(item)}
                          </div>
                          <div className='slideshow-cont'>
                            <div className='slideshow-cont-top'>
                              <p>
                                <i className='icon-images'></i>
                                {item?.mediaName || item?.medFileName}
                              </p>
                            </div>
                          </div>

                          <button
                            className='close-slideshow'
                            onClick={() =>
                              callBackFunction((prev) => [
                                ...prev.slice(0, index),
                                ...prev.slice(index + 1),
                              ])
                            }
                          >
                            <i className='icon-close' />
                          </button>
                        </div>
                      </div> */}
          </>
        );
      })}

      {/* <div className='hide-show'>
        <div className='carosuel-accordion-full'>
          <section className='accordion-primary-cont d-flex'>
            <article className='accordion-data-cont'>
              <Accordion defaultActiveKey='0'>
                {[...items].map((item, index) => {
                  return (
                    <>
                      <div className='slideshow-box-group-rept'>
                        <div className='slideshow-box d-flex'>
                          <div className='slideshow-imgbox d-flex align-items-center'>
                            {renderMedia(item)}
                          </div>
                          <div className='slideshow-cont'>
                            <div className='slideshow-cont-top'>
                              <p>
                                <i className='icon-images'></i>
                                {item?.mediaName || item?.medFileName}
                              </p>
                            </div>
                          </div>

                          <button
                            className='close-slideshow'
                            onClick={() =>
                              callBackFunction((prev) => [
                                ...prev.slice(0, index),
                                ...prev.slice(index + 1),
                              ])
                            }
                          >
                            <i className='icon-close' />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Accordion>
            </article>
          </section>
        </div>
      </div> */}
    </>
  );
};
export default SelectedMediaWidgetItems;
