import React from 'react';
import Card from '../common/Card';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';

const AudienceSegment = ({ options, value, onChange, fromErrors }) => {
  return (
    <Card>
      <CardHeader>
        <h5>
          Audience
          <span
            className='label-information tooltips tool-bottom'
            tooltip='Cascading Style Sheets'
            tooltip-position='top'
          >
            <i className='icon-info'></i>
            <span>
              {' '}
              Audiences are determined by people's activity using Google
              products, or estimated based on content certain groups of people
              are likely to be interested in. You can customize your audience
              targeting by creating various audience segments and attaching them
              to your ad group. Data from your audience sources may be used to
              improve the bidding and targeting of your audience campaigns.
            </span>
          </span>
        </h5>
        <p>
          Audiences allow you to reach people based on who they are, their
          interests and habits, what they're actively researching, or how
          they've interacted with your business or organization. To ensure your
          ads deliver the best results, select an existing audience or create a
          new one
        </p>
      </CardHeader>
      <CardBody>
        <div className='form-item '>
          <div className='select-box select-multiple-components '>
            <ReactSelect
              placeholder={'Select Audience Segments'}
              isSearchable={true}
              isClearable={false}
              closeMenuOnSelect={true}
              value={value}
              isMulti={true}
              options={options}
              onChange={onChange}
            />
          </div>
          <div className='error' id='nameError'>
            {fromErrors?.AudienceSegment}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AudienceSegment;
