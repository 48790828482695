import {
  MEDIA_DIVISION,
  MEDIA_PRODUCT_NAME,
  MEDIA_PHASE,
  MEDIA_QUARTER,
  MEDIA_FUNDING_SOURCE,
  MEDIA_CAMPAIGN_ID,
  MEDIA_QT_NUMBER,
  MEDIA_CLEAR_FORM,
} from './CreateMediaPlaneActionTypes';

Array.prototype.remove = function (value) {
  this.splice(this.indexOf(value), 1);
};

const initialState = {
  mediaDivision: null,
  mediaProductName: '',
  mediaPhase: null,
  mediaQuarter: '',
  mediaFundingSource: null,
  mediaCampaignId: '',
  mediaQtNumber: '',
};

export const TexonomyCreateMediaPlane = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case MEDIA_DIVISION: {
      const value = payload;
      return { ...state, mediaDivision: value };
    }

    case MEDIA_PRODUCT_NAME: {
      const value = payload;
      return { ...state, mediaProductName: value };
    }
    case MEDIA_PHASE: {
      const value = payload;
      return { ...state, mediaPhase: value };
    }
    case MEDIA_QUARTER: {
      const value = payload;
      return { ...state, mediaQuarter: value };
    }
    case MEDIA_FUNDING_SOURCE: {
      const value = payload;
      return { ...state, mediaFundingSource: value };
    }
    case MEDIA_CAMPAIGN_ID: {
      const value = payload;
      return { ...state, mediaCampaignId: value };
    }

    case MEDIA_QT_NUMBER: {
      const value = payload;
      return { ...state, mediaQtNumber: value };
    }

    case MEDIA_CLEAR_FORM: {
      const value = initialState;
      return value;
    }

    default:
      return state;
  }
};
