import React from 'react';

function ImageCardBody(props) {
  return (
    <>
      <div className='card-primary-repet'>
        <div className='card-primary'>
          <div className='card-header-top d-flex'>
            <div className='card-lt-pane'>
              <p>{props?.subTitle}</p>
              <h6 className='d-flex align-items-center'>{props?.title}</h6>
            </div>
          </div>
          {/* <div className='card-body'>{props.children}</div> */}
          {props.children}
        </div>
      </div>
    </>
  );
}

export default ImageCardBody;
