export const GOOGLE_AD_FINAL_URL = 'GOOGLE_AD_FINAL_URL';
export const GOOGLE_AD_DISPLAY_PATH_0 = 'GOOGLE_AD_DISPLAY_PATH_0';
export const GOOGLE_AD_DISPLAY_PATH_1 = 'GOOGLE_AD_DISPLAY_PATH_1';
export const GOOGLE_AD_CALLS = 'GOOGLE_AD_CALLS';
export const GOOGLE_AD_LEADFORMS = 'GOOGLE_AD_LEADFORMS';
export const GOOGLE_AD_SITELINKS = 'GOOGLE_AD_SITELINKS';
export const GOOGLE_AD_ADD_HEADLINES = 'GOOGLE_AD_ADD_HEADLINES';
export const GOOGLE_AD_DEL_HEADLINES = 'GOOGLE_AD_DEL_HEADLINES';
export const GOOGLE_AD_UPDATE_HEADLINES = 'GOOGLE_AD_UPDATE_HEADLINES';
export const GOOGLE_AD_ADD_DESCRIPTIONS = 'GOOGLE_AD_ADD_DESCRIPTIONS';
export const GOOGLE_AD_DEL_DESCRIPTIONS = 'GOOGLE_AD_DEL_DESCRIPTIONS';
export const GOOGLE_AD_DEL_SHORT_DESCRIPTIONS =
  'GOOGLE_AD_DEL_SHORT_DESCRIPTIONS';
export const GOOGLE_AD_UPDATE_DESCRIPTIONS = 'GOOGLE_AD_UPDATE_DESCRIPTIONS';
export const GOOGLE_AD_BUSINESS_NAME = 'GOOGLE_AD_BUSINESS_NAME';
export const GOOGLE_AD_GET_FORM_DATA = 'GOOGLE_AD_GET_FORM_DATA';
export const GOOGLE_AD_AD_NAME = 'GOOGLE_AD_AD_NAME';
export const GOOGLE_AD_VIDEO_ID = 'GOOGLE_AD_VIDEO_ID';
export const GOOGLE_AD_CALL_TO_ACTION = 'GOOGLE_AD_CALL_TO_ACTION';
export const GOOGLE_AD_VIDEO_HEADLINE = 'GOOGLE_AD_VIDEO_HEADLINE';
export const GOOGLE_AD_VIDEO_LONG_HEADLINE = 'GOOGLE_AD_VIDEO_LONG_HEADLINE';
export const GOOGLE_AD_DESCRIPTION = 'GOOGLE_AD_DESCRIPTION';
export const GOOGLE_AD_TRACKINGTEMPLATE = 'GOOGLE_AD_TRACKINGTEMPLATE';
export const GOOGLE_AD_FINAL_URL_SUFFIX = 'GOOGLE_AD_FINAL_URL_SUFFIX';
export const GOOGLE_AD_ISMOBILE_URL_ENABLED = 'GOOGLE_AD_ISMOBILE_URL_ENABLED';
export const GOOGLE_AD_FINAL_MOBILE_URL = 'GOOGLE_AD_FINAL_MOBILE_URL';
export const GOOGLE_AD_SET_COMPANION_BANNER_TYPE =
  'GOOGLE_AD_SET_COMPANION_BANNER_TYPE';
export const GOOGLE_AD_SET_COMPANION_BANNER_ID =
  'GOOGLE_AD_SET_COMPANION_BANNER_ID';
export const GOOGLE_AD_ADDITIONAL_FORMAT_OPTIONS =
  'GOOGLE_AD_ADDITIONAL_FORMAT_OPTIONS';
export const GOOGLE_AD_ISCALLTOACTION_ENABLED =
  'GOOGLE_AD_ISCALLTOACTION_ENABLED';
export const GOOGLE_AD_CUSTOM_COLOR_ENABLED = 'GOOGLE_AD_CUSTOM_COLOR_ENABLED';
export const GOOGLE_AD_CUSTOM_COLOR = 'GOOGLE_AD_CUSTOM_COLOR';
export const GOOGLE_AD_ACCENT_COLOR = 'GOOGLE_AD_ACCENT_COLOR';
export const GOOGLE_AD_SHOW_THIS_AD_STATUS = 'GOOGLE_AD_SHOW_THIS_AD_STATUS';
export const GOOGLE_AD_SELECTED_CREATE_TYPE = 'GOOGLE_AD_SELECTED_CREATE_TYPE';
export const GOOGLE_AD_RESET_FORM = 'GOOGLE_AD_RESET_FORM';
export const GOOGLE_AD_ADD_LONG_HEADLINES = 'GOOGLE_AD_ADD_LONG_HEADLINES';
export const GOOGLE_AD_DEL_LONG_HEADLINES = 'GOOGLE_AD_DEL_LONG_HEADLINES';
export const GOOGLE_AD_UPDATE_LONG_HEADLINES =
  'GOOGLE_AD_UPDATE_LONG_HEADLINES';
export const GOOGLE_AD_ADD_SHORT_DESCRIPTIONS =
  'GOOGLE_AD_ADD_SHORT_DESCRIPTIONS';
export const GOOGLE_AD_UPDATE_SHORT_DESCRIPTIONS =
  'GOOGLE_AD_UPDATE_SHORT_DESCRIPTIONS';
export const GOOGLE_AD_APP_URL = 'GOOGLE_AD_APP_URL';
export const DV_Prefilled_Values = 'DV_Prefilled_Values';
export const GOOGLE_AD_FINAL_URL_PERFORMANCE =
  'GOOGLE_AD_FINAL_URL_PERFORMANCE';
