import '../../../assets/css/style.css';
import Sidebar from '../../../components/common/Sidebar';
import Footer from '../../../components/common/Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg, googleV1Url } from '../../../utils/constants';
import { useEffect } from 'react';
import DataDetails from './Components/DataDetails';
import { useState } from 'react';
import { GoogleAdOptions } from '../GoogleAdConstant';
import SideTreeMenuView from '../Components/SideTreeMenuView';
import ErrorDisplay from '../../../components/google/ErrorDisplay';
import ReviewLog from '../ReviewLog/ReviewLog';
import { useSelector } from 'react-redux';
import ApproveReject from '../../../components/common/ApproveReject';
import ThankYouApprovalPopup from '../../../components/common/ThankYouApprovalPopup';
import { downloadFile, getCookies } from '../../../utils/utils';
import axios from 'axios';
import DisplayCampaignStatus from '../../../components/common/DisplayCampaignStatus';
import Loader from '../../../components/common/Loader';

const AdGroupsView = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isViewPage, setIsViewPage] = useState(true);
  const [campaignId, setCampaignId] = useState('');
  const [getCurrentStatus, setCurrentStatus] = useState('');
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getApprovalFlag, setApprovalFlag] = useState(false);
  const [getFileName, setFileName] = useState('');
  const [getFileDisplayStatus, setDisplayStatus] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const { id } = useParams();
  const fetchData = async () => {
    try {
      const response = await getApi(`${googleV1Url}/GetGoogleAdGroup?id=${id}`);
      if (response) {
        setData(response?.data);
        setCampaignId(response?.data?.campaignId);
        setCurrentStatus(response?.data?.atdStatus);
        setApprovalFlag(response?.approvalButton);
        setFileName(response?.data?.productFileName);
        setDisplayStatus(response?.data?.isAllProductsEnabled);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    totalKeywords = '-',
    adGroupName = '-',
    adGroupsId = '-',
    googleAdGroupsId = '-',
    googleKeywords = '-',
    audience,
    googleCampaignUrlData = '-',
    adGroupGoogleRefId,
    topics,
    googleAdGroupDemographics,
    language,
    placements,
  } = data || {};

  const GotoPage = GoogleAdOptions.AdGroups.url;

  useEffect(() => {
    if (query.get('platForm') === GoogleAdOptions.AdGroups.platform) {
      setIsViewPage(false);
    } else {
      setIsViewPage(true);
    }
  }, [query.get('platForm')]);

  const goToListingPage = () => {
    navigate(GotoPage);
  };

  const campaign_id = data?.campaignId;

  const handleRedirection = () => {
    navigate(GoogleAdOptions.AdGroups.url);
  };
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  const handleFileDownload = async (id, qtNumber) => {
    const downloadUrl = `${baseUrlStg}/api/GoogleListingDetails/DownLoadCSVV2?adGroupId=${id}`;
    let res = await axios.get(downloadUrl, {
      headers: {
        accept: 'text/plain',
        ...CustomHeader,
      },
      responseType: 'blob',
    });
    const fileURL = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = fileURL;
    let fileName = `Keyword_${qtNumber}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadProductListingFile = (fileName) => {
    let endPoint = `${baseUrlStg}/api/GoogleProduct/GetProductListByAdGroupId?adGroupId=${id}`;
    downloadFile(endPoint, fileName);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              {data && (
                <SideTreeMenuView
                  campaignId={campaign_id}
                  IdAndType={{
                    id: id,
                    type: GoogleAdOptions.AdGroups.name,
                  }}
                />
              )}
              <article className='side-right-form-wrap side-right-pad'>
                <div className='tab-new-form'>
                  <div className='camp-tab-cont d-flex'>
                    <div className='short-left-bar'>
                      <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <Link
                          className={`primary-btn ${
                            isViewPage ? 'active' : ''
                          }`}
                          to={`${GoogleAdOptions.AdGroups.viewPageUrl}/${id}`}
                        >
                          <i className='icon-show-password'></i> View
                        </Link>
                        {getCurrentStatus == 'In Moderation' ? (
                          <Link
                            class='primary-btn disable-button'
                            title='Entry under moderation: Editing disabled until review completion.'
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        ) : (
                          <Link
                            class='primary-btn'
                            to={GoogleAdOptions.AdGroups.editUrl + `/${id}`}
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        )}
                        <Link
                          className={`primary-btn ${
                            !isViewPage ? 'active' : ''
                          }`}
                          to={`${GoogleAdOptions.AdGroups.viewPageUrl}/${id}?platForm=${GoogleAdOptions.AdGroups.platform}&form=${GoogleAdOptions.AdGroups.reviewForm}`}
                        >
                          <i className='icon-history'></i> Review Log
                        </Link>
                      </div>
                    </div>
                    {getCurrentStatus == 'In Moderation' &&
                      getUserInfo?.roles == 'Publisher' &&
                      getApprovalFlag && (
                        <ApproveReject
                          platform={'GOOGLE'}
                          campaignId={campaignId}
                          getUserInfo={getUserInfo}
                          setThankYouApprovalPopupShowStatus={
                            setThankYouApprovalPopupShowStatus
                          }
                          getThankYouApprovalPopupShowStatus={
                            getThankYouApprovalPopupShowStatus
                          }
                          setDisplayLoader={setDisplayLoader}
                        />
                      )}
                  </div>
                </div>
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  <ErrorDisplay id={campaign_id} />

                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        {isViewPage ? (
                          <div className='card-primary'>
                            <DisplayCampaignStatus
                              moderationStatus={getCurrentStatus}
                              publishedAt={data?.modifiedOn}
                            />
                            <DataDetails
                              Label={'Ad Group name'}
                              LabelFor={adGroupName}
                            />

                            <DataDetails
                              Label={'ATD Group id'}
                              LabelFor={googleAdGroupsId}
                            />

                            <DataDetails
                              Label={'Google Ad Group id'}
                              LabelFor={adGroupGoogleRefId}
                            ></DataDetails>

                            {Array?.isArray(googleKeywords) &&
                              googleKeywords.length > 0 && (
                                <DataDetails
                                  Label={'Total Keywords'}
                                  LabelFor={googleKeywords?.length || '-'}
                                />
                              )}

                            {Array.isArray(audience) && audience.length > 0 && (
                              <DataDetails Label={'Audience'}>
                                {audience.map((audienceItem, index) => (
                                  <div key={index}>
                                    <p>{audienceItem?.name || '-'}</p>
                                  </div>
                                ))}
                              </DataDetails>
                            )}

                            {Array.isArray(topics) && topics.length > 0 && (
                              <DataDetails Label={'Topics'}>
                                {topics.map((item, index) => {
                                  const pathArray = item?.path
                                    ? JSON.parse(item.path)
                                    : [];
                                  const lastElement =
                                    pathArray[pathArray.length - 1] || '-';

                                  return (
                                    <div key={index}>
                                      <p>{lastElement}</p>
                                    </div>
                                  );
                                })}
                              </DataDetails>
                            )}

                            {Array.isArray(placements) &&
                              placements.length > 0 && (
                                <DataDetails Label={'Placements'}>
                                  {placements.map((placement, index) => (
                                    <div key={index}>
                                      <p>{placement.placementName}</p>
                                    </div>
                                  ))}
                                </DataDetails>
                              )}

                            {Array.isArray(googleAdGroupDemographics) &&
                              googleAdGroupDemographics.length > 0 && (
                                <DataDetails Label={'DemoGraphics'}>
                                  {googleAdGroupDemographics.map(
                                    (item, index) => (
                                      <div key={index}>
                                        <p>Gender: {item.gender.join(', ')}</p>
                                        <p>Age: {item.age.join(', ')}</p>
                                        <p>
                                          Parental Status:{' '}
                                          {item.parentalStatus.join(', ')}
                                        </p>
                                        <p>
                                          Household Income:{' '}
                                          {item.householdIncome.join(', ')}
                                        </p>
                                      </div>
                                    ),
                                  )}
                                </DataDetails>
                              )}

                            {Array.isArray(language) && language.length > 0 && (
                              <DataDetails Label={'Languages'}>
                                {language.map((language, index) => (
                                  <div key={index}>
                                    <p> {language.language}</p>
                                  </div>
                                ))}
                              </DataDetails>
                            )}

                            <DataDetails Label={'CSV File'} LabelFor={''} />
                            <p>
                              File Name {googleKeywords?.[0]?.fileName || '-'}
                            </p>
                            {googleKeywords?.[0]?.fileName && (
                              <a
                                color='transparent'
                                rel='noreferrer'
                                target='_blank'
                                // to={`${baseUrlStg}/api/GoogleListingDetails/DownLoadCSVV2?adGroupId=${id}`}
                                onClick={() =>
                                  handleFileDownload(
                                    id,
                                    googleKeywords?.[0]?.fileName,
                                  )
                                }
                                className='primary-btn footer-close-btn'
                              >
                                Download CSV
                              </a>
                            )}
                            {googleCampaignUrlData[0]?.suffix && (
                              <DataDetails Label={'URL Options'}>
                                <div>
                                  <>
                                    <p>Url Suffix</p>

                                    <p>
                                      <Link
                                        to={googleCampaignUrlData[0]?.suffix}
                                      >
                                        {' '}
                                        {googleCampaignUrlData[0]?.suffix ||
                                          '-'}
                                      </Link>
                                    </p>
                                  </>
                                </div>
                              </DataDetails>
                            )}
                            {
                              <>
                                {/* Download Listing groups file start */}
                                {getFileName && !getFileDisplayStatus && (
                                  <>
                                    <p>File Name - {getFileName}</p>
                                    <a
                                      color='transparent'
                                      rel='noreferrer'
                                      target='_blank'
                                      onClick={(e) =>
                                        handleDownloadProductListingFile(
                                          getFileName,
                                        )
                                      }
                                      className='primary-btn footer-close-btn'
                                    >
                                      Download Listing Groups
                                    </a>
                                  </>
                                )}
                                {/* Download Listing groups file end */}
                              </>
                            }
                          </div>
                        ) : (
                          <ReviewLog
                            id={id}
                            form={GoogleAdOptions.AdGroups.reviewForm}
                            platForm={GoogleAdOptions.AdGroups.platform}
                          ></ReviewLog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  >
                    History
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer
        closePath={GoogleAdOptions.Campaigns.url}
        prevPath={GotoPage}
        // nextBtnlabel='Next'
        // nextPath={GotoPage}
      ></Footer>
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          type={'Google'}
          approved={'approved'}
        />
      )}
      {displayLoader && <Loader />}
    </div>
  );
};

export default AdGroupsView;
