import React, { useEffect, useState } from 'react';
import './../../assets/css/style.css';
import logo_small from './../../assets/images/logo-small.svg';
import logo_cheil_atd from './../../assets/images/logo-cheil-atd.svg';
import { Link, useLocation } from 'react-router-dom';
import { DV360Options } from '../../pages/DV360/DV360Constant';
import { GoogleAdOptions } from '../../pages/googleAds/GoogleAdConstant';
import {
  metaListingUrl,
  logOutUrl,
  userManagement,
} from '../../utils/constants';
import { useSelector } from 'react-redux';

function Sidebar() {
  const [currentPage, setCurrentPage] = useState('');
  const location = useLocation();

  const LoginReducer = useSelector((state) => state?.loginReducer);
  const role = LoginReducer?.roleMapping;
  const user = LoginReducer?.userToken;
  const handleLogout = () => {
    window.location.replace(logOutUrl);
  };

  useEffect(() => {
    setCurrentPage(location?.pathname?.split('/')[1]); // Set Current page name
  }, []);

  return (
    <div>
      <nav className='sidebar close'>
        <header>
          <div className='image-text'>
            <div className='logo-cont'>
              <img src={logo_small} className='logo-small' />
              <img src={logo_cheil_atd} className='logo-big' />
            </div>
          </div>
          <div className='nav-control'>
            <div className='hamburger-toggle'>
              <span className='line'></span>
              <span className='line'></span>
              <span className='line'></span>
            </div>
          </div>
        </header>

        <div className='menu-bar'>
          <div className='menu'>
            <ul className='menu-links'>
              {true && (
                <li className='nav-link'>
                  <Link
                    to='/taxonomy-manager/media-plan-list'
                    className={
                      (currentPage == 'taxonomy-manager' ||
                        currentPage == '') &&
                      'nav-link menulink active'
                    }
                  >
                    <i className='icon-taxonomy-manager icon'></i>
                    <span className='nav-text'>Taxonomy Manager</span>
                  </Link>
                </li>
              )}
              {role?.meta && (
                <li className='nav-link'>
                  <Link to={metaListingUrl}>
                    <i className='icon-facebook-ads icon'></i>
                    <span className='nav-text'>Meta Campaign</span>
                  </Link>
                </li>
              )}
              {role?.google && (
                <li className='nav-link'>
                  <Link
                    to={GoogleAdOptions.Campaigns.url}
                    className={
                      currentPage == 'google-ad' && 'nav-link menulink active'
                    }
                  >
                    <i className='icon-google-ads icon'></i>
                    <span className='nav-text'>Google Ad Campaign</span>
                  </Link>
                </li>
              )}
              {role?.dv360 && (
                <li className='nav-link'>
                  <Link
                    to={DV360Options.Campaigns.url}
                    className={
                      currentPage == 'dv-360' && 'nav-link menulink active'
                    }
                  >
                    <i className='icon-ads icon'></i>
                    <span className='nav-text'>DV360 Campaign</span>
                  </Link>
                </li>
              )}
              {user?.roles?.toLowerCase() == 'admin' && (
                <li className='nav-link'>
                  <Link
                    to={userManagement.userList}
                    className={
                      Object.values(userManagement).some((url) =>
                        window.location.pathname.includes(url),
                      )
                        ? 'nav-link menulink active'
                        : ''
                    }
                  >
                    <i className='icon-user-management icon'></i>
                    <span className='nav-text'>User Management</span>
                  </Link>
                </li>
              )}
              {user?.roles?.toLowerCase() !== 'admin' && (
                <li className='nav-link'>
                  <Link
                    to='/media-library/listing'
                    className={
                      currentPage == 'media-library' &&
                      'nav-link menulink active'
                    }
                  >
                    <i className='icon-media icon'></i>
                    <span className='nav-text'>Media Library</span>
                  </Link>
                </li>
              )}
              <li className='nav-link'>
                <a href='javascript:void(0)' onClick={handleLogout}>
                  <i className='icon-logout icon'></i>
                  <span className='nav-text'>Log Out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
