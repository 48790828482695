import '../../assets/css/style.css';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from '../../components/common/Sidebar';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import Footer from '../../components/common/Footer';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  resetGoogleAdGroupState,
  setAdGroupCSVFile,
  setAdGroupName,
  setAudience,
  setBidCpc,
  setFinalMobileUrl,
  setFinalUrlSuffix,
  setGoogleProductId,
  setIsMobileUrlEnabled,
  setLanguage,
  setPlacements,
  setPrefilledValues,
  setTopics,
  setTrackingTemplate,
} from '../../state/googleAdGroup/googleAdGroupActions';
import { getApi, postApi, putApi } from '../../utils/Apis';
import {
  EnabledApproval,
  baseUrlStg,
  googleV1Url,
} from '../../utils/constants';
import {
  googleCampaignTypeList,
  googleObjectiveList,
} from '../../state/googleCreateCampaign/CreateCampaignReducer';
import Loader from '../../components/common/Loader';
import AudienceSegment from '../../components/google/AudienceSegment';
import OptimizeTarget from '../../components/google/OptimizeTarget';
import Demographics from '../../components/google/Demographics';
import Keywords from '../../components/google/Keywords';
import Topics from '../../components/google/Topics';
import Placements from '../../components/google/Placements';
import { GoogleAdOptions, GoogleStatus } from './GoogleAdConstant';
import AdUrlOption from '../../components/google/AdUrlOption';
import LanguagesUI from '../../components/google/LanguagesUI';
import BidCPC from '../../components/google/BidCPC';
import CommonModal from '../../components/common/commonModal';
import SideTreeMenuView from './Components/SideTreeMenuView';
import SelectApprovalPublisher from '../../components/common/SelectApprovalPublisher';
import ApprovalConfirmationPopup from '../../components/common/ApprovalConfirmationPopup';
import ThankYouApprovalPopup from '../../components/common/ThankYouApprovalPopup';
import { formatDateToIST } from '../../utils/validations';
import { ApproveReject } from '../../components/ApproveReject/ApproveReject';
import { downloadFile } from '../../utils/utils';
import ListingGroups from '../../components/google/ListingGroups';
// !important
//  /google-ad/ad-group/new?campaign_id=129&objective_id=1&campaign_type_id=1
// Display - /google-ad/ad-group/new?campaign_id=129&objective_id=2&campaign_type_id=3
// video - /google-ad/ad-group/new?campaign_id=129&objective_id=2&campaign_type_id=5
// test added comment

function AdGroup() {
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [thisIsFinalStep, setThisIsFinalStep] = useState(false);
  const [getTrackingTemplateLists, setTrackingTemplateLists] = useState([]);
  const [selectedCampaignType, setSelectedCampaignType] = useState(false);
  const [selectedObjectType, setSelectedObjectType] = useState(false);
  const hasNavigated = useRef(false);
  const [data, setData] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [campaignId, setCampaignId] = useState(false);
  const [adGroupId, setAdGroupID] = useState(false);
  const { id } = useParams();
  let getCurrentPageStatus = typeof id != 'undefined' ? true : false;
  const [getModerationStatus, setgetModerationStatus] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const localStorageKey = 'newGoogleAdGroupFlag';

  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const getSideTreeInfo = state?.sideTreeGoogleReducer?.SideTreeGoogle;
  const [productFeeds, setProductFeeds] = useState(0);

  useEffect(() => {
    console.log('getSideTreeInfo', getSideTreeInfo);
    setProductFeeds(getSideTreeInfo?.campaignSetting?.productFeeds);
  }, [getSideTreeInfo]);

  //CONSTANTS
  const saveAsDraft = 'Save As Draft';

  // query params
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  useEffect(() => {
    setCampaignId(query.get('campaign_id'));
  }, [query.get('campaign_id')]);

  const handleSetTrackingTemplateList = async (cID) => {
    // console.log('handleSetTrackingTemplateList cID', cID);
    if (typeof cID !== 'undefined' || cID !== null || cID !== '') {
      try {
        let response;

        response = await getApi(
          `${baseUrlStg}/api/Taxonomy/GetCidListByAddIdOrCampaignId?campaignId=${cID}`,
        );

        if (response && response.length > 0) {
          const data = response.map((item) => ({
            label: item.landingUrl,
            value: item.cidId,
          }));

          setTrackingTemplateLists(data);
        } else {
          console.error('Empty or invalid response received.');
          // Handle the case where the response is empty or invalid.
        }
      } catch (err) {
        console.error('Error while fetching or processing data:', err);
        // Handle errors, e.g., display an error message to the user or log the error.
      }
    }
  };
  const GoogleSideTree = useSelector(
    (state) => state?.sideTreeGoogleReducer?.SideTreeGoogle,
  );

  useEffect(() => {
    let cId = query.get('taxonomyCapmId');

    handleSetTrackingTemplateList(cId);
  }, [query.get('taxonomyCapmId')]);

  //states to manage hide and show of fields based on campaign type and objective type - starts
  const [showKeywordsUI, setShowKeywordsUI] = useState(false);
  const [targetingUI, setTargetingUI] = useState(false);
  const [audienceUI, setAudienceUI] = useState(false);
  const [optimizeTargetUI, setOptimizeTargetUI] = useState(false);
  const [demographicsUI, setDemographicsUI] = useState(false);
  const [topicsUI, setTopicsUI] = useState(false);
  const [placementUI, setPlacementUI] = useState(false);
  const [chooseProductUI, setChooseProductUI] = useState(false);
  const [languagesUI, setLanguagesUI] = useState(false);
  const [urlOptionUI, setUrlOptionsUI] = useState(false);
  const [bidCpcUI, setBidCpcUI] = useState(false);
  // for the dropdown for audience Segments
  const [audienceSegments, setAudienceSegments] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [placementOptions, setPlacementOptions] = useState([]);

  const [selectAllProduct, setSelectAllProduct] = useState(false);
  const [selectAllLanguage, setSelectAllLanguage] = useState(false);
  //states to manage hide and show of fields based on campaign type and objective type - ends
  const isProductNeeded = query.get('productFeedsStatus');
  const isCpcValueNeeded = query.get('cpcValue');
  //campaign type and objective type based condition check to hide and show fields
  const [
    getApprovalPublisherModelShowStatus,
    setApprovalPublisherModelShowStatus,
  ] = useState(false);
  const [
    getApprovalCoinfirmationPopupShowStatus,
    setApprovalCoinfirmationPopupShowStatus,
  ] = useState(false);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getPublisherInfo, setPublisherInfo] = useState([]);
  const [getPublisherError, setPublisherError] = useState('');
  const [isPublisher, setIsPublisher] = useState(false);
  const { handleAPICall } = ApproveReject();
  const [getSelectedListingGroupType, setSelectedListingGroupType] =
    useState(false);
  const [getCSVFileInfo, setCSVFileInfo] = useState({
    CSVFile: '',
    CSVFileName: '',
  });
  const [showListingGroupsStatus, setShowListingGroupsStatus] = useState(true);
  let getCurrentCampaignId = query.get('campaign_type_id');

  useEffect(() => {
    setSelectedObjectType(query.get('objective_id'));
    setSelectedCampaignType(query.get('campaign_type_id'));
  }, [query]);

  const [isKeywordIssue, setKeywordIssue] = useState(false);
  const [keywordIssueText, setKeywordIssueText] = useState('');

  useEffect(() => {
    switch (selectedObjectType) {
      case `${googleObjectiveList.Sales.objective_id}`:
        // Sales

        switch (selectedCampaignType) {
          case `${googleCampaignTypeList.Search.campaign_type_id}`:
            // Sales - Search
            setShowKeywordsUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList['Performance Max'].campaign_type_id}`:
            //Sales - Performance Max
            setChooseProductUI(true);
            setShowListingGroupsStatus(true);
            break;
          case `${googleCampaignTypeList['Demand Gen'].campaign_type_id}`:
            // Sales - Demand Gen
            setChooseProductUI(true);
            setLanguagesUI(true);
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setUrlOptionsUI(true);
            setShowListingGroupsStatus(true);
            break;
          case `${googleCampaignTypeList.Shopping.campaign_type_id}`:
            // Sales - Shopping
            // standard shopping
            // !this will come from url - check isCpcValueNeeded
            // setBidCpcUI(true);
            setShowListingGroupsStatus(true);
            break;
          default:
        }
        break;

      case `${googleObjectiveList.Leads.objective_id}`:
        // Leads
        switch (selectedCampaignType) {
          case `${googleCampaignTypeList.Search.campaign_type_id}`:
            // Leads->Search
            setShowKeywordsUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList.Video.campaign_type_id}`:
            // Leads->Video
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList.Display.campaign_type_id}`:
            // Leads->Display
            setShowKeywordsUI(true);
            setTargetingUI(true);
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setDemographicsUI(true);
            setTopicsUI(true);
            setPlacementUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList['Demand Gen'].campaign_type_id}`:
            // Leads->Demand Gen
            setChooseProductUI(true);
            setLanguagesUI(true);
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setUrlOptionsUI(true);
            setShowListingGroupsStatus(true);
            break;
          default:
        }
        break;

      case `${googleObjectiveList['Website Traffic'].objective_id}`:
        // Website Traffic
        switch (selectedCampaignType) {
          case `${googleCampaignTypeList.Search.campaign_type_id}`:
            //  Website Traffic ->Search
            setShowKeywordsUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList.Video.campaign_type_id}`:
            // Website Traffic ->Video
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList.Display.campaign_type_id}`:
            // Website Traffic ->Display
            setShowKeywordsUI(true);
            setTargetingUI(true);
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setDemographicsUI(true);
            setTopicsUI(true);
            setPlacementUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList['Demand Gen'].campaign_type_id}`:
            // Website Traffic ->Demand Gen
            setChooseProductUI(true);
            setLanguagesUI(true);
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setUrlOptionsUI(true);
            setShowListingGroupsStatus(true);
            break;
          default:
        }
        break;

      case `${googleObjectiveList['Brand awareness and reach'].objective_id}`:
        // Brand awareness and reach
        switch (selectedCampaignType) {
          case `${googleCampaignTypeList.Search.campaign_type_id}`:
            // Brand awareness and reach ->Search
            setShowKeywordsUI(true);
            setShowListingGroupsStatus(false);
            break;
          case `${googleCampaignTypeList.Display.campaign_type_id}`:
            // Brand awareness and reach ->Display
            setShowKeywordsUI(true);
            setTargetingUI(true);
            setAudienceUI(true);
            setOptimizeTargetUI(true);
            setDemographicsUI(true);
            setTopicsUI(true);
            setPlacementUI(true);
            setShowListingGroupsStatus(false);
            break;
          default:
        }
        break;
      case `${googleObjectiveList['Local store visits and promotion'].objective_id}`:
        // Local store visits and promotion
        switch (selectedCampaignType) {
          case `${googleCampaignTypeList['Performance Max'].campaign_type_id}`:
            // Local store visits and promotion ->Performance Max
            // !choose standard shopping then show submit button
            // !sales -> shopping -> standard shopping
            // !sales -> shopping -> performance max
            // setThisIsFinalStep(true);
            setChooseProductUI(true);
            setShowListingGroupsStatus(true);
            break;
        }

      case `${googleObjectiveList['App promotion'].objective_id}`:
        // App promotion
        switch (selectedCampaignType) {
          case `${googleCampaignTypeList.App.campaign_type_id}`:
            // App promotion  -> App
            // !removed form the front end
            // setChooseProductUI(true);
            setShowListingGroupsStatus(false);
        }
        break;

      default:
      // Handle other objective types if needed
    }
  }, [selectedCampaignType, selectedObjectType, query]);

  useEffect(() => {
    if (isProductNeeded === 'true') {
      setChooseProductUI(true);
    }
    if (isCpcValueNeeded === 'true') {
      setBidCpcUI(true);
    }
  }, [isProductNeeded, chooseProductUI, isCpcValueNeeded, bidCpcUI]);

  const handleAdGroupName = (e) => {
    dispatch(setAdGroupName(e.target.value));
  };
  const handleCPCBidValue = (e) => {
    dispatch(setBidCpc(e.target.value));
  };

  const adGroupData = state.googleAdGroup;
  const audienceValue = adGroupData.Audience;
  const DemographicAge = adGroupData.DemographicAge;
  const DemographicGender = adGroupData.DemographicGender;
  const DemographicParentalStatus = adGroupData.DemographicParentalStatus;
  const DemographicHouseholdIncome = adGroupData.DemographicHouseholdIncome;
  const topicsValue = adGroupData.Topics;
  const placementValue = adGroupData.Placements;
  const googleProductId = adGroupData.GoogleProductId;
  const googleLanguages = adGroupData.Language;
  const CpcBid = adGroupData.CpcBid;
  const trackingTemplateVal = adGroupData?.['URLOptions.TrackingTemplates'];
  const finalUrlSuffix = adGroupData?.['URLOptions.Suffix'];
  const handleUploadFile = (e) => {
    dispatch(setAdGroupCSVFile(e.target.files[0]));
  };
  const handleRemoveFile = () => {
    dispatch(setAdGroupCSVFile({}));
    setKeywordIssueText('');
  };

  const handleDownloadFile = (fileName) => {
    const endPoint = `${baseUrlStg}/api/GoogleListingDetails/DownLoadCSVV2?adGroupId=${id}`;
    downloadFile(endPoint, fileName);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setFormErrors(validate());
    setTimeout(async () => {
      if (Object.keys(validate()).length == 0) {
        setLoader(true);
        let payload = new FormData();

        payload.append(
          'CampaignId',
          query.get('campaign_id') || data?.campaignId,
        );
        payload.append('AdGroupName', adGroupData.AdGroupName),
          payload.append('CSVFile', adGroupData.CSVFile);
        payload.append('CampaignLevelLocationLanguage', 'false');
        if (DemographicHouseholdIncome) {
          DemographicHouseholdIncome.forEach((item) => {
            payload.append('DemographicHouseholdIncome', item.value);
          });
        }
        if (DemographicParentalStatus) {
          DemographicParentalStatus.forEach((item) => {
            payload.append('DemographicParentalStatus', item.value);
          });
        }
        if (DemographicGender) {
          DemographicGender.forEach((item) => {
            payload.append('DemographicGender', item.value);
          });
        }
        if (DemographicAge) {
          DemographicAge.forEach((item) => {
            payload.append('DemographicAge', item.value);
          });
        }
        if (topicsValue) {
          topicsValue.forEach((item) => {
            payload.append('Topics', item.value);
          });
        }
        if (audienceValue) {
          audienceValue.forEach((item) => {
            payload.append('Audience', item.value);
          });
        }
        if (placementValue) {
          placementValue.forEach((item) => {
            payload.append('Placements', item.value);
          });
        }
        if (selectAllProduct) {
          payload.append('GoogleProductId', 'All');
        } else {
          if (googleProductId) {
            googleProductId.forEach((item) => {
              payload.append('GoogleProductId', item.value);
            });
          }
        }
        if (selectAllLanguage) {
          payload.append('Language', 'All');
        } else {
          if (googleLanguages) {
            googleLanguages.forEach((item) => {
              payload.append('Language', item.value);
            });
          }
        }

        payload.append('ShowAllProducts', selectAllProduct);
        payload.append('OptimizedTargeting', !!audienceValue);
        payload.append('UrlOptionValue', '0');
        payload.append('URLOptions.Suffix', adGroupData?.['URLOptions.Suffix']);
        payload.append(
          'URLOptions.CusParamInfo',
          adGroupData?.['URLOptions.CusParamInfo'],
        );
        payload.append(
          'URLOptions.TrackingTemplates',
          adGroupData?.['URLOptions.TrackingTemplates'].label,
        );
        payload.append('URLOptions.CusParam', '');
        payload.append('Locations', 'India');
        payload.append('CpcBid', CpcBid);
        payload.append('CreatedBy', getUserInfo?.Userid);
        payload.append('createdOn', formatDateToIST(new Date()));
        payload.append('updatedOn', formatDateToIST(new Date()));
        payload.append(
          'atdStatus',
          EnabledApproval ? 'draft' : 'In Moderation',
        );
        payload.append('SaveAsDraft', e.target.innerText === saveAsDraft);
        if (e.target.innerText === saveAsDraft) {
          setIsDraft(true);
        }

        //Condition Would call in the shopping type campaign.
        payload.append(
          'isAllProductsEnabled',
          getSelectedListingGroupType || false,
        );

        //Check the selected type and based on that set the state.
        if (
          getSelectedListingGroupType == 'true' ||
          getSelectedListingGroupType == true
        ) {
          setCSVFileInfo({
            ...getCSVFileInfo,
            CSVFile: '',
            CSVFileName: '',
          });
        }

        if (!id && !isKeywordIssue) {
          const response = await postApi(
            `${googleV1Url}/createAdGroupV2`,
            payload,
            {
              accept: '*/',
              'Content-Type': 'multipart/form-data',
            },
          );
          if (response?.data?.status == 200) {
            setAdGroupID(response.data.adGroupID);
            if (
              response?.data?.keyword &&
              response.data.keyword.trim() !== ''
            ) {
              console.log('keywords on create issue', response?.data?.keyword);
              setKeywordIssueText(response?.data?.keyword);
              setKeywordIssue(true);
              setLoader(false);
              return;
            }

            //Upload file API call handle start
            if (
              (showListingGroupsStatus &&
                getSelectedListingGroupType == 'false') ||
              (showListingGroupsStatus && getSelectedListingGroupType == false)
            ) {
              handleUploadFileAPICall(
                getCSVFileInfo?.CSVFile,
                response?.data?.adGroupID,
              );
            }
            //Upload file API call handle end

            let adGroupId = response?.data?.adGroupID;
            setLoader(false);
            if (thisIsFinalStep) {
              setShowModal(true);
            } else {
              navigate(
                `${GoogleAdOptions.Ads.newGoogleAd}?objective_id=${query.get(
                  'objective_id',
                )}&campaign_type_id=${query.get(
                  'campaign_type_id',
                )}&ad_groupId=${adGroupId}&taxonomyCapmId=${query.get(
                  'taxonomyCapmId',
                )}&campaign_id=${query.get('campaign_id')}${
                  isProductNeeded === 'true' ? '&productFeedsStatus=true' : ''
                }&campaign_id=${query.get('campaign_id')}${
                  isCpcValueNeeded === 'true' ? '&cpcValue=true' : ''
                }&campaign_subtype=${query.get('campaign_subtype')} `,
              );
              dispatch(resetGoogleAdGroupState());
            }
          } else {
            setLoader(false);
          }
        } else {
          payload.append('GoogleAdGroupsId', id || adGroupId);
          const response = await putApi(
            `${googleV1Url}/updateAdGroupV2`,
            '',
            payload,
            {
              accept: '*/',
              'Content-Type': 'multipart/form-data',
            },
          );

          if (response?.data?.status == 200) {
            if (
              response?.data?.keyword &&
              response.data.keyword.trim() !== ''
            ) {
              console.log('keywords on update issue', response?.data?.keyword);
              setKeywordIssueText(response?.data?.keyword);
              setKeywordIssue(true);
              setLoader(false);
              return;
            }
            //Upload file API call handle start
            if (
              (showListingGroupsStatus &&
                getSelectedListingGroupType == 'false') ||
              (showListingGroupsStatus && getSelectedListingGroupType == false)
            ) {
              handleUploadFileAPICall(
                getCSVFileInfo?.CSVFile,
                response?.data?.adGroupID,
              );
            }
            //Upload file API call handle end
            if (EnabledApproval) {
              setApprovalPublisherModelShowStatus(true);
            } else {
              setAdGroupID(response.data.adGroupID);
              let adGroupId = response.data.adGroupID;
              setLoader(false);
              if (thisIsFinalStep) {
                setShowModal(true);
              } else if (e.target.innerText === saveAsDraft) {
                console.log('Drafted');
              } else {
                navigate(
                  `${GoogleAdOptions.Ads.newGoogleAd}?objective_id=${query.get(
                    'objective_id',
                  )}&campaign_type_id=${query.get(
                    'campaign_type_id',
                  )}&ad_groupId=${adGroupId}&taxonomyCapmId=${query.get(
                    'taxonomyCapmId',
                  )}&campaign_id=${query.get('campaign_id')}${
                    isProductNeeded === 'true' ? '&productFeedsStatus=true' : ''
                  }&campaign_id=${query.get('campaign_id')}${
                    isCpcValueNeeded === 'true' ? '&cpcValue=true' : ''
                  }&campaign_subtype=${query.get(
                    'campaign_subtype',
                  )}&productFeedsStatus=${
                    getSideTreeInfo?.campaignSetting?.productFeeds == 1
                      ? true
                      : false
                  } `,
                );
                dispatch(resetGoogleAdGroupState());
              }
            }
          } else {
            setLoader(false);
          }
        }
      }
    }, 100);
  };

  useEffect(() => {
    const fetchDataAndNavigate = async () => {
      setThisIsFinalStep(true);
      try {
        const response = await getApi(
          `${googleV1Url}/GetGoogleAdGroup?id=${id}`,
        );

        if (response) {
          const receivedData = response?.data;
          setData(receivedData);
          dispatch(setPrefilledValues(receivedData));
          setSelectedCampaignType(receivedData?.campaignTypeId);
          setSelectedObjectType(receivedData?.objectiveId);
          setgetModerationStatus(receivedData?.atdStatus);
          //Set CSV file name
          setCSVFileInfo({
            ...getCSVFileInfo,
            CSVFileName: receivedData?.productFileName || '',
          });

          //Set Listing group type
          setSelectedListingGroupType(receivedData?.isAllProductsEnabled);

          const campaign_id = receivedData?.campaignId;
          const objective_id = receivedData?.objectiveTypeId;
          const campaign_type_id = receivedData?.campaignTypeId;
          const taxonomyCapmId = receivedData?.taxonomyCampaignId;
          const cpcBid = receivedData?.cpcBid;
          const getProductFeedStatus = `&productFeedsStatus=${
            getSideTreeInfo?.campaignSetting?.productFeeds == 1 ? true : false
          }`;

          const url = `${
            GoogleAdOptions.AdGroups.editUrl
          }/${id}?campaign_id=${campaign_id}&objective_id=${objective_id}&campaign_type_id=${campaign_type_id}&taxonomyCapmId=${taxonomyCapmId}${getProductFeedStatus}${
            cpcBid !== null ? '&cpcValue=true' : ''
          }`;
          navigate(url);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (id) {
      fetchDataAndNavigate();
      localStorage.setItem(localStorageKey, 'false');
    }
  }, [id]);

  const validate = () => {
    let error = {};
    if (!adGroupData?.AdGroupName?.trim()) {
      error.adGroupName = 'Ad group name is required.';
    }
    return error;
  };

  const handleAudience = (event) => {
    dispatch(setAudience(event));
  };

  const handleTopics = (e) => {
    dispatch(setTopics(e));
  };

  const handlePlacement = (e) => {
    dispatch(setPlacements(e));
  };

  const handleProducts = (e) => {
    dispatch(setGoogleProductId(e));
  };

  const handleLanguages = (e) => {
    dispatch(setLanguage(e));
  };

  const handleSetTrackingTemplate = (e) => {
    dispatch(setTrackingTemplate(e));
  };
  const handleSetFinalUrlValue = (e) => {
    dispatch(setFinalUrlSuffix(e?.target?.value));
  };

  const handleSetIsMobileValue = (e) => {
    dispatch(setIsMobileUrlEnabled(e?.target?.checked));
  };

  const handleSetFinalMobileUrlValue = (e) => {
    dispatch(setFinalMobileUrl(e?.target?.value));
  };

  // !getMasters

  const fetchMaster = async () => {
    // If data is not cached, make the API call
    try {
      const response = await getApi(`${googleV1Url}/getMasters`);
      const convertedData = response?.data?.audienceSegments?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setAudienceSegments(convertedData);
      const convertedLangData = response?.data?.languages?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setLanguageOptions(convertedLangData);
      const convertedTopicData = response?.data?.topics?.map((item) => {
        // Extracting the last element from the "path" array
        const lastPathElement = JSON.parse(item?.path)?.pop();

        // Appending the last path element to the "name" property
        const label = `${lastPathElement}`;

        return {
          label,
          value: item?.id,
        };
      });
      setTopicOptions(convertedTopicData);
      const convertedPlacementData = response?.data?.placements?.map(
        (item) => ({
          label: item?.name,
          value: item?.id,
        }),
      );
      setPlacementOptions(convertedPlacementData);
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchMaster();
  }, []);

  useEffect(() => {
    // console.log(
    //   'adGroupData thisIsLastStep',
    //   query.has('thisIsLastStep', true),
    // );
    if (currentUrl.includes(GoogleAdOptions.AdGroups.newGoogleAdGroup)) {
      dispatch(resetGoogleAdGroupState());
      localStorage.setItem(localStorageKey, 'true');
    }
    if (query.has('thisIsLastStep', true)) {
      setThisIsFinalStep(true);
      setCSVFileInfo({
        CSVFile: '',
        CSVFileName: '',
      });
    }
  }, [currentUrl]);
  // Approval functions start
  const handleApprovalPublisher = () => {
    if (getPublisherInfo?.length == 0) {
      setPublisherError('Please Choose atleast one option');
    } else {
      setApprovalCoinfirmationPopupShowStatus(true);
      setApprovalPublisherModelShowStatus(false);
      setPublisherError('');
    }
  };

  const handleApprovalPublisherRequest = async () => {
    try {
      let payload = {
        platform: 'GOOGLE',
        userId: getUserInfo?.Userid,
        publisherId: getPublisherInfo?.value,
        campaignId: query.get('campaign_id'),
        link: `${window.location.origin}/google-ad/campaignsView/${query.get(
          'campaign_id',
        )}`,
      };
      setLoader(true);
      setApprovalCoinfirmationPopupShowStatus(false);
      // console.log('API', payload);
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/SendToPublisherApproval`,
        payload,
      );

      if (response?.status) {
        if (isPublisher) {
          setLoader(true);
          setApprovalCoinfirmationPopupShowStatus(false);
          let campaignId = query.get('campaign_id');
          handleAPICall('GOOGLE', campaignId, getPublisherInfo?.value).then(
            (res) => {
              if (res == 200) {
                setThankYouApprovalPopupShowStatus(true);
              }
            },
          );
        } else {
          setThankYouApprovalPopupShowStatus(true);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSetPublisherId = (e) => {
    if (e == null) {
      setPublisherInfo([]);
      setIsPublisher(false);
    } else {
      setPublisherInfo(e);
      e?.value == getUserInfo?.Userid && setIsPublisher(true);
    }
  };

  const handleCloseModel = () => {
    setApprovalPublisherModelShowStatus(false);
    setApprovalCoinfirmationPopupShowStatus(false);
    setLoader(false);
    setPublisherInfo([]);
    setIsPublisher(false);
  };

  const handleRedirection = () => {
    navigate(GoogleAdOptions.AdGroups.url);
  };
  // Approval functions end
  // Upload products functions start
  const handleSetValue = (e) => {
    setSelectedListingGroupType(e?.target?.value);
  };

  const handleProductListingUploadFile = (e) => {
    setCSVFileInfo({
      ...getCSVFileInfo,
      CSVFile: e.target.files[0],
      CSVFileName: e.target.files[0]?.name,
    });
  };
  const handleUploadFileAPICall = async (file, adGroupID) => {
    let fileUploadPayload = {
      GoogleAdGroupId: adGroupID,
      ProductFile: file,
      CreatedBy: getUserInfo?.Userid,
      ResourceId: '',
      CreatedOn: '',
    };

    try {
      let response;
      if (getCurrentPageStatus) {
        response = await putApi(
          `${baseUrlStg}/api/GoogleProduct/UpdategoogleProduct`,
          '',
          fileUploadPayload,
          {
            accept: '*/',
            'Content-Type': 'multipart/form-data',
          },
        );
      } else {
        response = await postApi(
          `${baseUrlStg}/api/GoogleProduct/UploadGoogleProductList`,
          fileUploadPayload,
          {
            accept: '*/',
            'Content-Type': 'multipart/form-data',
          },
        );
      }
      if (response?.data?.status == 200) {
        return true;
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDownloadProductListingFile = (fileName = 'sample') => {
    let endPoint = `${baseUrlStg}/api/GoogleProduct/GetSampleProductCSVFile`;
    downloadFile(endPoint, 'sample');
  };

  const handleDownloadProductListingSampleFile = (fileName) => {
    let endPoint = `${baseUrlStg}/api/GoogleProduct/GetProductListByAdGroupId?adGroupId=${id}`;
    downloadFile(endPoint, fileName);
  };

  // Upload products functions end
  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box padding-bottom-extra media-section-left'>
            {/* header code starts */}
            {/* <Header /> */}

            <header>
              <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
                <div className='top-right-bar'>
                  <div className='btn-box'>
                    {/* {id && ( */}
                    <button
                      type='button'
                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                      onClick={handleNext}
                    >
                      {saveAsDraft}
                    </button>
                    {/* )} */}
                    <button
                      type='button'
                      className='primary-btn close-btn'
                      onClick={() => {
                        navigate('/google-ad/campaign-listing');
                      }}
                    >
                      <i className='icon-close'></i>
                    </button>
                  </div>
                </div>
              </div>
            </header>
            {/* header code ends */}
            <section className='side-menu-form d-flex'>
              {/* side tree menu code  starts*/}

              <SideTreeMenuView
                campaignId={campaignId}
                IdAndType={{
                  id: id,
                  type: GoogleAdOptions.AdGroups.name,
                }}
              />

              {/* side tree menu code ends */}
              <article className='side-right-form-wrap side-right-pad view-edit-tab-remove'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        <Card>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Ad group</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      name='Ad group'
                                      value={adGroupData?.AdGroupName}
                                      id='text'
                                      placeholder='Enter the name'
                                      onChange={handleAdGroupName}
                                    />
                                  </div>
                                  <span className='error' id='emailError'>
                                    {formErrors?.adGroupName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {bidCpcUI && (
                          <BidCPC
                            value={CpcBid}
                            onChange={handleCPCBidValue}
                            formErrors={formErrors}
                          ></BidCPC>
                        )}
                        {languagesUI && (
                          <>
                            <LanguagesUI
                              googleLanguages={googleLanguages}
                              setSelectAllLanguage={setSelectAllLanguage}
                              selectAllLanguage={selectAllLanguage}
                              handleLanguages={handleLanguages}
                              options={languageOptions}
                            />
                          </>
                        )}

                        {targetingUI && (
                          <>
                            <CardHeader>
                              {' '}
                              <h4>Targeting</h4>{' '}
                            </CardHeader>

                            <CardHeader>
                              <h5>People</h5>
                            </CardHeader>
                          </>
                        )}
                        {audienceUI && (
                          <>
                            <AudienceSegment
                              options={audienceSegments}
                              value={audienceValue}
                              onChange={handleAudience}
                              formErrors={formErrors}
                            />
                          </>
                        )}

                        {optimizeTargetUI && (
                          <OptimizeTarget value={audienceValue} />
                        )}
                        {urlOptionUI && (
                          <Card>
                            <AdUrlOption
                              trackingTemplateOptions={getTrackingTemplateLists}
                              handleSetValue={handleSetTrackingTemplate}
                              handleSetFinalUrl={handleSetFinalUrlValue}
                              handleSetIsMobile={handleSetIsMobileValue}
                              handleSetFinalMobileUrl={
                                handleSetFinalMobileUrlValue
                              }
                              handleSetIsMobileStatus={
                                adGroupData.IsMobileUrlEnabled
                              }
                              trackingTemplateVal={trackingTemplateVal}
                              finalUrlSuffix={finalUrlSuffix}
                            />
                          </Card>
                        )}
                        {demographicsUI && (
                          <>
                            <Demographics
                              formErrors={formErrors}
                              DemographicAge={DemographicAge}
                              DemographicGender={DemographicGender}
                              DemographicHouseholdIncome={
                                DemographicHouseholdIncome
                              }
                              DemographicParentalStatus={
                                DemographicParentalStatus
                              }
                            />
                          </>
                        )}

                        {/* Keywords */}

                        {targetingUI && (
                          <div className='py-3'>
                            <CardHeader>
                              <h5>Content</h5>
                            </CardHeader>
                          </div>
                        )}

                        {showKeywordsUI && (
                          <Keywords
                            adGroupId={adGroupId}
                            handleRemoveFile={handleRemoveFile}
                            handleUploadFile={handleUploadFile}
                            adGroupData={adGroupData}
                            handleDownloadFile={handleDownloadFile}
                            keywordIssueText={keywordIssueText}
                          />
                        )}
                        {topicsUI && (
                          <Topics
                            onChange={handleTopics}
                            value={topicsValue}
                            formErrors={formErrors}
                            options={topicOptions}
                          />
                        )}
                        {placementUI && (
                          <Placements
                            onChange={handlePlacement}
                            value={placementValue}
                            formErrors={formErrors}
                            options={placementOptions}
                          />
                        )}
                        {productFeeds == 1 && (
                          <ListingGroups
                            handleSetValue={handleSetValue}
                            getSelectedListingGroupType={
                              getSelectedListingGroupType
                            }
                            handleUploadFile={handleProductListingUploadFile}
                            getCSVFileInfo={getCSVFileInfo}
                            handleDownloadFile={
                              handleDownloadProductListingFile
                            }
                            handleDownloadProductListingSampleFile={
                              handleDownloadProductListingSampleFile
                            }
                            getCurrentPageStatus={getCurrentPageStatus}
                          />
                        )}
                      </article>
                    </div>
                  </div>

                  {/* view starts */}
                  <div
                    className='tab-pane fade'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        <div className='card-primary'>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Campaign name</h5>
                              <p>New Awareness Campaign</p>
                              <p>ID: 23857805967620728</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Special ad categories</h5>
                              <p>No categories declared</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Buying type</h5>
                              <p>Awareness</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Ad sets</h5>
                              <p className='add-data'>Please add: Ad sets</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Format</h5>
                              <p>Image and Video</p>
                            </div>
                            <div className='card-rt-pane d-flex me-auto align-items-center'>
                              <figure className='prev-icon'>
                                <img src={atdplaceholder} />
                              </figure>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* view ends */}
                </div>
              </article>
            </section>
          </div>

          <Footer
            prevPath={GoogleAdOptions.Campaigns.newGoogleCampaign}
            nextBtnlabel={thisIsFinalStep ? 'Save' : 'Next'}
            handleSubmit={handleNext}
            getAtdStatus={getModerationStatus}
          >
            {' '}
          </Footer>
          <CommonModal
            show={showModal}
            handleClose={() => {
              setShowModal(false);
              navigate(`${GoogleAdOptions.AdGroups.editUrl}/${adGroupId}`);
            }}
            handleRedirection={() => navigate(GoogleAdOptions.AdGroups.url)}
            copy={'Your google ads campaign has been successfully updated.'}
            isUpdatedStatus={true}
          />
          {getApprovalPublisherModelShowStatus && (
            <SelectApprovalPublisher
              type={'Google'}
              handleApprovalPublisher={handleApprovalPublisher}
              handleSetPublisherId={handleSetPublisherId}
              getPublisherInfo={getPublisherInfo}
              getPublisherError={getPublisherError}
              handleCloseModel={handleCloseModel}
            />
          )}
          {getApprovalCoinfirmationPopupShowStatus && (
            <ApprovalConfirmationPopup
              type={'Google'}
              handleApprovalPublisherRequest={handleApprovalPublisherRequest}
              handleCloseModel={handleCloseModel}
              isPublisher={isPublisher}
            />
          )}
          {getThankYouApprovalPopupShowStatus && (
            <ThankYouApprovalPopup
              handleRedirect={handleRedirection}
              type={'Google'}
              approved={isPublisher ? 'approved' : ''}
            />
          )}
          {/* <CommonModal
            show={isDraft}
            handleClose={() => {
              setIsDraft(false);
            }}
            handleRedirection={() => navigate(GoogleAdOptions.AdGroups.url)}
            copy={'Your google ads successfully saved.'}
            isUpdatedStatus={true}
          /> */}
          {loader && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default AdGroup;
