import '../../assets/css/style.css';
import CreateCampaign from './CreateCampaign';

function DV360() {
  return (
    <>
      <CreateCampaign></CreateCampaign>
    </>
  );
}

export default DV360;
