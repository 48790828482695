import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { postApi } from '../../../../utils/Apis';
import { baseUrlStg } from '../../../../utils/constants';
import Loader from '../../../../components/common/Loader';

const DeleteModal = ({
  show,
  handleClose,
  deleteId,
  doneWithDelete,
  deleteUrl,
  from,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    try {
      setLoading(true);
      handleClose();
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrlStg}/${deleteUrl}?modified_by=4`,
        payload: deleteId,
      };

      const response = await postApi(config.url, config.payload).catch(
        (error) => {
          console.error('Failed to delete :', error);
          throw error;
        },
      );

      if (response && response?.data.status === 200) {
        setTimeout(() => {
          setLoading(false);
          handleClose();
          doneWithDelete();
        }, 1000);
      } else {
        setLoading(false);
        console.error('Failed to delete ');
      }
    } catch (error) {
      console.error('Failed to delete :', error);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class='modal-img-cont'>
            <figure class='modal-icon modal-remove'>
              <i class='icon-close'></i>
            </figure>
          </div>
          <div class='modal-text'>
            <h3>Are you sure?</h3>
            <p>You'll lose all the details of this {from} on DV360!</p>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <div className='modal-footer'>
            <button
              type='button'
              class='primary-btn cancel-btn'
              data-bs-dismiss='modal'
              id='btncancel'
              onClick={handleClose}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type='button'
              class='primary-btn primary-remove'
              data-bs-dismiss='modal'
              id='btndelete'
              onClick={handleDelete}
              disabled={loading}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default DeleteModal;
