export const DV_Optimization_Type = 'DV_Optimization_Type';
export const DV_Line_Item_Name = 'DV_Line_Item_Name';
export const DV_Quality = 'DV_Quality';
export const DV_Public_Inventory = 'DV_Public_Inventory';
export const DV_Deals_And_Inventory_Packages =
  'DV_Deals_And_Inventory_Packages';
export const DV_Groups = 'DV_Groups';
export const DV_Inventory_Source = 'DV_Inventory_Source';
export const DV_Targetting = 'DV_Targetting';
export const DV_Conversion_Counting = 'DV_Conversion_Counting';
export const DV_Selected_Line_Item = 'DV_Selected_Line_Item';
export const DV_Flight_Details = 'DV_Flight_Details';
export const DV_Selected_Start_Date = 'DV_Selected_Start_Date';
export const DV_Selected_End_Date = 'DV_Selected_End_Date';
export const DV_Selected_Frequencey_Type = 'DV_Selected_Frequencey_Type';
export const DV_Selected_Frequencey_Value = 'DV_Selected_Frequencey_Value';
export const DV_Selected_Exposures_Type = 'DV_Selected_Exposures_Type';
export const DV_Selected_Exposures_Value = 'DV_Selected_Exposures_Value';
export const DV_Optimized_Target = 'DV_Optimized_Target';
export const DV_Budget_Spacing_Type = 'DV_Budget_Spacing_Type';
export const DV_Budget_Spacing_Amount = 'DV_Budget_Spacing_Amount';
export const DV_Budget_Spacing_First_Type = 'DV_Budget_Spacing_First_Type';
export const DV_Budget_Spacing_Second_Type = 'DV_Budget_Spacing_Second_Type';
export const DV_Budget_Spacing_Daily_Price = 'DV_Budget_Spacing_Daily_Price';
export const DV_Automated_Bidding_Type = 'DV_Automated_Bidding_Type';
export const DV_Budget_Spacing_Second_Option_Type =
  'DV_Budget_Spacing_Second_Option_Type';
export const DV_Automated_Bidding_Option_One_Value =
  'DV_Automated_Bidding_Option_One_Value';
export const DV_Automated_Bidding_Option_Two_Value =
  'DV_Automated_Bidding_Option_Two_Value';
export const DV_Automated_Bidding_Exceed_Avg =
  'DV_Automated_Bidding_Exceed_Avg';
export const DV_Automated_Bidding_Exceed_Amount =
  'DV_Automated_Bidding_Exceed_Amount';
export const DV_Automated_Bidding_Prioritize_Deal =
  'DV_Automated_Bidding_Prioritize_Deal';
export const DV_Fixed_Bid_Val = 'DV_Fixed_Bid_Val';
export const DV_Budget_Type = 'DV_Budget_Type';
export const DV_Creative_Optimization = 'DV_Creative_Optimization';
export const DV_Automate_Bidding_Prioritize_Target_Cpm =
  'DV_Automate_Bidding_Prioritize_Target_Cpm';
export const DV_Prefilled_Values = 'DV_Prefilled_Values';
export const DV_Reset_Values = 'DV_Reset_Values';
