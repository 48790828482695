import React, { useState } from 'react';
import Sidebar from '../../components/common/Sidebar';
import ReactSelect from '../../components/common/ReactSelect';
import moment from 'moment';
import axios from 'axios';
import { useEffect } from 'react';
import { getApi, postApi } from '../../utils/Apis';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTaxonomyCampaignName,
  setTaxonomyCampaignProductName,
  setTaxonomyCampaignAdvertiser,
  setTaxonomyCampaignSubcategory,
  setTaxonomyCampaignJobOrRoNumber,
  setTaxonomyCampaignGender,
  setTaxonomyCampaignAge,
  setTaxonomyCampaignPhase,
  setTaxonomyCampaignChannel,
  setTaxonomyCampaignDspPublisher,
  setTaxonomyCampaignType,
  setTaxonomyCampaignBuyType,
  setTaxonomyCampaignKPI,
  setTaxonomyCampaignObjective,
  setTaxonomyCampaignAudienceType,
  setTaxonomyCampaignFreeForm,
  setTaxonomyCampaignUniqueId,
  setTaxonomyCampaignStartDate,
  setTaxonomyCampaignMatchType,
  setTaxonomyCampaignSizmekTracking,
  setTaxonomyCampaignClearForm,
} from '../../state/TaxonomyCreatecampaign/TaxonomyCreateCampaignActions';
import Loader from '../../components/common/Loader';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { baseUrlStg } from '../../utils/constants';
import { getCookies } from '../../utils/utils';

const CreateTexonomyCampaign = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [mediaPlan, setMediaPlan] = useState({});
  const [advertiserName, setAdvertiserName] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [gender, setGender] = useState([]);
  const [ageGroup, setAgeGroup] = useState([]);
  const [phaseData, setPhaseData] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [dspPublisherData, setDsppublisherData] = useState([]);
  const [matchTypeData, setMatchTypeData] = useState([
    {
      label: 'None',
      value: 0,
    },
  ]);
  const [campaignTypeData, setCampaignTypeData] = useState([]);
  const [buyTypeRateType, setBuyTypeRateType] = useState([]);
  const [campaignKpi, setCampaignKpi] = useState([]);
  const [campaignObjective, setCampaignObjective] = useState([]);
  const [campaignAudienceType, setCampaignAudienceType] = useState([]);
  const [showPupup, setShowPupup] = useState(false);
  const [showCharLengthPupup, setShowCharLengthPupup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const texonomyCreateCampaignData = state?.TaxonomyCreateCampaign;
  const LoginReducer = useSelector((state) => state?.loginReducer);
  const userId = LoginReducer?.userToken?.Userid;

  console.log('texonomyCreateCampaignData', texonomyCreateCampaignData);

  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };

  const sizmekTrackingData = [
    {
      label: 'Yes',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ];

  useEffect(() => {
    console.log('texonomyCreateCampaignData', texonomyCreateCampaignData);
    init();
  }, []);

  const init = () => {
    console.log('searchParams', searchParams.get('mediaplan_id'));
    getMediaPlanData();
    getGenderData();
    getAgeGroupData();
    getPhaseData();
    getChannelData();
  };

  const handleCampaignName = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    const value = event.target.value;
    dispatch(setTaxonomyCampaignName(value));
  };

  const handleProductName = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    const value = event.target.value;
    dispatch(setTaxonomyCampaignProductName(value));
  };

  const handleAdvertiserName = (event) => {
    const value = event;
    getSubCategoryData(event.value);
    dispatch(setTaxonomyCampaignAdvertiser(value));
    dispatch(setTaxonomyCampaignSubcategory(null));
  };

  const handleSubcategory = (event) => {
    const value = event ? event : null;
    dispatch(setTaxonomyCampaignSubcategory(value));
  };

  const handleJoborRONumber = (event) => {
    if (event?.target?.value?.length > 0) {
      event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
    }
    const value = event?.target?.value;
    console.log('job or ro', value);
    dispatch(setTaxonomyCampaignJobOrRoNumber(value));
  };

  const handleGender = (event) => {
    const value = event ? event : null;
    console.log(value);
    dispatch(setTaxonomyCampaignGender(value));
  };

  const handleAge = (event) => {
    const value = event ? event : null;
    console.log(value);
    dispatch(setTaxonomyCampaignAge(value));
  };

  const handlePhase = (event) => {
    const value = event ? event : null;
    console.log(value);
    dispatch(setTaxonomyCampaignPhase(value));
  };

  const handleChannel = (event) => {
    const value = event;
    console.log(value);
    getDSPPublisherData(event?.value);
    getcampaignAudiencetypeData(event?.value);
    dispatch(setTaxonomyCampaignChannel(value));
    dispatch(setTaxonomyCampaignDspPublisher(null));
    dispatch(setTaxonomyCampaignType(null));
    dispatch(setTaxonomyCampaignBuyType(null));
    dispatch(setTaxonomyCampaignKPI(null));
    dispatch(setTaxonomyCampaignObjective(null));
    dispatch(setTaxonomyCampaignAudienceType(null));
    dispatch(setTaxonomyCampaignMatchType(null));
    setDsppublisherData([]);
    setCampaignTypeData([]);
    setBuyTypeRateType([]);
    setCampaignKpi([]);
    setCampaignObjective([]);
    setCampaignAudienceType([]);
    if (event?.label.toLowerCase() === 'search') {
      // matchtype api call
      getMatchTypeData(event?.value);
    } else {
      setMatchTypeData([
        {
          label: 'None',
          value: 0,
        },
      ]);
      dispatch(setTaxonomyCampaignMatchType(null));
    }

    if (!event?.label.toLowerCase().includes('display')) {
      dispatch(setTaxonomyCampaignSizmekTracking(null));
    }
  };

  const handleDSPPublisher = (event) => {
    const value = event;
    console.log(value);
    getCampaignTypeData(event?.value);
    dispatch(setTaxonomyCampaignDspPublisher(value));
    dispatch(setTaxonomyCampaignType(null));
    dispatch(setTaxonomyCampaignBuyType(null));
    dispatch(setTaxonomyCampaignKPI(null));
    dispatch(setTaxonomyCampaignObjective(null));
    setCampaignTypeData([]);
    setBuyTypeRateType([]);
    setCampaignKpi([]);
    setCampaignObjective([]);
  };

  const handleCampaignType = (event) => {
    const value = event;
    console.log(value);
    getBuyTypeRateTypeData(event?.value);
    dispatch(setTaxonomyCampaignType(value));
    dispatch(setTaxonomyCampaignBuyType(null));
    dispatch(setTaxonomyCampaignKPI(null));
    dispatch(setTaxonomyCampaignObjective(null));
    setBuyTypeRateType([]);
    setCampaignKpi([]);
    setCampaignObjective([]);
  };

  const handleBuyAndRateType = (event) => {
    const value = event;
    console.log(value);
    getCampaignKPIData(event?.value);
    dispatch(setTaxonomyCampaignBuyType(value));
    dispatch(setTaxonomyCampaignKPI(null));
    dispatch(setTaxonomyCampaignObjective(null));
    setCampaignKpi([]);
    setCampaignObjective([]);
  };

  const handleCampaignKPI = (event) => {
    const value = event;
    console.log('kpi id', value);
    getcampaignObjectiveData(event?.value);
    dispatch(setTaxonomyCampaignKPI(value));
    dispatch(setTaxonomyCampaignObjective(null));
    setCampaignObjective([]);
  };

  const handleObjectives = (event) => {
    const value = event;
    console.log(value);
    dispatch(setTaxonomyCampaignObjective(value));
  };

  const handleAudience = (event) => {
    const value = event;
    console.log(value);
    dispatch(setTaxonomyCampaignAudienceType(value));
  };

  const handleFreeForm = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    console.log(event.target.value);
    const value = event.target.value;
    dispatch(setTaxonomyCampaignFreeForm(value));
  };

  const handleUniqueId = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    console.log(event.target.value);
    const value = event.target.value;
    dispatch(setTaxonomyCampaignUniqueId(value));
  };

  const handleStartDate = (e) => {
    dispatch(setTaxonomyCampaignStartDate(e));
  };

  const handleMatchType = (event) => {
    const value = event;
    console.log(value);
    dispatch(setTaxonomyCampaignMatchType(value));
  };

  const handleSizmekTracking = (event) => {
    const value = event;
    console.log(value);
    dispatch(setTaxonomyCampaignSizmekTracking(value));
  };

  const getMediaPlanData = async () => {
    setIsLoading(true);
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/taxonomy_media_plan_id?taxonomy_media_plan_id=${searchParams.get(
        'mediaplan_id',
      )}`;
      let res = await axios.get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      });
      console.log('media plan data', res.data);
      if (res && res.data.status) {
        setMediaPlan(res.data);
        getAdvertiserData(res.data.division_id);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAdvertiserData = async (division_id) => {
    setIsLoading(true);
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/advertiser_name?division_id=${division_id}`;
      let res = await getApi(url);
      console.log('media advrt data', res);
      if (res?.advertisers) {
        setAdvertiserName(res.advertisers);
      } else {
        setAdvertiserName([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSubCategoryData = async (adv_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/subcategory?advertiser_name_id=${adv_id}`;
      let res = await getApi(url);
      console.log('media advrt data', res);
      if (res?.subcategory) {
        setSubCategory(res.subcategory);
      } else {
        setSubCategory([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGenderData = async () => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Gender`;
      let res = await getApi(url);
      if (res) {
        setGender(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPhaseData = async () => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/phase`;
      let res = await getApi(url);
      if (res) {
        setPhaseData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAgeGroupData = async () => {
    setIsLoading(true);
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/age_group`;
      let res = await getApi(url);
      if (res) {
        setAgeGroup(res);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getChannelData = async () => {
    setIsLoading(true);
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Channel`;
      let res = await getApi(url);
      console.log('channel', res);
      if (res.status === false) {
        setChannelData([]);
      } else {
        setChannelData(res);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getDSPPublisherData = async (channel_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Dsp_publishers?channel_id=${channel_id}`;
      let res = await getApi(url);
      console.log('dspPublisher', res);
      if (res?.dspPublisherList) {
        setDsppublisherData(res.dspPublisherList);
      } else {
        setDsppublisherData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCampaignTypeData = async (publisher_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Campaign_type?dsp_publisher_id=${publisher_id}`;
      let res = await getApi(url);
      console.log('campaign type', res);
      if (res?.campaigntypevalue) {
        setCampaignTypeData(res.campaigntypevalue);
      } else {
        setCampaignTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBuyTypeRateTypeData = async (camp_type_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Buy_type?media_campaign_type_id=${camp_type_id}`;
      let res = await getApi(url);
      console.log('buy type', res);
      if (res?.buyType) {
        setBuyTypeRateType(res.buyType);
      } else {
        setBuyTypeRateType([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCampaignKPIData = async (buy_type_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Campaign_kpi?campaign_buy_type_id=${buy_type_id}`;
      let res = await getApi(url);
      console.log('KPI', res);
      if (res?.campaignKpi) {
        setCampaignKpi(res.campaignKpi);
      } else {
        setCampaignKpi([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getcampaignObjectiveData = async (kpi_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/Objective?media_campaign_kpi_id=${kpi_id}`;
      let res = await getApi(url);
      console.log('objectives', res);
      if (res?.objective) {
        setCampaignObjective(res.objective);
      } else {
        setCampaignObjective([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getcampaignAudiencetypeData = async (channel_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/AudienceType-Placement?media_campaign_channel_id=${channel_id}`;
      let res = await getApi(url);
      console.log('objectives', res);
      if (res?.audienceType) {
        setCampaignAudienceType(res.audienceType);
      } else {
        setCampaignAudienceType([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMatchTypeData = async (channel_id) => {
    try {
      const url = `${baseUrlStg}/api/taxonomy-media/MatchType?media_campaign_channel_id=${channel_id}`;
      let res = await getApi(url);
      console.log('matchtype', res);
      if (res?.matchTypeData) {
        setMatchTypeData((prev) => [...prev, ...res.matchTypeData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = () => {
    setFormErrors(validate(texonomyCreateCampaignData));

    console.log('outside');

    setTimeout(async () => {
      if (Object.keys(validate(texonomyCreateCampaignData)).length === 0) {
        console.log('inside');
        // console.log('submit campaign data ', texonomyCreateCampaignData)
        setIsLoading(true);

        let charCheck = ``;
        if (
          texonomyCreateCampaignData?.dsp_publisher?.label?.toLowerCase() ===
            'bing' &&
          texonomyCreateCampaignData?.channel?.label
            ?.toLowerCase()
            .includes('search')
        ) {
          console.log('inside search');
          charCheck = `${texonomyCreateCampaignData?.unique_id}|${texonomyCreateCampaignData?.campaign_name}|${mediaPlan?.divisionName}|${texonomyCreateCampaignData?.product_name}|${texonomyCreateCampaignData?.subcategory?.label}|${texonomyCreateCampaignData?.phase.label}||${mediaPlan?.media_paln_quater_id}|||${texonomyCreateCampaignData?.job_or_ro_number}|${texonomyCreateCampaignData?.buy_type_rate_type?.label}||${texonomyCreateCampaignData?.channel?.label}|${texonomyCreateCampaignData?.dsp_publisher?.label}|${texonomyCreateCampaignData?.free_form}|${texonomyCreateCampaignData?.campaign_type?.label}||${texonomyCreateCampaignData?.audience_type?.label}|${texonomyCreateCampaignData?.match_type?.label}|||`;
        }
        if (
          texonomyCreateCampaignData?.dsp_publisher?.label?.toLowerCase() ===
            'bing' &&
          texonomyCreateCampaignData?.channel?.label
            ?.toLowerCase()
            .includes('display')
        ) {
          console.log('inside display');
          charCheck = `${texonomyCreateCampaignData?.unique_id}|${texonomyCreateCampaignData?.campaign_name}|${mediaPlan?.divisionName}|${texonomyCreateCampaignData?.product_name}|${texonomyCreateCampaignData?.subcategory?.label}|${texonomyCreateCampaignData?.phase.label}||${mediaPlan?.media_paln_quater_id}|||${texonomyCreateCampaignData?.job_or_ro_number}|${texonomyCreateCampaignData?.buy_type_rate_type?.label}||${texonomyCreateCampaignData?.channel?.label}|${texonomyCreateCampaignData?.dsp_publisher?.label}|${texonomyCreateCampaignData?.free_form}|||${texonomyCreateCampaignData?.audience_type?.label}||||`;
        }

        console.log('check length', charCheck.length);
        if (
          texonomyCreateCampaignData?.dsp_publisher?.label?.toLowerCase() ===
            'bing' &&
          charCheck.length > 128
        ) {
          console.log('tested check length', charCheck.length > 128);
          setShowCharLengthPupup(true);
          setIsLoading(false);
        } else {
          try {
            console.log('apiiiiiiiiiiii');
            const postData = {
              division_id: mediaPlan.division_id,
              media_plan_phase_id: mediaPlan.media_plan_phase_id,
              media_paln_quater_id: mediaPlan.media_paln_quater_id,
              taxonomy_media_plan_id: searchParams.get('mediaplan_id'),
              campaign_name_bqms: texonomyCreateCampaignData.campaign_name,
              product_pr: texonomyCreateCampaignData.product_name,
              advertiser_name_id:
                texonomyCreateCampaignData.advertiser_name.value,
              sub_category_id: texonomyCreateCampaignData.subcategory.value,
              precision_job_number: texonomyCreateCampaignData.job_or_ro_number,
              gender: texonomyCreateCampaignData.gender.value,
              age_group_id: texonomyCreateCampaignData.age.value,
              phase_id: texonomyCreateCampaignData.phase.value,
              channel_id: texonomyCreateCampaignData.channel.value,
              dsp_publisher_id: texonomyCreateCampaignData.dsp_publisher.value,
              campaign_type_id: texonomyCreateCampaignData.campaign_type.value,
              buy_type_id: texonomyCreateCampaignData.buy_type_rate_type.value,
              campaign_kpi_id: texonomyCreateCampaignData.campaign_kpi.value,
              objective_id: texonomyCreateCampaignData.objective.value,
              audience_type_placement_id:
                texonomyCreateCampaignData.audience_type.value,
              free_form: texonomyCreateCampaignData.free_form,
              unique_id_cp: texonomyCreateCampaignData.unique_id,
              campaign_date: texonomyCreateCampaignData.start_date,
              match_type: texonomyCreateCampaignData.match_type.value,
              sizmek_tracking: texonomyCreateCampaignData.sizmek_tracking
                ? texonomyCreateCampaignData.sizmek_tracking.value
                : 0,
              created_by: userId,
              last_edited_by: 0,
            };

            const URL = `${baseUrlStg}/api/taxonomy-media/Create_Campaign`;
            const headers = {
              'Content-Type': 'application/json-patch+json',
            };
            console.log('postData', postData);
            const res = await postApi(URL, postData, headers);
            console.log('post api responce create campaign', res);
            if (res.data.success) {
              setShowPupup(true);
              dispatch(setTaxonomyCampaignClearForm(null));
              setIsLoading(false);
            } else {
              setIsLoading(false);
              if (res.data.message.toLowerCase().includes('unique')) {
                setFormErrors({
                  unique_id: res.data.message,
                });
              }
            }
          } catch (error) {
            setIsLoading(false);
          }
        }
        // console.log('submit media plan  data ', mediaPlan)
      }
    }, 100);
  };

  const validate = (formData) => {
    const errors = {};

    if (formData?.campaign_name === '') {
      errors.campaign_name = 'Please enter campaign name';
    }
    if (formData?.product_name === '') {
      errors.product_name = 'Please enter product';
    }
    if (!formData?.advertiser_name) {
      errors.advertiser_name = 'Please select advertiser';
    }
    if (!formData?.subcategory || formData?.subcategory === '') {
      errors.subcategory = 'Please select subcategory';
    }
    if (!formData?.job_or_ro_number || formData?.job_or_ro_number === '') {
      errors.job_or_ro_number = 'Please enter precision job number';
    }
    if (!formData?.gender) {
      errors.gender = 'Please select gender';
    }

    if (!formData?.age) {
      errors.age = 'Please select age';
    }

    if (!formData?.phase) {
      errors.phase = 'Please select phase';
    }
    if (!formData?.channel) {
      errors.channel = 'Please select channel';
    }
    if (!formData?.dsp_publisher) {
      errors.dsp_publisher = 'Please select dsp/publisher';
    }
    if (!formData?.campaign_type) {
      errors.campaign_type = 'Please select campaign type';
    }
    if (!formData?.buy_type_rate_type) {
      errors.buy_type_rate_type = 'Please select buy type';
    }
    if (!formData?.campaign_kpi) {
      errors.campaign_kpi = 'Please select campaign kpi';
    }
    if (!formData?.objective) {
      errors.objective = 'Please select objective';
    }
    if (!formData?.audience_type) {
      errors.audience_type = 'Please select audiance';
    }
    if (!formData?.free_form) {
      errors.free_form = 'Please enter free form';
    }
    if (!formData?.unique_id) {
      errors.unique_id = 'Please enter unique id';
    }
    if (!formData?.start_date) {
      errors.start_date = 'Please select campaign start date';
    }

    if (
      formData?.channel &&
      (formData?.channel?.label?.toLowerCase().includes('display') ||
        formData?.channel?.label?.toLowerCase().includes('video'))
    ) {
      if (!formData?.sizmek_tracking) {
        errors.sizmek_tracking = 'please select sizmek tracking';
      }
    }

    if (!formData?.match_type) {
      errors.match_type = 'please select match type';
    }

    return errors;
  };

  const handleNavigate = (type) => {
    switch (type) {
      case 'media_plan':
        navigate('/taxonomy-manager/media-plan-list');
        break;
      case 'campaign':
        navigate(
          `/taxonomy-manager/media-campaign-list?mediaplan_id=${searchParams.get(
            'mediaplan_id',
          )}`,
        );
        break;

      default:
        navigate(-1);
        break;
    }
  };

  const flightStartDate = texonomyCreateCampaignData?.start_date;
  const defaultStartDate = new Date(); // You can set your own default date here if needed

  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container'>
          <div className='container'>
            <div className='taxonomy-panel'>
              <div>
                <Sidebar />
              </div>
              <div className='right-side-box media-section-left'>
                <header>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-left-bar'>
                      <h2>Create Campaign</h2>
                    </div>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={() => navigate(-1)}
                          className='primary-btn close-btn'
                        >
                          <i className='icon-close' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={() => handleNavigate('campaign')}
                          className='btn-icon btn-icon-primary'
                        >
                          <i className='icon-edit-box' /> List Campaign
                        </button>
                        <button
                          onClick={() => handleNavigate('media_plan')}
                          className='btn-icon btn-icon-primary'
                        >
                          <i className='icon-edit-box' /> Show all Media Plans
                        </button>
                      </div>
                    </div>
                  </div>
                </header>
                <div className='top-bedcrumb'>
                  <ul>
                    <li>
                      Division: <strong>{mediaPlan?.divisionName}</strong>
                    </li>
                    <li>
                      Phase: <strong>{mediaPlan?.media_plan_phase_Name}</strong>
                    </li>
                    <li>
                      Quarter:{' '}
                      <strong>{mediaPlan?.media_paln_quater_id}</strong>
                    </li>
                    <li>
                      Market: <strong>{mediaPlan?.market}</strong>
                    </li>
                    {/* <li>Currency: <strong>{mediaPlan?.currency?.toLowerCase() === }</strong></li> */}
                    <li>
                      Currency: <strong>{'INR'}</strong>
                    </li>
                  </ul>
                </div>
                <article className='form-inside-create'>
                  <div className='form-panel card-primary'>
                    <div className='form-item-cont row'>
                      {/* Campaign Name {'(BQMS)'} */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Campaign Name </label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter campaign name'
                            onChange={handleCampaignName}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.campaign_name}
                        </div>
                      </div>
                      {/* Product{'(PR~)'} */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Product</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter product name'
                            onChange={handleProductName}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.product_name}
                        </div>
                      </div>
                      {/* Advertiser Name */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Advertiser Name </label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select advertiser'}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            isClearable={false}
                            options={advertiserName}
                            onChange={handleAdvertiserName}
                            defaultValue={[]}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.advertiser_name}
                        </div>
                      </div>
                      {/* Subcategory/Sub-Brand */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Subcategory/Sub-Brand </label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select subcategory'}
                            value={texonomyCreateCampaignData?.subcategory}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            options={subCategory}
                            onChange={handleSubcategory}
                            defaultValue={[]}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.subcategory}
                        </div>
                      </div>
                      {/* Precision Job Number/RO Number */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Precision Job Number/RO Number </label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            placeholder='Enter precision job number'
                            onChange={handleJoborRONumber}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.job_or_ro_number}
                        </div>
                      </div>
                      {/* Gender */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Gender</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select gender'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            options={gender}
                            defaultValue={[]}
                            onChange={handleGender}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.gender}
                        </div>
                      </div>
                      {/* Age */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Age</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select age'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            options={ageGroup}
                            defaultValue={[]}
                            onChange={handleAge}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.age}
                        </div>
                      </div>
                      {/* Phase */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Phase</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select phase'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            options={phaseData}
                            defaultValue={[]}
                            onChange={handlePhase}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.phase}
                        </div>
                      </div>
                      {/* Channel */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Channel</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select channel'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={channelData}
                            onChange={handleChannel}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.channel}
                        </div>
                      </div>
                      {/* DSP/Publisher */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>DSP/Publisher</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select DSP/Publisher'}
                            isSearchable={false}
                            isClearable={false}
                            value={texonomyCreateCampaignData?.dsp_publisher}
                            closeMenuOnSelect={true}
                            options={dspPublisherData}
                            defaultValue={[]}
                            onChange={handleDSPPublisher}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.dsp_publisher}
                        </div>
                      </div>
                      {/* Campaign Type */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Campaign Type</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select campaign type'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            value={texonomyCreateCampaignData?.campaign_type}
                            defaultValue={[]}
                            options={campaignTypeData}
                            onChange={handleCampaignType}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.campaign_type}
                        </div>
                      </div>
                      {/* Buy Type/Rate Type */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Buy Type/Rate Type</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select buy type/rate type'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            value={
                              texonomyCreateCampaignData?.buy_type_rate_type
                            }
                            defaultValue={[]}
                            options={buyTypeRateType}
                            onChange={handleBuyAndRateType}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.buy_type_rate_type}
                        </div>
                      </div>
                      {/* Campaign KPI */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Campaign KPI</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select campaign KPI'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            value={texonomyCreateCampaignData?.campaign_kpi}
                            defaultValue={[]}
                            options={campaignKpi}
                            onChange={handleCampaignKPI}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.campaign_kpi}
                        </div>
                      </div>
                      {/* Objective */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Objective</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select objective'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            value={texonomyCreateCampaignData?.objective}
                            defaultValue={[]}
                            options={campaignObjective}
                            onChange={handleObjectives}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.objective}
                        </div>
                      </div>
                      {/* AudienceType/Placement */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>AudienceType/Placement</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select audienceType'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            value={texonomyCreateCampaignData?.audience_type}
                            defaultValue={[]}
                            options={campaignAudienceType}
                            onChange={handleAudience}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.audience_type}
                        </div>
                      </div>
                      {/* Free Form */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Free Form</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter free form value'
                            onChange={handleFreeForm}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.free_form}
                        </div>
                      </div>
                      {/* Unique ID */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Unique ID</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter unique ID'
                            onChange={handleUniqueId}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.unique_id}
                        </div>
                      </div>
                      {/* Campaign Start Date */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Campaign Start Date</label>
                        </div>
                        <div className='input-group'>
                          <ReactDatePicker
                            selected={
                              flightStartDate ? new Date(flightStartDate) : null
                            }
                            onChange={(date) => handleStartDate(date)}
                            dateFormat='dd/MM/yyyy'
                            showMonthDropdown
                            showYearDropdown
                            placeholderText='DD/MM/YYYY'
                            name='flight_start_date'
                            minDate={defaultStartDate}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.start_date}
                        </div>
                      </div>
                      {/* Match Type */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Match Type</label>
                        </div>
                        <div className='select-box small-select-multiple-components '>
                          <ReactSelect
                            placeholder={'Select match type'}
                            isSearchable={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={matchTypeData}
                            value={texonomyCreateCampaignData?.match_type}
                            onChange={handleMatchType}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.match_type}
                        </div>
                      </div>
                      {/* Sizmek Tracking */}
                      {(texonomyCreateCampaignData?.channel?.label
                        ?.toLowerCase()
                        .includes('display') ||
                        texonomyCreateCampaignData?.channel?.label
                          ?.toLowerCase()
                          .includes('video')) && (
                        <div className='form-item col-4'>
                          <div className='label-icon'>
                            <label>Sizmek Tracking</label>
                          </div>
                          <div className='select-box small-select-multiple-components '>
                            <ReactSelect
                              placeholder={'Select sizmek tracking'}
                              isSearchable={false}
                              isClearable={false}
                              closeMenuOnSelect={true}
                              defaultValue={[]}
                              options={sizmekTrackingData}
                              onChange={handleSizmekTracking}
                            />
                          </div>
                          <div className='error' id='nameError'>
                            {formErrors?.sizmek_tracking}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='form-btn-cont'>
                      <button
                        type='submit'
                        id='submit'
                        onClick={submitHandler}
                        className='primary-btn'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={showPupup}
        onHide={() => {
          setShowPupup(false);
          handleNavigate('campaign');
        }}
        backdrop='static'
        keyboard={false}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-primary'>
              <i className='icon-media-plan' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Thank you!</h3>
            <p>Your campaign has been successfully created.</p>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => {
              setShowPupup(false);
              handleNavigate('campaign');
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
      <Modal
        show={showCharLengthPupup}
        onHide={() => setShowCharLengthPupup(false)}
        backdrop='static'
        keyboard={false}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-remove'>
              <i className='icon-close' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Oops!</h3>
            <p>
              Character Limit Exceeded for Bing Campaigns! Please modify your
              form entries and resubmit. Max limit: 128 characters.
            </p>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => setShowCharLengthPupup(false)}
          >
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateTexonomyCampaign;
