import React from 'react';
import { checkForNull } from '../../../../utils/utils';

const DataDetails = ({ Label, LabelFor = [], children }) => {
  console.log('LabelFor', LabelFor);
  return (
    <div className='card-header-top d-flex'>
      <div className='card-lt-pane'>
        <h5>{Label}</h5>
        {Array.isArray(LabelFor) && LabelFor?.length > 0 ? (
          <p>
            {Array.from(new Set(LabelFor))
              .map((value, index) => checkForNull(value))
              .join(', ')}
          </p>
        ) : (
          <p>{checkForNull(LabelFor)}</p>
        )}
        {children}
      </div>
    </div>
  );
};

export default DataDetails;
