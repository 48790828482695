import moment from 'moment';

import { formatDateToIST } from '../../utils/validations';

const DisplayCampaignStatus = (props) => {
  let getStatus = props?.moderationStatus?.toLowerCase();
  return (
    <>
      {getStatus != 'api_error' && (
        <div class='card-status-message'>
          <div
            class={`card-status-info ${
              getStatus == 'published'
                ? 'status-approved-mess'
                : getStatus == 'draft'
                  ? 'status-draft-mess'
                  : getStatus == 'rejected'
                    ? 'status-rejected-mess'
                    : getStatus == 'in_moderation' ||
                        getStatus == 'in moderation'
                      ? 'status-moderation-mess'
                      : ''
            }`}
            id='showmsgId'
          >
            {getStatus == 'published' ? (
              <p>
                The campaign has been approved by the publisher on{' '}
                {moment(formatDateToIST(props?.publishedAt)).format(
                  'DD/MM/YYYY',
                )}{' '}
              </p>
            ) : getStatus == 'draft' ? (
              <p>The campaign is currently in Draft</p>
            ) : getStatus == 'rejected' ? (
              <p>
                The campaign has been rejected by the publisher on{' '}
                {moment(formatDateToIST(props?.publishedAt)).format(
                  'DD/MM/YYYY',
                )}
              </p>
            ) : getStatus == 'in_moderation' || getStatus == 'in moderation' ? (
              <p>
                The campaign approval request is currently awaiting review from
                the publisher
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default DisplayCampaignStatus;
