import moment from 'moment-timezone';
import {
  DV_Insertion_Name,
  DV_Insertion_Id,
  DV_Insertion_Orders_Lists,
  DV_Pacing_First_Type,
  DV_Pacing_Second_Type,
  DV_Pacing_Daily_Price,
  DV_Goal_Type,
  DV_Goal_Value,
  DV_Optimization_Type,
  DV_Optimization_Action,
  DV_Optimization_CPM_Value,
  DV_Optimization_Priortize_Deal,
  DV_Frequency_Cap_Type,
  DV_Selected_Frequencey_Value,
  DV_Selected_Frequencey_Limit_Type,
  DV_Selected_Frequencey_Limit_Value,
  ADD_Budget,
  ADD_Budget_Amount,
  ADD_Budget_Start_Date,
  ADD_Budget_End_Date,
  Remove_Budget,
  DV_Quality,
  DV_Public_Inventory,
  DV_Deals_And_Inventory_Packages,
  DV_Groups,
  DV_Budget_Type,
  ADD_Budget_Type,
  DV_Prefilled_Values,
  DV_Optimization_Checked_Status,
  RESET_INSERTION_STATE,
} from './CreateCampaignActionTypes';

const setInsertionOrderLists = (payload) => {
  return {
    type: DV_Insertion_Orders_Lists,
    payload: payload,
  };
};
const setInsertionOrderName = (payload) => {
  return {
    type: DV_Insertion_Name,
    payload: payload,
  };
};
const setInsertionOrderId = (payload) => {
  return {
    type: DV_Insertion_Id,
    payload: payload,
  };
};
const setBudgetType = (payload) => {
  return {
    type: DV_Budget_Type,
    payload: payload,
  };
};
const setAddBudget = (payload) => {
  return {
    type: ADD_Budget,
    payload: payload,
  };
};
const setSpacingFirstType = (payload) => {
  return {
    type: DV_Pacing_First_Type,
    payload: payload,
  };
};

const setSpacingSecondType = (payload) => {
  return {
    type: DV_Pacing_Second_Type,
    payload: payload,
  };
};

const setBudgetSpacingDailyPrice = (payload) => {
  return {
    type: DV_Pacing_Daily_Price,
    payload: payload,
  };
};

const setGoalType = (payload) => {
  return {
    type: DV_Goal_Type,
    payload: payload,
  };
};

const setGoalValue = (payload) => {
  return {
    type: DV_Goal_Value,
    payload: payload,
  };
};

const setOptimizationType = (payload) => {
  return {
    type: DV_Optimization_Type,
    payload: payload,
  };
};

const setOptimizationAction = (payload) => {
  return {
    type: DV_Optimization_Action,
    payload: payload,
  };
};

const setOptimizationCheckedStatus = (payload) => {
  return {
    type: DV_Optimization_Checked_Status,
    payload: payload,
  };
};

const setOptimizationCPMValue = (payload) => {
  return {
    type: DV_Optimization_CPM_Value,
    payload: payload,
  };
};

const setPriortizeDealValue = (payload) => {
  return {
    type: DV_Optimization_Priortize_Deal,
    payload: payload,
  };
};
const setFrequencyCapType = (payload) => {
  return {
    type: DV_Frequency_Cap_Type,
    payload: payload,
  };
};
const setFrequencyVal = (payload) => {
  return {
    type: DV_Selected_Frequencey_Value,
    payload: payload,
  };
};

const setExposuresType = (payload) => {
  return {
    type: DV_Selected_Frequencey_Limit_Type,
    payload: payload,
  };
};

const setExposuresValue = (payload) => {
  return {
    type: DV_Selected_Frequencey_Limit_Value,
    payload: payload,
  };
};
const setQuality = (payload) => {
  return {
    type: DV_Quality,
    payload: payload,
  };
};
const setPublicInvetory = (payload) => {
  return {
    type: DV_Public_Inventory,
    payload: payload,
  };
};
const setDealsAndInventoryPackages = (payload) => {
  return {
    type: DV_Deals_And_Inventory_Packages,
    payload: payload,
  };
};
const setGroups = (payload) => {
  return {
    type: DV_Groups,
    payload: payload,
  };
};
const setBudgetAMount = (payload, index) => {
  return {
    type: ADD_Budget_Amount,
    payload: { payload, index },
  };
};
const setBudgetStartDate = (payload, index) => {
  return {
    type: ADD_Budget_Start_Date,
    payload: { payload, index },
  };
};
const setBudgetEndDate = (payload, index) => {
  return {
    type: ADD_Budget_End_Date,
    payload: { payload, index },
  };
};
const setAddBudgetType = (payload, index) => {
  return {
    type: ADD_Budget_Type,
    payload: { payload, index },
  };
};
const removeBudget = (payload) => {
  return {
    type: Remove_Budget,
    payload: payload,
  };
};

const preFillValues = (payload) => {
  return {
    type: DV_Prefilled_Values,
    payload: payload,
  };
};

export const resetInsertionState = () => {
  return {
    type: RESET_INSERTION_STATE,
  };
};

export {
  setInsertionOrderName,
  setInsertionOrderId,
  setInsertionOrderLists,
  setSpacingFirstType,
  setSpacingSecondType,
  setBudgetSpacingDailyPrice,
  setGoalType,
  setGoalValue,
  setOptimizationType,
  setOptimizationAction,
  setOptimizationCPMValue,
  setPriortizeDealValue,
  setFrequencyCapType,
  setFrequencyVal,
  setExposuresType,
  setExposuresValue,
  setAddBudget,
  setBudgetStartDate,
  setBudgetEndDate,
  setQuality,
  setPublicInvetory,
  setDealsAndInventoryPackages,
  setGroups,
  setBudgetAMount,
  removeBudget,
  setBudgetType,
  setAddBudgetType,
  preFillValues,
  setOptimizationCheckedStatus,
};
