import moment from 'moment-timezone';
import { dateFormat } from '../../utils/validations';
import {
  DV_Insertion_Name,
  DV_Insertion_Id,
  DV_Insertion_Orders_Lists,
  DV_Pacing_First_Type,
  DV_Pacing_Second_Type,
  DV_Pacing_Daily_Price,
  DV_Goal_Type,
  DV_Goal_Value,
  DV_Optimization_Type,
  DV_Optimization_Action,
  DV_Optimization_CPM_Value,
  DV_Optimization_Priortize_Deal,
  DV_Frequency_Cap_Type,
  DV_Selected_Frequencey_Value,
  DV_Selected_Frequencey_Limit_Type,
  DV_Selected_Frequencey_Limit_Value,
  ADD_Budget,
  ADD_Budget_Amount,
  ADD_Budget_Start_Date,
  ADD_Budget_End_Date,
  Remove_Budget,
  DV_Quality,
  DV_Public_Inventory,
  DV_Deals_And_Inventory_Packages,
  DV_Groups,
  DV_Budget_Type,
  ADD_Budget_Type,
  DV_Prefilled_Values,
  DV_Optimization_Checked_Status,
  RESET_INSERTION_STATE,
} from './CreateCampaignActionTypes';

const initialState = {
  insertion_order_lists: [],
  insertion_order_type: 'Real-time bidding',
  insertion_order_name: '',
  taxonomy_campaign_id: '',
  budget_type: '',
  budgets: [
    {
      budget_id: 0,
      insertion_order_id: 0,
      budget_type: 'INR',
      budget_value: '',
      budget_startdate: dateFormat(moment().toDate()),
      budget_enddate: null,
      status: 1,
    },
  ],
  pacing_first_type: '',
  pacing_second_type: '',
  pacing_daily_price: 0,
  goal_type: '',
  goal_value: '',
  billable_outcome: 'Impressions',
  optimization_type: '',
  optimization_action: '',
  optimization_checked_status: true,
  optimization_CPM_value: '',
  optimization_priortize_deal: false,
  frequency_cap_type: '',
  frequency_limit: '',
  frequency_limit_type: '',
  frequency_cap_limit_value: '',
  quality: '',
  public_inventory: '',
  deals_and_inventory_packages: '',
  groups: '',
  single_budget_value: '',
  moderation_status: '',
  single_budget_startdate: dateFormat(moment().toDate()),
  single_budget_enddate: dateFormat(moment().add(1, 'days')),
};

export const DvInsertionOrder = (state = initialState, { type, payload }) => {
  switch (type) {
    case DV_Quality: {
      let data = payload;
      return {
        ...state,
        quality: data,
      };
    }
    case DV_Public_Inventory: {
      let data = payload;
      return {
        ...state,
        public_inventory: data,
      };
    }
    case DV_Budget_Type: {
      let data = payload;
      return {
        ...state,
        budget_type: data,
      };
    }
    case DV_Deals_And_Inventory_Packages: {
      let data = payload;
      return {
        ...state,
        deals_and_inventory_packages: data,
      };
    }
    case DV_Groups: {
      let data = payload;
      return {
        ...state,
        groups: data,
      };
    }
    case DV_Insertion_Orders_Lists: {
      let data = payload;
      return {
        ...state,
        insertion_order_lists: data,
      };
    }
    case DV_Insertion_Name: {
      let data = payload;
      return {
        ...state,
        insertion_order_name: data,
      };
    }
    case DV_Insertion_Id: {
      let data = payload;
      return {
        ...state,
        taxonomy_campaign_id: data,
      };
    }
    case DV_Pacing_First_Type: {
      let data = payload;
      return {
        ...state,
        pacing_first_type: data,
      };
    }
    case DV_Pacing_Second_Type: {
      let data = payload;
      return {
        ...state,
        pacing_second_type: data,
      };
    }
    case DV_Pacing_Daily_Price: {
      let data = payload;
      return {
        ...state,
        pacing_daily_price: data,
      };
    }
    case DV_Goal_Type: {
      let data = payload;
      return {
        ...state,
        goal_type: data,
      };
    }
    case DV_Goal_Value: {
      let data = payload;
      return {
        ...state,
        goal_value: data,
      };
    }
    case DV_Optimization_Type: {
      let data = payload;
      return {
        ...state,
        optimization_type: data,
      };
    }
    case DV_Optimization_Action: {
      let data = payload;
      return {
        ...state,
        optimization_action: data,
      };
    }
    case DV_Optimization_Checked_Status: {
      let data = payload;
      return {
        ...state,
        optimization_checked_status: data,
      };
    }
    case DV_Optimization_CPM_Value: {
      let data = payload;
      return {
        ...state,
        optimization_CPM_value: data,
      };
    }
    case DV_Optimization_Priortize_Deal: {
      let data = payload;
      return {
        ...state,
        optimization_priortize_deal: data,
      };
    }
    case DV_Frequency_Cap_Type: {
      let data = payload;
      return {
        ...state,
        frequency_cap_type: data,
      };
    }
    case DV_Selected_Frequencey_Value: {
      let data = payload;
      return {
        ...state,
        frequency_limit: data,
      };
    }
    case DV_Selected_Frequencey_Limit_Type: {
      let data = payload;
      return {
        ...state,
        frequency_limit_type: data,
      };
    }
    case DV_Selected_Frequencey_Limit_Value: {
      let data = payload;
      return {
        ...state,
        frequency_cap_limit_value: data,
      };
    }
    case ADD_Budget: {
      let data = {
        budget_id: 0,
        insertion_order_id: 0,
        budget_type: 'INR',
        budget_value: '',
        budget_startdate: moment
          .tz(payload, 'Asia/Kolkata')
          .add(1, 'days')
          .format(),
        budget_enddate: null,
        status: 1,
      };
      let newArray = state?.budgets;
      newArray.push(data);
      return {
        ...state,
        budgets: newArray,
      };
    }
    case ADD_Budget_Amount: {
      let index = payload?.index;
      let allValues = state?.budgets?.filter((item) => item?.status == 1);
      let val = payload?.payload;
      let item = state?.budgets?.filter((item) => item?.status == 1)[index];
      item = { ...item, budget_value: val };
      allValues = [
        ...allValues.slice(0, index),
        item,
        ...allValues.slice(index + 1),
      ];
      return {
        ...state,
        budgets: allValues,
      };
    }
    case ADD_Budget_Type: {
      let index = payload?.index;
      let allValues = state?.budgets;
      let val = payload?.payload;
      let item = state?.budgets[index];
      item = { ...item, budget_type: val };
      allValues = [
        ...allValues.slice(0, index),
        item,
        ...allValues.slice(index + 1),
      ];

      return {
        ...state,
        budgets: allValues,
      };
    }
    case ADD_Budget_Start_Date: {
      let index = payload?.index;
      let allValues = state?.budgets?.filter((item) => item?.status == 1);
      let val = dateFormat(payload?.payload);
      let item = state?.budgets?.filter((item) => item?.status == 1)[index];
      if (val != 'Invalid date') {
        item = { ...item, budget_startdate: val };
        allValues = [
          ...allValues.slice(0, index),
          item,
          ...allValues.slice(index + 1),
        ];
        return {
          ...state,
          budgets: allValues,
        };
      } else {
        item = {
          ...item,
          budget_startdate: state?.budgets?.filter((item) => item?.status == 1)[
            index
          ]['budget_startdate'],
        };
        allValues = [
          ...allValues.slice(0, index),
          item,
          ...allValues.slice(index + 1),
        ];
        return {
          ...state,
          budgets: allValues,
        };
      }
    }

    case ADD_Budget_End_Date: {
      let index = payload?.index;
      let allValues = state?.budgets?.filter((item) => item?.status == 1);
      let val = dateFormat(payload?.payload);
      let item = state?.budgets?.filter((item) => item?.status == 1)[index];
      if (val != 'Invalid date') {
        // item = {
        //   ...item,
        //   budget_enddate: moment
        //     .tz(val, 'Asia/Kolkata')
        //     .add(1, 'days')
        //     .format(),
        // };
        item = { ...item, budget_enddate: val };
        allValues = [
          ...allValues.slice(0, index),
          item,
          ...allValues.slice(index + 1),
        ];
        return {
          ...state,
          budgets: allValues,
        };
      } else {
        item = {
          ...item,
          budget_enddate: state?.budgets?.filter((item) => item?.status == 1)[
            index
          ]['budget_enddate'],
        };
        allValues = [
          ...allValues.slice(0, index),
          item,
          ...allValues.slice(index + 1),
        ];
        return {
          ...state,
          budgets: allValues,
        };
      }
    }
    case Remove_Budget: {
      let index = payload;
      let allValues = state?.budgets?.filter((item) => item?.status == 1);
      let item = state?.budgets?.filter((item) => item?.status == 1)[index];
      item = { ...item, status: 0 };
      // allValues = [...allValues.slice(0, index), ...allValues.slice(index + 1)];
      allValues = [
        ...allValues.slice(0, index),
        item,
        ...allValues.slice(index + 1),
      ];

      return {
        ...state,
        budgets: allValues,
      };
    }
    case DV_Prefilled_Values: {
      return {
        ...state,
        insertion_order_id: payload?.insertion_order_id,
        campaign_id: payload?.campaign_id,
        dv360_insertionorder_id: payload?.dv360_insertionorder_id,
        insertion_order_type: payload?.insertion_order_type,
        taxonomy_campaign_id: payload?.taxonomy_campaign_id,
        insertion_order_name: payload?.insertion_order_name,
        taxonomy_media_campaign: payload?.taxonomy_media_campaign,
        budgets: payload?.budgets,
        optimization_type: payload?.optimization_type,
        optimization_action: payload?.optimization_action,
        frequency_cap_type: payload?.frequency_cap_type,
        frequency_limit: payload?.frequency_limit,
        frequency_limit_type: payload?.frequency_limit_type,
        frequency_cap_limit_value: payload?.frequency_cap_limit_value,
        billable_outcome: 'Impressions',
        pacing_first_type: payload?.pacing_type,
        pacing_second_type: payload?.pacing_action,
        pacing_daily_price: payload?.pacing_value,
        goal_type: payload?.goal_type,
        goal_value: payload?.goal_value,
        budget_type: payload?.budget_type,
        optimization_CPM_value: payload?.optimization_CPM_value,
        optimization_priortize_deal: payload?.optimization_priortize_deal,
        flight_startdate: payload?.flight_startdate,
        flight_enddate: payload?.flight_enddate,
        quality: payload?.invsource_quality,
        public_inventory: payload?.invsource_publicinventoryid,
        deals_and_inventory_packages: payload?.invsource_dealspackagesid,
        groups: payload?.invsource_groupid,
        created_by: payload?.created_by,
        user: payload?.user,
        modified_by: payload?.modified_by,
        moderation_status: payload?.moderation_status,
        status: payload?.status,
        created_at: payload?.created_at,
      };
    }

    case RESET_INSERTION_STATE: {
      return initialState; // Reset the state to its initial values
    }

    default:
      return state;
  }
};
