import React from 'react';

const ListingGroupUploadFile = ({
  handleUploadFile,
  getCSVFileInfo,
  handleDownloadFile,
  getCurrentPageStatus,
  handleDownloadProductListingSampleFile,
}) => {
  return (
    <>
      <div className='card-body'>
        <div>
          {/*  */}
          <div class='media-btn media-btn-file'>
            <div class='file-error'>
              <div class='btn-icon btn-bedcrumb-act btn-file'>
                <input
                  accept='.csv'
                  type='file'
                  id='imgFileIDVi'
                  onChange={(e) => handleUploadFile(e)}
                />
                <label for='imgFileIDVi'>
                  <i class='icon-export'></i> Upload Listing Groups
                </label>
              </div>
              <div
                class='btn-icon btn-bedcrumb-act btn-file'
                onClick={handleDownloadFile}
              >
                <label>
                  <i class='icon-file'></i> Download sample file
                </label>
              </div>
              {getCSVFileInfo?.CSVFileName?.length > 0 && (
                <div
                  class='btn-icon btn-bedcrumb-act btn-file btn-file-download'
                  onClick={(e) =>
                    handleDownloadProductListingSampleFile(
                      getCSVFileInfo?.CSVFileName,
                    )
                  }
                >
                  <label>
                    <i class='icon-csv-file'></i>{' '}
                    <strong> File Name: {getCSVFileInfo?.CSVFileName}</strong>
                    <i class='icon-import'></i>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </>
  );
};

export default ListingGroupUploadFile;
