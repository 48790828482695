import {
  CAMPAIGN_GOAL_APP_NAME,
  CAMPAIGN_GOAL_APP_PLATFORM,
  CAMPAIGN_GOAL_CHECKBOX,
  CAMPAIGN_GOAL_PHONE_CALLS,
  CAMPAIGN_GOAL_WEBSITE_VISITS,
  CAMPAIGN_LIST,
  CAMPAIGN_OBJECTIVE_AND_TYPE,
  GOOGLE_CAMPAIGN_ADD_PARAM,
  GOOGLE_CAMPAIGN_ADD_SCHEDULE,
  GOOGLE_CAMPAIGN_ADD_SCHEDULE_SELECT_DAYS,
  GOOGLE_CAMPAIGN_ADD_SCHEDULE_TIME,
  GOOGLE_CAMPAIGN_BIDDING_AD_LOCATION,
  GOOGLE_CAMPAIGN_BIDDING_CPA_COST,
  GOOGLE_CAMPAIGN_BIDDING_FOCUS,
  GOOGLE_CAMPAIGN_BIDDING_TARGET_COST,
  GOOGLE_CAMPAIGN_BIDDING_TARGET_COST_CHECKBOX,
  GOOGLE_CAMPAIGN_BID_HIGHER_FOR_NEW_CUSTOMERS_CHECK,
  GOOGLE_CAMPAIGN_BID_ONLY_FOR_NEW_CUSTOMERS_CHECK,
  GOOGLE_CAMPAIGN_BRAND_RESTRICTED,
  GOOGLE_CAMPAIGN_BRAND_RESTRICTED_LIST,
  GOOGLE_CAMPAIGN_BUDGET,
  GOOGLE_CAMPAIGN_CAMPAIGN_NAME,
  GOOGLE_CAMPAIGN_CAMPAIGN_URL,
  GOOGLE_CAMPAIGN_CAMPAIGN_URL_TEMPLATE,
  GOOGLE_CAMPAIGN_CPA_CHECKBOX_HANDLER,
  GOOGLE_CAMPAIGN_CPC_CHECKBOX_HANDLER,
  GOOGLE_CAMPAIGN_CUS_PARAM,
  GOOGLE_CAMPAIGN_END_DATE,
  GOOGLE_CAMPAIGN_FINAL_URL_CHECK,
  GOOGLE_CAMPAIGN_GET_MASTER_API,
  GOOGLE_CAMPAIGN_MERCHENT_CENTER_ACCOUNT,
  GOOGLE_CAMPAIGN_NETOWRK_ID,
  GOOGLE_CAMPAIGN_OPTIMIZE_CAMPAIGN_CHECK,
  GOOGLE_CAMPAIGN_PAY_FOR_TYPE,
  GOOGLE_CAMPAIGN_REMOVE_SCHEDULE,
  GOOGLE_CAMPAIGN_ROAS_CHECKBOX_HANDLER,
  GOOGLE_CAMPAIGN_SET_WEB_URL_DISPLAY,
  GOOGLE_CAMPAIGN_START_DATE,
  GOOGLE_CAMPAIGN_TEXT_ASSET_CHECK,
  GOOGLE_CAMPAIGN_TRACKING_TEMPLATE,
  GOOGLE_CAMPAIGN_VIEWABLE_CPM,
  GOOGLE_CAMPAIGN_WAYS_TO_GET_CONVERSIONS,
  GOOGLE_CAMPAIGN_SET_SELECTED_OPERATING_TYPE,
  GOOGLE_CAMPAIGN_ADD_OPERATING_SYSTEM,
  GOOGLE_CAMPAIGN_SET_SELECTED_DEVICE_MODEL,
  GOOGLE_CAMPAIGN_SET_SELECTED_FINAL_DEVICE,
  GOOGLE_CAMPAIGN_ADD_DEVICE_MODEL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM,
  GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_SUBCHILD,
  GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_FINALVAL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_FINALVAL,
  GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_SUBCHILD,
  GOOGLE_CAMPAIGN_ADD_CAMPAIGN_GOAL,
  GOOGLE_CAMPAIGN_ADD_CONVERSION_GOAL,
  GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_TARGET_COST_PER_ACTION_VAL,
  GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_LANG_ENABLE_DESABLE_STATUS,
  GOOGLE_CAMPAIGN_ADD_LANGAUGES,
  GOOGLE_CAMPAIGN_ADD_DEVICE_TYPE,
  GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_BUDGET_AMOUNT,
  GOOGLE_CAMPAIGN_RESET_FORM,
  GOOGLE_CAMPAIGN_PRODUCT_MERCHENT_CENTER,
  GOOGLE_CAMPAIGN_PRODUCT_FEED,
  GOOGLE_CAMPAIGN_ADD_NETWORKS,
  GOOGLE_CAMPAIGN_AD_ROTATION,
  GOOGLE_CAMPAIGN_CONTENTLABEL,
  GOOGLE_CAMPAIGN_CONTENTTYPE,
  GOOGLE_CAMPAIGN_SENSTIVECONTENT,
  GOOGLE_CAMPAIGN_CAMPAIGN_SUBTYPE_SHOPPING,
  GOOGLE_CAMPAIGN_CAMPAIGN_INVENTORY_FILTER_SHOPPING,
  GOOGLE_CAMPAIGN_CAMPAIGN_LOCAL_PRODUCTS_SHOPPING,
  GOOGLE_CAMPAIGN_CAMPAIGN_CAMPAIGN_PRIORITY_SHOPPING,
  GOOGLE_CAMPAIGN_LOCATION_TYPE,
  GOOGLE_CAMPAIGN_AD_SPECIFIC_TARGETTING_DEVICES,
  GOOGLE_CAMPAIGN_APP_PLATFORM_APP,
  GOOGLE_CAMPAIGN_APP_NAME_APP,
  GOOGLE_CAMPAIGN_BIDDING_CPI_COST,
  GOOGLE_CAMPAIGN_BIDDING_CPPR_COST,
  GOOGLE_CAMPAIGN_IS_DATA_FEED_APP,
  GOOGLE_CAMPAIGN_DATA_FEED_APP_TYPE,
  GOOGLE_CAMPAIGN_GET_CAMPAIGN_DATA,
  GOOGLE_CAMPAIGN_LANGUAGE,
  GOOGLE_CAMPAIGN_AUD,
  GOOGLE_CAMPAIGN_BIDDING_CPC_TARGET,
  GOOGLE_CAMPAIGN_CAMPAIGN_URL_ON_PERFORMANCE,
} from './CreateCampaignActionTypes';

export const googleObjectiveList = {
  Sales: {
    objective_id: 1,
    objective_name: 'Sales',
  },
  Leads: {
    objective_id: 2,
    objective_name: 'Leads',
  },
  ['Website Traffic']: {
    objective_id: 3,
    objective_name: 'Website Traffic',
  },
  ['Product and brand consideration']: {
    objective_id: 4,
    objective_name: 'Product and brand consideration',
  },
  ['Brand awareness and reach']: {
    objective_id: 5,
    objective_name: 'Brand awareness and reach',
  },
  ['App promotion']: {
    objective_id: 6,
    objective_name: 'App promotion',
  },
  ['Local store visits and promotion']: {
    objective_id: 7,
    objective_name: 'Local store visits and promotion',
  },
};

export const googleCampaignTypeList = {
  Search: {
    campaign_type_id: 1,
    campaign_type_name: 'Search',
  },
  ['Performance Max']: {
    campaign_type_id: 2,
    campaign_type_name: 'Performance Max',
  },
  Display: {
    campaign_type_id: 3,
    campaign_type_name: 'Display',
  },
  Shopping: {
    campaign_type_id: 4,
    campaign_type_name: 'Shopping',
  },
  Video: {
    campaign_type_id: 5,
    campaign_type_name: 'Video',
  },
  ['Demand Gen']: {
    campaign_type_id: 6,
    campaign_type_name: 'Demand Gen',
  },
  App: {
    campaign_type_id: 7,
    campaign_type_name: 'App',
  },
};

let initialState = {
  googleCampaignid: '',
  googleCampaignid: '',
  objective_id: googleObjectiveList?.Sales?.objective_id,
  // campaign_type_id: googleCampaignTypeList?.Search?.campaign_type_id,
  campaign_type_id: '',
  isdraftEnabled: true,
  campaign_name: '',
  Merchent_center_account: '',
  Merchent_center_selected_account_details: [],
  appList: [],
  campaignLocationType: '',
  taxonomy_campaign_id: '',
  websiteUrlForDisplay: '',
  campaignSubType: '',
  inventory_filter: '',
  local_product: '',
  campaign_priority: '',
  mobileAppPlatformForApp: '',
  lookupApp: '',
  isDataFeedForApp: false,
  DataFeedTypeForApp: '',
  automaticallyCreatedAssetText: false,
  automaticallyCreatedAssetFinalUrl: false,
  isactive: true,
  created_by: 23,
  // modified_by: 0,
  google_Goalinfo: {
    isWebsiteVisit: false,
    isPhoneCalls: false,
    isStoreVisits: false,
    isAppDownloads: false,
    isLeadFormSubmission: false,
    websiteUrl: '',
    phoneNumber: '',
    countryCode: '91',
    storeLocation: '',
    mobileAppPlatform: '',
    lookupApp: '',
    googleCampaignId: 0,
  },

  google_Binding: {
    bidStrategy: '',
    setTargetCostPerAction: false,
    targetRoas: null,
    targetCpa: null,
    maximumCpcBidLimit: false,
    maximumCpcBidLimitValue: null,
    increaseConversionsWithEnhancedCpc: false,
    optimizeForConversions: false,
    adLocation: null,
    impressionShareToTarget: null,
    //googleCampaignId: 0
    wayOfGettingConversions: '',
    payForType: '',
    viewableCpmValue: '',
    userTypeWantToTarget: '',
    setTargetCostPerInstall: false,
    targetCostPerInstall: null,
    targetCostPerPreRegistration: null,
    mostImportantActions: [],
    customerAcquisition: {
      optimizeCampaignAcquiringNewCustomers: false,
      bidHigherForNewCustomers: true,
      bidForNewCustomersOnly: false,
    },
  },

  google_Campaignsettings: {
    // google_campaign_settingsid: "96",
    taxonomy_campaign_id: 96,
    // campaign_id: "1",
    network_id: '',
    location: 'India',
    location_tartget_id: 'abc',
    language: [],
    languageSelected: [],
    languageList: [],
    audience_segments_id: [],
    audience_segments_List: [],
    merchentCenterAccountList: [],
    audience_tartget_id: 'abc',
    status: 'Draft',
    broad_match_keywords: true,
    add_rotation_value: true,
    startDate: null,
    endDate: null,
    schedule: [
      {
        scheduleDays: 'Monday',
        scheduleStartTime: null,
        scheduleEndTime: null,
      },
    ],
    brand_restricted: [],
    brandRestrictedList: [],
    BrandRestrictedFromGetApi: [],
    campaignBudget: null,
    created_on: new Date(),
    created_by: 23,
  },
  google_Campaign_Url_Data: {
    tracking_templates: '',
    suffix: '',
    cusInfo: [
      {
        param_name: '',
        param_value: '',
      },
    ],
    created_on: '2023-10-09T07:42:26.997Z',
    created_by: 23,
  },
  // Props added by Prashant start
  setSelectedOperatingSystem: '',
  getOperatingSystems: [],
  getAllOperatingSystem: [],
  getAllDeviceModels: [],
  getOperatingSystemsMinorOptions: [],
  getOperatingSystemsMajorOptions: [],
  getSubDeviceOptions: [],
  getSubDeviceAllOptions: [],
  getFinalDeviceOptions: [],
  getDeviceModelsLists: [],
  getNetworks: [],
  devicesState: [
    {
      selectedOperatingSystem: null,
      selectedMinimumValue: null,
      selectedMaximumValue: null,
    },
  ],
  devicesModelState: [
    {
      selectedDeviceModel: null,
      selectedSubChild: null,
      selectedFinalChild: null,
      googleSalesDevicesId: 0,
    },
  ],
  productFeeds: false,
  productFeedSelectedAccount: '',
  campaignGoal: null,
  conversionGoalId: null,
  targetCostPerClick: null,
  campaignLevelLocationLanguage: false,
  selectedLanguages: [],
  DeviceType: '',
  operatingSystemId: [],
  deviceId: [],
  budgetType: 'Daily',
  networkId: [],
  networkIdObj: [],
  // Props added by Prashant end
  // for content exclustion below 3 keys added Dipak G
  contentLabel: [],
  contentType: [],
  senstiveContent: [],
  specificTargetingDevices: ['COMPUTERS', 'MOBILE', 'TABLET', 'CONNECTED_TV'],
};

export const googleCreateCampaign = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case CAMPAIGN_OBJECTIVE_AND_TYPE: {
      const { name, value } = payload?.target;
      return { ...state, [name]: value };
    }
    case CAMPAIGN_GOAL_CHECKBOX: {
      const { name, checked } = payload?.target;
      // console.log("payload", checked);
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if (name == item?.goal_name) return { ...item, isChecked: checked };
      //   return item;
      // });
      return {
        ...state,
        google_Goalinfo: { ...state?.google_Goalinfo, [name]: checked },
      };
    }
    case CAMPAIGN_GOAL_WEBSITE_VISITS: {
      const { name, value } = payload?.target;
      // console.log("payload", value);
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if (name == item?.goal_name) return { ...item, website_url: value };
      //   return item;
      // });
      return {
        ...state,
        google_Goalinfo: { ...state?.google_Goalinfo, websiteUrl: value },
      };
    }
    case CAMPAIGN_GOAL_PHONE_CALLS: {
      const { name, value } = payload?.target;
      // console.log("payload", value);
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if (name == item?.goal_name) return { ...item, mobile_number: value };
      //   return item;
      // });
      return {
        ...state,
        google_Goalinfo: { ...state?.google_Goalinfo, phoneNumber: value },
      };
    }
    case CAMPAIGN_GOAL_APP_PLATFORM: {
      const { name, value } = payload?.target;
      // console.log("payloaddd",name, value)
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if (name == item?.goal_name) {
      //     if (value == 'android')
      //       return {
      //         ...item,
      //         mobile_android: true,
      //         mobile_ios: false,
      //         mobile_application_name: '',
      //       };
      //     else if (value == 'ios')
      //       return {
      //         ...item,
      //         mobile_android: false,
      //         mobile_ios: true,
      //         mobile_application_name: '',
      //       };
      //   }
      //   return item;
      // });
      return {
        ...state,
        google_Goalinfo: {
          ...state?.google_Goalinfo,
          mobileAppPlatform: value,
          lookupApp: '',
        },
      };
    }
    case CAMPAIGN_LIST: {
      // console.log("payload", payload);
      return { ...state, googleCampaignList: payload };
    }
    // case GOOGLE_CAMPAIGN_BRAND_RESTRICTED_LIST: {
    //   // console.log("payload", payload);
    //   return { ...state, brandRestrictedList: payload };
    // }
    case GOOGLE_CAMPAIGN_BRAND_RESTRICTED: {
      // console.log("payload", payload);
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          brand_restricted: payload,
        },
      };
    }
    case GOOGLE_CAMPAIGN_LANGUAGE: {
      // console.log("payload", payload);
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          languageSelected: payload,
        },
      };
    }
    case GOOGLE_CAMPAIGN_AUD: {
      // console.log("payload", payload);
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          audience_segments_id: payload,
        },
      };
    }
    case GOOGLE_CAMPAIGN_TRACKING_TEMPLATE: {
      // console.log("payload", payload);
      return { ...state, trackingTemplateList: payload };
    }
    case GOOGLE_CAMPAIGN_CAMPAIGN_SUBTYPE_SHOPPING: {
      // console.log("payload", payload);
      return { ...state, campaignSubType: payload };
    }
    case GOOGLE_CAMPAIGN_CAMPAIGN_INVENTORY_FILTER_SHOPPING: {
      // console.log("payload", payload);
      return { ...state, inventory_filter: payload };
    }
    case GOOGLE_CAMPAIGN_CAMPAIGN_LOCAL_PRODUCTS_SHOPPING: {
      // console.log("payload", payload);
      return { ...state, local_product: payload };
    }
    case GOOGLE_CAMPAIGN_CAMPAIGN_CAMPAIGN_PRIORITY_SHOPPING: {
      // console.log("payload", payload);
      return { ...state, campaign_priority: payload };
    }

    case CAMPAIGN_GOAL_APP_NAME: {
      let value;
      // console.log("payload",payload)
      if (payload) {
        value = payload?.value;
      }
      // console.log("value",value)
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if ('App downloads' == item?.goal_name)
      //     return { ...item, mobile_application_name: value };
      //   return item;
      // });
      return {
        ...state,
        google_Goalinfo: { ...state?.google_Goalinfo, lookupApp: value },
      };
    }
    case GOOGLE_CAMPAIGN_APP_NAME_APP: {
      let value;
      // console.log("payload",payload)
      if (payload) {
        value = payload?.value;
      }
      // console.log("value",value)
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if ('App downloads' == item?.goal_name)
      //     return { ...item, mobile_application_name: value };
      //   return item;
      // });
      return {
        ...state,
        lookupApp: value,
      };
    }
    case GOOGLE_CAMPAIGN_APP_PLATFORM_APP: {
      let value;
      // console.log("payload",payload)
      if (payload) {
        value = payload?.target?.value;
      }
      // console.log("value",value)
      // let arr = state?.google_Goalinfo.map((item) => {
      //   if ('App downloads' == item?.goal_name)
      //     return { ...item, mobile_application_name: value };
      //   return item;
      // });
      return {
        ...state,
        mobileAppPlatformForApp: value,
      };
    }

    case GOOGLE_CAMPAIGN_CAMPAIGN_NAME: {
      // console.log("cam_name", payload);
      let data = payload;
      let obj = state?.google_Campaign_Url_Data;

      return {
        ...state,
        campaign_name: payload?.label,
        taxonomy_campaign_id: payload?.value,
        google_Campaign_Url_Data: {
          ...obj,
          tracking_templates: '',
        },
      };
    }
    case GOOGLE_CAMPAIGN_MERCHENT_CENTER_ACCOUNT: {
      let data = payload;
      let obj = state?.google_Campaign_Url_Data;

      return {
        ...state,
        Merchent_center_account: payload?.value,
        Merchent_center_selected_account_details: payload,
      };
    }
    case GOOGLE_CAMPAIGN_LOCATION_TYPE: {
      return {
        ...state,
        campaignLocationType: payload?.value,
      };
    }
    case GOOGLE_CAMPAIGN_TEXT_ASSET_CHECK: {
      const { checked } = payload?.target;
      return {
        ...state,
        automaticallyCreatedAssetText: checked,
      };
    }
    case GOOGLE_CAMPAIGN_FINAL_URL_CHECK: {
      const { checked } = payload?.target;
      return {
        ...state,
        automaticallyCreatedAssetFinalUrl: checked,
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_FOCUS: {
      let value, name;
      if (payload) {
        name = payload?.name;
        value = payload?.data?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        setTargetCostPerAction: false,
        targetRoas: null,
        targetCpa: null,
        maximumCpcBidLimit: false,
        maximumCpcBidLimitValue: null,
        increaseConversionsWithEnhancedCpc: false,
        optimizeForConversions: false,
        adLocation: null,
        impressionShareToTarget: null,
        //googleCampaignId: 0
        wayOfGettingConversions: '',
        payForType: '',
        viewableCpmValue: '',
        userTypeWantToTarget: '',
        setTargetCostPerInstall: false,
        targetCostPerInstall: null,
        targetCostPerPreRegistration: null,
        mostImportantActions: [],
        customerAcquisition: {
          optimizeCampaignAcquiringNewCustomers: false,
          bidHigherForNewCustomers: true,
          bidForNewCustomersOnly: false,
        },
      };
      return { ...state, google_Binding: { ...obj, bidStrategy: value } };
    }
    case GOOGLE_CAMPAIGN_WAYS_TO_GET_CONVERSIONS: {
      let value, name;
      if (payload) {
        name = payload?.name;
        value = payload?.data?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        setTargetCostPerAction: false,
        targetRoas: null,
        targetCpa: null,
        maximumCpcBidLimit: false,
        maximumCpcBidLimitValue: null,
        increaseConversionsWithEnhancedCpc: false,
        optimizeForConversions: false,
        adLocation: null,
        impressionShareToTarget: null,
        //googleCampaignId: 0
        wayOfGettingConversions: '',
        payForType: '',
        viewableCpmValue: '',
        userTypeWantToTarget: '',
        setTargetCostPerInstall: false,
        targetCostPerInstall: null,
        targetCostPerPreRegistration: null,
        mostImportantActions: [],
        customerAcquisition: {
          optimizeCampaignAcquiringNewCustomers: false,
          bidHigherForNewCustomers: true,
          bidForNewCustomersOnly: false,
        },
      };
      return {
        ...state,
        google_Binding: { ...obj, wayOfGettingConversions: value },
      };
    }
    case GOOGLE_CAMPAIGN_PAY_FOR_TYPE: {
      let value, name;
      if (payload) {
        name = payload?.name;
        value = payload?.data?.value;
      }
      let obj = state?.google_Binding;

      return { ...state, google_Binding: { ...obj, payForType: value } };
    }
    case GOOGLE_CAMPAIGN_VIEWABLE_CPM: {
      let value, name;
      if (payload) {
        // name = payload?.name;
        value = payload?.target?.value;
      }
      let obj = state?.google_Binding;

      return { ...state, google_Binding: { ...obj, viewableCpmValue: value } };
    }

    case GOOGLE_CAMPAIGN_CPA_CHECKBOX_HANDLER: {
      const { checked } = payload?.target;
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        setTargetCostPerAction: false,
        targetRoas: null,
        targetCpa: null,
        maximumCpcBidLimit: false,
        maximumCpcBidLimitValue: null,
        increaseConversionsWithEnhancedCpc: false,
        optimizeForConversions: false,
        adLocation: null,
        impressionShareToTarget: null,
        //googleCampaignId: 0
        // wayOfGettingConversions: "",
        payForType: '',
        viewableCpmValue: '',
        userTypeWantToTarget: '',
        setTargetCostPerInstall: false,
        targetCostPerInstall: null,
        targetCostPerPreRegistration: null,
        mostImportantActions: [],
        customerAcquisition: {
          optimizeCampaignAcquiringNewCustomers: false,
          bidHigherForNewCustomers: true,
          bidForNewCustomersOnly: false,
        },
      };
      return {
        ...state,
        google_Binding: { ...obj, setTargetCostPerAction: checked },
      };
    }
    case GOOGLE_CAMPAIGN_OPTIMIZE_CAMPAIGN_CHECK: {
      const { checked } = payload?.target;
      console.log('checked', checked);
      let obj = state?.google_Binding;

      obj = {
        ...obj,
        customerAcquisition: {
          optimizeCampaignAcquiringNewCustomers: checked,
          bidHigherForNewCustomers: true,
          bidForNewCustomersOnly: false,
        },
      };

      return {
        ...state,
        google_Binding: {
          ...obj,
          customerAcquisition: {
            optimizeCampaignAcquiringNewCustomers: checked,
            bidHigherForNewCustomers: true,
            bidForNewCustomersOnly: false,
          },
        },
      };
    }
    case GOOGLE_CAMPAIGN_BID_HIGHER_FOR_NEW_CUSTOMERS_CHECK: {
      const { checked } = payload?.target;
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        // customerAcquisition: {
        //   optimizeCampaignAcquiringNewCustomers: false,
        //   bidHigherForNewCustomers: false,
        //   bidForNewCustomersOnly: false
        // }
      };
      let cusAcquisition = obj?.customerAcquisition;
      return {
        ...state,
        google_Binding: {
          ...obj,
          customerAcquisition: {
            ...cusAcquisition,
            bidHigherForNewCustomers: checked,
            bidForNewCustomersOnly: !checked,
          },
        },
      };
    }
    case GOOGLE_CAMPAIGN_BID_ONLY_FOR_NEW_CUSTOMERS_CHECK: {
      const { checked } = payload?.target;
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        // customerAcquisition: {
        //   optimizeCampaignAcquiringNewCustomers: false,
        //   bidHigherForNewCustomers: false,
        //   bidForNewCustomersOnly: false
        // }
      };
      let cusAcquisition = obj?.customerAcquisition;
      return {
        ...state,
        google_Binding: {
          ...obj,
          customerAcquisition: {
            ...cusAcquisition,
            bidHigherForNewCustomers: !checked,
            bidForNewCustomersOnly: checked,
          },
        },
      };
    }
    case GOOGLE_CAMPAIGN_ROAS_CHECKBOX_HANDLER: {
      const { checked } = payload?.target;
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        setTargetCostPerAction: false,
        targetRoas: null,
        targetCpa: null,
        maximumCpcBidLimit: false,
        maximumCpcBidLimitValue: null,
        increaseConversionsWithEnhancedCpc: false,
        optimizeForConversions: false,
        adLocation: null,
        impressionShareToTarget: null,
        //googleCampaignId: 0
        wayOfGettingConversions: '',
        payForType: '',
        viewableCpmValue: '',
        userTypeWantToTarget: '',
        setTargetCostPerInstall: false,
        targetCostPerInstall: null,
        targetCostPerPreRegistration: null,
        mostImportantActions: [],
        customerAcquisition: {
          optimizeCampaignAcquiringNewCustomers: false,
          bidHigherForNewCustomers: true,
          bidForNewCustomersOnly: false,
        },
      };
      return {
        ...state,
        google_Binding: { ...obj, setTargetCostPerAction: checked },
      };
    }
    case GOOGLE_CAMPAIGN_CPC_CHECKBOX_HANDLER: {
      const { checked } = payload?.target;
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        setTargetCostPerAction: false,
        targetRoas: null,
        targetCpa: null,
        maximumCpcBidLimit: false,
        maximumCpcBidLimitValue: null,
        increaseConversionsWithEnhancedCpc: false,
        optimizeForConversions: false,
        adLocation: null,
        impressionShareToTarget: null,
        //googleCampaignId: 0
        wayOfGettingConversions: '',
        payForType: '',
        viewableCpmValue: '',
        userTypeWantToTarget: '',
        setTargetCostPerInstall: false,
        targetCostPerInstall: null,
        targetCostPerPreRegistration: null,
        mostImportantActions: [],
        customerAcquisition: {
          optimizeCampaignAcquiringNewCustomers: false,
          bidHigherForNewCustomers: true,
          bidForNewCustomersOnly: false,
        },
      };
      return {
        ...state,
        google_Binding: { ...obj, maximumCpcBidLimit: checked },
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_TARGET_COST_CHECKBOX: {
      const { checked } = payload?.target;
      let obj = state?.google_Binding;
      obj = {
        ...obj,
        setTargetCostPerAction: false,
        adLocation: null,
        impressionShareToTarget: null,
        targetCpa: null,
        targetRoas: null,
        maximumCpcBidLimitValue: null,
      };
      return {
        ...state,
        google_Binding: { ...obj, setTargetCostPerAction: checked },
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_CPC_TARGET: {
      let name = '',
        value;
      if (payload) {
        name = payload?.target?.name;
        value = payload?.target?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
      };
      if (name == 'maximumCpcBidLimitValue') {
        return {
          ...state,
          google_Binding: { ...obj, [name]: value },
        };
      }

      return {
        ...state,
        google_Binding: { ...obj, [name]: value, setTargetCostPerAction: true },
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_CPA_COST: {
      let name = '',
        value;
      if (payload) {
        name = payload?.target?.name;
        value = payload?.target?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
      };
      if (name == 'maximumCpcBidLimitValue') {
        return {
          ...state,
          google_Binding: { ...obj, [name]: value },
        };
      }

      return {
        ...state,
        google_Binding: { ...obj, [name]: value, setTargetCostPerAction: true },
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_CPI_COST: {
      let name = '',
        value;
      if (payload) {
        name = payload?.target?.name;
        value = payload?.target?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
      };

      return {
        ...state,
        google_Binding: {
          ...obj,
          targetCostPerInstall: value,
          setTargetCostPerInstall: true,
        },
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_CPPR_COST: {
      let name = '',
        value;
      if (payload) {
        name = payload?.target?.name;
        value = payload?.target?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
      };

      return {
        ...state,
        google_Binding: { ...obj, targetCostPerPreRegistration: value },
      };
    }
    case GOOGLE_CAMPAIGN_BIDDING_AD_LOCATION: {
      let value;
      if (payload) {
        value = payload?.value;
      }
      let obj = state?.google_Binding;
      obj = {
        ...obj,
      };
      return { ...state, google_Binding: { ...obj, adLocation: value } };
    }

    case GOOGLE_CAMPAIGN_NETOWRK_ID: {
      let value = payload?.target?.value;
      let dataArr = state?.google_Campaignsettings?.network_id?.split(',');
      if (payload?.target?.checked) {
        value = payload?.target?.value;
        let index = dataArr.findIndex((item) => item == value);
        if (index == -1) dataArr.push(value);
      } else {
        dataArr.remove(value);
      }
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          network_id: dataArr.join(','),
        },
      };
    }
    case GOOGLE_CAMPAIGN_START_DATE: {
      // console.log("payloadx", payload);
      let { name, date } = payload;
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          [name]: date,
          endDate: null,
        },
      };
    }
    case GOOGLE_CAMPAIGN_END_DATE: {
      let { name, date } = payload;
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          [name]: date,
        },
      };
    }
    case GOOGLE_CAMPAIGN_IS_DATA_FEED_APP: {
      // let { name, date } = payload;
      return {
        ...state,
        isDataFeedForApp: payload,
      };
    }
    case GOOGLE_CAMPAIGN_DATA_FEED_APP_TYPE: {
      // let { name, date } = payload;
      return {
        ...state,
        DataFeedTypeForApp: payload,
      };
    }
    case GOOGLE_CAMPAIGN_ADD_SCHEDULE: {
      let data = {
        scheduleDays: 'Monday',
        scheduleStartTime: null,
        scheduleEndTime: null,
      };
      let schedules = state?.google_Campaignsettings?.schedule;
      schedules.push(data);
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          schedule: schedules,
        },
      };
    }
    case GOOGLE_CAMPAIGN_AD_ROTATION: {
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          add_rotation_value: payload?.target?.value,
        },
      };
    }

    case GOOGLE_CAMPAIGN_ADD_SCHEDULE_SELECT_DAYS: {
      let index = payload?.index;
      let schedules = state?.google_Campaignsettings?.schedule;
      let selected_day = payload?.selected_day;
      let item = state?.google_Campaignsettings?.schedule[index];
      item = { ...item, scheduleDays: selected_day?.value };
      schedules = [
        ...schedules.slice(0, index),
        item,
        ...schedules.slice(index + 1),
      ];
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          schedule: schedules,
        },
      };
    }
    case GOOGLE_CAMPAIGN_ADD_SCHEDULE_TIME: {
      let index = payload?.index;
      let schedules = state?.google_Campaignsettings?.schedule;
      let timeType = payload?.timeType;
      let time = payload?.time;
      let item = state?.google_Campaignsettings?.schedule[index];
      if (timeType == 'start') {
        item = { ...item, scheduleStartTime: time };
      } else if (timeType == 'end') {
        item = { ...item, scheduleEndTime: time };
      }

      schedules = [
        ...schedules.slice(0, index),
        item,
        ...schedules.slice(index + 1),
      ];
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          schedule: schedules,
        },
      };
    }
    case GOOGLE_CAMPAIGN_REMOVE_SCHEDULE: {
      let index = payload?.index;
      let schedules = state?.google_Campaignsettings?.schedule;

      schedules = [...schedules.slice(0, index), ...schedules.slice(index + 1)];
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          schedule: schedules,
        },
      };
    }

    case GOOGLE_CAMPAIGN_CAMPAIGN_URL: {
      let value, name;
      let data = payload?.data?.target;
      if (data) {
        name = data?.name;
        value = data?.value;
      }
      console.log('check GOOGLE_CAMPAIGN_CAMPAIGN_URL', name, value);
      let obj = state?.google_Campaign_Url_Data;
      return { ...state, google_Campaign_Url_Data: { ...obj, [name]: value } };
    }

    case GOOGLE_CAMPAIGN_CAMPAIGN_URL_ON_PERFORMANCE: {
      let value, name;
      let data = payload?.data?.target;
      if (data) {
        name = data?.name;
        value = data?.value;
      }
      let obj = state?.google_Campaign_Url_Data;
      return {
        ...state,
        google_Campaign_Url_Data: { ...obj, [payload?.key]: payload?.value },
      };
    }

    case GOOGLE_CAMPAIGN_CAMPAIGN_URL_TEMPLATE: {
      let data = payload;
      let obj = state?.google_Campaign_Url_Data;
      return {
        ...state,
        google_Campaign_Url_Data: {
          ...obj,
          tracking_templates: data?.data?.label,
        },
      };
    }

    case GOOGLE_CAMPAIGN_BUDGET: {
      let value, name;
      let data = payload?.target;
      if (data) {
        name = data?.name;
        value = data?.value;
      }
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          [name]: value,
        },
      };
    }

    case GOOGLE_CAMPAIGN_ADD_PARAM: {
      let data = {
        param_name: '',
        param_value: '',
      };
      let params = state?.google_Campaign_Url_Data?.cusInfo;
      params.push(data);
      // console.log("params", params);
      return {
        ...state,
        google_Campaign_Url_Data: {
          ...state?.google_Campaign_Url_Data,
          cusInfo: params,
        },
      };
    }

    case GOOGLE_CAMPAIGN_CUS_PARAM: {
      let value, name;
      let index = payload?.index;
      let type = payload?.type;
      let data = payload?.data?.target;
      if (data) {
        name = data?.name;
        value = data?.value;
      }
      // let schedules = state?.google_Campaignsettings?.schedule;
      let params = state?.google_Campaign_Url_Data?.cusInfo;
      let item = state?.google_Campaign_Url_Data?.cusInfo[index];
      // let item = state?.google_Campaignsettings?.schedule[index]
      if (type == 'param') item = { ...item, param_name: value };
      else if (type == 'value') item = { ...item, param_value: value };
      params = [...params.slice(0, index), item, ...params.slice(index + 1)];
      return {
        ...state,
        google_Campaign_Url_Data: {
          ...state?.google_Campaign_Url_Data,
          cusInfo: params,
        },
      };
    }
    case GOOGLE_CAMPAIGN_GET_MASTER_API: {
      if (payload) {
        let languages = payload?.languages;
        let audienceSegments = payload?.audienceSegments;
        let operatingSystem = payload?.operatingSystems;
        let deviceModels = payload?.devices;
        let conversionGoals = payload?.conversionGoals;
        let merchentCenterAccount = payload?.merchentCenterAccount;
        let network = payload?.network;
        let brandRestrictedList = payload?.brandRestriction;
        let appList = payload?.apps;
        let defaultVal = [
          {
            label: 'All',
            value: 'all',
          },
        ];
        languages = languages?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        brandRestrictedList = brandRestrictedList?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        audienceSegments = audienceSegments?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });

        operatingSystem = operatingSystem?.map((item) => {
          return {
            label: item?.name,
            value: item?.name,
          };
        });

        deviceModels = deviceModels?.map((item) => {
          return {
            label: item?.name,
            value: item?.name,
          };
        });

        conversionGoals = conversionGoals?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });

        merchentCenterAccount = merchentCenterAccount?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
            urlFinal: item?.urlFinal || '',
          };
        });

        network = network?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
        appList = appList?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });

        return {
          ...state,
          google_Campaignsettings: {
            ...state?.google_Campaignsettings,
            language: languages,
            languageList: languages,
            audience_segments_List: audienceSegments,
            merchentCenterAccountList: merchentCenterAccount,
            brandRestrictedList: brandRestrictedList,
          },
          getOperatingSystems: [...defaultVal, ...operatingSystem],
          getAllOperatingSystem: payload?.operatingSystems,
          getDeviceModelsLists: [...defaultVal, ...deviceModels],
          getAllDeviceModels: payload?.devices,
          conversionGoals: conversionGoals,
          merchentCenterAccount: merchentCenterAccount,
          network: network,
          appList: appList,
        };
      }
      return { ...state };
    }
    case GOOGLE_CAMPAIGN_SET_WEB_URL_DISPLAY: {
      return {
        ...state,
        websiteUrlForDisplay: payload,
      };
    }

    // Added by Prashant
    case GOOGLE_CAMPAIGN_SET_SELECTED_OPERATING_TYPE: {
      if (payload) {
        let getOperatingSystemFromOptions =
          state?.getAllOperatingSystem?.filter((v) => {
            return v?.name == payload;
          });

        let setCustomObjMinorFormt;
        let setCustomObjMajorFormt;

        if (payload != 'all') {
          setCustomObjMinorFormt = getOperatingSystemFromOptions?.[0][
            'minor'
          ]?.map((item) => {
            return {
              label: item?.version,
              value: item?.id,
            };
          });

          setCustomObjMajorFormt = getOperatingSystemFromOptions?.[0][
            'major'
          ]?.map((item) => {
            return {
              label: item?.version,
              value: item?.id,
            };
          });
        } else {
          setCustomObjMinorFormt = [];
          setCustomObjMajorFormt = [];
        }

        return {
          ...state,
          getOperatingSystemsMinorOptions: setCustomObjMinorFormt,
          getOperatingSystemsMajorOptions: setCustomObjMajorFormt,
          setSelectedOperatingSystem: payload,
        };
      } else {
        return {
          ...state,
          getOperatingSystemsMinorOptions: [],
          getOperatingSystemsMajorOptions: [],
          setSelectedOperatingSystem: '',
        };
      }
    }

    case GOOGLE_CAMPAIGN_ADD_OPERATING_SYSTEM: {
      let data = {
        selectedOperatingSystem: null,
        selectedMinimumValue: null,
        selectedMaximumValue: null,
      };

      let devicesStateValue = state?.devicesState;
      devicesStateValue.push(data);
      return {
        ...state,
        devicesState: devicesStateValue,
      };
    }

    // Device models
    case GOOGLE_CAMPAIGN_SET_SELECTED_DEVICE_MODEL: {
      if (payload) {
        let getDeviceFromOptions = state?.getAllDeviceModels?.filter((v) => {
          return v?.name == payload;
        });
        let getSubDevices = getDeviceFromOptions?.[0]?.['manufacturer']?.map(
          (item) => {
            return {
              label: item?.name,
              value: item?.name,
            };
          },
        );

        return {
          ...state,
          getSubDeviceOptions: getSubDevices,
          getSubDeviceAllOptions: getDeviceFromOptions?.[0]?.['manufacturer'],
        };
      } else {
        return {
          ...state,
          getSubDeviceOptions: [],
          getSubDeviceAllOptions: [],
          getFinalDeviceOptions: [],
        };
      }
    }

    case GOOGLE_CAMPAIGN_SET_SELECTED_FINAL_DEVICE: {
      if (payload) {
        let getFinalDeviceLists = state?.getSubDeviceAllOptions?.filter((v) => {
          return v?.name == payload;
        });

        let setFinalDeviceLists = getFinalDeviceLists?.[0]?.['devices']?.map(
          (item) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          },
        );

        return {
          ...state,
          getFinalDeviceOptions: setFinalDeviceLists,
        };
      } else {
        return {
          ...state,
          getFinalDeviceOptions: [],
        };
      }
    }

    case GOOGLE_CAMPAIGN_ADD_DEVICE_MODEL: {
      let data = {
        selectedDeviceModel: null,
        selectedSubChild: null,
        selectedFinalChild: null,
      };

      let devicesModelStateValue = state?.devicesModelState;
      devicesModelStateValue.push(data);
      return {
        ...state,
        devicesModelState: devicesModelStateValue,
      };
    }

    //Add Values to state in Operating system start

    case GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM: {
      let index = payload?.index;

      let selectedOS = state?.devicesState;
      let selected_os = payload?.selectedOperatingSystem;
      let item = state?.devicesState[index];
      item = { ...item, selectedOperatingSystem: selected_os?.value };
      selectedOS = [
        ...selectedOS.slice(0, index),
        item,
        ...selectedOS.slice(index + 1),
      ];
      return {
        ...state,
        devicesState: selectedOS,
      };
    }

    case GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_SUBCHILD: {
      let index = payload?.index;

      let selectedOSSubChild = state?.devicesState;
      let selected_os_subchild = payload?.selectedMinimumValue;
      let item = state?.devicesState[index];
      item = { ...item, selectedMinimumValue: selected_os_subchild?.value };
      selectedOSSubChild = [
        ...selectedOSSubChild.slice(0, index),
        item,
        ...selectedOSSubChild.slice(index + 1),
      ];
      return {
        ...state,
        devicesState: selectedOSSubChild,
      };
    }

    case GOOGLE_CAMPAIGN_ADD_SELECTED_OPERATING_SYSTEM_FINALVAL: {
      let index = payload?.index;

      let selectedOSFinalChild = state?.devicesState;
      let selected_os_finalchild = payload?.selectedMaximumValue;
      let item = state?.devicesState[index];
      let combineVal = [];
      item = { ...item, selectedMaximumValue: selected_os_finalchild?.value };
      selectedOSFinalChild = [
        ...selectedOSFinalChild.slice(0, index),
        item,
        ...selectedOSFinalChild.slice(index + 1),
      ];

      selectedOSFinalChild?.map((v) => {
        combineVal.push(v?.selectedMinimumValue?.toString());
        combineVal.push(v?.selectedMaximumValue?.toString());
      });

      return {
        ...state,
        devicesState: selectedOSFinalChild,
        operatingSystemId: combineVal,
      };
    }
    //Add Values to state in Operating system end
    //Add Values to state in Device model start
    case GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL: {
      let index = payload?.index;

      let selectedDM = state?.devicesModelState;
      let selected_os = payload?.selectedDeviceModel;
      let item = state?.devicesModelState[index];
      item = { ...item, selectedDeviceModel: selected_os?.value };
      selectedDM = [
        ...selectedDM.slice(0, index),
        item,
        ...selectedDM.slice(index + 1),
      ];
      return {
        ...state,
        devicesModelState: selectedDM,
      };
    }

    case GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_SUBCHILD: {
      let index = payload?.index;

      let selectedOSSubChild = state?.devicesModelState;
      let selected_os_subchild = payload?.selectedSubChild;
      let item = state?.devicesModelState[index];
      item = { ...item, selectedSubChild: selected_os_subchild?.value };
      selectedOSSubChild = [
        ...selectedOSSubChild.slice(0, index),
        item,
        ...selectedOSSubChild.slice(index + 1),
      ];
      return {
        ...state,
        devicesModelState: selectedOSSubChild,
      };
    }

    case GOOGLE_CAMPAIGN_ADD_SELECTED_DEVICE_MODEL_FINALVAL: {
      let index = payload?.index;

      let selectedOSFinalChild = state?.devicesModelState;
      let selected_os_finalchild = payload?.selectedFinalChild;
      let item = state?.devicesModelState[index];
      let combineVal = [];

      item = { ...item, selectedFinalChild: selected_os_finalchild?.value };
      selectedOSFinalChild = [
        ...selectedOSFinalChild.slice(0, index),
        item,
        ...selectedOSFinalChild.slice(index + 1),
      ];

      selectedOSFinalChild?.map((v) => {
        combineVal.push(v?.selectedFinalChild?.toString());
      });

      return {
        ...state,
        devicesModelState: selectedOSFinalChild,
        deviceId: combineVal,
      };
    }

    case GOOGLE_CAMPAIGN_ADD_CAMPAIGN_GOAL: {
      return { ...state, campaignGoal: payload };
    }

    case GOOGLE_CAMPAIGN_ADD_CONVERSION_GOAL: {
      return { ...state, conversionGoalId: payload };
    }

    case GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_TARGET_COST_PER_ACTION_VAL: {
      return { ...state, targetCostPerClick: payload };
    }

    case GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_LANG_ENABLE_DESABLE_STATUS: {
      return { ...state, campaignLevelLocationLanguage: payload };
    }

    case GOOGLE_CAMPAIGN_ADD_LANGAUGES: {
      let langArray = [];
      payload?.map((v) => {
        langArray.push(v?.value);
      });

      return {
        ...state,
        selectedLanguages: langArray,
      };
      // return { ...state, campaignLevelLocationLanguage: payload };
    }

    case GOOGLE_CAMPAIGN_ADD_DEVICE_TYPE: {
      return { ...state, DeviceType: payload };
    }

    case GOOGLE_CAMPAIGN_PRODUCT_FEED: {
      return { ...state, productFeeds: payload };
    }

    case GOOGLE_CAMPAIGN_PRODUCT_MERCHENT_CENTER: {
      return { ...state, productFeedSelectedAccount: payload };
    }

    case GOOGLE_CAMPAIGN_ADD_DEMAND_GEN_BUDGET_AMOUNT: {
      return {
        ...state,
        google_Campaignsettings: {
          ...state?.google_Campaignsettings,
          campaignBudget: payload,
        },
      };
    }

    case GOOGLE_CAMPAIGN_ADD_NETWORKS: {
      if (payload) {
        let newArry = [];
        payload?.map((v) => {
          newArry?.push(v?.value?.toString());
        });
        return { ...state, networkId: newArry, networkIdObj: payload };
      }
    }

    case GOOGLE_CAMPAIGN_RESET_FORM: {
      return initialState;
    }

    case GOOGLE_CAMPAIGN_CONTENTLABEL: {
      return {
        ...state,
        contentLabel: payload,
      };
    }

    case GOOGLE_CAMPAIGN_CONTENTTYPE: {
      return {
        ...state,
        contentType: payload,
      };
    }
    case GOOGLE_CAMPAIGN_SENSTIVECONTENT: {
      return {
        ...state,
        senstiveContent: payload,
      };
    }

    case GOOGLE_CAMPAIGN_AD_SPECIFIC_TARGETTING_DEVICES: {
      return {
        ...state,
        specificTargetingDevices: payload,
      };
    }
    //Add Values to state in Device model end
    case GOOGLE_CAMPAIGN_GET_CAMPAIGN_DATA: {
      let data = payload;

      let objective_id = data?.objectiveId;
      let campaign_type_id = data?.campaignTypeId;

      // alert(campaign_type_id)
      //switch case to handle update campaign based on objective type and campaign type

      switch (`${objective_id}`) {
        case `${googleObjectiveList?.Sales?.objective_id}`: //sales
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
                {
                  // google_Campaignsettings: {
                  //   ...state?.google_Campaignsettings,
                  //   network_id: dataArr.join(','),
                  // }
                  let network_id = '';

                  if (data?.campaignSetting?.isSearchNetwork)
                    network_id += 'Search,';
                  if (data?.campaignSetting?.isDisplayNetwork)
                    network_id += 'Display,';
                  // isSearchNetwork,isDisplayNetwork,campaignSetting

                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });

                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    // campaignName
                    campaign_name: data?.campaignName,
                    googleCampaignid: data?.googleCampaignid,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    google_Goalinfo: data?.googleCampaignGoalReachTypes?.[0],
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      brand_restricted:
                        data?.campaignSetting?.brandsRestricted?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.branListName,
                            value: item?.brandRestrictionId,
                          };
                        }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                      audience_segments_id:
                        data?.campaignSetting?.audience?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.name,
                            value: item?.googleAudienceSegmentsid,
                          };
                        }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              case `${googleCampaignTypeList?.['Performance Max']?.campaign_type_id}`: //performance max
                let schedule = data?.campaignSetting?.googleAdSchedules;
                schedule = schedule?.map((item) => {
                  return {
                    scheduleDays: item?.scheduleDays,
                    scheduleStartTime: item?.scheduleStartTime,
                    scheduleEndTime: item?.scheduleEndTime,
                  };
                });
                {
                  return {
                    ...state,
                    googleCampaignid: data?.googleCampaignid || '',
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                      customerAcquisition: {
                        optimizeCampaignAcquiringNewCustomers:
                          data?.campaignBidding?.customerAcquisition
                            ?.optimizeCampaignAcquiringNewCustomers || false,
                        bidHigherForNewCustomers:
                          data?.campaignBidding?.customerAcquisition
                            ?.bidHigherForNewCustomers,
                        bidForNewCustomersOnly:
                          data?.campaignBidding?.customerAcquisition
                            ?.bidForNewCustomersOnly,
                      },
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      // network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      brand_restricted:
                        data?.campaignSetting?.brandsRestricted?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.branListName,
                            value: item?.brandRestrictionId,
                          };
                        }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),

                      // audience_segments_id: data?.campaignSetting?.audience?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.name,
                      //     value:item?.googleAudienceSegmentsid
                      //   }
                      // }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    automaticallyCreatedAssetText:
                      data?.campaignSetting?.automaticallyCreatedAssetText,
                    automaticallyCreatedAssetFinalUrl:
                      data?.campaignSetting?.automaticallyCreatedAssetFinalUrl,
                    Merchent_center_account: data?.merchantCenterAccount,
                    dipak: true,
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand Gen
                {
                  let selectedMer =
                    data?.campaignSetting?.productFeedAccount?.map((item) => {
                      return {
                        label: item?.googleMerchentCenterAccountName,
                        value: item?.googleMerchentCenterAccountId,
                      };
                    });

                  //Network ids
                  let selectedNetworkIds = data?.campaignSetting?.network?.map(
                    (item) => {
                      return {
                        label: item?.networkName,
                        value: item?.googleNetworkid,
                      };
                    },
                  );

                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });

                  //Model Device
                  let devicesModelState = data?.campaignSetting?.devices;
                  devicesModelState = devicesModelState?.map((item) => {
                    return {
                      selectedDeviceModel: item?.operatingSystemName,
                      selectedSubChild: item?.manufacturerName,
                      selectedFinalChild: item?.name,
                      googleSalesDevicesId: item?.googleSalesDevicesId,
                    };
                  });
                  //Model Devices Ids
                  let deviceId = [];
                  data?.campaignSetting?.devices?.map((item) => {
                    deviceId.push(item?.googleSalesDevicesId);
                  });

                  return {
                    ...state,
                    googleCampaignid: data?.googleCampaignid || '',
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    productFeeds: data?.campaignSetting?.productFeeds,
                    Merchent_center_selected_account_details: selectedMer,
                    targetCostPerClick:
                      data?.campaignSetting?.targetCostPerClick,
                    campaignLevelLocationLanguage:
                      data?.campaignSetting?.campaignLevelLocationLanguage,
                    DeviceType: data?.campaignSetting?.deviceType,
                    networkId: selectedNetworkIds,
                    networkIdObj: selectedNetworkIds,
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      schedule: schedule,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                    },
                    // devicesState: devicesState,
                    devicesModelState: devicesModelState,
                    deviceId: deviceId,
                    campaignGoal: data?.campaignSetting?.campaignGoal || '',
                    conversionGoalId:
                      data?.campaignSetting?.conversionGoalId || '',
                    campaignLocationType: data?.campaignLocationType || '',
                    productFeedSelectedAccount: selectedMer[0]?.value,
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              case `${googleCampaignTypeList?.['Shopping']?.campaign_type_id}`: //shopping
                {
                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });
                  {
                    return {
                      ...state,
                      campaign_priority:
                        data?.campaignSetting?.campaignPriority,
                      googleCampaignid: data?.googleCampaignid || '',
                      objective_id: data?.objectiveId,
                      campaign_type_id: data?.campaignTypeId,
                      campaign_name: data?.campaignName,
                      taxonomy_campaign_id:
                        data?.campaignSetting?.taxonomyCampaignId,
                      google_Binding: {
                        ...state?.google_Binding,
                        bidStrategy: data?.campaignBidding?.bidStrategy,
                        setTargetCostPerAction:
                          data?.campaignBidding?.setTargetCostPerAction,
                        setTargetReturnOnAdSpend:
                          data?.campaignBidding?.setTargetReturnOnAdSpend,
                        targetRoas: data?.campaignBidding?.targetRoas,
                        targetCpa: data?.campaignBidding?.targetCpa,
                        maximumCpcBidLimit:
                          data?.campaignBidding?.maximumCpcBidLimit,
                        maximumCpcBidLimitValue:
                          data?.campaignBidding?.maximumCpcBidLimitValue,
                        adLocation: data?.campaignBidding?.adLocation,
                        impressionShareToTarget:
                          data?.campaignBidding?.impressionShareToTarget,
                        viewableCpmValue:
                          data?.campaignBidding?.viewableCpmValue,
                        customerAcquisition: {
                          optimizeCampaignAcquiringNewCustomers:
                            data?.campaignBidding?.customerAcquisition
                              ?.optimizeCampaignAcquiringNewCustomers || false,
                          bidHigherForNewCustomers:
                            data?.campaignBidding?.customerAcquisition
                              ?.bidHigherForNewCustomers,
                          bidForNewCustomersOnly:
                            data?.campaignBidding?.customerAcquisition
                              ?.bidForNewCustomersOnly,
                        },
                      },
                      google_Campaignsettings: {
                        ...state?.google_Campaignsettings,
                        // network_id: network_id,
                        startDate: data?.campaignSetting?.startDate,
                        endDate: data?.campaignSetting?.endDate,
                        schedule: schedule,
                        add_rotation_value:
                          data?.campaignSetting?.adRotationValue,
                        campaignBudget: data?.campaignSetting?.campaignBudget,
                        brand_restricted:
                          data?.campaignSetting?.brandsRestricted?.map(
                            (item) => {
                              // alert("hi")
                              return {
                                label: item?.branListName,
                                value: item?.brandRestrictionId,
                              };
                            },
                          ),
                        languageSelected: data?.campaignSetting?.language?.map(
                          (item) => {
                            // alert("hi")
                            return {
                              label: item?.language,
                              value: item?.id,
                            };
                          },
                        ),
                      },
                      google_Campaign_Url_Data: {
                        ...state?.google_Campaign_Url_Data,
                        tracking_templates:
                          data?.campaignSetting?.googleCampaignUrlData[0]
                            ?.trackingTemplates,
                        suffix:
                          data?.campaignSetting?.googleCampaignUrlData[0]
                            ?.suffix,
                      },
                      automaticallyCreatedAssetText:
                        data?.campaignSetting?.automaticallyCreatedAssetText,
                      automaticallyCreatedAssetFinalUrl:
                        data?.campaignSetting
                          ?.automaticallyCreatedAssetFinalUrl,
                      Merchent_center_account: data?.merchantCenterAccount,
                      dipak: true,
                      campaignGoal: data?.campaignSetting?.campaignGoal || '',
                      conversionGoalId:
                        data?.campaignSetting?.conversionGoalId || '',
                      campaignLocationType: data?.campaignLocationType || '',
                      campaignSubType: data?.campaignSubType,
                      atdStatus: data?.atdStatus,
                    };
                  }
                }
                break;
              default:
            }
          }
          break;
        case `${googleObjectiveList?.Leads?.objective_id}`: //Leads
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
                {
                  // google_Campaignsettings: {
                  //   ...state?.google_Campaignsettings,
                  //   network_id: dataArr.join(','),
                  // }
                  let network_id = '';

                  if (data?.campaignSetting?.isSearchNetwork)
                    network_id += 'Search,';
                  if (data?.campaignSetting?.isDisplayNetwork)
                    network_id += 'Display,';
                  // isSearchNetwork,isDisplayNetwork,campaignSetting

                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });
                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    // campaignName
                    campaign_name: data?.campaignName,
                    googleCampaignid: data?.googleCampaignid,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    google_Goalinfo: data?.googleCampaignGoalReachTypes?.[0],
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      brand_restricted:
                        data?.campaignSetting?.brandsRestricted?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.branListName,
                            value: item?.brandRestrictionId,
                          };
                        }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                      audience_segments_id:
                        data?.campaignSetting?.audience?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.name,
                            value: item?.googleAudienceSegmentsid,
                          };
                        }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              case `${googleCampaignTypeList?.Video?.campaign_type_id}`: //video
                {
                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                  };
                }
                break;

              case `${googleCampaignTypeList?.Display?.campaign_type_id}`: //display
                {
                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });
                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    googleCampaignid: data?.googleCampaignid,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    websiteUrlForDisplay: data?.webPageUrl,
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      wayOfGettingConversions:
                        data?.campaignBidding?.wayOfGettingConversions,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      payForType: data?.campaignBidding?.payForType,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      // network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      // brand_restricted: data?.campaignSetting?.brandsRestricted?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.branListName,
                      //     value:item?.brandRestrictionId
                      //   }
                      // }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                      // audience_segments_id: data?.campaignSetting?.audience?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.name,
                      //     value:item?.googleAudienceSegmentsid
                      //   }
                      // }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              default:
            }
          }
          break;
        case `${googleObjectiveList?.['Website Traffic']?.objective_id}`: //website traffic
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
                {
                  // google_Campaignsettings: {
                  //   ...state?.google_Campaignsettings,
                  //   network_id: dataArr.join(','),
                  // }
                  let network_id = '';

                  if (data?.campaignSetting?.isSearchNetwork)
                    network_id += 'Search,';
                  if (data?.campaignSetting?.isDisplayNetwork)
                    network_id += 'Display,';
                  // isSearchNetwork,isDisplayNetwork,campaignSetting

                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });
                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    // campaignName
                    campaign_name: data?.campaignName,
                    googleCampaignid: data?.googleCampaignid,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    google_Goalinfo: data?.googleCampaignGoalReachTypes?.[0],
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      brand_restricted:
                        data?.campaignSetting?.brandsRestricted?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.branListName,
                            value: item?.brandRestrictionId,
                          };
                        }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                      audience_segments_id:
                        data?.campaignSetting?.audience?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.name,
                            value: item?.googleAudienceSegmentsid,
                          };
                        }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    atdStatus: data?.atdStatus,
                  };
                }
                break;

              case `${googleCampaignTypeList?.Display?.campaign_type_id}`: //display
                {
                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });
                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    googleCampaignid: data?.googleCampaignid,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    websiteUrlForDisplay: data?.webPageUrl,
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      wayOfGettingConversions:
                        data?.campaignBidding?.wayOfGettingConversions,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      payForType: data?.campaignBidding?.payForType,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      // network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      // brand_restricted: data?.campaignSetting?.brandsRestricted?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.branListName,
                      //     value:item?.brandRestrictionId
                      //   }
                      // }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                      // audience_segments_id: data?.campaignSetting?.audience?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.name,
                      //     value:item?.googleAudienceSegmentsid
                      //   }
                      // }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand Gen
                {
                  let selectedMer =
                    data?.campaignSetting?.productFeedAccount?.map((item) => {
                      return {
                        label: item?.googleMerchentCenterAccountName,
                        value: item?.googleMerchentCenterAccountId,
                      };
                    });

                  //Network ids
                  let selectedNetworkIds = data?.campaignSetting?.network?.map(
                    (item) => {
                      return {
                        label: item?.networkName,
                        value: item?.googleNetworkid,
                      };
                    },
                  );

                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });

                  //Model Device
                  let devicesModelState = data?.campaignSetting?.devices;
                  devicesModelState = devicesModelState?.map((item) => {
                    return {
                      selectedDeviceModel: item?.operatingSystemName,
                      selectedSubChild: item?.manufacturerName,
                      selectedFinalChild: item?.name,
                      googleSalesDevicesId: item?.googleSalesDevicesId,
                    };
                  });
                  //Model Devices Ids
                  let deviceId = [];
                  data?.campaignSetting?.devices?.map((item) => {
                    deviceId.push(item?.googleSalesDevicesId);
                  });
                  return {
                    ...state,
                    googleCampaignid: data?.googleCampaignid || '',
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    productFeeds: data?.campaignSetting?.productFeeds,
                    Merchent_center_selected_account_details: selectedMer,
                    targetCostPerClick:
                      data?.campaignSetting?.targetCostPerClick,
                    campaignLevelLocationLanguage:
                      data?.campaignSetting?.campaignLevelLocationLanguage,
                    DeviceType: data?.campaignSetting?.deviceType,
                    networkId: selectedNetworkIds,
                    networkIdObj: selectedNetworkIds,
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      schedule: schedule,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                    },
                    // devicesState: devicesState,
                    devicesModelState: devicesModelState,
                    deviceId: deviceId,
                    campaignGoal: data?.campaignSetting?.campaignGoal || '',
                    conversionGoalId:
                      data?.campaignSetting?.conversionGoalId || '',
                    campaignLocationType: data?.campaignLocationType || '',
                    productFeedSelectedAccount: selectedMer[0]?.value,
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              default:
            }
          }
          break;
        case `${googleObjectiveList?.['Brand awareness and reach']?.objective_id}`: //Brand awareness and reach
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.Display?.campaign_type_id}`: //display
                {
                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });
                  return {
                    ...state,
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    googleCampaignid: data?.googleCampaignid,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    websiteUrlForDisplay: data?.webPageUrl,
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      wayOfGettingConversions:
                        data?.campaignBidding?.wayOfGettingConversions,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      payForType: data?.campaignBidding?.payForType,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      // network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      // brand_restricted: data?.campaignSetting?.brandsRestricted?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.branListName,
                      //     value:item?.brandRestrictionId
                      //   }
                      // }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                      // audience_segments_id: data?.campaignSetting?.audience?.map((item) => {
                      //   // alert("hi")
                      //   return {
                      //     label: item?.name,
                      //     value:item?.googleAudienceSegmentsid
                      //   }
                      // }),
                      // language
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              default:
            }
          }
          break;
        case `${googleObjectiveList?.['Local store visits and promotion']?.objective_id}`: //Local store visits and promotion
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.['Performance Max']?.campaign_type_id}`: //Performance Max
                let schedule = data?.campaignSetting?.googleAdSchedules;
                schedule = schedule?.map((item) => {
                  return {
                    scheduleDays: item?.scheduleDays,
                    scheduleStartTime: item?.scheduleStartTime,
                    scheduleEndTime: item?.scheduleEndTime,
                  };
                });
                {
                  return {
                    ...state,
                    googleCampaignid: data?.googleCampaignid || '',
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    google_Binding: {
                      ...state?.google_Binding,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                      customerAcquisition: {
                        optimizeCampaignAcquiringNewCustomers:
                          data?.campaignBidding?.customerAcquisition
                            ?.optimizeCampaignAcquiringNewCustomers || false,
                        bidHigherForNewCustomers:
                          data?.campaignBidding?.customerAcquisition
                            ?.bidHigherForNewCustomers,
                        bidForNewCustomersOnly:
                          data?.campaignBidding?.customerAcquisition
                            ?.bidForNewCustomersOnly,
                      },
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      // network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      brand_restricted:
                        data?.campaignSetting?.brandsRestricted?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.branListName,
                            value: item?.brandRestrictionId,
                          };
                        }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    automaticallyCreatedAssetText:
                      data?.campaignSetting?.automaticallyCreatedAssetText,
                    automaticallyCreatedAssetFinalUrl:
                      data?.campaignSetting?.automaticallyCreatedAssetFinalUrl,
                    Merchent_center_account: data?.merchantCenterAccount,
                    dipak: true,
                    campaignGoal: data?.campaignSetting?.campaignGoal || '',
                    conversionGoalId:
                      data?.campaignSetting?.conversionGoalId || '',
                    campaignLocationType: data?.campaignLocationType || '',
                    atdStatus: data?.atdStatus,
                  };
                }
            }
          }
          break;
        case `${googleObjectiveList?.['Product and brand consideration']?.objective_id}`: //Product and brand consideration
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand Gen
                {
                  let selectedMer =
                    data?.campaignSetting?.productFeedAccount?.map((item) => {
                      return {
                        label: item?.googleMerchentCenterAccountName,
                        value: item?.googleMerchentCenterAccountId,
                      };
                    });

                  //Network ids
                  let selectedNetworkIds = data?.campaignSetting?.network?.map(
                    (item) => {
                      return {
                        label: item?.networkName,
                        value: item?.googleNetworkid,
                      };
                    },
                  );

                  let schedule = data?.campaignSetting?.googleAdSchedules;
                  schedule = schedule?.map((item) => {
                    return {
                      scheduleDays: item?.scheduleDays,
                      scheduleStartTime: item?.scheduleStartTime,
                      scheduleEndTime: item?.scheduleEndTime,
                    };
                  });

                  //Model Device
                  let devicesModelState = data?.campaignSetting?.devices;
                  devicesModelState = devicesModelState?.map((item) => {
                    return {
                      selectedDeviceModel: item?.operatingSystemName,
                      selectedSubChild: item?.manufacturerName,
                      selectedFinalChild: item?.name,
                      googleSalesDevicesId: item?.googleSalesDevicesId,
                    };
                  });
                  //Model Devices Ids
                  let deviceId = [];
                  data?.campaignSetting?.devices?.map((item) => {
                    deviceId.push(item?.googleSalesDevicesId);
                  });
                  return {
                    ...state,
                    googleCampaignid: data?.googleCampaignid || '',
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    productFeeds: data?.campaignSetting?.productFeeds,
                    Merchent_center_selected_account_details: selectedMer,
                    targetCostPerClick:
                      data?.campaignSetting?.targetCostPerClick,
                    campaignLevelLocationLanguage:
                      data?.campaignSetting?.campaignLevelLocationLanguage,
                    DeviceType: data?.campaignSetting?.deviceType,
                    networkId: selectedNetworkIds,
                    networkIdObj: selectedNetworkIds,
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      schedule: schedule,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                    },
                    // devicesState: devicesState,
                    devicesModelState: devicesModelState,
                    deviceId: deviceId,
                    campaignGoal: data?.campaignSetting?.campaignGoal || '',
                    conversionGoalId:
                      data?.campaignSetting?.conversionGoalId || '',
                    campaignLocationType: data?.campaignLocationType || '',
                    productFeedSelectedAccount: selectedMer[0]?.value,
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              default:
            }
          }
          break;
        case `${googleObjectiveList?.['App promotion']?.objective_id}`: //App promotion
          {
            switch (`${campaign_type_id}`) {
              case `${googleCampaignTypeList?.['App']?.campaign_type_id}`: //App
                let schedule = data?.campaignSetting?.googleAdSchedules;
                schedule = schedule?.map((item) => {
                  return {
                    scheduleDays: item?.scheduleDays,
                    scheduleStartTime: item?.scheduleStartTime,
                    scheduleEndTime: item?.scheduleEndTime,
                  };
                });
                {
                  return {
                    ...state,
                    googleCampaignid: data?.googleCampaignid || '',
                    objective_id: data?.objectiveId,
                    campaign_type_id: data?.campaignTypeId,
                    campaign_name: data?.campaignName,
                    taxonomy_campaign_id:
                      data?.campaignSetting?.taxonomyCampaignId,
                    google_Binding: {
                      ...state?.google_Binding,
                      targetCostPerInstall:
                        data?.campaignBidding?.targetCostPerInstall,
                      bidStrategy: data?.campaignBidding?.bidStrategy,
                      setTargetCostPerAction:
                        data?.campaignBidding?.setTargetCostPerAction,
                      setTargetReturnOnAdSpend:
                        data?.campaignBidding?.setTargetReturnOnAdSpend,
                      targetRoas: data?.campaignBidding?.targetRoas,
                      targetCpa: data?.campaignBidding?.targetCpa,
                      maximumCpcBidLimit:
                        data?.campaignBidding?.maximumCpcBidLimit,
                      maximumCpcBidLimitValue:
                        data?.campaignBidding?.maximumCpcBidLimitValue,
                      adLocation: data?.campaignBidding?.adLocation,
                      impressionShareToTarget:
                        data?.campaignBidding?.impressionShareToTarget,
                      viewableCpmValue: data?.campaignBidding?.viewableCpmValue,
                      customerAcquisition: {
                        optimizeCampaignAcquiringNewCustomers:
                          data?.campaignBidding?.customerAcquisition
                            ?.optimizeCampaignAcquiringNewCustomers || false,
                        bidHigherForNewCustomers:
                          data?.campaignBidding?.customerAcquisition
                            ?.bidHigherForNewCustomers,
                        bidForNewCustomersOnly:
                          data?.campaignBidding?.customerAcquisition
                            ?.bidForNewCustomersOnly,
                      },
                    },
                    google_Campaignsettings: {
                      ...state?.google_Campaignsettings,
                      // network_id: network_id,
                      startDate: data?.campaignSetting?.startDate,
                      endDate: data?.campaignSetting?.endDate,
                      schedule: schedule,
                      add_rotation_value:
                        data?.campaignSetting?.adRotationValue,
                      campaignBudget: data?.campaignSetting?.campaignBudget,
                      brand_restricted:
                        data?.campaignSetting?.brandsRestricted?.map((item) => {
                          // alert("hi")
                          return {
                            label: item?.branListName,
                            value: item?.brandRestrictionId,
                          };
                        }),
                      languageSelected: data?.campaignSetting?.language?.map(
                        (item) => {
                          // alert("hi")
                          return {
                            label: item?.language,
                            value: item?.id,
                          };
                        },
                      ),
                    },
                    google_Campaign_Url_Data: {
                      ...state?.google_Campaign_Url_Data,
                      tracking_templates:
                        data?.campaignSetting?.googleCampaignUrlData[0]
                          ?.trackingTemplates,
                      suffix:
                        data?.campaignSetting?.googleCampaignUrlData[0]?.suffix,
                    },
                    automaticallyCreatedAssetText:
                      data?.campaignSetting?.automaticallyCreatedAssetText,
                    automaticallyCreatedAssetFinalUrl:
                      data?.campaignSetting?.automaticallyCreatedAssetFinalUrl,
                    Merchent_center_account: data?.merchantCenterAccount,
                    dipak: true,
                    campaignGoal: data?.campaignSetting?.campaignGoal || '',
                    conversionGoalId:
                      data?.campaignSetting?.conversionGoalId || '',
                    campaignLocationType: data?.campaignLocationType || '',
                    google_Goalinfo: {
                      mobileAppPlatform: data?.mobileAppPlatform,
                      lookupApp: data?.lookupApp?.googleAppsAssetTypeId,
                    },
                    campaignSubType: data?.campaignSubType,
                    isDataFeedForApp: data?.campaignSetting?.isDataFeed,
                    DataFeedTypeForApp: data?.campaignSetting?.dataFeedType,
                    atdStatus: data?.atdStatus,
                  };
                }
                break;
              default:
            }
          }
          break;
        default:
      }

      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
