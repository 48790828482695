import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

function SideTreeMenuGoogle(props) {
  let treeData = props?.campaignTreeData;
  let Id = props?.IdAndType?.id;
  let Type = props?.IdAndType?.type;

  const [selectedItemType, setSelectedItemType] = useState(null);
  useEffect(() => {
    if (Type == 'campaign') {
      //   setSelectedItemId(-1);
      setSelectedItemType('campaign');
    } else if (Type == 'adGroup') {
      //   setSelectedItemId(Id);
      setSelectedItemType('adGroup');
    } else if (Type == 'ad') {
      //   setSelectedItemId(Id);
      setSelectedItemType('ad');
    }
  });
  return (
    <>
      <article className='side-tree-menu'>
        <div className='nav-left scroll-vertical'>
          <nav className='navbar'>
            <ul className='navbar-nav'>
              {treeData?.length > 0 ? (
                <>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      aria-current='page'
                      href='javascript:void(0)'
                    >
                      <i className='icon-camopaign'></i>
                      <span>{treeData?.[0]?.campaignName}</span>
                    </a>
                    <div className='dropdown-wrap'>
                      <Dropdown>
                        <Dropdown.Toggle
                          id='oneBoxx'
                          className='primary-btn close-btn dot-btn-primary'
                        >
                          <i className='icon-meni-hori'></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href='#/action-1'>
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href='#/action-2'>
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href='#/action-3'>
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      aria-current='page'
                      href='javascript:void(0)'
                    >
                      <i className='icon-camopaign'></i>
                      <span>New Campaign</span>
                    </a>
                    <div className='dropdown-wrap'>
                      <Dropdown>
                        <Dropdown.Toggle
                          id='oneBoxx'
                          className='primary-btn close-btn dot-btn-primary'
                        >
                          <i className='icon-meni-hori'></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href='#/action-1'>
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href='#/action-2'>
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href='#/action-3'>
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </>
              )}
              {treeData?.[0]?.googleAdGroups?.length > 0 ? (
                <>
                  {treeData?.[0]?.googleAdGroups?.map((adGroups, index) => {
                    return (
                      <>
                        <li className='nav-item' key={index}>
                          <a href='javascript:void(0)' className='nav-link'>
                            <i className='icon-ad-set'></i>
                            <span> {adGroups?.adGroupName}</span>
                          </a>
                          <div className='dropdown-wrap'>
                            <Dropdown>
                              <Dropdown.Toggle
                                id='oneBox'
                                className='primary-btn close-btn dot-btn-primary'
                              >
                                <i className='icon-meni-hori'></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href='#/action-4'>
                                  Duplicate
                                </Dropdown.Item>
                                <Dropdown.Item href='#/action-5'>
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item href='#/action-6'>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <ul>
                            {adGroups?.googleAds?.length > 0 ? (
                              <>
                                {adGroups?.googleAds?.map((ads, index) => {
                                  <li key={index}>
                                    <a href='#' className='nav-link'>
                                      <i className='icon-ad'></i>
                                      <span>{ads?.adName}</span>
                                    </a>
                                  </li>;
                                })}
                              </>
                            ) : (
                              <li>
                                <a href='#' className='nav-link active'>
                                  <i className='icon-ad'></i>
                                  <span>New Ad</span>
                                </a>
                              </li>
                            )}

                            {/* <li>
                                              <a href="#" className="nav-link">
                                                <i className="icon-ad"></i> <span>New ad 2</span>
                                              </a>
                                            </li> */}
                          </ul>
                        </li>
                        ;
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {treeData?.length > 0 ? (
                    <>
                      <li className='nav-item'>
                        <a
                          href='javascript:void(0)'
                          className='nav-link active'
                        >
                          <i className='icon-ad-set'></i>
                          <span> New Ad group</span>
                        </a>
                        <div className='dropdown-wrap'>
                          <Dropdown>
                            <Dropdown.Toggle
                              id='oneBox'
                              className='primary-btn close-btn dot-btn-primary'
                            >
                              <i className='icon-meni-hori'></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href='#/action-4'>
                                Duplicate
                              </Dropdown.Item>
                              <Dropdown.Item href='#/action-5'>
                                Copy
                              </Dropdown.Item>
                              <Dropdown.Item href='#/action-6'>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {/* <ul>
                      <li>
                        <a href='#' className='nav-link'>
                          <i className='icon-ad'></i>
                          <span>New Ad</span>
                        </a>
                      </li>
                      
                    </ul> */}
                      </li>
                    </>
                  ) : null}
                </>
              )}

              {/* <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                          <i className="icon-ad-set"></i>
                          <span> New awareness ad set 2</span>
                        </a>
                        <ul>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i>
                              <span>New awareness ad 1</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li>
                        </ul>
                      </li> */}
            </ul>
          </nav>
        </div>
      </article>
    </>
  );
}

export default SideTreeMenuGoogle;
