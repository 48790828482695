import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { extractInitials, parseUrlEncodedJson } from '../../utils/utils';
import { setLoginToken } from '../../state/Login/LoginAction';
import { useDispatch, useSelector } from 'react-redux';
import './NavHeading.css';
import { getApi } from '../../utils/Apis';
import { baseUrlStg, metaUrl } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { DV360Options } from '../../pages/DV360/DV360Constant';
import { GoogleAdOptions } from '../../pages/googleAds/GoogleAdConstant';

const NavHeading = ({ HeadingName }) => {
  const [cookies, setCookie] = useCookies(['Token']);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const GetPendingApprovalCount = async (id) => {
    const url = `${baseUrlStg}/api/CampaignApproval/GetPendingApprovalCount?userId=${id}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await getApi(url, headers);
    setCount(response?.response);
    return response;
  };

  useEffect(() => {
    const token = cookies?.Token;

    if (token) {
      try {
        dispatch(setLoginToken(token));
        if (token?.Username) {
          setUsername(token?.Username);
          GetPendingApprovalCount(token?.Userid);
          setRole(token?.roles);
        } else {
          console.error('Token does not contain Username property.');
          setUsername('');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setUsername('');
      }
    }
  }, [cookies.Token]);
  const LoginReducer = useSelector((state) => state?.loginReducer);
  const roleMapping = LoginReducer?.roleMapping;

  const updateUsername = extractInitials(username);
  const { googleCount = 0, dv360Count = 0, metaCount = 0 } = count || {};
  return (
    <div className='top-header-bar'>
      <div className='top-left-bar'>
        <h2>{HeadingName}</h2>
      </div>

      <div className='top-right-bar d-flex'>
        {role?.toLowerCase() === 'publisher' && (
          <div className='top-notifi-badge d-flex'>
            <div className='top-notifi-title'>
              <h3>Pending Request:</h3>
            </div>
            <div className='top-notifi-btn'>
              {/* 
                Check if the roleMapping object has a 'meta' property and if it's greater than 0.
                If so, render the div with the Google buttons, otherwise don't render it.
              */}
              {roleMapping?.meta && (
                <button
                  type='button'
                  className='primary-btn fb-notifi'
                  onClick={() =>
                    (window.location.href = `${metaUrl}/FaceBookCampaign/GetPlatformNameByClicking?platformClicked=Meta`)
                  }
                >
                  <small className='noti-message'>{metaCount}</small>
                  <i className='icon-facebook-ads'></i>
                </button>
              )}
              {roleMapping?.google && (
                <button
                  type='button'
                  className='primary-btn google-notifi'
                  onClick={() =>
                    navigate(`${GoogleAdOptions.Campaigns.url}?google=clicked`)
                  }
                >
                  <small className='noti-message'>{googleCount}</small>
                  <i className='icon-google-ads'></i>
                </button>
              )}
              {roleMapping?.dv360 && (
                <button
                  type='button'
                  className='primary-btn ad-notifi'
                  onClick={() =>
                    navigate(`${DV360Options.Campaigns.url}?dv360=clicked`)
                  }
                >
                  <small className='noti-message'>{dv360Count}</small>
                  <i className='icon-ads'></i>
                </button>
              )}
              {cookies?.Token && (
                <div className='profile-box'>
                  <h3>{updateUsername}</h3>
                  <small className='profile-dot'></small>
                </div>
              )}
            </div>
          </div>
        )}
        {cookies?.Token && role?.toLowerCase() !== 'publisher' && (
          <div className='profile-box'>
            <h3>{updateUsername}</h3>
            <small className='profile-dot'></small>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavHeading;
