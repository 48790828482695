import Dropdown from 'react-bootstrap/Dropdown';

function SideTreeMenu() {
  return (
    <>
      <article className='side-tree-menu'>
        {/* <div className="status-cont-top">
                  <div className="status-lt">
                    <span>Status:</span>
                    <span><strong>Published</strong> (Enabled)</span>
                  </div>
                  <div className="status-btn">
                    <button type="button" className="primary-btn close-btn" href="javascript:void(0)">
                      <i className="icon-right-arrow"></i>
                    </button>
                  </div>
                </div> */}
        <div className='nav-left scroll-vertical'>
          <nav className='navbar'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  aria-current='page'
                  href='javascript:void(0)'
                >
                  <i className='icon-camopaign'></i>
                  <span>New Campaign</span>
                </a>
                <div className='dropdown-wrap'>
                  <Dropdown>
                    <Dropdown.Toggle
                      id='oneBoxx'
                      className='primary-btn close-btn dot-btn-primary'
                    >
                      <i className='icon-meni-hori'></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                      <Dropdown.Item href='#/action-2'>
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href='#/action-3'>
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
              <li className='nav-item'>
                <a href='javascript:void(0)' className='nav-link'>
                  <i className='icon-ad-set'></i>
                  <span> New Ad group</span>
                </a>
                <div className='dropdown-wrap'>
                  <Dropdown>
                    <Dropdown.Toggle
                      id='oneBox'
                      className='primary-btn close-btn dot-btn-primary'
                    >
                      <i className='icon-meni-hori'></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href='#/action-4'>Duplicate</Dropdown.Item>
                      <Dropdown.Item href='#/action-5'>Copy</Dropdown.Item>
                      <Dropdown.Item href='#/action-6'>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <ul>
                  <li>
                    <a href='#' className='nav-link'>
                      <i className='icon-ad'></i>
                      <span>New Ad</span>
                    </a>
                  </li>
                  {/* <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li> */}
                </ul>
              </li>
              {/* <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                          <i className="icon-ad-set"></i>
                          <span> New awareness ad set 2</span>
                        </a>
                        <ul>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i>
                              <span>New awareness ad 1</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li>
                        </ul>
                      </li> */}
            </ul>
          </nav>
        </div>
      </article>
    </>
  );
}

export default SideTreeMenu;
