import CardBody from '../common/CardBody';
import CardHeader from '../common/CardHeader';
import InfoText from '../common/InfoText';

const AppUrl = (props) => {
  return (
    <>
      <section className='group-section-data'>
        <CardHeader>
          <h5>App URL</h5>
          {/* <p>Select apps</p> */}
        </CardHeader>
        <CardBody>
          <InfoText
            copy={
              'Add a deep link to send people to a specific page in your app after they install'
            }
          />
          <div className='card-form form-panel'>
            <div className='row align-items-center'>
              <div className='form-item col-12'>
                <div className='label-icon'>
                  <label>App URL</label>
                </div>
                <div className='input-group'>
                  <input
                    type='text'
                    name='Ad Name'
                    id='text'
                    placeholder='Enter a App url'
                    onChange={props?.handlesetAppUrlVal}
                    value={props?.value}
                  />
                  <span className='error ps-0' id='error-campaignType'>
                    {props?.appUrlError}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </section>
      <div className='divder-form'></div>
    </>
  );
};

export default AppUrl;
