import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { MediaListing } from './pages/mediaLibrary/MediaListing';
import { UploadFiles } from './pages/mediaLibrary/UploadFiles';
import { store } from './state/store';
import DV360 from './pages/DV360/DV360';
import Common from './pages/DV360/Common';
import InsertionOrder from './pages/DV360/InsertionOrder';
import LineItem from './pages/DV360/LineItem';
import RtbDisplay from './pages/DV360/RtbDisplay';
import RtbVideo from './pages/DV360/RtbVideo';
import RtbAudio from './pages/DV360/RtbAudio';
import CreateMediaPlan from './pages/texonomyManager/CreateMediaPlan';
import CreateTexonomyCampaign from './pages/texonomyManager/CreateTexonomyCampaign';
// Listing
import CampaignsListing from './pages/DV360/Listing/CampaignsListing';
import LineItemListing from './pages/DV360/Listing/LineItemListing';
import InsertionOrderListing from './pages/DV360/Listing/InsertionOrderListing';
// ViewPage
import CampaignsView from './pages/DV360/View/CampaignsView';
import InsertionOrderView from './pages/DV360/View/InsertionOrderView';
import LineItemView from './pages/DV360/View/LineItemView';

// Constant Imports
import { DV360Options } from './pages/DV360/DV360Constant';
import MediaWidget from './pages/mediaLibrary/MediaWidgets/MediaWidget';

import CreateTaxonomyAds from './pages/texonomyManager/CreateTaxonomyAds';

// css
import './App.css';
import CreateTaxonomyAdCID from './pages/texonomyManager/CreateTaxonomyAdCID';
import TaxonomyMediaPlanListing from './pages/texonomyManager/taxonomyListings/TaxonomyMediaPlanListing';
import TaxonomyMediaCampaignListing from './pages/texonomyManager/taxonomyListings/TaxonomyMediaCampaignListing';
import TaxomonyCampaignAdsListing from './pages/texonomyManager/taxonomyListings/TaxomonyCampaignAdsListing';
import TaxonomyCIDListing from './pages/texonomyManager/taxonomyListings/TaxonomyCIDListing';

//google ads
import AdGroup from './pages/googleAds/AdGroup';
import CreateGoogleAdCampaign from './pages/googleAds/CreateGoogleAdCampaign';
import GoogleAd from './pages/googleAds/GoogleAd';

// google listing
import GoogleCampaignListing from './pages/googleAds/Listing/GoogleCampaignListing';
import { GoogleAdOptions } from './pages/googleAds/GoogleAdConstant';
import AdGroupsListing from './pages/googleAds/Listing/AdGroupsListing';
import AdsListing from './pages/googleAds/Listing/AdsListing';
import GoogleCampaignVIew from './pages/googleAds/View/GoogleCampaignVIew';
import AdGroupsView from './pages/googleAds/View/AdGroupsView';
import AdsView from './pages/googleAds/View/AdsView';
import LoginComponent from './components/login/LoginComponent';
import Error404 from './pages/Error404/Error404';
import APIError from './pages/APIError/APIError';
import { API_ERROR, userManagement } from './utils/constants';

// Toast for error
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserList from './pages/User/UserList';
import UserCreation from './pages/User/UserCreation';
import UserDetail from './pages/User/UserDetail';

function AppRoutes() {
  const LoginReducer = useSelector((state) => state?.loginReducer);
  const role = LoginReducer?.roleMapping;
  const user = LoginReducer?.userToken;

  const isAdmin = user?.roles?.toLowerCase() === 'admin';

  return (
    <Routes>
      {/* media library start */}
      <Route
        exact
        path='/media-library/widget'
        element={
          <LoginComponent>
            <MediaWidget />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/media-library/listing'
        element={
          <LoginComponent>
            <MediaListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/media-library/upload-media'
        element={
          <LoginComponent>
            <UploadFiles />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/media-library/listing'
        element={
          <LoginComponent>
            <MediaListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/media-library/upload-media'
        element={
          <LoginComponent>
            <UploadFiles />
          </LoginComponent>
        }
      ></Route>
      {/* media library end */}
      {/* texonomy-manager */}
      <Route
        exact
        path='/taxonomy-manager/create-media-plan'
        element={
          <LoginComponent>
            <CreateMediaPlan />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/create-campaign'
        element={
          <LoginComponent>
            <CreateTexonomyCampaign />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/create-ad'
        element={
          <LoginComponent>
            <CreateTaxonomyAds />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/create-cid'
        element={
          <LoginComponent>
            <CreateTaxonomyAdCID />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/media-plan-list'
        element={
          <LoginComponent>
            <TaxonomyMediaPlanListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/media-campaign-list'
        element={
          <LoginComponent>
            <TaxonomyMediaCampaignListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/campaign-ads-list'
        element={
          <LoginComponent>
            <TaxomonyCampaignAdsListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/taxonomy-manager/CID-structer-list'
        element={
          <LoginComponent>
            <TaxonomyCIDListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/'
        element={
          <LoginComponent>
            <TaxonomyMediaPlanListing />
          </LoginComponent>
        }
      ></Route>
      <Route
        exact
        path='/common'
        element={
          <LoginComponent>
            <Common />
          </LoginComponent>
        }
      ></Route>
      {/* dv360 */}
      {/* DV360 start */}
      {/* {role?.dv360 && ( */}
      <>
        <Route
          exact
          path={DV360Options.Campaigns.url}
          element={
            <LoginComponent>
              <CampaignsListing />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={DV360Options.Insertion_Order.url}
          element={
            <LoginComponent>
              <InsertionOrderListing />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={DV360Options.Line_Item.url}
          element={
            <LoginComponent>
              <LineItemListing />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={DV360Options?.Campaigns?.newUrl}
          element={
            <LoginComponent>
              <DV360 />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360/create-campaign/:id'
          element={
            <LoginComponent>
              <DV360 />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360/create-insertion-order/new'
          element={
            <LoginComponent>
              <InsertionOrder />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360/create-insertion-order/:id'
          element={
            <LoginComponent>
              <InsertionOrder />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360/create-line-item/new'
          element={
            <LoginComponent>
              <LineItem />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360/create-line-item/:id'
          element={
            <LoginComponent>
              <LineItem />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360-Rtb-Display'
          element={
            <LoginComponent>
              <RtbDisplay />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360-Rtb-Video'
          element={
            <LoginComponent>
              <RtbVideo />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360-Rtb-Youtube'
          element={
            <LoginComponent>
              <RtbVideo />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path='/dv-360-Rtb-Audio'
          element={
            <LoginComponent>
              <RtbAudio />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${DV360Options.Campaigns.viewPageUrl}/:id`}
          element={
            <LoginComponent>
              <CampaignsView />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${DV360Options.Insertion_Order.viewPageUrl}/:id`}
          element={
            <LoginComponent>
              <InsertionOrderView />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${DV360Options.Line_Item.viewPageUrl}/:id`}
          element={
            <LoginComponent>
              <LineItemView />
            </LoginComponent>
          }
        ></Route>
      </>
      {/* )} */}
      {/* DV360 end */}

      {/* google Ad */}
      {/* {role?.google && ( */}
      <>
        <Route
          exact
          path={GoogleAdOptions.Campaigns.newGoogleCampaign}
          element={
            <LoginComponent>
              <CreateGoogleAdCampaign />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.Campaigns.editUrl}/:id`}
          element={
            <LoginComponent>
              <CreateGoogleAdCampaign />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.Campaigns.editUrl}/:id`}
          element={
            <LoginComponent>
              <CreateGoogleAdCampaign />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={GoogleAdOptions.AdGroups.newGoogleAdGroup}
          element={
            <LoginComponent>
              <AdGroup />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.AdGroups.editUrl}/:id`}
          element={
            <LoginComponent>
              <AdGroup />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={GoogleAdOptions.Ads.newGoogleAd}
          element={
            <LoginComponent>
              <GoogleAd />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.Ads.editUrl}/:id`}
          element={
            <LoginComponent>
              <GoogleAd />
            </LoginComponent>
          }
        ></Route>

        {/* google listing */}
        <Route
          exact
          path={`${GoogleAdOptions.Campaigns.url}`}
          element={
            <LoginComponent>
              <GoogleCampaignListing />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.AdGroups.url}`}
          element={
            <LoginComponent>
              <AdGroupsListing />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.Ads.url}`}
          element={
            <LoginComponent>
              <AdsListing />
            </LoginComponent>
          }
        ></Route>
        {/* google view */}

        <Route
          exact
          path={`${GoogleAdOptions.Campaigns.viewPageUrl}/:id`}
          element={
            <LoginComponent>
              <GoogleCampaignVIew />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.AdGroups.viewPageUrl}/:id`}
          element={
            <LoginComponent>
              <AdGroupsView />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${GoogleAdOptions.Ads.viewPageUrl}/:id`}
          element={
            <LoginComponent>
              <AdsView />
            </LoginComponent>
          }
        ></Route>
      </>
      {/* )} */}

      {/* google end */}

      {/* user management */}
      {/* {isAdmin && ( */}
      <>
        <Route
          exact
          path={userManagement.userList}
          element={
            <LoginComponent>
              <UserList />
            </LoginComponent>
          }
        />
        <Route
          exact
          path={userManagement?.createUser}
          element={
            <LoginComponent>
              <UserCreation />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${userManagement?.updateUser}/:id`}
          element={
            <LoginComponent>
              <UserCreation />
            </LoginComponent>
          }
        ></Route>
        <Route
          exact
          path={`${userManagement.userDetail}/:id`}
          element={
            <LoginComponent>
              <UserDetail />
            </LoginComponent>
          }
        ></Route>
      </>
      {/* )} */}
      {/* user management */}

      <Route path='*' element={<Error404 />} />
      <Route exact path='/not-found' element={<Error404 />} />
      <Route exact path={API_ERROR} element={<APIError />} />
    </Routes>
  );
}

export default AppRoutes;
