import {
  Taxonomy_AD_CAMPAIGN_ID,
  TAXONOMY_AD_AD_ID,
  TAXONOMY_AD_USP_FOCUS,
  TAXONOMY_AD_CREATIVE_TYPE,
  TAXONOMY_AD_CONCEPT_NAME,
  TAXONOMY_AD_UNIQUE_ID,
  TAXONOMY_AD_CONTENT_TYPE,
  TAXONOMY_AD_CREATIVE_SIZE,
  TAXONOMY_AD_CALL_TO_ACTION,
  TAXONOMY_AD_AV_DURATION,
  TAXONOMY_AD_LANGUAGE,
  TAXONOMY_AD_RESET_FORM,
} from './TaxonomyCreateAdActionTypes';

const initialState = {
  campaign_id: '',
  ad_id: '',
  usp_focus: '',
  creative_type: null,
  concept_name: '',
  content_type: null,
  call_to_action: null,
  creative_size: null,
  av_duration: null,
  language: null,
  unique_id: '',
};

export const TaxonomyCreateAd = (state = initialState, { type, payload }) => {
  switch (type) {
    case Taxonomy_AD_CAMPAIGN_ID: {
      const value = payload;
      return { ...state, campaign_id: value };
    }
    case TAXONOMY_AD_AD_ID: {
      const value = payload;
      return { ...state, ad_id: value };
    }
    case TAXONOMY_AD_USP_FOCUS: {
      const value = payload;
      return { ...state, usp_focus: value };
    }
    case TAXONOMY_AD_CREATIVE_TYPE: {
      const value = payload;
      return { ...state, creative_type: value };
    }
    case TAXONOMY_AD_CONCEPT_NAME: {
      const value = payload;
      return { ...state, concept_name: value };
    }
    case TAXONOMY_AD_CONTENT_TYPE: {
      const value = payload;
      return { ...state, content_type: value };
    }
    case TAXONOMY_AD_CALL_TO_ACTION: {
      const value = payload;
      return { ...state, call_to_action: value };
    }
    case TAXONOMY_AD_CREATIVE_SIZE: {
      const value = payload;
      return { ...state, creative_size: value };
    }
    case TAXONOMY_AD_AV_DURATION: {
      const value = payload;
      return { ...state, av_duration: value };
    }
    case TAXONOMY_AD_LANGUAGE: {
      const value = payload;
      return { ...state, language: value };
    }
    case TAXONOMY_AD_UNIQUE_ID: {
      const value = payload;
      return { ...state, unique_id: value };
    }

    case TAXONOMY_AD_RESET_FORM: {
      const value = initialState;
      return value;
    }

    default:
      return state;
  }
};
