import React from 'react';

function FormHeader() {
  return (
    <>
      <div className='tab-new-form'>
        <div className='camp-tab-cont d-flex'>
          <div className='short-left-bar'>
            <div className='nav nav-tabs' id='nav-tab' role='tablist'>
              <button
                className='primary-btn active'
                id='edit-tab'
                data-bs-toggle='tab'
                data-bs-target='#edit'
                type='button'
                role='tab'
                aria-selected='true'
              >
                <i className='icon-edit'></i> Edit
              </button>
              <button
                className='primary-btn'
                id='review-tab'
                data-bs-toggle='tab'
                data-bs-target='#review'
                type='button'
                role='tab'
                aria-selected='false'
              >
                <i className='icon-show-password'></i>View
              </button>
              <button
                className='primary-btn'
                id='history-tab'
                data-bs-toggle='tab'
                data-bs-target='#history'
                type='button'
                role='tab'
                aria-selected='false'
              >
                <i className='icon-history'></i>Review Log
              </button>
            </div>
          </div>
          <div className='short-right-cont'>
            <button
              className='btn-icon btn-icon-primary approve-act'
              type='button'
            >
              <i className='icon-check-mark'></i> Approve
            </button>
            <button
              className='btn-icon btn-icon-primary reject-act'
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#rejectState'
            >
              <i className='icon-close'></i> Reject
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormHeader;
