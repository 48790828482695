import React from 'react';
import Sidebar from '../../components/common/Sidebar';
import ReactSelect from '../../components/common/ReactSelect';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMediaDivision,
  setMediaProductName,
  setMediaPhase,
  setMediaQuarter,
  setMediaFundingSource,
  setMediaCampaignID,
  setMediaQtNumber,
  clearMediaFormValue,
} from '../../state/TexonomyCreateMediaPlan/CreateMediaPlanAction';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../components/common/Loader';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { baseUrlStg } from '../../utils/constants';
import { getCookies } from '../../utils/utils';

const CreateMediaPlan = () => {
  const [divisions, setDivisions] = useState([]);
  const [phase, setPhase] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [fundingSource, setFundingSource] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPupup, setShowPupup] = useState(false);
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const CreateMediaPlaneData = state?.TexonomyCreateMediaPlane;

  const navigate = useNavigate();
  const LoginReducer = useSelector((state) => state?.loginReducer);
  const userId = LoginReducer?.userToken?.Userid;

  useEffect(() => {
    getDivisionsData();
    getPhaseData();
    getQuarterData();
    getFundingSourceData();
  }, []);

  const getDivisionsData = () => {
    const url = `${baseUrlStg}/api/taxonomy-media/division`;
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((response) => {
        setDivisions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPhaseData = () => {
    const url = `${baseUrlStg}/api/taxonomy-media/phase`;
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((response) => {
        setPhase(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuarterData = () => {
    const url = `${baseUrlStg}/api/taxonomy-media/quarter`;
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((response) => {
        const newQuarters = response.data.map((ele) => {
          return {
            label: ele.label.replace(': ', '-'),
            value: ele.value.replace(': ', '-'),
          };
        });
        setQuarters(newQuarters);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFundingSourceData = () => {
    const url = `${baseUrlStg}/api/taxonomy-media/funding_source`;
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((response) => {
        setFundingSource(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDivision = (event) => {
    const value = event?.value ? event?.value : null;
    dispatch(setMediaDivision(value));
  };

  const handleProductName = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    const value = event.target.value;
    dispatch(setMediaProductName(value));
  };

  const handlePhase = (event) => {
    const value = event?.value ? event?.value : null;
    dispatch(setMediaPhase(value));
  };

  const handleQuater = (event) => {
    const value = event?.value ? event?.value : '';
    dispatch(setMediaQuarter(value));
  };

  const handleFundingSource = (event) => {
    const value = event?.value ? event?.value : null;
    dispatch(setMediaFundingSource(value));
  };

  const handleCampaignId = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    const value = event.target.value;
    dispatch(setMediaCampaignID(value));
  };

  const handleQtNumber = (event) => {
    let value = '';
    if (event?.target?.value?.length > 0) {
      event.currentTarget.value = event.currentTarget.value.replace(/\D/g, '');
      value = 'QT' + event.target.value;
    }
    dispatch(setMediaQtNumber(value));
  };

  const handleNavigate = () => {
    navigate('/taxonomy-manager/media-plan-list');
  };

  const submitHandler = () => {
    setFormErrors(validate(CreateMediaPlaneData));
    console.log('outside');
    setTimeout(async () => {
      if (Object.keys(validate(CreateMediaPlaneData)).length === 0) {
        setIsLoading(true);
        try {
          const postData = {
            division_id: CreateMediaPlaneData.mediaDivision,
            media_plan_phase_id: CreateMediaPlaneData.mediaPhase,
            media_paln_quater_id: CreateMediaPlaneData.mediaQuarter,
            media_paln_funding_source_id:
              CreateMediaPlaneData.mediaFundingSource,
            product: CreateMediaPlaneData.mediaProductName,
            campaign_identifier: CreateMediaPlaneData.mediaCampaignId,
            qt_number: CreateMediaPlaneData.mediaQtNumber,
            created_by: userId,
            last_edited_by: 0,
          };

          console.log('media post Data', postData);
          let url = `${baseUrlStg}/api/taxonomy-media/media_plan`;
          let response = await axios.post(url, postData, {
            headers: {
              'Content-Type': 'application/json-patch+json',
              ...CustomHeader,
            },
          });
          console.log('post api res', response);
          if (response.data.success) {
            setIsLoading(false);
            dispatch(clearMediaFormValue(null));
            setShowPupup(true);
          } else {
            setIsLoading(false);
            setFormErrors({
              qtNumber: response.data.message,
            });
          }
        } catch (error) {
          console.log('create media plan api error');

          setIsLoading(false);
        }
      }
    }, 100);
  };

  const validate = (formData) => {
    const errors = {};

    if (!formData?.mediaDivision) {
      errors.division = 'Please select the division';
    }
    if (formData?.mediaProductName === '') {
      errors.productName = 'Please enter the product name';
    }
    if (!formData?.mediaPhase) {
      errors.phase = 'Please select the phase';
    }
    if (!formData?.mediaQuarter || formData?.mediaQuarter === '') {
      errors.quarter = 'Please select the quarter';
    }
    if (!formData?.mediaFundingSource) {
      errors.fundingSource = 'Please select the funding source';
    }
    if (formData?.mediaCampaignId === '') {
      errors.campaignId = 'Please enter the campaign identifier';
    }

    if (formData?.mediaQtNumber === '') {
      errors.qtNumber = 'Please enter the QT number';
    }

    return errors;
  };

  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container'>
          <div className='container'>
            <div className='taxonomy-panel'>
              <div>
                <Sidebar />
              </div>
              <div className='right-side-box padding-bottom-extra media-section-left'>
                <header>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-left-bar'>
                      <h2>Create Media Plan</h2>
                    </div>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={handleNavigate}
                          className='primary-btn close-btn'
                        >
                          <i className='icon-close' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={() => {
                            navigate('/taxonomy-manager/media-plan-list');
                          }}
                          className='btn-icon btn-icon-primary'
                        >
                          <i className='icon-edit-box' /> Show all Media Plans
                        </button>
                      </div>
                    </div>
                  </div>
                </header>
                <article className='form-inside-create'>
                  <div className='form-panel card-primary'>
                    <div className='form-item-cont row'>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Division</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select division'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            defaultValue={[]}
                            options={divisions}
                            onChange={handleDivision}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.division}
                        </div>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Product</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter product Name'
                            onChange={handleProductName}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.productName}
                        </div>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Phase</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select phase'}
                            isSearchable={false}
                            isMulti={false}
                            isClearable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={phase}
                            onChange={handlePhase}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.phase}
                        </div>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Quarter</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select quarter'}
                            options={quarters}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            isClearable={false}
                            onChange={handleQuater}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.quarter}
                        </div>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Funding Source</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select funding source'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                            isSearchable={false}
                            isClearable={false}
                            defaultValue={[]}
                            options={fundingSource}
                            onChange={handleFundingSource}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.fundingSource}
                        </div>
                      </div>
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Campaign Identifier</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter campaign identifier'
                            onChange={handleCampaignId}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.campaignId}
                        </div>
                      </div>
                      <div className='form-item input-group-label col-4'>
                        <div className='label-icon'>
                          <label>QT Number</label>
                        </div>
                        <div className='input-group'>
                          <span className='input-group-text' id='basic-addon1'>
                            QT
                          </span>
                          <input
                            type='text'
                            placeholder='Enter QT number'
                            className='input-validation-error'
                            name='qtNumber'
                            onChange={handleQtNumber}
                            maxLength={50}
                          />
                        </div>
                        <span
                          className='error field-validation-error'
                          id='MediaPlan.qt_number'
                        >
                          {formErrors?.qtNumber}
                        </span>
                      </div>
                      {/* <div className="form-item col-4">
                      <div className="label-icon">
                        <label></label>
                      </div>
                      <div className="select-box small-select-multiple-components">
                       <select name="drinks" required="">
                      <option value="" disabled="" selected="">
                        Choose...
                      </option>
                      <option value="coffee">Coffee</option>
                      <option value="tea">Tea</option>
                      <option value="milk">Milk</option>
                    </select> 
                        <ReactSelect />
                      </div>
                      <div className="error" id="nameError">
                        Please enter valid Passwoard
                      </div>
                    </div> */}
                    </div>
                    <div className='form-btn-cont'>
                      <button
                        type='submit'
                        id='submit'
                        onClick={submitHandler}
                        className='primary-btn'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* media plan added popup */}
      <Modal
        show={showPupup}
        onHide={() => {
          setShowPupup(false);
          handleNavigate();
        }}
        backdrop='static'
        keyboard={false}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-primary'>
              <i className='icon-media-plan' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Thank you!</h3>
            <p>Your media plan has been successfully created.</p>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => {
              setShowPupup(false);
              handleNavigate();
            }}
          >
            Ok
          </button>
        </div>

        {/* <Modal.Footer>
            <Button variant="secondary" >
              Close
            </Button>
            <Button variant="primary" onClick={deleteItems}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default CreateMediaPlan;
