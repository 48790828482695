export const GOOGLE_AD_GROUP_NAME = 'GOOGLE_AD_GROUP_NAME';
export const GOOGLE_AD_GROUP_CSV_FILE = 'GOOGLE_AD_GROUP_CSV_FILE';
export const GOOGLE_AD_GROUP_CAMPAIGN_LEVEL_LOCATION_LANGUAGE =
  'GOOGLE_AD_GROUP_CAMPAIGN_LEVEL_LOCATION_LANGUAGE';
export const GOOGLE_AD_GROUP_URL_OPTIONS_TRACKING_TEMPLATES =
  'GOOGLE_AD_GROUP_URL_OPTIONS_TRACKING_TEMPLATES';
export const GOOGLE_AD_GROUP_TOPICS = 'GOOGLE_AD_GROUP_TOPICS';
export const GOOGLE_AD_GROUP_AUDIENCE = 'GOOGLE_AD_GROUP_AUDIENCE';
export const GOOGLE_AD_GROUP_DEMOGRAPHIC_GENDER =
  'GOOGLE_AD_GROUP_DEMOGRAPHIC_GENDER';
export const GOOGLE_AD_GROUP_AD_GROUP_NAME = 'GOOGLE_AD_GROUP_AD_GROUP_NAME';
export const GOOGLE_AD_GROUP_SHOW_ALL_PRODUCTS =
  'GOOGLE_AD_GROUP_SHOW_ALL_PRODUCTS';
export const GOOGLE_AD_GROUP_OPTIMIZED_TARGETING =
  'GOOGLE_AD_GROUP_OPTIMIZED_TARGETING';
export const GOOGLE_AD_GROUP_CAMPAIGN_ID = 'GOOGLE_AD_GROUP_CAMPAIGN_ID';
export const GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM =
  'GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM';
export const GOOGLE_AD_GROUP_DEMOGRAPHIC_PARENTAL_STATUS =
  'GOOGLE_AD_GROUP_DEMOGRAPHIC_PARENTAL_STATUS';
export const GOOGLE_AD_GROUP_URL_OPTION_VALUE =
  'GOOGLE_AD_GROUP_URL_OPTION_VALUE';
export const GOOGLE_AD_GROUP_PLACEMENTS = 'GOOGLE_AD_GROUP_PLACEMENTS';
export const GOOGLE_AD_GROUP_URL_OPTIONS_SUFFIX =
  'GOOGLE_AD_GROUP_URL_OPTIONS_SUFFIX';
export const GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM_INFO =
  'GOOGLE_AD_GROUP_URL_OPTIONS_CUS_PARAM_INFO';
export const GOOGLE_AD_GROUP_DEMOGRAPHIC_AGE =
  'GOOGLE_AD_GROUP_DEMOGRAPHIC_AGE';
export const GOOGLE_AD_GROUP_AD_GROUP_HOUSEHOLD_INCOME =
  'GOOGLE_AD_GROUP_AD_GROUP_HOUSEHOLD_INCOME';
export const GOOGLE_AD_GROUP_AD_GROUP_PRODUCT_ID =
  'GOOGLE_AD_GROUP_AD_GROUP_PRODUCT_ID';
export const GOOGLE_AD_GROUP_LANGUAGE = 'GOOGLE_AD_GROUP_LANGUAGE';
export const GOOGLE_AD_TRACKING_TEMPLATE = 'GOOGLE_AD_TRACKING_TEMPLATE';
export const GOOGLE_AD_FINAL_URL_SUFFIX = 'GOOGLE_AD_FINAL_URL_SUFFIX';
export const GOOGLE_AD_IS_MOBILE_URL_ENABLED =
  'GOOGLE_AD_IS_MOBILE_URL_ENABLED';
export const GOOGLE_AD_FINAL_MOBILE_URL = 'GOOGLE_AD_FINAL_MOBILE_URL';
export const SET_BID_CPC = 'SET_BID_CPC';
export const GOOGLE_AD_GROUP_PREFILLED_VALUES =
  'GOOGLE_AD_GROUP_PREFILLED_VALUES';
export const RESET_GOOGLE_AD_GROUP_STATE = 'RESET_GOOGLE_AD_GROUP_STATE';
