import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { postApi } from '../../../../utils/Apis';
import { baseUrlStg } from '../../../../utils/constants';
import CommonSuccessModal from '../../../../components/common/CommonSuccessModal';
import Loader from '../../../../components/common/Loader';

const ControlModal = ({
  showControl,
  handleClose,
  from,
  setToggleControl,
  toggleControl,
  controlUrl,
  controlId,
  doneWithControl,
  setShowControl,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [getRequstedType, setRequestedType] = useState('');

  const handleControl = async () => {
    try {
      setLoading(true);
      setIsLoading(true);
      setRequestedType(toggleControl);
      const is_enabled = toggleControl == 'Enable' ? 1 : 0;
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrlStg}/${controlUrl}?modified_by=24&is_enabled=${is_enabled} `,
        payload: controlId,
      };

      const response = await postApi(config.url, config.payload).catch(
        (error) => {
          console.error('Failed to Control :', error);
          throw error;
        },
      );
      console.log('response Disable', response);

      if (response && response?.data.status === 200) {
        setTimeout(() => {
          setLoading(false);
          setIsLoading(false);
          handleClose();
          doneWithControl();
          setShowSuccessModal(true);

          console.log('Enable/Disable Response', response);
        }, 1000);
      } else {
        setLoading(false);
        setIsLoading(false);
        console.error('Failed to Disable/Enable ');
      }
    } catch (error) {
      console.error('Failed to Disable.Enable :', error);
    }
  };

  return (
    <>
      <div>
        {isLoading && <Loader />}
        <Modal
          show={showControl}
          onHide={handleClose}
          className='atd-modal approved-model'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            {/* You can remove this if you don't need it */}
            {/* <Modal.Title></Modal.Title> */}
          </Modal.Header>

          <Modal.Body>
            <div className='modal-img-cont'>
              <figure className='modal-icon modal-remove'>
                <i className='icon-close'></i>
              </figure>
            </div>

            <div className='modal-text'>
              {loading ? (
                <h3>Processing the Records. Please Wait.</h3>
              ) : (
                <>
                  <h3>Are You Sure?</h3>

                  <p>
                    {' '}
                    You want to {toggleControl} campaign ? This action may
                    affect your current advertising effectiveness.
                  </p>
                </>
              )}
            </div>
          </Modal.Body>

          <div className='modal-footer'>
            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn cancel-btn'
                data-bs-dismiss='modal'
                id='btncancel'
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type='button'
                className='primary-btn primary-remove'
                data-bs-dismiss='modal'
                id='btndelete'
                disabled={loading}
                onClick={() => {
                  setToggleControl();
                  handleControl();
                }}
              >
                {toggleControl}
              </button>
            </div>
          </div>
        </Modal>
        {showSuccessModal && (
          <CommonSuccessModal
            show={true}
            copy={`Campaign has been successfully ${getRequstedType}.`}
            handleClose={() => setShowSuccessModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default ControlModal;
