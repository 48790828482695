import { getRoleMapping } from '../../utils/roleMapping';
import { LOGIN_TOKEN } from './LoginActionTypes';

const login = {
  userToken: '',
};

export const loginReducer = (state = login, action) => {
  switch (action.type) {
    case LOGIN_TOKEN:
      return {
        ...state,
        userToken: action.payload,
        roleMapping: getRoleMapping(action.payload),
      };
    default:
      return state;
  }
};
