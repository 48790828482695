import { useState } from 'react';
import Select, { components } from 'react-select';

// import "./styles.css";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type='checkbox' defaultChecked={isSelected} />
      {children}
    </components.Option>
  );
};

const allOptions = [
  { value: 'option 1', label: 'option 1' },
  { value: 'option 2', label: 'option 2' },
  { value: 'option 3', label: 'option 3' },
  { value: 'option 4', label: 'option 4' },
  { value: 'option 5', label: 'option 5' },
  { value: 'option 6', label: 'option 6' },
  { value: 'option 7', label: 'option 7' },
  { value: 'option 8', label: 'option 8' },
  { value: 'option 9', label: 'option 9' },
  { value: 'option 10', label: 'option 10' },
  { value: 'option 11', label: 'option 11' },
  { value: 'option 12', label: 'label' },
];

function ReactSelect(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  let options;
  if (props?.options) {
    options = props.options;
  } else {
    options = [];
  }
  let defaultValue;
  if (props?.defaultValue) {
    if (props?.defaultValue[0]?.label && props?.defaultValue[0]?.value) {
      defaultValue = props?.defaultValue;
    } else defaultValue = [];
  }
  let clearble = true;
  if (props?.isClearable == false) {
    clearble = false;
  }
  return (
    <>
      <div
        className={`select-multiple-components ${
          props?.isMulti == true ? '' : 'single-slelect-components'
        }`}
      >
        <Select
          // menuIsOpen={true}
          value={props?.value}
          isClearable={clearble}
          isSearchable={props?.isSearchable}
          placeholder={
            props?.placeholder != '' ? props?.placeholder : 'Select...'
          }
          isDisabled={props?.isDisabled}
          defaultValue={defaultValue || []}
          isMulti={props?.isMulti}
          closeMenuOnSelect={props?.closeMenuOnSelect === true ? true : false}
          hideSelectedOptions={false}
          onChange={props?.onChange}
          options={options}
          components={{
            Option: InputOption,
          }}
        />
      </div>
      {/* <pre>{JSON.stringify({ selected: selectedOptions }, null, 2)}</pre> */}
    </>
  );
}
export default ReactSelect;
