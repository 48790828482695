import React from 'react';
import Card from '../common/Card';
import CardBody from '../common/CardBody';
import CardHeader from '../common/CardHeader';
import ReactSelect from '../common/ReactSelect';

const Placements = ({ onChange, value, formErrors, options }) => {
  return (
    <Card>
      <CardBody>
        <>
          <div className='form-item '>
            <div className='label-icon'>
              <label>
                <CardHeader>
                  <h6>Placements</h6>
                </CardHeader>
                <p className='d-flex align-items-center'>
                  Suggest websites, videos, or apps where you'd like to show
                  your ads
                  <span
                    className='label-information tooltips'
                    tooltip='tootltip'
                    tooltip-position='top'
                  >
                    <i className='icon-info'></i>
                    <span>
                      Add placements to target specific locations on the Google
                      Display Network or YouTube. On the Display Network, a
                      placement can be an entire website, a specific page of a
                      site, or a mobile app. On YouTube, a placement can be a
                      channel or a video. For Video campaigns only: When you add
                      Display Network placements, your ad may still run in all
                      eligible locations on YouTube. And when you add YouTube
                      placements, your ad may still run in all eligible
                      locations across the Display Network.
                    </span>
                  </span>
                </p>
              </label>
            </div>
            <div className='select-box select-multiple-components '>
              <ReactSelect
                placeholder={'Select Placements'}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti={true}
                options={options}
                value={value}
                onChange={onChange}
              />
            </div>
            <div className='error' id='nameError'>
              {formErrors?.gender}
            </div>
          </div>
        </>
      </CardBody>
    </Card>
  );
};

export default Placements;
