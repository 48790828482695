import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { postApi } from '../../utils/Apis';
import ProgressBarComponent from './ProgressBarComponent';
import { baseUrlStg } from '../../utils/constants';

const BulkUploadCommonModal = (props) => {
  const [getCSVFileInfo, setCSVFileInfo] = useState({
    CSVFile: '',
    CSVFileName: '',
  });
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [getProgressBarCount, setProgressBarCount] = useState(0);
  const [getApiResponseStatus, setApiResponseStatus] = useState(false);
  const state = useSelector((state) => state);
  let getCurrentUserId = state?.loginReducer?.userToken?.Userid;
  const [getShowPreparingMsgStatus, setSHowPreparingMsgStatus] =
    useState(false);
  const [getShowAlmostThereMsgStatus, setShowAlmostThereMsgStatus] =
    useState(false);
  const [getShowFinalMsgStatus, setShowFinalMsgStatus] = useState(false);
  const handleFileUpload = (e) => {
    setCSVFileInfo({
      ...getCSVFileInfo,
      CSVFile: e.target.files[0] || '',
      CSVFileName: e.target.files[0]?.name,
    });
  };
  const handleResetState = () => {
    setCSVFileInfo({
      CSVFile: '',
      CSVFileName: '',
    });
    setShowErrorMsg(false);
  };

  const handleImportFile = () => {
    if (getCSVFileInfo?.CSVFileName == '') {
      setShowErrorMsg(true);
    } else {
      setShowProgressBar(true);
      setShowErrorMsg(false);
      handleProgressBarCount(0);
      let endPoint;
      if (props?.pageType == 'DV360') {
        //DV360 API call
        let payload = {
          DVFile: getCSVFileInfo?.CSVFile,
          createdBy: getCurrentUserId,
        };
        endPoint = `${baseUrlStg}/api/GoogleBulkCampaignUpload/DVBulkUpload`;
        handleFileUploadAPI(endPoint, payload);
      } else {
        //Google API call
        let payload = {
          CampaignCSVFile: getCSVFileInfo?.CSVFile,
          Createdby: getCurrentUserId,
        };
        setSHowPreparingMsgStatus(true);
        endPoint = `${baseUrlStg}/api/GoogleBulkCampaignUpload/CamapignBulkUpload`;
        handleFileUploadAPI(endPoint, payload);
      }
    }
  };

  const handleFileUploadAPI = async (endPoint, payload) => {
    //If got the success response hide the progress bar & redirect to the listing page
    try {
      setSHowPreparingMsgStatus(true);
      const response = await postApi(endPoint, payload, {
        accept: '*/',
        'Content-Type': 'multipart/form-data',
      });
      if (response?.status == 200) {
        setApiResponseStatus(true);
        const fileURL = URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement('a');
        link.href = fileURL;
        let getCurrentDate = moment(new Date()).format('MM-DD-YYYY');
        let fileName = `${props?.pageType}-uploaded-records-${getCurrentDate}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
      console.log('ppp', response.status);
    } catch (err) {
      setTimeout(() => {
        handleRedirectToListing();
      }, 2000);
    }
  };

  const handleProgressBarCount = (count) => {
    setTimeout(() => {
      if (count < 100) {
        count += 20;
        setProgressBarCount(count);
        handleProgressBarCount(count);
      } else {
        setSHowPreparingMsgStatus(false);
        setShowAlmostThereMsgStatus(true);
        setTimeout(() => {
          setShowFinalMsgStatus(true);
          setShowAlmostThereMsgStatus(false);
        }, 120000);
      }
    }, 200);
  };

  useEffect(() => {
    if (getProgressBarCount == 100 && getApiResponseStatus) {
      handleRedirectToListing();
    }
  }, [getApiResponseStatus, getProgressBarCount]);

  const handleRedirectToListing = () => {
    setTimeout(() => {
      if (props?.pageType == 'DV360') {
        window.location = '/dv-360/campaigns-listing';
      } else {
        window.location = '/google-ad/campaign-listing';
      }
    }, 200);
  };

  return (
    <>
      <Modal
        show={true}
        className={`atd-modal ${showProgressBar ? 'custom-scroll-model' : ''}`}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        {showProgressBar ? (
          <ProgressBarComponent
            getProgressBarCount={getProgressBarCount}
            getShowPreparingMsgStatus={getShowPreparingMsgStatus}
            getShowAlmostThereMsgStatus={getShowAlmostThereMsgStatus}
            getShowFinalMsgStatus={getShowFinalMsgStatus}
          />
        ) : (
          <>
            {/* <div class='card-header-top ps-3'>
                <div class='card-lt-pane'>
                  <p>Select a Excel file.</p>
                </div>
                <div class='media-btn media-btn-file'>
                  <div class='file-error'>
                    <div class='btn-icon btn-bedcrumb-act btn-file'>
                      <input type='file' id='imgFileID' multiple='' />
                      <label for='imgFileID'>
                        <i class='icon-file'></i> Chosse File
                      </label>
                    </div>
                    <span class='error' id='emailError'>
                      Please Choose File
                    </span>
                    <div class='btn-icon btn-bedcrumb-act btn-file'>
                      <label>
                        <i class='icon-file'></i> Download Sample File
                      </label>
                    </div>
                    <div class='btn-icon btn-bedcrumb-act btn-file btn-file-download'>
                      <label>
                        <i class='icon-csv-file'></i>{' '}
                        <strong> File Name:</strong>
                        An Instant Experience is required. Select or create an
                        Instant Experience to publish.
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
            <Modal.Body>
              <div className='modal-text pt-3'>
                <h3>{props?.copy}</h3>
              </div>
              <div class='card-header-top'>
                <div class='card-lt-pane'>
                  <p>Select a Excel file.</p>
                </div>
                <div class='media-btn media-btn-file'>
                  <div class='file-error'>
                    <div class='btn-icon btn-bedcrumb-act btn-file'>
                      <input
                        accept='.csv'
                        type='file'
                        id='imgFileIDVi'
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <label for='imgFileIDVi'>
                        <i class='icon-export'></i> Choose File
                      </label>
                    </div>
                    <div class='btn-icon btn-bedcrumb-act btn-file'>
                      <Link
                        to={
                          props?.pageType == 'DV360'
                            ? `${baseUrlStg}/bulk-sample-file/Dv_BulkUpload_sampleFile.csv`
                            : `${baseUrlStg}/bulk-sample-file/Google_BulkUpload_sampleFile.csv`
                        }
                      >
                        <label>
                          <i class='icon-file'></i> Download sample file
                        </label>
                      </Link>
                    </div>
                    {getCSVFileInfo?.CSVFileName && (
                      <div class='btn-icon btn-bedcrumb-act btn-file btn-file-download'>
                        <label>
                          <i class='icon-csv-file'></i>{' '}
                          <strong>
                            {' '}
                            File Name: {getCSVFileInfo?.CSVFileName}
                          </strong>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {getCSVFileInfo?.CSVFileName == '' && showErrorMsg && (
                <div className='media-btn'>
                  <div className='file-error'>
                    <span className='error' id='emailError'>
                      Please Choose a file
                    </span>
                  </div>
                </div>
              )}
            </Modal.Body>

            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn cancel-btn'
                onClick={() => {
                  handleResetState();
                  props?.setBulkUploadModelStatus();
                }}
              >
                Close
              </button>
              <button
                type='button'
                className='primary-btn'
                onClick={handleImportFile}
              >
                Import
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default BulkUploadCommonModal;
