export const GoogleAdOptions = {
  Campaigns: {
    name: 'Campaigns',
    url: '/google-ad/campaign-listing',
    icon: 'icon-camopaign',
    viewPageUrl: '/google-ad/campaignsView',
    deleteUrl: 'api/Campaign/deleteCampaignByID?campaignID=',
    deleteAllUrl: 'api/GoogleListingDetails/GoogleCampaignDelete',
    fetcherUrl: '/api/GoogleAdListing/Campaign-list?',
    newGoogleCampaign: `/google-ad/create-campaign/new`,
    editUrl: '/google-ad/create-campaign/edit',
    reviewForm: 'campaign',
    platform: 'google',
  },
  AdGroups: {
    name: 'Ad Groups',
    url: '/google-ad/ad-group-listing',
    icon: 'icon-ad-set',
    viewPageUrl: '/google-ad/AdGroupView',
    deleteUrl: 'api/InsertionOrder/deleteInsertionOrderByID?insertionOrderID=',
    deleteAllUrl: 'api/GoogleListingDetails/GoogleAdGroupDelete',
    fetcherUrl: 'api/GoogleAdListing/Ad-group-list?',
    newGoogleAdGroup: `/google-ad/ad-group/new`,
    editUrl: '/google-ad/ad-group/edit',
    reviewForm: 'ad group',
    platform: 'google',
  },
  Ads: {
    name: 'Ads',
    url: '/google-ad/ads-listing',
    icon: 'icon-ad',
    viewPageUrl: '/google-ad/adView',
    deleteUrl: 'api/LineItem/deleteLineItemByID?lineItemID=',
    deleteAllUrl: 'api/GoogleListingDetails/GoogleAdDelete',
    fetcherUrl: `/api/GoogleAdListing/Ad-list?`,
    newGoogleAd: `/google-ad/ads/new`,
    editUrl: '/google-ad/ads/edit',
    reviewForm: 'ad',
    platform: 'google',
  },
};

export const ATDStatus = [
  {
    label: 'Draft',
    value: 'Draft',
  },
  {
    label: 'In Moderation',
    value: 'In Moderation',
  },
  {
    label: 'Published',
    value: 'Published',
  },
  {
    label: 'Api Error',
    value: 'api_error',
  },
];

export const GoogleStatus = [
  {
    label: 'NA',
    value: 'NA',
  },
  {
    label: 'Published(Enabled)',
    value: 'Published(Enabled)',
  },
  {
    label: 'Published(Disabled)',
    value: 'Published(Disabled)',
  },
];

export const GoogleEnableDisableOptions = [
  {
    label: 'Enable',
    value: '1',
  },
  {
    label: 'Disable',
    value: '0',
  },
];
