import React from 'react';
import Card from './Card';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import ReactSelect from './ReactSelect';
import { blockInvalidChar, handleFocus } from '../../utils/utils';

function BiddingForDisaplyFlow(props) {
  let bidStrategy = props?.bidStrategy;
  let setTargetCostPerAction = !!props?.setTargetCostPerAction;
  let cpaCheckBoxHandler = props?.cpaCheckBoxHandler;
  let waysToGetConversionsHandler = props?.waysToGetConversionsHandler;
  let waysToGetConversions = props?.waysToGetConversions;
  let payForTypeHandler = props?.payForTypeHandler;
  let cpaCostHandler = props?.cpaCostHandler;
  let roasCheckBoxHandler = props?.roasCheckBoxHandler;
  let viewableCPMHandler = props?.viewableCPMHandler;
  let maximumCpcBidLimitValue = props?.maximumCpcBidLimitValue;
  let viewableCpmValue = props?.viewableCpmValue;
  let payForType = props?.payForType;
  let targetCpa = props?.targetCpa;
  let targetRoas = props?.targetRoas;
  return (
    <>
      {/* Bidding starts here */}
      {
        <>
          <Card>
            <CardHeader>
              <div className='card-lt-pane'>
                <h5>Bidding</h5>
              </div>
            </CardHeader>
            <CardHeader>
              <div className='card-lt-pane'>
                <h6>What do you want to focus as on?</h6>
              </div>
            </CardHeader>
            <CardBody>
              <div className='card-form form-panel'>
                <div className='row align-items-center'>
                  <div className='form-item'>
                    <div className='label-icon'>
                      <label>focus</label>
                    </div>
                    <ReactSelect
                      options={[
                        {
                          value: 'Conversions',
                          label: 'Conversions',
                        },
                        {
                          value: 'Conversion value',
                          label: 'Conversion value',
                        },
                        {
                          value: 'Viewable impression',
                          label: 'Viewable impression',
                        },
                      ]}
                      onChange={props?.bidStrategyHandler}
                      closeMenuOnSelect={true}
                      value={[
                        {
                          label: bidStrategy,
                          value: bidStrategy,
                        },
                      ]}
                    />
                    <span className='error' id='emailError'>
                      {props?.bidStrategyError}
                    </span>
                  </div>
                  {bidStrategy == 'Conversions' && (
                    <>
                      <div className='form-item'>
                        <div className='label-icon'>
                          <label>How do you want to get conversions?</label>
                        </div>
                        <ReactSelect
                          options={[
                            {
                              value: 'Manually set bids',
                              label: 'Manually set bids',
                            },
                            {
                              value: 'Automatically maximize conversions',
                              label: 'Automatically maximize conversions',
                            },
                          ]}
                          onChange={waysToGetConversionsHandler}
                          closeMenuOnSelect={true}
                          value={[
                            {
                              label: waysToGetConversions,
                              value: waysToGetConversions,
                            },
                          ]}
                        />
                        <span className='error' id='emailError'>
                          {/* {formErrors?.bidding_focus} */}
                        </span>
                      </div>
                    </>
                  )}

                  {bidStrategy == 'Conversions' && setTargetCostPerAction && (
                    <>
                      <div className='form-item'>
                        <div className='label-icon'>
                          <label>Pay for</label>
                        </div>
                        <ReactSelect
                          options={[
                            {
                              value: 'Interactions',
                              label: 'Interactions',
                            },
                            {
                              value: 'Viewable Impressions',
                              label: 'Viewable Impressions',
                            },
                          ]}
                          onChange={payForTypeHandler}
                          closeMenuOnSelect={true}
                          value={[
                            {
                              label: payForType,
                              value: payForType,
                            },
                          ]}
                        />
                        <span className='error' id='emailError'>
                          {/* {formErrors?.bidding_focus} */}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </CardBody>

            <CardBody></CardBody>

            <CardBody>
              <div className='action-btn-both action-btn-both-check row relative'>
                {bidStrategy == 'Conversions' &&
                  waysToGetConversions ==
                    'Automatically maximize conversions' && (
                    <>
                      <div className='form-check-box'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='targetCpa'
                          // onChange={costTargetCheckBoxHandler}
                          onChange={cpaCheckBoxHandler}
                          id='inlineCheckbox1xz'
                          defaultValue='option1'
                          checked={setTargetCostPerAction}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='inlineCheckbox1xz'
                        >
                          Set a target cost per action{' '}
                          <span className='opt-text'>(Optional)</span>
                        </label>
                      </div>
                    </>
                  )}

                {bidStrategy == 'Conversions' && setTargetCostPerAction && (
                  <>
                    <div className='card-form form-panel'>
                      <div className='row align-items-center'>
                        <div className='form-item input-group-label col-6'>
                          <div className='budget-cont'>
                            <div className='label-icon'>
                              <label>Target CPA</label>
                            </div>
                            <div className='input-group'>
                              <span className='input-group-text rs-text'>
                                <i className='icon-rupee' />
                              </span>
                              <input
                                type='number'
                                id='email'
                                placeholder='X.XX'
                                name='targetCpa'
                                value={targetCpa}
                                // onChange={cpaCostHandler}
                                onChange={cpaCostHandler}
                                onFocus={handleFocus}
                                onKeyDown={blockInvalidChar}
                              />
                              <span className='input-group-text inr-text rs-text'>
                                INR
                              </span>
                            </div>
                          </div>
                          <span className='error' id='emailError'>
                            {/* {formErrors?.targetSpend} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {bidStrategy == 'Conversion value' && (
                  <>
                    <div className='form-check-box'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='targetRoas'
                        onChange={roasCheckBoxHandler}
                        id='inlineCheckbox1dc'
                        defaultValue='option1'
                        checked={setTargetCostPerAction}
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineCheckbox1dc'
                      >
                        Set a target return on ad{' '}
                        <span className='opt-text'>(Optional)</span>
                      </label>
                    </div>
                  </>
                )}
                {bidStrategy == 'Conversion value' &&
                  setTargetCostPerAction && (
                    <>
                      <div className='card-form form-panel'>
                        <div className='row align-items-center'>
                          <div className='form-item input-group-label col-6'>
                            <div className='budget-cont'>
                              <div className='label-icon'>
                                <label>Target ROAS</label>
                              </div>

                              <div className='input-group'>
                                <span className='input-group-text rs-text'>
                                  %
                                </span>
                                <input
                                  type='number'
                                  id='email'
                                  placeholder='X.XX'
                                  name='targetRoas'
                                  value={targetRoas}
                                  onChange={cpaCostHandler}
                                  onFocus={handleFocus}
                                  onKeyDown={blockInvalidChar}
                                />
                              </div>
                            </div>
                            <span className='error' id='emailError'>
                              {/* {formErrors?.targetSpend} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='maximumCpcBidLimitValue'
                    // onChange={costTargetCheckBoxHandler}
                    id='inlineCheckbox1'
                    defaultValue='option1'
                    // defaultChecked={IsmaxcpcEnabled}
                  />
                  <label className='form-check-label' htmlFor='inlineCheckbox1'>
                    Set a maximum cost per click bid limit{' '}
                    <span className='opt-text'>(Optional)</span>
                  </label>
                </div> */}

                {bidStrategy == 'Conversions' &&
                  waysToGetConversions == 'Manually set bids' && (
                    <>
                      <div className='card-form form-panel'>
                        <div className='row align-items-center'>
                          <div className='form-item input-group-label col-6'>
                            <div className='budget-cont'>
                              <div className='label-icon'>
                                <label>Enhanced CPC bid</label>
                              </div>
                              <div className='input-group'>
                                <span className='input-group-text rs-text'>
                                  <i className='icon-rupee' />
                                </span>
                                <input
                                  type='number'
                                  id='email'
                                  placeholder='X.XX'
                                  name='maximumCpcBidLimitValue'
                                  value={maximumCpcBidLimitValue}
                                  onChange={cpaCostHandler}
                                  onFocus={handleFocus}
                                  onKeyDown={blockInvalidChar}
                                />
                                <span className='input-group-text inr-text rs-text'>
                                  INR
                                </span>
                              </div>
                            </div>
                            <span className='error' id='emailError'>
                              {/* {formErrors?.targetSpend} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                <span className='error' id='emailError'>
                  {/* Please enter valid email id */}
                </span>
                {bidStrategy == 'Viewable impression' && (
                  <>
                    <div className='card-form form-panel'>
                      <div className='row align-items-center'>
                        <div className='form-item input-group-label col-6'>
                          <div className='budget-cont'>
                            <div className='label-icon'>
                              <label>Viewable CPM bid for this ad group</label>
                            </div>
                            <div className='input-group'>
                              <span className='input-group-text rs-text'>
                                <i className='icon-rupee' />
                              </span>
                              <input
                                type='number'
                                id='email'
                                placeholder='X.XX'
                                name='maximumCpcBidLimitValue'
                                value={viewableCpmValue}
                                onChange={viewableCPMHandler}
                                onFocus={handleFocus}
                                onKeyDown={blockInvalidChar}
                              />
                              <span className='input-group-text inr-text rs-text'>
                                INR
                              </span>
                            </div>
                          </div>
                          <span className='error' id='emailError'>
                            {/* {formErrors?.targetSpend} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <span className='error' id='emailError'>
                  {/* Please enter valid email id */}
                </span>
              </div>
            </CardBody>
          </Card>
        </>
      }

      {/* Bidding ends here */}
    </>
  );
}

export default BiddingForDisaplyFlow;
