export const TAXONOMY_CAMPAIGN_NAME = 'TAXONOMY_CAMPAIGN_NAME';
export const TAXONOMY_CAMPAIGN_PRODUCT_NAME = 'TAXONOMY_CAMPAIGN_PRODUCT_NAME';
export const TAXONOMY_CAMPAIGN_ADVERTISER_NAME =
  'TAXONOMY_CAMPAIGN_ADVERTISER_NAME';
export const TAXONOMY_CAMPAIGN_SUBCATEGORY = 'TAXONOMY_CAMPAIGN_SUBCATEGORY';
export const TAXONOMY_CAMPAIGN_PRECISION_JOB_OR_RO_NUMBER =
  'TAXONOMY_CAMPAIGN_PRECISION_JOB_OR_RO_NUMBER';
export const TAXONOMY_CAMPAIGN_GENDER = 'TAXONOMY_CAMPAIGN_GENDER';
export const TAXONOMY_CAMPAIGN_AGE = 'TAXONOMY_CAMPAIGN_AGE';
export const TAXONOMY_CAMPAIGN_PHASE = 'TAXONOMY_CAMPAIGN_PHASE';
export const TAXONOMY_CAMPAIGN_CHANNEL = 'TAXONOMY_CAMPAIGN_CHANNEL';
export const TAXONOMY_CAMPAIGN_DSP_PUBLISHER =
  'TAXONOMY_CAMPAIGN_DSP_PUBLISHER';
export const TAXONOMY_CAMPAIGN_CAMPAIGN_TYPE =
  'TAXONOMY_CAMPAIGN_CAMPAIGN_TYPE';
export const TAXONOMY_CAMPAIGN_BUY_TYPE_RATE_TYPE =
  'TAXONOMY_CAMPAIGN_BUY_TYPE_RATE_TYPE';
export const TAXONOMY_CAMPAIGN_CAMPAIGN_KPI = 'TAXONOMY_CAMPAIGN_CAMPAIGN_KPI';
export const TAXONOMY_CAMPAIGN_OBJECTIVE = 'TAXONOMY_CAMPAIGN_OBJECTIVE';
export const TAXONOMY_CAMPAIGN_AUDIENCE_TYPE =
  'TAXONOMY_CAMPAIGN_AUDIENCE_TYPE';
export const TAXONOMY_CAMPAIGN_FREE_FORM = 'TAXONOMY_CAMPAIGN_FREE_FORM';
export const TAXONOMY_CAMPAIGN_UNIQUE_ID = 'TAXONOMY_CAMPAIGN_UNIQUE_ID';
export const TAXONOMY_CAMPAIGN_START_DATE = 'TAXONOMY_CAMPAIGN_START_DATE';
export const TAXONOMY_CAMPAIGN_MATCH_TYPE = 'TAXONOMY_CAMPAIGN_MATCH_TYPE';
export const TAXONOMY_CAMPAIGN_SIZMMEK_TRACKING =
  'TAXONOMY_CAMPAIGN_SIZMMEK_TRACKING';
export const TAXONOMY_CAMPAIGN_CLEAR_FORM = 'TAXONOMY_CAMPAIGN_CLEAR_FORM';
