import { SIDE_TREE_DATA_GOOGLE } from './SideTreeActionTypes';

const SideTreeGoogle = {
  SideTreeGoogle: {},
};

export const sideTreeGoogleReducer = (state = SideTreeGoogle, action) => {
  switch (action.type) {
    case SIDE_TREE_DATA_GOOGLE:
      return {
        SideTreeGoogle: action.payload,
      };
    default:
      return state;
  }
};
