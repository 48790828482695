import { useState, useEffect, useId } from 'react';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg, googleV1Url } from '../../../utils/constants';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { GoogleAdOptions } from '../../googleAds/GoogleAdConstant';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Dropdown } from 'react-bootstrap';
import DeleteModal from '../Listing/Component/DeleteModal';
import { useDispatch } from 'react-redux';
import { setSideTreeGoogle } from '../../../state/SideTreeGoogle/SideTreeGoogleAction';

function SideTreeMenuView({ campaignId, IdAndType }) {
  const param = useParams();
  const isCreateMode = campaignId ? false : true;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [atdStatus, setAdsStatus] = useState('');
  const [googleStatus, setGoogleAdsStatus] = useState('');
  const [showNewAdsSection, setShowNewAdsSection] = useState(false);
  const [showNewCampaignSection, setShowNewCampaignSection] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  const dispatch = useDispatch();
  const location = useLocation();
  const isViewPage =
    location.pathname.includes(GoogleAdOptions.Ads.viewPageUrl) ||
    location.pathname.includes(GoogleAdOptions.AdGroups.viewPageUrl) ||
    location.pathname.includes(GoogleAdOptions.Campaigns.viewPageUrl);

  const isCreateCampaignPage = location.pathname.includes(
    GoogleAdOptions.Campaigns.newGoogleCampaign,
  );
  const isCreateAdGroupPage = location.pathname.includes(
    GoogleAdOptions.AdGroups.newGoogleAdGroup,
  );
  const isCreateAdsPage = location.pathname.includes(
    GoogleAdOptions.Ads.newGoogleAd,
  );

  const isOnAdGroupPage =
    location.pathname === GoogleAdOptions.AdGroups.newGoogleAdGroup &&
    location.search.includes('campaign_id');
  const isOnAdsPage =
    location.pathname === GoogleAdOptions.Ads.newGoogleAd &&
    location.search.includes('campaign_id');

  const getType = (data) => {
    const type = IdAndType?.type;
    const id = IdAndType?.id;
    if (GoogleAdOptions.Campaigns.name === type) {
      setAdsStatus(data?.atdStatus);
      setGoogleAdsStatus(data?.googleStatus);
    } else if (GoogleAdOptions.AdGroups.name === type) {
      data.adGroups.map((item) => {
        if (item.googleAdGroupsId == id) {
          setAdsStatus(item.atdStatus);
          setGoogleAdsStatus(item.googleStatus);
        }
      });
    } else if (GoogleAdOptions.Ads.name === type) {
      data.adGroups.map((item) => {
        item.ads.map((ad) => {
          if (ad.googleAdDetails.googleAdId == id) {
            console.log(ad.googleAdDetails.googleAdId);
            setAdsStatus(ad.googleAdDetails.atdStatus);
            setGoogleAdsStatus(ad.googleAdDetails.googleStatus);
          }
        });
      });
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (campaignId) {
        const response = await getApi(
          `${googleV1Url}/GetGoogleCampaignWithAddGroupAndAdById?id=${campaignId}`,
        );

        if (response) {
          dispatch(setSideTreeGoogle(response.response));
          setData(response.response);
          getType(response?.response);
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [campaignId]);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);

  let campaignName = data?.campaignName;
  let googleCampaigned = data?.googleCampaignid;
  let googleAdGroups = data?.adGroups;
  const navigate = useNavigate();

  let Id = IdAndType?.id;
  let Type = IdAndType?.type;
  useEffect(() => {
    if (Id) {
      if (Type == GoogleAdOptions.Campaigns.name) {
        setSelectedItemId(-1);
        setSelectedItemType(GoogleAdOptions.Campaigns.name);
      } else if (Type == GoogleAdOptions.AdGroups.name) {
        setSelectedItemId(Id);
        setSelectedItemType(GoogleAdOptions.AdGroups.name);
      } else if (Type == GoogleAdOptions.Ads.name) {
        setSelectedItemId(Id);
        setSelectedItemType(GoogleAdOptions.Ads.name);
      }
    }
  }, [Id]);

  useEffect(() => {
    // Scroll the active item into view
    setTimeout(() => {
      if (selectedItemId) {
        const element = document.getElementById(
          `item-${selectedItemType}-${selectedItemId}`,
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }, 1000);
  }, [selectedItemId, selectedItemType]);
  const [creatingNewAdGroup, setCreatingNewAdGroup] = useState(false);
  const handleNewAdGroup = (id) => {
    setCreatingNewAdGroup(true);
    navigate(
      `${
        GoogleAdOptions.AdGroups.newGoogleAdGroup
      }?campaign_id=${campaignId}&objective_id=${
        data?.objectiveId
      }&campaign_type_id=${data?.campaignTypeId}&taxonomyCapmId=${
        data?.campaignSetting?.taxonomyCampaignId
      }&cpcValue=${
        data?.campaignBidding?.bidStrategy == 'Manual CPC'
      }&productFeedsStatus=${
        data?.campaignSetting?.productFeeds == 1 ? true : false
      }&campaign_subtype=${data?.campaignSubType}`,
    );
  };

  const handleNewAds = (adGroupId) => {
    navigate(
      `${GoogleAdOptions.Ads.newGoogleAd}?objective_id=${
        data?.objectiveId
      }&campaign_type_id=${
        data?.campaignTypeId
      }&ad_groupId=${adGroupId}&taxonomyCapmId=${
        data?.campaignSetting?.taxonomyCampaignId
      }&campaign_id=${data?.googleCampaignid}&productFeedsStatus=${
        data?.campaignSetting?.productFeeds == 1 ? true : false
      }&campaign_id=${data?.googleCampaignid}&campaign_subtype=${
        data?.campaignSubType
      }`,
    );
  };

  const doneWithDeleteCampaign = () => {
    navigate(GoogleAdOptions.Campaigns.url);
  };

  const [campaignDeleteId, setCampaignDeleteId] = useState(false);
  const [showCampaignDeleteModal, setShowCampaignDeleteModal] = useState(false);
  const deleteCampaignUrl = GoogleAdOptions.Campaigns.deleteAllUrl;
  const handleCloseCampaignDeleteModal = () =>
    setShowCampaignDeleteModal(false);
  const handleDeleteCampaign = (id) => {
    setCampaignDeleteId([id]);
    setShowCampaignDeleteModal(true);
  };

  const doneWithDeleteAdGroup = () => {
    navigate(GoogleAdOptions.AdGroups.url);
  };

  const [adGroupDeleteId, setAdGroupDeleteId] = useState(false);
  const [showAdGroupDeleteModal, setShowAdGroupDeleteModal] = useState(false);
  const deleteAdGroupUrl = GoogleAdOptions.AdGroups.deleteAllUrl;
  const handleCloseAdGroupDeleteModal = () => setShowAdGroupDeleteModal(false);
  const handleDeleteAdGroup = (id) => {
    setAdGroupDeleteId([id]);
    setShowAdGroupDeleteModal(true);
  };

  const doneWithDeleteAd = () => {
    navigate(GoogleAdOptions.Ads.url);
  };

  const [adDeleteId, setAdDeleteId] = useState(false);
  const [showAdDeleteModal, setShowAdDeleteModal] = useState(false);
  const deleteAdUrl = GoogleAdOptions.Ads.deleteAllUrl;
  const handleCloseAdDeleteModal = () => setShowAdDeleteModal(false);
  const handleDeleteAd = (id) => {
    setAdDeleteId([id]);
    setShowAdDeleteModal(true);
  };

  const statusClass = {
    Draft: 'status-draft',
    'In Moderation': 'status-moderation',
    Published: 'status-published',
    Rejected: 'status-rejected',
    api_error: 'status-error',
    'Published(Enabled)': 'status-published',
    'Published(Disabled)': 'status-na-disable',
    draft: 'status-draft',
    in_moderation: 'status-moderation',
    null: 'status-na-disable',
  };
  const atdStatusClassName = `${statusClass[atdStatus]} status-cont-top`;
  const googleStatusClassName = `${statusClass[googleStatus]} status-cont-top`;

  // useEffect(() => {
  //   console.log('data', atdStatus);
  //   console.log('data', googleStatus);
  // }, [data]);

  return (
    <>
      <article className='side-tree-menu'>
        {loading ? (
          <div>
            <Skeleton height={40} count={5} />
          </div>
        ) : (
          <>
            {!loading && data && !isCreateMode && (
              <>
                <div className={atdStatusClassName}>
                  <div className='status-cont-left'>
                    <div className='status-lt'>
                      <span>ATD Status :</span>
                      <span>{capitalizeFirstLetter(atdStatus)}</span>
                    </div>
                  </div>
                  <br />

                  <div className='status-btn'>
                    <button
                      type='button'
                      className='primary-btn close-btn'
                      href='javascript:void(0)'
                    >
                      <i className='icon-right-arrow'></i>
                    </button>
                  </div>
                </div>

                <div className={googleStatusClassName}>
                  <div className='status-cont-left'>
                    <div className='status-lt'>
                      <span>Google Status :</span>
                      <span>{capitalizeFirstLetter(googleStatus)}</span>
                    </div>
                  </div>

                  <div className='status-btn'>
                    <button
                      type='button'
                      className='primary-btn close-btn'
                      href='javascript:void(0)'
                    >
                      <i className='icon-right-arrow'></i>
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className='nav-left scroll-vertical scroll-vertical-status'>
              <nav className='navbar'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link
                      id={`item-${GoogleAdOptions.Campaigns.name}-${googleCampaigned}`}
                      className={`nav-link ${campaignName ? '' : 'active'} ${
                        selectedItemType == GoogleAdOptions.Campaigns.name
                          ? 'active'
                          : ''
                      }`}
                      aria-current='page'
                      to={
                        googleCampaigned
                          ? isViewPage
                            ? `${GoogleAdOptions.Campaigns.viewPageUrl}/${googleCampaigned}`
                            : `${GoogleAdOptions.Campaigns.editUrl}/${googleCampaigned}`
                          : 'javascript:void(0)'
                      }
                    >
                      <i className='icon-camopaign'></i>
                      <span>{campaignName || 'New Campaign'}</span>
                    </Link>
                    {!isViewPage && (
                      <div className='dropdown-wrap'>
                        <Dropdown>
                          <Dropdown.Toggle
                            id='oneBoxx'
                            className='primary-btn close-btn dot-btn-primary'
                          >
                            <i className='icon-meni-hori'></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setShowNewCampaignSection(true),
                                  handleNewAdGroup(googleCampaigned);
                              }}
                            >
                              Add New AD Group
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handleDeleteCampaign(googleCampaigned)
                              }
                            >
                              Delete Campaign
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </li>
                  {isCreateAdGroupPage && (
                    <li className='nav-item'>
                      <Link className='nav-link active' to='javascript:void(0)'>
                        <i className='icon-ad-set'></i>
                        <span> New Ad Group </span>
                      </Link>
                    </li>
                  )}

                  {googleAdGroups?.length > 0
                    ? [...googleAdGroups].map((adGroup, index) => {
                        return (
                          <>
                            <li className='nav-item' key={index}>
                              <Link
                                id={`item-${GoogleAdOptions.AdGroups.name}-${adGroup?.googleAdGroupsId}`}
                                to={
                                  adGroup?.googleAdGroupsId
                                    ? isViewPage
                                      ? `${GoogleAdOptions.AdGroups.viewPageUrl}/${adGroup?.googleAdGroupsId}`
                                      : param.id == adGroup?.googleAdGroupsId
                                        ? 'javascript:void(0)'
                                        : `${GoogleAdOptions.AdGroups.editUrl}/${adGroup?.googleAdGroupsId}`
                                    : 'javascript:void(0)'
                                }
                                className={`nav-link ${
                                  selectedItemType ==
                                    GoogleAdOptions.AdGroups.name &&
                                  param.id == adGroup?.googleAdGroupsId &&
                                  !showNewCampaignSection
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <i className='icon-ad-set'></i>
                                <span>{adGroup?.adGroupName}</span>
                              </Link>
                              {!isViewPage && (
                                <div className='dropdown-wrap'>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id='oneBoxx'
                                      className='primary-btn close-btn dot-btn-primary'
                                    >
                                      <i className='icon-meni-hori'></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleNewAds(
                                            adGroup?.googleAdGroupsId,
                                          );
                                          setShowNewAdsSection(true);
                                        }}
                                      >
                                        Add New AD
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteAdGroup(
                                            adGroup?.googleAdGroupsId,
                                          )
                                        }
                                      >
                                        Delete Ad Group
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}

                              <ul>
                                {adGroup?.ads?.length > 0
                                  ? [...adGroup?.ads]?.map((ads, index) => {
                                      return (
                                        <>
                                          <li key={index}>
                                            <Link
                                              id={`item-${GoogleAdOptions.Ads.name}-${ads?.googleAdDetails?.googleAdId}`}
                                              to={
                                                isViewPage
                                                  ? `${GoogleAdOptions.Ads.viewPageUrl}/${ads?.googleAdDetails?.googleAdId}`
                                                  : param.id ==
                                                      ads?.googleAdDetails
                                                        ?.googleAdId
                                                    ? 'javascript:void(0)'
                                                    : `${GoogleAdOptions.Ads.editUrl}/${ads?.googleAdDetails?.googleAdId}`
                                              }
                                              className={`nav-link ${
                                                selectedItemType ==
                                                  GoogleAdOptions.Ads.name &&
                                                param.id ==
                                                  ads?.googleAdDetails
                                                    ?.googleAdId
                                                  ? 'active'
                                                  : ''
                                              }`}
                                            >
                                              <i className='icon-ad'></i>
                                              <span>
                                                {ads?.googleAdDetails?.adName}
                                              </span>
                                              {!isViewPage && (
                                                <div className='dropdown-wrap'>
                                                  <Dropdown>
                                                    <Dropdown.Toggle
                                                      id='oneBoxx'
                                                      className='primary-btn close-btn dot-btn-primary'
                                                    >
                                                      <i className='icon-meni-hori'></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleDeleteAd(
                                                            ads?.googleAdDetails
                                                              ?.googleAdId,
                                                          )
                                                        }
                                                      >
                                                        Delete Ad
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </div>
                                              )}
                                            </Link>
                                          </li>
                                        </>
                                      );
                                    })
                                  : ''}
                              </ul>
                              {query?.get('ad_groupId') ==
                                adGroup?.googleAdGroupsId &&
                                showNewAdsSection && (
                                  <li className='nav-item'>
                                    <Link
                                      className='nav-link active'
                                      to={'javascript:void(0)'}
                                    >
                                      <i className='icon-ad'></i>
                                      <span> New AD</span>
                                    </Link>
                                  </li>
                                )}
                              {query?.get('ad_groupId') ==
                                adGroup?.googleAdGroupsId &&
                                isCreateAdsPage &&
                                !showNewAdsSection && (
                                  <li className='nav-item'>
                                    <Link
                                      className='nav-link active'
                                      to={'javascript:void(0)'}
                                    >
                                      <i className='icon-ad'></i>
                                      <span> New AD </span>
                                    </Link>
                                  </li>
                                )}
                            </li>
                          </>
                        );
                      })
                    : ''}
                </ul>
              </nav>
            </div>
          </>
        )}
      </article>
      <DeleteModal
        handleClose={handleCloseCampaignDeleteModal}
        show={showCampaignDeleteModal}
        key={useId()}
        deleteId={campaignDeleteId}
        doneWithDelete={doneWithDeleteCampaign}
        deleteUrl={deleteCampaignUrl}
        from={GoogleAdOptions.Campaigns.name}
      ></DeleteModal>

      <DeleteModal
        handleClose={handleCloseAdGroupDeleteModal}
        show={showAdGroupDeleteModal}
        key={useId()}
        deleteId={adGroupDeleteId}
        doneWithDelete={doneWithDeleteAdGroup}
        deleteUrl={deleteAdGroupUrl}
        from={GoogleAdOptions.AdGroups.name}
      ></DeleteModal>

      <DeleteModal
        handleClose={handleCloseAdDeleteModal}
        show={showAdDeleteModal}
        key={useId()}
        deleteId={adDeleteId}
        doneWithDelete={doneWithDeleteAd}
        deleteUrl={deleteAdUrl}
        from={GoogleAdOptions.Ads.name}
      ></DeleteModal>
    </>
  );
}

export default SideTreeMenuView;
