import React, { useState, Fragment } from 'react';
import ReactDatePicker from 'react-datepicker';
// import moment from 'moment/moment';
import moment from 'moment-timezone';

import CommonBudget from '../../components/common/commonBudget';
import { dateFormat, compareDates } from '../../utils/validations';

function Budget(props) {
  const segmentCount = 1; // Replace with your actual segmentCount value or pass it as a prop

  const budgetStartDateStatus = compareDates(props?.startDateVal);
  const budgetEndDateStatus = compareDates(props?.endDateVal);

  return (
    <>
      {Array.from({ length: segmentCount }).map((_, index) => (
        <Fragment key={index}>
          <div
            className='card-form form-panel form-panel-group-add'
            key={index}
          >
            <div className='row align-items-center'>
              <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                <CommonBudget
                  id='budget'
                  handler={props?.handleBudgetAmount}
                  // err={props?.numberCheckBudgetAMount}
                  value={props?.budgetVal}
                />
              </div>

              <div className='form-item input-group-label col-box-3'>
                <div className='label-icon'>
                  <label>Start date</label>
                </div>
                <div className='input-group input-width-less'>
                  <span className='input-group-text rs-text'>
                    <i className='icon-calendar'></i>
                  </span>
                  <ReactDatePicker
                    selected={
                      props?.startDateVal && new Date(props?.startDateVal)
                    }
                    onChange={props?.startDateHandler}
                    minDate={new Date()}
                    dateFormat='dd/MM/yyyy'
                    showMonthDropdown
                    showYearDropdown
                    placeholderText='DD/MM/YYYY'
                    name='budget_start_date'
                    disabled={budgetStartDateStatus}
                  />
                </div>
              </div>

              <div className='form-item input-group-label col-box-3'>
                <div className='label-icon'>
                  <label>End date</label>
                </div>
                <div className='input-group input-width-less'>
                  <span className='input-group-text rs-text'>
                    <i className='icon-calendar'></i>
                  </span>
                  <ReactDatePicker
                    selected={props?.endDateVal && new Date(props?.endDateVal)}
                    onChange={props?.endDateHandler}
                    minDate={
                      props?.startDateVal && new Date(props?.startDateVal)
                    }
                    dateFormat='dd/MM/yyyy'
                    showMonthDropdown
                    showYearDropdown
                    placeholderText='DD/MM/YYYY'
                    name='flight_end_date'
                    disabled={budgetEndDateStatus}
                  />
                </div>
              </div>
              {props?.budgetLength > 1 && (
                <div class='media-btn'>
                  <button
                    type='button'
                    class='btn-icon btn-icon-primary btn-bedcrumb-act'
                    onClick={props?.handleDeleteBudget}
                  >
                    <i class='icon-delete'></i>Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ))}
    </>
  );
}

export default Budget;
