import Modal from 'react-bootstrap/Modal';

const ThankYouApprovalPopup = (props) => {
  return (
    <Modal
      show={true}
      className='atd-modal approved-model'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img-cont'></div>
        <div className='modal-text'>
          {props?.approved == 'approved' ? (
            <>
              <h3>Thank you for Approving the request !</h3>
              <p>
                {`The campaign has been sent to ${props?.type || 'Dv360'} for approval and will be publish
                in sometime !`}
              </p>
            </>
          ) : (
            <>
              <h3>Thank you for submitting the campaign</h3>
              <p>You will be notified once it has processed by the approver</p>
            </>
          )}
        </div>
      </Modal.Body>

      <div className='modal-footer'>
        <button
          type='button'
          className='primary-btn'
          onClick={props?.handleRedirect}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};
export default ThankYouApprovalPopup;
