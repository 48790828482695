import React from 'react';
import CardHeader from './CardHeader';

function Card(props) {
  return (
    <>
      <div className='card-primary-repet'>
        <div className='card-primary'>{props.children}</div>
      </div>
    </>
  );
}

export default Card;
