import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';
import ErrorDisplay from '../common/ErrorDisplay';

import { googleBudgetType } from '../../utils/constants';
import CommonDatePicker from '../common/CommonDatePicker';
import { blockInvalidChar, handleFocus } from '../../utils/utils';

const BudgetAndDates = (props) => {
  return (
    <>
      <CardBody>
        <div className='card-form form-panel'>
          <div className='row align-items-center'>
            <div className='form-item input-group-label col-6'>
              <ReactSelect
                options={googleBudgetType}
                value={[
                  {
                    value: 'Daily',
                    label: 'Daily',
                  },
                ]}
                isDisabled={true}
              />
              {/* <span className='error' id='emailError'>
                Please enter valid email id
              </span> */}
            </div>
            <div class='form-item input-group-label col-6'>
              <div class='budget-cont'>
                <div class='label-icon'>
                  <label>Amount</label>
                </div>
                <div class='input-group'>
                  <span class='input-group-text rs-text'>
                    <i class='icon-rupee'></i>
                  </span>
                  <input
                    type='number'
                    id='number'
                    placeholder='X.XX'
                    name='number'
                    onChange={props?.handleBudgetAndDate}
                    value={props?.campaignBudget}
                    readOnly={props?.getCurrentPageStatus}
                    onFocus={handleFocus}
                    onKeyDown={blockInvalidChar}
                  />
                  <span class='input-group-text inr-text rs-text'>INR</span>
                  <ErrorDisplay val={props?.getCampaignBudgetError} />
                </div>
              </div>
              {/* <span class='error' id='emailError'>
                Please enter valid email id
              </span> */}
            </div>
            <div className='form-item input-group-label col-6'>
              <CommonDatePicker
                label={'Start date'}
                changeHandler={props?.startDateHandler}
                selectedDate={props?.selectedStartDate}
              />
            </div>
            <div className='form-item input-group-label col-6'>
              <CommonDatePicker
                label={'End date ∙ Optional'}
                changeHandler={props?.endDateHandler}
                selectedDate={props?.selectedEndDate}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </>
  );
};

export default BudgetAndDates;
