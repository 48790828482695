const CommonSmallDescription = (props) => {
  return (
    <>
      <div class='dv-header-icon'>
        <div class='dv-icon-outer d-flex'>
          <div class='dv-data-icon'>
            <div class='dv-inner-icon d-flex'>
              <p class='dv-text-g'>{props?.copy} </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CommonSmallDescription;
