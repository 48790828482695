import moment from 'moment-timezone';

export const emptyFieldCheck = (val) => {
  if (val == '') {
    return 'Please Select a value';
  }
};

export const lengthFieldCheck = (val) => {
  if (val.length == 0) {
    return 'Please select a value';
  }
};

export const acceptNumValCheck = (val) => {
  const regex = /^[0-9]+$/;
  if (regex.test(val) != true) {
    return 'Please enter the valid Phone number';
  }
};

export const multiSelectVal = (val) => {
  let selectedIds = '';
  val?.map((v) => {
    selectedIds += `${v['value']},`;
  });
  return selectedIds.replace(/,\s*$/, '');
};

export const formatDateToIST = (date) => {
  if (date) {
    let formatedDate = moment.tz(date, 'Asia/Kolkata');
    formatedDate = formatedDate.format();
    return formatedDate;
  }
};

export const dateFormat = (date) => {
  return moment.tz(date, 'Asia/Kolkata').format();
};
export const prefillSelect = (Ids, options) => {
  //Ids = "1,2,3,4"
  let defaultValue = [];
  if (options.length > 0 && Ids?.length > 0) {
    Ids = Ids?.split(',');
    for (let i = 0; i < Ids?.length; i++) {
      let find = options?.find((item) => item?.value == Ids[i]);
      if (find) defaultValue.push(find);
    }
    // console.log("default",defaultValue)
    return defaultValue;
  } else return [];
};

export const compareDates = (selectedDate) => {
  let startD = selectedDate;
  let today = new Date();
  const format = 'YYYY-MM-DD';
  if (startD != 'Invalid date') {
    let res;
    //Compare two dates.
    const result = moment(startD, format).isBefore(moment(today, format));
    // Check whether current date is similar to selected date.
    const isSameCheck = moment(
      moment.tz(startD, 'Asia/Kolkata').format('YYYY-MM-DD'),
    ).isSame(moment.tz(today, 'Asia/Kolkata').format('YYYY-MM-DD'));
    // Set status based on the conditions
    if (isSameCheck) {
      res = false;
    } else if (result) {
      res = true;
    } else {
      res = false;
    }
    return res;
  }
};

export const lineItemUrlFormat = (data) => {
  const redirectUrl =
    data?.optimization_action == ''
      ? `/dv-360/create-line-item/new?insertion_order_id=${data?.insertion_order_id}&campaign_id=${data?.campaign_id}&optimization=${data?.optimization_type}&cID=${data?.taxonomy_campaign_id}&flag=multiple`
      : `/dv-360/create-line-item/new?insertion_order_id=${
          data?.insertion_order_id
        }&campaign_id=${
          data?.campaign_id
        }&optimization=${data?.optimization_type.replace(
          '&',
          'and',
        )},${data?.optimization_action.replace('&', 'and')}&cID=${
          data?.taxonomy_campaign_id
        }&flag=multiple`;
  return redirectUrl;
};
