// import './App.css';
import '../../assets/css/style.css';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import SideTreeMenu from '../../components/common/SideTreeMenu';
// import FormHeader from "../../components/common/FormHeader";
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import InfoText from '../../components/common/InfoText';
import AdvancedCardHeader from '../../components/common/AdvancedCardHeader';
import AddSection from '../../components/common/AddSection';
import CurrencyInputField from '../../components/common/CurrencyInputField';
import Button from '../../components/common/Button';
import DateSelectField from '../../components/common/DateSelectField';
import noCreativesAssigned from '../../assets/images/creative.png';
import creative from '../../assets/images/creative.png';
import slideshow from '../../assets/images/slideshow.jpg';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { Fragment, useEffect, useState } from 'react';
import Footer from '../../components/common/Footer';
// import MultipleSelect from "../../components/common/MultipleSelect";
// import DaysInputField from "../../components/common/DaysInputField";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
// import moment from "moment/moment";
import TextareaAutosize from 'react-textarea-autosize';
import ReactSelect from '../../components/common/ReactSelect';

const creativeArr = [];
function RtbVideo() {
  const initialvalues = {
    // code
  };
  const [RtbVideoData, setRtbVideoData] = useState(initialvalues);
  const [formErrors, setFormErrors] = useState({});
  const [date, setdate] = useState('');
  const [date1, setdate1] = useState('');

  Array.prototype.remove = function (value) {
    this.splice(this.indexOf(value), 1);
  };

  const onChangeHandler = (e) => {};

  const handleSubmit = (e) => {
    setFormErrors(validate());
  };
  const validate = (formData) => {
    const errors = {};
    // code
    return errors;
  };
  useEffect(() => {
    console.log(RtbVideoData, 'data');
    console.log(formErrors);
  });
  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box active padding-bottom-extra'>
            {/* <Header /> */}
            {/* header starts here */}

            <header>
              <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
                <div className='top-left-bar'>
                  <ul className='nav-bedcrumb d-flex'>
                    <li>
                      <button
                        className='btn-icon btn-icon-primary btn-bedcrumb '
                        type='button'
                      >
                        <i className='icon-camopaign'></i> New Campaign
                      </button>
                    </li>
                    <li>
                      <button
                        className='btn-icon btn-icon-primary btn-bedcrumb '
                        type='button'
                      >
                        <i className='icon-ad-set'></i> New insertion order
                      </button>
                    </li>
                    <li>
                      <button
                        className='btn-icon btn-icon-primary btn-bedcrumb btn-bedcrumb-act'
                        type='button'
                      >
                        <i className='icon-ad'></i> New Line item
                      </button>
                    </li>
                  </ul>
                </div>
                <div className='top-right-bar'>
                  <div className='btn-box'>
                    <button
                      type='button'
                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                    >
                      Save as draft
                    </button>
                    <button type='button' className='primary-btn close-btn'>
                      <i className='icon-close'></i>
                    </button>
                  </div>
                </div>
              </div>
            </header>
            {/* header ends here */}
            <section className='side-menu-form d-flex'>
              {/* <SideTreeMenu /> */}

              {/* sidetreemenu starts */}

              <article className='side-tree-menu'>
                {/* <div className="status-cont-top">
                  <div className="status-lt">
                    <span>Status:</span>
                    <span><strong>Published</strong> (Enabled)</span>
                  </div>
                  <div className="status-btn">
                    <button type="button" className="primary-btn close-btn" href="javascript:void(0)">
                      <i className="icon-right-arrow"></i>
                    </button>
                  </div>
                </div> */}
                <div className='nav-left scroll-vertical'>
                  <nav className='navbar'>
                    <ul className='navbar-nav'>
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          aria-current='page'
                          href='javascript:void(0)'
                        >
                          <i className='icon-camopaign'></i>
                          <span>New Campaign</span>
                        </a>
                        <div className='dropdown'>
                          <button
                            type='button'
                            className='primary-btn close-btn dot-btn-primary'
                            id='dropdownTable'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='icon-meni-hori'></i>
                          </button>
                          <ul
                            className='dropdown-menu dropdown-menu-lg-end'
                            aria-labelledby='dropdownTable'
                          >
                            <li>
                              <a className='dropdown-item' href='#'>
                                Action
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Another action
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className='nav-item'>
                        <a href='javascript:void(0)' className='nav-link '>
                          <i className='icon-ad-set'></i>
                          <span> New insertion order</span>
                        </a>
                        <div className='dropdown'>
                          <button
                            type='button'
                            className='primary-btn close-btn dot-btn-primary'
                            id='dropdownMenuButton2'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='icon-meni-hori'></i>
                          </button>
                          <ul
                            className='dropdown-menu dropdown-menu-lg-end'
                            aria-labelledby='dropdownMenuButton2'
                          >
                            <li>
                              <a className='dropdown-item' href='#'>
                                Duplicate
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Copy{' '}
                              </a>
                            </li>
                            <li>
                              <a className='dropdown-item' href='#'>
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                        <ul>
                          <li>
                            <a href='#' className='nav-link active'>
                              <i className='icon-ad'></i>
                              <span>New Line item</span>
                            </a>
                          </li>
                          {/* <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                          <i className="icon-ad-set"></i>
                          <span> New awareness ad set 2</span>
                        </a>
                        <ul>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i>
                              <span>New awareness ad 1</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </article>

              {/* sidetree menu ends */}
              <article className='side-right-form-wrap'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        {/* 
                                                {/* <Card>
                                                    <CardHeader>
                                                        <h5 className="d-flex align-items-center">
                                                            insertion order type
                                                            {/* <span className="label-information tooltips tool-bottom" tooltip="Cascading Style Sheets"
                                                                tooltip-position="top"> <i className="icon-info"></i></span> */}
                        {/* </h5>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="card-form form-panel">
                                                            <div className="row align-items-center">
                                                                <div className="action-btn-cont pt-3 pb-3">
                                                                    <div
                                                                        className="action-btn-both row"
                                                                        onChange={(e) => {
                                                                            onChangeHandler(e);
                                                                        }}
                                                                    >
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="1"
                                                                                id="flexRadioDefault1"
                                                                                defaultChecked
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault1"
                                                                            >
                                                                                <i className="icon-display"></i>
                                                                                Display
                                                                                {/* <i className="icon-info"></i> */}
                        {/* </label> */}
                        {/* </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="2"
                                                                                id="flexRadioDefault2"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault2"
                                                                            >
                                                                                <i className="icon-video-one"></i>Video
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="2"
                                                                                id="flexRadioDefault21"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault21"
                                                                            >
                                                                                <i className="icon-traffic"></i>Audio
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="3"
                                                                                id="flexRadioDefault6"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault6"
                                                                            >
                                                                                <i className="icon-ytube-part"></i>{" "}
                                                                                YouTube & partners video
                                                                            </label>
                                                                        </div> 
                                                                        <div className="form-check-box col-12">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="campaignGoal"
                                                                                value="3"
                                                                                id="flexRadioDefault61"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault61"
                                                                            >
                                                                                <i className="icon-sales"></i> YouTube &
                                                                                partners audio
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>  */}
                        <Card>
                          <div class='card-primary'>
                            <div class='card-header-top d-flex'>
                              <div class='card-lt-pane'>
                                <h5>Line item name</h5>
                              </div>
                              <div class='me-auto card-switch-rigth'>
                                <div class='form-panel select-with-side-label'>
                                  <div class='form-item'></div>
                                </div>
                              </div>
                            </div>
                            <div class='card-body'>
                              <div class='card-form form-panel'>
                                <div class='row align-items-center'>
                                  <div class='form-item col-12'>
                                    <div class='label-icon'>
                                      <label>Line item name</label>
                                    </div>
                                    <div class='input-group'>
                                      <input
                                        type='email'
                                        id='email'
                                        placeholder='Enter line item name'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5>Inventory source</h5>
                          </CardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <p>
                                New insertion orders and line items in this
                                campaign will inherit these settings.
                              </p>
                              {/* <SimpleSelectBox></SimpleSelectBox> */}
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Quality</h6>
                              <SimpleSelectBox
                                name='quality'
                                placeholder='Select...'
                                options={[
                                  'Authorized Direct Sellers',
                                  'Authorized Direct Sellers And Resellers',
                                  'Authorized and Non-Participating Publishers',
                                ]}
                                onChangeHandler={onChangeHandler}
                              ></SimpleSelectBox>
                              {/* <div class="dv-header-icon">
                                                                <div class="dv-icon-outer d-flex">
                                                                    <div class="dv-data-icon">
                                                                        <div class="dv-inner-icon d-flex">
                                                                            <p class="dv-text-g">Authorised and Non-partcipating Publishers. </p>
                                                                            <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div> */}
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Public Inventory</h6>
                              {/* <SimpleSelectBox></SimpleSelectBox> */}
                              <ReactSelect
                                isMulti={true}
                                options={[
                                  { label: 'Adform', value: 'Adform' },

                                  { label: 'AdsWizz', value: 'AdsWizz' },

                                  { label: 'Aja', value: 'Aja' },

                                  {
                                    label: 'Dailymotion',

                                    value: 'Dailymotion',
                                  },

                                  {
                                    label: 'Digital Turbine',

                                    value: 'Digital Turbine',
                                  },

                                  { label: 'Equativ', value: 'Equativ' },

                                  { label: 'Five', value: 'Five' },

                                  { label: 'Fluct', value: 'Fluct' },

                                  {
                                    label: 'AFreeWheel SSP',

                                    value: 'FreeWheel SSP',
                                  },
                                ]}
                              />
                              {/* <div class="dv-header-icon">
                                                                <div class="dv-icon-outer d-flex">
                                                                    <div class="dv-icon">
                                                                        <i class="icon-check-mark"></i>
                                                                    </div>
                                                                    <div class="dv-data-icon">
                                                                        <p>Target the following regions</p>
                                                                        <div class="dv-inner-icon d-flex">
                                                                            <p class="dv-text-g">India (Country)</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Deals and inventory packages.</h6>
                              {/* <SimpleSelectBox></SimpleSelectBox> */}
                              <ReactSelect
                                isMulti={true}
                                options={[
                                  {
                                    label: '$150k+ HHI on CTV Devices',
                                    value: '$150k+ HHI on CTV Devices',
                                  },
                                  {
                                    label: '$50k+ HHI on CTV Devices',
                                    value: '$50k+ HHI on CTV Devices',
                                  },
                                  {
                                    label:
                                      '0.10%+ CTR Content - GumGum Marketplace',
                                    value:
                                      '0.10%+ CTR Content - GumGum Marketplace',
                                  },
                                  {
                                    label:
                                      '0.2%+ High CTR AMER-US Display All Devices Always On',
                                    value:
                                      '0.2%+ High CTR AMER-US Display All Devices Always On',
                                  },
                                  {
                                    label:
                                      '0.30%+ CTR Content - GumGum Marketplace',
                                    value:
                                      '0.30%+ CTR Content - GumGum Marketplace',
                                  },
                                  {
                                    label: '1F-DV360-OneFOX-121422',
                                    value: '1F-DV360-OneFOX-121422',
                                  },
                                  {
                                    label:
                                      '1ur-460529l_DGT_SAMSUNG_RB_underDelivery_20220823',
                                    value:
                                      '1ur-460529l_DGT_SAMSUNG_RB_underDelivery_20220823',
                                  },
                                  {
                                    label:
                                      "1ur-460533l_IN_CNT_Samsung_PG_ROS_Aug'22",
                                    value:
                                      "1ur-460533l_IN_CNT_Samsung_PG_ROS_Aug'22",
                                  },
                                  {
                                    label:
                                      '202452210INTLGOOGLETILBOTHINATFCPDENGNEWSRCH2FOR',
                                    value:
                                      '202452210INTLGOOGLETILBOTHINATFCPDENGNEWSRCH2FOR',
                                  },
                                ]}
                              ></ReactSelect>
                              {/* <div class="dv-header-icon">
                                                                <div class="dv-icon-outer d-flex">
                                                                    <div class="dv-data-icon">
                                                                        <div class="dv-inner-icon d-flex">
                                                                            <p class="dv-text-g">0 deals and inventory packages selected. </p>
                                                                             <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div> */}
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Groups</h6>
                              <ReactSelect
                                isMulti={true}
                                options={[
                                  { label: 'test1', value: 'test1' },
                                  { label: 'test2', value: 'test2' },
                                  { label: 'test3', value: 'test3' },
                                  { label: 'test4', value: 'test4' },
                                  { label: 'test5', value: 'test5' },
                                ]}
                              ></ReactSelect>
                              {/* <SimpleSelectBox></SimpleSelectBox> */}
                              {/* <div class="dv-header-icon">
                                                                <div class="dv-icon-outer d-flex">
                                                                    <div class="dv-data-icon">
                                                                        <div class="dv-inner-icon d-flex">
                                                                            <p class="dv-text-g">No inventory group selected. </p>
                                                                             <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> 
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div> */}
                            </div>
                          </AdvancedCardHeader>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5>Targetting</h5>
                            <p>
                              New insertion orders and line items in this
                              campaign will inherit these settings.
                            </p>
                          </CardHeader>
                          <CardBody>
                            <ReactSelect placeholder='Select'></ReactSelect>
                          </CardBody>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Demographics</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        All genders,ages,parental statuses and
                                        household incomes.{' '}
                                      </p>
                                      {/* <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h6>Geography</h6>
                              <div className='dv-header-icon'>
                                <div className='dv-icon-outer d-flex'>
                                  <div className='dv-icon'>
                                    <i className='icon-check-mark' />
                                  </div>
                                  <div className='dv-data-icon'>
                                    <p>Target the following regions</p>
                                    <div className='dv-inner-icon d-flex'>
                                      <p className='dv-text-g'>
                                        India (Country)
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='dv-header-icon'>
                                <div className='dv-icon-outer d-flex'>
                                  <div className='dv-icon'>
                                    <i className='icon-check-mark' />
                                  </div>
                                  <div className='dv-data-icon'>
                                    <p>Target the following regions</p>
                                    <div className='dv-inner-icon d-flex'>
                                      <p className='dv-text-g'>
                                        India (Country)
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Language</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>All languages </p>
                                      {/* <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Brand safety</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        No inventory group selected.{' '}
                                      </p>
                                      {/* <span class="dv-icon">
                                                                                <i class="icon-lock"></i>
                                                                            </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                        </Card>
                        <Card>
                          <div className='card-header-top'>
                            <div className='card-lt-pane'>
                              <h5>Optimized targeting</h5>

                              <div className='action-btn-both action-btn-both-check row'>
                                <div className='form-check-box'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='inlineCheckbox1'
                                    defaultValue='option1'
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='inlineCheckbox1'
                                  >
                                    Use optimized targeting
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='info-icon-body'>
                              <div className='more-info-icon'>
                                <i className='icon-bulb' />
                              </div>
                              <div className='more-info-text'>
                                <p>
                                  Optimized targeting may help you reach your
                                  campaign's goals by finding new people beyond
                                  your selected audience list.
                                </p>
                                <p>
                                  For this line item to be eligible to use
                                  optimized targeting, use audience list
                                  targeting and turn on automated bidding.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Card>
                        <Card>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Flight dates</h5>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div className='action-btn-both action-btn-no-icon row'>
                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefault'
                                          id='flexRadioDefault50'
                                          defaultChecked=''
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault50'
                                        >
                                          Use same dates as insertion order
                                          {/* <p>Jul 18, 2023 - Aug 18, 2023</p> */}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefault'
                                          id='flexRadioDefault51'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault51'
                                        >
                                          Custom dates
                                        </label>
                                      </div>
                                      <div className='dv-from-check-field'>
                                        <div className='row align-items-center'>
                                          <div className='form-item input-group-label col-6'>
                                            <div className='label-icon'>
                                              <label>Start date</label>
                                            </div>
                                            <div className='input-group'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-calendar' />
                                              </span>
                                              {/* <input
                                                                                                    type="text"
                                                                                                    id="datepicker"
                                                                                                    placeholder="DD/MM/YYYY"
                                                                                                /> */}
                                              <ReactDatePicker
                                                selected={date1}
                                                onChange={(e) => setdate1(e)}
                                                dateFormat='dd/MM/yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText='DD/MM/YYYY'
                                              />
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                          <div className='form-item input-group-label col-6'>
                                            <div className='label-icon'>
                                              <label>End date</label>
                                            </div>
                                            <div className='input-group time'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-calendar' />
                                              </span>
                                              {/* <input
                                                                                                    type="text"
                                                                                                    id="datepicker"
                                                                                                    placeholder="DD/MM/YYYY"
                                                                                                /> */}
                                              <ReactDatePicker
                                                selected={date}
                                                onChange={(e) => setdate(e)}
                                                dateFormat='dd/MM/yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText='DD/MM/YYYY'
                                              />
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                        <Card>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Budget</h5>
                              <p>
                                Budget and pacing depend on both insertion order
                                and line item settings.
                              </p>
                            </div>
                            <div className='me-auto card-switch-rigth'>
                              <div className='form-panel select-with-side-label'>
                                <div className='form-item'>
                                  <div className='label-icon'>
                                    <label>Budget Type</label>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Select
                                      </option>
                                      <option value=''>INR</option>
                                      {/* <option value="coffee">Impressions</option> */}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='card-header-top'>
                            <div className='card-lt-pane'>
                              <h6>Budget & pacing</h6>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div className='action-btn-both action-btn-no-icon row'>
                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultz'
                                          id='flexRadioDefault500'
                                          defaultChecked=''
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault500'
                                        >
                                          {' '}
                                          Unlimited up to insertion order's
                                          budget.
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-12 px-0 d-flex'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultz'
                                          id='flexRadioDefault501'
                                          defaultChecked=''
                                        />
                                      </div>

                                      <div className='card-form form-panel form-panel-group-add mt-0 form-panel-no-border ps-5'>
                                        <div className='form-item input-group-label col-5'>
                                          <div className='budget-cont'>
                                            <div className='label-icon'>
                                              <label>Amount</label>
                                            </div>
                                            <div className='input-group'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-rupee' />
                                              </span>
                                              <input
                                                type='email'
                                                id='email'
                                                placeholder='X.XX'
                                                name='Planned spend'
                                                onChange={onChangeHandler}
                                              />
                                              <span className='input-group-text inr-text rs-text'>
                                                INR
                                              </span>
                                            </div>
                                          </div>
                                          {/* <span className="error ps-0" id="emailError">
                                                                                            Please enter valid email id
                                                                                        </span> */}
                                        </div>
                                        <div className='row align-items-center'>
                                          <div className='form-item col-box-3'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  Daily
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                          <div className='form-item col-box-3'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  Even
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                          <div className='col-12'>
                                            <div className='or-line'>or</div>
                                          </div>

                                          <div className='form-item col-box-3'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  Daily
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                          <div className='form-item col-box-3'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <div className='select-box'>
                                              <select name='drinks' required=''>
                                                <option
                                                  value=''
                                                  disabled=''
                                                  selected=''
                                                >
                                                  Even
                                                </option>
                                                <option value='coffee'>
                                                  Coffee
                                                </option>
                                                <option value='tea'>Tea</option>
                                                <option value='milk'>
                                                  Milk
                                                </option>
                                              </select>
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                          <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                            <div className='label-icon'>
                                              <label>Amount</label>
                                            </div>
                                            <div className='input-group'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-rupee' />
                                              </span>
                                              <input
                                                type='email'
                                                id='email'
                                                placeholder='X.XX'
                                              />
                                              <span className='input-group-text inr-text rs-text'>
                                                INR
                                              </span>
                                            </div>
                                            {/* <span className="error" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultz'
                                          id='flexRadioDefault502'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault502'
                                        >
                                          Control bid and budget at the line
                                          item level
                                        </label>
                                      </div>
                                      <div className='dv-from-check-field'>
                                        <div className='dv-check-box'>
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox81'
                                                defaultValue='option1'
                                              />
                                              <label
                                                className='form-check-labe2'
                                                htmlFor='inlineCheckbox81'
                                              >
                                                Prioritize deals over open
                                                auction inventory
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='card-header-top'>
                            <div className='card-lt-pane'>
                              <h6>Bid strategy</h6>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div className='action-btn-both action-btn-no-icon row'>
                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultc'
                                          id='flexRadioDefault503'
                                          defaultChecked=''
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault503'
                                        >
                                          Automate bidding
                                        </label>
                                      </div>
                                      <div className='dv-from-check-field'>
                                        <div className='form-item'>
                                          <div className='label-icon'>
                                            <label>Select</label>
                                          </div>
                                          <div className='select-box'>
                                            <select name='drinks' required=''>
                                              <option
                                                value=''
                                                disabled=''
                                                selected=''
                                              >
                                                Maximize viewable impressions
                                              </option>
                                              <option value='coffee'>
                                                Coffee
                                              </option>
                                              <option value='tea'>Tea</option>
                                              <option value='milk'>Milk</option>
                                            </select>
                                          </div>
                                          {/* <span className="error ps-0" id="emailError">
                                                                                            Please enter valid email id
                                                                                        </span> */}
                                        </div>
                                        <div className='form-item'>
                                          <div className='label-icon'>
                                            <label>Select</label>
                                          </div>
                                          <div className='select-box'>
                                            <select name='drinks' required=''>
                                              <option
                                                value=''
                                                disabled=''
                                                selected=''
                                              >
                                                while prioritizing spending my
                                                full budget (recommended)
                                              </option>
                                              <option value='coffee'>
                                                Coffee
                                              </option>
                                              <option value='tea'>Tea</option>
                                              <option value='milk'>Milk</option>
                                            </select>
                                          </div>
                                          {/* <span className="error ps-0" id="emailError">
                                                                                            Please enter valid email id
                                                                                        </span> */}
                                        </div>

                                        <div className='d-flex dv-check-box'>
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox7'
                                                defaultValue='option1'
                                              />
                                              <label
                                                className='form-check-labe2'
                                                htmlFor='inlineCheckbox7'
                                              >
                                                Do not exceed average CPM of
                                              </label>
                                            </div>
                                          </div>
                                          <div className='form-item input-group-label'>
                                            <div className='label-icon'>
                                              <label>Amount</label>
                                            </div>
                                            <div className='input-group'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-rupee' />
                                              </span>
                                              <input
                                                type='email'
                                                id='email'
                                                placeholder='X.XX'
                                              />
                                              <span className='input-group-text inr-text rs-text'>
                                                INR
                                              </span>
                                            </div>
                                            {/* <span className="error px-0" id="emailError">
                                                                                                Please enter valid email id
                                                                                            </span> */}
                                          </div>
                                        </div>
                                        <div className='dv-check-box'>
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox8'
                                                defaultValue='option1'
                                              />
                                              <label
                                                className='form-check-labe2'
                                                htmlFor='inlineCheckbox8'
                                              >
                                                Prioritize deals over open
                                                auction inventory
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultc'
                                          id='flexRadioDefault511'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault511'
                                        >
                                          Fixed bidd
                                        </label>
                                      </div>
                                      <div className='dv-from-check-field'>
                                        <div className='dv-check-box'>
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-item input-group-label col-5'>
                                              <div className='label-icon'>
                                                <label>Amount</label>
                                              </div>
                                              <div className='input-group'>
                                                <span className='input-group-text rs-text'>
                                                  <i className='icon-rupee' />
                                                </span>
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder='X.XX'
                                                />
                                                <span className='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                              {/* <span className="error px-0" id="emailError">
                                                                                                    Please enter valid email id
                                                                                                </span> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='card-header-top'>
                            <div className='card-lt-pane'>
                              <h6 className='d-flex align-items-center'>
                                Frequency cap
                              </h6>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel card-form-days dv-card-form-days'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div className='action-btn-both action-btn-no-icon row'>
                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultv'
                                          id='flexRadioDefault232'
                                          defaultChecked=''
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault232'
                                        >
                                          No limit
                                        </label>
                                      </div>
                                    </div>
                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='flexRadioDefaultv'
                                          id='flexRadioDefault333'
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault333'
                                        >
                                          Limit frequency to
                                        </label>
                                      </div>
                                      <div className='form-item d-flex align-items-center me-3'>
                                        <input
                                          type='email'
                                          id='email'
                                          placeholder='Enter a value'
                                        />
                                        <label>exposures per </label>
                                        {/* <span className="error px-0" id="emailError">
                                                                                        Please enter valid email id
                                                                                    </span> */}
                                      </div>
                                      <div className='form-item d-flex align-items-center'>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Lifetime of this campaign'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                        ></SimpleSelectBox>
                                        {/* <div className="select-box">
                                                                                    <select name="drinks" required="">
                                                                                        <option value="" disabled="" selected="">
                                                                                            Lifetime of this campaign
                                                                                        </option>
                                                                                        <option value="coffee">Coffee</option>
                                                                                        <option value="tea">Tea</option>
                                                                                        <option value="milk">Milk</option>
                                                                                    </select>
                                                                                </div> */}
                                        {/* <span className="error px-0" id="emailError">
                                                                                        Please enter valid email id
                                                                                    </span> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>

                        <Card>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Creatives</h5>
                              {/* <p className="price-highlight">Advanced ad serving rate: ₹0.00 CPM</p> */}
                            </div>
                            <div className='me-auto card-switch-rigth'>
                              <div className='form-panel select-with-side-label'>
                                <div className='form-item'>
                                  <div className='label-icon'>
                                    <label>Creative optimization</label>
                                    <span
                                      className='label-information tooltips'
                                      tooltip='Cascading Style Sheets'
                                      tooltip-position='top'
                                    >
                                      {' '}
                                      <i className='icon-info' />
                                    </span>
                                  </div>
                                  <div className='select-box'>
                                    <select name='drinks' required=''>
                                      <option value='' disabled='' selected=''>
                                        Clicks
                                      </option>
                                      <option value='coffee'>Conversion</option>
                                      <option value='coffee'>Even</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='crt-box-data'>
                              <div className='crt-img-cont'>
                                <figure className='crt-img'>
                                  <img
                                    src={noCreativesAssigned}
                                    alt='No creatives assigned'
                                  />
                                </figure>
                                <div className='crt-text'>
                                  <p>No creatives assigned</p>
                                </div>
                              </div>
                              {/* <input type="file" /> */}
                              <div className='media-btn'>
                                <button
                                  type='button'
                                  className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                >
                                  Assign creative
                                  <input
                                    style={{ width: '100%', height: '100%' }}
                                    type='file'
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5>Conversion Tracking</h5>
                          </CardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <p>
                                <b>
                                  {' '}
                                  select the activity or conversion that
                                  represents a succcesful conversion
                                </b>
                              </p>
                            </div>
                          </AdvancedCardHeader>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h6> Conversion Counting </h6>
                          </CardHeader>
                          <div className='form-item'>
                            <div className='label-icon'>
                              <label>Conversion</label>
                            </div>
                            <div className='select-box'>
                              <select name='drinks' required=''>
                                <option value='' disabled='' selected=''>
                                  Primary model
                                </option>
                                <option value='Count all conversions'>
                                  Count all conversions
                                </option>
                                <option value='Count only post-click conversions'>
                                  Count only post-click conversions
                                </option>
                                <option value='count percentage of conversion'>
                                  count percentage of conversion
                                </option>
                              </select>
                            </div>
                            {/* <span className="error ps-0" id="emailError">
                                                                                            Please enter valid email id
                                                                                        </span> */}
                          </div>

                          <CardHeader>
                            <h6>
                              {' '}
                              Select the attribution model to use the conversion
                              measurement{' '}
                            </h6>
                          </CardHeader>
                          <div className='form-item'>
                            <div className='label-icon'>
                              <label>Conversion</label>
                            </div>
                            <div className='select-box'>
                              <select name='drinks' required=''>
                                <option value='' disabled='' selected=''>
                                  Primary model
                                </option>
                                <option value='Primary model'>
                                  Primary model
                                </option>
                                <option value='Outcome Based Buying - Pay-Per-Active View (read only)'>
                                  Outcome Based Buying - Pay-Per-Active View
                                  (read only)
                                </option>
                                <option value='Floodlight attribution model'>
                                  Floodlight attribution model
                                </option>
                              </select>
                            </div>
                            {/* <span className="error ps-0" id="emailError">
                                                                                            Please enter valid email id
                                                                                        </span> */}
                          </div>
                        </Card>

                        <Card>
                          <div className='card-header-top'>
                            <div className='card-lt-pane'>
                              <h5>Note</h5>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Note</label>
                                  </div>
                                  <div className='input-sizer stacked'>
                                    {/* <textarea
                                                                            // onInput="this.parentNode.dataset.value = this.value"
                                                                            rows={1}
                                                                            placeholder="Type Reason"
                                                                        /> */}
                                    <TextareaAutosize />
                                  </div>

                                  {/* <span className="error" id="emailError">
                                                                            Please enter valid email id
                                                                        </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </article>
                    </div>
                  </div>
                  {/* view starts */}
                  <div
                    className='tab-pane fade'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        <div className='card-primary'>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Campaign name</h5>
                              <p>New Awareness Campaign</p>
                              <p>ID: 23857805967620728</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Special ad categories</h5>
                              <p>No categories declared</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Buying type</h5>
                              <p>Awareness</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Ad sets</h5>
                              <p className='add-data'>Please add: Ad sets</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Format</h5>
                              <p>Image and Video</p>
                            </div>
                            <div className='card-rt-pane d-flex me-auto align-items-center'>
                              <figure className='prev-icon'>
                                <img src={atdplaceholder} />
                              </figure>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* view ends */}
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  ></div>
                </div>
              </article>
            </section>
          </div>
          <Footer
            prevPath='/dv-360-insertion-order'
            handleSubmit={handleSubmit}
            nextBtnlabel='Submit'
          ></Footer>
        </div>
      </div>
    </div>
  );
}

export default RtbVideo;
