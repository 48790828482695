import { useEffect, useState, useId } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import DeleteModalEditFlow from './DeleteModalEditFlow';
import { DV360Options } from '../../pages/DV360/DV360Constant';
import { capitalizeFirstLetter } from '../../utils/utils';

function SideTreeMenuForCreate(props) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [showCampaignDeleteModal, setShowCampaignDeleteModal] = useState(false);
  const [campaignDeleteId, setCampaignDeleteId] = useState([]);
  const [getInsertionOrderId, setInsertionOrderId] = useState('');
  const [getLineItems, setLineItems] = useState([]);
  const [getSelectedLineItemIndex, setSelectedLineItemIndex] = useState();

  let treeData = props?.campaignTreeData;
  let campaignName = treeData?.campaign_name;
  let campaignId = treeData?.campaign_id;
  let insertionOrders = treeData?.insertionOrders;
  let isCreateMode = props?.isCreateMode;
  let moderationStatus = props?.campaignTreeData?.moderation_status;
  let getInsertionOrders = treeData?.insertionOrders;
  const navigate = useNavigate();

  let Id = props?.IdAndType?.id;
  let Type = props?.IdAndType?.type;
  let displayStatus = props?.showTreeMenu;

  useEffect(() => {
    if (Id) {
      if (Type == 'campaign') {
        setSelectedItemId(-1);
        setSelectedItemType('campaign');
      } else if (Type == 'insertion') {
        setSelectedItemId(Id);
        setSelectedItemType('insertionOrder');
      } else if (Type == 'lineItem') {
        setSelectedItemId(Id);
        setSelectedItemType('lineItem');
      }
    }
  }, [Id]);

  const handleClick = (item) => {
    if (item) {
      if (item?.insertion_order_id && item?.line_item_id) {
        setSelectedItemId(item?.line_item_id);
        setSelectedItemType('lineItem');
      } else if (item?.insertion_order_id) {
        setSelectedItemId(item?.insertion_order_id);
        setSelectedItemType('insertionOrder');
      } else if (item == 'newInsertion') {
        setSelectedItemId(null);
        setSelectedItemType('insertionOrder');
      } else if (item == 'newLineItem') {
        setSelectedItemId(null);
        setSelectedItemType('lineItem');
      } else if ((item = 'newCampaign')) {
        setSelectedItemId(-1);
        setSelectedItemType('campaign');
      }
    }
  };
  const handleDirection = (
    type,
    campaign_id,
    insertion_id,
    lineItem_id,
    optimization,
    taxonomy_campaign_id,
  ) => {
    if (type == 'campaign' && campaign_id) {
      navigate(`/dv-360/create-campaign/${campaign_id}`, {
        state: { isCreateFlow: isCreateMode },
      });
    } else if (type == 'insertion' && insertion_id) {
      navigate(`/dv-360/create-insertion-order/${insertion_id}`, {
        state: { isCreateFlow: isCreateMode },
      });
    } else if (type == 'lineItem' && lineItem_id) {
      navigate(`/dv-360/create-line-item/${lineItem_id}`, {
        state: { isCreateFlow: isCreateMode },
      });
    } else if (type == 'newLineItem') {
      // `/dv-360/create-line-item/new?insertion_order_id=${response?.data?.insertionorderID}&campaign_id=${getQueryParam?.campaign_id}&optimization=${getSelectedOptimization}&cID=${DvInsertionOrderData?.taxonomy_campaign_id}`
      let reDirURL = `/dv-360/create-line-item/new?insertion_order_id=${insertion_id}&campaign_id=${campaignId}&optimization=${optimization}&cID=${taxonomy_campaign_id}`;
      navigate(reDirURL, {
        state: { isCreateFlow: isCreateMode, campaignId: campaignId },
      });
    } else if (type == 'newInsertion') {
      // alert(campaignId)
      navigate(`/dv-360/create-insertion-order/new?campaign_id=${campaignId}`, {
        state: { isCreateFlow: isCreateMode, campaignId: campaignId },
      });
    }
  };

  const handleDelete = (id, type, InsertionIndex = 0, lineItemIndex) => {
    setCampaignDeleteId([id]);
    setSelectedItemType(type);
    setShowCampaignDeleteModal(true);

    if (type == 'lineItem') {
      setInsertionOrderId(
        getInsertionOrders?.[InsertionIndex]?.insertion_order_id,
      );
      setLineItems(getInsertionOrders[InsertionIndex]?.lineItems);
      setSelectedLineItemIndex(lineItemIndex);
    }
  };

  const handleCloseCampaignDeleteModal = () =>
    setShowCampaignDeleteModal(false);

  const doneWithDelete = () => {
    if (selectedItemType == 'campaign') {
      navigate(`${DV360Options.Campaigns.url}`);
      setSelectedItemType('');
    } else if (
      selectedItemType == 'insertionOrder' &&
      props?.pageType == 'campaign'
    ) {
      navigate(`${DV360Options.Campaigns.editUrl}/${campaignId}`);
    } else if (
      selectedItemType == 'insertionOrder' &&
      props?.pageType == 'insertionOrder'
    ) {
      if (getInsertionOrders?.length > 1) {
        let getSecondLastInsertionOrderId =
          getInsertionOrders?.length > 2
            ? getInsertionOrders[getInsertionOrders?.length - 1]
                ?.insertion_order_id
            : getInsertionOrders[0]?.insertion_order_id;
        navigate(
          `${DV360Options.Insertion_Order.editUrl}/${getSecondLastInsertionOrderId}`,
        );
      } else {
        navigate(`${DV360Options.Campaigns.editUrl}/${campaignId}`);
      }
    } else if (
      selectedItemType == 'insertionOrder' &&
      props?.pageType == 'lineItem'
    ) {
      navigate(`${DV360Options.Campaigns.editUrl}/${campaignId}`);
    } else if (
      selectedItemType == 'lineItem' &&
      props?.pageType == 'lineItem'
    ) {
      let getLength = getLineItems?.length;
      if (getLength == 1) {
        navigate(
          `${DV360Options.Insertion_Order.editUrl}/${getInsertionOrderId}`,
        );
      } else if (getLength > 1) {
        let increaseIndexCount = getSelectedLineItemIndex + 1;
        if (increaseIndexCount < getLength) {
          navigate(
            `${DV360Options.Line_Item.editUrl}/${
              getLineItems?.[getLength - 1]?.line_item_id
            }`,
          );
        } else {
          navigate(
            `${DV360Options.Line_Item.editUrl}/${
              getLineItems?.[getLength - 2]?.line_item_id
            }`,
          );
        }
      }
    } else if (
      selectedItemType == 'lineItem' &&
      props?.pageType == 'insertionOrder'
    ) {
      if (getLength == 1) {
        navigate(
          `${DV360Options.Insertion_Order.editUrl}/${getInsertionOrderId}`,
        );
      }
    }
  };

  const statusClass = {
    Draft: 'status-draft',
    'In Moderation': 'status-moderation',
    Published: 'status-published',
    published: 'status-published',
    Rejected: 'status-rejected',
    api_error: 'status-error',
    'Published(Enabled)': 'status-published',
    'Published(Disabled)': 'status-na-disable',
    draft: 'status-draft',
    in_moderation: 'status-moderation',
    null: 'status-na-disable',
  };
  const atdStatusClassName = `${statusClass[props?.AtdStatus]} status-cont-top`;
  const Dv360StatusClassName = `${
    statusClass[props?.Dv360Status]
  } status-cont-top`;
  const Dv360StatusText =
    props?.Dv360Status?.toLowerCase() == 'enabled'
      ? 'Published(Enabled)'
      : props?.Dv360Status;

  return (
    <>
      <article className='side-tree-menu'>
        <>
          {props?.AtdStatus && props?.Dv360Status && (
            <>
              <div className={atdStatusClassName}>
                <div className='status-cont-left'>
                  <div className='status-lt'>
                    <span>ATD Status :</span>
                    <span>{capitalizeFirstLetter(props?.AtdStatus)}</span>
                  </div>
                </div>
                <br />

                <div className='status-btn'>
                  <button
                    type='button'
                    className='primary-btn close-btn'
                    href='javascript:void(0)'
                  >
                    <i className='icon-right-arrow'></i>
                  </button>
                </div>
              </div>
              {/* <br /> */}
              <div className={Dv360StatusClassName}>
                <div className='status-cont-left'>
                  <div className='status-lt'>
                    <span>DV360 Status :</span>
                    <span>{capitalizeFirstLetter(Dv360StatusText)}</span>
                  </div>
                </div>

                <div className='status-btn'>
                  <button
                    type='button'
                    className='primary-btn close-btn'
                    href='javascript:void(0)'
                  >
                    <i className='icon-right-arrow'></i>
                  </button>
                </div>
              </div>
            </>
          )}
        </>
        <div className='nav-left scroll-vertical scroll-vertical-status'>
          <nav className='navbar'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a
                  className={`nav-link ${campaignName ? '' : 'active'} ${
                    selectedItemType == 'campaign' ? 'active' : ''
                  }`}
                  aria-current='page'
                  // href='javascript:void(0)'
                  onClick={(e) => {
                    handleClick('newCampaign');
                    handleDirection('campaign', campaignId);
                  }}
                >
                  {!displayStatus ? (
                    <Skeleton count={3} />
                  ) : (
                    <i className='icon-camopaign'></i>
                  )}
                  <span>
                    {campaignName && displayStatus
                      ? campaignName
                      : displayStatus && 'New Campaign'}
                  </span>
                  {/* <span>New Campaign</span> */}
                </a>
                {/* Show button if Campaign is already created - start */}
                {campaignName && displayStatus && (
                  <div className='dropdown-wrap'>
                    <Dropdown>
                      <Dropdown.Toggle
                        id='oneBoxx'
                        className='primary-btn close-btn dot-btn-primary'
                      >
                        <i className='icon-meni-hori'></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            props?.handleRedirectionToNextPage(campaignId)
                          }
                        >
                          New Insertion Order
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDelete(campaignId, 'campaign')}
                        >
                          Delete Campaign
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {/* Show button if Campaign is already created - end */}
              </li>
              {insertionOrders?.length > 0 && displayStatus ? (
                [...insertionOrders]?.map((insertionOrder, InsertionIndex) => {
                  return (
                    <li className='nav-item' key={InsertionIndex}>
                      {/* <a href="javascript:void(0)" className="nav-link" onClick={(e)=>console.log("insertion",insertionOrder)}> */}
                      <a
                        // href='javascript:void(0)'
                        className={`nav-link ${
                          selectedItemType == 'insertionOrder' &&
                          selectedItemId == insertionOrder?.insertion_order_id
                            ? 'active'
                            : ''
                        }`}
                        onClick={(e) => {
                          handleClick(insertionOrder);
                          handleDirection(
                            'insertion',
                            campaignId,
                            insertionOrder?.insertion_order_id,
                          );
                        }}
                      >
                        <i className='icon-ad-set'></i>
                        <span> {insertionOrder?.insertion_order_name}</span>
                      </a>
                      {insertionOrder?.optimization_type != '' && (
                        <div className='dropdown-wrap'>
                          <Dropdown>
                            <Dropdown.Toggle
                              id='oneBox'
                              className='primary-btn close-btn dot-btn-primary'
                            >
                              <i className='icon-meni-hori'></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  props?.handleRedirectionToNextPageOfLineItem(
                                    insertionOrder,
                                  )
                                }
                              >
                                New Line Item
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleDelete(
                                    insertionOrder?.insertion_order_id,
                                    'insertionOrder',
                                  )
                                }
                              >
                                Delete Insertion Order
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                      <ul>
                        {insertionOrder?.lineItems?.length > 0 ? (
                          [...insertionOrder?.lineItems]?.map(
                            (lineItem, index) => {
                              return (
                                <li key={index}>
                                  {/* <a href="#" className="nav-link" onClick={(e)=>console.log("line",lineItem)}> */}
                                  <a
                                    // href='#'
                                    className={`nav-link ${
                                      selectedItemType == 'lineItem' &&
                                      selectedItemId == lineItem?.line_item_id
                                        ? 'active'
                                        : ''
                                    }`}
                                    onClick={(e) => {
                                      handleClick(lineItem);
                                      handleDirection(
                                        'lineItem',
                                        campaignId,
                                        undefined,
                                        lineItem?.line_item_id,
                                      );
                                    }}
                                  >
                                    <i className='icon-ad'></i>
                                    <span>{lineItem?.line_item_name}</span>
                                    <div className='dropdown-wrap'>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id='oneBox'
                                          className='primary-btn close-btn dot-btn-primary'
                                        >
                                          <i className='icon-meni-hori'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleDelete(
                                                lineItem?.line_item_id,
                                                'lineItem',
                                                InsertionIndex,
                                                index,
                                              )
                                            }
                                          >
                                            Delete Line Item
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </a>
                                </li>
                              );
                            },
                          )
                        ) : insertionOrders?.length > 0 && isCreateMode ? (
                          <li>
                            <a
                              // href='#'
                              className={`nav-link ${
                                selectedItemId == null ? 'active' : ''
                              }`}
                              onClick={() => {
                                const {
                                  campaign_id,
                                  insertion_order_id,
                                  optimization_type,
                                  optimization_action,
                                  taxonomy_campaign_id,
                                } = insertionOrders[InsertionIndex];
                                handleClick('newLineItem');
                                handleDirection(
                                  'newLineItem',
                                  campaign_id,
                                  insertion_order_id,
                                  undefined,
                                  `${optimization_type},${optimization_action}`,
                                  taxonomy_campaign_id,
                                );
                              }}
                            >
                              <i className='icon-ad'></i>
                              <span>New Line Item</span>
                            </a>
                          </li>
                        ) : (
                          ''
                        )}
                        {/* Below div would display in multiple Line Item case - start */}
                        {props?.getInsertionOrderId ==
                          insertionOrder?.insertion_order_id &&
                          props?.flagStatus == 'multiple' &&
                          Type == 'lineItem' &&
                          displayStatus && (
                            <li>
                              <a
                                // href='#'
                                className={`nav-link ${
                                  selectedItemId == null ? 'active' : ''
                                }`}
                                onClick={() => {
                                  const {
                                    campaign_id,
                                    insertion_order_id,
                                    optimization_type,
                                    optimization_action,
                                    taxonomy_campaign_id,
                                  } = insertionOrders[InsertionIndex];
                                  handleClick('newLineItem');
                                  handleDirection(
                                    'newLineItem',
                                    campaign_id,
                                    insertion_order_id,
                                    undefined,
                                    `${optimization_type},${optimization_action}`,
                                    taxonomy_campaign_id,
                                  );
                                }}
                              >
                                <i className='icon-ad'></i>
                                <span>New Line Item</span>
                              </a>
                            </li>
                          )}
                        {/* Above div would display in multiple Line Item case - end */}
                      </ul>
                    </li>
                  );
                })
              ) : campaignName &&
                isCreateMode &&
                moderationStatus != 'draft' &&
                displayStatus ? (
                <li className='nav-item'>
                  <a
                    // href='javascript:void(0)'
                    className={`nav-link ${
                      selectedItemId == null ? 'active' : ''
                    }`}
                    onClick={() => {
                      handleClick('newInsertion');
                      handleDirection('newInsertion');
                    }}
                  >
                    <i className='icon-ad-set'></i>
                    <span> New Insertion Order</span>
                  </a>
                  {/* <div className='dropdown-wrap'>
                    <Dropdown>
                      <Dropdown.Toggle
                        id='oneBox'
                        className='primary-btn close-btn dot-btn-primary'
                      >
                        <i className='icon-meni-hori'></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='#/action-4'>
                          Duplicate
                        </Dropdown.Item>
                        <Dropdown.Item href='#/action-5'>Copy</Dropdown.Item>
                        <Dropdown.Item href='#/action-6'>Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                  {/* <ul>
                      {[...Array(2)].map((item) => {
                          return (
                            <li>
                              <a href="#" className="nav-link">
                                <i className="icon-ad"></i>
                                <span>New Ad</span>
                              </a>
                            </li>
                          );
                        })
                      }

                    </ul> */}
                </li>
              ) : (
                ''
              )}
              {/* Below div would display in multiple insertion order case - start */}
              {props?.flagStatus == 'multiple' &&
                Type == 'insertion' &&
                displayStatus && (
                  <li className='nav-item'>
                    <a
                      // href='javascript:void(0)'
                      className={`nav-link ${
                        selectedItemId == null ? 'active' : ''
                      }`}
                    >
                      <i className='icon-ad-set'></i>
                      <span> New Insertion Order</span>
                    </a>
                  </li>
                )}
              {/* Above div would display in multiple insertion order case - start */}
              {/* <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                          <i className="icon-ad-set"></i>
                          <span> New awareness ad set 2</span>
                        </a>
                        <ul>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i>
                              <span>New awareness ad 1</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" className="nav-link">
                              <i className="icon-ad"></i> <span>New ad 2</span>
                            </a>
                          </li>
                        </ul>
                      </li> */}
            </ul>
          </nav>
        </div>
      </article>
      <DeleteModalEditFlow
        handleClose={handleCloseCampaignDeleteModal}
        show={showCampaignDeleteModal}
        key={useId()}
        deleteId={campaignDeleteId}
        deleteUrl={
          selectedItemType == 'campaign'
            ? DV360Options.Campaigns.deleteAllUrl
            : selectedItemType == 'insertionOrder'
              ? DV360Options.Insertion_Order.deleteAllUrl
              : DV360Options.Line_Item.deleteAllUrl
        }
        from={
          selectedItemType == 'campaign'
            ? DV360Options.Campaigns.name
            : selectedItemType == 'insertionOrder'
              ? DV360Options.Insertion_Order.name
              : DV360Options.Line_Item.name
        }
        doneWithDelete={doneWithDelete}
      ></DeleteModalEditFlow>
    </>
  );
}

export default SideTreeMenuForCreate;
