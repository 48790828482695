import React from 'react';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';
import Card from '../common/Card';
import ReactSelect from '../common/ReactSelect';

const Topics = ({ onChange, value, formErrors, options }) => {
  return (
    <Card>
      <CardBody>
        <>
          <div className='form-item '>
            <div className='label-icon'>
              <label>
                <CardHeader>
                  <h6>Topics</h6>
                </CardHeader>
                <p className='d-flex align-items-center'>
                  Suggest webpages, apps, and videos about a certain topic
                  <span
                    className='label-information tooltips'
                    tooltip='tootltip'
                    tooltip-position='top'
                  >
                    <i className='icon-info'></i>
                    <span>
                      Topic targeting is a simple way to place your ads on many
                      webpages, apps, and videos about a certain subject. Topics
                      are based on broad industries or interests, such as
                      agriculture or music. For example, by targeting the "Autos
                      & Vehicles" topic, your ad may appear on websites, apps,
                      or YouTube videos with content about cars or other
                      automotive themes.
                    </span>
                  </span>
                </p>
              </label>
            </div>
            <div className='select-box select-multiple-components '>
              <ReactSelect
                placeholder={'Select Topics'}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti={true}
                options={options}
                value={value}
                onChange={onChange}
              />
            </div>
            <div className='error' id='nameError'>
              {formErrors?.topic}
            </div>
          </div>
        </>
      </CardBody>
    </Card>
  );
};

export default Topics;
