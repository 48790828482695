import React from 'react';
import Card from '../common/Card';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';
import {
  setDemographicAge,
  setDemographicGender,
  setDemographicHouseholdIncome,
  setDemographicParentalStatus,
} from '../../state/googleAdGroup/googleAdGroupActions';
import { useDispatch } from 'react-redux';

const Demographics = ({
  formErrors,
  DemographicGender,
  DemographicAge,
  DemographicParentalStatus,
  DemographicHouseholdIncome,
}) => {
  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Unknown', label: 'Unknown' },
  ];
  const ageOptions = [
    { value: '18-24', label: '18-24' },
    { value: '25-34', label: '25-34' },
    { value: '35-44', label: '35-44' },
    { value: '45-54', label: '45-54' },
    { value: '55-64', label: '55-64' },
    { value: '65+', label: '65+' },
    { value: 'Unknown', label: 'Unknown' },
  ];

  const parentalStatusOptions = [
    { value: 'Not a Parent', label: 'Not a Parent' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Unknown', label: 'Unknown' },
  ];

  const householdIncomeOptions = [
    { value: 'Top 10%', label: 'Top 10%' },
    { value: '11%-20%', label: '11%-20%' },
    { value: '21%-30%', label: '21%-30%' },
    { value: '31%-40%', label: '31%-40%' },
    { value: '41%-50%', label: '41%-50%' },
    { value: 'Lower than 50%', label: 'Lower than 50%' },
    { value: 'Unknown', label: 'Unknown' },
  ];

  const dispatch = useDispatch();
  const handleSelectChange = (fieldName) => (selectedOptions) => {
    switch (fieldName) {
      case 'age':
        dispatch(setDemographicAge(selectedOptions));
        break;
      case 'gender':
        dispatch(setDemographicGender(selectedOptions));
        break;
      case 'parentalStatus':
        dispatch(setDemographicParentalStatus(selectedOptions));
        break;
      case 'householdIncome':
        dispatch(setDemographicHouseholdIncome(selectedOptions));
        break;
      default:
        break;
    }
  };

  return (
    <Card>
      <CardBody>
        <CardHeader>
          <h6>Demographics</h6>
        </CardHeader>
        <p className='d-flex align-items-center'>
          Suggest people based on age, gender, parental status, or household
          income
          <span
            className='label-information tooltips'
            tooltip='tootltip'
            tooltip-position='top'
          >
            <i className='icon-info'></i>
            <span>
              With demographic targeting, you can reach people who are likely to
              be in demographic groups that you choose, including age, gender,
              parental status, or household income.
            </span>
          </span>
        </p>
      </CardBody>

      <CardBody>
        <>
          <div className='form-item'>
            <CardHeader>
              <h6>Gender</h6>
            </CardHeader>
            <div className='select-box select-multiple-components '>
              <ReactSelect
                placeholder={'Select gender'}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti={true}
                options={genderOptions}
                value={DemographicGender}
                onChange={handleSelectChange('gender')}
              />
            </div>
            <div className='error' id='nameError'>
              {formErrors?.gender}
            </div>
          </div>
        </>
      </CardBody>
      <CardBody>
        <>
          <div className='form-item '>
            <CardHeader>
              <h6>Age</h6>
            </CardHeader>
            <div className='select-box select-multiple-components '>
              <ReactSelect
                placeholder={'Select Age'}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti={true}
                options={ageOptions}
                value={DemographicAge}
                onChange={handleSelectChange('age')}
              />
            </div>
            <div className='error' id='nameError'>
              {formErrors?.age}
            </div>
          </div>
        </>
      </CardBody>
      <CardBody>
        <>
          <div className='form-item '>
            <CardHeader>
              <h6>Parental Status</h6>
            </CardHeader>

            <div className='select-box select-multiple-components '>
              <ReactSelect
                placeholder={'Parental Status'}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti={true}
                options={parentalStatusOptions}
                value={DemographicParentalStatus}
                onChange={handleSelectChange('parentalStatus')}
              />
            </div>
            <div className='error' id='nameError'>
              {formErrors?.parentalStatus}
            </div>
          </div>
        </>
      </CardBody>
      <CardBody>
        <>
          <div className='form-item '>
            <CardHeader>
              <h6>Household Income</h6>
            </CardHeader>

            <div className='select-box select-multiple-components '>
              <ReactSelect
                placeholder={'Household Income'}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={true}
                isMulti={true}
                options={householdIncomeOptions}
                value={DemographicHouseholdIncome}
                onChange={handleSelectChange('householdIncome')}
              />
            </div>
            <div className='error' id='nameError'>
              {formErrors?.gender}
            </div>
          </div>
        </>
      </CardBody>
    </Card>
  );
};

export default Demographics;
