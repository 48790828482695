import {
  GOOGLE_AD_ADD_CALL,
  GOOGLE_AD_ADD_DESCRIPTIONS,
  GOOGLE_AD_ADD_HEADLINES,
  GOOGLE_AD_AD_NAME,
  GOOGLE_AD_BUSINESS_NAME,
  GOOGLE_AD_CALLS,
  GOOGLE_AD_DEL_DESCRIPTIONS,
  GOOGLE_AD_DEL_HEADLINES,
  GOOGLE_AD_DISPLAY_PATH,
  GOOGLE_AD_DISPLAY_PATH_0,
  GOOGLE_AD_DISPLAY_PATH_1,
  GOOGLE_AD_FINAL_URL,
  GOOGLE_AD_GET_FORM_DATA,
  GOOGLE_AD_LEADFORMS,
  GOOGLE_AD_SITELINKS,
  GOOGLE_AD_UPDATE_DESCRIPTIONS,
  GOOGLE_AD_UPDATE_HEADLINES,
  GOOGLE_AD_VIDEO_ID,
  GOOGLE_AD_CALL_TO_ACTION,
  GOOGLE_AD_VIDEO_HEADLINE,
  GOOGLE_AD_VIDEO_LONG_HEADLINE,
  GOOGLE_AD_DESCRIPTION,
  GOOGLE_AD_TRACKINGTEMPLATE,
  GOOGLE_AD_FINAL_URL_SUFFIX,
  GOOGLE_AD_ISMOBILE_URL_ENABLED,
  GOOGLE_AD_FINAL_MOBILE_URL,
  GOOGLE_AD_SET_COMPANION_BANNER_TYPE,
  GOOGLE_AD_SET_COMPANION_BANNER_ID,
  GOOGLE_AD_ADDITIONAL_FORMAT_OPTIONS,
  GOOGLE_AD_ISCALLTOACTION_ENABLED,
  GOOGLE_AD_CUSTOM_COLOR,
  GOOGLE_AD_ACCENT_COLOR,
  GOOGLE_AD_SHOW_THIS_AD_STATUS,
  GOOGLE_AD_CUSTOM_COLOR_ENABLED,
  GOOGLE_AD_SELECTED_CREATE_TYPE,
  GOOGLE_AD_RESET_FORM,
  GOOGLE_AD_ADD_LONG_HEADLINES,
  GOOGLE_AD_DEL_LONG_HEADLINES,
  GOOGLE_AD_UPDATE_LONG_HEADLINES,
  GOOGLE_AD_ADD_SHORT_DESCRIPTIONS,
  GOOGLE_AD_DEL_SHORT_DESCRIPTIONS,
  GOOGLE_AD_UPDATE_SHORT_DESCRIPTIONS,
  GOOGLE_AD_APP_URL,
  DV_Prefilled_Values,
  GOOGLE_AD_FINAL_URL_PERFORMANCE,
} from './GoogleAdActionTypes';

let initialState = {
  googleAdId: 0,
  final_url: '',
  display_path_0: '',
  display_path_1: '',
  calls: [],
  lead_forms: [],
  head_lines: ['', '', ''],
  long_head_lines: [''],
  descriptions: ['', ''],
  short_descriptions: ['', ''],
  images: [{}],
  business_name: '',
  ad_name: '',
  business_logo: '',
  site_links: [],
  callouts: [],
  // more_asset_types: [{}, {}, {}, {}, {}],
  promotions: [],
  prices: [],
  structured_snippets: [],
  apps: [],
  locations: [
    { id: 1, name: 'abc' },
    { id: 2, name: 'bca' },
  ],
  ad_url_options: {
    tracking_temp: '',
    suffix: '',
    cus_params: [{}],
    mobile_url: '',
  },
  // Video Campaign type params start
  videoIds: [],
  callToAction: '',
  heading: [],
  headingLong: [],
  description: [],
  trackingTemplate: '',
  finalUrlSuffix: '',
  isfinalmobileurlEnabled: false,
  finalUrlMobile: '',
  companionBannerType: '',
  companionBannerId: '',
  // Video Campaign type params end
  // Display Campaign type params start
  additionalFormatOptions: [],
  iscalltoactionEnabled: false,
  customMainColor: '',
  customAccentColor: '',
  iscolorEnabled: false,
  iscustomcolorEnabled: false,
  creativeType: '',
  // Display Campaign type params end
  appUrl: null,
  atdStatus: 'draft',
  createdBy: 0,
};

export const googleAd = (state = initialState, { type, payload }) => {
  switch (type) {
    case GOOGLE_AD_FINAL_URL: {
      const { name, value } = payload?.target;
      return { ...state, [name]: value };
    }
    case GOOGLE_AD_FINAL_URL_PERFORMANCE: {
      return { ...state, final_url: payload?.value };
    }

    case GOOGLE_AD_DISPLAY_PATH_0: {
      const { name, value } = payload?.target;
      if (value?.length > 15) return { ...state };
      return { ...state, display_path_0: value };
    }
    case GOOGLE_AD_DISPLAY_PATH_1: {
      const { name, value } = payload?.target;
      if (value?.length > 15) return { ...state };
      return { ...state, display_path_1: value };
    }
    // case GOOGLE_AD_CALLS: {
    //   const { checked, index } = payload;
    //   let calls = state?.calls;
    //   let call = calls[index];
    //   call = { ...call, checked: checked };
    //   calls = [...calls?.slice(0, index), call, ...calls?.slice(index + 1)];

    //   return { ...state, calls: calls };
    // }

    // case GOOGLE_AD_LEADFORMS: {
    //   const { checked, index } = payload;
    //   let leadForms = state?.lead_forms;
    //   let leadForm = leadForms[index];
    //   leadForm = { ...leadForm, checked: checked };
    //   leadForms = [
    //     ...leadForms?.slice(0, index),
    //     leadForm,
    //     ...leadForms?.slice(index + 1),
    //   ];

    //   return { ...state, lead_forms: leadForms };
    // }

    // case GOOGLE_AD_SITELINKS: {
    //   const { checked, index } = payload;
    //   let siteLinks = state?.site_links;
    //   let siteLink = siteLinks[index];
    //   siteLink = { ...siteLink, checked: checked };
    //   siteLinks = [
    //     ...siteLinks?.slice(0, index),
    //     siteLink,
    //     ...siteLinks?.slice(index + 1),
    //   ];

    //   return { ...state, site_links: siteLinks };
    // }
    case GOOGLE_AD_ADD_HEADLINES: {
      let headlines = state?.head_lines;
      headlines.push('');
      return { ...state, head_lines: headlines };
    }
    case GOOGLE_AD_ADD_LONG_HEADLINES: {
      let Longheadlines = state?.long_head_lines;
      Longheadlines.push('');
      return { ...state, long_head_lines: Longheadlines };
    }
    case GOOGLE_AD_DEL_HEADLINES: {
      let index = payload;
      // alert(index)
      let headlines = state?.head_lines;
      // headlines.push("");
      if (headlines?.length > 3)
        headlines = [
          ...headlines.slice(0, index),
          ...headlines.slice(index + 1),
        ];
      return { ...state, head_lines: headlines };
    }
    case GOOGLE_AD_DEL_LONG_HEADLINES: {
      let index = payload;
      // alert(index)
      let Longheadlines = state?.long_head_lines;
      // headlines.push("");
      if (Longheadlines?.length > 1)
        Longheadlines = [
          ...Longheadlines.slice(0, index),
          ...Longheadlines.slice(index + 1),
        ];
      return { ...state, long_head_lines: Longheadlines };
    }
    case GOOGLE_AD_DEL_DESCRIPTIONS: {
      let index = payload;
      // alert(index)
      let descriptions = state?.descriptions;
      // headlines.push("");
      if (descriptions?.length > 2)
        descriptions = [
          ...descriptions.slice(0, index),
          ...descriptions.slice(index + 1),
        ];
      return { ...state, descriptions: descriptions };
    }
    case GOOGLE_AD_DEL_SHORT_DESCRIPTIONS: {
      let index = payload;
      // alert(index)
      let descriptions = state?.short_descriptions;
      // headlines.push("");
      if (descriptions?.length > 2)
        descriptions = [
          ...descriptions.slice(0, index),
          ...descriptions.slice(index + 1),
        ];
      return { ...state, short_descriptions: descriptions };
    }
    case GOOGLE_AD_UPDATE_HEADLINES: {
      let headlines = state?.head_lines;
      // console.log(payload?.data?.target?.value?.length)
      if (payload?.data?.target?.value?.length > 30) return state;
      headlines = [
        ...headlines.slice(0, payload?.index),
        payload?.data?.target?.value,
        ...headlines.slice(payload?.index + 1),
      ];
      return { ...state, head_lines: headlines };
    }
    case GOOGLE_AD_UPDATE_LONG_HEADLINES: {
      let Longheadlines = state?.long_head_lines;
      // console.log(payload?.data?.target?.value?.length)
      if (payload?.data?.target?.value?.length > 90) return state;
      Longheadlines = [
        ...Longheadlines.slice(0, payload?.index),
        payload?.data?.target?.value,
        ...Longheadlines.slice(payload?.index + 1),
      ];
      return { ...state, long_head_lines: Longheadlines };
    }
    case GOOGLE_AD_ADD_DESCRIPTIONS: {
      let descriptions = state?.descriptions;
      descriptions.push('');
      return { ...state, descriptions: descriptions };
    }
    case GOOGLE_AD_ADD_SHORT_DESCRIPTIONS: {
      let shortdescriptions = state?.short_descriptions;
      shortdescriptions.push('');
      return { ...state, short_descriptions: shortdescriptions };
    }
    case GOOGLE_AD_UPDATE_DESCRIPTIONS: {
      let descriptions = state?.descriptions;
      // console.log(payload?.data?.target?.value?.length)
      if (payload?.data?.target?.value?.length > 90) return state;
      descriptions = [
        ...descriptions.slice(0, payload?.index),
        payload?.data?.target?.value,
        ...descriptions.slice(payload?.index + 1),
      ];
      return { ...state, descriptions: descriptions };
    }
    case GOOGLE_AD_UPDATE_SHORT_DESCRIPTIONS: {
      let descriptions = state?.short_descriptions;
      // console.log(payload?.data?.target?.value?.length)
      if (payload?.data?.target?.value?.length > 60) return state;
      descriptions = [
        ...descriptions.slice(0, payload?.index),
        payload?.data?.target?.value,
        ...descriptions.slice(payload?.index + 1),
      ];
      return { ...state, short_descriptions: descriptions };
    }
    case GOOGLE_AD_BUSINESS_NAME: {
      if (payload?.target?.value?.length > 25) return state;
      return { ...state, business_name: payload?.target?.value };
    }
    case GOOGLE_AD_AD_NAME: {
      // if (payload?.target?.value?.length > 25) return state;
      return { ...state, ad_name: payload?.target?.value };
    }
    case GOOGLE_AD_GET_FORM_DATA: {
      if (payload) {
        // console.log("master",payload)
        return {
          ...state,
          apps: payload?.apps,
          calls: payload?.calls,
          lead_forms: payload?.leads,
          prices: payload?.prices,
          promotions: payload?.promotions,
          site_links: payload?.siteLinks,
          structured_snippets: payload?.structuredSnippets,
          callouts: payload?.callouts,
          // callouts
        };
      }
      return { ...state };
    }
    // Video Campaign type params start
    case GOOGLE_AD_VIDEO_ID: {
      return { ...state, videoIds: payload?.toString() };
    }
    case GOOGLE_AD_CALL_TO_ACTION: {
      return { ...state, callToAction: payload };
    }
    case GOOGLE_AD_VIDEO_HEADLINE: {
      let headingVal = state?.heading;
      headingVal.push(payload);
      return { ...state, heading: headingVal };
    }
    case GOOGLE_AD_VIDEO_LONG_HEADLINE: {
      let headingLongVal = state?.headingLong;
      headingLongVal.push(payload);
      return { ...state, headingLong: headingLongVal };
    }
    case GOOGLE_AD_DESCRIPTION: {
      let descriptionVal = state?.description;
      descriptionVal.push(payload);
      return { ...state, description: descriptionVal };
    }
    case GOOGLE_AD_TRACKINGTEMPLATE: {
      return { ...state, trackingTemplate: payload };
    }
    case GOOGLE_AD_FINAL_URL_SUFFIX: {
      return { ...state, finalUrlSuffix: payload };
    }
    case GOOGLE_AD_ISMOBILE_URL_ENABLED: {
      return { ...state, isfinalmobileurlEnabled: payload };
    }
    case GOOGLE_AD_FINAL_MOBILE_URL: {
      return { ...state, finalUrlMobile: payload };
    }
    case GOOGLE_AD_SET_COMPANION_BANNER_TYPE: {
      return { ...state, companionBannerType: payload };
    }
    case GOOGLE_AD_SET_COMPANION_BANNER_ID: {
      return { ...state, companionBannerId: payload?.toString() };
    }
    // Video Campaign type params end
    // Display Campaign type params start
    case GOOGLE_AD_ADDITIONAL_FORMAT_OPTIONS: {
      let newArr = [];
      payload?.map((item) => {
        newArr.push(item?.value);
      });
      return { ...state, additionalFormatOptions: newArr };
    }

    case GOOGLE_AD_ISCALLTOACTION_ENABLED: {
      return { ...state, iscalltoactionEnabled: payload };
    }

    case GOOGLE_AD_CUSTOM_COLOR: {
      return { ...state, customMainColor: payload };
    }

    case GOOGLE_AD_ACCENT_COLOR: {
      return { ...state, customAccentColor: payload };
    }

    case GOOGLE_AD_SHOW_THIS_AD_STATUS: {
      return { ...state, iscustomcolorEnabled: payload };
    }

    case GOOGLE_AD_CUSTOM_COLOR_ENABLED: {
      return { ...state, iscolorEnabled: payload };
    }

    case GOOGLE_AD_SELECTED_CREATE_TYPE: {
      return { ...state, creativeType: payload };
    }

    case GOOGLE_AD_APP_URL: {
      return { ...state, appUrl: payload };
    }

    case GOOGLE_AD_RESET_FORM: {
      return initialState;
    }

    case DV_Prefilled_Values: {
      return {
        ...state,
        googleAdId: payload?.googleAdDetails?.googleAdId,
        final_url: payload?.googleAdDetails?.finalUrl,
        display_path_0: payload?.googleAdDetails?.displayPath1,
        display_path_1: payload?.googleAdDetails?.displayPath2,
        // calls: [],
        // lead_forms: [],
        head_lines: payload?.googleAdDetails?.heading,
        heading: payload?.googleAdDetails?.heading,
        long_head_lines: payload?.googleAdDetails?.headingLong,
        descriptions: payload?.googleAdDetails?.description,
        description: payload?.googleAdDetails?.description,
        short_descriptions: [
          ...(payload?.googleAdDetails?.descriptionShort?.[0]
            ? [payload?.googleAdDetails?.descriptionShort?.[0]]
            : []),
          ...(payload?.googleAdDetails?.description
            ? payload?.googleAdDetails?.description
            : []),
        ],
        business_name: payload?.googleAdDetails?.businessName,
        ad_name: payload?.googleAdDetails?.adName,
        headingLong: payload?.googleAdDetails?.headingLong,
        finalUrlSuffix: payload?.googleAdDetails?.finalUrlSuffix,
        isfinalmobileurlEnabled:
          payload?.googleAdDetails?.isfinalmobileurlEnabled,
        finalUrlMobile: payload?.googleAdDetails?.finalUrlMobile,
        iscalltoactionEnabled: payload?.googleAdDetails?.iscalltoactionEnabled,
        customMainColor: payload?.googleAdDetails?.customMainColor,
        customAccentColor: payload?.googleAdDetails?.customAccentColor,
        iscolorEnabled: payload?.googleAdDetails?.iscolorEnabled,
        iscustomcolorEnabled: payload?.googleAdDetails?.iscustomcolorEnabled,
        creativeType: payload?.googleAdDetails?.creativeType,
        appUrl: payload?.googleAdDetails?.appUrl,
        atdStatus: payload?.googleAdDetails?.atdStatus,
        googleStatus: payload?.googleAdDetails?.googleStatus,
        createdBy: payload?.googleAdDetails?.createdBy,
        updatedBy: payload?.googleAdDetails?.updatedBy,
        callouts: payload?.googleAdDetails?.calloutTypeIds,
      };
    }
    // Display Campaign type params end
    default:
      return state;
  }
};
