import React from 'react';

const HeaderTopBar = ({ setActiveTab, currentTab, setCurrentTab, options }) => {
  const handleSetActiveTab = (tabName) => {
    setCurrentTab(tabName);
    setActiveTab(tabName); // Pass the tabName to the parent component
  };

  const optionsKeysLength = Object.keys(options).length; // Find the length of the keys in the options object

  return (
    <div className='camp-tab-cont'>
      <div className='nav nav-tabs row' id='nav-tab' role='tablist'>
        {Object.entries(options).map(([key, value]) => (
          <button
            key={key}
            className={`justify-content-start  col-${
              12 / optionsKeysLength
            } primary-btn ${currentTab === value.name && 'active'}`}
            id={`${key.toLowerCase()}-tab`}
            data-bs-toggle='tab'
            data-bs-target={`#${key.toLowerCase()}`}
            type='button'
            role='tab'
            aria-selected={currentTab === value.name ? 'true' : 'false'}
            onClick={() => handleSetActiveTab(value.name)}
          >
            {value.icon && <i className={value.icon} />} {value.name}
          </button>
        ))}
      </div>
    </div>
    // </div>
  );
};

export default HeaderTopBar;
