import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../../components/common/Sidebar';
import Header from '../../components/common/Header';
import facebookIcon from '../../assets/images/icon-fb.svg';
import googleAdIcon from '../../assets/images/icon-drive.svg';
import DV360Icon from '../../assets/images/icon-video.svg';
import ReactPaginate from 'react-paginate';
import ReactSelect from '../../components/common/ReactSelect';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { menuItems } from './Main';
import { Link } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import audioImage from '../../assets/images/audio-small.png';
import htmlImage from '../../assets/images/html-small.png';
import imageNotPresent from '../../assets/images/image-small.png';
import videoNotPresent from '../../assets/images/video-small.png';
import youtubeNotPresent from '../../assets/images/youtube-small.png';
import Loader from '../../components/common/Loader';
import noDataFound from '../../assets/images/no-media.png';
import FsLightbox from 'fslightbox-react';
import NavHeading from '../../components/common/NavHeading';
import { baseUrlStg } from '../../utils/constants';
import { aspectRatio } from './mediaConstant';
import { getCookies } from '../../utils/utils';

export const MediaListing = () => {
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState('All');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [apiError, setApiError] = useState('');
  const [filters, setFilters] = useState({
    fileType: [],
    dimensions: [],
    aspectRatio: [],
    dates: {
      startDate: '',
      endDate: '',
    },
    creativeName: '',
  });
  const [deleteIds, setDeleteIds] = useState([]);
  const [show, setShow] = useState(false);
  const [openModalData, setOpenModalData] = useState(null);
  const [fileTypes, setFileTypes] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [creativeNameFilter, setCreativeNameFilter] = useState(false);
  const creativeRef = useRef();
  const [creativeNameValue, setCreativeNameValue] = useState('');
  const [creativeNameError, setCreativeNameError] = useState(false);
  const [editInputValue, setEditInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const [editName, setEditName] = useState(false);
  const [disableDimensions, setDisableDimensions] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [showMenu, setShowMenu] = useState();
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false);
  const [showDeletePopup, setShowDeletePupup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);
  const [youtube, setYoutube] = useState(false);
  const [filterApply, setFilterApply] = useState(false);
  const AddDropdownRef = useRef();

  useEffect(() => {
    getFilterData();
    getMenuData();
  }, []);

  const itemsPerPage = 24;
  // console.log('filter Apply', filterApply)
  console.log('filter Apply', openModalData);
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };

  useEffect(() => {
    getData();
  }, [currentTab, currentPage, filters]);

  //use Effect for creative name filter
  useEffect(() => {
    const handler = (event) => {
      if (
        creativeNameFilter &&
        creativeRef.current &&
        !creativeRef.current.contains(event.target)
      ) {
        setCreativeNameError(false);
        setCreativeNameFilter(false);
      }
      if (!AddDropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [creativeNameFilter]);

  // get Api data
  const getData = async () => {
    let URL = `${baseUrlStg}/api/MediaListing/All_Meadia?Platform=${currentTab}&page=${
      currentPage + 1
    }&limit=${itemsPerPage}`;

    if (filters?.fileType && filters?.fileType.length > 0) {
      setFilterApply(true);
      filters?.fileType.forEach((type) => {
        URL = URL + `&Filetype=${type.value}`;
      });
    }

    if (filters?.dimensions && filters?.dimensions.length > 0) {
      const Dimensions = filters?.dimensions.map((type) => type.value);
      console.log('filetypwess in api', Dimensions);
      setFilterApply(true);
      filters?.dimensions.forEach((type) => {
        URL = URL + `&Dimensions=${type.value}`;
      });
    }

    if (filters?.aspectRatio && filters?.aspectRatio.length > 0) {
      const aspectRatios = filters?.aspectRatio.map((type) => type.value);
      console.log('filetypwess in api', aspectRatios);
      setFilterApply(true);
      filters?.aspectRatio.forEach((type) => {
        URL = URL + `&Ratio=${type.value}`;
      });
    }

    if (filters?.dates.startDate && filters?.dates.endDate) {
      setFilterApply(true);
      URL =
        URL +
        `&StartDate=${filters?.dates.startDate}&EndDate=${filters?.dates.endDate}`;
    }

    if (filters?.creativeName) {
      setFilterApply(true);
      URL = URL + `&CreativeName=${filters?.creativeName}`;
    }

    try {
      setIsLoading(true);
      await axios
        .get(URL, {
          headers: {
            accept: 'text/plain',
            ...CustomHeader,
          },
        })
        .then((resp) => {
          if (resp.data.data) {
            setData(resp.data.data);
            setTotalPages(Math.ceil(resp.data.totalRecords / itemsPerPage));
            setTotalRecords(resp.data.totalRecords);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      setApiError(error);
      setIsLoading(false);
    }
  };

  const getDataById = (id) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      let URL = `${baseUrlStg}/api/MediaListing/GetByMedID?MedID=${id}`;

      axios
        .get(URL, {
          headers: {
            accept: 'text/plain',
            ...CustomHeader,
          },
        })
        .then((res) => {
          resolve(res.data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          reject(err.response.data);
          setIsLoading(false);
        });
    });
  };

  const getFilterData = () => {
    const url = `${baseUrlStg}/api/MediaListing/DropdownData`;
    axios
      .get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((result) => {
        setFileTypes(result.data.filetype);
        setDimensions(result.data.dimensions);
      })
      .catch((err) => {
        setApiError(err.response);
      });
  };

  const getMenuData = () => {
    let URL = `${baseUrlStg}/api/MediaListing/MediaTypeDropdown`;
    axios
      .get(URL, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      })
      .then((result) => {
        const newMenu = result.data.map((ele) => {
          return {
            title: ele.platformName,
            submenu: ele.mediaType.map((element) => {
              return {
                title: element,
                url: '/media-library/upload-media',
                type: ele.platformName,
              };
            }),
          };
        });
        setMenuData(newMenu);
      })
      .catch((err) => {
        setApiError(err.response);
      })
      .catch((err) => {
        setApiError(err.response);
      });
  };

  // hide show assets detail modal
  const handleShow = (event, modalData) => {
    event?.preventDefault();
    if (show) {
      setShow(false);
      getData();
      setEditName(false);
      setOpenModalData(modalData);
      setPlayAudio(false);
      setYoutube(false);
    } else {
      getDataById(modalData.mediaId).then((res) => {
        setShow(true);
        setOpenModalData(res);
        setYoutube(res.youTubeUrl);
        modalData?.fileType === 'Audio' && setPlayAudio(true);
      });
    }
  };

  // set Tab function

  const setTab = (tab) => {
    ClearFilters('All');
    setCurrentPage(0);
    setCurrentTab(tab);
  };

  // apply filters
  const handleFileType = (val) => {
    // if (val) {
    //   let valueArray = [];
    //   val.forEach((ele) => {
    //     valueArray.push(ele.value);
    //   });
    //   if (valueArray.includes("Video") || valueArray.includes("Youtube")) {
    //     setDisableDimensions(true);
    //   } else {
    //     setDisableDimensions(false);
    //   }
    // } else {
    //   console.log("inside falsesss");
    //   setDisableDimensions(false);
    // }
    setCurrentPage(0);
    setFilters((prevVal) => {
      return { ...prevVal, fileType: val };
    });
    checkFilterApplied();
  };

  const handleDimensionsChange = (e) => {
    console.log(e);
    setCurrentPage(0);
    setFilters((prevVal) => {
      return { ...prevVal, dimensions: e };
    });
    checkFilterApplied();
  };

  const handleAspectRatio = (e) => {
    console.log(e);
    setCurrentPage(0);
    setFilters((prevVal) => {
      return { ...prevVal, aspectRatio: e };
    });
    checkFilterApplied();
  };

  const setDates = (e, { startDate, endDate }) => {
    console.log('setDate in startDate', startDate, 'endDate', endDate);
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: startDate.format('YYYY/MM/DD'),
          endDate: endDate.format('YYYY/MM/DD'),
        },
      };
    });
    checkFilterApplied();
  };

  const setCreativeName = () => {
    // console.log(creativeNameRef.current.value)
    if (!creativeNameValue) {
      setCreativeNameError(true);
      return;
    }
    setCreativeNameError(false);
    setCreativeNameFilter(false);
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        creativeName: creativeNameValue,
      };
    });
  };

  const onCancelDate = (e, { startDate, endDate }) => {
    setCurrentPage(0);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: '',
          endDate: '',
        },
      };
    });
    checkFilterApplied();
  };
  //clear filters
  const ClearFilters = (type) => {
    switch (type) {
      case 'All':
        setCurrentPage(0);
        setFilters({
          fileType: [],
          dimensions: [],
          dates: {
            startDate: '',
            endDate: '',
          },
          creativeName: '',
        });
        setCreativeNameValue('');
        setCreativeNameFilter(false);
        setFilterApply(false);
        break;
      case 'fileType':
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            fileType: [],
          };
        });
        checkFilterApplied();
        break;
      case 'dimensions':
        // onClear()
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            dimensions: [],
          };
        });
        checkFilterApplied();
        break;
      case 'creativeName':
        // onClear()
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            creativeName: '',
          };
        });
        setCreativeNameValue('');
        checkFilterApplied();
        break;
      case 'dates':
        setCurrentPage(0);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            dates: {
              startDate: '',
              endDate: '',
            },
          };
        });
        checkFilterApplied();
        break;

      default:
        break;
    }
  };

  //pagination page change handle
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  //items Select for delete
  const handleInputChecked = (event) => {
    if (event.target.checked) {
      setDeleteIds((prevIds) => {
        return [...prevIds, parseInt(event.target.value)];
      });
    }

    if (!event.target.checked) {
      const removeId = deleteIds.filter(
        (id) => id !== parseInt(event.target.value),
      );
      setDeleteIds(removeId);
    }
  };

  const deleteItems = () => {
    if (deleteIds.length == 0) {
      return;
    }

    // let formData = new FormData()
    // formData.append('MedID',deleteIds)
    setIsLoading(true);
    axios({
      method: 'post',
      url: `${baseUrlStg}/api/MediaListing/deleteMedia`,
      data: deleteIds,
      headers: {
        'Content-Type': 'application/json',
        ...CustomHeader,
      },
    })
      .then((result) => {
        if (result.data.status) {
          // alert(result.data.message)
          getData();
          setDeleteIds([]);
          setIsLoading(false);
          setShowDeleteConfirmationPopup(false);
        } else {
          setIsLoading(false);
          setShowDeleteConfirmationPopup(false);
        }
      })
      .catch((error) => {
        setApiError(error.message);
        setIsLoading(false);
      });
  };

  const deleteitem = (id) => {
    if (id) {
      let postData = [id];
      console.log(postData);
      setIsLoading(true);
      axios({
        method: 'post',
        url: `${baseUrlStg}/api/MediaListing/deleteMedia`,
        data: postData,
        headers: {
          'Content-Type': 'application/json',
          ...CustomHeader,
        },
      })
        .then((result) => {
          if (result.data.status) {
            getData();
            setShow(false);
            setShowDeletePupup(false);
            setOpenModalData(null);
            // alert(result.data.message)
            setIsLoading(false);
          } else {
            // alert('Something went wrong!')
            setShowDeletePupup(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error.message);
          setIsLoading(false);
        })
        .then((result) => {
          if (result.data.status) {
            getData();
            setShow(false);
            setShowDeletePupup(false);
            setOpenModalData(null);
            // alert(result.data.message)
            setIsLoading(false);
          } else {
            // alert('Something went wrong!')
            setShowDeletePupup(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error.message);
          setIsLoading(false);
        })
        .then((result) => {
          console.log('delete result', result);
          if (result.data.status) {
            getData();
            setShow(false);
            setOpenModalData(null);
            alert(result.data.message);
            setIsLoading(false);
          } else {
            alert('Something went wrong!');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setApiError(error.message);
          setIsLoading(false);
        });
    }
  };

  const deleteItemConfirmation = () => {
    setShowDeletePupup(true);
  };

  const handleEditInput = (e) => {
    // console.log('editInput', e.target.value)
    if (!e.target.value) {
      setInputError(true);
    } else if (
      e.target.value &&
      e.target.value.length > 50 &&
      openModalData?.fileType?.toLowerCase() === 'html'
    ) {
      setInputError(true);
    } else {
      setInputError(false);
    }
    setEditInputValue(e.target.value);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleCreativeInput = (event) => {
    if (!event.target.value) {
      setCreativeNameError(true);
    } else {
      setCreativeNameError(false);
    }
    setCreativeNameValue(capitalizeFirstLetter(event.target.value));
  };

  const showHideCreative = () => {
    if (creativeNameFilter) {
      if (!creativeNameValue) {
        setCreativeNameError(false);
      }
      setCreativeNameFilter(false);
    } else {
      setCreativeNameFilter(true);
    }
  };

  const editFileName = (item) => {
    if (!editInputValue) {
      setInputError(true);
      return;
    }

    if (editInputValue) {
      setIsLoading(true);
      axios({
        method: 'post',
        url: `${baseUrlStg}/api/MediaListing/UpdateMediaName?MedId=${item.mediaId}&medFileName=${editInputValue}`,
        headers: {
          'Content-Type': 'application/json',
          ...CustomHeader,
        },
      })
        .then((result) => {
          if (result.data.status) {
            getDataById(item.mediaId)
              .then((response) => {
                setOpenModalData(response);
                setEditName(false);
                setShowEditPopup(false);
                setIsLoading(true);
              })
              .catch((err) => {
                setEditName(false);
                setShowEditPopup(false);
                setApiError(err);
                setIsLoading(true);
              });
          } else {
            setEditName(false);
            setShowEditPopup(false);
            setIsLoading(true);
          }
        })
        .catch((error) => {
          setApiError(error.message);
          setEditName(false);
          setIsLoading(true);
          setShowEditPopup(false);
        });
    }
  };
  // nested dropdown code
  const MenuItems = ({ items, depthLevel, addRef }) => {
    let ref = useRef();
    const [dropdown, setDropdown] = useState(false);
    useEffect(() => {
      const handler = (event) => {
        if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      };
      document.addEventListener('mousedown', handler);
      document.addEventListener('touchstart', handler);
      return () => {
        // Cleanup the event listener
        document.removeEventListener('mousedown', handler);
        document.removeEventListener('touchstart', handler);
      };
    }, [dropdown]);

    const onMouseEnter = () => {
      window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
      window.innerWidth > 960 && setDropdown(false);
    };

    return (
      <div
        className='menu-drpdwn-items'
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {items.submenu ? (
          <>
            <button
              type='button'
              aria-haspopup='menu'
              aria-expanded={dropdown ? 'true' : 'false'}
              onClick={() => setDropdown((prev) => !prev)}
            >
              <span>{items.title}</span> <i className='icon-right-arrow'></i>
              {/* {depthLevel > 0 ? (
                <span className="icon-right-arrow"></span>
              ) : (
                <span className="arrow" />
              )} */}
            </button>
            <Dropdown
              submenus={items.submenu}
              dropdown={dropdown}
              depthLevel={depthLevel}
            />
          </>
        ) : (
          <Link
            className='btn-icon btn-icon-primary drpdwn-btn'
            to={items.url}
            state={{ fileType: items.title, platformType: items.type }}
          >
            {items.title}
          </Link>
        )}
      </div>
    );
  };

  const Dropdown = ({ submenus, dropdown, depthLevel }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';
    return (
      <ul
        className={`dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}
        style={{ display: `${dropdown ? 'block' : 'none'}` }}
      >
        {submenus.map((submenu, index) => (
          <MenuItems items={submenu} key={index} depthLevel={depthLevel} />
        ))}
      </ul>
    );
  };

  const handleMenuDropdown = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const deleteComfirmationClose = () => {
    setShowDeleteConfirmationPopup(false);
  };

  const deleteConformation = () => {
    if (deleteIds.length === 0) {
      return;
    }
    console.log('delete conformation');
    setShowDeleteConfirmationPopup(true);
  };

  const editConfirmation = () => {
    if (!editInputValue || editInputValue === '') {
      setInputError(true);
      return;
    }
    setShowEditPopup(true);
  };

  const checkImage = (path) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ path, status: 'ok' });
      img.onerror = () => resolve({ path, status: 'error' });

      img.src = path;
    });

  const formatFileSize = (bytes, decimalPoint) => {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const extractYouTubeId = (url) => {
    const regex =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regex);
    return match && match[7].length === 11 ? match[7] : null;
  };

  const checkFilterApplied = () => {
    if (
      filters.creativeName === '' ||
      (filters.dates.startDate === '' && filters.dates.endDate === '') ||
      filters.dimensions.length === 0 ||
      filters.fileType.length === 0
    ) {
      setFilterApply(false);
    } else {
      setFilterApply(true);
    }
  };

  const closefsBox = () => {
    document.webkitExitFullscreen();
  };
  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container facebook-step-one'>
          <div className='container'>
            <div className='fb-panel'>
              <Sidebar />
              <div className='right-side-box padding-bottom-extra media-section-left'>
                <NavHeading HeadingName={'Media Library'} />
                <section className='side-menu-form d-flex'>
                  <article className='side-right-form-wrap'>
                    <div className='tab-new-form'>
                      <div className='camp-tab-cont d-flex'>
                        <div className='short-left-bar'>
                          <div
                            className='nav nav-tabs'
                            id='nav-tab'
                            role='tablist'
                          >
                            <button
                              className={`primary-btn ${
                                currentTab == 'All' && 'active'
                              }`}
                              id='edit-tab'
                              data-bs-toggle='tab'
                              data-bs-target='#edit'
                              type='button'
                              role='tab'
                              aria-selected='true'
                              onClick={() => setTab('All')}
                            >
                              <i className='icon-media' /> All
                            </button>
                            <button
                              className={`primary-btn ${
                                currentTab == 'Facebook' && 'active'
                              }`}
                              id='review-tab'
                              data-bs-toggle='tab'
                              data-bs-target='#review'
                              type='button'
                              role='tab'
                              aria-selected='false'
                              onClick={() => setTab('Facebook')}
                            >
                              <i className='icon-facebook-ads icon' />
                              Meta
                            </button>
                            <button
                              className={`primary-btn ${
                                currentTab == 'Google ad' && 'active'
                              }`}
                              id='history-tab'
                              data-bs-toggle='tab'
                              data-bs-target='#history'
                              type='button'
                              role='tab'
                              aria-selected='false'
                              onClick={() => setTab('Google ad')}
                            >
                              <i className='icon-google-ads' />
                              Google Ad
                            </button>
                            <button
                              className={`primary-btn ${
                                currentTab == 'DV360' && 'active'
                              }`}
                              id='history-tab'
                              data-bs-toggle='tab'
                              data-bs-target='#history'
                              type='button'
                              role='tab'
                              aria-selected='false'
                              onClick={() => setTab('DV360')}
                            >
                              <i className='icon-ads' />
                              DV360
                            </button>
                          </div>
                        </div>
                        {/* <div className="short-right-cont">
                          <button
                            className="btn-icon btn-icon-primary approve-act"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#approvedState"
                          >
                            <i className="icon-check-mark" /> Approve
                          </button>
                          <button
                            className="btn-icon btn-icon-primary reject-act"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#rejectState"
                          >
                            <i className="icon-close" /> Reject
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className='card-primary'>
                      <div className='media-fillter-btns'>
                        <div className='fillter-btns d-flex'>
                          <div className='fillter-left d-flex align-items-center'>
                            <div
                              className='dropdown-select DpdrpDownSlct'
                              ref={AddDropdownRef}
                            >
                              <button
                                onClick={handleMenuDropdown}
                                className='btn-icon btn-icon-primary btn-bedcrumb-act'
                              >
                                <i className='icon-plus'></i> Add
                              </button>
                              <div className='menuDropdown'>
                                {showMenu &&
                                  menuData.map((menu, index) => {
                                    const depthLevel = 0;
                                    return (
                                      <MenuItems
                                        items={menu}
                                        key={index}
                                        depthLevel={depthLevel}
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                            <div className='dropdown-select'>
                              <ReactSelect
                                options={fileTypes}
                                value={filters.fileType}
                                isMulti={true}
                                defaultValue={[]}
                                placeholder={'File Type'}
                                isSearchable={false}
                                onChange={handleFileType}
                              />
                            </div>
                            <div className='dropdown-select'>
                              <ReactSelect
                                options={dimensions}
                                value={filters.dimensions}
                                isMulti={true}
                                defaultValue={[]}
                                placeholder={'By Dimensions'}
                                isSearchable={false}
                                showHideCreative
                                onChange={handleDimensionsChange}
                                isClearable={false}
                              />
                            </div>
                            <div
                              className='dropdown-select createinptWrap'
                              ref={creativeRef}
                            >
                              <button
                                className='btn-icon btn-icon-primary btn-bedcrumb-act dropdown-icon daterange'
                                type='button'
                                name='daterange'
                                aria-expanded='false'
                                onClick={showHideCreative}
                              >
                                Creative Name
                              </button>
                              {creativeNameFilter && (
                                <div className='d-flex createinpt'>
                                  <input
                                    type='text'
                                    value={creativeNameValue}
                                    onChange={handleCreativeInput}
                                    placeholder='Enter Name'
                                  />
                                  {/* {creativeNameError && <span className="error crtErr"> Please enter Creative Name </span>} */}
                                  <div className='applybtnWrap'>
                                    {' '}
                                    <button
                                      className='applybtn'
                                      onClick={setCreativeName}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='dropdown-select'>
                              <DateRangePicker
                                onApply={setDates}
                                onCancel={onCancelDate}
                                isClearable={true}
                                initialSettings={{
                                  endDate: new Date(),
                                  maxDate: new Date(),
                                }}
                                // setEndDate={"10/20/2023"}
                                setEndDate={moment().toDate()}
                              >
                                <button className='btn-icon btn-icon-primary btn-bedcrumb-act dropdown-icon'>
                                  Date Range
                                </button>
                              </DateRangePicker>
                            </div>
                            <div className='dropdown-select'>
                              <ReactSelect
                                options={aspectRatio}
                                value={filters.aspectRatio}
                                isMulti={true}
                                placeholder='Aspect Ratio'
                                isSearchable={true}
                                onChange={handleAspectRatio}
                              />
                            </div>
                          </div>
                          <div className='fillter-right ms-auto'>
                            <button
                              type='button'
                              className='btn-icon single-icon btn-icon-primary btn-bedcrumb-act'
                              disabled={!deleteIds}
                              onClick={deleteConformation}
                            >
                              <i className='icon-delete' />
                            </button>
                          </div>
                        </div>
                        <div className='fillter-options'>
                          {(filters.fileType.length > 0 ||
                            filters.dimensions.length > 0 ||
                            (filters.dates.startDate &&
                              filters.dates.endDate) ||
                            filters.creativeName) && (
                            <button
                              className='clear-btn'
                              onClick={() => ClearFilters('All')}
                            >
                              Clear All
                            </button>
                          )}

                          {filters.fileType.length > 0 && (
                            <span>
                              File Type:{' '}
                              {filters.fileType.map((ele, i) => {
                                return (
                                  <>
                                    {filters.fileType.length > 1 && i != 0
                                      ? ','
                                      : null}{' '}
                                    <b>{ele.label}</b>
                                    <button
                                      type='button'
                                      onClick={() => ClearFilters('fileType')}
                                    >
                                      <i className='icon-close' />
                                    </button>
                                  </>
                                );
                              })}
                            </span>
                          )}
                          {filters.dimensions.length > 0 && (
                            <span>
                              Dimensions:{' '}
                              {filters.dimensions &&
                                filters.dimensions.map((ele, i) => {
                                  return (
                                    <>
                                      {filters.dimensions.length > 1 && i != 0
                                        ? ','
                                        : null}{' '}
                                      <b>{ele.label}</b>
                                      <button
                                        type='button'
                                        onClick={() =>
                                          ClearFilters('dimensions')
                                        }
                                      >
                                        <i className='icon-close' />
                                      </button>
                                    </>
                                  );
                                })}
                            </span>
                          )}
                          {filters.creativeName && (
                            <span>
                              Creative Name: <b>{filters.creativeName}</b>
                              <button
                                type='button'
                                onClick={() => ClearFilters('creativeName')}
                              >
                                <i className='icon-close' />
                              </button>
                            </span>
                          )}
                          {filters.dates.startDate && filters.dates.endDate && (
                            <span>
                              Date Range:{' '}
                              {
                                <>
                                  <b>
                                    {moment(filters.dates.startDate).format(
                                      'DD/MM/YYYY',
                                    )}
                                    -
                                    {moment(filters.dates.endDate).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </b>
                                  <button
                                    type='button'
                                    onClick={() => ClearFilters('dates')}
                                  >
                                    <i className='icon-close' />
                                  </button>
                                </>
                              }
                            </span>
                          )}
                        </div>
                      </div>
                      {data?.length == 0 && !isLoading && (
                        <div className='dragdrop-wrap'>
                          {!filterApply && (
                            <div className='dropzone-wrapper'>
                              <div className='dropzone-desc'>
                                <div className='img-wrap'>
                                  <img src={noDataFound} alt='' />
                                </div>
                                <input
                                  disabled
                                  type='file'
                                  name='img_logo'
                                  className='dropzone'
                                />
                              </div>
                              <p className=''>
                                Start by adding some amazing <br />
                                <b>images</b> and <b>videos</b>
                              </p>
                            </div>
                          )}
                          {filterApply && (
                            <div className='that-miss-wrap'>
                              <div className='img-wrap'>
                                <img src={noDataFound} alt='' />
                              </div>
                              <h2>That's miss</h2>
                              <p>
                                Sorry,that filter combination has no results.
                                Please try different criteria
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {data && (
                        <div className='media-entries'>
                          <div className='show-entries'>
                            {data.length > 0 && (
                              <p>
                                Showing{' '}
                                {currentPage == 0
                                  ? 1
                                  : Math.ceil(
                                      currentPage * itemsPerPage -
                                        itemsPerPage +
                                        (itemsPerPage + 1),
                                    )}{' '}
                                to{' '}
                                {Math.ceil(
                                  currentPage * itemsPerPage + data.length,
                                )}{' '}
                                of {totalRecords} entries
                              </p>
                            )}
                          </div>
                          {/*PAGINATION CODE  */}
                          {totalRecords > itemsPerPage && (
                            <div className='media-pagination'>
                              <ReactPaginate
                                breakLabel={'...'}
                                marginPagesDisplayed={1}
                                previousLabel={<button>Previous</button>}
                                nextLabel={<button>Next</button>}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                forcePage={currentPage}
                                renderOnZeroPageCount={null}
                                pageRangeDisplayed={5}
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {/* Media Listing */}
                      {/* REACT Suraj Code */}
                      <div className='media-list'>
                        {data.map((item, i) => {
                          return (
                            <div className='media-card' key={item?.mediaId}>
                              <div className='radioOption-Item'>
                                <input
                                  type='checkbox'
                                  name='BannerTypes'
                                  id={item?.mediaId}
                                  value={item?.mediaId}
                                  onChange={(e) => handleInputChecked(e)}
                                />
                                <label htmlFor={item?.mediaId}>
                                  <i className='icon-check-mark' />
                                </label>
                              </div>
                              <a
                                data-bs-toggle='modal'
                                data-bs-target='#exampleModal'
                                onClick={(e) => handleShow(e, item)}
                              >
                                <div className='mediaimg-box'>
                                  <div className='media-img'>
                                    {/* {console.log("check the ",checkImage(item.mediaPath))} */}
                                    {item?.fileType?.toLowerCase() ===
                                      'image' && (
                                      <img
                                        src={item?.mediaThumbnail ? item?.mediaThumbnail : imageNotPresent}
                                        alt='media Image'
                                        onError={(e) => {
                                          e.currentTarget.src = imageNotPresent;
                                        }}
                                      />
                                    )}
                                    {item?.fileType?.toLowerCase() ===
                                      'video' && (
                                      <img
                                        src={
                                          item?.mediaThumbnail
                                            ? item?.mediaThumbnail
                                            : videoNotPresent
                                        }
                                        alt='media Image'
                                        onError={(e) => {
                                          e.currentTarget.src = videoNotPresent;
                                        }}
                                      />
                                    )}
                                    {item?.fileType?.toLowerCase() ===
                                      'youtube' && (
                                      <img
                                        src={
                                          item?.mediaThumbnail
                                            ? item?.mediaThumbnail
                                            : youtubeNotPresent
                                        }
                                        alt='media Image'
                                        onError={(e) => {
                                          e.currentTarget.src =
                                            youtubeNotPresent;
                                        }}
                                      />
                                    )}
                                    {item?.fileType?.toLowerCase() ===
                                      'audio' && (
                                      <img src={audioImage} alt='media Image' />
                                    )}
                                    {item?.fileType?.toLowerCase() ===
                                      'html' && (
                                      <img src={htmlImage} alt='media Image' />
                                    )}
                                    {!item?.fileType && (
                                      <img
                                        src={imageNotPresent}
                                        alt='media Image'
                                      />
                                    )}
                                    {item?.mediaThumbnail &&
                                      (item?.fileType?.toLowerCase() ===
                                        'video' ||
                                        item?.fileType?.toLowerCase() ===
                                          'youtube') && (
                                        <span
                                          className={`playVideo list-playvideo ${
                                            item?.fileType?.toLowerCase() ===
                                            'youtube'
                                              ? 'you-tube'
                                              : ''
                                          }`}
                                        >
                                          <i
                                            className={` ${
                                              item?.fileType?.toLowerCase() ===
                                              'video'
                                                ? 'icon-video'
                                                : ''
                                            } ${
                                              item?.fileType?.toLowerCase() ===
                                              'youtube'
                                                ? 'icon-you-tube'
                                                : ''
                                            } `}
                                          ></i>
                                        </span>
                                      )}
                                  </div>
                                  {currentTab === 'All' && (
                                    <div className='media-social'>
                                      {item?.platform == 'Facebook' && (
                                        <img src={facebookIcon} alt='' />
                                      )}
                                      {item?.platform == 'DV360' && (
                                        <img src={DV360Icon} alt='' />
                                      )}
                                      {item?.platform == 'Google Ad' && (
                                        <img src={googleAdIcon} alt='' />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className='mediabox-cont'>
                                  <h3>{item?.mediaName}</h3>
                                  <p>
                                    {item?.videoDuration
                                      ? item?.videoDuration
                                      : ''}
                                    {item?.fileType?.toLowerCase() ===
                                      'image' &&
                                      item?.mediaHeight &&
                                      item?.mediaHeight &&
                                      `${item?.mediaWidth} x ${item?.mediaHeight}px`}
                                    &nbsp;
                                    {item?.mediaSize?.split(' ')[0] &&
                                      `• ${formatFileSize(
                                        parseInt(
                                          item?.mediaSize?.split(' ')[0] * 1024,
                                        ),
                                      )}`}
                                  </p>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      <div className='media-entries'>
                        <div className='show-entries'>
                          {data.length > 0 && (
                            <p>
                              Showing{' '}
                              {currentPage == 0
                                ? 1
                                : Math.ceil(
                                    currentPage * itemsPerPage -
                                      itemsPerPage +
                                      (itemsPerPage + 1),
                                  )}{' '}
                              to{' '}
                              {Math.ceil(
                                currentPage * itemsPerPage + data.length,
                              )}{' '}
                              of {totalRecords} entries
                            </p>
                          )}
                        </div>
                        <div className='media-pagination'>
                          {totalRecords > itemsPerPage && (
                            <div className='media-pagination'>
                              <ReactPaginate
                                breakLabel={'...'}
                                marginPagesDisplayed={1}
                                previousLabel={<button>Previous</button>}
                                nextLabel={<button>Next</button>}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                forcePage={currentPage}
                                renderOnZeroPageCount={null}
                                pageRangeDisplayed={5}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </article>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Modal */}
      {/* expand-modal */}

      {/* <Button variant="primary" onClick={()=>handleShow(true)}>
        Launch demo modal
      </Button> */}
      <Modal
        fullscreen={true}
        show={show}
        onHide={(e) => handleShow(e, null)}
        animation={true}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}

        <Modal.Body className='full-media-gallery'>
          <div className='expand-modal '>
            <div className='modal-left'>
              {(openModalData?.fileType?.toLowerCase() === 'image' ||
                openModalData?.fileType?.toLowerCase() === 'video' ||
                openModalData?.fileType?.toLowerCase() === 'youtube') && (
                <button
                  className='zoom-icon'
                  onClick={() => setToggler(!toggler)}
                >
                  <i className='icon-full-up' />
                </button>
              )}
              <div className='modal-img'>
                {openModalData?.fileType?.toLowerCase() === 'image' && (
                  <img
                    src={openModalData?.mediaPath}
                    alt='media Image'
                    onError={(e) => {
                      e.currentTarget.src = imageNotPresent;
                    }}
                  />
                )}
                {openModalData?.fileType?.toLowerCase() === 'video' && (
                  <img
                    src={
                      openModalData?.mediaThumbnail
                        ? openModalData?.mediaThumbnail
                        : videoNotPresent
                    }
                    alt='media Image'
                    onError={(e) => {
                      e.currentTarget.src = videoNotPresent;
                    }}
                  />
                )}
                {openModalData?.fileType?.toLowerCase() === 'youtube' && (
                  <img
                    src={
                      openModalData?.mediaThumbnail
                        ? openModalData?.mediaThumbnail
                        : youtubeNotPresent
                    }
                    alt='media Image'
                    onError={(e) => {
                      e.currentTarget.src = youtubeNotPresent;
                    }}
                  />
                )}
                {openModalData?.fileType?.toLowerCase() === 'audio' && (
                  <img src={audioImage} alt='media Image' />
                )}
                {openModalData?.fileType?.toLowerCase() === 'html' && (
                  <img src={htmlImage} alt='media Image' />
                )}
                {!openModalData?.fileType && (
                  <img src={imageNotPresent} alt='media Image' />
                )}
                {(openModalData?.fileType?.toLowerCase() === 'video' ||
                  openModalData?.fileType?.toLowerCase() === 'youtube') && (
                  <span className='playVideo'>
                    <i
                      className='icon-video'
                      onClick={() => setToggler(!toggler)}
                    ></i>
                  </span>
                )}
                {openModalData?.mediaPath && openModalData?.fileType && (
                  <>
                    {playAudio ? (
                      <div>
                        <audio controls>
                          <source
                            src={openModalData?.mediaPath}
                            autoplay='autoPlay'
                          />
                        </audio>
                      </div>
                    ) : (
                      <FsLightbox
                        // ref={fsLightBoxRef}
                        toggler={toggler}
                        sources={
                          openModalData?.fileType?.toLowerCase() === 'youtube'
                            ? [
                                `https://www.youtube.com/embed/${extractYouTubeId(
                                  youtube,
                                )}?autoplay=1&mute=1`,
                              ]
                            : [openModalData?.mediaPath]
                        }
                        // types={['image',"youtube","video"]}
                        types={[openModalData?.fileType?.toLowerCase()]}
                        onClose={closefsBox}
                        customAttributes={[
                          {
                            autoPlay:
                              openModalData?.fileType?.toLowerCase() ===
                                'video' || 'youtube'
                                ? 'autoplay'
                                : true,
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='modal-dialog '>
              <div className='modal-content'>
                <div className='modal-header'>
                  <div className='modal-top'>
                    <h3>Asset Details</h3>
                    <button
                      type='button'
                      className='btn-icon btn-icon-primary btn-bedcrumb btn-bedcrumb-act ms-auto'
                      onClick={deleteItemConfirmation}
                    >
                      <i className='icon-delete' />
                    </button>
                    <button
                      type='button'
                      className='primary-btn'
                      data-bs-dismiss='modal'
                      onClick={(e) => handleShow(e, null)}
                    >
                      <i className='icon-close' />
                    </button>
                  </div>
                  <div className='heading-img'>
                    {editName && (
                      <div className='card-form form-panel'>
                        <div className='row align-items-center'>
                          <div className='form-item col-8'>
                            <div className='input-group'>
                              <input
                                type='text'
                                value={editInputValue}
                                onChange={handleEditInput}
                                placeholder='Edit Image Name'
                              />
                            </div>
                            {inputError && (
                              <span className='error'>
                                Please Enter Valid Name
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {!editName && <h4>{openModalData?.mediaName}</h4>}
                    {!editName && (
                      <div className='upload-btn'>
                        <button
                          className='close edit-link'
                          onClick={() => {
                            setEditInputValue(
                              openModalData?.mediaName?.split('.')[0],
                            );
                            setEditName(true);
                          }}
                        >
                          <i className='icon-edit' />
                        </button>
                      </div>
                    )}
                    {editName && (
                      <div className='upload-btn'>
                        <button className='success' onClick={editConfirmation}>
                          <i className='icon-check-mark' />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className='sub-heading'>
                    <h3>Details</h3>
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='expand-body'>
                    <div className='expand-cont-wrap'>
                      {/* <div className="expand-list">
                        <p>Asset ID</p>
                        <span>{openModalData?.mediaId}</span>
                      </div> */}
                      <div className='expand-list'>
                        <p>Type</p>
                        <span>
                          {openModalData?.fileType
                            ? openModalData?.fileType
                            : '-'}
                        </span>
                      </div>
                      {openModalData?.mediaExtension && (
                        <div className='expand-list'>
                          <p>File Extension</p>
                          <span>
                            {openModalData?.mediaExtension
                              ? openModalData?.mediaExtension
                              : '-'}
                          </span>
                        </div>
                      )}
                      {openModalData?.mediaHeight &&
                        openModalData?.mediaWidth && (
                          <div className='expand-list'>
                            <p>Dimensions</p>
                            <span>
                              {openModalData?.mediaHeight &&
                              openModalData?.mediaWidth
                                ? `${openModalData?.mediaHeight} x ${openModalData?.mediaWidth}px`
                                : '-'}
                            </span>
                          </div>
                        )}
                      <div className='expand-list'>
                        <p>File Size</p>
                        <span>
                          {openModalData?.mediaSize &&
                          openModalData?.mediaSize.split(' ')[0]
                            ? formatFileSize(
                                parseInt(
                                  openModalData?.mediaSize.split(' ')[0] * 1024,
                                ),
                              )
                            : '-'}
                        </span>
                      </div>
                      {/* {openModalData?.fileType == 'Video' && <div className="expand-list">
                        <p>Type</p>
                        <span>{openModalData?.platform}</span>
                      </div>} */}
                      {/* {openModalData?.fileType == 'Video' && <div className="expand-list">
                        <p>YouTube channel</p>
                        <span>{openModalData?.mediaId}</span>
                      </div>} */}
                      {openModalData?.youTubeUrl && (
                        <div className='expand-list'>
                          <p>URL</p>
                          <span>
                            <a href={openModalData?.youTubeUrl} target='_blank'>
                              {openModalData?.youTubeUrl}
                            </a>
                          </span>
                        </div>
                      )}
                      {((openModalData?.mediaThumbnail &&
                        openModalData?.fileType == 'Video') ||
                        openModalData?.fileType == 'Audio') && (
                        <div className='expand-list'>
                          <p>Duration</p>
                          <span>
                            {openModalData?.videoDuration
                              ? openModalData?.videoDuration
                              : '-'}
                          </span>
                        </div>
                      )}
                      <div className='expand-list'>
                        <p>Modified</p>
                        <span>
                          {' '}
                          {openModalData?.modified
                            ? moment(openModalData?.modified).format(
                                'MMM DD,YYYY hh:mm:ss A',
                              )
                            : '-'}
                        </span>
                      </div>
                      <div className='expand-list'>
                        <p>Added</p>
                        <span>
                          {openModalData?.addedOn
                            ? moment(openModalData?.addedOn).format(
                                'MMM DD,YYYY hh:mm:ss A',
                              )
                            : '-'}
                        </span>
                      </div>
                      <div className='expand-list'>
                        <p>Platform</p>
                        <span>
                          <img src='images/facebook-media.png' alt='' />
                          {openModalData?.platform == 'Facebook' && (
                            <img src={facebookIcon} alt='Facebook' />
                          )}
                          {openModalData?.platform == 'DV360' && (
                            <img src={DV360Icon} alt='DV360' />
                          )}
                          {openModalData?.platform == 'Google Ad' && (
                            <img src={googleAdIcon} alt='Google ad' />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      {/* expand-modal delete item listing page */}
      <Modal
        show={showDeleteConfirmationPopup}
        onHide={deleteComfirmationClose}
        className='atd-modal approved-model'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-remove'>
              <i className='icon-delete' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>
              Are you sure you want to delete the selected{' '}
              {deleteIds.length == 1 ? 'file' : 'files'}?
            </h3>
            {/* <p>
              The campaign has been sent to Facebook for approval and will be publish in
              sometime
            </p> */}
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={deleteComfirmationClose}
          >
            Cancel
          </button>
          <button type='button' className='primary-btn' onClick={deleteItems}>
            Ok
          </button>
        </div>
      </Modal>

      {/* delete item on deatil page confirmation modal*/}
      <Modal
        show={showDeletePopup}
        onHide={() => setShowDeletePupup(!showDeletePopup)}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-remove'>
              <i className='icon-delete' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Are you sure you want to delete the selected file?</h3>
            {/* <p>
              The campaign has been sent to Facebook for approval and will be publish in
              sometime
            </p> */}
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => setShowDeletePupup(!showDeletePopup)}
          >
            Cancel
          </button>
          <button
            type='button'
            className='primary-btn'
            onClick={() => deleteitem(openModalData?.mediaId)}
          >
            Ok
          </button>
        </div>

        {/* <Modal.Footer>
              <Button variant="secondary" >
                Close
              </Button>
              <Button variant="primary" onClick={deleteItems}>
                Save Changes
              </Button>
            </Modal.Footer> */}
      </Modal>
      <Modal
        show={showEditPopup}
        onHide={() => setShowEditPopup(!showEditPopup)}
        className='atd-modal approved-model'
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-success'>
              <i className='icon-edit' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Are you sure you want to rename the file?</h3>
            {/* <p>
              The campaign has been sent to Facebook for approval and will be publish in
              sometime
            </p> */}
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => {
              setEditName(false);
              setShowEditPopup(!showEditPopup);
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='primary-btn'
            onClick={() => editFileName(openModalData)}
          >
            Ok
          </button>
        </div>
      </Modal>

      <div
        className='modal fade media-modal'
        id='exampleModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content '>
            {/* slide-left */}
            <div className='modal-header'>
              <h3>Upload Files</h3>
              <button
                type='button'
                className='primary-btn'
                data-bs-dismiss='modal'
              >
                <i className='icon-close' />
              </button>
            </div>
            <div className='modal-body'>
              <div className='media-modal-cont'>
                <div className='media-modal-left'>
                  <div className='dropzone-wrapper'>
                    <div className='dropzone-desc'>
                      <div className='img-wrap'>
                        <img src='images/upload-img.svg' alt='' />
                      </div>
                      <p>
                        Drop your files here. <br /> or <b>Browse</b>
                      </p>
                    </div>
                    <input type='file' name='img_logo' className='dropzone' />
                  </div>
                </div>
                <div className='media-modal-right'>
                  <div className='media-upload-list'>
                    <div className='upload-img'>
                      <i className='icon-image' />
                    </div>
                    <div className='upload-cont'>
                      <h3>tab_interaction.png</h3>
                    </div>
                    <div className='upload-btn'>
                      <div className='success'>
                        <i className='icon-check-mark' />
                      </div>
                    </div>
                  </div>
                  <div className='media-upload-list'>
                    <div className='upload-img'>
                      <i className='icon-video' />
                    </div>
                    <div className='upload-cont'>
                      <h3>tab_interaction.png</h3>
                      <p>1.5 MB</p>
                      <div className='progress'>
                        <div
                          className='progress-bar progress-bar-success'
                          role='progressbar'
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                          style={{ width: '40%' }}
                        ></div>
                      </div>
                    </div>
                    <div className='upload-btn'>
                      <button className='close'>
                        <i className='icon-close' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade atd-modal approved-model'
        id='approvedState'
        tabIndex={-1}
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content '>
            <div className='modal-body'>
              <div className='modal-img-cont'>
                <figure className='modal-icon modal-success'>
                  <i className='icon-check-mark' />
                </figure>
              </div>
              <div className='modal-text'>
                <h3>Thank you for approving the request!</h3>
                <p>
                  The campaign has been sent to Facebook for approval and will
                  be publish in sometime
                </p>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn'
                data-bs-dismiss='modal'
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Approved Modal Start Here */}
      <div
        className='modal fade atd-modal reject-model'
        id='rejectState'
        tabIndex={-1}
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='modal-img-cont'>
                <figure className='modal-icon modal-remove'>
                  <i className='icon-close' />
                </figure>
              </div>
              <div className='modal-text'>
                <h3>Rejection of request</h3>
                <p>Please type reason for rejection of request</p>
              </div>
              <div className='form-panel'>
                <div className='form-item-cont row'>
                  <div className='form-item col-12'>
                    <label>Type Reason</label>
                    <div className='input-sizer stacked'>
                      <textarea
                        oninput='this.parentNode.dataset.value = this.value'
                        rows={1}
                        placeholder='Type Reason'
                        defaultValue={''}
                      />
                    </div>
                    <span className='error'> Please Type Reason </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='primary-btn cancel-btn'
                data-bs-dismiss='modal'
              >
                Cancel
              </button>
              <button
                type='button'
                className='primary-btn primary-remove'
                data-bs-dismiss='modal'
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <MediaWidget getData={getData} /> */}

      {/* Approved Modal Ends Here */}
    </>
  );
};
