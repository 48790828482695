import React from 'react';
import Card from '../common/Card';
import CardHeader from '../common/CardHeader';
import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';
import { useId } from 'react';
import { generateRandomId } from '../../utils/utils';
import { GoogleStatus } from '../../pages/googleAds/GoogleAdConstant';
import AdvancedCardHeader from '../common/AdvancedCardHeader';
import {
  googleCampaignTypeList,
  googleObjectiveList,
} from '../../state/googleCreateCampaign/CreateCampaignReducer';
// import searchImg from '../../assets/images/search.svg';
import searchImg from '../../assets/images/search.svg';
import displayImg from '../../assets/images/display.svg';
import shoppingImg from '../../assets/images/shopping.svg';
import videoImg from '../../assets/images/video.svg';
import discoveryImg from '../../assets/images/discovery.svg';

const CampaignTypes = (props) => {
  let onChangeHandler = props?.onChangeHandler;
  let campaign_type_id = props?.campaign_type_id;
  let objective_id = props?.objective_id;
  let disableCampaignType = props?.disableCampaignType;
  let formErrors = props?.formErrors;

  const campTypeList = () => {
    switch (`${objective_id}`) {
      case `${googleObjectiveList?.Sales?.objective_id}`: //sales
        {
          return (
            <>
              {/*search */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault550'
                    value={googleCampaignTypeList?.Search?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Search?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault550'
                  >
                    <figure className='crt-img'>
                      <img src={searchImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Search
                      <p>
                        Get in front of high-intent customers at the right time
                        on Google Search
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              {/* perform */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault551'
                    value={
                      googleCampaignTypeList?.['Performance Max']
                        ?.campaign_type_id
                    }
                    checked={
                      googleCampaignTypeList?.['Performance Max']
                        ?.campaign_type_id == campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault551'
                  >
                    <figure className='crt-img'>
                      <img
                        src={
                          'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjgwIiB2aWV3Qm94PSIwIDAgMTAwIDgwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNzUgNjguOTI5Mkg4OVY3Mi40MjkySDc1VjY4LjkyOTJaIiBmaWxsPSIjRTdFN0U3Ii8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNjMgNDRIODguOTExNFY2NS40MDUxSDYzVjQ0WiIgZmlsbD0iI0U3RTdFNyIvPgo8cmVjdCB4PSI1OC41IiB5PSIzOC41IiB3aWR0aD0iMzQiIGhlaWdodD0iMzkiIHN0cm9rZT0iI0RBRENFMCIvPgo8cGF0aCBkPSJNMjQgMkg2NlYzMkgyNFYyWiIgZmlsbD0iI0Y1RjVGNSIvPgo8cGF0aCBkPSJNMzAgMTVINThWMTguNUgzMFYxNVoiIGZpbGw9IiNDMEMwQzAiLz4KPHBhdGggZD0iTTMwIDIxSDU4VjI0LjVIMzBWMjFaIiBmaWxsPSIjRTdFN0U3Ii8+CjxwYXRoIGQ9Ik00MiA4SDU4VjExLjVINDJWOFoiIGZpbGw9IiNFN0U3RTciLz4KPHBhdGggZD0iTTQwIDdIMzBWMTJINDBWN1oiIGZpbGw9IiNGRkMxMDciLz4KPHJlY3QgeD0iOC41IiB5PSIzOC41IiB3aWR0aD0iNDAiIGhlaWdodD0iMjkiIHN0cm9rZT0iI0RBRENFMCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjAwMSA1MkgyNi41OTRWNTUuNUgxMy4wMDFWNTJaIiBmaWxsPSIjQzBDMEMwIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzAgNDNINDIuMjc4OVY1NUgzMFY0M1Y0M1oiIGZpbGw9IiNFRUVFRUUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4wMDEgNThINDMuNDc2VjYxLjVIMTNWNThIMTMuMDAxWiIgZmlsbD0iI0U3RTdFNyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzIDQ0SDIzVjQ5SDEzVjQ0WiIgZmlsbD0iI0ZGQzEwNyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYzIDY4SDczVjczSDYzVjY4WiIgZmlsbD0iI0ZGQzEwNyIvPgo8bGluZSB4MT0iMzYuNSIgeTE9IjM4IiB4Mj0iMzYuNSIgeTI9IjMyIiBzdHJva2U9IiNEQURDRTAiLz4KPGxpbmUgeDE9IjQ4IiB5MT0iNTIuNSIgeDI9IjU4IiB5Mj0iNTIuNSIgc3Ryb2tlPSIjREFEQ0UwIi8+Cjwvc3ZnPgo='
                        }
                        alt='No creatives assigned'
                      />
                    </figure>
                    <div className='label-multitext'>
                      Performance Max
                      <p>
                        Reach audiences across all of Google with a single
                        campaign.
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              {/* shopping */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault553'
                    value={googleCampaignTypeList?.Shopping?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Shopping?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault553'
                  >
                    <figure className='crt-img'>
                      <img src={shoppingImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Shopping
                      <p>
                        Showcase your products to shoppers as they explore what
                        to buy
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              {/* demand gen */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault555'
                    value={
                      googleCampaignTypeList?.['Demand Gen']?.campaign_type_id
                    }
                    checked={
                      googleCampaignTypeList?.['Demand Gen']
                        ?.campaign_type_id == campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault555'
                  >
                    <figure className='crt-img'>
                      <img src={discoveryImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Demand Gen
                      <p>Run ads on YouTube, Gmail, Discover, and more</p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      case `${googleObjectiveList?.Leads?.objective_id}`: //Leads
        {
          return (
            <>
              {/*search */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault550'
                    value={googleCampaignTypeList?.Search?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Search?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault550'
                  >
                    <figure className='crt-img'>
                      <img src={searchImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Search
                      <p>
                        Get in front of high-intent customers at the right time
                        on Google Search
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              {/* display */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault552'
                    value={googleCampaignTypeList?.Display?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Display?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault552'
                  >
                    <figure className='crt-img'>
                      <img src={displayImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Display
                      <p>
                        Reach customers across 3 million sites and apps with
                        engaging creative
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      case `${googleObjectiveList?.['Website Traffic']?.objective_id}`: //website traffic
        {
          return (
            <>
              {/*search */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault550'
                    value={googleCampaignTypeList?.Search?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Search?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault550'
                  >
                    <figure className='crt-img'>
                      <img src={searchImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Search
                      <p>
                        Get in front of high-intent customers at the right time
                        on Google Search
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              {/* display */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault552'
                    value={googleCampaignTypeList?.Display?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Display?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault552'
                  >
                    <figure className='crt-img'>
                      <img src={displayImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Display
                      <p>
                        Reach customers across 3 million sites and apps with
                        engaging creative
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              {/* demand gen */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault555'
                    value={
                      googleCampaignTypeList?.['Demand Gen']?.campaign_type_id
                    }
                    checked={
                      googleCampaignTypeList?.['Demand Gen']
                        ?.campaign_type_id == campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault555'
                  >
                    <figure className='crt-img'>
                      <img src={discoveryImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Demand Gen
                      <p>Run ads on YouTube, Gmail, Discover, and more</p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      case `${googleObjectiveList?.['Brand awareness and reach']?.objective_id}`: //Brand awareness and reach
        {
          return (
            <>
              {/* display */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault552'
                    value={googleCampaignTypeList?.Display?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.Display?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault552'
                  >
                    <figure className='crt-img'>
                      <img src={displayImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Display
                      <p>
                        Reach customers across 3 million sites and apps with
                        engaging creative
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      case `${googleObjectiveList?.['Product and brand consideration']?.objective_id}`: //Product and brand consideration
        {
          return (
            <>
              {/* demand gen */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault555'
                    value={
                      googleCampaignTypeList?.['Demand Gen']?.campaign_type_id
                    }
                    checked={
                      googleCampaignTypeList?.['Demand Gen']
                        ?.campaign_type_id == campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault555'
                  >
                    <figure className='crt-img'>
                      <img src={discoveryImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Demand Gen
                      <p>Run ads on YouTube, Gmail, Discover, and more</p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      case `${googleObjectiveList?.['App promotion']?.objective_id}`: //App promotion
        {
          return (
            <>
              {/* app */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault555'
                    value={googleCampaignTypeList?.['App']?.campaign_type_id}
                    checked={
                      googleCampaignTypeList?.['App']?.campaign_type_id ==
                      campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault555'
                  >
                    <figure className='crt-img'>
                      <img src={discoveryImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      App
                      <p>Drive downloads and grow engagement for your app</p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      case `${googleObjectiveList?.['Local store visits and promotion']?.objective_id}`: //Local store visits and promotion
        {
          return (
            <>
              {/* perform */}
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='campaign_type_id'
                    id='flexRadioDefault551'
                    value={
                      googleCampaignTypeList?.['Performance Max']
                        ?.campaign_type_id
                    }
                    checked={
                      googleCampaignTypeList?.['Performance Max']
                        ?.campaign_type_id == campaign_type_id
                    }
                    disabled={disableCampaignType}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault551'
                  >
                    <figure className='crt-img'>
                      <img src={searchImg} alt='No creatives assigned' />
                    </figure>
                    <div className='label-multitext'>
                      Performance Max
                      <p>
                        Reach audiences across all of Google with a single
                        campaign.
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </>
          );
        }
        break;
      default: {
        return (
          <>
            {/*search */}
            <div className='col-12 px-0'>
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='campaign_type_id'
                  id='flexRadioDefault550'
                  value={googleCampaignTypeList?.Search?.campaign_type_id}
                  checked={
                    googleCampaignTypeList?.Search?.campaign_type_id ==
                    campaign_type_id
                  }
                  disabled={disableCampaignType}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault550'
                >
                  <figure className='crt-img'>
                    <img src={searchImg} alt='No creatives assigned' />
                  </figure>
                  <div className='label-multitext'>
                    Search
                    <p>
                      Get in front of high-intent customers at the right time on
                      Google Search
                    </p>
                  </div>
                </label>
              </div>
            </div>
            {/* perform */}
            <div className='col-12 px-0'>
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='campaign_type_id'
                  id='flexRadioDefault551'
                  value={
                    googleCampaignTypeList?.['Performance Max']
                      ?.campaign_type_id
                  }
                  checked={
                    googleCampaignTypeList?.['Performance Max']
                      ?.campaign_type_id == campaign_type_id
                  }
                  disabled={disableCampaignType}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault551'
                >
                  <figure className='crt-img'>
                    <img src={searchImg} alt='No creatives assigned' />
                  </figure>
                  <div className='label-multitext'>
                    Performance Max
                    <p>
                      Reach audiences across all of Google with a single
                      campaign.
                    </p>
                  </div>
                </label>
              </div>
            </div>
            {/* display */}
            <div className='col-12 px-0'>
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='campaign_type_id'
                  id='flexRadioDefault552'
                  value={googleCampaignTypeList?.Display?.campaign_type_id}
                  checked={
                    googleCampaignTypeList?.Display?.campaign_type_id ==
                    campaign_type_id
                  }
                  disabled={disableCampaignType}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault552'
                >
                  <figure className='crt-img'>
                    <img src={displayImg} alt='No creatives assigned' />
                  </figure>
                  <div className='label-multitext'>
                    Display
                    <p>
                      Reach customers across 3 million sites and apps with
                      engaging creative
                    </p>
                  </div>
                </label>
              </div>
            </div>
            {/* shopping */}
            <div className='col-12 px-0'>
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='campaign_type_id'
                  id='flexRadioDefault553'
                  value={googleCampaignTypeList?.Shopping?.campaign_type_id}
                  checked={
                    googleCampaignTypeList?.Shopping?.campaign_type_id ==
                    campaign_type_id
                  }
                  disabled={disableCampaignType}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault553'
                >
                  <figure className='crt-img'>
                    <img src={shoppingImg} alt='No creatives assigned' />
                  </figure>
                  <div className='label-multitext'>
                    Shopping
                    <p>
                      Showcase your products to shoppers as they explore what to
                      buy
                    </p>
                  </div>
                </label>
              </div>
            </div>
            {/* video */}
            <div className='col-12 px-0'>
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='campaign_type_id'
                  id='flexRadioDefault554'
                  value={googleCampaignTypeList?.Video?.campaign_type_id}
                  checked={
                    googleCampaignTypeList?.Video?.campaign_type_id ==
                    campaign_type_id
                  }
                  disabled={disableCampaignType}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault554'
                >
                  <figure className='crt-img'>
                    <img src={videoImg} alt='No creatives assigned' />
                  </figure>
                  <div className='label-multitext'>
                    Video
                    <p>Reach viewers on YouTube and get conversions</p>
                  </div>
                </label>
              </div>
            </div>
            {/* demand gen */}
            <div className='col-12 px-0'>
              <div className='form-check-box'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='campaign_type_id'
                  id='flexRadioDefault555'
                  value={
                    googleCampaignTypeList?.['Demand Gen']?.campaign_type_id
                  }
                  checked={
                    googleCampaignTypeList?.['Demand Gen']?.campaign_type_id ==
                    campaign_type_id
                  }
                  disabled={disableCampaignType}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault555'
                >
                  <figure className='crt-img'>
                    <img src={discoveryImg} alt='No creatives assigned' />
                  </figure>
                  <div className='label-multitext'>
                    Demand Gen
                    <p>Run ads on YouTube, Gmail, Discover, and more</p>
                  </div>
                </label>
              </div>
            </div>
          </>
        );
      }
    }
  };
  return (
    <Card>
      <AdvancedCardHeader>
        <div className='card-lt-pane'>
          <h5>Select a Campaign type *</h5>
          <span
            className='error'
            id='error-campaignType'
            style={{ position: 'static' }}
          >
            {formErrors?.campaignType}
          </span>
        </div>
      </AdvancedCardHeader>
      <CardBody>
        <div className='card-form form-panel'>
          <div className='row align-items-center'>
            <div className='action-btn-cont'>
              <div
                className='action-btn-both action-btn-img-tag row'
                onChange={onChangeHandler}
              >
                {campTypeList()}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CampaignTypes;
