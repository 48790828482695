import Modal from 'react-bootstrap/Modal';

const ApprovalConfirmationPopup = (props) => {
  return (
    <Modal
      show={true}
      className='atd-modal approved-model'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='modal-img-cont'></div>
        <div className='modal-text'>
          {props?.isPublisher ? (
            <>
              <h3>
                {`You have selected yourself as an approver for this campaign, please note that it will be submitted directly to ${props?.type?.toUpperCase()} for approval.`}
              </h3>
              <h3>Are you sure you want to proceed with this action?</h3>
            </>
          ) : (
            <>
              <h3>
                Are you sure you want to send this campaign for approval to
                publisher?
              </h3>
            </>
          )}
        </div>
      </Modal.Body>

      <div className='modal-footer'>
        <button
          type='button'
          className='primary-btn footer-close-btn'
          onClick={props?.handleCloseModel}
        >
          No
        </button>
        <button
          type='button'
          className='primary-btn'
          onClick={props?.handleApprovalPublisherRequest}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};
export default ApprovalConfirmationPopup;
