import axios from 'axios';

import audioImage from '../assets/images/audio-small.png';
import htmlImage from '../assets/images/html-small.png';
import imageNotPresent from '../assets/images/image-small.png';
import videoNotPresent from '../assets/images/video-small.png';
import youtubeNotPresent from '../assets/images/youtube-small.png';

export const checkForNull = (value) => {
  return value !== null && value !== undefined ? value : '-';
};

export const capitalizeFirstLetter = (string = '') => {
  if (string == '0') {
    return 'Published(Disabled)';
  } else if (string == '1') {
    return 'Published(Enabled)';
  } else if (string == 'na' || string === undefined || string === null) {
    return 'NA';
  } else {
    return capitalizeWords(string?.charAt(0)?.toUpperCase() + string?.slice(1));
  }
};

export const renderMedia = (item) => {
  const fileType =
    item?.fileType?.toLowerCase() || item?.medType?.toLowerCase();
  const mediaPath = item?.mediaPath || item?.medPath;
  const mediaThumbnail = item?.mediaThumbnail || item?.medThumbnailPath;

  let source = imageNotPresent;
  let altText = 'media Image';
  let additionalClass = '';

  if (fileType === 'image') {
    source = mediaPath;
  } else if (fileType === 'video') {
    source = mediaThumbnail || videoNotPresent;
    additionalClass = 'playVideo list-playvideo';
  } else if (fileType === 'youtube') {
    source = mediaThumbnail || youtubeNotPresent;
    additionalClass = 'playVideo list-playvideo you-tube';
  } else if (fileType === 'audio') {
    source = audioImage;
  } else if (fileType === 'html') {
    source = htmlImage;
  }

  const onErrorHandler = (e) => {
    if (fileType === 'video') {
      e.currentTarget.src = videoNotPresent;
    } else if (fileType === 'youtube') {
      e.currentTarget.src = youtubeNotPresent;
    } else {
      e.currentTarget.src = imageNotPresent;
    }
  };

  return (
    <>
      {fileType && <img src={source} alt={altText} onError={onErrorHandler} />}
      {mediaThumbnail && (fileType === 'video' || fileType === 'youtube') && (
        <span className={`${additionalClass}`}>
          <i
            className={`icon-${
              fileType === 'video' ? 'videono' : 'you-tubeno'
            }`}
          ></i>
        </span>
      )}
    </>
  );
};

export function capitalizeWords(inputString) {
  // Split the string into an array of words
  const words = inputString.split('_');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );

  // Join the capitalized words back into a string
  const resultString = capitalizedWords.join(' ');

  return resultString;
}

export const extractInitials = (username) => {
  const nameParts = username.split(' ').filter(Boolean);
  const firstName = nameParts.length > 0 ? nameParts[0] : '';
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

  const firstInitial = firstName.charAt(0);
  const lastInitial = lastName.charAt(0);

  return `${firstInitial}${lastInitial}`;
};

export const scrollToError = () => {
  const errorElements = document.querySelectorAll('.error'); // Assuming error elements have a common class 'error'
  if (errorElements.length > 0) {
    const firstErrorElement = errorElements[0];
    firstErrorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }
};

export function generateRandomId(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export function isValidArray(arr) {
  return Array.isArray(arr) && arr.length > 0;
}

export function convertToLabelValuePairArray(inputString) {
  var rangeArray = inputString?.split(',');

  return rangeArray?.map(function (range) {
    return {
      label: range,
      value: range,
    };
  });
}

export const getCookies = () => {
  // Split document.cookie string into individual cookies
  let cookieName = 'Token';
  const cookies = document?.cookie.split(';');

  // Iterate over cookies to find the one with the specified name
  for (let cookie of cookies) {
    cookie = cookie.trim(); // Remove leading and trailing whitespace
    // Check if the cookie starts with the specified name
    if (cookie.startsWith(cookieName + '=')) {
      // Return the value of the cookie
      return JSON.stringify(cookie?.substring(cookieName?.length + 1));
    }
  }
  // Return null if the cookie is not found
  return null;
};

export function parseUrlEncodedJson(urlEncodedJson) {
  console.log('urlEncodedJson', urlEncodedJson);
  // Decode the URL-encoded JSON string
  const decodedJson = decodeURIComponent(urlEncodedJson);

  // Parse the JSON string into a JavaScript object
  try {
    const parsedObject = JSON?.parse(decodedJson);
    return parsedObject;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
}

export const downloadFile = async (endpoint, downloadFileName) => {
  let getCookie = getCookies();
  const CustomHeader = {
    Authorization: getCookie?.substring(1, getCookie?.length - 1),
  };

  const downloadUrl = endpoint;
  let res = await axios.get(downloadUrl, {
    headers: {
      accept: 'text/plain',
      ...CustomHeader,
    },
    responseType: 'blob',
  });

  try {
    const fileURL = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = fileURL;
    let fileName = `${downloadFileName}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.log('err', err);
  }
};

export const handleFocus = (e) => {
  e.target.addEventListener(
    'wheel',
    function (e) {
      e.preventDefault();
    },
    { passive: false },
  );
};

export const blockInvalidChar = (e) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
