import { blockInvalidChar, handleFocus } from '../../utils/utils';
import CardBody from '../common/CardBody';
import ErrorDisplay from '../common/ErrorDisplay';

const SingleInput = (props) => {
  return (
    <>
      <CardBody>
        <div className='card-form form-panel'>
          <div className='row align-items-center'>
            <h6 className='card-header-title'>{props?.label}</h6>
            <div className='form-item col-12'>
              <div className='label-icon'>
                <label>{props?.label}</label>
              </div>
              <div className='input-group'>
                <input
                  type={`${props.type}` || 'text'}
                  id='text'
                  placeholder={`${props?.label}`}
                  onChange={props?.handleSetValue}
                  value={props?.value}
                  readOnly={props?.getCurrentPageStatus}
                  onFocus={handleFocus}
                  onKeyDown={props?.type === 'number' && blockInvalidChar}
                />
              </div>
              {props?.totalStringLength && (
                <small className='select-time'>
                  {typeof props?.totalLength == 'undefined'
                    ? 0
                    : props?.totalLength}{' '}
                  / {props?.totalStringLength}
                </small>
              )}
              <ErrorDisplay val={props?.error} />
            </div>
          </div>
        </div>
      </CardBody>
      {props?.bgLineDisplay && <div className='divder-form'></div>}
    </>
  );
};
export default SingleInput;
