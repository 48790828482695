import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './state/store';

// Toast for error
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <Router>
      <div className='App'>
        <Provider store={store}>
          <AppRoutes></AppRoutes>
        </Provider>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </div>
    </Router>
  );
}

export default App;
