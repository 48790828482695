import React from 'react';

function CardHeader(props) {
  return (
    <>
      <div className='card-header-top'>
        <div className='card-lt-pane'>{props.children}</div>
      </div>
    </>
  );
}

export default CardHeader;
