import React from 'react';
import Card from '../common/Card';
import CardBody from '../common/CardBody';
import CardHeader from '../common/CardHeader';
import ReactSelect from '../../components/common/ReactSelect';

const LocationGroups = (props) => {
  let locationHandler = props?.locationHandler;
  let locationGroupHandler = props?.locationGroupHandler;
  let locationType = props?.locationType;

  return (
    <>
      <Card>
        <CardHeader>
          <h5>Campaign feeds</h5>
          <p>Which store locations should your ads promote?</p>
        </CardHeader>

        <CardBody>
          <div className='card-form form-panel'>
            <div className='row align-items-center'>
              <div className='form-item col-12'>
                <h6 className='card-header-title'>Choose locations</h6>
                <div className='label-icon'>
                  <label>Choose locations for this campaign:</label>
                </div>
                <ReactSelect
                  options={[
                    { label: 'Use all locations', value: 'Use all locations' },
                    {
                      label: 'Use location groups',
                      value: 'Use location groups',
                    },
                  ]}
                  closeMenuOnSelect={true}
                  onChange={locationHandler}
                  value={{
                    label: locationType,
                    value: locationType,
                  }}
                />
              </div>
              {locationType == 'Use location groups' ? (
                <>
                  <div className='form-item col-12'>
                    <h6 className='card-header-title'>Location Groups</h6>
                    <div className='label-icon'>
                      <label>Location Groups</label>
                    </div>
                    <ReactSelect
                      options={[]}
                      closeMenuOnSelect={true}
                      isMulti={true}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default LocationGroups;
