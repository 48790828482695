import '../../../assets/css/style.css';
import Sidebar from '../../../components/common/Sidebar';
import SideTreeMenu from '../../../components/common/SideTreeMenu';
import Footer from '../../../components/common/Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { useEffect } from 'react';
import { DV360Options } from '../DV360Constant';
import DataDetails from './Component/DataDetails';
import { useState } from 'react';
import moment from 'moment';
import { capitalizeFirstLetter, checkForNull } from '../../../utils/utils';
import SideTreeMenuView from '../Component/SideTreeMenuView';
import DvErrorDisplay from '../../../components/dv360/dvErrorDisplay';
import { useSelector } from 'react-redux';
import ApproveReject from '../../../components/common/ApproveReject';
import ReviewLog from '../../googleAds/ReviewLog/ReviewLog';
import ThankYouApprovalPopup from '../../../components/common/ThankYouApprovalPopup';
import DisplayCampaignStatus from '../../../components/common/DisplayCampaignStatus';
import Loader from '../../../components/common/Loader';

const LineItemView = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [insertionData, setInsertionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [lineItemError, setLineItemError] = useState('');
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const [getCurrentStatus, setCurrentStatus] = useState('');
  const [isViewPage, setIsViewPage] = useState(true);
  const [getCurrentInsertionCampaignId, setCurrentInsertionCampaignId] =
    useState('');
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getApprovalFlag, setApprovalFlag] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  useEffect(() => {
    if (query.get('platForm') === DV360Options.Line_Item.platform) {
      setIsViewPage(false);
    } else {
      setIsViewPage(true);
    }
  }, [query.get('platForm')]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `${baseUrlStg}/api/LineItem/getByLineItemsID?lineItemID=${id}`,
      );
      if (response) {
        console.log('API Response1', response.response);
        setIsLoading(false);
        setData(response.response);
        setLineItemError(response.response?.insertion_order['campaign_id']);
        setCurrentStatus(response?.response?.moderation_status);
        fetchDataInsertionData(response.response.insertion_order_id);
        setApprovalFlag(response?.approvalButton);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataInsertionData = async (id) => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `${baseUrlStg}/api/InsertionOrder/getInsertionOrderByID?insertionOrderID=${id}`,
      );
      //atdcoreapi.experiencecommerce.com/api/InsertionOrder/getInsertionOrderByID?insertionOrderID=3
      console.log('API Response', response.response);
      if (response) {
        setIsLoading(false);
        setInsertionData(response.response);
        setCurrentInsertionCampaignId(response?.response?.campaign_id);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    line_item_name = '-',
    dv360_lineitem_id = '-',
    lineItem_type = '-',
    custom_startdate = '-',
    custom_enddate = '-',
    goal_value = '-',
    billable_outcome = '-',
    optimization_action = '-',
    frequency_cap_limit_value = '-',
    budget_pacing_frequency_spread = '-',
    budget_pacing_frequency = '-',
    bid_strategy = '-',
    frequency_limit_type = '-',
    frequency_limit = '-',
    frequency_cap_type = '-',
  } = data || {};

  const lineItemListUrl = DV360Options.Line_Item.url;

  const goToListingPage = () => {
    navigate(DV360Options.Campaigns.url);
  };

  const campaign_id = insertionData?.campaign_id;

  const handleRedirection = () => {
    navigate(lineItemListUrl);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              {data && (
                <SideTreeMenuView
                  id={campaign_id}
                  IdAndType={{
                    id: id,
                    type: DV360Options.Line_Item.name,
                  }}
                  AtdStatus={capitalizeFirstLetter(data?.moderation_status)}
                  Dv360Status={capitalizeFirstLetter(data?.is_enabled) || 'NA'}
                />
              )}
              <article className='side-right-form-wrap side-right-pad'>
                <div className='tab-new-form'>
                  <div className='camp-tab-cont d-flex'>
                    <div className='short-left-bar'>
                      <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <Link
                          className={`primary-btn ${
                            isViewPage ? 'active' : ''
                          }`}
                          to={`${DV360Options.Line_Item.viewPageUrl}/${id}`}
                        >
                          <i className='icon-show-password'></i> View
                        </Link>
                        {getCurrentStatus == 'in_moderation' ? (
                          <Link
                            class='primary-btn disable-button'
                            title='Entry under moderation: Editing disabled until review completion.'
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        ) : (
                          <Link
                            class='primary-btn'
                            to={DV360Options.Line_Item.editUrl + `/${id}`}
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        )}
                        <Link
                          className={`primary-btn ${
                            !isViewPage ? 'active' : ''
                          }`}
                          to={`${DV360Options.Line_Item.viewPageUrl}/${id}?platForm=${DV360Options.Line_Item.platform}&form=${DV360Options.Line_Item.reviewForm}`}
                        >
                          <i className='icon-history'></i> Review Log
                        </Link>
                      </div>
                    </div>
                    {getCurrentStatus == 'in_moderation' &&
                      getUserInfo?.roles == 'Publisher' &&
                      getApprovalFlag && (
                        <ApproveReject
                          platform={'DV360'}
                          campaignId={getCurrentInsertionCampaignId}
                          getUserInfo={getUserInfo}
                          setThankYouApprovalPopupShowStatus={
                            setThankYouApprovalPopupShowStatus
                          }
                          getThankYouApprovalPopupShowStatus={
                            getThankYouApprovalPopupShowStatus
                          }
                          setDisplayLoader={setDisplayLoader}
                        />
                      )}
                  </div>
                </div>
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  <DvErrorDisplay id={lineItemError} />

                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        {isViewPage ? (
                          <div className='card-primary'>
                            <DisplayCampaignStatus
                              moderationStatus={getCurrentStatus}
                              publishedAt={data?.updated_at}
                            />
                            <DataDetails
                              Label={'Line item name'}
                              LabelFor={line_item_name}
                            />
                            <DataDetails
                              Label={'DV360 line item id'}
                              LabelFor={dv360_lineitem_id}
                            />
                            <DataDetails
                              Label={'Line item type'}
                              LabelFor={lineItem_type}
                            />
                            <DataDetails
                              Label={'Start date'}
                              LabelFor={moment(custom_startdate).format(
                                'DD-MMM-YYYY',
                              )}
                            />
                            <DataDetails
                              Label={'End date'}
                              LabelFor={
                                moment(custom_enddate).format('DD-MMM-YYYY') ||
                                '-'
                              }
                            />
                            <DataDetails
                              Label={'Budget and pacing'}
                              LabelFor={`Budget is set at the insertion order level ${budget_pacing_frequency} ${budget_pacing_frequency_spread} `}
                            />
                            <DataDetails
                              Label={'Bid strategy'}
                              LabelFor={bid_strategy}
                            />

                            <DataDetails
                              Label={'Frequency Cap'}
                              LabelFor={
                                frequency_cap_type === 'no limit'
                                  ? 'No Limit'
                                  : `Limit frequency to ${checkForNull(
                                      frequency_cap_limit_value,
                                    )} ${
                                      frequency_limit_type ===
                                      'Lifetime of this campaign'
                                        ? 'Lifetime of this campaign'
                                        : `exposures per ${checkForNull(
                                            frequency_limit,
                                          )} ${checkForNull(
                                            frequency_limit_type,
                                          )}`
                                    }`
                              }
                            />
                          </div>
                        ) : (
                          <ReviewLog
                            id={id}
                            form={DV360Options.Line_Item.reviewForm}
                            platForm={DV360Options.Line_Item.platform}
                          ></ReviewLog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  >
                    History
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer
        closePath={DV360Options.Campaigns.url}
        prevPath={lineItemListUrl}
        // nextBtnlabel='Next'
        // nextPath={lineItemListUrl}
      ></Footer>
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          type={'Dv360'}
          approved={'approved'}
        />
      )}
      {displayLoader && <Loader />}
    </div>
  );
};

export default LineItemView;
