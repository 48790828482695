import React, { useState } from 'react';
import Sidebar from '../../components/common/Sidebar';
import Loader from '../../components/common/Loader';
import ReactSelect from '../../components/common/ReactSelect';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { getApi, postApi } from '../../utils/Apis';
import { toast, ToastContainer } from 'react-toastify';

import { baseUrlStg } from '../../utils/constants';
import { useSelector } from 'react-redux';

const CreateTaxonomyAdCID = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [lpUrlValue, setLpurlValue] = useState({
    label: 'No',
    value: false,
  });
  const [landingUrlInputValue, setLandingUrlInputValue] = useState('');
  const [disableUrlInput, setDisableUrlInput] = useState(false);
  const [showPupup, setShowPupup] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setsearchParams] = useSearchParams();
  const campaign_id = searchParams.get('campaign_id');
  const ad_id = searchParams.get('ad_id');
  const LoginReducer = useSelector((state) => state?.loginReducer);
  const userId = LoginReducer?.userToken?.Userid;

  const lpurlOptions = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const handlelpUrl = (event) => {
    const value = event;
    console.log(value);
    setLpurlValue(value);
    if (event.label.toLowerCase() === 'yes') {
      setLandingUrlInputValue('{lpurl}');
      setDisableUrlInput(true);
    } else {
      setDisableUrlInput(false);
      setLandingUrlInputValue('');
    }
  };

  const handleLandingUrl = (event) => {
    console.log(event.target.value);
    const value = event?.target?.value;
    setLandingUrlInputValue(value);
  };

  const submitHandler = async () => {
    setFormErrors(validate());
    console.log('outside');
    setTimeout(async () => {
      if (Object.keys(validate()).length === 0) {
        console.log('lpUrlValue', lpUrlValue);
        console.log('landingUrlInputValue', landingUrlInputValue);
        console.log('campaignid', campaign_id);
        console.log('ad_id', ad_id);

        try {
          const postData = {
            taxonomy_media_campaign_id: parseInt(campaign_id),
            taxonomy_media_campaign_ad_id: ad_id ? parseInt(ad_id) : 0,
            is_lp_url: lpUrlValue.value,
            landing_url: landingUrlInputValue,
            created_by: userId,
            last_edited_by: 0,
          };

          console.log('postData', postData);

          const URL = `${baseUrlStg}/api/taxonomy-media/Create_CID`;
          const headers = {
            'Content-Type': 'application/json-patch+json',
          };
          const res = await postApi(URL, postData, headers);
          console.log('post api responce create campaign', res);
          if (res.data.success) {
            setShowPupup(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 100);
  };

  const validate = () => {
    const errors = {};
    // const regEx = /^(?:(?:https?|www):\/\/)?(?:[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/[\w@?^=%&/~+#-]*)?$/
    if (landingUrlInputValue === '') {
      errors.landing_url = 'Please enter landing url';
    }
    return errors;
  };

  const handleNavigate = () => {
    if (campaign_id && ad_id) {
      navigate(`/taxonomy-manager/CID-structer-list?ad_id=${parseInt(ad_id)}`);
    } else {
      navigate(
        `/taxonomy-manager/CID-structer-list?campaign_id=${parseInt(
          campaign_id,
        )}`,
      );
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container'>
          <div className='container'>
            <div className='taxonomy-panel'>
              <div>
                <Sidebar />
              </div>
              <div className='right-side-box media-section-left'>
                <header>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-left-bar'>
                      <h2>Create CID</h2>
                    </div>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={() => navigate(-1)}
                          className='primary-btn close-btn'
                        >
                          <i className='icon-close' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={() => navigate(-1)}
                          className='btn-icon btn-icon-primary'
                        >
                          <i className='icon-edit-box' /> CID Structer List
                        </button>
                      </div>
                    </div>
                  </div>
                </header>
                <article className='form-inside-create'>
                  <div className='form-panel card-primary'>
                    <div className='form-item-cont row'>
                      {/* Creative Type*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Is lpurl?</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            value={lpUrlValue}
                            defaultValue={[]}
                            options={lpurlOptions}
                            onChange={handlelpUrl}
                          />
                        </div>
                        {/* <div className='error' id='nameError'>
                                                    {formErrors?.creative_type}
                                                </div> */}
                      </div>
                      {/* Landing Url */}
                      <div className='form-item col-12'>
                        <div className='label-icon'>
                          <label>Landing Url</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            placeholder='Landing url link'
                            value={landingUrlInputValue}
                            readOnly={disableUrlInput}
                            onChange={handleLandingUrl}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.landing_url}
                        </div>
                      </div>
                    </div>
                    <div className='form-btn-cont'>
                      <button
                        type='submit'
                        id='submit'
                        onClick={submitHandler}
                        className='primary-btn'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={showPupup}
        onHide={() => {
          setShowPupup(false);
          handleNavigate();
        }}
        backdrop='static'
        keyboard={false}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-primary'>
              <i className='icon-media-plan' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Thank you!</h3>
            <p>Your CID has been successfully created.</p>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => {
              setShowPupup(false);
              handleNavigate();
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateTaxonomyAdCID;
