import React from 'react';

function Button(props) {
  return (
    <>
      <button
        type='button'
        className='btn-icon btn-icon-primary btn-bedcrumb-act'
      >
        <i className='icon-delete'></i> {props.children}
      </button>
    </>
  );
}

export default Button;
