import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/common/Sidebar';
import Loader from '../../components/common/Loader';
import ReactSelect from '../../components/common/ReactSelect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { getApi, postApi } from '../../utils/Apis';
import {
  setTaxonomyADAdId,
  setTaxonomyAdUspFocus,
  setTaxonomyAdCreativeType,
  setTaxonomyAdConceptName,
  setTaxonomyAdContentType,
  setTaxonomyAdCallToAction,
  setTaxonomyAdCreativeSize,
  setTaxonomyAdAvDuration,
  setTaxonomyAdLanguage,
  setTaxonomyAdUniqueId,
  settaxonomyAdCampaignId,
  setTaxonomyAdResetForm,
} from '../../state/TaxonomyCreateAd/TaxonomyCreateAdAction';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from 'react-toastify';

import { baseUrlStg } from '../../utils/constants';
import { getCookies } from '../../utils/utils';

const CreateTaxonomyAds = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [campaignData, setCampaignData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [creativeTypeData, setCreativeTypeData] = useState([]);
  const [contentTypeData, setContentTypeData] = useState([]);
  const [callToActionData, setCallToActionData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [creativeSizeData, setCreativeSizeData] = useState([
    {
      label: 'None',
      value: 0,
    },
  ]);
  const [avDurationData, setAVDurationData] = useState([
    {
      label: 'None',
      value: 0,
    },
  ]);
  const [showPupup, setShowPupup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const taxonomyCreateAdData = state?.TaxonomyCreateAd;
  const LoginReducer = useSelector((state) => state?.loginReducer);
  const userId = LoginReducer?.userToken?.Userid;

  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  const campaign_id = searchParams.get('campaign_id')
    ? parseInt(searchParams.get('campaign_id'))
    : null;

  useEffect(() => {
    console.log('taxonomyCreateAdData', taxonomyCreateAdData);
    init();
  }, []);

  const init = () => {
    getMediaPlanCampaignData();
    getCreativeTypeData();
    getContentTypeData();
    getCallToActionData();
    getLanguageData();
  };

  const handleUsoFocus = (event) => {
    event.currentTarget.value = event?.currentTarget?.value?.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    const value = event?.target?.value;
    dispatch(setTaxonomyAdUspFocus(value));
  };

  const handleCreativeType = (event) => {
    const value = event;
    dispatch(setTaxonomyAdCreativeType(value));
    dispatch(setTaxonomyAdCreativeSize(null));
    dispatch(setTaxonomyAdAvDuration(null));

    if (event?.label?.toLowerCase() === 'banner') {
      getCreativeSizeData();
      setAVDurationData([
        {
          label: 'None',
          value: 0,
        },
      ]);
    } else {
      setCreativeSizeData([
        {
          label: 'None',
          value: 0,
        },
      ]);
      setAVDurationData([
        {
          label: 'None',
          value: 0,
        },
      ]);
      getAVDurationData();
    }
  };

  const handleConceptName = (event) => {
    event.currentTarget.value = event?.currentTarget?.value?.replace(
      /[^A-Za-z0-9-]/g,
      '',
    );
    const value = event?.target?.value;
    dispatch(setTaxonomyAdConceptName(value));
  };

  const handleContentType = (event) => {
    const value = event;
    dispatch(setTaxonomyAdContentType(value));
  };

  const handleCallToAction = (event) => {
    const value = event;
    dispatch(setTaxonomyAdCallToAction(value));
  };

  const handleCreativaSize = (event) => {
    const value = event ? event : null;
    dispatch(setTaxonomyAdCreativeSize(value));
  };

  const handleLanguage = (event) => {
    const value = event;
    dispatch(setTaxonomyAdLanguage(value));
  };

  const handleAvDuration = (event) => {
    const value = event;
    dispatch(setTaxonomyAdAvDuration(value));
  };

  const getMediaPlanCampaignData = async () => {
    setIsLoading(true);
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetCreateCampaign?CampaignId=${searchParams.get(
        'campaign_id',
      )}`;
      let res = await axios.get(url, {
        headers: {
          accept: 'text/plain',
          ...CustomHeader,
        },
      });
      console.log('mcampaigndata data', res.data);
      if (res && res.data.success) {
        setCampaignData(res?.data);
        dispatch(settaxonomyAdCampaignId(searchParams.get('campaign_id')));
        dispatch(
          setTaxonomyAdUniqueId(res?.data?.getKeyUniqeValueFromCamp?.uniqueId),
        );
        dispatch(setTaxonomyADAdId(res?.data?.adid));
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getCreativeTypeData = async () => {
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetCreativeType`;
      let res = await getApi(url);
      console.log('creative type', res);
      if (res.success) {
        setCreativeTypeData(res.creativeTypeList);
      } else {
        setCreativeTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAVDurationData = async () => {
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetAvDurtion`;
      let res = await getApi(url);
      console.log('avDuration', res);
      if (res?.success) {
        setAVDurationData((prev) => [...prev, ...res.avDurtionList]);
      } else {
        setAVDurationData([
          {
            label: 'None',
            value: 0,
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContentTypeData = async () => {
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetContentType`;
      let res = await getApi(url);
      console.log('content type', res);
      if (res?.success) {
        setContentTypeData(res.contentTypeList);
      } else {
        setContentTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCallToActionData = async () => {
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetCallActionValues`;
      let res = await getApi(url);
      console.log('call to action', res);
      if (res?.success) {
        setCallToActionData(res.callActionValues);
      } else {
        setCallToActionData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCreativeSizeData = async () => {
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetCreativeSize`;
      let res = await getApi(url);
      console.log('getCreative size ', res);
      if (res?.creativeSizeList) {
        setCreativeSizeData(res?.creativeSizeList);
      } else {
        setCreativeSizeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLanguageData = async () => {
    try {
      const url = `${baseUrlStg}/api/TaxonomyAdCreate/GetLanguageTypes`;
      let res = await getApi(url);
      console.log('language', res);
      if (res?.success) {
        setLanguageData(res.languageTypesList);
      } else {
        setLanguageData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = () => {
    setFormErrors(validate(taxonomyCreateAdData));
    console.log('outside');

    setTimeout(async () => {
      if (Object.keys(validate(taxonomyCreateAdData)).length === 0) {
        console.log('inside', taxonomyCreateAdData);
        setIsLoading(true);
        try {
          const postData = {
            taxonomy_media_campaign_id: parseInt(
              taxonomyCreateAdData?.campaign_id,
            ),
            ad_id: taxonomyCreateAdData?.ad_id,
            usp_focus: taxonomyCreateAdData?.usp_focus,
            ad_creative_type_id: taxonomyCreateAdData?.creative_type?.value,
            concept_name: taxonomyCreateAdData?.concept_name,
            content_type_id: taxonomyCreateAdData?.content_type?.value,
            call_to_action_type_id: taxonomyCreateAdData?.call_to_action?.value,
            creative_size_id: taxonomyCreateAdData?.creative_size
              .map((ele) => ele.value)
              .join(','),
            av_duration_id: taxonomyCreateAdData?.av_duration?.value,
            language_id: taxonomyCreateAdData?.language?.value,
            unique_id: taxonomyCreateAdData?.unique_id,
            created_by: userId,
            last_edited_by: 0,
          };

          console.log('postData', postData);
          const URL = `${baseUrlStg}/api/TaxonomyAdCreate/CreateAd`;
          const headers = {
            'Content-Type': 'application/json-patch+json',
          };
          const res = await postApi(URL, postData, headers);
          console.log('post api responce create campaign', res);
          if (res.data.success) {
            setShowPupup(true);
            dispatch(setTaxonomyAdResetForm(null));
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    }, 100);
  };

  const validate = (formData) => {
    const error = {};
    if (formData?.usp_focus === '') {
      error.usp_focus = 'Please enter USP focus value';
    }
    if (!formData?.creative_type) {
      error.creative_type = 'Select creative type';
    }
    if (formData?.concept_name === '') {
      error.concept_name = 'Please enter concept name';
    }
    if (!formData?.content_type) {
      error.content_type = 'Please select content Type';
    }
    if (!formData?.call_to_action) {
      error.call_to_action = 'Please select call to action';
    }
    if (!formData?.creative_size || formData?.creative_size.length === 0) {
      error.creative_size = 'Select creative size';
    }
    if (!formData?.av_duration) {
      error.av_duration = 'Select A/V duration';
    }
    if (!formData?.language) {
      error.language = 'Please select Language';
    }
    return error;
  };

  const handleNavigate = () => {
    navigate('/taxonomy-manager/media-plan-list');
  };

  return (
    <>
      {isLoading && <Loader />}
      <main>
        <div className='main-container'>
          <div className='container'>
            <div className='taxonomy-panel'>
              <div>
                <Sidebar />
              </div>
              <div className='right-side-box media-section-left'>
                <header>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-left-bar'>
                      <h2>Create Ads</h2>
                    </div>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={() => navigate(-1)}
                          className='primary-btn close-btn'
                        >
                          <i className='icon-close' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='top-header-bar grey-top-bar top-header-secondary'>
                    <div className='top-right-bar'>
                      <div className='btn-box'>
                        <button
                          onClick={handleNavigate}
                          className='btn-icon btn-icon-primary'
                        >
                          <i className='icon-edit-box' /> Show All Media Plans
                        </button>
                      </div>
                    </div>
                  </div>
                </header>
                <div className='top-bedcrumb'>
                  <ul>
                    <li>
                      Division:{' '}
                      <strong>
                        {campaignData?.createAdFromCampaign?.divisionName}
                      </strong>
                    </li>
                    <li>
                      Sub Category:{' '}
                      <strong>
                        {campaignData?.createAdFromCampaign?.subCategoryName}
                      </strong>
                    </li>
                    <li>
                      Quarter:{' '}
                      <strong>
                        {campaignData?.createAdFromCampaign?.quatername}
                      </strong>
                    </li>
                    <li>
                      Channel:{' '}
                      <strong>
                        {campaignData?.createAdFromCampaign?.channel}
                      </strong>
                    </li>
                    <li>
                      Publisher:{' '}
                      <strong>
                        {campaignData?.createAdFromCampaign?.publisher}
                      </strong>
                    </li>
                  </ul>
                </div>
                <article className='form-inside-create'>
                  <div className='form-panel card-primary'>
                    <div className='form-item-cont row'>
                      {/* Ad ID */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Ad ID</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            readOnly={true}
                            disabled={true}
                            value={taxonomyCreateAdData?.ad_id}
                          />
                        </div>
                        {/* <div className="error" id="nameError">
                                                    {formErrors?.campaign_name}
                                                </div> */}
                      </div>
                      {/* USP Focus */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>USP Focus</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter USP focus'
                            onChange={handleUsoFocus}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.usp_focus}
                        </div>
                      </div>
                      {/* Creative Type*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Creative Type</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select creative type'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={creativeTypeData}
                            onChange={handleCreativeType}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.creative_type}
                        </div>
                      </div>
                      {/* Concept Name */}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Concept Name</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            id='Passwoard'
                            placeholder='Enter concept name'
                            onChange={handleConceptName}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.concept_name}
                        </div>
                      </div>
                      {/* Content Type*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Content Type</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select content type'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={contentTypeData}
                            onChange={handleContentType}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.content_type}
                        </div>
                      </div>
                      {/* Call to Action*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Call to Action</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select call to action'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={callToActionData}
                            onChange={handleCallToAction}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.call_to_action}
                        </div>
                      </div>
                      {/* Creative Size*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Creative Size</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select creative size'}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={true}
                            defaultValue={[]}
                            options={creativeSizeData}
                            value={taxonomyCreateAdData?.creative_size}
                            onChange={handleCreativaSize}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.creative_size}
                        </div>
                      </div>
                      {/* A/V Duration*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>A/V Duration</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select A/V duration'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            value={taxonomyCreateAdData?.av_duration}
                            options={avDurationData}
                            onChange={handleAvDuration}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.av_duration}
                        </div>
                      </div>
                      {/*language*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Language</label>
                        </div>
                        <div className='select-box small-select-multiple-components'>
                          <ReactSelect
                            placeholder={'Select language'}
                            isClearable={false}
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            defaultValue={[]}
                            options={languageData}
                            onChange={handleLanguage}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {formErrors?.language}
                        </div>
                      </div>
                      {/* Unique Id*/}
                      <div className='form-item col-4'>
                        <div className='label-icon'>
                          <label>Unique ID</label>
                        </div>
                        <div className='input-group'>
                          <input
                            type='text'
                            readOnly={true}
                            value={taxonomyCreateAdData?.unique_id}
                            disabled={true}
                          />
                        </div>
                        <div className='error' id='nameError'>
                          {/* {formErrors?.campaign_name} */}
                        </div>
                      </div>
                    </div>
                    <div className='form-btn-cont'>
                      <button
                        type='submit'
                        id='submit'
                        onClick={submitHandler}
                        className='primary-btn'
                      >
                        Create Ad
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={showPupup}
        onHide={() => {
          setShowPupup(false);
          navigate(
            `/taxonomy-manager/campaign-ads-list?campaign_id=${campaign_id}`,
          );
        }}
        backdrop='static'
        keyboard={false}
        className='atd-modal approved-model'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='modal-img-cont'>
            <figure className='modal-icon modal-primary'>
              <i className='icon-media-plan' />
            </figure>
          </div>
          <div className='modal-text'>
            <h3>Thank you!</h3>
            <p>Your campaign ads has been successfully created.</p>
          </div>
        </Modal.Body>

        <div className='modal-footer'>
          <button
            type='button'
            className='primary-btn'
            onClick={() => {
              setShowPupup(false);
              navigate(
                `/taxonomy-manager/campaign-ads-list?campaign_id=${campaign_id}`,
              );
            }}
          >
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateTaxonomyAds;
