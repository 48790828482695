import React, { useState, useEffect, useId, useCallback } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Loader from '../../../components/common/Loader';
import ReactTable from '../../../components/common/ReactTable';
import { baseUrlStg } from '../../../utils/constants';
import { getApi, postApi } from '../../../utils/Apis';
import ReactPaginate from 'react-paginate';
import _debounce from 'lodash/debounce';
import { ATDStatus, GoogleAdOptions, GoogleStatus } from '../GoogleAdConstant';
import HeaderTopBar from '../../DV360/Component/HeaderTopBar';
import DeleteModal from './Component/DeleteModal';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { useMemo } from 'react';
import GoogleHeader from './Component/GoogleHeader';
import SimpleSelectBox from '../../../components/common/SimpleSelectBox';
import CommonEnableDisableModal from '../../../components/common/CommonEnableDisableModal';
import CommonSuccessModal from '../../../components/common/CommonSuccessModal';
import ReactSelect from '../../../components/common/ReactSelect';
import { CommonExport } from '../../../components/Export/CommonExport';
import BulkUploadCommonModal from '../../../components/common/BulkUploadCommonModal';
import { useSelector } from 'react-redux';

const GoogleCampaignListing = () => {
  const [currentTab, setCurrentTab] = useState(GoogleAdOptions.Campaigns.name);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    fileType: [],
    dimensions: [],
    dates: {
      startDate: '',
      endDate: '',
    },
    creativeName: '',
    googleStatus: [],
    atdStatus: [],
  });

  const [creativeNameFilter, setCreativeNameFilter] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterApply, setFilterApply] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [campaignName, setCampaignName] = useState('');
  // for delete
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const deleteUrl = GoogleAdOptions.Campaigns.deleteAllUrl;

  // for enable disable
  const [getEnabledSelectedType, setEnabledSelectedType] = useState('');
  const [getShowEnabledDisabledModal, setShowEnabledDisabledModal] =
    useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [getRequstedType, setRequestedType] = useState('');
  const { handleAPICall } = CommonExport();
  const [getBulkUploadModelStatus, setBulkUploadModelStatus] = useState(false);
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;

  const handleEnabledDisabledVal = (e) => {
    setEnabledSelectedType(e.target.value);
    if (e.target.value != '') {
      setShowEnabledDisabledModal(true);
    }
  };
  const handleAction = async (type) => {
    try {
      setShowEnabledDisabledModal(false);
      setRequestedType(type);
      setIsLoading(true);
      const getSelectedIds = selectedRows
        .filter((row) => row.googleId !== null)
        .map((row) => {
          return row.googleCampaignID;
        });
      console.log('getSelectedIds', getSelectedIds);
      const endPoint = `${baseUrlStg}/api/GoogleAdListing/Campaign-Enable-Disable?status=${
        type === 'Enable' ? 1 : 0
      }&created_by=${getUserInfo?.Userid}`;
      const response = await postApi(endPoint, getSelectedIds).catch(
        (error) => {
          console.error('Failed to Enable & Disable :', error);
          throw error;
        },
      );
      if (response) {
        setShowSuccessModal(true);
        fetchData();
        setIsLoading(false);
        setEnabledSelectedType('');
      }
    } catch (err) {
      console.error('Failed to Enable & Disable :', err);
    }
  };

  const navigate = useNavigate();

  const initialState = {
    campaign_name: '',
    from_date: '',
    to_date: '',
    pageNumber: currentPage,
    pageSize: itemsPerPage,
  };

  const [request, setRequest] = useState(initialState);

  const fetchData = async () => {
    let url = `${baseUrlStg}${GoogleAdOptions.Campaigns.fetcherUrl}page=${
      request.pageNumber
    }&limit=${Number(itemsPerPage)} `;
    if (campaignName) {
      url += `&search=${campaignName}`;
    }
    if (filters.dates.startDate && filters.dates.endDate) {
      url += `&startdate=${filters.dates.startDate}&enddate=${filters.dates.endDate}`;
    }
    if (filters.googleStatus && filters.googleStatus.length > 0) {
      filters.googleStatus.forEach((status) => {
        url += `&googleStatus=${status}`;
      });
    }
    if (filters?.atdStatus && filters?.atdStatus?.length > 0) {
      filters?.atdStatus?.forEach((status) => {
        url += `&atdStatus=${status}`;
      });
    }
    console.log('campaign url', url);
    try {
      setIsLoading(true);
      const response = await getApi(url, {
        campaign_name: campaignName,
        from_date: filters.dates.startDate,
        to_date: filters.dates.endDate,
        pageNumber: request.pageNumber,
        pageSize: Number(itemsPerPage),
      });

      setData(response?.campaignlist || []);
      setTotalRecords(response?.dataTotalCount);
      setTotalPages(Math.ceil(response?.dataTotalCount / Number(itemsPerPage)));
      if (response) {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [itemsPerPage, currentPage, filters]);

  const handleMenuDropdown = () => {
    navigate('/google-ad/create-campaign/new', {
      state: { isCreateFlow: true },
    });
  };

  const setDates = (e, { startDate, endDate }) => {
    setCurrentPage(1);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        },
      };
    });
    setRequest((preValue) => {
      return {
        ...preValue,
        from_date: startDate.format('YYYY-MM-DD'),
        to_date: endDate.format('YYYY-MM-DD'),
      };
    });
    checkFilterApplied();
  };

  const onCancelDate = (e, { startDate, endDate }) => {
    setCurrentPage(1);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        dates: {
          startDate: '',
          endDate: '',
        },
      };
    });
    setRequest((preValue) => {
      return {
        ...preValue,
        from_date: '',
        to_date: '',
      };
    });
    checkFilterApplied();
  };

  const setTab = (tab) => {
    ClearFilters(GoogleAdOptions.Campaigns.name);
    setCurrentPage(1);
    setCurrentTab(tab);
  };

  //clear filters
  const ClearFilters = (type) => {
    switch (type) {
      case GoogleAdOptions.Campaigns.name:
        setCurrentPage(1);
        setFilters({
          fileType: [],
          dimensions: [],
          dates: {
            startDate: '',
            endDate: '',
          },
          creativeName: '',
        });
        setCreativeNameFilter(false);
        setFilterApply(false);
        break;

      case 'dates':
        setCurrentPage(1);
        setFilters((prevFilters) => {
          return {
            ...prevFilters,
            dates: {
              startDate: '',
              endDate: '',
            },
          };
        });
        checkFilterApplied();
        break;

      default:
        break;
    }
  };

  const checkFilterApplied = () => {
    if (filters.dates.startDate === '' && filters.dates.endDate === '') {
      setFilterApply(false);
    } else {
      setFilterApply(true);
    }
  };

  const setActiveTab = (tab) => {
    setTab(tab);
    if (tab === GoogleAdOptions.Campaigns.name) {
      navigate(GoogleAdOptions.Campaigns.url);
    } else if (tab === GoogleAdOptions.AdGroups.name) {
      navigate(GoogleAdOptions.AdGroups.url);
    } else {
      navigate(GoogleAdOptions.Ads.url);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Campaign name',
        accessor: 'campaignName',
        Cell: ({ row }) => (
          <Link
            to={`${GoogleAdOptions.Campaigns.viewPageUrl}/${row.original.googleCampaignID}`}
            onClick={() => handleEdit(row.original.googleCampaignID)}
          >
            {row.original.campaignName}
          </Link>
        ),
      },
      {
        Header: 'ATD Status',
        accessor: 'atdStatus',
        Cell: ({ row }) =>
          row.original.atdStatus ? (
            <span>{capitalizeFirstLetter(row.original.atdStatus)}</span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'Google Status',
        accessor: 'googleStatus',
        Cell: ({ row }) =>
          row.original.googleStatus ? (
            <span>{capitalizeFirstLetter(row.original.googleStatus)}</span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'Google ID',
        accessor: 'googleId',
        Cell: ({ row }) =>
          row.original.googleId ? <span>{row.original.googleId}</span> : '-',
      },
      {
        Header: 'Campaign objective',
        accessor: 'campaignObjective',
      },
      {
        Header: 'Campaign type',
        accessor: 'campaignType',
        Cell: ({ row }) =>
          row.original.campaignType ? (
            <span>{row.original.campaignType}</span>
          ) : (
            '-'
          ),
      },
      {
        Header: 'Budget',
        accessor: 'budget',
        Cell: ({ row }) => <span>&#x20B9; {row.original.budget}</span>,
      },
      {
        Header: 'Bidding type',
        accessor: 'biddingType',
        Cell: ({ row }) => <span>{row.original.biddingType}</span>,
      },
      {
        Header: 'Start date',
        accessor: 'startDate',
        Cell: ({ row }) => (
          <span>
            {row.original.startDate
              ? moment(row.original.startDate)
                  .format('DD-MMM-YYYY')
                  .toUpperCase()
              : '-'}
          </span>
        ),
      },
      {
        Header: 'End date',
        accessor: 'endDate',
        Cell: ({ row }) => (
          <span>
            {row.original.endDate
              ? moment(row.original.endDate).format('DD-MMM-YYYY').toUpperCase()
              : '-'}
          </span>
        ),
      },
      {
        Header: 'Created date',
        accessor: 'createdDate',
        Cell: ({ row }) => (
          <span>
            {moment(row.original.createdDate)
              .format('DD-MMM-YYYY')
              .toUpperCase() || '-'}
          </span>
        ),
      },
      {
        Header: 'Edit',
        accessor: 'edit.id',
        Cell: ({ row }) =>
          row?.original?.atdStatus == 'In Moderation' ? (
            <Link
              class='disable-button'
              title='Entry under moderation: Editing disabled until review completion.'
            >
              Edit
            </Link>
          ) : (
            <Link
              to={`${GoogleAdOptions.Campaigns.editUrl}/${row.original.googleCampaignID}`}
              // onClick={() => handleEdit(row.original.campaign_id)}
            >
              Edit
            </Link>
          ),
      },
      {
        Header: 'Delete',
        accessor: 'delete.id',
        Cell: ({ row }) => (
          <Link
            href=''
            onClick={() => handleDelete(row.original.googleCampaignID)}
          >
            Delete
          </Link>
        ),
      },
      // Add more columns as needed
    ],
    [],
  );

  const handleEdit = (id) => {
    navigate(`/dv-360/create-campaign/${id}`);
  };

  const handleDelete = (id) => {
    setShow(true);
    setDeleteId([id]);
  };

  const clearSearch = (e) => {
    setFilters((preValue) => ({
      ...preValue,
      creativeName: '',
    }));
    setCampaignName('');
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    setRequest((prevValue) => ({
      ...prevValue,
      pageNumber: selectedPage.selected + 1,
    }));
  };

  const handleChange = (e) => {
    setCampaignName(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const handleSearch = () => {
    fetchData(campaignName);
  };

  const handleClose = () => setShow(false);

  const doneWithDelete = () => {
    fetchData();
  };

  // for Delete
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = useCallback((selectedRowsIds) => {
    setSelectedRows(selectedRowsIds);
  }, []);

  const handleMultipleDelete = () => {
    const deleteId = selectedRows.map((row) => row.googleCampaignID);
    setShow(true);
    setDeleteId(deleteId);
  };
  // for status
  const [selectedGoogleValues, setSelectedGoogleValues] = useState([]);
  const [selectedATDValues, setSelectedATDValues] = useState([]);

  const handleGoogleStatusChange = (selectedOptions) => {
    setSelectedGoogleValues(selectedOptions);
    handleStatusChange(selectedOptions, 'googleStatus');
  };

  const handleATDStatusChange = (selectedOptionATD) => {
    setSelectedATDValues(selectedOptionATD);
    handleStatusChange(selectedOptionATD, 'atdStatus');
  };

  const handleStatusChange = (selectedOptions, filterKey) => {
    console.log('handleStatusChange', selectedOptions, filterKey);
    const selectedValuesOnly = selectedOptions?.map((option) => option.value);
    console.log(
      `Selected Values For ${filterKey?.toUpperCase()} STATUS:`,
      selectedValuesOnly,
    );

    setFilters((prevState) => ({
      ...prevState,
      [filterKey]: selectedOptions ? selectedValuesOnly : '',
    }));
  };
  const urlParams = new URLSearchParams(window.location.search);
  const googleParam = urlParams.get('google');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (googleParam === 'clicked') {
        setSelectedATDValues([ATDStatus[1]]);
        setFilters((prevState) => ({
          ...prevState,
          atdStatus: [ATDStatus[1].label],
        }));
      } else {
        // handleStatusChange();
        // setSelectedATDValues();
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [googleParam]);

  const handleDuplicate = async () => {
    setIsLoading(true);
    const ids = selectedRows.map((row) => row.googleCampaignID);

    const response = await postApi(
      `${baseUrlStg}/api/GoogleDuplicateCampaign/CreateDuplicateCampaign?campaignId=${ids}`,
    );

    if (response?.data) {
      setIsLoading(false);
      navigate(
        `${GoogleAdOptions.Campaigns.editUrl}/${response?.data?.camapignID}`,
      );
    } else {
      setIsLoading(false);
    }
  };

  const handleExport = () => {
    const ids = selectedRows.map((row) => row.googleCampaignID);
    handleAPICall(0, ids, 'google', 'Campaign');
  };

  return (
    <div>
      {isLoading && <Loader />}
      {data ? (
        <>
          <main>
            <div className='main-container facebook-step-one'>
              <div className='container'>
                <div className='fb-panel'>
                  <Sidebar />
                  <div className='right-side-box padding-bottom-extra media-section-left'>
                    <GoogleHeader />
                    <div className='search-bar-cont'>
                      <div className='search'>
                        <div className='search-close'>
                          <input
                            type='text'
                            id='searchtextid'
                            placeholder='Search'
                            value={campaignName}
                            onChange={handleChange}
                            onKeyDown={handleKeyPress}
                          />
                          <button
                            className='close-btn'
                            id='ClearMediaSearchID'
                            onClick={clearSearch}
                          >
                            <i className='icon-close'></i>
                          </button>
                        </div>
                        <button
                          className='btn-icon btn-icon-primary'
                          id='searchstringid'
                          onClick={handleSearch}
                        >
                          <i className='icon-search'></i>
                        </button>
                        <div className='date-range-select'>
                          <>
                            <DateRangePicker
                              onApply={setDates}
                              onCancel={onCancelDate}
                              isClearable={true}
                            >
                              <input
                                type='text'
                                className='daterange'
                                name='daterangefilter'
                                id='startenddateid'
                              />
                            </DateRangePicker>
                          </>
                        </div>
                        {/* <div className='date-range-select'>
                          <input
                            type='text'
                            className='daterange'
                            name='daterangefilter'
                            id='startenddateid'
                            placeholder='select date'
                          />
                        </div> */}
                      </div>
                    </div>

                    {/* <Header /> */}
                    <section className='side-menu-form d-flex'>
                      <article className='side-right-form-wrap'>
                        <HeaderTopBar
                          setActiveTab={setActiveTab}
                          currentTab={currentTab}
                          setCurrentTab={setCurrentTab}
                          options={GoogleAdOptions}
                        />

                        <div className='card-primary'>
                          <div className='media-fillter-btns'>
                            <div className='fillter-btns d-flex justify-content-between'>
                              <div className='fillter-left d-flex align-items-center'>
                                <div className='dropdown-select DpdrpDownSlct'>
                                  <button
                                    onClick={handleMenuDropdown}
                                    className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                  >
                                    <i className='icon-plus'></i> Create
                                  </button>
                                  <button
                                    type='button'
                                    className='btn-icon btn-icon-primary no-text'
                                    id='facebookdltid'
                                    onClick={() => handleMultipleDelete()}
                                    disabled={selectedRows.length === 0}
                                  >
                                    <i className='icon-delete'></i>
                                  </button>
                                  <button
                                    disabled={selectedRows.length !== 1}
                                    type='button'
                                    className={`btn-icon btn-icon-primary ${
                                      selectedRows.length !== 0
                                        ? 'btn-bedcrumb-act:hover'
                                        : 'btn-bedcrumb-act'
                                    }`}
                                    id='facebookdltid'
                                    onClick={() => handleDuplicate()}
                                  >
                                    <i class='icon-copy-plus'></i> Duplicate
                                  </button>
                                  <button
                                    type='button'
                                    class='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    id='facebookdltid'
                                    onClick={() => handleExport()}
                                    disabled={selectedRows.length === 0}
                                  >
                                    <i class='icon-export'></i> Export
                                  </button>
                                  <button
                                    type='button'
                                    class='btn-icon btn-icon-primary btn-bedcrumb-act'
                                    id='facebookdltid'
                                    onClick={() =>
                                      setBulkUploadModelStatus(true)
                                    }
                                  >
                                    <i class='icon-import'></i> Import
                                  </button>
                                </div>
                                <div class='select-box'>
                                  <SimpleSelectBox
                                    name='quality'
                                    placeholder='Choose control'
                                    options={['Disable', 'Enable']}
                                    value={getEnabledSelectedType}
                                    onChangeHandler={handleEnabledDisabledVal}
                                    disabled={
                                      selectedRows?.length > 0 ? false : true
                                    }
                                  ></SimpleSelectBox>
                                </div>
                              </div>
                              <div className='fillter-right d-flex align-items-center'>
                                <div className='me-2'>
                                  <ReactSelect
                                    className='select-box'
                                    isSearchable={false}
                                    placeholder='ATD Status'
                                    isMulti={true}
                                    options={ATDStatus}
                                    onChange={handleATDStatusChange}
                                    value={selectedATDValues}
                                  ></ReactSelect>
                                </div>
                                <ReactSelect
                                  isSearchable={false}
                                  placeholder='Google Status'
                                  isMulti={true}
                                  options={GoogleStatus}
                                  onChange={handleGoogleStatusChange}
                                  value={selectedGoogleValues}
                                />
                              </div>
                            </div>
                            <div className='fillter-options'>
                              {filters.dates.startDate &&
                                filters.dates.endDate && (
                                  <button
                                    className='clear-btn'
                                    onClick={() => [
                                      ClearFilters(GoogleAdOptions.Campaigns),
                                      setRequest(initialState),
                                    ]}
                                  >
                                    Clear All
                                  </button>
                                )}

                              {filters.dates.startDate &&
                                filters.dates.endDate && (
                                  <span>
                                    Date Range:{' '}
                                    {
                                      <>
                                        <b>
                                          {moment(
                                            filters.dates.startDate,
                                          ).format('DD/MM/YYYY')}
                                          -
                                          {moment(filters.dates.endDate).format(
                                            'DD/MM/YYYY',
                                          )}
                                        </b>
                                        <button
                                          type='button'
                                          onClick={() => ClearFilters('dates')}
                                        >
                                          <i className='icon-close' />
                                        </button>
                                      </>
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                          <div
                            className='dataTables_length'
                            id='Facebookcampaigntable_length'
                          >
                            <label>
                              Show{' '}
                              <select
                                name='Facebookcampaigntable_length'
                                aria-controls='Facebookcampaigntable'
                                value={itemsPerPage}
                                onChange={(e) => [
                                  setItemsPerPage(Number(e.target.value)),
                                  // setItemsPerPage(0),
                                ]}
                              >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                              </select>{' '}
                              entries
                            </label>
                          </div>

                          <ReactTable
                            columns={columns}
                            data={data}
                            totalCount={totalRecords}
                            onSelectionChange={handleSelectionChange}
                          />
                          {data && (
                            <div className='media-entries dataTables_paginate'>
                              <div className='show-entries'>
                                {data.length > 0 && (
                                  <p>
                                    Showing{' '}
                                    {currentPage === 1
                                      ? 1
                                      : (currentPage - 1) * itemsPerPage +
                                        1}{' '}
                                    to{' '}
                                    {Number(currentPage - 1) *
                                      Number(itemsPerPage) +
                                      data.length}{' '}
                                    of {totalRecords} entries
                                  </p>
                                )}
                              </div>
                              <div className='media-pagination'>
                                {totalRecords > itemsPerPage && (
                                  <div className='media-pagination dataTables_paginate'>
                                    <ReactPaginate
                                      breakLabel={'...'}
                                      marginPagesDisplayed={1}
                                      previousLabel={<button>Previous</button>}
                                      nextLabel={<button>Next</button>}
                                      pageCount={totalPages}
                                      onPageChange={handlePageChange}
                                      forcePage={currentPage - 1}
                                      renderOnZeroPageCount={null}
                                      pageRangeDisplayed={5}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      ) : (
        <Sidebar />
      )}

      <DeleteModal
        handleClose={handleClose}
        show={show}
        key={useId()}
        deleteId={deleteId}
        doneWithDelete={doneWithDelete}
        deleteUrl={deleteUrl}
        from={GoogleAdOptions.Campaigns.name}
      ></DeleteModal>
      <CommonEnableDisableModal
        show={getShowEnabledDisabledModal}
        handleClose={() => setShowEnabledDisabledModal(false)}
        copy={
          getEnabledSelectedType == 'Enable'
            ? 'You want to Enable this Google Campaign ?'
            : 'You want to Disable this Google Campaign ?'
        }
        type={getEnabledSelectedType}
        handleAction={handleAction}
      />
      <CommonSuccessModal
        show={showSuccessModal}
        copy={`Campaign has been successfully ${getRequstedType}.`}
        handleClose={() => setShowSuccessModal(false)}
      />
      {getBulkUploadModelStatus && (
        <BulkUploadCommonModal
          copy={'Upload Google Campaigns'}
          setBulkUploadModelStatus={() => setBulkUploadModelStatus(false)}
          pageType={'Google'}
        />
      )}
    </div>
  );
};

export default GoogleCampaignListing;
