const CompanionBanner = (props) => {
  return (
    <>
      <div className='card-form form-panel'>
        <div className='row align-items-center'>
          <div className='action-btn-cont pt-3 pb-3'>
            <div className='action-btn-both row'>
              <div className='form-check-box col-12'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='autogenerate_using_channel_banner'
                  value='Autogenerate using your channel banner (recommended)'
                  id='flexRadioDefault1'
                  onChange={props?.handleSetCompanionBanner}
                  checked={
                    props?.selectedValue != 'upload image' ? true : false
                  }
                />
                <label className='form-check-label' htmlFor='flexRadioDefault1'>
                  Autogenerate using your channel banner (recommended)
                </label>
              </div>
              <div className='form-check-box col-12'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='upload_image'
                  value='upload image'
                  id='flexRadioDefault2'
                  onChange={props?.handleSetCompanionBanner}
                  checked={
                    props?.selectedValue == 'upload image' ? true : false
                  }
                />
                <label className='form-check-label' htmlFor='flexRadioDefault2'>
                  Upload an image
                </label>
              </div>
            </div>
            {props?.selectedValue == 'upload image' && (
              <div className='media-btn'>
                <div className='file-error'>
                  <div
                    className='btn-icon btn-file-icon'
                    onClick={props?.openMediaWidgetVideoUploadImage}
                  >
                    <i className='icon-plus-circle' />
                    <label htmlFor='imgFileIDVib'>Choose Image</label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanionBanner;
