// import moment from "moment/moment";
import moment from 'moment-timezone';
import {
  DV_CAMPAIGN_EXIST,
  DV_CAMPAIGN_NAME,
  DV_CREATIVE_TYPE,
  DV_Deals_And_Inventory_Packages,
  DV_FREQUENCY_CAP_TYPE,
  DV_FREQUENCY_LIMIT,
  DV_FREQUENCY_LIMIT_TYPE,
  DV_FREQUENCY_LIMIT_VALUE,
  DV_Groups,
  DV_KPI,
  DV_KPI_GOAL,
  DV_OVERALL_CAMPAIGN_GOAL,
  DV_PLANNED_END_DATE,
  DV_PLANNED_SPEND,
  DV_PLANNED_START_DATE,
  DV_Public_Inventory,
  DV_Quality,
  DV_STORE_CAMPAIGN_BY_ID,
  RESET_CAMPAIGN_STATE,
} from './CreateCampaignActionTypes';
import { dateFormat } from '../../utils/validations';

Array.prototype.remove = function (value) {
  this.splice(this.indexOf(value), 1);
};

const initialState = {
  campaign_exist: false,
  campaign_id: 0,
  // "google_campaign_id": "123testcampaignid_updated",
  campaign_name: '',
  campaign_goal: 'Raise awareness of my brand or product',
  kpi_type: '',
  kpi_value: null,
  creative_type: 'Display',
  planned_spend: '',
  planned_startdate: null,
  planned_enddate: null,
  frequency_cap_type: 'no limit',
  frequency_limit: null,
  frequency_limit_type: '',
  frequency_cap_limit_value: null,
  invsource_quality: '',
  invsource_publicinventoryid: '',
  invsource_dealspackagesid: '',
  invsource_groupid: '',
  targetingsource_id: 1,
  moderation_status: 'in_moderation',
  rejection_reason: '',
  created_by: 4,
  status: 1,
  created_at: moment().toDate(),
};

export const DvCreateCampaign = (state = initialState, { type, payload }) => {
  switch (type) {
    case DV_OVERALL_CAMPAIGN_GOAL: {
      const { value } = payload?.target;

      return { ...state, campaign_goal: value };
    }

    case DV_CAMPAIGN_NAME: {
      const { value } = payload?.target;

      return { ...state, campaign_name: value };
    }
    case DV_KPI: {
      const { value } = payload?.target;

      return { ...state, kpi_type: value, kpi_value: '' };
    }
    case DV_KPI_GOAL: {
      const { value } = payload?.target;

      return { ...state, kpi_value: value };
    }
    case DV_CREATIVE_TYPE: {
      let value = payload?.target?.value;
      let dataArr = state?.creative_type?.split(',');
      if (payload?.target?.checked) {
        value = payload?.target?.value;
        let index = dataArr.findIndex((item) => item == value);
        if (index == -1) dataArr.push(value);
      } else {
        dataArr.remove(value);
      }

      return { ...state, creative_type: dataArr.join(',') };
    }
    case DV_PLANNED_SPEND: {
      return { ...state, planned_spend: payload };
    }
    case DV_PLANNED_START_DATE: {
      if (payload) {
        payload = dateFormat(payload);
        return { ...state, planned_startdate: payload };
      } else {
        return { ...state, planned_startdate: payload };
      }
    }
    case DV_PLANNED_END_DATE: {
      if (payload) {
        payload = dateFormat(payload);
        return { ...state, planned_enddate: payload };
      } else {
        return { ...state, planned_enddate: payload };
      }
    }
    case DV_FREQUENCY_CAP_TYPE: {
      let value = payload?.target?.value;
      value = value.toLowerCase();
      if (value == 'no limit')
        return {
          ...state,
          frequency_cap_type: value,
          frequency_limit: '',
          frequency_limit_type: '',
          frequency_cap_limit_value: '',
        };
      return { ...state, frequency_cap_type: value };
    }
    case DV_FREQUENCY_LIMIT: {
      const { value } = payload?.target;
      return { ...state, frequency_cap_limit_value: value };
    }
    case DV_FREQUENCY_LIMIT_TYPE: {
      const { value } = payload?.target;
      return { ...state, frequency_limit_type: value };
    }
    case DV_FREQUENCY_LIMIT_VALUE: {
      const { value } = payload?.target;
      return { ...state, frequency_limit: value };
    }
    case DV_Quality: {
      let data = payload;
      return {
        ...state,
        invsource_quality: data,
      };
    }
    case DV_Public_Inventory: {
      let data = payload;
      return {
        ...state,
        invsource_publicinventoryid: data,
      };
    }
    case DV_Deals_And_Inventory_Packages: {
      let data = payload;
      return {
        ...state,
        invsource_dealspackagesid: data,
      };
    }
    case DV_Groups: {
      let data = payload;
      return {
        ...state,
        invsource_groupid: data,
      };
    }
    case DV_CAMPAIGN_EXIST: {
      return {
        ...state,
        campaign_exist: payload,
      };
    }
    case DV_STORE_CAMPAIGN_BY_ID: {
      let data = payload;
      return {
        ...state,
        // campaignData: data,

        campaign_id: data?.campaign_id,
        // "google_campaign_id": "123testcampaignid_updated",
        campaign_name: data?.campaign_name,
        campaign_goal: data?.campaign_goal,
        kpi_type: data?.kpi_type,
        kpi_value: data?.kpi_value,
        creative_type: data?.creative_type,
        planned_spend: data?.planned_spend,
        planned_startdate: data?.planned_startdate,
        planned_enddate: data?.planned_enddate,
        frequency_cap_type: data?.frequency_cap_type,
        frequency_limit: data?.frequency_limit,
        frequency_limit_type: data?.frequency_limit_type,
        frequency_cap_limit_value: data?.frequency_cap_limit_value,
        invsource_quality: data?.invsource_quality,
        invsource_publicinventoryid: data?.invsource_publicinventoryid,
        invsource_dealspackagesid: data?.invsource_dealspackagesid,
        invsource_groupid: data?.invsource_groupid,
        targetingsource_id: data?.targetingsource_id,
        moderation_status: data?.moderation_status,
        rejection_reason: data?.rejection_reason,
        created_by: data?.created_by,
        status: 1,
        created_at: data?.created_at,
      };
    }
    case RESET_CAMPAIGN_STATE: {
      return initialState; // Reset the state to its initial values
    }

    default:
      return state;
  }
};
