import {
  Taxonomy_AD_CAMPAIGN_ID,
  TAXONOMY_AD_AD_ID,
  TAXONOMY_AD_USP_FOCUS,
  TAXONOMY_AD_CREATIVE_TYPE,
  TAXONOMY_AD_CONCEPT_NAME,
  TAXONOMY_AD_UNIQUE_ID,
  TAXONOMY_AD_CONTENT_TYPE,
  TAXONOMY_AD_CREATIVE_SIZE,
  TAXONOMY_AD_CALL_TO_ACTION,
  TAXONOMY_AD_AV_DURATION,
  TAXONOMY_AD_LANGUAGE,
  TAXONOMY_AD_RESET_FORM,
} from './TaxonomyCreateAdActionTypes';

const settaxonomyAdCampaignId = (payload) => {
  return {
    type: Taxonomy_AD_CAMPAIGN_ID,
    payload: payload,
  };
};

const setTaxonomyADAdId = (payload) => {
  return {
    type: TAXONOMY_AD_AD_ID,
    payload: payload,
  };
};

const setTaxonomyAdUspFocus = (payload) => {
  return {
    type: TAXONOMY_AD_USP_FOCUS,
    payload: payload,
  };
};

const setTaxonomyAdCreativeType = (payload) => {
  return {
    type: TAXONOMY_AD_CREATIVE_TYPE,
    payload: payload,
  };
};

const setTaxonomyAdConceptName = (payload) => {
  return {
    type: TAXONOMY_AD_CONCEPT_NAME,
    payload: payload,
  };
};

const setTaxonomyAdContentType = (payload) => {
  return {
    type: TAXONOMY_AD_CONTENT_TYPE,
    payload: payload,
  };
};

const setTaxonomyAdCallToAction = (payload) => {
  return {
    type: TAXONOMY_AD_CALL_TO_ACTION,
    payload: payload,
  };
};

const setTaxonomyAdCreativeSize = (payload) => {
  return {
    type: TAXONOMY_AD_CREATIVE_SIZE,
    payload: payload,
  };
};

const setTaxonomyAdAvDuration = (payload) => {
  return {
    type: TAXONOMY_AD_AV_DURATION,
    payload: payload,
  };
};

const setTaxonomyAdLanguage = (payload) => {
  return {
    type: TAXONOMY_AD_LANGUAGE,
    payload: payload,
  };
};

const setTaxonomyAdUniqueId = (payload) => {
  return {
    type: TAXONOMY_AD_UNIQUE_ID,
    payload: payload,
  };
};

const setTaxonomyAdResetForm = (payload) => {
  return {
    type: TAXONOMY_AD_RESET_FORM,
    payload: payload,
  };
};

export {
  setTaxonomyADAdId,
  setTaxonomyAdUspFocus,
  setTaxonomyAdCreativeType,
  setTaxonomyAdConceptName,
  setTaxonomyAdContentType,
  setTaxonomyAdCallToAction,
  setTaxonomyAdCreativeSize,
  setTaxonomyAdAvDuration,
  setTaxonomyAdLanguage,
  setTaxonomyAdUniqueId,
  settaxonomyAdCampaignId,
  setTaxonomyAdResetForm,
};
