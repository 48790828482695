import '../../../assets/css/style.css';
import Sidebar from '../../../components/common/Sidebar';
import SideTreeMenu from '../../../components/common/SideTreeMenu';
import Footer from '../../../components/common/Footer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApi } from '../../../utils/Apis';
import { baseUrlStg } from '../../../utils/constants';
import { useEffect } from 'react';
import { DV360Options } from '../DV360Constant';
import DataDetails from './Component/DataDetails';
import { useState } from 'react';
import moment from 'moment';
import { capitalizeFirstLetter, checkForNull } from '../../../utils/utils';
import ReactTable from './Component/ReactTable';
import SideTreeMenuView from '../Component/SideTreeMenuView';
import DvErrorDisplay from '../../../components/dv360/dvErrorDisplay';
import { useSelector } from 'react-redux';
import ApproveReject from '../../../components/common/ApproveReject';
import ReviewLog from '../../googleAds/ReviewLog/ReviewLog';
import ThankYouApprovalPopup from '../../../components/common/ThankYouApprovalPopup';
import DisplayCampaignStatus from '../../../components/common/DisplayCampaignStatus';
import Loader from '../../../components/common/Loader';

const InsertionOrderView = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [insertionError, setInsertionError] = useState('');
  const state = useSelector((state) => state);
  const getUserInfo = state?.loginReducer?.userToken;
  const [getCurrentStatus, setCurrentStatus] = useState('');
  const [isViewPage, setIsViewPage] = useState(true);
  const [getCurrentInsertionCampaignId, setCurrentInsertionCampaignId] =
    useState('');
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getApprovalFlag, setApprovalFlag] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  useEffect(() => {
    if (query.get('platForm') === DV360Options.Insertion_Order.platform) {
      setIsViewPage(false);
    } else {
      setIsViewPage(true);
    }
  }, [query.get('platForm')]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `${baseUrlStg}/api/InsertionOrder/getInsertionOrderByID?insertionOrderID=${id}`,
      );
      //atdcoreapi.experiencecommerce.com/api/InsertionOrder/getInsertionOrderByID?insertionOrderID=3
      // console.log('API Response', response.response['campaign_id']);
      if (response) {
        setIsLoading(false);
        setData(response.response);
        setInsertionError(response.response['campaign_id']);
        setCurrentInsertionCampaignId(response.response['campaign_id']);
        setCurrentStatus(response?.response?.moderation_status);
        setApprovalFlag(response?.approvalButton);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const {
    insertion_order_name = '-',
    dv360_insertionorder_id = '-',
    insertion_order_type = '-',
    flight_startdate = '-',
    flight_enddate = '-',
    pacing_type = '-',
    goal_type = '-',
    goal_value = '-',
    billable_outcome = '-',
    optimization_action = '-',
    frequency_cap_limit_value = '-',
    frequency_limit_type = '-',
    frequency_limit = '-',
    frequency_cap_type = '-',
  } = data || {};

  const columns = [
    {
      Header: 'Budget',
      accessor: 'budget_value',
      Cell: ({ value }) => (
        <span>
          ₹ {value} {/* Assuming the value is a number */}
        </span>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'budget_startdate',
      Cell: ({ row }) => (
        <span>
          {moment(row.original.budget_startdate)
            .format('DD-MMM-YYYY')
            .toUpperCase()}
        </span>
      ),
    },
    {
      Header: 'End Date',
      accessor: 'budget_enddate',
      Cell: ({ row }) => (
        <span>
          {moment(row.original.budget_enddate)
            .format('DD-MMM-YYYY')
            .toUpperCase()}
        </span>
      ),
    },
  ];

  const tableData = data?.budgets || [];

  const insertionOrderListUrl = DV360Options.Insertion_Order.url;

  const goToListingPage = () => {
    navigate(DV360Options.Campaigns.url);
  };

  const totalCount = data?.budgets?.length;

  const campaign_id = data?.campaign_id;

  const handleRedirection = () => {
    navigate(insertionOrderListUrl);
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box media-section-left'>
            <div className=' top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
              <div className='top-right-bar '>
                <div className='btn-box'>
                  <button type='button' className='primary-btn close-btn'>
                    <i className='icon-close' onClick={goToListingPage}></i>
                  </button>
                </div>
              </div>
            </div>
            <section className='side-menu-form d-flex'>
              {data && (
                <SideTreeMenuView
                  id={campaign_id}
                  IdAndType={{
                    id: id,
                    type: DV360Options.Insertion_Order.name,
                  }}
                  AtdStatus={capitalizeFirstLetter(data?.moderation_status)}
                  Dv360Status={capitalizeFirstLetter(data?.is_enabled) || 'NA'}
                />
              )}
              <article className='side-right-form-wrap side-right-pad'>
                <div className='tab-new-form'>
                  <div className='camp-tab-cont d-flex'>
                    <div className='short-left-bar'>
                      <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <Link
                          className={`primary-btn ${
                            isViewPage ? 'active' : ''
                          }`}
                          to={`${DV360Options.Insertion_Order.viewPageUrl}/${id}`}
                        >
                          <i className='icon-show-password'></i> View
                        </Link>
                        {getCurrentStatus == 'in_moderation' ? (
                          <Link
                            class='primary-btn disable-button'
                            title='Entry under moderation: Editing disabled until review completion.'
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        ) : (
                          <Link
                            class='primary-btn'
                            to={DV360Options.Insertion_Order.editUrl + `/${id}`}
                          >
                            <i class='icon-edit'></i> Edit
                          </Link>
                        )}
                        <Link
                          className={`primary-btn ${
                            !isViewPage ? 'active' : ''
                          }`}
                          to={`${DV360Options.Insertion_Order.viewPageUrl}/${id}?platForm=${DV360Options.Insertion_Order.platform}&form=${DV360Options.Insertion_Order.reviewForm}`}
                        >
                          <i className='icon-history'></i> Review Log
                        </Link>
                      </div>
                    </div>
                    {getCurrentStatus == 'in_moderation' &&
                      getUserInfo?.roles == 'Publisher' &&
                      getApprovalFlag && (
                        <ApproveReject
                          platform={'DV360'}
                          campaignId={getCurrentInsertionCampaignId}
                          getUserInfo={getUserInfo}
                          setThankYouApprovalPopupShowStatus={
                            setThankYouApprovalPopupShowStatus
                          }
                          getThankYouApprovalPopupShowStatus={
                            getThankYouApprovalPopupShowStatus
                          }
                          setDisplayLoader={setDisplayLoader}
                        />
                      )}
                  </div>
                </div>
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  <DvErrorDisplay id={insertionError} />
                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        {isViewPage ? (
                          <div className='card-primary'>
                            <DisplayCampaignStatus
                              moderationStatus={getCurrentStatus}
                              publishedAt={data?.updated_at}
                            />
                            <DataDetails
                              Label={'Insertion order name'}
                              LabelFor={insertion_order_name}
                            />
                            <DataDetails
                              Label={'DV360 insertion order id'}
                              LabelFor={dv360_insertionorder_id}
                            />
                            <DataDetails
                              Label={'Insertion order type'}
                              LabelFor={insertion_order_type}
                            />
                            <DataDetails Label={'Budget'} LabelFor={''} />
                            <ReactTable
                              columns={columns}
                              data={tableData}
                              totalCount={totalCount}
                            />

                            <DataDetails
                              Label={'Start date'}
                              LabelFor={moment(flight_startdate).format(
                                'DD-MMM-YYYY',
                              )}
                            />
                            <DataDetails
                              Label={'End date'}
                              LabelFor={
                                moment(flight_enddate).format('DD-MMM-YYYY') ||
                                '-'
                              }
                            />
                            <DataDetails
                              Label={'Pacing'}
                              LabelFor={pacing_type}
                            />
                            <DataDetails
                              Label={'Goal Type'}
                              LabelFor={goal_type}
                            />
                            <DataDetails
                              Label={'Goal Value'}
                              LabelFor={goal_value}
                            />
                            {/* <DataDetails
                            Label={'Billable outcome'}
                            LabelFor={billable_outcome}
                          />
                          <DataDetails
                            Label={'Optimization'}
                            LabelFor={optimization_action}
                          /> */}

                            <DataDetails
                              Label={'Frequency Cap'}
                              LabelFor={
                                frequency_cap_type === 'no limit'
                                  ? 'No Limit'
                                  : `Limit frequency to ${checkForNull(
                                      frequency_cap_limit_value,
                                    )} ${
                                      frequency_limit_type ===
                                      'Lifetime of this campaign'
                                        ? 'Lifetime of this campaign'
                                        : `exposures per ${checkForNull(
                                            frequency_limit,
                                          )} ${checkForNull(
                                            frequency_limit_type,
                                          )}`
                                    }`
                              }
                            />
                          </div>
                        ) : (
                          <ReviewLog
                            id={id}
                            form={DV360Options.Insertion_Order.reviewForm}
                            platForm={DV360Options.Insertion_Order.platform}
                          ></ReviewLog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  >
                    History
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </div>
      <Footer
        closePath={DV360Options.Campaigns.url}
        prevPath={insertionOrderListUrl}
        // nextBtnlabel='Next'
        // nextPath={insertionOrderListUrl}
      ></Footer>
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          type={'Dv360'}
          approved={'approved'}
        />
      )}
      {displayLoader && <Loader />}
    </div>
  );
};

export default InsertionOrderView;
