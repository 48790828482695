import { postApi } from '../../utils/Apis';
import { baseUrlStg } from '../../utils/constants';

export const ApproveReject = () => {
  const handleAPICall = async (platform, campaignId, publisherId) => {
    let payload = {
      platform: platform,
      campaignId: campaignId,
      action: 'Approved',
      remark: '',
      link: window.location.href,
      publisherId: publisherId,
      token: '',
    };

    try {
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/ApproveCampaign`,
        payload,
      );
      if (response?.status == 200) {
        return response?.status;
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return { handleAPICall };
};
