import CardBody from '../common/CardBody';
import ReactSelect from '../common/ReactSelect';
import SingleInput from './SingleInput';

const AdUrlOption = (props) => {
  return (
    <>
      <section className='group-section-data'>
        <CardBody>
          <h6 className='card-header-title'>Ad URL options</h6>
          <div className='card-form form-panel'>
            <div className='row align-items-center'>
              <div className='form-item'>
                <div className='budget-cont'>
                  <div className='label-icon'>
                    <label>Tracking Template</label>
                  </div>
                  <ReactSelect
                    options={props?.trackingTemplateOptions}
                    isMulti={false}
                    onChange={props?.handleSetValue}
                    closeMenuOnSelect={true}
                    value={props?.trackingTemplateVal}
                  />
                </div>
                {/* <span className='error' id='emailError'>
                  Please enter valid email id
                </span> */}
              </div>
              <div class='info-icon-body mt-2 mb-2'>
                <div class='more-info-icon'>
                  <i class='icon-bulb'></i>
                </div>
                <div class='more-info-text'>
                  <p>
                    Example: https://www.trackingtemplate.foo/?url=
                    {''}
                    &id=5
                  </p>
                </div>
              </div>

              <div className='form-item'>
                <div className='budget-cont'>
                  <div className='label-icon'>
                    <label>Final URL suffix</label>
                  </div>
                  <div className='input-group'>
                    <input
                      type='text'
                      id='final-url'
                      placeholder='Final URL suffix'
                      name='final_url'
                      onChange={props?.handleSetFinalUrl}
                      value={props?.finalUrlSuffix}
                    />
                  </div>
                </div>
                {/* <span className='error' id='emailError'>
                  Please enter valid email id
                </span> */}
              </div>
              <div class='info-icon-body mt-2 mb-2'>
                <div class='more-info-icon'>
                  <i class='icon-bulb'></i>
                </div>
                <div class='more-info-text'>
                  <p>Example: param1=value1&param2=value2</p>
                </div>
              </div>
            </div>
          </div>
        </CardBody>

        <CardBody>
          <div className='action-btn-both action-btn-both-check row'>
            <div className='form-check-box'>
              <input
                className='form-check-input'
                type='checkbox'
                id='inlineCheckbox1'
                onChange={props?.handleSetIsMobile}
                checked={props?.handleSetIsMobileStatus ? true : false}
              />
              <label className='form-check-label' htmlFor='inlineCheckbox1'>
                Use a different final URL for mobile
              </label>
            </div>
          </div>
          {props?.handleSetIsMobileStatus && (
            <SingleInput
              label={'Final URL for mobile'}
              handleSetValue={props?.handleSetFinalMobileUrl}
              value={props?.finalUrlMobile}
            />
          )}
        </CardBody>
      </section>
      <div className='divder-form'></div>
    </>
  );
};
export default AdUrlOption;
