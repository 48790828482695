import { getApi } from '../../utils/Apis';
import { baseUrlStg, googleV1Url } from '../../utils/constants';
import {
  GOOGLE_AD_ADD_CALL,
  GOOGLE_AD_ADD_DESCRIPTIONS,
  GOOGLE_AD_ADD_HEADLINES,
  GOOGLE_AD_AD_NAME,
  GOOGLE_AD_BUSINESS_NAME,
  GOOGLE_AD_CALLS,
  GOOGLE_AD_DEL_DESCRIPTIONS,
  GOOGLE_AD_DEL_HEADLINES,
  GOOGLE_AD_DISPLAY_PATH,
  GOOGLE_AD_DISPLAY_PATH_0,
  GOOGLE_AD_DISPLAY_PATH_1,
  GOOGLE_AD_FINAL_URL,
  GOOGLE_AD_GET_FORM_DATA,
  GOOGLE_AD_LEADFORMS,
  GOOGLE_AD_SITELINKS,
  GOOGLE_AD_UPDATE_DESCRIPTIONS,
  GOOGLE_AD_UPDATE_HEADLINES,
  GOOGLE_AD_VIDEO_ID,
  GOOGLE_AD_CALL_TO_ACTION,
  GOOGLE_AD_VIDEO_HEADLINE,
  GOOGLE_AD_VIDEO_LONG_HEADLINE,
  GOOGLE_AD_DESCRIPTION,
  GOOGLE_AD_TRACKINGTEMPLATE,
  GOOGLE_AD_FINAL_URL_SUFFIX,
  GOOGLE_AD_ISMOBILE_URL_ENABLED,
  GOOGLE_AD_FINAL_MOBILE_URL,
  GOOGLE_AD_SET_COMPANION_BANNER_TYPE,
  GOOGLE_AD_SET_COMPANION_BANNER_ID,
  GOOGLE_AD_ADDITIONAL_FORMAT_OPTIONS,
  GOOGLE_AD_ISCALLTOACTION_ENABLED,
  GOOGLE_AD_SHOW_THIS_AD_STATUS,
  GOOGLE_AD_CUSTOM_COLOR_ENABLED,
  GOOGLE_AD_CUSTOM_COLOR,
  GOOGLE_AD_ACCENT_COLOR,
  GOOGLE_AD_SELECTED_CREATE_TYPE,
  GOOGLE_AD_RESET_FORM,
  GOOGLE_AD_ADD_LONG_HEADLINES,
  GOOGLE_AD_DEL_LONG_HEADLINES,
  GOOGLE_AD_UPDATE_LONG_HEADLINES,
  GOOGLE_AD_ADD_SHORT_DESCRIPTIONS,
  GOOGLE_AD_DEL_SHORT_DESCRIPTIONS,
  GOOGLE_AD_UPDATE_SHORT_DESCRIPTIONS,
  GOOGLE_AD_APP_URL,
  DV_Prefilled_Values,
  GOOGLE_AD_FINAL_URL_PERFORMANCE,
} from './GoogleAdActionTypes';

export const setFinalURL = (payload) => {
  return {
    type: GOOGLE_AD_FINAL_URL,
    payload: payload,
  };
};

export const setFinalURLPerformance = (payload) => {
  return {
    type: GOOGLE_AD_FINAL_URL_PERFORMANCE,
    payload: {
      name: 'final_url',
      value: payload,
    },
  };
};

export const setDisplayPath_0 = (payload) => {
  return {
    type: GOOGLE_AD_DISPLAY_PATH_0,
    payload: payload,
  };
};
export const setDisplayPath_1 = (payload) => {
  return {
    type: GOOGLE_AD_DISPLAY_PATH_1,
    payload: payload,
  };
};

export const setCalls = (checked, index) => {
  return {
    type: GOOGLE_AD_CALLS,
    payload: { checked, index },
  };
};
export const setLeadForms = (checked, index) => {
  return {
    type: GOOGLE_AD_LEADFORMS,
    payload: { checked, index },
  };
};
export const setSiteLinks = (checked, index) => {
  return {
    type: GOOGLE_AD_SITELINKS,
    payload: { checked, index },
  };
};

export const addHeadlines = () => {
  return {
    type: GOOGLE_AD_ADD_HEADLINES,
  };
};
export const addLongHeadlines = () => {
  return {
    type: GOOGLE_AD_ADD_LONG_HEADLINES,
  };
};
export const removeHeadlines = (payload) => {
  return {
    type: GOOGLE_AD_DEL_HEADLINES,
    payload: payload,
  };
};
export const removeLongHeadlines = (payload) => {
  return {
    type: GOOGLE_AD_DEL_LONG_HEADLINES,
    payload: payload,
  };
};
export const updateHeadlines = (payload) => {
  return {
    type: GOOGLE_AD_UPDATE_HEADLINES,
    payload: payload,
  };
};
export const updateLongHeadlines = (payload) => {
  return {
    type: GOOGLE_AD_UPDATE_LONG_HEADLINES,
    payload: payload,
  };
};
export const addDescriptions = () => {
  return {
    type: GOOGLE_AD_ADD_DESCRIPTIONS,
  };
};
export const addShortDescriptions = () => {
  return {
    type: GOOGLE_AD_ADD_SHORT_DESCRIPTIONS,
  };
};
export const removeDescriptions = (payload) => {
  return {
    type: GOOGLE_AD_DEL_DESCRIPTIONS,
    payload: payload,
  };
};
export const removeShortDescriptions = (payload) => {
  return {
    type: GOOGLE_AD_DEL_SHORT_DESCRIPTIONS,
    payload: payload,
  };
};
export const updateDescriptions = (payload) => {
  return {
    type: GOOGLE_AD_UPDATE_DESCRIPTIONS,
    payload: payload,
  };
};
export const updateShortDescriptions = (payload) => {
  return {
    type: GOOGLE_AD_UPDATE_SHORT_DESCRIPTIONS,
    payload: payload,
  };
};
export const updateBusinessName = (payload) => {
  return {
    type: GOOGLE_AD_BUSINESS_NAME,
    payload: payload,
  };
};
export const updateAdName = (payload) => {
  return {
    type: GOOGLE_AD_AD_NAME,
    payload: payload,
  };
};
export const setFormData = (payload) => {
  return async (dispatch, getState) => {
    let response = await getApi(`${googleV1Url}/getMasters`);
    // console.log("res", response);
    try {
      if (response?.status == 200) {
        let data = response?.data;
        // console.log('masters', data);

        dispatch({
          type: GOOGLE_AD_GET_FORM_DATA,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// Video Campaign type params start
export const setVideoId = (payload) => {
  return {
    type: GOOGLE_AD_VIDEO_ID,
    payload: payload,
  };
};

export const setCallToAction = (payload) => {
  return {
    type: GOOGLE_AD_CALL_TO_ACTION,
    payload: payload,
  };
};

export const setVideoHeading = (payload) => {
  return {
    type: GOOGLE_AD_VIDEO_HEADLINE,
    payload: payload,
  };
};

export const setVideoLongHeading = (payload) => {
  return {
    type: GOOGLE_AD_VIDEO_LONG_HEADLINE,
    payload: payload,
  };
};

export const setVideoDescription = (payload) => {
  return {
    type: GOOGLE_AD_DESCRIPTION,
    payload: payload,
  };
};

export const setTrackingTemplate = (payload) => {
  return {
    type: GOOGLE_AD_TRACKINGTEMPLATE,
    payload: payload,
  };
};

export const setFinalUrlSuffix = (payload) => {
  return {
    type: GOOGLE_AD_FINAL_URL_SUFFIX,
    payload: payload,
  };
};

export const setIsMobileUrlEnabled = (payload) => {
  return {
    type: GOOGLE_AD_ISMOBILE_URL_ENABLED,
    payload: payload,
  };
};

export const setFinalMobileUrl = (payload) => {
  return {
    type: GOOGLE_AD_FINAL_MOBILE_URL,
    payload: payload,
  };
};

export const setCompanionBannerType = (payload) => {
  return {
    type: GOOGLE_AD_SET_COMPANION_BANNER_TYPE,
    payload: payload,
  };
};

export const setCompanionBannerId = (payload) => {
  return {
    type: GOOGLE_AD_SET_COMPANION_BANNER_ID,
    payload: payload,
  };
};
// Video Campaign type params end
// Display Campaign type params start
export const setAdditionalFormatOptions = (payload) => {
  return {
    type: GOOGLE_AD_ADDITIONAL_FORMAT_OPTIONS,
    payload: payload,
  };
};

export const setIsEnabledCallToActionStatus = (payload) => {
  return {
    type: GOOGLE_AD_ISCALLTOACTION_ENABLED,
    payload: payload,
  };
};

export const setCustomMainColor = (payload) => {
  return {
    type: GOOGLE_AD_CUSTOM_COLOR,
    payload: payload,
  };
};

export const setAccentColor = (payload) => {
  return {
    type: GOOGLE_AD_ACCENT_COLOR,
    payload: payload,
  };
};

export const setShowThisAdStatus = (payload) => {
  return {
    type: GOOGLE_AD_SHOW_THIS_AD_STATUS,
    payload: payload,
  };
};

export const setCustomColorStatusEnabled = (payload) => {
  return {
    type: GOOGLE_AD_CUSTOM_COLOR_ENABLED,
    payload: payload,
  };
};

export const setSelectedCreateType = (payload) => {
  return {
    type: GOOGLE_AD_SELECTED_CREATE_TYPE,
    payload: payload,
  };
};

export const setAppUrl = (payload) => {
  return {
    type: GOOGLE_AD_APP_URL,
    payload: payload,
  };
};

export const setResetForm = (payload) => {
  return {
    type: GOOGLE_AD_RESET_FORM,
  };
};

export const setPrefillValues = (payload) => {
  return {
    type: DV_Prefilled_Values,
    payload: payload,
  };
};
// Display Campaign type params end
