import { useState } from 'react';

export const RemarkCell = ({ row }) => {
  const [showMore, setShowMore] = useState(false);
  const remark = row.original.remark;

  // Function to toggle showMore state
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // Function to truncate text to 20 words and capitalize first letter
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const truncateText = (text) => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, 10);
    const truncatedText = truncatedWords.join(' ');
    return truncatedWords.length < words.length
      ? `${truncatedText}...`
      : truncatedText;
  };

  return (
    <span>
      {remark && remark.length > 70 ? (
        <>
          {showMore
            ? capitalizeFirstLetter(remark)
            : capitalizeFirstLetter(truncateText(remark))}
          <div className='show-more-less'>
            <button
              className='btn-icon ms-auto'
              onClick={toggleShowMore}
              style={{ color: 'var(--primary)', fontWeight: 'bold' }}
            >
              {showMore ? 'Show Less' : 'Show More'}
            </button>
          </div>
        </>
      ) : (
        capitalizeFirstLetter(remark) || '-'
      )}
    </span>
  );
};
